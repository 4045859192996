import {ButtonBase, ButtonColors, ConcreteButtonProps} from "./button-base";
import React from "react";
import {observer} from "mobx-react-lite";
import {useServices} from "../../hooks/use-services.hook";


interface PrimaryButtonProps extends ConcreteButtonProps {
}

export const PrimaryButton: React.FC<PrimaryButtonProps> = observer((props) => {
    const services = useServices();
    const themeColors = services.theme.currentTheme.colors;

    const buttonColors: ButtonColors = {
        $background: themeColors.primary,
        $color: themeColors.primaryContrast,
        $border: themeColors.primary,
        $backgroundActivated: themeColors.primaryContrast,
        $colorActivated: themeColors.primary,
        $borderActivated: themeColors.primary
    }
    return (
        <ButtonBase {...props} colors={buttonColors}>
            {props.children}
        </ButtonBase>
    );
});
