import {EXTERNAL_LINK_CSS_CLASS_NAME, IExternalLinksService} from "./external-links.service.interface";
import {ServiceBase} from "../service-base";
import {IExternalLinksConfiguration} from "./external-links-configuration.interface";
import { NullableString } from "../../types/nullable-types";
import {AirlineCompanyName} from "../../global-constants";

export class ExternalLinksService extends ServiceBase implements IExternalLinksService {

    private get links(): IExternalLinksConfiguration {
        return this.services.externalLinksConfiguration;
    }

    private _anchorStart(link: string, className?: string) {
        return `<a class="${EXTERNAL_LINK_CSS_CLASS_NAME} ${className}" href="${link}" target="_blank">`;
    }

    private get _anchorEnd(): string {
        return '</a>'
    }

    get appUsageTermsAndConditions(): string {
        return this.services.language.translationFor('We take your privacy and security seriously. Our policy documents inform you of your rights and obligations when using {airlineCompanyName} services.{lineBreak}In order to continue please accept our {privacyLinkStart}Privacy Policy{linkEnd}, {cookiesPolicyLinkStart}Cookies Policy{linkEnd}, {mobileAppPolicyLinkStart}Mobile app T&C{linkEnd}')
            .withParams({
                airlineCompanyName: AirlineCompanyName,
                privacyLinkStart: this._anchorStart(this.links.privacyPolicy),
                cookiesPolicyLinkStart: this._anchorStart(this.links.cookiesPolicy),
                mobileAppPolicyLinkStart: this._anchorStart(this.links.privacyPolicy),
                linkEnd: this._anchorEnd,
                lineBreak: '<br>'

            });
    }

    get privacyPolicyLink(): string {
        return this.links.privacyPolicy;
    }
    get travelConditions(): string {
        return this.services.language.translationFor(`I have read and I accept the {aStart}Travel Conditions{aEnd}`)
            .withParams({
                aStart: this._anchorStart(this.links.travelConditions, 'link-travel-conditions'),
                aEnd: this._anchorEnd
            });
    }

    get childTravelConditions(): string {
        return this.services.language.translationFor(`You need to select at least one adult for one child. If you really need the child to travel alone, please {aStart}contact us{aEnd}`)
            .withParams({
                aStart: this._anchorStart(this.links.contactUs, 'link-child-travel-conditions'),
                aEnd: this._anchorEnd
            })
    }

    get dangerousGoods(): string {
        return this.services.language.translationFor(`I agree with the {aStart}Travel Conditions{aEnd}`) //`I agree with the {aStart1}Travel Conditions{aEnd} and I acknowledge the restrictions on {aStart2}Dangerous Goods and Liquids on Board{aEnd}`
            .withParams({
                aStart: this._anchorStart(this.links.travelConditions, 'link-travel-conditions'),
                aEnd: this._anchorEnd
            });
    }

    get groupBooking(): string {
        return this.services.language.translationFor(`For groups over {maxPassengersNumber} people please use our {aStart}Group Booking Tool{aEnd}`)
            .withParams({
                maxPassengersNumber: this.services.configuration.data.maxAllowedPassengersNumberOnBooking,
                aStart: this._anchorStart(this.links.groupBooking, 'link-group-booking'),
                aEnd: this._anchorEnd
            });
    }

    get registrationTermsAndConditions(): string {
        return this.services.language.translationFor(`I confirm that after reading the {aStart}Terms and conditions{aEnd}, I agree with their content and to the processing of my personal data in order to register and create an account on this website.`)
            .withParams({
                aStart: this._anchorStart(this.links.travelConditions, 'link-register-terms-and-conditions'),
                aEnd: this._anchorEnd
            });
    }

    flightCanceled(pnr: string, lastName: NullableString): NullableString {
        if(!this.links.flightCanceled) {
            return null;
        }
        return this.services.language.translationFor(`In order to see all your options, please access {aStart}self-service{aEnd} section from our website.`)
        .withParams({
            aStart: this._anchorStart(`${this.links.flightCanceled}?rl=${pnr}&ln=${lastName}`, 'link-flight-canceled'),
            aEnd: this._anchorEnd
        });
    }

    get notAllowFlightChangeForCheckedInPassengers(): string {
        return this.services.language.translationFor(`Check-in formalities already performed for this booking, if you need any change, please contact {aStart}Call Center Service{aEnd} for further assistance, during business hours.`)
            .withParams({
                aStart: this._anchorStart(this.links.notAllowFlightChangeForCheckedInPassengers, 'link-not-allow-flight-change-for-checked-in-passengers'),
                aEnd: this._anchorEnd
            });
    }

    insuranceTermsAndConditionsConfirmation(pdsUrl: string): string {
        return this.services.language.translationFor(`By clicking the Continue button you agree that you have read and accepted {aStart}policy terms.{aEnd}`)
            .withParams({
                aStart: this._anchorStart(pdsUrl, 'link-insurance-tc'),
                aEnd: this._anchorEnd
            })
    }

    insurancePolicyInfoDocument(ipidUrl: string, countryCode: string): string {
        const documentName = (countryCode === "IT" ? "IPID/DIP Danni Aggiuntivo" : "IPID");
        return this.services.language.translationFor(`{aStart}View full {documentName}{aEnd}`)
            .withParams({
                aStart: this._anchorStart(ipidUrl, 'link-ipid'),
                aEnd: this._anchorEnd,
                documentName: documentName
            })
    }

    get removeMyAccount(): string {
        return this.services.language.translationFor('If you wish to remove your account, please contact us {aStart}here{aEnd}')
            .withParams({
                aStart: this._anchorStart(this.links.accountDeletion, 'link-zendesk'),
                aEnd: this._anchorEnd,
            });
    }

    get contactForm(): string {
        return this.services.language.translationFor('{aStart}Web form{aEnd}')
            .withParams({
                aStart: this._anchorStart(this.links.contactForm, 'link-web-form'),
                aEnd: this._anchorEnd,
            });
    }
}
