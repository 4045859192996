import {
    IPassengerSsrEditorViewModel
} from "../../../../../../services/booking/models/ssrs/passenger-ssr-editor-view-model.interface";
import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {translateIncludedBags} from "../../../bags-selection.helpers";
import {PriceWithDiscountComponent} from "../../../../../../components/price/price-with-discount.component";
import {StandardPriceLabelComponent} from "../../../../../../components/price/standard-price-label.component";
import {BagIconComponent} from "../../bag-icon.component";
import {CheckInBagQuantityButtonsComponent} from "../quantity-buttons/check-in-bag-quantity-buttons.component";
import {BagContainerComponent} from "../../bag-container/bag-container-component";
import {
    SsrModificationBlockingReasonComponent
} from "../../../../common-components/ssr-modification-blocking-reason.component";
import styled from "styled-components";
import {Bag3dIconComponent} from "../../bag-icon/bag-3d-icon.component";


const CheckInBackPriceContainerBox = styled.div`
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

interface CheckInBagSelectionComponentProps {
    bagSsrEditor: IPassengerSsrEditorViewModel;
}

export const CheckInBagSelectionComponent: React.FC<CheckInBagSelectionComponentProps> = observer((props) => {
    const services = useServices();
    const isSelected = props.bagSsrEditor.newQuantity > 0

    const getTopLabelText = () => {

        if(props.bagSsrEditor.quantityIncludedInBundle > 0) {
            return translateIncludedBags(services.language, props.bagSsrEditor.quantityIncludedInBundleToDisplay)
        }

        return null;
    }

    const renderBlockingReason = () => {
        if(props.bagSsrEditor.maxAllowedQuantityReachedMessage) {
            return (
                <SsrModificationBlockingReasonComponent reason={props.bagSsrEditor.maxAllowedQuantityReachedMessage}/>
            );
        }

        if(props.bagSsrEditor.isCompletelyIncludedInBundle) {
            return null;
        }

        return (
            <SsrModificationBlockingReasonComponent reason={props.bagSsrEditor.modificationBlockingReason}/>
        );
    }

    const renderBagIcon = () => {
        const bag3DIconProps = props.bagSsrEditor.ssrType.get3DIcon();
        if(bag3DIconProps) {
            return (<Bag3dIconComponent imageUrl={bag3DIconProps.imageUrl} width={bag3DIconProps.width} height={bag3DIconProps.height}/>)
        }

        return (<BagIconComponent ssrType={props.bagSsrEditor.ssrType} />);

    }


    return (
        <BagContainerComponent isSelected={isSelected} topLabel={getTopLabelText()} isClickable={false}>
            {renderBagIcon()}
            <CheckInBackPriceContainerBox>
                <PriceWithDiscountComponent discountedPrice={props.bagSsrEditor.discountedMinPrice}
                                            standardPrice={props.bagSsrEditor.standardMinPrice}
                                            label={<StandardPriceLabelComponent/>}/>
            </CheckInBackPriceContainerBox>

            <CheckInBagQuantityButtonsComponent ssrEditor={props.bagSsrEditor}/>
            {renderBlockingReason()}
        </BagContainerComponent>
    )
});

