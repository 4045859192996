import {IMediaChecks} from "../../services/media-query/media-queries.interface";
import {
    IRenderPaymentMethodIconOptions
} from "../../services/booking/models/payment/payment-methods/payment-method.view-model.interface";

interface LogoSize {
    width: number;
    height: number;
}

export function computePaymentMethodIconSize(mediaQuery: IMediaChecks, options: IRenderPaymentMethodIconOptions): LogoSize {
    const originalWidth = 91;
    const originalHeight = 60;

    if(options.forPayButton) {
        return {
            width: originalWidth * 0.4,
            height: originalHeight * 0.4
        }
    }

    if(mediaQuery.xsAndBelow) {
        return {
            width: originalWidth * 0.65,
            height: originalHeight * 0.65
        }
    }

    return {
        width: originalWidth,
        height: originalHeight
    };
}