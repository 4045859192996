import React from "react";
import {observer} from "mobx-react";
import {AirlineLogoLargeMulticolorIcon} from "../../../assets/svg/airline-logo-large-multicolor.icon";
export const TicketAirlineLogoComponent: React.FC = observer(() => {

    return (
        <div>
            <AirlineLogoLargeMulticolorIcon/>
        </div>
    );
})