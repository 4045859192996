import { observer } from 'mobx-react';
import React, {PropsWithChildren} from 'react';
import styled from 'styled-components';
import { DownloadIcon } from '../../assets/svg/download.icon';
import { useServices } from '../../hooks/use-services.hook';
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import {StandardDialogPageComponent} from "../modal/page/standard-dialog-page.component";
import {StandardDialogContentComponent} from "../modal/content/standard-dialog-content.component";
import {AppVersionUpgradeModeEnum} from "../../services/airline-webapi/responses/get-configurations.response";
import {PrimaryButtonInverted} from "../../basic-components/buttons/primary-button-inverted";
import {VerticalSpacer} from "../../basic-components/spacing/vertical-spacer";

const DialogContent = styled(StandardDialogContentComponent)`
    &::part(scroll) {
        background-image: linear-gradient(to right, rgba(${props => props.theme.colors.primaryRgb}, 0.79), rgba(${props => props.theme.colors.primaryRgb}, 0.79)), url("${props => props.theme.assets.images.airplane_window_view_png.toString()}");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;    
        overflow: hidden;
    }
`

const ContentWrapperBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    width: 100%;
    flex-grow: 1;
    color: ${props => props.theme.colors.primaryContrast};
    font-size: ${props => props.theme.fontSize.h2};
`

const TitleBox = styled.div`
    text-align: center;
`
const SubTitleBox = styled.div`
    text-align: center;
`

const ContinueToExistingVersionButton = styled.div`
    color: ${props => props.theme.colors.primaryContrast};
    text-decoration: underline;
    font-size: ${props => props.theme.fontSize.h5};
`

interface AppVersionUpdateSuggestionDialogComponentProps extends PropsWithChildren {
    dialogHandler: IDialogConfirmationHandler;
    appVersionBlockingMode: AppVersionUpgradeModeEnum;
}

export const AppVersionUpdateSuggestionDialogComponent: React.FC<AppVersionUpdateSuggestionDialogComponentProps> = observer((props) => {
    const services = useServices();
    let isIosDevice = services.device.isIOS;

    const renderContinueWithoutUpdateToggleButton = () => {
        if (services.configuration.appVersionUpgradeMode === AppVersionUpgradeModeEnum.Mandatory) {
            return null;
        }else {
            return <ContinueToExistingVersionButton onClick={() => props.dialogHandler.reject()}>
                {services.language.translate("Continue with existing version")}
            </ContinueToExistingVersionButton>
        }
    }
    
    const goToUpdateUrl = () => {
        if (isIosDevice){
            window.open(services.configuration.data.appDownloadLinks.ios);
        }else {
            window.open(services.configuration.data.appDownloadLinks.android);
        }
    }

    return (
        <StandardDialogPageComponent>
            <DialogContent dialogHandler={props.dialogHandler}>
                <ContentWrapperBox className={"tst-content-wrapper"}>
                    <DownloadIcon color={services.theme.currentTheme.colors.secondaryContrast}/>
                    <VerticalSpacer size={"extraLarge"}/>
                    <TitleBox>{services.language.translate('Please upgrade to the newest version available!')}</TitleBox>
                    <VerticalSpacer size={"extraLarge"}/>
                    <SubTitleBox>{services.language.translate('Download it now for a better experience!')}</SubTitleBox>
                    <VerticalSpacer size={"extraLarge"}/>
                    <PrimaryButtonInverted onClick={goToUpdateUrl}>
                        {services.language.translate('Upgrade to the New Version')}
                    </PrimaryButtonInverted>
                    <VerticalSpacer/>
                    {renderContinueWithoutUpdateToggleButton()}
                </ContentWrapperBox>
            </DialogContent>
        </StandardDialogPageComponent>
    );
});
