import {IDateSelectionStrategy} from "./strategy/date-selection-strategy.interface";
import {computed, IReactionDisposer, makeObservable, observable, reaction, runInAction} from "mobx";
import {DepartureDateSelectionStrategy} from "./strategy/departure-date-selection.strategy";
import {IServiceFactory} from "../../service-factory.interface";
import {ReturnDateSelectionStrategy} from "./strategy/return-date-selection.strategy";
import {MonthModel} from "../../time/month.model";
import {
    IFlightSearchControllerViewModel
} from "../flight-search-controller/flight-search-controller-view-model.interface";
import {
    ILowFareResult,
    IRoundTripLowFareReader,
    LowFareStatusEnum
} from "../../low-fare/low-fare-readers/low-fare-reader.interface";
import {NullableDate, NullableString} from "../../../types/nullable-types";

export class FlightsDatesSelectorModel {
    constructor(public readonly flightSearchController: IFlightSearchControllerViewModel,
                private readonly services: IServiceFactory,
                getLowFareReader: () => IRoundTripLowFareReader) {


        this._departureDateSelectionStrategy = new DepartureDateSelectionStrategy(this.services, flightSearchController, this._getCurrentStrategy, this._setCurrentStrategy, getLowFareReader);
        this._returnDateSelectionStrategy = new ReturnDateSelectionStrategy(this.services, flightSearchController, this._getCurrentStrategy, this._setCurrentStrategy, getLowFareReader);
        if(this.flightSearchController.isOneWayReturnTrip) {
            this._currentStrategy = this._returnDateSelectionStrategy;
        } else {
            this._currentStrategy = this._departureDateSelectionStrategy;
        }


        makeObservable<this, '_currentStrategy'>(this, {
            _currentStrategy: observable.ref,
            availableMonths: computed
        });

        this._reactions.push(reaction(() => this.services.navigator.currentRoute,
            (currentRoute) => {

                if(currentRoute.equals(this.services.navigator.routes.flightSearch.flightDates)) {
                    if(!this.flightSearchController.isOneWayDepartureTrip) {
                        if(this.flightSearchController.departureDate && !this.flightSearchController.returnDate) {
                            this._setCurrentStrategy(this._returnDateSelectionStrategy);
                        }
                    }
                }
            }, {
                fireImmediately: true
            }));

        this._reactions.push(reaction(() => this.flightSearchController.isOneWay,
            () => {
                if(this.flightSearchController.isOneWayDepartureTrip) {
                    this._setCurrentStrategy(this._departureDateSelectionStrategy);
                } else if(this.flightSearchController.isOneWayReturnTrip) {
                    this._setCurrentStrategy(this._returnDateSelectionStrategy);
                } else {
                    if(this.flightSearchController.departureDate) {
                        this._setCurrentStrategy(this._returnDateSelectionStrategy);
                    } else {
                        this._setCurrentStrategy(this._departureDateSelectionStrategy);
                    }
                }
            }));

        this._reactions.push(reaction(() => this.flightSearchController.departureDate, (departureDate) => {
            if(!departureDate && !this.flightSearchController.isOneWayReturnTrip) {
                //this is for the change flight feature on the booking flow to behave correctly
                this._setCurrentStrategy(this._departureDateSelectionStrategy);
            }
        }))
    }

    /*
    clearDepartureDate(): void {
        this.flightSearchController.returnDate = null;
        this.flightSearchController.departureDate = null;
        this._setCurrentStrategy(this._departureDateSelectionStrategy);
    }

    clearReturnDate(): void {
        this.flightSearchController.returnDate = null;
        if(this.flightSearchController.departureDate) {
            this._setCurrentStrategy(this._returnDateSelectionStrategy);
        } else {
            this._setCurrentStrategy(this._departureDateSelectionStrategy);
        }

    }
*/
    private _reactions: IReactionDisposer[] = [];

    private readonly _departureDateSelectionStrategy: DepartureDateSelectionStrategy;
    private readonly _returnDateSelectionStrategy: ReturnDateSelectionStrategy;


    private _currentStrategy: IDateSelectionStrategy;

    private _getCurrentStrategy = () => {
        return this._currentStrategy;
    }

    private _setCurrentStrategy = (strategy: IDateSelectionStrategy) => {
        runInAction(() => {
            this._currentStrategy = strategy;
        })
    }

    get strategies(): IDateSelectionStrategy[] {
        if(this.flightSearchController.isOneWayDepartureTrip) {
            return [this._departureDateSelectionStrategy]
        }

        if(this.flightSearchController.isOneWayReturnTrip) {
            return [this._returnDateSelectionStrategy];
        }

        return [this._departureDateSelectionStrategy, this._returnDateSelectionStrategy];
    }

    get isOnDepartureDateStrategy(): boolean {
        return this._currentStrategy instanceof DepartureDateSelectionStrategy;
    }

    isSameDateForDepartureAndReturn(date: Date): boolean {
        const departureDate = this.flightSearchController.departureDate;
        const returnDate = this.flightSearchController.returnDate;
        const time = this.services.time;


        return Boolean(departureDate && returnDate && time.areDatesEqual(date, departureDate) && time.areDatesEqual(date, returnDate));
    }

    get departureDate(): NullableDate {
        return this.flightSearchController.departureDate;
    }

    get returnDate(): NullableDate {
        return this.flightSearchController.returnDate;
    }

    get isOneWayFlight(): boolean {
        return this.flightSearchController.isOneWay;
    }

    get isLoadingSchedule(): boolean {
        return this.flightSearchController.departureFlightSchedule.isLoading || this.flightSearchController.returnFlightSchedule.isLoading;
    }

    get scheduleLoadingError(): NullableString {
        if(this.flightSearchController.isOneWayDepartureTrip) {
            return this.flightSearchController.departureFlightSchedule.errorMessage
        }

        if(this.flightSearchController.isOneWayReturnTrip) {
            return this.flightSearchController.returnFlightSchedule.errorMessage;
        }

        return this.flightSearchController.departureFlightSchedule.errorMessage || this.flightSearchController.returnFlightSchedule.errorMessage;
    }

    getLowFare(date: Date): ILowFareResult {
        return this._currentStrategy.getLowFare(date);
    }

    getDepartureLowFare(date: Date): ILowFareResult {
        return this._departureDateSelectionStrategy.getLowFare(date);
    }

    getReturnLowFare(date: Date): ILowFareResult {
        return this._returnDateSelectionStrategy.getLowFare(date);
    }

    isDateAvailable(date: Date): boolean {
        return this._currentStrategy.isDateAvailable(date) && this._currentStrategy.getLowFare(date).status !== LowFareStatusEnum.NoFare;
    }

    isDateSelected(date: Date): boolean {
        return this.services.time.areDatesEqual(date, this.flightSearchController.departureDate)
               || this.services.time.areDatesEqual(date, this.flightSearchController.returnDate);
    }

    isDepartureDate(date: Date): boolean {
        return this.services.time.areDatesEqual(date, this.flightSearchController.departureDate);
    }

    isDateInsideSelection(date: Date): boolean {
        const searchController = this.flightSearchController;
        if(searchController.departureDate && searchController.returnDate) {
            return searchController.departureDate?.getTime() <= date.getTime()
                && date.getTime() <= searchController.returnDate?.getTime();
        } else {
            return false;
        }
    }

    clearDepartureDate(): void {
        this.flightSearchController.returnDate = null;
        this.flightSearchController.departureDate = null;
        this._setCurrentStrategy(this._departureDateSelectionStrategy);
    }

    clearReturnDate(): void {
        this.flightSearchController.returnDate = null;
        if(this.flightSearchController.departureDate) {
            this._setCurrentStrategy(this._returnDateSelectionStrategy);
        } else {
            this._setCurrentStrategy(this._departureDateSelectionStrategy);
        }

    }


    selectDate(date: Date): void {
        this._currentStrategy.setCurrentDate(date);
        if(this.flightSearchController.isOneWay) {
            return;
        }

        if(this._currentStrategy instanceof DepartureDateSelectionStrategy) {
            this._setCurrentStrategy(this._returnDateSelectionStrategy);
        } else {
            this._setCurrentStrategy(this._departureDateSelectionStrategy);
        }
    }

    get availableMonths(): MonthModel[] {
        const availableDepartureDates = this.flightSearchController.departureFlightSchedule.availableDates;
        const availableReturnDates = this.flightSearchController.returnFlightSchedule.availableDates;

        if(availableDepartureDates.length === 0 && availableReturnDates.length === 0) {
            return [];
        }

        let firstAvailableDate: number;
        let lastAvailableDate: number;

        if(availableReturnDates.length === 0) {
            firstAvailableDate = availableDepartureDates[0].getTime();
            lastAvailableDate = availableDepartureDates[availableDepartureDates.length - 1].getTime();
        } else if(availableDepartureDates.length === 0) {
            firstAvailableDate = availableReturnDates[0].getTime();
            lastAvailableDate = availableReturnDates[availableReturnDates.length - 1].getTime();
        } else {
            firstAvailableDate = Math.min(availableDepartureDates[0].getTime(), availableReturnDates[0].getTime());
            lastAvailableDate = Math.max(availableDepartureDates[availableDepartureDates.length - 1].getTime(),
                                         availableReturnDates[availableReturnDates.length - 1].getTime());
        }


        return this.services.time.getMonthsInRange(new Date(firstAvailableDate), new Date(lastAvailableDate));
    }

    dispose(): void {
        this._reactions.forEach(disposeReaction => disposeReaction());
        this._reactions = [];
    }
}




