import React from 'react';
import {ISeatsRowViewModel} from "../../../../../services/booking/models/seat-maps/seats-row-view-model.interface";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {SeatComponent} from "./seat.component";
import {ISeatViewModel} from "../../../../../services/booking/models/seat-maps/seat-view-model.interface";
import {
    CSS_VALUE_EMERGENCY_ROW_MARKER_WIDTH,
    CSS_VAR_SEAT_FONT_SIZE,
    CSS_VAR_SEAT_HEIGHT
} from "../seat-map-css-constants";


const RowNumberBox = styled.div<{$isOnRowWithExtraLegRoom: boolean; $gridColumn: number}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-column: ${props => props.$gridColumn};
    
    width: 100%;
    height: 100%;
    font-size: var(${CSS_VAR_SEAT_FONT_SIZE}, ${props => props.theme.fontSize.h6});
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.colors.medium};
    ${props => props.$isOnRowWithExtraLegRoom 
        ? css`
                justify-content: flex-end;
                padding-bottom: 1.1rem;
        `
        : ''
    }
`

const SeatWrapperBox = styled.div<{$gridColumn: number}>`
    grid-column: ${props => props.$gridColumn};
`

const EmergencyRowMarkerBox = styled.div`
    width: ${CSS_VALUE_EMERGENCY_ROW_MARKER_WIDTH};
    height: var(${CSS_VAR_SEAT_HEIGHT});
    background-color: ${props => props.theme.colors.medium};
`

const EmergencyRowMarkerLeftBox = styled(EmergencyRowMarkerBox)`
    border-top-right-radius: 34px;
    border-bottom-right-radius: 34px;
    grid-column: 1;
    
`

const EmergencyRowMarkerRightBox = styled(EmergencyRowMarkerBox)<{$gridColumn: number}>`
    border-top-left-radius: 34px;
    border-bottom-left-radius: 34px;
    grid-column: ${props => props.theme.$gridColumn};
`

interface SeatRowComponentProps {
    row: ISeatsRowViewModel;
    seatsPerRowCount: number;
}


export const SeatsRowComponent: React.FC<SeatRowComponentProps> = observer((props) => {

    const renderLeftSeat = (seat: ISeatViewModel | null, index: number) => {
        return (
            <SeatWrapperBox key={seat?.seatKey + index.toString()} $gridColumn={index + 2}>
                <SeatComponent seat={seat} />
            </SeatWrapperBox>

        )
    }

    const renderRightSeat = (seat: ISeatViewModel | null, index: number) => {
        return (
            <SeatWrapperBox key={seat?.seatKey + index.toString()} $gridColumn={props.row.leftSeats.length + index + 3}>
                <SeatComponent seat={seat} />
            </SeatWrapperBox>

        )
    }

    const rowNumberId = `ROW_NUMBER_${props.row.rowNumber.toString()}`

    return (
        <>
            {props.row.isMiddleEmergencyRow && <EmergencyRowMarkerLeftBox/>}
            {props.row.leftSeats.map(renderLeftSeat)}
            <RowNumberBox key={rowNumberId}
                          id={rowNumberId}
                          $isOnRowWithExtraLegRoom={props.row.hasExtraLegRoom}
                          $gridColumn={props.row.leftSeats.length + 2}>
                {props.row.rowNumber}
            </RowNumberBox>
            {props.row.rightSeats.map(renderRightSeat)}
            {props.row.isMiddleEmergencyRow && <EmergencyRowMarkerRightBox $gridColumn={props.seatsPerRowCount + 3}/>}
        </>
    )
});

