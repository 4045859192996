import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../../../../hooks/use-services.hook";
import {PassengerTypeDropDownItemComponent} from "./passenger-type-drop-down-item.component";
import {IDropDownController} from "../../../../forms/drop-down/models/drop-down-controller.interface";
import {PrimaryShadeButton} from "../../../../../basic-components/buttons/primary-shade-button";
import {IPassengerTypeSelector} from "../../../../../services/passenger-types/passengers-types.service.interface";


const DropDownContentBox = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${props => props.theme.spacing.cardDefaultPadding};
    gap: ${props => props.theme.spacing.spacing16};
    width: 300px;
    ${props => props.theme.screenMediaQuery.smallScreen} {
        width: 100%;
    }
`

const PassengersTypesContainerBox = styled.div`
    display: grid;
    grid-template-columns: 1fr auto 20px auto;
    row-gap: ${props => props.theme.spacing.flexGap20};
    column-gap: ${props => props.theme.spacing.flexGap8};
    align-items: center;
`

const MaximumPassengersLimitReachedMessage = styled.div`
    font-size: ${props => props.theme.fontSize.subtitle2};
    color: ${props => props.theme.colors.danger};
`

const DoneButtonWrapperBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
`

const DoneButton = styled(PrimaryShadeButton)`
    min-width: 140px;
`

const DividerBox = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.border.mainColor};
    grid-column: 1/-1;
`

interface PassengersTypesDropDownListComponentProps {
    dropDownController: IDropDownController;
}

export const PassengersTypesDropDownListComponent: React.FC<PassengersTypesDropDownListComponentProps> = observer((props) => {
    const services = useServices();


    const passengerTypes = services.flightSearch.searchController.passengers;

    const renderErrorMessage = () => {
        if(passengerTypes.passengersMaximumLimitReachedErrorMessage) {
            return (
                <MaximumPassengersLimitReachedMessage>
                    {passengerTypes.passengersMaximumLimitReachedErrorMessage}
                </MaximumPassengersLimitReachedMessage>
            )
        }

        return null;
    }

    const onCloseClick = () => {
        props.dropDownController.closeDropDown();
    }

    const renderDivider = (index: number) => {
        if(index < passengerTypes.getAll().length  - 1) {
            return (<DividerBox/>)
        }
        return null;
    }

    const renderOnePassengerType = (passengerType: IPassengerTypeSelector, index: number) => {
        return (
            <React.Fragment key={passengerType.description}>
                <PassengerTypeDropDownItemComponent passengerTypeSelector={passengerType}/>
                {renderDivider(index)}
            </React.Fragment>

        );
    }

    return (
        <DropDownContentBox>
            <PassengersTypesContainerBox>
                {passengerTypes.getAll().map(renderOnePassengerType)}
            </PassengersTypesContainerBox>

            {renderErrorMessage()}

            <DoneButtonWrapperBox>
                <DoneButton onClick={onCloseClick}>
                    {services.language.translate('Done')}
                </DoneButton>
            </DoneButtonWrapperBox>

        </DropDownContentBox>
    );
});
