import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {VerticalLineBox} from "./vertical-line.box";
import {useServices} from "../../../../hooks/use-services.hook";
import {StopPointComponent} from "../common/stop-point.component";
import {TimeSpan} from "../../../../types/time-span";

const LayoverBox = styled.div`
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    width: 100%;
    align-items: center;
    color: ${props => props.theme.colors.notesTint};
    
`

const LabelBox = styled.span`
    flex-grow: 1;
    text-align: right;
    padding-right: 4px;
`

const LayoverTimeBox = styled.span`
    flex-grow: 1;
    padding-left: 4px;
    
`

const StopPointWrapperBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
    
    
`

interface LayoverComponentProps {
    layoverTime: TimeSpan;
}

export const LayoverComponent: React.FC<LayoverComponentProps> = observer((props) => {
    const services = useServices();
    return (
        <>
            <div/>

            <LayoverBox>
                <LabelBox>{services.language.translate('Layover')}</LabelBox>
                <StopPointWrapperBox>
                    <VerticalLineBox/>
                    <StopPointComponent/>
                    <VerticalLineBox/>
                </StopPointWrapperBox>
                <LayoverTimeBox>{props.layoverTime.toUserFriendlyString(services.language, {useShortFormat: true, ignoreSeconds: true})}</LayoverTimeBox>
            </LayoverBox>

            <div/>
        </>
    )
})