import {IBookingViewModel} from "../../../../services/booking/models/booking-view-model.interface";
import {IExtrasSectionRenderer} from "./extras-section-renderer.interface";
import {AirportCheckInExtrasSectionRenderer} from "../sections/airport-check-in/airport-check-in.extras-section.renderer";
import {EarlyCheckInExtrasSectionRenderer} from "../sections/early-check-in/early-check-in.extras-section.renderer";
import {BusTransferExtrasSectionRenderer} from "../sections/bus-transfer/bus-transfer.extras-section.renderer";
import {BusinessLoungeExtrasSectionRenderer} from "../sections/business-lounge/business-lounge.extras-section.renderer";
import {FastTrackExtrasSectionRenderer} from "../sections/fast-track/fast-track.extras-section.renderer";
import {PetsExtrasSectionRenderer} from "../sections/pets/pets.extras-section.renderer";
import {SpecialEquipmentExtrasSectionRenderer} from "../sections/special-equipment/special-equipment.extras-section.renderer";
import {IExtrasSectionsRenderersFactory} from "./extras-sections-renderers-factory.interface";
import {SeatsExtrasSectionRenderer} from "../sections/seats/seats.extras-section.renderer";
import {ExtrasRenderersSorter} from "./extras-renderers-sorter";
import {makeObservable, observable, runInAction} from "mobx";
import {BagsExtrasSectionRenderer} from "../sections/bags/bags-extras-section.renderer";

export class ExtrasSectionsRenderersFactory implements IExtrasSectionsRenderersFactory{
    constructor(booking: IBookingViewModel) {
        makeObservable<this, '_customSectionsOrder'>(this, {
            _customSectionsOrder: observable.ref
        })

        this._sections = ExtrasRenderersSorter.sortRenderers( [
            new FastTrackExtrasSectionRenderer(booking),
            new AirportCheckInExtrasSectionRenderer(booking),
            new SeatsExtrasSectionRenderer(booking),
            new EarlyCheckInExtrasSectionRenderer(booking),
            new BusTransferExtrasSectionRenderer(booking),
            new BusinessLoungeExtrasSectionRenderer(booking),
            new BagsExtrasSectionRenderer(booking),
            new SpecialEquipmentExtrasSectionRenderer(booking),
            new PetsExtrasSectionRenderer(booking)
        ]);
    }

    private _customSectionsOrder: IExtrasSectionRenderer[] | null = null;
    private readonly _sections: IExtrasSectionRenderer[];

    getExtrasSections(): IExtrasSectionRenderer[] {
        if(this._customSectionsOrder) {
            return this._customSectionsOrder;
        }
        return this._sections;
    }

    setCustomOrder(sections: IExtrasSectionRenderer[]) {
        runInAction(() => {
            this._customSectionsOrder = sections
        })
    }
}

