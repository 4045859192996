import React from "react";
import styled, {css} from "styled-components";
import { useServices } from "../../../../../hooks/use-services.hook";
import { IBundleViewModel } from "../../../../../services/booking/models/bundle/bundle-view-model.interface";
import {PriceComponent} from "../../../../../components/price/price.component";
import {useContainerMediaQueriesChecks} from "../../../../../hooks/use-container-media-queries-checks.hook";
import {AlertBoxComponent} from "../../../../../components/alert-box/alert-box.component";

const BundleFooterBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap16};
    padding: ${props => props.theme.spacing.spacing16} ${props => props.theme.spacing.spacing24};
    width: 100%;
    font-size: ${props => props.theme.fontSize.subtitle1};
    font-weight:  ${props => props.theme.fontWeight.regular};
`

const SelectionButtonBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 100%;
    padding: ${props => props.theme.spacing.spacing16};
    border-radius: ${props => props.theme.border.defaultRadius};
`

const BundleUnselectedButtonBox = styled(SelectionButtonBox)`
    background-color: ${props => props.theme.colors.darkContrast};
    color: ${props => props.theme.colors.dark};
    border: 1px solid ${props => props.theme.colors.dark};
`

const BundleSelectedButtonBox = styled(SelectionButtonBox)<{$showCursor: boolean}>`
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primaryContrast};
    font-weight:  ${props => props.theme.fontWeight.bold};
    cursor: ${props => props.$showCursor ? 'pointer' : 'default'};
`

const BundlePriceBox = styled(PriceComponent)`
    font-size: ${props => props.theme.fontSize.body1};
    font-weight: ${props => props.theme.fontWeight.bold};
`

const SelectForOnlyBox = styled.div<{$hasPrice: boolean}>`
    ${props => !props.$hasPrice 
                    ? css`
                        font-weight: ${props => props.theme.fontWeight.bold};
                    `
                    : ''
    }
`

const PricePerPassengerBox = styled.div<{$hidden: boolean; $showCursor: boolean}>`
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.notesTint};
    font-size: ${props => props.theme.fontSize.caption};
    visibility: ${props => props.$hidden ? "hidden" : "visible"};
    cursor: ${props => props.$showCursor ? 'pointer' : 'default'};
`


export const BundleFooterComponent: React.FC<{ bundle: IBundleViewModel; showContinueButton: boolean; isDisabled: boolean; showReturnToDepartureLink: boolean}> = (props) => {
    const services = useServices();
    const mediaQuery = useContainerMediaQueriesChecks();
    const hasPrice = props.bundle.pricePerPassenger.amount > 0;

    const renderCustomerFavourite = () => {
        if(props.bundle.isSelected) {
            return null;
        }
        if(!props.bundle.bundleType.isFavouriteBundle) {
            return null;
        }

        return (
            <AlertBoxComponent severity={"warning"}
                               fullWidth={true}
                               size={"medium"}
                               title={services.language.translate('Price only available during booking')}>
                {services.language.translate(`You won't see this discount again`)}
            </AlertBoxComponent>
        );
    }

    const conContinueClick = async () => {
        if(props.showContinueButton) {
            await services.booking.steps.nextStep()
        }
    }

    const renderFooterContent = () => {
        if(props.bundle.isSelected) {
            return (
                <>
                    <BundleSelectedButtonBox onClick={conContinueClick} $showCursor={props.showContinueButton}>
                        {props.showContinueButton ? services.language.translate('Continue') : services.language.translate('Selected')}
                    </BundleSelectedButtonBox>
                </>


            );
        } else if(!props.isDisabled){
            return (
                <BundleUnselectedButtonBox>
                   <SelectForOnlyBox $hasPrice={hasPrice}>
                       {hasPrice ? services.language.translate('Select for only') : services.language.translate('Select')}
                   </SelectForOnlyBox>
                    {props.bundle.pricePerPassenger.amount > 0 && <BundlePriceBox price={props.bundle.pricePerPassenger}/>}

                </BundleUnselectedButtonBox>
            );
        }
    }

    const renderPricePerPassengerText = () => {
        if(mediaQuery.smallScreen) {
            return null;
        }

        const onReturnToDeparture = () => {
            if(props.showReturnToDepartureLink) {
                services.navigator.goBack();
            }

        }

        if(props.showContinueButton && !props.showReturnToDepartureLink) {
            return null;
        }

        return (
            <PricePerPassengerBox onClick={onReturnToDeparture} $hidden={props.isDisabled} $showCursor={props.showReturnToDepartureLink}>
                {props.showContinueButton
                    ? services.language.translate('or Return to departure to change the bundle')
                    : services.language.translate('Price per passenger')
                }
            </PricePerPassengerBox>
        );
    }

    return (
        <BundleFooterBox>
            {renderCustomerFavourite()}
            {renderFooterContent()}
            {renderPricePerPassengerText()}
        </BundleFooterBox>
    )

};
