import React from "react";
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";

export const PenToSquareIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={0.95} sizeIsHeight {...props} viewBox="0 0 17 16" ratio={17/16}>
            <g clipPath="url(#clip0_36113_35935)">
                <path
                    d="M14.1147 1.84102L14.4929 2.21914C14.7866 2.51289 14.7866 2.98789 14.4929 3.27852L13.5835 4.19102L12.1429 2.75039L13.0522 1.84102C13.346 1.54727 13.821 1.54727 14.1116 1.84102H14.1147ZM6.88975 8.00664L11.0835 3.80977L12.5241 5.25039L8.32725 9.44414C8.23662 9.53477 8.12412 9.60039 8.00225 9.63477L6.17412 10.1566L6.696 8.32852C6.73037 8.20664 6.796 8.09414 6.88662 8.00352L6.88975 8.00664ZM11.9929 0.781641L5.82725 6.94414C5.55537 7.21602 5.3585 7.55039 5.25537 7.91602L4.36162 11.041C4.28662 11.3035 4.3585 11.5848 4.55225 11.7785C4.746 11.9723 5.02725 12.0441 5.28975 11.9691L8.41475 11.0754C8.7835 10.9691 9.11787 10.7723 9.38662 10.5035L15.5522 4.34102C16.4304 3.46289 16.4304 2.03789 15.5522 1.15977L15.1741 0.781641C14.296 -0.0964844 12.871 -0.0964844 11.9929 0.781641ZM3.0835 2.00039C1.56475 2.00039 0.333496 3.23164 0.333496 4.75039V13.2504C0.333496 14.7691 1.56475 16.0004 3.0835 16.0004H11.5835C13.1022 16.0004 14.3335 14.7691 14.3335 13.2504V9.75039C14.3335 9.33477 13.9991 9.00039 13.5835 9.00039C13.1679 9.00039 12.8335 9.33477 12.8335 9.75039V13.2504C12.8335 13.941 12.2741 14.5004 11.5835 14.5004H3.0835C2.39287 14.5004 1.8335 13.941 1.8335 13.2504V4.75039C1.8335 4.05977 2.39287 3.50039 3.0835 3.50039H6.5835C6.99912 3.50039 7.3335 3.16602 7.3335 2.75039C7.3335 2.33477 6.99912 2.00039 6.5835 2.00039H3.0835Z"
                    fill={props.color ? props.color : "currentColor"}/>
            </g>
            <defs>
                <clipPath id="clip0_36113_35935">
                    <rect width="16" height="16" fill="white" transform="translate(0.333496)"/>
                </clipPath>
            </defs>
        </SvgIcon>
    )
}