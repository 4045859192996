import React from "react";
import {observer} from "mobx-react";
import {DayCellBox} from "./boxes/day-cell.box";
import styled from "styled-components";
import {FlightsDatesSelectorModel} from "../../../../../services/flight-search/dates-selection/flights-dates-selector.model";
import {DayPriceComponent} from "./day-price.component";
import {PlaneGoIcon} from "../../../../../assets/svg/plane-go.icon";
import {PlaneReturnsIcon} from "../../../../../assets/svg/plane-returns.icon";
import {NullableUndefinedPrice} from "../../../../../services/currency/price";

const DayContentBox = styled(DayCellBox)`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.primaryContrast};
  cursor: pointer;
`

interface SelectedDayComponentProps {
  date: Date;
  flightsDatesSelector: FlightsDatesSelectorModel;
  departureLowFare: NullableUndefinedPrice;
  returnLowFare: NullableUndefinedPrice;
}
export const SelectedDayComponent: React.FC<SelectedDayComponentProps> = observer((props) => {
  let price: NullableUndefinedPrice;
  let PlaneIcon = PlaneGoIcon;
  if(props.flightsDatesSelector.isDepartureDate(props.date)) {
    price = props.departureLowFare;
  } else {
    price = props.returnLowFare;
    PlaneIcon = PlaneReturnsIcon;
  }
  return (
    <DayContentBox>
      <PlaneIcon size={1}/>
      <DayPriceComponent price={price}/>
    </DayContentBox>
  )
})