import { SsrCategoryEnum } from "../../ssr-category.enum";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../ssrs-preferred-orders-in-shopping-cart";
import {OnlineCheckInBlockerSsr} from "../online-check-in-blocker-ssr";
import {TimeSpan} from "../../../../types/time-span";
import React from "react";
import {SkiingIcon} from "../../../../assets/svg/skiing.icon";
import {
    SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE,
    SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE,
} from "../../ssr-types.service.interface";


export abstract class SpecialEquipmentSsrTypeBase extends OnlineCheckInBlockerSsr {
    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.SPECIAL_EQUIPMENT;
    }

    get category(): SsrCategoryEnum {
        return SsrCategoryEnum.SpecialEquipment;
    }

    protected _getPurchaseMinimumTimeBeforeFlight(): TimeSpan | null {
        return TimeSpan.fromHours(3);
    }

    get iconInExtrasFirstLargeTile(): React.ReactElement | null {
        return (<SkiingIcon size={SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE}/>);
    }

    get iconInExtrasSecondLargeTile(): React.ReactElement | null {
        return (<SkiingIcon size={2.2}/>);
    }

    get iconInExtrasSmallTile(): React.ReactElement | null {
        return (<SkiingIcon size={SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE}/>);
    }




}
