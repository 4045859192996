import React, {useRef, useState} from "react";
import {IPassengerViewModel} from "../../../../../services/booking/models/passenger/passenger-view-model.interface";
import {observer} from "mobx-react";
import {useServices} from "../../../../../hooks/use-services.hook";
import {IonIcon} from "@ionic/react";
import {WheelchairIcon} from "../../../../../assets/svg/wheelchair.icon";
import styled from "styled-components";
import {ISsrType} from "../../../../../services/ssr-types/ssr-types.service.interface";
import {checkmark, checkmarkOutline, chevronForwardOutline, chevronDownOutline, chevronUpOutline} from "ionicons/icons";
import {DialogCloseButtonBehavior} from "../../../../../services/dialog/dialog-enums";
import {
    scrollElementIntoViewLazySmooth
} from "../../../../../utils/scroll-element-into-view";
import {useScreenMediaQueriesChecks} from "../../../../../hooks/use-screen-media-queries-checks.hook";
import {PrimaryContactToggleComponent} from "../primary-contact-toggle.component";
import {useContainerMediaQueriesChecks} from "../../../../../hooks/use-container-media-queries-checks.hook";
import {
    IMaturePassengerViewModel
} from "../../../../../services/booking/models/passenger/mature-passenger-view-model.interface";
import {PassengerSsrsSelectionComponent} from "../../../common-components/ssrs/passenger-ssrs-selection.component";
import {
    SsrsSelectionJourneysTabsComponent
} from "../../../common-components/ssrs/ssrs-selection-journeys-tabs.component";
import {IJourneyViewModel} from "../../../../../services/booking/models/journey/journey-view-model.interface";
import {VerticalSpacer} from "../../../../../basic-components/spacing/vertical-spacer";

const ContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap20};
`

const SelectedSsrsGridBox = styled.div`
    display: grid;
    grid-template-columns: 24px auto;
    width: 100%;
    cursor: pointer;
    gap: 2px;
    
`

const HeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: ${props => props.theme.fontSize.subtitle2};
    font-weight: ${props => props.theme.fontSize.h6};
`

const HeaderLeftSectionBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    ${props => props.theme.screenMediaQuery.smallScreen} {
        flex-basis: 100%;
    }
`

const HeaderRightSectionBox = styled.div`
`


const IconBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 24px;
    height: 24px;
`

const SpecialAssistanceSsrsSelectionContainerBox = styled.div<{$isVisible: boolean}>`
    display: ${props => props.$isVisible ? 'block' : 'none'};
`

const ChevronBox = styled(IconBox)`
    font-size: 20px;
`

const DescriptionBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    font-size: ${props => props.theme.fontSize.body2};
    font-weight: ${props => props.theme.fontWeight.medium};
    
`

const LineBox = styled.div<{$marginMultiplier: number}>`
    height: 1px;
    background-color: ${props => props.theme.border.mainColor};
    margin-left: calc(${props => props.$marginMultiplier} * ${props => props.theme.spacing.cardDefaultPadding});
    margin-right: calc(${props => props.$marginMultiplier} * ${props => props.theme.spacing.cardDefaultPadding});
`


const SelectedSsrComponent: React.FC<{ssrType: ISsrType}> = observer((props) => {
    return (
        <>
            <IconBox>
                <IonIcon ios={checkmarkOutline} md={checkmark}/>
            </IconBox>
            <DescriptionBox>
                {props.ssrType.name}
            </DescriptionBox>
        </>

    );
});


const ExpandedSsrsBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0;
`

const AssistanceForLabelBox = styled.div`
    color: ${props => props.theme.colors.dark};
`

const AssistanceForPassengerNameBox = styled.div`
    color: ${props => props.theme.colors.mediumShade};
`

const AssistanceForContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
`


export const PassengerSelectedSpecialAssistanceSsrsComponent: React.FC<{passenger: IPassengerViewModel}> = observer((props) => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();
    const containerMediaQuery = useContainerMediaQueriesChecks();
    const booking = services.booking.current;
    const [isExpanded, setIsExpanded] = useState(false);
    const ssrsSelectionContainerRef = useRef<HTMLDivElement | null>(null);

    const [ssrsAggregator] = useState(booking.createSsrsAggregator({
        ssrTypes: services.ssrTypes.getSpecialAssistanceSsrTypes(),
        onlyForPassengerKeys: [props.passenger.passengerKey]
    }));

    const journeys = ssrsAggregator.journeysSsrsBuckets.map(b => b.parentJourney);
    const [currentJourney, setCurrentJourney] = useState(journeys[0]);

    if(!props.passenger.canHaveSpecialAssistance) {
        return null;
    }

    const onJourneyTabClick = (journey: IJourneyViewModel) => {
        setCurrentJourney(journey)
    }


    const assistanceForText = () => {
        return (
            <AssistanceForContainerBox>
                <AssistanceForLabelBox>
                    {services.language.translate('Assistance for')}
                </AssistanceForLabelBox>
                <AssistanceForPassengerNameBox>
                    {props.passenger.getFullName()}
                </AssistanceForPassengerNameBox>
            </AssistanceForContainerBox>
        );
    }


    const openSpecialAssistanceSelector = async () => {

        if(screenMediaQuery.smallScreen) {
            if(props.passenger.fields)
                await services.dialogFactory.showSsrsSelector({
                    closeButtonBehavior: DialogCloseButtonBehavior.Accept,
                    ssrsAggregator: ssrsAggregator,
                    renderTitle: () => services.language.translate('Request Special Assistance'),
                    renderSubtitle: () => null,
                    passengers: [props.passenger as IMaturePassengerViewModel]
                });
        } else {
            setIsExpanded(!isExpanded);
            scrollElementIntoViewLazySmooth(ssrsSelectionContainerRef.current, {
                block: "center"
            });
        }
    }

    const renderCollapsedSsrs = () => {
        if(isExpanded) {
            return null;
        }

        if(ssrsAggregator.totalSsrsQuantity === 0) {
            return null;
        }

        return (
            <SelectedSsrsGridBox onClick={openSpecialAssistanceSelector}>
                {ssrsAggregator.countAllSsrsPerBooking.map(ssrTypeCount => <SelectedSsrComponent key={ssrTypeCount.ssrType.ssrCode} ssrType={ssrTypeCount.ssrType}/>)}
            </SelectedSsrsGridBox>
        );
    }

    const renderExpandedSsrs = () => {
        if(!isExpanded) {
           return null;
        }

        return (
            <ExpandedSsrsBox>
                <SsrsSelectionJourneysTabsComponent ssrsAggregator={ssrsAggregator}
                                                    journeys={journeys}
                                                    currentJourney={currentJourney}
                                                    hasScroll={false}
                                                    onJourneyTabClick={onJourneyTabClick}/>
                {isExpanded && !ssrsAggregator.useSameOptionsForAllFlights && <LineBox $marginMultiplier={-2.3}/>}
                {!ssrsAggregator.useSameOptionsForAllFlights && <VerticalSpacer/>}
                <PassengerSsrsSelectionComponent ssrsAggregator={ssrsAggregator}
                                                 passenger={props.passenger as IMaturePassengerViewModel}
                                                 journey={currentJourney}
                                                 showSameForAllFlights={true}
                                                 renderCustomTitle={() => assistanceForText()}/>
            </ExpandedSsrsBox>

        );
    }

    const renderChevron = () => {
        if(screenMediaQuery.smallScreen) {
            return (<IonIcon icon={chevronForwardOutline} />);
        } else {
            if(isExpanded) {
                return (<IonIcon icon={chevronUpOutline} />);
            } else {
                return (<IonIcon icon={chevronDownOutline} />);
            }
        }
    }

    const renderPrimaryContactToggleForLargeScreen = () => {
        if(containerMediaQuery.smallScreen) {
            return null;
        }

        return (
            <HeaderRightSectionBox>
                <PrimaryContactToggleComponent passenger={props.passenger}/>
            </HeaderRightSectionBox>
        );
    }

    return (
        <ContainerBox>
            <HeaderBox>
                <HeaderLeftSectionBox onClick={openSpecialAssistanceSelector}>
                    <IconBox>
                        <WheelchairIcon size={1.5}/>
                    </IconBox>
                    <DescriptionBox>
                        {services.language.translate('Request Special Assistance')}
                    </DescriptionBox>
                    <ChevronBox>
                        {renderChevron()}
                    </ChevronBox>
                </HeaderLeftSectionBox>
                {renderPrimaryContactToggleForLargeScreen()}

            </HeaderBox>
            {renderCollapsedSsrs()}
            {isExpanded && ssrsAggregator.useSameOptionsForAllFlights && <LineBox $marginMultiplier={-2.3}/>}
            <SpecialAssistanceSsrsSelectionContainerBox ref={ssrsSelectionContainerRef} $isVisible={isExpanded}>

                {renderExpandedSsrs()}
            </SpecialAssistanceSsrsSelectionContainerBox>

        </ContainerBox>
    );
});
