import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const RankingStarIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.4} {...props} viewBox="0 0 43 34" ratio={43 / 34}>
            <g clipPath="url(#clip0_35694_46357)">
                <path
                    d="M23.9552 3.5304L22.4152 0.411099C22.1607 -0.130537 21.3645 -0.150115 21.0839 0.411099L19.5438 3.5304L16.1309 4.01983C15.524 4.11119 15.263 4.86165 15.7132 5.31193L18.193 7.72645L17.6057 11.1264C17.5143 11.7332 18.1408 12.2031 18.702 11.9225L21.7626 10.3041L24.8036 11.8964C25.3648 12.177 25.9978 11.7071 25.8999 11.1003L25.3126 7.70034L27.7924 5.31193C28.2361 4.86818 27.9816 4.11772 27.3747 4.01983L23.9618 3.5304H23.9552ZM17.5731 16.7059C16.418 16.7059 15.4848 17.639 15.4848 18.7941V31.3235C15.4848 32.4786 16.418 33.4117 17.5731 33.4117H25.926C27.0811 33.4117 28.0142 32.4786 28.0142 31.3235V18.7941C28.0142 17.639 27.0811 16.7059 25.926 16.7059H17.5731ZM2.95542 20.8823C1.80037 20.8823 0.867188 21.8155 0.867188 22.9706V31.3235C0.867188 32.4786 1.80037 33.4117 2.95542 33.4117H11.3084C12.4634 33.4117 13.3966 32.4786 13.3966 31.3235V22.9706C13.3966 21.8155 12.4634 20.8823 11.3084 20.8823H2.95542ZM30.1025 27.147V31.3235C30.1025 32.4786 31.0357 33.4117 32.1907 33.4117H40.5437C41.6987 33.4117 42.6319 32.4786 42.6319 31.3235V27.147C42.6319 25.992 41.6987 25.0588 40.5437 25.0588H32.1907C31.0357 25.0588 30.1025 25.992 30.1025 27.147Z"
                    fill="#FFF0B5"/>
            </g>
            <defs>
                <clipPath id="clip0_35694_46357">
                    <rect width="41.7647" height="33.4118" fill="white" transform="translate(0.867188)"/>
                </clipPath>
            </defs>
        </SvgIcon>
    )
}
