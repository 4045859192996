import React from "react";
import {observer} from "mobx-react";
import {IFormField} from "../../../models/forms/form-field.interface";
import styled, {css} from "styled-components";
import {CheckboxComponent} from "../../../basic-components/checkbox/checkbox.component";
import {FieldInputContainerComponent} from "../field-input-container.component";
import {
    FieldEditorCommonProps,
} from "../inputs-common.props";
import { FieldLabelBox } from "../field-label.box";

const CheckBoxLabelBox = styled(FieldLabelBox)<{$hasHtmlContent: boolean}>`
    ${props => props.$hasHtmlContent  && !props.$hasError
            ? css`
                color: ${props => props.theme.colors.medium};
            `
            : ``
    }
    margin-bottom: 0;
`

interface BooleanFieldEditorComponentProps extends FieldEditorCommonProps {
    field: IFormField<boolean>;
    labelHtml?: string;
}
export const BooleanFieldEditorComponent: React.FC<BooleanFieldEditorComponentProps> = observer((props) => {
    const renderLabel = () => {

        if(props.labelHtml) {
            return (
                <CheckBoxLabelBox $hasHtmlContent={true} $hasError={props.field.hasError} dangerouslySetInnerHTML={{
                    __html: props.labelHtml
                }}/>
            );
        }

        return (
            <CheckBoxLabelBox $hasError={props.field.hasError} $hasHtmlContent={false}>
                {props.field.fieldName}
            </CheckBoxLabelBox>
        );
    }

    const renderCheck = () => {
        return (
            <CheckboxComponent checked={props.field.value || false}
                               onChange={isChecked => props.field.setValue(isChecked)}/>
        );
    }


    return (
        <FieldInputContainerComponent {...props} hideLabel={true} renderInput={renderLabel} renderIcon={renderCheck}/>
    );
});
