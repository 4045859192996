import React from "react";
import {observer} from "mobx-react";
import {useServices} from "./hooks/use-services.hook";
import {IonToast} from "@ionic/react";

export const CopyToClipboardToastComponent: React.FC = observer(() => {
    const services = useServices();

    const onDismiss = () => {
        services.device.showCopyToClipboardToast = false;
    }

    return (
        <IonToast isOpen={services.device.showCopyToClipboardToast}
                  onDidDismiss={onDismiss}
                  message={services.language.translate('Copied to clipboard.')}
                  duration={700}
                  position="top"/>
    )
})