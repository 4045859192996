import React from "react";
import {useServices} from "../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import styled from "styled-components";
import {VoucherCodeComponent} from "./voucher-code/voucher-code.component";
import {BookingFlowPage} from "../booking-flow.page";
import {InvoiceViewContainerComponent} from "./invoice-view-container.component";
import {PaymentMethodGroupsTabsComponent} from "./groups/payment-method-groups-tabs.component";
import {PaymentPageFooterComponent} from "./payment-page-footer.component";
import {useScreenMediaQueriesChecks} from "../../../hooks/use-screen-media-queries-checks.hook";

const PAYMENT_PAGE_FOOTER_CSS_CLASS = 'payment-page-footer'



const PaymentMethodGroupsContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.spacing30};
`


const PaymentPageContentComponent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;

    const selectionHandler = booking.paymentHandler.paymentSelectionHandler;

    return (
        <PaymentMethodGroupsContainerBox>

            <PaymentMethodGroupsTabsComponent selectionHandler={selectionHandler}/>

            <VoucherCodeComponent voucher={booking.voucher} />

            <InvoiceViewContainerComponent/>

        </PaymentMethodGroupsContainerBox>
    );
});


const PageBox = styled(BookingFlowPage)`
    & .${PAYMENT_PAGE_FOOTER_CSS_CLASS} {
        background-color: ${props => props.theme.colors.successVeryLight};
    }
`

export const PaymentPage: React.FC = observer(() => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();


    const renderContent = () => {
        return (
            <PaymentPageContentComponent/>
        );
    }

    const getFooterRenderer = () => {
        return () => {
            return (<PaymentPageFooterComponent/>)
        }
    }

    const replaceContinueButton = () => {
        if(!screenMediaQuery.smallScreen) {
            return undefined;
        }

        return () => services.booking.current.paymentHandler.paymentSelectionHandler.renderPayButton();

    }

    return (
        <PageBox disableSwipeNavigation={true}
                         headerOptions={{
                            title: services.language.translate('Payment')
                         }}
                         contentOptions={{
                             renderContent: renderContent,
                             maxContentWidth: '1280px',
                             hideCopyRight: true
                         }}
                         footerOptions={{
                             renderExtraFooterContent: getFooterRenderer(),
                             pageFooterClassName: PAYMENT_PAGE_FOOTER_CSS_CLASS
                         }}
                         shoppingCartOptions={{
                             hideContinueButton: true,
                             showPromotionCode: false,
                             replaceContinueButton: replaceContinueButton()
                         }}/>
    );
});
