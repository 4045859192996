import React from "react";
import styled from "styled-components";
import { useServices } from "../../../../hooks/use-services.hook";
import { CopyIcon } from "../../../../assets/svg/copy.icon";
import {observer} from "mobx-react-lite";
import {PrintTicketButtonComponent} from "../../../../components/print-ticket/print-ticket-button.component";

const FlightItineraryBookingReferenceBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
`;

const LeftSectionBox = styled.div`
    
`

const RightSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.bold};
    ${(props) => props.theme.containerMediaQuery.sAndBelow} {
        font-size: ${props => props.theme.fontSize.h6};
    }
`

const FlightItineraryBookingReferenceText = styled.div`
    font-size: ${props => props.theme.fontSize.h5};
`;

const FlightItineraryRecordLocatorBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: ${props => props.theme.spacing.flexGap16};
`;

const FlightItineraryRecordLocatorText = styled.div`
    font-size: ${props => props.theme.fontSize.h4};
    font-weight: ${props => props.theme.fontWeight.bold};
`;

const FlightItineraryCopyCodeBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap16};
`;

const FlightItineraryCopyCode: React.FC = observer(() => {
    const services = useServices();


    return(
        <FlightItineraryCopyCodeBox >
            <CopyIcon color={services.theme.currentTheme.colors.secondary}/>
        </FlightItineraryCopyCodeBox>
    )
});

export const FlightItineraryBookingReferenceComponent: React.FC = observer(() => {
    const services = useServices();

    return(
        <FlightItineraryBookingReferenceBox>
            <LeftSectionBox>
                <FlightItineraryBookingReferenceText>
                    {services.language.translate('Booking code')}
                </FlightItineraryBookingReferenceText>
                <FlightItineraryRecordLocatorBox>
                    <FlightItineraryRecordLocatorText>
                        {services.booking.current.recordLocator}
                    </FlightItineraryRecordLocatorText>
                    <FlightItineraryCopyCode/>
                </FlightItineraryRecordLocatorBox>
            </LeftSectionBox>
            <RightSectionBox>
                <PrintTicketButtonComponent booking={services.booking.current} hideLabel={false}/>
            </RightSectionBox>
        </FlightItineraryBookingReferenceBox>
    )
});
