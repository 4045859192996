import React from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {IonSkeletonText} from "@ionic/react";
import {useContainerMediaQueriesChecks} from "../../../../hooks/use-container-media-queries-checks.hook";

const RoundedIonSkeleton = styled(IonSkeletonText)`
    border-radius: ${props => props.theme.border.defaultRadius};
`

const SkeletonContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.spacing16};
    padding: ${props => props.theme.spacing.spacing16};
    align-items: center;
    height: 580px;
    width: min(100vw, 1024px);
    background-color: ${props => props.theme.colors.cardBackground};
    
    ${props => props.theme.containerMediaQuery.smallScreen} {
        width: 100%;
    }
`

const SwiperCalendarsSkeletonsContainerBox = styled.div`
    display: flex;
    gap: ${props => props.theme.spacing.spacing24};
    align-items: center;
    height: 100%;
    flex-direction: row;
    
`


const CalendarSkeletonBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 457px;
    height: 100%;
    gap: ${props => props.theme.spacing.spacing16};
    ${props => props.theme.containerMediaQuery.smallScreen} {
        width: 100%;
    }
`


const MonthNameSkeletonBox = styled(RoundedIonSkeleton)`
    width: 200px;
    height: 30px;
    margin-top: 15px;
    margin-bottom: 5px;
`

const CalendarDaysNamesSkeleton = styled(RoundedIonSkeleton)`
  width: 100%;
  height: 52px;

`

const CalendarDaysSkeletonBox = styled(RoundedIonSkeleton)`
    width: 100%;
    height: 100%;
`

const CalendarSkeletonComponent: React.FC = () => {
    return (
        <CalendarSkeletonBox>
            <MonthNameSkeletonBox animated/>
            <CalendarDaysNamesSkeleton animated/>
            <CalendarDaysSkeletonBox animated/>
        </CalendarSkeletonBox>
    );
}

const HeaderSkeletonBox = styled(RoundedIonSkeleton)`
    width: 100%;
    height: 60px;
`

const SwiperSkeletonsComponent: React.FC = observer(() => {
    return (
        <SkeletonContainerBox>
            <HeaderSkeletonBox animated/>
            <SwiperCalendarsSkeletonsContainerBox>
                <CalendarSkeletonComponent/>
                <CalendarSkeletonComponent/>
            </SwiperCalendarsSkeletonsContainerBox>

        </SkeletonContainerBox>
    )
})

const ListSkeletonsComponent: React.FC = observer(() => {
    return (
        <SkeletonContainerBox>
            <CalendarSkeletonComponent/>
        </SkeletonContainerBox>
    )
})


export const FlightDatesSelectorSkeletonsComponent: React.FC = observer(() => {
    const containerMediaQueries = useContainerMediaQueriesChecks();
    if(containerMediaQueries.smallScreen) {
        return (
            <ListSkeletonsComponent/>
        );
    } else {
        return (
            <SwiperSkeletonsComponent/>
        )
    }

});
