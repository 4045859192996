import styled, {css} from "styled-components";
import {IonIcon} from "@ionic/react";
import React from "react";
import {addCircleOutline, removeCircleOutline} from "ionicons/icons";
import {observer} from "mobx-react-lite";

const ButtonBox = styled.div<{$disabled: boolean}>`
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.primary};
    ${
        props => props.$disabled 
                ? css`
                    cursor: not-allowed;
                    opacity: 0.5;
                    color: ${props => props.theme.colors.medium};
                    visibility: hidden;
                `
                : css`
                    cursor: pointer;
                    opacity: 1;
                `
    }
`

const IconBox = styled(IonIcon)`
  width: 28px;
  height: 28px;
`

interface QuantityChangeButtonComponentProps {
    icon: string;
    disabled: boolean;
    onClick: () => void;
    className?: string;
}

const QuantityChangeButtonComponent: React.FC<QuantityChangeButtonComponentProps> = observer((props) => {
    return (
        <ButtonBox className={props.className}
                   onClick={props.onClick}
                   $disabled={props.disabled}>
            <IconBox icon={props.icon}/>
        </ButtonBox>
    );
})

interface QuantityChangeConcreteButtonComponentProps extends Omit<QuantityChangeButtonComponentProps, 'icon'> {
}

export const QuantityDecrementButtonComponent: React.FC<QuantityChangeConcreteButtonComponentProps> = observer((props) => {
    return (
        <QuantityChangeButtonComponent {...props} icon={removeCircleOutline}/>
    );
})


export const QuantityIncrementButtonComponent: React.FC<QuantityChangeConcreteButtonComponentProps> = observer((props) => {

    return (
        <QuantityChangeButtonComponent {...props} icon={addCircleOutline}/>
    );

})
