import React from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {useServices} from "../../../../../hooks/use-services.hook";
import {CardBox} from "../../../../../basic-components/card/card.box";
import {IJourneyViewModel} from "../../../../../services/booking/models/journey/journey-view-model.interface";
import {ManageMyBookingSectionTitleBox} from "../../boxes/manage-my-booking-section-title.box";
import {ManageMyBookingEditSectionButtonComponent} from "../edit-button/manage-my-booking-edit-section-button.component";
import {ManageMyBookingOneFlightComponent} from "./manage-my-booking-one-flight.component";
import {showFlightsToModifySelectionDialog} from "./show-flights-to-modify-selection-dialog";
import {NeutralButton} from "../../../../../basic-components/buttons/neutral-button";


const SectionContainerBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.pageContentVerticalPadding};
    width: 100%;
`

const SectionHeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    
`

const SectionBodyBox = styled.div<{$columnsCount: number}>`
    display: grid;
    grid-template-columns: repeat(${props => props.$columnsCount}, 1fr);
    gap: ${props => props.theme.spacing.flexGap16};
    width: 100%;
    align-items: flex-end;
    
    ${props => props.theme.containerMediaQuery.smallScreen} {
        grid-template-columns: 1fr;   
    }
`

const NeutralButtonBox = styled(NeutralButton)`
    border-color: ${props => props.theme.colors.darkTint};
    color: ${props => props.theme.colors.darkTint};
    padding: ${props => props.theme.spacing.spacing12} ${props => props.theme.spacing.spacing24};
`

const CheckInButtonComponent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;

    if(booking.hasPurchasesOnCurrentSession) {
        return null;
    }

    if(booking.filteredJourneys.all(j => j.isCanceled)) {
        return null;
    }

    if(!booking.filteredJourneys.some(j => (j.isOnlineCheckInAllowed && j.isOpenForCheckIn))) {
        return null;
    }

    if(booking.getAllPassengersSegments().all(p => p.isCheckedIn)) {
        return null;
    }

    const onClickHandler = async () => {
        const recordLocator = booking.recordLocator;
        if(!recordLocator) {
            return;
        }

        const goHome = () => {
            services.navigator.routes.home.activate({
                allowBack: false
            })
        }

        const email = booking.contact.fields.emailAddress.value;
        const lastName = booking.passengers[0]?.fields.lastName.value;
        if(email) {
            goHome();
            await services.booking.startCheckInByEmail({
                emailAddress: email,
                recordLocator: recordLocator
            });
        } else if(lastName) {
            goHome();
            await services.booking.startCheckInByLastName({
                lastName: lastName,
                recordLocator: recordLocator
            });
        }
    }

    return (
        <NeutralButtonBox onClick={onClickHandler}>
            Check-in Online
        </NeutralButtonBox>
    )
})

const HeaderButtonsContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap16};
    ${props => props.theme.containerMediaQuery.xsAndBelow} {
        flex-direction: column-reverse;
        gap: ${props => props.theme.spacing.flexGap20};
        
    }
`

export const ManageMyBookingFlightsComponent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;


    const onChangeFlightsClick = async () => {
        await showFlightsToModifySelectionDialog(services);
    }

    const renderOneJourney = (journey: IJourneyViewModel, index: number) => {
        return (
            <ManageMyBookingOneFlightComponent key={journey.journeyKey} journey={journey} index={index}/>
        )
    }

    const getTitle = () => {
        if(booking.unfilteredJourneys.length > 1) {
            return services.language.translate('Flights');
        } else {
            return services.language.translate('Flight');
        }
    }

    const getLabel = () => {
        if(booking.unfilteredJourneys.length > 1) {
            return services.language.translate('Change Flights');
        } else {
            return services.language.translate('Change Flight');
        }
    }


    return (
        <SectionContainerBox>
            <SectionHeaderBox>
                <ManageMyBookingSectionTitleBox>
                    {getTitle()}
                </ManageMyBookingSectionTitleBox>
                <HeaderButtonsContainerBox>
                    <CheckInButtonComponent/>
                    <ManageMyBookingEditSectionButtonComponent onClick={onChangeFlightsClick} label={getLabel()}/>
                </HeaderButtonsContainerBox>

            </SectionHeaderBox>
            <SectionBodyBox $columnsCount={booking.unfilteredJourneys.length}>
                {booking.unfilteredJourneys.map(renderOneJourney)}
            </SectionBodyBox>
        </SectionContainerBox>

    );
});
