import React, {PropsWithChildren} from "react";
import {FootNoteComponent} from "../../../../../components/foot-note/foot-note.component";
import styled from "styled-components";

const FootNoteBox = styled(FootNoteComponent)`
    text-align: center;
    font-size: ${props => props.theme.fontSize.subtitle2};
    font-weight: ${props => props.theme.fontWeight.medium};
`
export const BagDetailsComponent: React.FC<PropsWithChildren> = (props) => {
    return (
        <FootNoteBox>
            {props.children}
        </FootNoteBox>

    )
}