import {ISeatsRowsGroupViewModel} from "../../../../../services/booking/models/seat-maps/seats-rows-group-view-model.interface";
import React from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {SeatsRowComponent} from "./seats-row.component";
import {PriceComponent} from "../../../../../components/price/price.component";
import {StandardPriceLabelComponent} from "../../../../../components/price/standard-price-label.component";

const GroupHeaderBox = styled.div<{$firstRowHasExtraLegRoom: boolean; $isFirstGroup: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: ${props => props.theme.spacing.spacing16};
    width: 100%;
    grid-column: 2 / -2;
    font-size: ${props => props.theme.fontSize.h1};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.colors.dark};
    padding-top: ${props => props.theme.spacing.spacing8};
    padding-bottom: ${props => props.theme.spacing.spacing8};
    
    ${props => props.$firstRowHasExtraLegRoom
        ? css`
            padding-bottom: ${props => props.theme.spacing.spacing32};
        `    
        : css`
            
        `
    }
   
`


const GroupTitleBox = styled.div`
    
`;


interface SeatMapGroupComponentProps {
    title: string;
    group: ISeatsRowsGroupViewModel;
    groupIndex: number;
    seatsPerRowCount: number;
}

export const SeatMapGroupComponent: React.FC<SeatMapGroupComponentProps> = observer((props) => {

    const firstRowHasExtraLegRoom = props.group.rows[0]?.hasExtraLegRoom;
    return (
        <React.Fragment>
            <GroupHeaderBox $firstRowHasExtraLegRoom={firstRowHasExtraLegRoom} $isFirstGroup={props.groupIndex === 0}>
                <GroupTitleBox>{props.title} </GroupTitleBox>
                <PriceComponent price={props.group.minPrice} label={<StandardPriceLabelComponent/>}/>
            </GroupHeaderBox>
            {props.group.rows.map(row => <SeatsRowComponent key={row.rowNumber} row={row} seatsPerRowCount={props.seatsPerRowCount}/>)}
        </React.Fragment>

    )
});

