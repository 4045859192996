import React from "react";
import {IServiceFactory} from "../../../service-factory.interface";
import {ParentRouteModel} from "../../models/parent-route.model";
import {IFlightSearchRoutes} from "./flight-search.routes.interface";
import {ChildRouteModel} from "../../models/child-route.model";
import {FlightSearchDestinationSelectionPage} from "../../../../pages/flight-search/stations/flight-search-destination-selection.page";
import {FlightSearchPassengersTypesSelectionPage} from "../../../../pages/flight-search/passengers-types/flight-search-passengers-types-selection.page";
import {FlightSearchOriginSelectionPage} from "../../../../pages/flight-search/stations/flight-search-origin-selection.page";
import {FlightSearchDatesSelectionPage} from "../../../../pages/flight-search/flight-dates/flight-search-dates-selection.page";

export class FlightSearchRoutes extends ParentRouteModel implements IFlightSearchRoutes {
    constructor(services: IServiceFactory) {
        super('/flight-search', services);
    }
    originStation = new ChildRouteModel('/origin-station', this, () => <FlightSearchOriginSelectionPage/>);
    destinationStation = new DestinationStationRoute('/destination-station', this,() => <FlightSearchDestinationSelectionPage/>);
    flightDates = new FlightDatesRoute('/flight-date',  this,() => <FlightSearchDatesSelectionPage/>);
    passengers = new PassengersRoute('/passenger-types-selection', this,() => <FlightSearchPassengersTypesSelectionPage/>);

    protected _getDefaultChildRoute(): ChildRouteModel {
        return this.originStation;
    }
}

class DestinationStationRoute extends ChildRouteModel {
    activate() {
        if (this.services.flightSearch.searchController.departureOrigin) {
            super.activate();
        } else {
            this.services.navigator.routes.flightSearch.originStation.activate();
        }
    }
}

class FlightDatesRoute extends ChildRouteModel {
    activate() {
        if (this.services.flightSearch.searchController.departureDestination) {
            super.activate();
        } else {
            this.services.navigator.routes.flightSearch.originStation.activate();
        }
    }
}

class PassengersRoute extends ChildRouteModel {
    activate() {
        if (this.services.flightSearch.searchController.isOneWayDepartureTrip) {
            if (this.services.flightSearch.searchController.departureDate) {
                super.activate();
            } else {
                this.services.navigator.routes.flightSearch.flightDates.activate();
            }
        } else {
            if (this.services.flightSearch.searchController.returnDate) {
                super.activate();
            } else {
                this.services.navigator.routes.flightSearch.flightDates.activate();
            }
        }
    }
}
