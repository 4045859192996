import React from 'react';
import {AvailableTripComponent} from "./available-trip.component";
import styled from "styled-components";
import {observer} from "mobx-react";
import { useServices } from '../../../../../hooks/use-services.hook';
import { PlaneNotAvailableIcon } from '../../../../../assets/svg/plane-not-available.icon';
import {IAvailableTripViewModel} from "../../../../../services/flight-search/models/available-trip-view-model.interface";
import {CardBox} from "../../../../../basic-components/card/card.box";

const AvailableTripsListBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap40};
    ${props => props.theme.containerMediaQuery.smallScreen} {
        gap: ${props => props.theme.spacing.flexGap24};    
    }
    
`


const SoldOutBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 170px;
    font-weight: ${props => props.theme.fontWeight.semiBold};
    font-size: ${props => props.theme.fontSize.h4};
    background-color: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.secondaryContrast};
`

interface TripsListComponentProps {
    trips: IAvailableTripViewModel[];
}

export const AvailableTripsListComponent: React.FC<TripsListComponentProps> = observer((props) => {
    const services = useServices();

    const renderTripsList = () => {
        if (props.trips.length === 0) {
            return (<SoldOutBox>
                        <PlaneNotAvailableIcon size={1.7}/>
                        {services.language.translate('Sold Out')}
                    </SoldOutBox>);
        } else {
            return (
                <>
                    {
                        props.trips.map(trip => (
                            <AvailableTripComponent key={trip.date.toString()}
                                                    trip={trip}/>
                        ))
                    }
                </>
            )
        }
    }


    return (
        <AvailableTripsListBox>
            {renderTripsList()}
        </AvailableTripsListBox>
    );
});
