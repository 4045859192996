import {IConfigurationService, IDotRezApiSubscriptionKeyHeader} from "./configuration.service.interface";
import {
    ApplicationEnvironmentTypeEnum,
    IConfigurationObject,
    PaymentStrategyEnum
} from "./configuration-object.interface";
import {computed, makeObservable, observable, reaction, runInAction} from "mobx";
import {ServiceBase} from "../service-base";
import {IServiceFactory} from "../service-factory.interface";
import {TimeSpan} from "../../types/time-span";
import {NullableString} from "../../types/nullable-types";
import {EnvConfigs} from "./env.configs";
import {SsrsConfiguration} from "./objects/ssrs/ssrs.configuration";
import {AppVersionUpgradeModeEnum} from "../airline-webapi/responses/get-configurations.response";
import {NonEnvLocalStorageKeys} from "../storage/local-storage-keys";


const REGULAR_CONFIGS_REFRESH_INTERVAL = TimeSpan.fromMinutes(5);
const ACCELERATED_CONFIGS_REFRESH_INTERVAL = TimeSpan.fromSeconds(5);

//https://www.youtube.com/watch?v=NJwBNWwNlTk

export class ConfigurationService extends ServiceBase implements IConfigurationService {

    constructor(services: IServiceFactory) {
        super(services);

        this.envConfigs = new EnvConfigs();
        this._isHybridTestModeEnabled = ('true' === localStorage.getItem(NonEnvLocalStorageKeys.testModeEnabled));

        makeObservable(this, {
            data: observable,
            _isHybridTestModeEnabled: observable.ref,
            appVersionUpgradeMode: observable.ref,
            ssrs: computed,

        });

        reaction(() => this.services.language.currentLanguage,
            async () => {
                await this.loadConfigurations();
            });

    }

    readonly envConfigs: EnvConfigs;

    get currentEnvironmentType(): ApplicationEnvironmentTypeEnum {
        return this.envConfigs.currentEnvironmentType;
    }

    appVersionUpgradeMode: AppVersionUpgradeModeEnum = AppVersionUpgradeModeEnum.None;

    setPreferredApiOrigin(apiOrigin: string): void {

        apiOrigin = apiOrigin.toLowerCase();
        if(apiOrigin === this.airlineWebapiUrl.toLowerCase()) {
            return;
        }

        const initialApiOrigin = this.envConfigs.getInitialApiOrigin().toLowerCase();
        if(initialApiOrigin === apiOrigin) {
            localStorage.removeItem(NonEnvLocalStorageKeys.envConfigsPreferredApiOrigin);
        } else {
            localStorage.setItem(NonEnvLocalStorageKeys.envConfigsPreferredApiOrigin, apiOrigin);
        }

        this.services.window.reload();
    }

    get airlineWebapiUrl(): string {
        return this.envConfigs.airlineWebapiOrigin;
    }

    get dotRezApiUrl(): string {
        return this.data.dotRezApiUrl;
    }

    get dotRezApiSubscriptionKeyHeader(): IDotRezApiSubscriptionKeyHeader {
        return {
            "Ocp-Apim-Subscription-Key": this.data.dotRezApiSubscriptionKey
        };
    }

    data: IConfigurationObject = {
        airlineIataCode: "",
        enforceLoginOnBookingFlow: false,
        websiteHomeUrl: "",
        dotRezApiUrl: "",
        dotRezApiSubscriptionKey: "",
        dotRezSessionTimeout: "00:15:00",
        refreshConfigsInterval: "01:00:00",
        noBundleEarliestCheckIn: "3.00:00:00",
        maxAllowedPassengersNumberOnBooking: 9,
        maximumBagsQuantityPerPassenger: 4,
        enable10KgBag: false,
        enable32KgBag: false,
        enableEckn: false,
        analytics: {
            enableForWebsite: false,
            enableForMobileApp: false,
        },
        enableRandomSeatAllocation: false,
        vouchersRestrictedByPromotions: false,
        countries: [],
        stations: [],
        macs: [],
        carriers: [],
        italianRegions: [],
        specialPriceMarketsV2: {
            markets: []
        },
        supportedCurrencies: [
            {
                "code": "EUR",
                "name": "Euro"
            }
        ],
        systemCodesBlockedForFlightChange: [],
        minTimeToChangeDateBeforeFlight: "04:00:00",
        moveFlight: {
            enabled: false,
            disruptionMinDelayTime: "03:00:00",
            minimumFlightConnectionTime: "00:45:00",
            scheduleRestrictions: {
                daysBeforeFlight: 3,
                daysAfterFlight: 7
            }
        },
        ssrsConfig: {
            flex: {
                bundlesCutOffPeriods: [],
                inAllBundlesPeriods: []
            },
            eckn: {
                bundlesCutOffPeriods: [],
                inAllBundlesPeriods: []
            }
        },
        marketing: {
            enableLowFare: true,
            promotionalMessages: {
                loadingIndicatorTitle: "",
                loadingIndicatorMessage: ""
            }
        },
        translations: {
            errors: {}
        },
        appDownloadLinks: {
            ios: "https://apps.apple.com/us/app/aeroitalia/id6444920474",
            android: "https://play.google.com/store/apps/details?id=com.aeroitalia.mobile.app"
        },
        forbiddenVouchers: [],
        blueBenefits: {
            activationUrl: "",
            packages: {}
        },
        payments: {
            strategy: PaymentStrategyEnum.Webapi,
            savePaymentMethodInfo: false,
            validateCreditCardNumber: true,
            paymentMethods: [],
            statusTimeoutInSeconds: 180,
            abortTimeoutInSeconds: 60,
            googlePay: {
                enableOnAndroid: true,
                enableOnWindows: true
            },
            applePay: {
                enableOnIOS: true,
                enableOnMac: true
            },
            payPal: {
                clientId: "",
                environment: "production",
                intent: "authorize"
            }
        },
        virtualSsrsInBundle: {},
        insurance: {
            insuranceDaysForOneWayFlight: 8
        },
        callCenterPhoneNumber: "",
        companyAddress: "Aeroitalia S.r.l. Viale Cesare Pavese 8, 00144, Roma, Italia - P.IVA: 16340701008",
        cms: {
            baseUrl: "",
            pages: {
                pso: ""
            },
            mobileAppHomePageBanners: []
        },
        bundles: {},
        fees: []
    };
    private _refreshConfigsInterval: any = null;
    private _startRefreshConfigsTimer(refreshInterval?: TimeSpan) {
        if(!refreshInterval) {
            refreshInterval =  this.data.refreshConfigsInterval
                ? TimeSpan.parse(this.data.refreshConfigsInterval)
                : REGULAR_CONFIGS_REFRESH_INTERVAL;
        }


        this._refreshConfigsInterval = setInterval(async () => {
            await this.loadConfigurations();
        }, refreshInterval.totalMilliseconds);
    }

    private _stopRefreshConfigsTimer() {
        if(this._refreshConfigsInterval) {
            clearInterval(this._refreshConfigsInterval);
            this._refreshConfigsInterval = null;
        }
    }

    async loadConfigurations(): Promise<void> {
        this._stopRefreshConfigsTimer();
        try {
            const response = await this.services.airlineWebapi.getConfigurations(this.services.language.currentLanguage);

            runInAction(() => {
                this.data = response.configurations;
                this.appVersionUpgradeMode = response.appVersionUpgradeMode ?? AppVersionUpgradeModeEnum.None;
            });

            this._startRefreshConfigsTimer();

        } catch (err) {
            this.services.logger.error('Failed to load configurations', err);
            this._startRefreshConfigsTimer(ACCELERATED_CONFIGS_REFRESH_INTERVAL);
        }
    }

    get noBundleEarliestCheckIn(): TimeSpan {
        try {
            return TimeSpan.parse(this.data.noBundleEarliestCheckIn);
        } catch (err) {
            this.services.logger.error(`Failed to parse noBundleEarliestCheckIn ${this.data.noBundleEarliestCheckIn}`, err);
            return TimeSpan.fromDays(3);
        }
    }

    getErrorDescription(errorCode: NullableString): NullableString {
        if(!errorCode) {
            return errorCode;
        }
        if(this.data.translations.errors[errorCode]) {
            return this.data.translations.errors[errorCode]
        } else {
            return errorCode;
        }
    }

    get isProduction(): boolean {
        return this.envConfigs.isProduction;
    }

    get dotRezSessionTimeout(): TimeSpan {
        return TimeSpan.parse(this.data.dotRezSessionTimeout);
    }

    get ssrs(): SsrsConfiguration {

        if(this.data.ssrsConfig) {
            return new SsrsConfiguration(this.data.ssrsConfig, this.services);
        }

        return new SsrsConfiguration({
            flex: {
                bundlesCutOffPeriods: [],
                inAllBundlesPeriods: []
            },
            eckn: {
                bundlesCutOffPeriods: [],
                inAllBundlesPeriods: []
            }
        }, this.services);
    }


    getBundleVirtualSsrs(bundleCode: string): string[] {
        if(this.data.virtualSsrsInBundle) {
            return this.data.virtualSsrsInBundle[bundleCode] ?? [];
        }

        return [];

    }

    get defaultCountryCode(): string {
        return "IT";
    }

    _isHybridTestModeEnabled: boolean;
    get isHybridTestModeEnabled(): boolean {
        return this._isHybridTestModeEnabled;

    }

    enableHybridTestMode(password: string): boolean {
        if(password === "Aeroitalia#123") {
            localStorage.setItem(NonEnvLocalStorageKeys.testModeEnabled, "true");
            runInAction(() => {
                this._isHybridTestModeEnabled = true;
            })
            return true;
        } else {
            this.services.alert.showError("Invalid password");
            return false;
        }

    }

    get  isAnalyticsEnabled(): boolean{
        try {
            return this.services.device.isHybrid ?
                this.data.analytics.enableForMobileApp:
                this.data.analytics.enableForWebsite;
        }
        catch (err) {
            return false;
        }

    }

}

