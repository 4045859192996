import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../../../hooks/use-services.hook";

const DangerousGoodsImgBox = styled.img`
    width: 100%;
    height: auto;
`

export const DangerousGoodsAgreementContentComponent: React.FC = observer(() => {
    const services = useServices();
    return (
        <DangerousGoodsImgBox src={services.theme.currentTheme.assets.images.dangerous_goods_jpg.toString()}/>
    );
})