import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {
    IFlightDesignatorViewModel
} from "../../../../services/booking/models/designator/flight-designator-view-model.interface";
import {useServices} from "../../../../hooks/use-services.hook";


const TravellingTimeBox = styled.div`
    text-align: right;
    color: ${props => props.theme.colors.notesTint};
    ${props => props.theme.containerMediaQuery.smallScreen} {
        text-align: center;
    }
`


interface JourneyTravelTimeComponentProps {
    journeyDesignator: IFlightDesignatorViewModel;
}

export const JourneyTravelTimeComponent: React.FC<JourneyTravelTimeComponentProps> = observer((props) => {
    const services = useServices();

    return (
        <TravellingTimeBox>
            {services.time.formatTravelTime(props.journeyDesignator.travelTime)}
        </TravellingTimeBox>
    )
})