import {IServiceFactory} from "../../../../services/service-factory.interface";
import {makeObservable, observable, runInAction} from "mobx";
import {MutableRefObject} from "react";

export interface IBoardingPassExportAction {
    readonly isExportInProgress: boolean;
    export(elementRef: MutableRefObject<HTMLDivElement | null>): Promise<void>;
}

export abstract class BoardingPassExportActionBase implements IBoardingPassExportAction {
    constructor(protected readonly services: IServiceFactory) {
        makeObservable(this, {
            isExportInProgress: observable.ref
        });
    }

    protected abstract _executeExport(element: HTMLDivElement): Promise<void>;

    isExportInProgress: boolean = false;

    private _waitForElement(elementRef: MutableRefObject<HTMLDivElement | null>): Promise<HTMLDivElement> {
        return new Promise<HTMLDivElement>(resolve => {
            const intervalRef = setInterval(() => {
                if(elementRef.current) {
                    clearInterval(intervalRef);
                    resolve(elementRef.current)
                }
            }, 100);
        })
    }

    export(elementRef: MutableRefObject<HTMLDivElement | null>): Promise<void> {
        if(this.isExportInProgress) {
            return Promise.resolve();
        }

        runInAction(() => {
            this.isExportInProgress = true;
        });

        return new Promise<void>((resolve, reject) => {
            setTimeout(async () => {
                try {
                    const element = await this._waitForElement(elementRef);
                    await this._executeExport(element);
                    resolve();

                } catch (err) {
                    this.services.logger.error('Failed to export boarding pass to PDF', err);
                    reject(err);
                } finally {
                    runInAction(() => {
                        this.isExportInProgress = false;
                    });
                }
            }, 1000);
        })
    }
}

export class PrintBoardingPassAction extends BoardingPassExportActionBase {
    constructor(private readonly fileName: string,
                services: IServiceFactory) {
        super(services);
    }

    protected async _executeExport(element: HTMLDivElement): Promise<void> {
        await this.services.pdfExporter.printPdf({
            element: element,
            fileName: this.fileName
        });
    }


}

export class ShareBoardingPassAction extends BoardingPassExportActionBase {
    constructor(private readonly fileName: string,
                private readonly subject: string,
                private readonly message: string,
                services: IServiceFactory) {
        super(services);
    }

    protected async _executeExport(element: HTMLDivElement): Promise<void> {
        await this.services.pdfExporter.sharePdf({
            element: element,
            fileName: this.fileName,
            message: this.message,
            subject: this.subject
        });
    }
}
