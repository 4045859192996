import React from 'react';
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";
//fill={props.color? props.color: "currentColor"}
export const PlaneTicketsSimpleIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.8} {...props} sizeIsHeight viewBox="0 0 24 24" ratio={24 / 24}>
            <g clipPath="url(#clip0_37763_8824)">
                <path
                    d="M13.4808 9.71726L11.6699 10.1424L7.60627 7.71627C6.6975 7.17434 6.24728 8.31656 6.89593 8.90185L9.09702 10.7511L5.89712 11.5014L5.35186 11.153C4.273 10.4493 4.4631 11.4214 4.64819 12.1384L4.81495 12.8321C4.98169 13.5691 5.77538 13.399 6.16891 13.3173L9.50386 12.5453L8.36001 15.1399C8.03652 15.9536 8.94694 16.779 9.52722 15.8903L12.0735 11.9466L14.0561 11.4864C16.4773 10.9245 14.7481 9.42879 13.4808 9.71726Z"
                    fill={props.color? props.color: "currentColor"}/>
                <path
                    d="M21.4212 3.29443H2.57863C1.92646 3.29488 1.30112 3.55415 0.83996 4.0153C0.378805 4.47646 0.119582 5.10175 0.119141 5.75392V18.0933C0.119582 18.7455 0.378805 19.3708 0.83996 19.832C1.30112 20.2931 1.92646 20.5523 2.57863 20.5528H21.4212C22.0733 20.5523 22.6987 20.2931 23.1598 19.832C23.621 19.3708 23.8802 18.7455 23.8807 18.0933V5.75392C23.8802 5.10175 23.621 4.47646 23.1598 4.0153C22.6987 3.55415 22.0733 3.29488 21.4212 3.29443ZM21.8413 18.1C21.84 18.211 21.7953 18.3171 21.7168 18.3956C21.6383 18.4741 21.5322 18.5188 21.4212 18.5201H18.5614C18.5243 18.434 18.4627 18.3606 18.3843 18.309C18.306 18.2574 18.2142 18.2299 18.1203 18.2299C18.0265 18.2299 17.9348 18.2574 17.8564 18.309C17.778 18.3606 17.7164 18.434 17.6793 18.5201H2.57033C2.45929 18.5188 2.35315 18.4741 2.27463 18.3956C2.1961 18.3171 2.15141 18.211 2.15011 18.1V5.76064C2.15141 5.6496 2.1961 5.54346 2.27463 5.46493C2.35315 5.38641 2.45929 5.34171 2.57033 5.34041H17.6393V5.76064C17.6393 5.888 17.6899 6.01015 17.78 6.10021C17.87 6.19027 17.9922 6.24086 18.1195 6.24086C18.2469 6.24086 18.369 6.19027 18.4591 6.10021C18.5492 6.01015 18.5998 5.888 18.5998 5.76064V5.34041H21.4212C21.5322 5.34171 21.6383 5.38641 21.7168 5.46493C21.7953 5.54346 21.84 5.6496 21.8413 5.76064V18.1Z"
                    fill={props.color? props.color: "currentColor"}/>
                <path
                    d="M18.1214 7.43311C17.994 7.43311 17.8718 7.4837 17.7818 7.57376C17.6917 7.66382 17.6411 7.78596 17.6411 7.91333V9.35237C17.6411 9.47974 17.6917 9.60188 17.7818 9.69194C17.8718 9.78201 17.994 9.8326 18.1214 9.8326C18.2488 9.8326 18.3708 9.78201 18.4609 9.69194C18.551 9.60188 18.6017 9.47974 18.6017 9.35237V7.91333C18.6017 7.78596 18.551 7.66382 18.4609 7.57376C18.3708 7.4837 18.2488 7.43311 18.1214 7.43311Z"
                    fill={props.color? props.color: "currentColor"}/>
                <path
                    d="M18.1214 14.6299C17.994 14.6299 17.8718 14.6805 17.7818 14.7705C17.6917 14.8606 17.6411 14.9828 17.6411 15.1102V16.5492C17.6411 16.6765 17.6917 16.7987 17.7818 16.8888C17.8718 16.9788 17.994 17.0294 18.1214 17.0294C18.2488 17.0294 18.3708 16.9788 18.4609 16.8888C18.551 16.7987 18.6017 16.6765 18.6017 16.5492V15.1102C18.6017 14.9828 18.551 14.8606 18.4609 14.7705C18.3708 14.6805 18.2488 14.6299 18.1214 14.6299Z"
                    fill={props.color? props.color: "currentColor"}/>
                <path
                    d="M18.1214 11.0312C17.994 11.0312 17.8718 11.0818 17.7818 11.1719C17.6917 11.262 17.6411 11.3842 17.6411 11.5115V12.9505C17.6411 13.0779 17.6917 13.2001 17.7818 13.2901C17.8718 13.3802 17.994 13.4308 18.1214 13.4308C18.2488 13.4308 18.3708 13.3802 18.4609 13.2901C18.551 13.2001 18.6017 13.0779 18.6017 12.9505V11.5115C18.6017 11.3842 18.551 11.262 18.4609 11.1719C18.3708 11.0818 18.2488 11.0312 18.1214 11.0312Z"
                    fill={props.color? props.color: "currentColor"}/>
            </g>
            <defs>
                <clipPath id="clip0_37763_8824">
                    <rect width="23.7616" height="22.7" fill="white" transform="translate(0.119141 0.57373)"/>
                </clipPath>
            </defs>
        </SvgIcon>
    )
}
