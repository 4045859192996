import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {globeOutline} from "ionicons/icons";
import {IonIcon} from "@ionic/react";
import {ExternalLinkComponent} from "../external-link/external-link.component";
import {useServices} from "../../hooks/use-services.hook";
import {EXTERNAL_LINK_CSS_CLASS_NAME} from "../../services/external-links/external-links.service.interface";

const WebContactFormBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
`

const ExternalLinkBox = styled(ExternalLinkComponent)`
    & a.${EXTERNAL_LINK_CSS_CLASS_NAME} {
        font-size: ${props => props.theme.fontSize.h5};
        font-weight: ${props => props.theme.fontWeight.semiBold};
        color: ${props => props.theme.colors.dialogContentColor};
    }
`

export const WebContactFormComponent: React.FC = observer(() => {
    const services = useServices();

    return (
        <WebContactFormBox>
            <IonIcon md={globeOutline} ios={globeOutline}/>
            <ExternalLinkBox innerHtml={services.externalLinks.contactForm}/>
        </WebContactFormBox>
    );
})