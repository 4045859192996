import React from "react";
import {observer} from "mobx-react";
import {PassengerBoardingPassesGroupSelector} from "../models/passengers-boarding-passes-group-selector";
import {PassengerBoardingPassesSelectorComponent} from "./passenger-boarding-passes-selector.component";
import {
    IPassengerSegmentBoardingPassViewModel
} from "../../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import styled from "styled-components";
import {CardBox} from "../../../../basic-components/card/card.box";

const GroupBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    padding: 0;
    
    
`

const GroupHeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: ${props => props.theme.fontSize.h2};
    background-color: ${props => props.theme.colors.lightShade};
    color: ${props => props.theme.colors.lightContrast};
    padding: ${props => props.theme.spacing.spacing12};
    border-top-left-radius: ${props => props.theme.border.defaultRadius};
    border-top-right-radius: ${props => props.theme.border.defaultRadius};
`

const GroupContentBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap16};
    padding: ${props => props.theme.spacing.cardDefaultPadding};
`

const GroupItemsBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap16};
`



interface PassengersBoardingPassesGroupSelectorComponentProps {
    group: PassengerBoardingPassesGroupSelector;
    renderAddToWalletButton: (selectedBoardingPasses: IPassengerSegmentBoardingPassViewModel[]) => React.ReactElement;
}
export const PassengersBoardingPassesGroupSelectorComponent: React.FC<PassengersBoardingPassesGroupSelectorComponentProps> = observer((props) => {

    return (
        <GroupBox>
            <GroupHeaderBox>{props.group.title}</GroupHeaderBox>
            <GroupContentBox>
                <GroupItemsBox>
                    {props.group.selectors.map(s => <PassengerBoardingPassesSelectorComponent key={s.uniqueKey} passengerSelector={s}/>)}
                </GroupItemsBox>
                {props.renderAddToWalletButton(props.group.getSelectedBoardingPasses())}
            </GroupContentBox>

        </GroupBox>
    )
})