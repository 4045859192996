import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {BookingCodeComponent} from "../../../components/booking-code/booking-code.component";
import {useServices} from "../../../hooks/use-services.hook";


const PageTitleContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`


export const ManageMyBookingDashboardHeaderTitleLargeScreenComponent: React.FC = observer(() => {
    const services = useServices();
    return (
        <PageTitleContainerBox>
            <div>{services.language.translate('Manage my booking')}</div>
            <BookingCodeComponent recordLocator={services.booking.current.recordLocator}/>
        </PageTitleContainerBox>
    )
})