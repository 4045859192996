import { FormModel } from "../../models/forms/form.model";
import { NullableString } from "../../types/nullable-types";
import {FormFields} from "../../models/forms/form-field.interface";

export interface IUserSettingsFormFields {
    readonly language:NullableString;
    readonly currency:NullableString;
}

export class UserSettingsFormModel extends FormModel<IUserSettingsFormFields> {
    protected _createFields(): FormFields<IUserSettingsFormFields> {
        return {
            currency: this._createField({
                fieldName: () => this.services.language.translate('Currency'),
                initialValue: () => this.services.currency.current

            }),
            language: this._createField({
                fieldName: () => this.services.language.translate('Language'),
                initialValue: () => this.services.language.currentLanguage
            })
        }
    }
}
