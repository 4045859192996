import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {StandardDialogPageComponent} from "../../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogContentComponent} from "../../../../components/modal/content/standard-dialog-content.component";
import {StandardDialogHeaderComponent} from "../../../../components/modal/header/standard-dialog-header.component";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import {SsrsSelectionComponent} from "./ssrs-selection.component";
import {SsrsSelectionComponentProps} from "./ssrs-selection.component.props";
import styled from "styled-components";
import {StandardDialogTitleComponent} from "../../../../components/modal/header/standard-dialog-title.component";
import {SameForAllFlightsToggleComponent} from "../same-for-all-flights-toggle.component";
import {IJourneyViewModel} from "../../../../services/booking/models/journey/journey-view-model.interface";
import {SsrsSelectionJourneysTabsComponent} from "./ssrs-selection-journeys-tabs.component";
import {SsrsSelectionDialogFooterComponent} from "./ssrs-selection-dialog-footer.component";
import {reaction} from "mobx";


const HeaderContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${props => props.theme.spacing.flexGap20};
    
`

const HeaderUpperSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${props => props.theme.spacing.flexGap16};
    
    width: 100%;
`

const IconContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`

const SsrInfoBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    gap: ${props => props.theme.spacing.spacing16};
    width: 100%;
`

const SsrDescriptionBox = styled.div`
    font-size: ${props => props.theme.fontSize.body1};
    color: ${props => props.theme.colors.mediumShade};
`

const SsrInfoLeftSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    gap: ${props => props.theme.spacing.flexGap8};
`

const SsrInfoRightSectionBox = styled.div`
    
`

export interface SsrsSelectionDialogComponentProps extends Omit<SsrsSelectionComponentProps, 'journey'> {
    renderTitle: () => (string | React.ReactElement);
    renderSubtitle: () => (React.ReactElement | string | null );
    dialogHandler: IDialogConfirmationHandler;
    renderIcon?: () => React.ReactElement | null;
}


export const SsrsSelectionDialogComponent: React.FC<SsrsSelectionDialogComponentProps> = observer((props) => {
    const journeys = props.ssrsAggregator.journeysSsrsBuckets.map(j => j.parentJourney);
    const [currentJourney, setCurrentJourney] = useState(journeys[0]);
    const {renderTitle, dialogHandler, renderSubtitle, renderIcon, ...ssrsSelectionComponentProps} = props;

    useEffect(() => {
        const r = reaction(() => props.ssrsAggregator.useSameOptionsForAllFlights,
            () => setCurrentJourney(props.ssrsAggregator.journeysSsrsBuckets[0].parentJourney));

        return () => r();
    }, [props.ssrsAggregator.journeysSsrsBuckets, props.ssrsAggregator.useSameOptionsForAllFlights]);

    const renderSameForAllFlights = () => {
        if(!props.ssrsAggregator.allowUseSameOptionsForAllFlights) {
            return null;
        }

        return (
            <SsrInfoRightSectionBox>
                <SameForAllFlightsToggleComponent ssrsAggregator={props.ssrsAggregator} checkboxLabelPlacement={"stacked"}/>
            </SsrInfoRightSectionBox>
        );
    }

    const onJourneyTabClick = (journey: IJourneyViewModel) => {
        setCurrentJourney(journey)
    }



    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={dialogHandler}
                                           showBottomBorder={!props.ssrsAggregator.useSameOptionsForAllFlights ? 'always' : 'onScroll'}>
                <HeaderContentBox>
                    <HeaderUpperSectionBox>
                        <IconContainerBox>
                            {renderIcon && renderIcon()}
                        </IconContainerBox>
                        <SsrInfoBox>
                            <SsrInfoLeftSectionBox>
                                <StandardDialogTitleComponent>
                                    {renderTitle()}
                                </StandardDialogTitleComponent>
                                <SsrDescriptionBox>
                                    {renderSubtitle()}
                                </SsrDescriptionBox>
                            </SsrInfoLeftSectionBox>

                            {renderSameForAllFlights()}

                        </SsrInfoBox>
                    </HeaderUpperSectionBox>

                    <SsrsSelectionJourneysTabsComponent ssrsAggregator={props.ssrsAggregator}
                                                  journeys={journeys}
                                                  currentJourney={currentJourney}
                                                  hasScroll={props.dialogHandler.hasScroll}
                                                  onJourneyTabClick={onJourneyTabClick}/>
                </HeaderContentBox>

            </StandardDialogHeaderComponent>
            <StandardDialogContentComponent dialogHandler={props.dialogHandler}>
                <SsrsSelectionComponent {...ssrsSelectionComponentProps} journey={currentJourney} />
            </StandardDialogContentComponent>

            <SsrsSelectionDialogFooterComponent ssrsAggregator={props.ssrsAggregator}
                                                dialogHandler={props.dialogHandler}
                                                currentSelectedJourney={currentJourney}
                                                onGoToJourney={(j) => setCurrentJourney(j)}/>

        </StandardDialogPageComponent>
    )
});
