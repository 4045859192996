import {SeatFilterBaseModel} from "./seat-filter-base.model";
import {SeatModel} from "../seat.model";
import React from "react";
import {PetIcon} from "../../../../../assets/svg/pet.icon";

export class PetSeatFilterModel extends SeatFilterBaseModel {

    get description(): string {
        return this.services.language.translate('Seats for pets');
    }

    shouldAllowSeat(seat: SeatModel): boolean {
        return seat.allowsPet;
    }

    renderIcon(): React.ReactElement {
        return (<PetIcon size={1.4}/>);
    }
}
