import {IDotRezLeg} from "../../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {LegStatusEnum} from "../../../../dot-rez-api/data-contracts/enums/leg-status.enum";
import {IServiceFactory} from "../../../../service-factory.interface";
import {FlightDesignatorModel} from "../../designator/flight-designator.model";
import {computed, makeObservable} from "mobx";


export abstract class LegBaseModel {

    constructor() {
        makeObservable(this, {
            designator: computed
        });
    }

    protected abstract get legDotRezData(): IDotRezLeg;
    protected abstract get services(): IServiceFactory;

    get designator(): FlightDesignatorModel {
        return FlightDesignatorModel.createLegDesignator(this.legDotRezData, this.services);
    }

    get isCanceled(): boolean {
        return this.legDotRezData.legInfo.status === LegStatusEnum.Canceled;
    }

    get standardDepartureTime(): Date {
        return this.services.time.parseIsoDate(this.legDotRezData.legInfo.departureTime);
    }

    get flightReference(): string {
        return this.legDotRezData.flightReference;
    }


}
