import React from 'react';
import {CreateAnimation} from "@ionic/react";
import { useServices } from '../../../hooks/use-services.hook';
import {observer} from "mobx-react";
import {RoutedPageComponent} from "../../../basic-components/page/routed-page/routed-page.component";
import {DEFAULT_ANIMATION_TIME} from "../../../global-constants";
import {HYBRID_HOME_PAGE_ID} from "./hybrid-home-page-constants";
import {HomePageHybridContentComponent} from "./content/home-page-hybrid-content.component";
import styled from "styled-components";
import {HybridHomePageFooterComponent} from "./footer/hybrid-home-page-footer.component";
import {HybridPageSideMenuComponent} from "./side-menu/hybrid-page-side-menu.component";

const ContentContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
`

export const HybridHomePage: React.FC = observer(() => {
    const services = useServices();
    const currentTab = services.homePage.currentTab;

    return (
        <>
            <HybridPageSideMenuComponent/>
            <RoutedPageComponent id={HYBRID_HOME_PAGE_ID} disableSwipeNavigation={true}>
                {currentTab.renderHeader()}
                <HomePageHybridContentComponent>
                    <CreateAnimation key={currentTab.text}
                                     duration={DEFAULT_ANIMATION_TIME}
                                     iterations={1}
                                     play={true}
                                     fromTo={{
                                         property: 'transform',
                                         fromValue: 'translateX(100%)',
                                         toValue: 'translateX(0)'
                                     }}>
                        <ContentContainerBox>
                            {currentTab.renderContent()}
                        </ContentContainerBox>

                    </CreateAnimation>
                </HomePageHybridContentComponent>
                <HybridHomePageFooterComponent/>
            </RoutedPageComponent>
        </>
    );
})
