import classNames from 'classnames';
export const SSR_COLORED_IN_BUNDLE = 'colored';
export const SSR_BOLDED_IN_BUNDLE = 'bold';

interface ISsrTranslationFormatter {
    colored(text: string, bolded?: boolean): string;
    coloredBold(text: string): string;
    bolded(text: string): string;
}

export class SsrTranslationFormatter {
    static create(useHtml: boolean): ISsrTranslationFormatter {
        if(useHtml) {
            return new SsrHtmlTranslationFormatter();
        } else {
            return new SsrPlainTextTranslationFormatter();
        }
    }
}


class SsrHtmlTranslationFormatter implements ISsrTranslationFormatter {
    colored = (text: string) => {
        return `<span class="${SSR_COLORED_IN_BUNDLE}">${text}</span>`;
    }

    coloredBold = (text: string) => {
        const cssClasses =  classNames(SSR_COLORED_IN_BUNDLE, SSR_BOLDED_IN_BUNDLE);
        return `<span class="${cssClasses}">${text}</span>`;
    }

    bolded = (text: string) => {
        return `<span class="${SSR_BOLDED_IN_BUNDLE}">${text}</span>`;
    }
}

class SsrPlainTextTranslationFormatter implements ISsrTranslationFormatter {
    bolded(text: string): string {
        return text;
    }

    colored(text: string, bolded?: boolean): string {
        return text;
    }

    coloredBold(text: string): string {
        return text;
    }

}
