import React from 'react';
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {FlightSearchStationSelectionPage} from "./flight-search-station-selection.page";
import {PlaneDepartingLineFilledIcon} from "../../../assets/svg/plane-departing-line-filled.icon";

export const FlightSearchOriginSelectionPage: React.FC = observer(() => {
    const services = useServices();
    const searchController = services.flightSearch.searchController;
    return (
        <FlightSearchStationSelectionPage titleText={services.language.translate('Departing From')}
                                          plainIcon={<PlaneDepartingLineFilledIcon size={1.6}/>}
                                          stations={searchController.availableDepartureStations}
                                          recentlySearchedStations={searchController.recentlySearchedOrigins}
                                          onStationSelected={ async (station) => {
                                  searchController.returnDate = null;
                                  searchController.departureDate = null;
                                  searchController.departureDestination = null;
                                  searchController.departureOrigin = station;
                                  await services.flightSearch.steps.nextStep();
                              }} />
    );
});
