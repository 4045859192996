import React, {useState} from "react";
import {observer} from "mobx-react";
import {
    IJourneyHistoryViewModel
} from "../../../../../../../services/booking-history/models/journey-history-view-model.interface";
import {FlightCardContentBox} from "../../../../common/flight-card/flight-card-content.box";
import {FlightCardTopSectionComponent} from "../../../../common/flight-card/flight-card-top-section.component";
import {FlightCardMiddleSectionComponent} from "../../../../common/flight-card/flight-card-middle-section.component";
import {PrimaryButton} from "../../../../../../../basic-components/buttons/primary-button";
import {useServices} from "../../../../../../../hooks/use-services.hook";
import {ActionSheetButton} from "@ionic/react";
import {ActionsSheetComponent} from "../../../../../../../basic-components/action-sheet/action-sheet.component";



interface FutureFlightCardComponentProps {
    flight: IJourneyHistoryViewModel;
}
export const FutureFlightCardComponent: React.FC<FutureFlightCardComponentProps> = observer((props) => {
    const services = useServices();
    const [actions, setActions] = useState<ActionSheetButton[]>([]);

    const onDetailsButtonClick = async () => {
        const actions = await props.flight.getActions();
        const actionSheetButtons = actions.map(action => {
            const button: ActionSheetButton = {
                text: action.text,
                handler: action.execute
            }
            return button;
        });

        actionSheetButtons.push({
            text: services.language.translate('Cancel'),
            role: "cancel"
        });

        setActions(actionSheetButtons);
    }

    return (
        <FlightCardContentBox id={props.flight.uniqueKey}>
            <FlightCardTopSectionComponent flight={props.flight} />
            <FlightCardMiddleSectionComponent flight={props.flight}/>
            <PrimaryButton fullWidth={true} showArrow={true} onClick={onDetailsButtonClick}>
                {services.language.translate('See your options')}
            </PrimaryButton>
            <ActionsSheetComponent isOpen={actions.length > 0}
                          mode="ios"
                          onDidDismiss={() => setActions([])}
                          buttons={actions}/>
        </FlightCardContentBox>
    )
})