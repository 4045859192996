import React from 'react';
import {observer} from "mobx-react-lite";
import {StandardDialogPageComponent} from "../modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../modal/header/standard-dialog-header.component";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import {useServices} from "../../hooks/use-services.hook";
import {
    StandardDialogContentComponent
} from "../modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../modal/footer/standard-dialog-footer.component";
import {ContinueButtonComponent} from "../continue-button/continue-button.component";
import styled from "styled-components";
import {ExclamationDiamondIcon} from "../../assets/svg/exclamation-diamond.icon";

const DialogContentContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    flex-grow: 1;
    font-size: ${props => props.theme.fontSize.h2};
    font-weight: ${props => props.theme.fontWeight.bold};
    gap: ${props => props.theme.spacing.spacing48};
`


interface ErrorMessageDialogComponentProps {
    message: string;
    dialogHandler: IDialogConfirmationHandler;
}

export const ErrorMessageDialogComponent: React.FC<ErrorMessageDialogComponentProps> = observer((props) => {
    const services = useServices();
    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler}/>
            <StandardDialogContentComponent dialogHandler={props.dialogHandler} fullContentHeight={true}>
                <DialogContentContainerBox>
                    <ExclamationDiamondIcon size={5.65} color={services.theme.currentTheme.colors.danger}/>
                    <div>
                        {props.message}
                    </div>
                </DialogContentContainerBox>

            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <ContinueButtonComponent onClick={() => props.dialogHandler.accept()} fullWidth={true}/>
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    )
})
