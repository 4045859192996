import React, {useEffect, useRef} from "react";
import {IFormField} from "../../../models/forms/form-field.interface";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {NullableDate, NullableUndefinedDate} from "../../../types/nullable-types";
import styled from "styled-components";
import {
    FieldEditorCommonProps,
    FieldInputContainerComponentProps,
    InputContainerCssClassesForOutsideBordersStyle
} from "../inputs-common.props";
import {FieldInputContainerComponent} from "../field-input-container.component";
import {DateFieldEditorModel} from "./date-field-editor.model";
import {YearFieldEditorComponent} from "./year/year-field-editor.component";
import {MonthFieldEditorComponent} from "./month/month-field-editor.component";
import {DayFieldEditorComponent} from "./day/day-field-editor.component";
import {reaction} from "mobx";



const InputContainerCssClassesForOutsideBorders: InputContainerCssClassesForOutsideBordersStyle = {
    inputAndIconContainer: 'date-field-input-and-icon-container'
}

const ContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    width: 100%;
    gap: ${props => props.theme.spacing.spacing8};
`

const FieldInputContainer = styled(FieldInputContainerComponent)`
    & .${InputContainerCssClassesForOutsideBorders.inputAndIconContainer} {
        border: none;
        padding: 0;
        
    }
`

const YearBox = styled(YearFieldEditorComponent)`
    max-width: 30%;
`

const MonthBox = styled(MonthFieldEditorComponent)`
    max-width: 60%;
`

const DayBox = styled(DayFieldEditorComponent)`
    max-width: 30%;
`


interface DateFieldEditorComponentProps extends FieldEditorCommonProps {
    field: IFormField<NullableDate>;
    minimumValue?: NullableUndefinedDate;
    maximumValue?: NullableUndefinedDate

}
export const DateFieldEditorComponent: React.FC<DateFieldEditorComponentProps> = observer((props) => {
    const services = useServices();
    const datePartsRef = useRef(new DateFieldEditorModel(props.field, props.minimumValue, props.maximumValue, services));

    useEffect(() => {
        const disposeReaction = reaction(() => props.field.error, () => {
            datePartsRef.current.activateErrorsValidation();
        });

        return () => {
            disposeReaction();
        };

    }, [props.field.error])

    if(props.field.isHidden) {
        return null;
    }

    const renderEmptyLabel = () => {
        return null;
    }

    const renderInput = () => {
        return (<ContainerBox>
            <YearBox field={datePartsRef.current.fields.year}
                     minYear={datePartsRef.current.minYear}
                     maxYear={datePartsRef.current.maxYear}
                     placeholder={datePartsRef.current.fields.year.fieldName}
                     renderLabel={renderEmptyLabel}
                     hideErrorMessage={true}/>

            <MonthBox field={datePartsRef.current.fields.month}
                      displayStyle={"monthName"}
                      minMonth={datePartsRef.current.minMonth}
                      maxMonth={datePartsRef.current.maxMonth}
                      placeholder={datePartsRef.current.fields.month.fieldName}
                      renderLabel={renderEmptyLabel}
                      hideErrorMessage={true}/>

            <DayBox field={datePartsRef.current.fields.day}
                    month={datePartsRef.current.fields.month.value}
                    year={datePartsRef.current.fields.year.value}
                    minDay={datePartsRef.current.minDay}
                    maxDay={datePartsRef.current.maxDay}
                    placeholder={datePartsRef.current.fields.day.fieldName}
                    renderLabel={renderEmptyLabel}
                    hideErrorMessage={true}/>

        </ContainerBox>);
    }

    const fieldContainerProps: FieldInputContainerComponentProps = {
        ...props,
        cssClassesForOutsideBordersStyle: {
            ...InputContainerCssClassesForOutsideBorders,
            ...props.cssClassesForOutsideBordersStyle
        },
        renderInput: renderInput
    }

    return (
        <FieldInputContainer {...fieldContainerProps}/>
    );
});
