import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";

const WidthConstrainWrapperBox = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100%;
`

const WidthConstrainBox = styled.div<{$maxPageContentWidth: string;}>`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex-grow: 1;
    width: min(100%, ${props => props.$maxPageContentWidth});
`

interface BookingFlowPageWidthConstrainComponentProps extends PropsWithChildren{
    maxPageContentWidth: string;
}
export const BookingFlowPageWidthConstrainComponent: React.FC<BookingFlowPageWidthConstrainComponentProps> = observer((props) => {

    return (
        <WidthConstrainWrapperBox>
            <WidthConstrainBox $maxPageContentWidth={props.maxPageContentWidth}>
                {props.children}
            </WidthConstrainBox>
        </WidthConstrainWrapperBox>
    )
})