import styled from "styled-components";

export const BagsSelectionContainerBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: ${props => props.theme.spacing.spacing24};
    gap: ${props => props.theme.spacing.flexGap20};
    ${props => props.theme.containerMediaQuery.smallScreen} {
        padding: ${props => props.theme.spacing.cardDefaultPadding};
    }
`