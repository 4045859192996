import React from 'react';
import {IServiceFactory} from "../../../service-factory.interface";
import { CheckInOnlineSmallIcon } from './check-in-online.small-icon';
import {CknSsrBase} from "./ckn-ssr-base";
import {
    SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE, SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE,
    SSR_LARGE_ICON_SIZE
} from "../../ssr-types.service.interface";

export default class PcknSsrType extends CknSsrBase {
    constructor(services: IServiceFactory) {
        super('PCKN', services);
    }

    get smallIcon(): React.ReactElement | null {
        return (<CheckInOnlineSmallIcon/>);
    }

    get largeIcon(): React.ReactElement | null {
        return (<CheckInOnlineSmallIcon size={SSR_LARGE_ICON_SIZE}/>);
    }

    get iconInExtrasFirstLargeTile(): React.ReactElement | null {
        return (<CheckInOnlineSmallIcon size={SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE}/>);
    }

    get iconInExtrasSmallTile(): React.ReactElement | null {
        return (<CheckInOnlineSmallIcon size={SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE}/>);
    }

}
