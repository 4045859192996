import React from "react";
import {IonIcon} from "@ionic/react";
import {arrowForwardOutline, arrowUpOutline} from "ionicons/icons";
import styled, {css} from "styled-components";
import {useServices} from "../../../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import {ExclamationDiamondIcon} from "../../../../../assets/svg/exclamation-diamond.icon";


const MustChooseContainerBox = styled.div<{$hasError: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    font-size: ${props => props.theme.fontSize.caption};
    font-weight: ${props => props.theme.fontWeight.medium};
    color: ${props => props.$hasError ? props.theme.colors.danger : props.theme.colors.mediumTint};
    line-height: 1;
    
    ${props => props.$hasError 
            ? css`
                font-weight: ${props => props.theme.fontWeight.bold};
            `   
            : css`
                font-weight: ${props => props.theme.fontWeight.medium};
            `
    }
    
`

interface MustChooseAnOptionComponentProps {
    arrowDirection: 'forward' | 'up'
    hasError: boolean;
}
export const MustChooseAnOptionComponent: React.FC<MustChooseAnOptionComponentProps> = observer((props) => {
    const services = useServices();

    if(!services.booking.current.shouldValidateBags) {
        return null;
    }

    const renderIcon = () => {
        if(!props.hasError) {
            return null;
        }
        return (
            <ExclamationDiamondIcon/>
        )
    }

    return (
        <MustChooseContainerBox $hasError={props.hasError}>
            {renderIcon()}
            <span>
                {services.language.translate('You must choose an option')}
            </span>
            <IonIcon icon={props.arrowDirection === 'forward' ? arrowForwardOutline : arrowUpOutline}/>
        </MustChooseContainerBox>
    );
})