import React from "react";
import styled from "styled-components";
import { ShoppingCartComponent } from "../../../components/shopping-cart/shopping-cart.component";
import { useServices } from "../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import {PrimaryButton} from "../../../basic-components/buttons/primary-button";
import {BookingFlowPage} from "../booking-flow.page";
import {PriceComponent} from "../../../components/price/price.component";
import {useScreenMediaQueriesChecks} from "../../../hooks/use-screen-media-queries-checks.hook";
import {FinalPriceSummaryComponent} from "../payment/final-price-summary.component";
import {FinalPriceTermsAndConditionsToggleComponent} from "./final-price-terms-and-conditions-toggle.component";

const MAX_PAGE_CONTENT_WIDTH = '812px'

const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.spacing24};
`

const PageTitleContainerBox = styled.div`
    font-size: ${props => props.theme.fontSize.h1};
    font-weight: ${props => props.theme.fontWeight.bold};
    gap: ${props => props.theme.spacing.flexGap8};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

const FinalPriceAmountBox = styled(PriceComponent)`
    color: ${props => props.theme.colors.primary};
`

const FooterRootBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`


const FooterContentBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    line-height: 1;
    width: min(100%, ${MAX_PAGE_CONTENT_WIDTH});
    ${props => props.theme.containerMediaQuery.xsAndBelow} {
        flex-direction: column;
        gap: ${props => props.theme.spacing.flexGap12};
    }
`




const ContinueButton = styled(PrimaryButton)`
    font-size: ${props => props.theme.fontSize.h4};
`

export const FinalPricePage: React.FC = observer(() => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();

    if(!services.booking.currentPageCanBeRendered) {
        return null;
    }

    const booking = services.booking.current;

    const onClick = async () => {
        await services.booking.steps.nextStep();
    }

    const getPageTitle = () => {
        if(!services.booking.currentPageCanBeRendered) {
            return "";
        }

        return(
            <PageTitleContainerBox>
                <span>{services.language.translate('Final Price')}</span>
                <FinalPriceAmountBox price={booking.balanceDue}/>
            </PageTitleContainerBox>

        )
    }

    const renderContent = () => {
        return (
            <ContentBox>
                <ShoppingCartComponent showSectionsBoxShadow={true}/>
                <FinalPriceSummaryComponent/>
            </ContentBox>

        );
    }

    const renderFooter = () => {
        let buttonText = services.language.translate('Continue with payment');
        if(booking.balanceDue.amount === 0 && !booking.voucher.isApplied) {
            buttonText = services.language.translate('Save changes');
        }

        return (
            <FooterRootBox>
                <FooterContentBox>
                    <FinalPriceTermsAndConditionsToggleComponent/>
                    <ContinueButton disabled={!services.booking.current.travelConditionsAccepted} onClick={onClick}>
                        {buttonText}
                    </ContinueButton>
                </FooterContentBox>
            </FooterRootBox>


        );
    }

    return (
        <BookingFlowPage headerOptions={{
                            title: getPageTitle(),
                            hideWizardProgressIndicator: screenMediaQuery.smallScreen
                         }}
                         contentOptions={{
                             renderContent: renderContent,
                             maxContentWidth: MAX_PAGE_CONTENT_WIDTH
                         }}
                         shoppingCartOptions={{
                             hideShoppingCart: true,
                             hideContinueButton: true,
                             showPromotionCode: false
                         }}
                         footerOptions={{
                             renderExtraFooterContent: renderFooter
                         }}/>
    );
});
