import React from "react";
import {observer} from "mobx-react";
import {
    FlightsDatesSelectorModel
} from "../../../../services/flight-search/dates-selection/flights-dates-selector.model";
import {
    LowFareDisclaimerMessageForDropDownComponent
} from "../../../low-fare-disclaimer-message/lowfare-disclamer-message.component";
import {FlightDirectionSelectorComponent} from "../../flight-direction/flight-direction-selector.component";
import styled from "styled-components";

const ContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
`

interface FlightsDatesSelectionFooterComponentProps {
    flightsDatesSelector: FlightsDatesSelectorModel;
    onDone: () => void;
    buttonText?: string;
}
export const FlightsDatesSelectionFooterComponent: React.FC<FlightsDatesSelectionFooterComponentProps> = observer((props) => {
    return (
        <ContainerBox>
            <FlightDirectionSelectorComponent flightsDatesSelector={props.flightsDatesSelector} onDone={props.onDone} buttonText={props.buttonText}/>
            <LowFareDisclaimerMessageForDropDownComponent/>
        </ContainerBox>
    )
})