import React from "react";
import {FlightSearchPage} from "../flight-search.page";
import {useServices} from "../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useIonViewDidEnter} from "@ionic/react";
import {FlightsDatesSelectorModel} from "../../../services/flight-search/dates-selection/flights-dates-selector.model";
import {FlightSearchTitleComponent} from "../components/flight-search-title.component";
import {CalendarIcon} from "../../../assets/svg/calendar.icon";
import {
    LowFareDisclaimerMessageForDropDownComponent
} from "../../../components/low-fare-disclaimer-message/lowfare-disclamer-message.component";
import {
    FlightDirectionSelectorComponent
} from "../../../components/flight-search/flight-direction/flight-direction-selector.component";
import {
    FlightsDatesSelectionHeaderComponent
} from "../../../components/flight-search/dates-selection/header/flights-dates-selection-header.component";
import {
    FlightDatesSelectorComponent
} from "../../../components/flight-search/dates-selection/flight-dates-selector.component";

const PAGE_CONTENT_CSS_CLASS = "flight-search-dates-selection-page-content";

const PageBox = styled(FlightSearchPage)` 
    & .${PAGE_CONTENT_CSS_CLASS} {
        --padding-top: 0;
        --padding-bottom: 0;
        --padding-start: 0;
        --padding-end: 0;
    }
`

const FooterContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
`


const FlightDatesSelectionPageFooter: React.FC<{flightsDatesSelector: FlightsDatesSelectorModel}> = observer((props) => {
    const services = useServices();

    if (props.flightsDatesSelector.scheduleLoadingError || props.flightsDatesSelector.isLoadingSchedule) {
        return null;
    }

    const onContinue = async () => {
        await services.flightSearch.steps.nextStep();
    }

    return (
        <FooterContentBox>
            <FlightDirectionSelectorComponent flightsDatesSelector={props.flightsDatesSelector} onDone={onContinue}/>
            <LowFareDisclaimerMessageForDropDownComponent/>
        </FooterContentBox>
    );
});

export const FlightSearchDatesSelectionPage: React.FC = observer(() => {
    const services = useServices();
    const flightSearchController = services.flightSearch.searchController;
    const flightsDatesSelector =flightSearchController.datesSelector;


    let selectedDepartureDateElement: HTMLElement | null = null;

    const captureSelectedDepartureDateElementRef = (date: Date, element: HTMLElement | null) => {
        if(flightsDatesSelector.isDepartureDate(date)) {
            selectedDepartureDateElement = element;
        }
    }

    const getTitle = () => {
        if(flightSearchController.isOneWayDepartureTrip) {
            return services.language.translate('Flight date');
        } else {
            return services.language.translate('Flights dates');
        }
    }


    useIonViewDidEnter(() => {
        if(!services.navigator.routes.flightSearch.flightDates.isActive) {
            return;
        }

        if(selectedDepartureDateElement) {
            selectedDepartureDateElement.scrollIntoView({
                block: "center"
            });
        }
    });

    return (
        <PageBox title={<FlightSearchTitleComponent
                          title={getTitle()}
                          icon={<CalendarIcon size={1.4}/>}/>}
                          pageContentCssClass={PAGE_CONTENT_CSS_CLASS}
                          renderToolbar={() => <FlightsDatesSelectionHeaderComponent flightsDatesSelector={flightsDatesSelector}/>}
                          renderFooter={() => <FlightDatesSelectionPageFooter flightsDatesSelector={flightsDatesSelector}/>}>
            <FlightDatesSelectorComponent captureSelectedDateElementRef={captureSelectedDepartureDateElementRef}
                                          flightsDatesSelector={flightsDatesSelector}/>
        </PageBox>
    );
});
