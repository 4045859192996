import React from "react";
import {observer} from "mobx-react";
import {PlaneGoIcon} from "../../../../assets/svg/plane-go.icon";
import {useServices} from "../../../../hooks/use-services.hook";
import {
    IFlightDesignatorViewModel
} from "../../../../services/booking/models/designator/flight-designator-view-model.interface";

export const PlaneGoComponent: React.FC<{designator: IFlightDesignatorViewModel | null}> = observer((props) => {
    const services = useServices();

    let color = services.theme.currentTheme.colors.dark;
    if(props.designator?.flightIdentifier?.getOperatedByMessage()) {
        color = services.theme.currentTheme.colors.tertiaryTint;
    }
    return (
        <PlaneGoIcon color={color} size={1.05} sizeIsHeight={true}/>
    );
})