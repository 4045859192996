import React from "react";
import {observer} from "mobx-react";
import {FlightsDatesSelectorModel} from "../../../../../services/flight-search/dates-selection/flights-dates-selector.model";
import {IDropDownController} from "../../../../forms/drop-down/models/drop-down-controller.interface";
import {EmptyDropDownMessageComponent} from "../../../../forms/drop-down/empty-drop-down-message.component";
import {useScreenMediaQueriesChecks} from "../../../../../hooks/use-screen-media-queries-checks.hook";
import {FlightDatesSelectorComponent} from "../../flight-dates-selector.component";
import styled, {css} from "styled-components";
import {NullableString} from "../../../../../types/nullable-types";

const ContainerBox = styled.div<{$hasFlightScheduleLoadingError: boolean}>`
    background-color: ${props => props.theme.colors.lightShade};
    
    ${props => props.theme.screenMediaQuery.smallScreen} {
        width: 100%;
    }
    
    ${props => props.$hasFlightScheduleLoadingError 
        ? css`
                background-color: ${props => props.theme.colors.cardBackground};
                
                min-width: 385px;
                ${props => props.theme.screenMediaQuery.smallScreen} {
                    min-width: unset;
                    width: 100%;
                }
        ` 
        : css`
                background-color: ${props => props.theme.colors.lightShade};
                width: min(100vw, 1026px);
        `
    }
`

interface FlightDatesDropDownContentComponentProps {
    datesSelector: FlightsDatesSelectorModel;
    dropDownController: IDropDownController;
    emptyDropDownMessage: NullableString;
}

export const FlightDatesDropDownContentComponent: React.FC<FlightDatesDropDownContentComponentProps> = observer((props) => {
    const screenMediaQuery = useScreenMediaQueriesChecks();
    const datesSelector = props.datesSelector;


    if(props.emptyDropDownMessage) {
        return (
            <EmptyDropDownMessageComponent message={props.emptyDropDownMessage}/>
        )
    }


    const captureSelectedDepartureDateElementRef = (date: Date, element: HTMLElement | null) => {
        if(screenMediaQuery.smallScreen && datesSelector.isDepartureDate(date)) {
            //we don't do this scroll into view when we are in swiper
            //because it will mess up the swiper
            //scrollElementIntoViewLazySmooth(element); //TODO - this one needs to be done only the first time drop down is open.
        }
    }

    const onDone = () => {
        props.dropDownController.closeDropDown();
    }

    return (
        <ContainerBox $hasFlightScheduleLoadingError={Boolean(datesSelector.scheduleLoadingError)}>
            <FlightDatesSelectorComponent flightsDatesSelector={datesSelector}
                                          captureSelectedDateElementRef={captureSelectedDepartureDateElementRef}
                                          swiperOptions={{
                                              onDone: onDone
                                          }}/>
        </ContainerBox>

    );
});
