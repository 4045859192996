import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {IBookingInsuranceViewModel} from "../../../../services/booking/models/insurance/booking-insurance-view-model.interface";
import {useServices} from "../../../../hooks/use-services.hook";
import {ExternalLinkComponent} from "../../../../components/external-link/external-link.component";
import { CheckedSelectedIcon } from "../../../../assets/svg/checked-selected.icon";

const BenefitsContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 4px;
    width: 100%;
`

const BenefitsItemBox = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    width: 100%;
    
`

const BenefitsItemIconBox = styled.div`
`

const BenefitsItemDescriptionBox = styled.div`
    flex-grow: 1;
    font-size: ${props => props.theme.fontSize.h5};
    
`


const BenefitsItemComponent: React.FC<{description: string}> = observer((props) => {
    const services = useServices();
    return (
        <BenefitsItemBox>
            <BenefitsItemIconBox>
                <CheckedSelectedIcon size={1.2} color={services.theme.currentTheme.colors.success}/>
            </BenefitsItemIconBox>
            <BenefitsItemDescriptionBox>
                {props.description}
            </BenefitsItemDescriptionBox>
        </BenefitsItemBox>
    )
});

export const InsuranceBenefitsComponent: React.FC<{insurance: IBookingInsuranceViewModel}> = observer((props) => {
    const services = useServices();
    const renderLink = () => {
        if(props.insurance.quote.policyInfoDocumentUrl) {
            return (
                <ExternalLinkComponent innerHtml={services.externalLinks.insurancePolicyInfoDocument(props.insurance.quote.policyInfoDocumentUrl, services.booking.current.contact.fields.countryCode.value!)}/>
            );
        }

        return null;
    }
    return (
        <BenefitsContainerBox>
            {props.insurance.quote.benefits.map(b => <BenefitsItemComponent key={b} description={b}/>)}
            {renderLink()}
        </BenefitsContainerBox>
    )
});
