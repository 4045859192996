import {IRoute} from "../../../navigation/models/route.interface";
import {YesNoDialogPrimaryButton, YesNoDialogResult} from "../../../dialog-factory/yes-no-dialog.enums";
import {
    IOnBeforeActivationContext,
    IOnBeforeDeactivationContext
} from "../../../../models/wizard/wizard.step.interface";
import {waitForBookingMutations} from "../../models/mutation-actions/wait-for-booking-mutations";
import {ManageMyBookingStepBase} from "../common/manage-my-booking-step.base";
import {DialogResult} from "../../../dialog/dialog-enums";
import {runInAction} from "mobx";

export class ManageMyBookingDashboardStep extends ManageMyBookingStepBase {

    get title(): string {
        return this.services.language.translate('Manage my booking');
    }

    get route(): IRoute {
        return this.routes.dashboard;
    }

    private async _discardChanges(): Promise<void> {
        if(this.booking.hasChangedSegments) {
            await this.services.loadingIndicator.execute({
                action: async () => {
                    await this.booking.manageMyBooking.discardChanges();
                }
            });
        }
    }

    protected async _onBeforeActivation(context: IOnBeforeActivationContext): Promise<DialogResult> {
        if(context.previousStep === null) {
            return DialogResult.Accepted;
        }

        if(!this.booking.hasChangedSegments) {
            return DialogResult.Accepted;
        }


        let yesNoResult = await this.services.dialogFactory.showYesNoDialog({
            title: this.services.language.translate('Confirmation'),
            message: this.services.language.translate('This action will discard your changes on this booking on the current session. Are you sure you want to continue ?'),
            primaryButton: YesNoDialogPrimaryButton.PrimaryButtonNo,
            noButtonText: this.services.language.translate(`Don't discard my changes`),
            yesButtonText: this.services.language.translate(`Discard my changes`)
        });

        if(yesNoResult === YesNoDialogResult.No) {
            return DialogResult.Rejected;
        }

        await this._discardChanges();
        return DialogResult.Accepted
    }

    protected async _onBeforeDeactivation(context: IOnBeforeDeactivationContext): Promise<DialogResult> {
        if(context.routingGuardContext.isForwardNavigation) {
            return DialogResult.Accepted;
        }

        const services = this.services;
        if(!this.booking.hasPurchasesOnCurrentSession) {
            return DialogResult.Accepted;
        }

        const dialogResult = await services.dialogFactory.showYesNoDialog({
            primaryButton: YesNoDialogPrimaryButton.PrimaryButtonYes,
            title: services.language.translate('Did you forget something?'),
            yesButtonText: services.language.translate('Resume shopping'),
            noButtonText: services.language.translate('Discard changes'),
            message: services.language.translate('Looks like you have some unsaved changes. Are you sure you want to discard them?')
        });

        if(dialogResult === YesNoDialogResult.Yes) {
            return DialogResult.Rejected;
        }

        return DialogResult.Accepted;
    }

    async next(): Promise<void> {
        await waitForBookingMutations(this.booking);
        if(this.booking.hasPurchasesOnCurrentSession) {
            runInAction(() => {
                this.booking.manageMyBooking.startRegularFlow();
                this._activateStepByRoute(this.routes.finalPrice);
            });

        } else {
            this.services.alert.showError(this.services.language.translate(`You cannot continue to the next step because you didn't purchase any new service.`));
        }
    }
}
