export const SWIPER_WRAPPER_CSS_CLASS = 'swiper-wrapper';
export const SWIPER_SLIDE_CSS_CLASS = 'swiper-slide';
export const SWIPER_SLIDE_ACTIVE_CSS_CLASS = 'swiper-slide-active';
export const SWIPER_NAV_BUTTON_CSS_CLASS = 'swiper-nav-button';
export const SWIPER_NAV_BUTTON_PREV_CSS_CLASS = 'swiper-nav-button-prev';
export const SWIPER_NAV_BUTTON_NEXT_CSS_CLASS = 'swiper-nav-button-next';
export const SWIPER_NAV_BUTTON_DISABLED_CSS_CLASS = 'swiper-button-disabled';
export const SWIPER_PAGINATION_CSS_CLASS = 'swiper-pagination';
export const SWIPER_PAGINATION_BULLET_CSS_CLASS = 'swiper-pagination-bullet';
export const SWIPER_PAGINATION_BULLET_ACTIVE_CSS_CLASS = 'swiper-pagination-bullet-active';




