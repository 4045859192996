import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../../../hooks/use-services.hook";
import { menuController } from '@ionic/core/components';
import {HYBRID_HOME_PAGE_SIDE_MENU_ID} from "../hybrid-home-page-constants";
const SideMenuContentBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`

const MenuItemsContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap12};
    width: 100%;
    flex-grow: 1;
    
    font-size: ${props => props.theme.fontSize.h1};
    font-weight: ${props => props.theme.fontWeight.bold};
    padding: 20% 
             ${props => props.theme.spacing.pageContentHorizontalPadding} 
             ${props => props.theme.spacing.pageContentVerticalPadding} 
             ${props => props.theme.spacing.pageContentHorizontalPadding};
`

const MenuItemBox = styled.div`
    padding: ${props => props.theme.spacing.spacing16} 
            ${props => props.theme.spacing.spacing16} 
            ${props => props.theme.spacing.spacing16} 
            0;
`

const MenuItemComponent: React.FC<{label: string; action: () => void}> = observer((props) => {

    const onClick = async () => {
        props.action();
        await menuController.close(HYBRID_HOME_PAGE_SIDE_MENU_ID);

    }

    return (
        <MenuItemBox onClick={onClick}>
            {props.label}
        </MenuItemBox>
    )
})

export const HybridPageSideMenuContentComponent: React.FC = observer(() => {
    const services = useServices();
    return (
        <SideMenuContentBox>
            <MenuItemsContainerBox>
                <MenuItemComponent label={services.language.translate('Home')}
                                   action={() => services.homePage.activateBookTab()}/>

                <MenuItemComponent label={services.language.translate('Check-in')}
                                   action={() => services.homePage.activateCheckInTab()}/>

                <MenuItemComponent label={services.language.translate('Book Travel')}
                                   action={() => services.flightSearch.startWizardSearch()}/>

                <MenuItemComponent label={services.language.translate('My Trips')}
                                   action={() => services.homePage.activateMyBookingsTab()}/>

            </MenuItemsContainerBox>
        </SideMenuContentBox>
    );
})