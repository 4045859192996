import React from "react";

export const PlaneWingRightSvg: React.FC<{color?: string;}> = (props) => {
    return (
        <svg width="1568" height="1075" viewBox="0 0 1568 1075" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1404.93 759.506C1377.96 745.355 468.317 284.481 468.317 284.481C468.317 284.481 327.688 214.5 242.233 169.966C156.778 125.432 45.5146 38.8108 0.000732422 0V645.842H427.416C547.027 679.156 617.621 698.858 719.887 727.358L1427.88 925.767C1492.79 945.1 1510.2 958.234 1522.86 972.943C1532.99 984.682 1556.63 1045.37 1567.19 1074.23V1027.83C1566.82 1019.36 1566.57 1011.57 1566.34 1004.3L1566.33 1004.15L1566.33 1004.15C1565.06 964.331 1564.25 939.119 1547.4 894.057C1527.44 840.703 1457.76 787.229 1404.93 759.506Z"
                fill={props.color? props.color: "currentColor"}/>
        </svg>

    )
}
