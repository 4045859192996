import React, {useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react-lite";
import {ISegmentSeatMapEditorViewModel} from "../../../../../services/booking/models/seat-maps/segment-seat-map-editor-view-model.interface";
import styled, {css} from "styled-components";
import {SeatMapGroupComponent} from "../seats/seats-group.component";
import {useContainerMediaQueriesChecks} from "../../../../../hooks/use-container-media-queries-checks.hook";
import {PlaneWingLeftSvg} from "../../../../../assets/svg/plane-wing-left.svg";
import {PlaneWingRightSvg} from "../../../../../assets/svg/plane-wing-right.svg";
import {useServices} from "../../../../../hooks/use-services.hook";
import {CockpitComponent} from "../cockpit/cockpit.component";
import {
    ATR_SEATS_PER_ROW_COUNT,
    CSS_VALUE_EMERGENCY_ROW_MARKER_WIDTH,
    CSS_VALUE_SEAT_HEIGHT_LARGE,
    CSS_VALUE_SEAT_HEIGHT_SMALL,
    CSS_VALUE_SEAT_WIDTH_LARGE,
    CSS_VALUE_SEAT_WIDTH_SMALL, CSS_VAR_EXTRA_LEG_ROOM_SEAT_INDICATOR,
    CSS_VAR_SEAT_FONT_SIZE,
    CSS_VAR_SEAT_HEIGHT,
    CSS_VAR_SEAT_WIDTH
} from "../seat-map-css-constants";
import {RowsLettersComponent} from "../rows-letters/rows-letters.component";

const SeatMapBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    line-height: 1;
    width: fit-content;
`

function computeStyleBasedOnSeatsCount(seatsPerRowsCount: number) {



    const gridTemplateColumns = [
        CSS_VALUE_EMERGENCY_ROW_MARKER_WIDTH,
        ...new Array(seatsPerRowsCount + 1).fill('1fr'),
        CSS_VALUE_EMERGENCY_ROW_MARKER_WIDTH
    ].join(" ");

    if(seatsPerRowsCount <= ATR_SEATS_PER_ROW_COUNT) {

        return css`
            grid-template-columns: ${gridTemplateColumns};
            column-gap: ${props => props.theme.spacing.spacing24};
            ${CSS_VAR_SEAT_WIDTH}: ${CSS_VALUE_SEAT_WIDTH_LARGE};
            ${CSS_VAR_SEAT_HEIGHT}: ${CSS_VALUE_SEAT_HEIGHT_LARGE};
            ${CSS_VAR_SEAT_FONT_SIZE}: ${props => props.theme.fontSize.h2};
            ${CSS_VAR_EXTRA_LEG_ROOM_SEAT_INDICATOR}: calc(0.85 * var(${CSS_VAR_SEAT_HEIGHT}, ${CSS_VALUE_SEAT_HEIGHT_SMALL}));
            
        `
    } else {
        return css`
            grid-template-columns: ${gridTemplateColumns};
            column-gap: ${props => props.theme.spacing.spacing20};
            ${CSS_VAR_SEAT_WIDTH}: ${CSS_VALUE_SEAT_WIDTH_SMALL};
            ${CSS_VAR_SEAT_HEIGHT}: ${CSS_VALUE_SEAT_HEIGHT_SMALL};
            ${CSS_VAR_SEAT_FONT_SIZE}: ${props => props.theme.fontSize.h4};
            ${CSS_VAR_EXTRA_LEG_ROOM_SEAT_INDICATOR}: calc(0.85 * var(${CSS_VAR_SEAT_HEIGHT}, ${CSS_VALUE_SEAT_HEIGHT_SMALL}));
        `
    }
}

function getAircraftBodyImage(): string {
    return 'data:image/svg+xml;utf8,%3Csvg%20width%3D%22560%22%20height%3D%223881%22%20viewBox%3D%220%200%20560%203881%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0.56005%201342.69C0.56005%20986.49%200.556774%20562.164%200.56005%20430.582C0.563326%20299%20148.317%206.96182e-05%20279.733%206.96182e-05L279.73%200C411.146%200%20559.168%20299%20559.172%20430.582C559.175%20562.164%20559.172%20986.49%20559.172%201342.69V2043V3753.96C559.172%203753.96%20552.499%203836.71%20519.285%203880.2H40.4463C7.23288%203836.71%200.56005%203753.96%200.56005%203753.96V2043V1342.69Z%22%20fill%3D%22%23F5F6F9%22%2F%3E%3C%2Fsvg%3E';
}

const AircraftBodyGridBox = styled.div<{$seatsPerRowsCount: number}>`
    display: grid;
    align-items: flex-end;
    justify-content: center;
    justify-items: center;
    padding-top: 70px;
    padding-bottom: ${props => props.theme.spacing.spacing16};
    background-image: url("${getAircraftBodyImage()}");
    background-size: cover;
    background-position-y: -80px;
    background-repeat: no-repeat;
    border: none;
    margin-bottom: ${props => props.theme.spacing.spacing16};
    border-radius: ${props => props.theme.border.defaultRadius};
    ${props => computeStyleBasedOnSeatsCount(props.$seatsPerRowsCount)};
    row-gap: ${props => props.theme.spacing.spacing16};
    
    ${props => props.theme.screenMediaQuery.xsAndBelow} {
        padding-top: 0;
        background-position-y: -160px;
    }
`

const WingBox = styled.div<{$top: number; $scaleY: number}>`
    position: absolute;
    color: ${props => props.theme.colors.pageContentBackground};
    top: ${props => props.$top}px;
`

const LeftWingBox = styled(WingBox)`
    left: 0;
    transform: translateX(calc(-100% + 3px)) scaleY(${props => props.$scaleY});
`

const RightWingBox = styled(WingBox)`
    right: 0;
    transform: translateX(calc(100% - 3px)) scaleY(${props => props.$scaleY});
    
`



interface SeatMapComponentProps {
    seatMap: ISegmentSeatMapEditorViewModel;
}

export const SeatMapComponent: React.FC<SeatMapComponentProps> = observer((props) => {
    const services = useServices();
    const mediaQuery = useContainerMediaQueriesChecks();
    const seatMap = props.seatMap;
    const seatMapRef = useRef<HTMLDivElement>(null);
    const [firstSeatOnWingElement, setFirstSeatOnWingElement] = useState<HTMLElement | null>(null);
    const [lastSeatOnWingElement, setLastSeatOnWingElement] = useState<HTMLElement | null>(null);
    const [wingTop, setWingTop] = useState(0);
    const [wingScaleY, setWingScaleY] = useState(0);

    const seatsPerRowCount = seatMap.seatsPerRowCount;

    const shouldDrawWings = !mediaQuery.smallScreen && Boolean(wingTop) && seatsPerRowCount > ATR_SEATS_PER_ROW_COUNT;

    useEffect(() => {
        let interval: any = null;

        const destroyInterval = () => {
            if(interval) {
                clearInterval(interval);
                interval = null;
            }
        }

        if(!firstSeatOnWingElement && !lastSeatOnWingElement) {
            /*here we are waiting for the seats elements to be created */
            interval = setInterval(() => {
                const firstSeatOnWing = seatMap.firstLeftSeatOnWing;
                const lastSeatOnWing = seatMap.lastLeftSeatOnWing;
                if(firstSeatOnWing && lastSeatOnWing) {
                    const firstSeatElement = services.document.getElementById(firstSeatOnWing.seatKey);
                    const lastSeatElement = services.document.getElementById(lastSeatOnWing.seatKey);
                    if(firstSeatElement && lastSeatElement) {
                        destroyInterval();
                        setFirstSeatOnWingElement(firstSeatElement);
                        setLastSeatOnWingElement(lastSeatElement);
                    }
                }
            }, 100);
        }


        return () => {
            destroyInterval();
        }
    }, [firstSeatOnWingElement, lastSeatOnWingElement, seatMap.firstLeftSeatOnWing, seatMap.lastLeftSeatOnWing, services.document]);


    useEffect(() => {
        const seatMapElement = seatMapRef.current;
        const wingBaseDesignedHeight = 650; //the height of the wing base (the part attached to the plane) as designed in the svg
        if(seatMapElement && firstSeatOnWingElement && lastSeatOnWingElement) {
            const seatMapTop = seatMapElement.getBoundingClientRect().top;
            const firstSeatTop = firstSeatOnWingElement.getBoundingClientRect().top;
            const lastSeatBottom = lastSeatOnWingElement.getBoundingClientRect().bottom;
            const computedWingBaseHeight = lastSeatBottom - firstSeatTop;
            const wingTop = (firstSeatTop - seatMapTop) + (computedWingBaseHeight - wingBaseDesignedHeight);
            const scaleY = computedWingBaseHeight / wingBaseDesignedHeight;

            setWingTop(wingTop);
            setWingScaleY(scaleY);
        }

    }, [firstSeatOnWingElement, lastSeatOnWingElement]);

    const renderLeftWing = () => {
        if(!shouldDrawWings) {
            return null;
        }
        return (
            <LeftWingBox $top={wingTop} $scaleY={wingScaleY}>
                <PlaneWingLeftSvg/>
            </LeftWingBox>
        )
    }

    const renderRightWing = () => {
        if(!shouldDrawWings) {
            return null;
        }

        return (
            <RightWingBox $top={wingTop} $scaleY={wingScaleY}>
                <PlaneWingRightSvg/>
            </RightWingBox>
        )
    }




    return (
        <SeatMapBox ref={seatMapRef}>
            {renderLeftWing()}
            <AircraftBodyGridBox key={seatMap.segmentKey} $seatsPerRowsCount={seatsPerRowCount}>
                <CockpitComponent/>
                <RowsLettersComponent seatMap={seatMap} seatsPerRowCount={seatsPerRowCount}/>
                {seatMap.rowsGroups.map((group, index) => (<SeatMapGroupComponent key={group.groupType}
                                                                                                 title={group.description}
                                                                                                 group={group} groupIndex={index}
                                                                                                 seatsPerRowCount={seatsPerRowCount} />))}
            </AircraftBodyGridBox>
            {renderRightWing()}
        </SeatMapBox>
    )
})
