import React from 'react';
import {makeObservable, observable, runInAction} from "mobx";
import {SeatModel} from "../seat.model";
import {ISeatFilterModel} from "./seat-filter-model.interface";
import {IServiceFactory} from "../../../../service-factory.interface";

export abstract class SeatFilterBaseModel implements ISeatFilterModel {
    constructor(protected readonly services: IServiceFactory) {
        makeObservable<this, '_isApplied'>(this, {
            _isApplied: observable.ref
        });
    }

    abstract shouldAllowSeat(seat: SeatModel): boolean;
    abstract renderIcon(): React.ReactElement;
    abstract  get description(): string;

    private _isApplied: boolean = false;
    get isApplied(): boolean {
        return this._isApplied;
    }

    set isApplied(value: boolean) {
        runInAction(() => {
            this._isApplied = value;
        })
    }
}
