import React, {useEffect, useRef} from "react";
import {AccordionComponent, IAccordionController} from "../../../../../components/accordion/accordion.component";



interface PaymentAccordionComponentProps {
    renderHeader: () => React.ReactElement;
    renderContent: () => React.ReactElement;
    isExpanded?: boolean;
    onExpandStateChange?: (isExpanded: boolean) => void;
    scrollIntoViewOnExpand?: boolean;
}

export const PaymentAccordionComponent: React.FC<PaymentAccordionComponentProps> = (props) => {
    const accordionControllerRef = useRef<IAccordionController | null>(null);


    useEffect(() => {
        const controller = accordionControllerRef.current;
        if(controller) {
            if(props.isExpanded) {
                controller.expand();
            } else {
                controller.collapse();
            }
        }

    }, [props.isExpanded]);

    const provideController = (controller: IAccordionController) =>  {
        accordionControllerRef.current = controller;
    }

    const isExpanded = props.isExpanded ?? false;


    return(
        <AccordionComponent renderHeader={props.renderHeader}
                            renderContent={props.renderContent}
                            isExpanded={isExpanded}
                            onChange={props.onExpandStateChange}
                            provideController={provideController}
                            scrollIntoViewOnExpand={props.scrollIntoViewOnExpand}
                            animated={true}/>
    )
}

