 import React from "react";
import {
    IBundleUpgradeBenefit
} from "../../../../../services/bundle-types/bundle-types.service.interface";
import {IDialogConfirmationHandler} from "../../../../../services/dialog/dialog.service.interface";
import {useServices} from "../../../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import {IBundleType} from "../../../../../services/bundle-types/bundle-type.interface";
import {CheckedIcon} from "../../../../../assets/svg/checked.icon";
import styled, {css} from "styled-components";
import {IBundleViewModel} from "../../../../../services/booking/models/bundle/bundle-view-model.interface";
import {StandardDialogPageComponent} from "../../../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../../../components/modal/header/standard-dialog-header.component";
import {StandardDialogFooterComponent} from "../../../../../components/modal/footer/standard-dialog-footer.component";
import {
        StandardDialogContentComponent
} from "../../../../../components/modal/content/standard-dialog-content.component";
import {StandardDialogTitleComponent} from "../../../../../components/modal/header/standard-dialog-title.component";
import {AlertBoxComponent} from "../../../../../components/alert-box/alert-box.component";
import {DollarCircleIcon} from "../../../../../assets/svg/dollar-circle.icon";

 import {
     UpsellSuggestionComponent
 } from "../../../../../components/upsell-suggestion-button/upsell-suggestion.component";
 import {
     CSS_CLASS_STANDARD_DIALOG_CONTENT_WRAPPER
 } from "../../../../../components/modal/content/dialog-content-css-classes";

 const CSS_CLASS_ALERT_BOX_TITLE_PRICE = 'alert-box-title-price'

 const DialogContent = styled(StandardDialogContentComponent)`
     & .${CSS_CLASS_STANDARD_DIALOG_CONTENT_WRAPPER} {
         gap: ${props => props.theme.spacing.spacing16};
     }
 `

 const DialogTitleBox = styled(StandardDialogTitleComponent)`
     font-size: ${props => props.theme.fontSize.h2};
     padding-bottom: ${props => props.theme.spacing.spacing8};
 `

const SubtitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.body1};
    text-align: justify;
    
`

const GridBox = styled.div`
    display: grid;
    grid-template-columns: repeat(3, auto);
    align-items: stretch;
    width: 100%;
    gap: 0;
`

const CellBox = styled.div<{$isLastOne: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
    padding: ${props => props.theme.spacing.spacing8};
    ${props => props.$isLastOne 
            ? css`
                border-bottom: none;
            `
            : css`
                border-bottom: 1px solid ${props => props.theme.border.mainColor};
            `
    }
`


const HeaderCellBox = styled(CellBox)`
    font-size: ${props => props.theme.fontSize.h4};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    
`

const LeftHeaderCellBox = styled(HeaderCellBox)`
    align-items: flex-start;
    color: ${props => props.theme.colors.mediumShade};
`

const MiddleCellHeaderBox = styled(HeaderCellBox)`
    border-right: 1px solid ${props => props.theme.border.mainColor};
`

const RightCellHeaderBox = styled(HeaderCellBox)`
    color: ${props => props.theme.colors.primary};
`


const LeftCellBox = styled(CellBox)`
    flex-direction: row;
    gap: ${props => props.theme.spacing.spacing16};
`


 const BenefitIconBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
`


 const BenefitInfoBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
`

 const MiddleCellBox = styled(CellBox)`
    border-right: 1px solid ${props => props.theme.border.mainColor};
    
`

 const RightCellBox = styled(CellBox)`
`

const BenefitNameBox = styled.div`
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

const BenefitDetailsBox = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: ${props => props.theme.fontSize.subtitle1};
`

const FooterComponent = styled(StandardDialogFooterComponent)`
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: ${props => props.theme.spacing.spacing16};
`

 const HeaderRowComponent: React.FC<{bundleFrom: IBundleType; bundleTo: IBundleType}> = observer((props) => {
    const services = useServices();
    return (
        <>
            <LeftHeaderCellBox $isLastOne={false}>
                {services.language.translate('Benefits')}
            </LeftHeaderCellBox>

            <MiddleCellHeaderBox $isLastOne={false}>
                {props.bundleFrom.bundleName}
            </MiddleCellHeaderBox>

            <RightCellHeaderBox $isLastOne={false}>
                {props.bundleTo.bundleName}
            </RightCellHeaderBox>
        </>
    )
})

const BenefitDetailsComponent: React.FC<{bundleUpgradeBenefit: IBundleUpgradeBenefit; isLastOne: boolean}> = observer((props) => {
    return (
        <LeftCellBox $isLastOne={props.isLastOne}>
            <BenefitIconBox>
                {props.bundleUpgradeBenefit.icon}
            </BenefitIconBox>

            <BenefitInfoBox>
                <BenefitNameBox>
                    {props.bundleUpgradeBenefit.description}
                </BenefitNameBox>
                <BenefitDetailsBox>
                    {props.bundleUpgradeBenefit.details}
                </BenefitDetailsBox>
            </BenefitInfoBox>

        </LeftCellBox>
    )
});


const RowComponent: React.FC<{bundleUpgradeBenefit: IBundleUpgradeBenefit; isLastOne: boolean}> = observer((props) => {

    const fromIcon = (props.bundleUpgradeBenefit.from
        ? <CheckedIcon />
        : <DollarCircleIcon />);

    const toIcon = (props.bundleUpgradeBenefit.to
        ? <CheckedIcon />
        : <DollarCircleIcon />);


    return (
        <>
            <BenefitDetailsComponent bundleUpgradeBenefit={props.bundleUpgradeBenefit} isLastOne={props.isLastOne}/>
            <MiddleCellBox $isLastOne={props.isLastOne}>{fromIcon}</MiddleCellBox>
            <RightCellBox $isLastOne={props.isLastOne}>{toIcon}</RightCellBox>
        </>
    );
})

 const AlertBoxTitle = styled.div`
     & .${CSS_CLASS_ALERT_BOX_TITLE_PRICE} {
         font-weight: ${props => props.theme.fontWeight.bold};
     }
 `


export interface BundleUpgradeSuggestionComponentProps {
    upgradeFrom: IBundleViewModel;
    upgradeTo: IBundleViewModel;
    benefits: IBundleUpgradeBenefit[]
    dialogHandler: IDialogConfirmationHandler;
}

export const BundleUpgradeSuggestionDialogComponent: React.FC<BundleUpgradeSuggestionComponentProps> = observer((props) => {
    const services = useServices();
    const upgradeFromBundle = props.upgradeFrom;
    const upgradeToBundle = props.upgradeTo;
    const priceDifference = upgradeToBundle.pricePerPassenger.subtract(upgradeFromBundle.pricePerPassenger);

    const dialogTitle = services.language.translationFor('{bundle} is ideal for your trip!')
        .withParams({
            bundle: upgradeToBundle.bundleType.bundleName
        });

    const renderAlertBoxTitle = () => {
        return (
            <AlertBoxTitle dangerouslySetInnerHTML={{
                __html: services.language.translationFor('Upgrade to {bundle} for only {boldStart}{price}{boldEnd} per person!').withParams({
                    bundle: upgradeToBundle.bundleType.bundleName,
                    price: priceDifference.toString(),
                    boldStart: `<span class="${CSS_CLASS_ALERT_BOX_TITLE_PRICE}">`,
                    boldEnd: `</span>`
                })
            }}/>
        )
    }

    const acceptButtonLabel = services.language.translationFor('Upgrade to {bundle}').withParams({bundle: upgradeToBundle.bundleType.bundleName});
    const rejectButtonLabel = services.language.translationFor('Continue with {bundle}').withParams({bundle: upgradeFromBundle.bundleType.bundleName});
    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler}>
                <DialogTitleBox>
                    {dialogTitle}
                </DialogTitleBox>
                <SubtitleBox>
                    {upgradeFromBundle.bundleType.getUpgradeMessage(upgradeToBundle.bundleType)}
                </SubtitleBox>
            </StandardDialogHeaderComponent>
            <DialogContent dialogHandler={props.dialogHandler}>
                <GridBox>
                    <HeaderRowComponent key="header_row" bundleFrom={upgradeFromBundle.bundleType}
                                        bundleTo={upgradeToBundle.bundleType}/>
                    {props.benefits.map((benefit, index) => <RowComponent key={benefit.description}
                                                                          bundleUpgradeBenefit={benefit}
                                                                          isLastOne={index === props.benefits.length - 1}/>)}
                </GridBox>




            </DialogContent>

            <FooterComponent dialogHandler={props.dialogHandler}>

                <AlertBoxComponent severity={"warning"}
                                   size={"large"}
                                   title={renderAlertBoxTitle()}/>

                <UpsellSuggestionComponent acceptButtonLabel={acceptButtonLabel}
                                           rejectButtonLabel={rejectButtonLabel}
                                           acceptButtonClick={() => props.dialogHandler.accept(upgradeToBundle)}
                                           rejectButtonClick={() => props.dialogHandler.accept(upgradeFromBundle)}/>

            </FooterComponent>
        </StandardDialogPageComponent>
    );
});
