import React from 'react';
import {observer} from "mobx-react-lite";
import {useServices} from "../../../../../hooks/use-services.hook";
import {
    IFlightSearchControllerViewModel
} from "../../../../../services/flight-search/flight-search-controller/flight-search-controller-view-model.interface";
import {
    IDropDownController
} from "../../../../forms/drop-down/models/drop-down-controller.interface";
import {
    ReadonlyDropDownInputComponent
} from "../../../../../basic-components/input/read-only-drop-down-input.component";


interface FlightDatesDropDownInputComponentProps {
    searchController: IFlightSearchControllerViewModel;
    dropDownController: IDropDownController;
}

export const FlightDatesDropDownInputComponent: React.FC<FlightDatesDropDownInputComponentProps> = observer((props) => {
    const services = useServices();


    const departureDate = services.time.formatUserFriendlyMonthDay(props.searchController.departureDate)
    const returnDate = services.time.formatUserFriendlyMonthDay(props.searchController.returnDate)

    const getPlaceholderText = () => {
        if(props.dropDownController.isOpen) {
            return "";
        }
        if(props.searchController.isOneWay) {
            return services.language.translate('Select date');
        } else {
            return services.language.translate('Select dates');
        }
    }

    const getText = () => {
        if(departureDate && returnDate) {
            return `${departureDate} - ${returnDate}`;
        } else if(departureDate) {
            return departureDate;
        } else {
            return getPlaceholderText();
        }
    }

    return (<ReadonlyDropDownInputComponent value={getText()} hasSelection={Boolean(departureDate)}/>);



});
