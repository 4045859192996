import styled, {css} from "styled-components";
import React, {PropsWithChildren, useState} from "react";
import {observer} from "mobx-react";
import {arrowForwardOutline} from "ionicons/icons";
import {IonIcon} from "@ionic/react";



export interface ButtonColors {
    $background: string;
    $color: string;
    $border: string;
    $backgroundActivated: string;
    $colorActivated: string;
    $borderActivated: string;
}

interface ButtonBaseBoxProps extends PropsWithChildren {
    $fullWidth: boolean;
    $colors: ButtonColors;
    $isPressed: boolean;
    $disabled?: boolean;
    $inactive?: boolean;
    $hasArrow?: boolean
}



function computeButtonColors(props: ButtonBaseBoxProps) {
    if(props.$disabled || props.$inactive) {
        return css`
                      background-color: ${props => props.theme.colors.lightShade};
                      color: ${props => props.theme.colors.buttonDisabledTextColor};
                      border-color: ${props => props.theme.colors.lightShade};
                  `;
    }

    if(props.$disabled) {
        return css`
                      cursor: not-allowed;
                  `;
    }

    if(props.$isPressed) {
        return  css`
                      background-color: ${props.$colors.$backgroundActivated};
                      color: ${props.$colors.$colorActivated};
                      border-color: ${props.$colors.$borderActivated};
                    `;
    }

    return css`
              background-color: ${props.$colors.$background};
              color: ${props.$colors.$color};
              border-color: ${props.$colors.$border};
            `
}


const ButtonBaseBox = styled.button<ButtonBaseBoxProps>`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${props => props.theme.spacing.spacing16} ${props => props.theme.spacing.spacing24};
    border-width: 1px;
    border-style: solid;
    border-radius: ${props => props.theme.border.defaultRadius};
    font-size: ${props => props.theme.fontSize.body1};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    height: auto;
    text-transform: none;
    white-space: normal;
    cursor: pointer;
    outline: none;
    ${props => computeButtonColors(props)}
    
    
    ${
            props => props.$fullWidth
                    ? css`
                      width: 100%;
                    `
                    : ''
    };

    ${
            props => props.$hasArrow
                    ? css`
                        justify-content: space-between;
                        gap: ${props => props.theme.spacing.flexGap12};
                    `
                    : css`
                        justify-content: center;
                    `
    };
    
`

const ArrowBox = styled.span`
    margin-bottom: -4px;
`

export interface ButtonBaseProps extends PropsWithChildren {
    className?: string;
    onClick?: () => void;
    disabled?: boolean;
    inactive?: boolean;
    fullWidth?: boolean;
    colors: ButtonColors;
    showArrow?: boolean
}



export interface ConcreteButtonProps extends Omit<ButtonBaseProps, 'colors'> {

}

export const ButtonBase: React.FC<ButtonBaseProps> = observer((props) => {
    const [isPressed, setIsPressed] = useState(false);
    const fullWidth = props.fullWidth ?? false;

    const onTouchStart = () => {
        setIsPressed(true);
    }

    const onTouchEnd = () => {
        setIsPressed(false)
    }

    const onClick = () => {
        if(props.disabled) {
            return;
        }

        if(props.onClick) {
            props.onClick();
        }
    }

    const renderArrow = () => {
        if(props.showArrow) {
            return (
                <ArrowBox>
                    <IonIcon icon={arrowForwardOutline}/>
                </ArrowBox>
            )
        }
        return null;
    }

    return (
        <ButtonBaseBox $fullWidth={fullWidth}
                       $disabled={props.disabled}
                       $inactive={props.inactive}
                       className={props.className}
                       onClick={onClick}
                       onMouseDown={onTouchStart}
                       onTouchStart={onTouchStart}
                       onTouchEnd={onTouchEnd}
                       onMouseUp={onTouchEnd}
                       $isPressed={isPressed}
                       $colors={props.colors}
                       $hasArrow={props.showArrow}>
            {props.children}
            {renderArrow()}
        </ButtonBaseBox>
    )
})
