import React from 'react';
import {observer} from "mobx-react-lite";
import {ContinueButtonComponent} from "../../../../components/continue-button/continue-button.component";



interface BookingFlowContinueButtonLargeScreenComponentProps {
    onClick: () => void;
    inactive?: boolean;
    fullWidth?: boolean;
    className?: string;
    customButtonText?: string;
}

export const BookingFlowContinueButtonLargeScreenComponent: React.FC<BookingFlowContinueButtonLargeScreenComponentProps> = observer((props) => {
    return (
        <ContinueButtonComponent onClick={props.onClick} fullWidth={props.fullWidth ?? true} inactive={props.inactive} className={props.className} customButtonText={props.customButtonText}/>
    );
});
