import {WizardBaseStep} from "../../../../models/wizard/wizard-base.step";
import {BookingModel} from "../../models/booking.model";
import {Wizard} from "../../../../models/wizard/wizard";
import {IRoute} from "../../../navigation/models/route.interface";
import {IRouteActivationOptions} from "../../../navigation/navigator.service.interface";

export abstract class BookingStepBase extends WizardBaseStep {
    constructor(protected readonly booking: BookingModel, wizard: Wizard) {
        super(booking.services, wizard);
    }

    protected _activateStepByRoute(route: IRoute, activateOptions?: IRouteActivationOptions): void {
        const finalPriceRoute = this.booking.bookingStrategyAdapter.getFinalPriceRoute();

        if(this.booking.bookingStrategyAdapter.hideFinalPriceStep && route.equals(finalPriceRoute)) {
            const paymentRoute = this.booking.bookingStrategyAdapter.getPaymentRoute();
            super._activateStepByRoute(paymentRoute, activateOptions);
        } else {
            super._activateStepByRoute(route, activateOptions)
        }
    }
}
