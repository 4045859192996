import React from "react";
import {observer} from "mobx-react";
import {ExtrasSectionSmallTileComponent} from "../../../base-component/small-tile/extras-section.small-tile.component";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {BaggageIcon} from "../../../../../../assets/svg/baggage.icon";
import {ExtrasBagsSectionCommonProps} from "../extras-bags-section-common-props";
import {SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE} from "../../../../../../services/ssr-types/ssr-types.service.interface";


interface ExtrasBagsSmallTileComponentProps extends ExtrasBagsSectionCommonProps {

}
export const ExtrasBagsSmallTileComponent: React.FC<ExtrasBagsSmallTileComponentProps> = observer((props) => {
    const services = useServices();
    const ssrsAggregator = props.ssrsAggregator;
    return(
        <ExtrasSectionSmallTileComponent sectionTitle={services.language.translate('Bags')}
                                         modificationBlockingReason={props.ssrsAggregator.modificationBlockingReason}
                                         minDiscountedPrice={ssrsAggregator.discountedMinPrice}
                                         minStandardPrice={ssrsAggregator.standardMinPrice}
                                         renderIcon={() => <BaggageIcon size={SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE}/>}
                                         onEditButtonClick={props.onEditButtonClick}
                                         numberOfSelectedServices={props.countSelectedSsrs()}
                                         allowEdit={!Boolean(ssrsAggregator.modificationBlockingReason)}/>
    )
})