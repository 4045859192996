import React from 'react';
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {IFlightDesignatorViewModel} from "../../../services/booking/models/designator/flight-designator-view-model.interface";
import {
    DesignatorStationNameComponent
} from "../common/components/designator-station-name.component";
import {DesignatorPlaneIconComponent} from "../common/components/designator-plane-icon.component";
import {DesignatorBox} from "../common/boxes/designator.box";

const StationsNamesAndDateBox = styled(DesignatorBox)`
  display: flex;
  flex-direction: column;
`

const StationsNamesBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const DesignatorDetailsBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 0;
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.regular};
  
    ${(props) => props.theme.containerMediaQuery.lAndBelow} {
      font-size: ${props => props.theme.fontSize.h6};
    }
`

const DepartureDateBox = styled.div`
    margin-right: ${props => props.theme.spacing.spacing8};
`

const DepartureAndArrivalTimeBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const TimeSeparatorBox = styled.div`
    padding: 0 ${props => props.theme.spacing.spacing8};
`

interface StationsNamesAndDateComponentProps {
    designator: IFlightDesignatorViewModel;
    useMac?: boolean;
    className?: string;
}

export const StationsNamesAndDateComponent: React.FC<StationsNamesAndDateComponentProps> = observer((props) => {
    const designator = props.designator;
    return (
        <StationsNamesAndDateBox className={props.className}>
            <StationsNamesBox>
                <DesignatorStationNameComponent station={designator.origin} useMac={props.useMac}/>
                <DesignatorPlaneIconComponent/>
                <DesignatorStationNameComponent station={designator.destination} useMac={props.useMac}/>
            </StationsNamesBox>
            <DesignatorDetailsBox>
                <DepartureDateBox>
                    {designator.formatDepartureDate()}
                </DepartureDateBox>
                <DepartureAndArrivalTimeBox>
                    {designator.formatDepartureTime()}
                    <TimeSeparatorBox>-</TimeSeparatorBox>
                    {designator.formatArrivalTime()}
                </DepartureAndArrivalTimeBox>
            </DesignatorDetailsBox>
        </StationsNamesAndDateBox>

    );
});
