import styled from "styled-components";
import React from "react";
import {observer} from "mobx-react";
import {BundleComponent} from "./bundle.component";
import {IBundleViewModel} from "../../../../../services/booking/models/bundle/bundle-view-model.interface";
import {IFlightDesignatorViewModel} from "../../../../../services/booking/models/designator/flight-designator-view-model.interface";
import {PsoBundlePlaceholderComponent} from "./pso-bundle-placeholder.component";
import {useServices} from "../../../../../hooks/use-services.hook";
import {CardBox} from "../../../../../basic-components/card/card.box";
import {useContainerMediaQueriesChecks} from "../../../../../hooks/use-container-media-queries-checks.hook";
import {PsoUserAgreementEnum} from "../../../../../services/flight-search/flight-search-controller/pso.enums";



const JourneyBundlesBox = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: ${props => props.theme.spacing.flexGap20};
    width: 100%;
    ${(props) => props.theme.containerMediaQuery.smallScreen} {
        grid-template-columns: 1fr;
        gap: ${props => props.theme.spacing.flexGap30};
    }
`

const EmptyFrameBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${props => props.theme.spacing.flexGap20};
    border: none;
    box-shadow: none;
`


interface JourneyBundlesComponentProps {
    journeyDesignator: IFlightDesignatorViewModel;
    availableBundles: IBundleViewModel[];
    isOnReturnJourney: boolean;
    onSellBundleClick?: (bundle: IBundleViewModel) => void;
}
export const JourneyBundlesComponent: React.FC<JourneyBundlesComponentProps> = observer( (props) => {
    const services = useServices();
    const mediaQuery = useContainerMediaQueriesChecks();


    if(props.availableBundles.length === 1) {
        return (
            <JourneyBundlesBox>
                {mediaQuery.smallScreen ? <></> : <EmptyFrameBox/>}
                <BundleComponent key={props.availableBundles[0].bundleCode}
                                 bundle={props.availableBundles[0]}
                                 onSellBundleClick={props.onSellBundleClick}
                                 disableNotSelected={props.isOnReturnJourney}
                                 footerButtonType={"continue"}
                                 showReturnToDepartureLink={false}/>
                {services.booking.current.flightSearchController.psoUserAgreement === PsoUserAgreementEnum.Agree ?  <PsoBundlePlaceholderComponent/> : <div/>}
            </JourneyBundlesBox>
        )
    }

    return (
        <JourneyBundlesBox>
            {props.availableBundles.map(bundle => <BundleComponent key={bundle.bundleCode}
                                                                   bundle={bundle}
                                                                   onSellBundleClick={props.onSellBundleClick}
                                                                   disableNotSelected={props.isOnReturnJourney}
                                                                   footerButtonType={(bundle.isSelected && props.isOnReturnJourney) ? "continue" : "selected" }
                                                                   showReturnToDepartureLink={bundle.isSelected && props.isOnReturnJourney}/>)}
        </JourneyBundlesBox>
    );
});
