import {
    BundleCodes,
    IBundleTypesConfiguration,
    IBundleTypesConfigurationService
} from "./bundle-types-configuration.service.interface";
import {IBundleType} from "../bundle-types/bundle-type.interface";
import {TimeSpan} from "../../types/time-span";
import {IServiceFactory} from "../service-factory.interface";
import {ServiceBase} from "../service-base";
import {NullableString} from "../../types/nullable-types";


abstract class BundleTypeBase implements IBundleType {
    constructor(protected readonly services: IServiceFactory) {
    }

    abstract get code(): string;
    abstract get bundleName(): string;
    abstract readonly earliestCheckIn: TimeSpan;

    get isFavouriteBundle(): boolean {
        return false;
    }

    public readonly earliestCheckInWhenHasSeat = TimeSpan.fromDays(30);
    abstract getHeaderBackgroundColor(): string;
    abstract getHeaderColor(): string;
    abstract getSelectedBorderColor(): string;

    getEmphasiseSsrWordsColor(): NullableString {
        return null;
    }

    getUpgradeMessage(toBundle: IBundleType): string {
        return '';
    }

    get requireBookingImpersonation(): boolean {
        return false;
    }

    appendVirtualSsrs(realSsrsIncludedInTheBundle: string[]): string[] {
        const result: string[] = [...realSsrsIncludedInTheBundle];
        for(let virtualSsrCode of this.services.configuration.getBundleVirtualSsrs(this.code)) {
            const ssrType = this.services.ssrTypes.getSsrType(virtualSsrCode);
            if(ssrType.aliasFor) {
                if(realSsrsIncludedInTheBundle.includes(ssrType.aliasFor.ssrCode)
                    && !realSsrsIncludedInTheBundle.includes(virtualSsrCode)) {
                    result.push(virtualSsrCode)
                }
            }

        }

        return result;
    }

    get minTimeToChangeDateBeforeFlight(): TimeSpan {
        let bundleSpecificMinTime = this.services.configuration.data.bundles[this.code]?.minTimeToChangeDateBeforeFlight;
        if(bundleSpecificMinTime) {
            return TimeSpan.parse(bundleSpecificMinTime);
        }

        return TimeSpan.parse(this.services.configuration.data.minTimeToChangeDateBeforeFlight);

    }
}

class ClassicBundleType extends BundleTypeBase implements IBundleType {
    public readonly code = BundleCodes.BPLU;

    public get bundleName() {
        return 'Classic';
    }
    public readonly earliestCheckIn = TimeSpan.fromDays(30);
    public getHeaderBackgroundColor(): string {
        return this.services.theme.currentTheme.colors.primary;
    }

    public getHeaderColor(): string {
        return this.services.theme.currentTheme.colors.primaryContrast;
    }

    getSelectedBorderColor(): string {
        return this.services.theme.currentTheme.colors.primary;
    }

    getEmphasiseSsrWordsColor(): NullableString {
        return this.services.theme.currentTheme.colors.primary;
    }

    getUpgradeMessage(toBundle: IBundleType): string {
        return this.services.language.translationFor('Upgrade to {bundle} to benefit of premium seats, extra 23kg check-in bag, large cabin bag and priority boarding.').withParams({bundle: toBundle.bundleName});
    }

    get isFavouriteBundle(): boolean {
        return true;
    }
}

class BizBundleType extends BundleTypeBase implements IBundleType {
    public get code(): string  {
        return BundleCodes.CBIZ;
    }
    public get bundleName(): string {
        return 'Biz';
    }
    public readonly earliestCheckIn = TimeSpan.fromDays(30);
    public getHeaderBackgroundColor() {
        return this.services.theme.currentTheme.colors.darkTint;
    }

    public getHeaderColor() {
        return this.services.theme.currentTheme.colors.darkContrast;
    }

    getSelectedBorderColor(): string {
        return this.services.theme.currentTheme.colors.darkTint;
    }

    getEmphasiseSsrWordsColor(): string {
        return this.services.theme.currentTheme.colors.darkTint;
    }
}

class CbcmBundleType extends BizBundleType {
    public get code(): string {
        return BundleCodes.CBCM;
    }
}

class CorporateBundleType extends BizBundleType implements IBundleType {
    public get code(): string {
        return BundleCodes.CORR;
    }

    public get bundleName(): string {
        return 'Corporate';
    }
}

class BasicBundleType extends BundleTypeBase implements IBundleType{
    public get code(): string {
        return BundleCodes.ABAS;
    }


    get bundleName(): string {
        return 'Basic';
    }

    public readonly isBasicBundle = true;
    public readonly earliestCheckIn = TimeSpan.fromHours(24);
    public getHeaderBackgroundColor() {
        return this.services.theme.currentTheme.colors.darkContrast;
    }

    public getHeaderColor() {
        return this.services.theme.currentTheme.colors.dark;
    }

    getSelectedBorderColor() {
        return this.services.theme.currentTheme.colors.dark;
    }

    getUpgradeMessage(toBundle: IBundleType) {
        return this.services.language.translationFor('Upgrade to {bundle} to benefit of free standard seat, 23kg check-in bag and possibility to check-in at the airport for free.').withParams({bundle: toBundle.bundleName});
    }
}

abstract class TerritorialContinuityBundle extends BundleTypeBase implements IBundleType {
    public abstract get code(): string;
    public get bundleName() {
        return this.services.language.translate('Territorial continuity')
    }
    public readonly earliestCheckIn = TimeSpan.fromDays(30);
    public getHeaderBackgroundColor(): string {
        return this.services.theme.currentTheme.colors.primaryShade;
    }

    public getHeaderColor(): string {
        return this.services.theme.currentTheme.colors.primaryContrast;
    }

    getSelectedBorderColor(): string {
        return this.services.theme.currentTheme.colors.primaryShade;
    }

    get requireBookingImpersonation(): boolean {
        return true;
    }
}

//Bundle for Sardinia
class AbpsBundleType extends TerritorialContinuityBundle {
    public get code(): string {
        return BundleCodes.ABPS;
    }
}

//Bundle for Sardinia workers
class AbwkBundleType extends TerritorialContinuityBundle {
    public get code(): string {
        return BundleCodes.ABWK;
    }
}

//bundle for marche
class AbmrBundleType extends TerritorialContinuityBundle {

    public get code(): string {
        return BundleCodes.ABMR;
    }

    public readonly earliestCheckIn = TimeSpan.fromHours(24);
}

export class BundleTypesConfigurationService extends ServiceBase implements IBundleTypesConfigurationService {
    getConfiguration(): IBundleTypesConfiguration {
        const bundleTypesDisplayOrder: IBundleType[] = [
            new BizBundleType(this.services),
            new CbcmBundleType(this.services),
            new CorporateBundleType(this.services),
            new ClassicBundleType(this.services),
            new BasicBundleType(this.services),
            new AbpsBundleType(this.services),
            new AbwkBundleType(this.services),
            new AbmrBundleType(this.services),

        ];

        return {
            bundlesDisplayOrder: bundleTypesDisplayOrder,
        }
    }
}
