import React from 'react';
import {IServiceFactory} from "../../../../service-factory.interface";
import {IBookingViewModel} from "../../booking-view-model.interface";
import {
    IPaymentHandlerViewModel
} from "../payment-handler-view-model.interface";
import {IPaymentMethodGroupViewModel} from "./payment-method-group.view-model.interface";
import {PaymentMethodsGroupTypeEnum} from "./payment-method-group-type.enum";


export abstract class PaymentMethodGroupModel implements IPaymentMethodGroupViewModel {
    constructor(public readonly groupType: PaymentMethodsGroupTypeEnum,
                protected readonly services: IServiceFactory) {
    }

    abstract renderTabHeader(): React.ReactElement;
    abstract renderTabContent(): React.ReactElement;
    abstract renderPayButton(): React.ReactElement;

    protected get booking(): IBookingViewModel {
        return this.services.booking.current;
    }

    protected get paymentHandler(): IPaymentHandlerViewModel {
        return this.booking.paymentHandler;
    }
}

