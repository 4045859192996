import {SeatsSelectionBaseStep} from "../common/seats-selection-base-step";
import {IWizardStep} from "../../../../models/wizard/wizard.step.interface";
import {ICheckInRoutes} from "../../../navigation/routes/check-in/check-in.routes.interface";

export class CheckInFlowSeatsSelectionStep extends SeatsSelectionBaseStep<ICheckInRoutes> {

    protected get routes(): ICheckInRoutes {
        return this.services.navigator.routes.checkIn;
    }

    get route() {
        return this.routes.seatsSelection;
    }

    protected async _goToNextStep(): Promise<void> {
        this._activateStepByRoute(this.routes.extras);
    }

    protected _shouldSuggestSeats(previousStep: IWizardStep | null): boolean {
        return !Boolean(previousStep && this.routes.extras.equals(previousStep.route));
    }
}
