import React from 'react';
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {CardBox} from "../../../../../basic-components/card/card.box";
import {ExtrasSectionComponentCommonProps} from "../extras-section.component.common-props";
import {PriceWithDiscountComponent} from "../../../../../components/price/price-with-discount.component";
import {StandardPriceLabelComponent} from "../../../../../components/price/standard-price-label.component";
import {ExtrasEditButtonComponent} from "../extras-edit-button.component";
import {NullableString} from "../../../../../types/nullable-types";
import {ExtrasSelectedServicesCountComponent} from "../extras-selected-services-count.component";
import {
    SsrModificationBlockingReasonComponent
} from "../../../common-components/ssr-modification-blocking-reason.component";


const FIRST_TILE_ICON_BOX_SIZE = '4.125rem';
const SECOND_TILE_ICON_BOX_SIZE = '40px';

const SectionBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.spacing8};
    padding: 0;
    width: 100%;
    justify-content: space-between;
    
`

const PictureContainerBox = styled.div`
    width: 100%;
    border-top-left-radius: ${props => props.theme.border.defaultRadius};
    border-top-right-radius: ${props => props.theme.border.defaultRadius};
    overflow: hidden;
`

const HeaderBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const FirstTileHeaderBox = styled(HeaderBox)`
    position: relative;
`

const SecondTileHeaderBox = styled(HeaderBox)`
`

const SubHeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-items: center;
    font-size: ${props => props.theme.fontSize.body2};
    gap: ${props => props.theme.spacing.spacing16};
`

const FirstTileSubHeaderBox = styled(SubHeaderBox)`
    padding-left: calc(2 * ${props => props.theme.spacing.flexGap20} + ${FIRST_TILE_ICON_BOX_SIZE});
    padding-right: ${props => props.theme.spacing.spacing8};
    font-size: ${props => props.theme.fontSize.body2};
`

const SecondTileSubHeaderBox = styled(SubHeaderBox)`
    padding: ${props => props.theme.spacing.spacing8} ${props => props.theme.spacing.spacing16};
    border-radius: ${props => props.theme.border.defaultRadius};
    background-color: ${props => props.theme.colors.tertiaryVeryLight};
    color: ${props => props.theme.colors.tertiaryShade};
    transform: translateY(-50%);
    margin-left: ${props => props.theme.spacing.spacing20};
    margin-right: ${props => props.theme.spacing.spacing20};
`

const FirstTileIconBox = styled.div`
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${FIRST_TILE_ICON_BOX_SIZE};
    height: ${FIRST_TILE_ICON_BOX_SIZE};
    background-color: ${props => props.theme.colors.tertiaryVeryLight};
    color: ${props => props.theme.colors.tertiaryShade};
    left: ${props => props.theme.spacing.spacing20};
    padding: ${props => props.theme.spacing.spacing16};
    border-radius: ${props => props.theme.border.defaultRadius};
`

const SecondTileIconBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: ${SECOND_TILE_ICON_BOX_SIZE};
    height: ${SECOND_TILE_ICON_BOX_SIZE};
`

const FooterBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const FooterTopSectionBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.spacing8};
    padding: 0 ${props => props.theme.spacing.spacing20} ${props => props.theme.spacing.spacing20} ${props => props.theme.spacing.spacing20};
    width: 100%;
`


const DescriptionAndPriceContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
`

const ServiceDescriptionBox = styled.div`
    flex-grow: 1;
    font-size: ${props => props.theme.fontSize.h4};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

const PriceLabelBox = styled(StandardPriceLabelComponent)`
    font-size: ${props => props.theme.fontSize.caption};
`

const PriceBox = styled(PriceWithDiscountComponent)`
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.bold};
    
`

const ImageBox = styled.img`
    width: 100%;
    
`

const BlockingReasonBox = styled.div`
    width: 100%;
    padding: 0 ${props => props.theme.spacing.cardDefaultPadding} ${props => props.theme.spacing.cardDefaultPadding} ${props => props.theme.spacing.cardDefaultPadding};
`

interface HeaderProps {
    renderIcon: () => React.ReactElement | null;
    getMarketingMessage: () => NullableString;
    imageUrl: NullableString;
}

const FirstTileHeaderComponent: React.FC<HeaderProps> = observer((props) => {

    const renderImage = () => {
        if (!props.imageUrl) {
            return null;
        }

        return (<ImageBox src={props.imageUrl} alt={""}/>);
    }

    return (
        <FirstTileHeaderBox>
            <PictureContainerBox>
                {renderImage()}
            </PictureContainerBox>
            <FirstTileSubHeaderBox>
                <FirstTileIconBox>
                    {props.renderIcon()}
                </FirstTileIconBox>
                <div>
                    {props.getMarketingMessage()}
                </div>
            </FirstTileSubHeaderBox>
        </FirstTileHeaderBox>
    )
});


const SecondTileHeaderComponent: React.FC<HeaderProps> = observer((props) => {
    const renderImage = () => {
        if (!props.imageUrl) {
            return null;
        }

        return (<ImageBox src={props.imageUrl} alt={""}/>);
    }
    return (
        <SecondTileHeaderBox>
            <PictureContainerBox>
                {renderImage()}
            </PictureContainerBox>
            <SecondTileSubHeaderBox>
                <SecondTileIconBox>
                    {props.renderIcon()}
                </SecondTileIconBox>
                <div>
                    {props.getMarketingMessage()}
                </div>
            </SecondTileSubHeaderBox>
        </SecondTileHeaderBox>
    )
});



export interface ExtrasSectionLargeTileComponentProps extends ExtrasSectionComponentCommonProps {
    isFirstTile: boolean;
    renderFirstTileIcon: () => React.ReactElement | null;
    renderSecondTileIcon: () => React.ReactElement | null;
    getMarketingMessage: () => NullableString;
    imageUrl: NullableString;

}

export const ExtrasSectionLargeTileComponent: React.FC<ExtrasSectionLargeTileComponentProps> = observer((props) => {

    const renderPrice = () => {
        if(props.numberOfSelectedServices > 0) {
            return (
                <ExtrasSelectedServicesCountComponent numberOfServices={props.numberOfSelectedServices}/>
            )
        }
        if(!props.modificationBlockingReason && props.minDiscountedPrice && props.minStandardPrice) {
            return (
                <PriceBox label={<PriceLabelBox/>}
                                            discountedPrice={props.minDiscountedPrice}
                                            standardPrice={props.minStandardPrice}/>
            );
        }

        return null;
    }

    const renderHeader = () => {
        if(props.isFirstTile) {
            return (
                <FirstTileHeaderComponent renderIcon={props.renderFirstTileIcon} getMarketingMessage={props.getMarketingMessage} imageUrl={props.imageUrl}/>
            );
        }
        return (
            <SecondTileHeaderComponent renderIcon={props.renderSecondTileIcon} getMarketingMessage={props.getMarketingMessage} imageUrl={props.imageUrl}/>
        );

    }

    const renderEditButton = () => {
        if(!props.allowEdit) {
            return null;
        }
        return (
            <ExtrasEditButtonComponent onClick={props.onEditButtonClick} hasPurchases={props.numberOfSelectedServices > 0}/>
        );
    }

    const renderModificationBlockingReason = () => {
        if(props.modificationBlockingReason) {
            return (<BlockingReasonBox>
                <SsrModificationBlockingReasonComponent reason={props.modificationBlockingReason}/>
            </BlockingReasonBox>)
        }
        return null;
    }

    return (
        <SectionBox>
            {renderHeader()}
            <FooterBox>
                <FooterTopSectionBox>
                    <DescriptionAndPriceContainerBox>
                        <ServiceDescriptionBox>
                            {props.sectionTitle}
                        </ServiceDescriptionBox>
                        {renderPrice()}
                    </DescriptionAndPriceContainerBox>

                    {renderEditButton()}

                </FooterTopSectionBox>
                {renderModificationBlockingReason()}

            </FooterBox>
        </SectionBox>
    );
});
