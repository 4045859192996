import {BookingModel} from "../booking/models/booking.model";
import {JourneyModel} from "../booking/models/journey/journey.model";

export function isJourneyEligibleForPsoDiscount(booking: BookingModel, journey: JourneyModel | null): boolean {
    if(!journey) {
        return false;
    }

    const bookedDate = booking.getBookedDate();
    const departureDate = journey.designator.departureDate;

    if(bookedDate.getFullYear() !== departureDate.getFullYear()
        && departureDate.getTime() >= new Date(bookedDate.getFullYear() + 1, 1, 1).getTime() ) {
        return false;
    }

    return true;
}