import {computed, IReactionDisposer, makeObservable, observable, reaction, runInAction} from "mobx";
import {IDotRezBookingSession} from "../../../dot-rez-api/session/booking-session/dot-rez-booking.session.interface";
import {BookingModel} from "../booking.model";
import {IServiceFactory} from "../../../service-factory.interface";
import {ILoadPaymentMethodsOptions, IPaymentHandlerViewModel} from "./payment-handler-view-model.interface";
import {DialogResult} from "../../../dialog/dialog-enums";
import {ISelectedPaymentMethod} from "./payment-selection/selected-payment-method.interface";
import {ValidationResultEnum} from "../../../../types/validation-result.enum";
import {TimeSpan} from "../../../../types/time-span";
import {IPaymentStrategy} from "./payment-strategy/payment-strategy.interface";
import {BeginPaymentStatusEnum} from "../../../airline-webapi/enums/begin-payment-status.enum";
import {BookingSessionStorageKeys} from "../storage/booking-storage.interface";
import {PaymentStatusObserver} from "./payment-status-observer";
import {PaymentResultsDialogFactory} from "./payment-results-dialog-factory";
import {IPaymentStrategyBeginPaymentResponse} from "./payment-strategy/payment-strategy-begin-payment-response";
import {PaymentStatusQueryParamEnum, PaymentStatusQueryParamValuesEnum} from "./payment-status-query-params.enum";
import {PaymentTransactionStatusEnum} from "../../../airline-webapi/enums/payment-transaction-status.enum";
import {IPaymentMethodModel} from "./payment-methods/payment-method.model.interface";
import {delay} from "../../../../utils/util-functions";
import {Check} from "../../../../types/type-checking";
import {WebapiPaymentStrategy} from "./payment-strategy/webapi-payment.strategy";
import {NullableString} from "../../../../types/nullable-types";
import {PaymentMethodCodesEnum} from "./payment-methods/payment-method-codes.enum";
import {AirlineWebapiErrorCodesEnum} from "../../../airline-webapi/airline-webapi-error-codes.enum";
import {IPspError} from "../../../airline-webapi/responses/psp-error";
import {IPaymentStatusResponse} from "../../../airline-webapi/responses/payment-status.response";
import {PaymentSelectionHandlerModel} from "./payment-selection/payment-selection-handler.model";
import {Lazy} from "../../../../utils/lazy";
import {IPaymentSelectionHandlerViewModel} from "./payment-selection/payment-selection-handler.view-model.interface";

interface IPayBookingResponse {
    pspOrderId: NullableString;
}


export class PaymentHandlerModel implements IPaymentHandlerViewModel /*, IRoutingGuard*/ {

    constructor(private readonly booking: BookingModel) {

        this._selectedPaymentMethodsCodes = this.booking.storage.getJson(BookingSessionStorageKeys.selectedPaymentMethods) ?? [];

        makeObservable<this, '_selectedPaymentMethodsCodes'>(this, {
            _selectedPaymentMethodsCodes: observable.ref,
            creditCardPaymentMethods: computed,
        });

        this._paymentStrategy = new WebapiPaymentStrategy(booking);


        //this._routingGuardSubscription = this.services.navigator.registerRoutingGuard(this);

        this.resultDialogs = new PaymentResultsDialogFactory(this.booking);
        if(this._shouldFinalizePayment) {
            this._finalizePayment(this.services.navigator.getQueryParamsValues(PaymentStatusQueryParamEnum.Status)[PaymentStatusQueryParamEnum.Status] as PaymentStatusQueryParamValuesEnum);
        } else if(this._shouldTryFinalizePendingPayment) {
            this._tryFinalizePendingPayment();
        }

    }

    private readonly _paymentStrategy: IPaymentStrategy;
    readonly resultDialogs: PaymentResultsDialogFactory;
    //private readonly _routingGuardSubscription: IRoutingGuardSubscription;
    private _selectedPaymentMethodsCodes: string[] = [];
    private _preventDoublePayBooking = false;
    private _activateFromPaymentReaction: IReactionDisposer | null = null;
    private _redirectPromiseResolver: null | ((value: void | PromiseLike<void>) => void) = null;

    dispose(): void {
        //this._routingGuardSubscription.unsubscribe();
    }

    private get services(): IServiceFactory {
        return this.booking.services;
    }

    private get session(): IDotRezBookingSession {
        return this.booking.session;
    }

    private _paymentSelectionHandler: Lazy<PaymentSelectionHandlerModel> = new Lazy<PaymentSelectionHandlerModel>(() => new PaymentSelectionHandlerModel(this.services));

    get paymentSelectionHandler(): IPaymentSelectionHandlerViewModel {
        return this._paymentSelectionHandler.value;
    }

    get creditCardPaymentMethods(): IPaymentMethodModel[] {
        return [
            ...this._paymentStrategy.mobileWalletPaymentMethods,
            ...this._paymentStrategy.creditCardPaymentMethods
        ];
    }


    selectPaymentMethod(code: string): void {

        const selectedMethods: string[] = [];

        let selectedCard = this.creditCardPaymentMethods.find(m => m.code === code)
                                                        ?? this.creditCardPaymentMethods.find(c => c.isSelected);
        if(selectedCard) {
            selectedMethods.push(selectedCard.code);
        }

        runInAction(() => {
            this._selectedPaymentMethodsCodes = selectedMethods;
            this.booking.storage.setJson(BookingSessionStorageKeys.selectedPaymentMethods, selectedMethods);
        })

    }

    unselectPaymentMethod(code: string): void {
        const index = this._selectedPaymentMethodsCodes.findIndex(c => c === code);
        if(index < 0) {
            return;
        }

        const newMethods = [...this._selectedPaymentMethodsCodes];
        newMethods.splice(index, 1);
        runInAction(() => {
            this._selectedPaymentMethodsCodes = newMethods;
            this.booking.storage.setJson(BookingSessionStorageKeys.selectedPaymentMethods, this._selectedPaymentMethodsCodes);
        })
    }

    isPaymentMethodSelected(code: string): boolean {
        return this._selectedPaymentMethodsCodes.includes(code);
    }

    async loadPaymentMethods(options: ILoadPaymentMethodsOptions): Promise<void> {
        await this._paymentStrategy.loadPaymentMethods(options);
    }

    getSelectedPaymentMethods(): ISelectedPaymentMethod[] {
        const selectedMethods = this.creditCardPaymentMethods.filter(pm => pm.isSelected);

        let amountToPay = this.booking.balanceDue.amount;
        const result: ISelectedPaymentMethod[] = [];
        for(let pm of selectedMethods) {
            if(amountToPay <= 0) {
                break;
            }

            const amount = Math.min(amountToPay, pm.availableAmountInBookingCurrency.amount);
            if(amount > 0) {
                result.push({
                    code: pm.code,
                    category: pm.category,
                    amount: amount
                });

                amountToPay -= amount;
            }
        }

        return result;
    }

    private async _refreshBookingData(): Promise<void> {
        const bookingData = await this.session.bookingStateQueryBuilder().useBookingData().getBookingState();
        this.booking.updateBookingSessionData(bookingData);
    }

    private async _executePostSaveBookingOperations(): Promise<void> {
        if (this.booking.voucher.isApplied) {
            this.booking.voucher.clear();
        }

        this.booking.saveToMyTrips();

        // old purchase event
        //this.booking.sendAnalyticsPurchaseEvent();
        this.services.analytics.paymentEvents.logPurchase(this.booking)

        //await this.booking.moveBetweenDisruptionQueues();

        try {
            if(this.booking.getAllPassengersSegments().some(p => p.isCheckedIn)) {
                await this.services.airlineWebapi.updateBoardingPassesInWallets({
                    dotRezToken: this.session.token
                })
            }
        } catch (err) {
            this.services.logger.error(`Failed to update boarding passes in wallets for PNR ${this.booking.recordLocator}`, err);
        }
    }

    private async _saveZeroBalanceDueBooking(): Promise<ValidationResultEnum> {
        try {
            await this.booking.commitBooking();
            await this._refreshBookingData();
        } catch (err) {
            this.services.logger.error('Payment handler failed to save booking', err);
            return ValidationResultEnum.Failure;
        }

        await this._executePostSaveBookingOperations();
        return ValidationResultEnum.Success;
    }

    private async _savePayedBooking(): Promise<void> {
        try {
            await this.booking.commitBooking();
            await this._refreshBookingData();
        } catch (err) {
            this.services.logger.error('Payment handler failed to save payed booking', err);
        } finally {
            //If the booking is payed it doesn't matter the outcome of the commitBooking and _refreshBookingData.
            //We need to give the user the ticket because the booking is payed
            await this._executePostSaveBookingOperations();
        }
    }

    private async _onPayBookingSuccess(): Promise<void> {
        this.paymentAttempt++;
        await this.services.loadingIndicator.execute({
            action: async () => {
                await this._savePayedBooking();
            }
        });

        this.services.analytics.paymentEvents.logAddRemarketingPurchase(this.booking);
        this._paymentStrategy.onPaymentFinalized();
        await this.booking.bookingStrategyAdapter.onPaymentSuccess();
        this.resultDialogs.showPaymentSuccess();


    }

    private async _onSaveZeroBalanceDueBookingSuccess(): Promise<void> {
        await this.booking.bookingStrategyAdapter.onPaymentSuccess();
        this.resultDialogs.showPaymentSuccess({customMessage: this.services.language.translate('Booking saved!')});

    }

    private async _onPayBookingFailed(options: {status: PaymentTransactionStatusEnum;
                                                shouldRefreshBooking: boolean;
                                                apiErrorCode: AirlineWebapiErrorCodesEnum | null;
                                                pspErrorDetails: IPspError[] | null}): Promise<void> {

        try {
            await this.services.loadingIndicator.execute({
                action: async () => {
                    if(options.shouldRefreshBooking) {
                        await this._refreshBookingData();
                    }
                    await this.loadPaymentMethods({showLoadingIndicator: false});
                }
            })
        } catch (err) {
            this.services.logger.error('Failed to refresh booking and reload payment methods after failed payment', err);
        }

        if(options.status === PaymentTransactionStatusEnum.FatalError) {
            this.resultDialogs.showPaymentFatalError({
                pspErrorDetails: options.pspErrorDetails
            });
            return;
        }

        switch (options.apiErrorCode) {
            case AirlineWebapiErrorCodesEnum.NskServerClassNotAvailable:
                this._paymentStrategy.onPaymentFinalized();
                await this.booking.errorHandling.handleNskServerClassNotAvailable();
                break;
            default:
                switch (options.status) {
                    case PaymentTransactionStatusEnum.Cancel:
                        this._paymentStrategy.onPaymentFinalized();
                        this.resultDialogs.showPaymentCanceled();
                        break;
                    default:
                        this._paymentStrategy.onPaymentFinalized();
                        this.resultDialogs.showPaymentFailed({
                            errorMessage: null,
                            pspErrorDetails: options.pspErrorDetails
                        });
                        break;
                }
        }




    }

    private async _tryRefreshToken(): Promise<ValidationResultEnum> {
        try {
            const result = await this.booking.session.tryRefreshSession();
            if(result.isExpired) {
                return ValidationResultEnum.Failure;
            } else {
                return ValidationResultEnum.Success;
            }

        } catch (err) {
            this.services.alert.showError(this.services.language.translate('There was an error trying to initialize your payment transaction'));
            return ValidationResultEnum.Failure;
        }


    }


    async payBooking(): Promise<void> {
        await this._payBooking();
    }

    unselectAllPaymentMethods(): void {
        runInAction(() => {
            this._selectedPaymentMethodsCodes = [];
            this.booking.storage.removeItem(BookingSessionStorageKeys.selectedPaymentMethods);
        })

    }
    async createPspOrder(paymentMethodCode: PaymentMethodCodesEnum): Promise<string> {
        this.selectPaymentMethod(paymentMethodCode);
        const response = await this._payBooking();

        if(response?.pspOrderId) {
            this.booking.session.pauseSessionTimer();
            return response.pspOrderId;
        } else {
            this.unselectAllPaymentMethods();
            return "";
        }

    }

    async completePspOrder(orderId: string): Promise<void> {
        try {

            const response = await this.services.loadingIndicator.execute({
                action: async () => {
                    return await this._paymentStrategy.completePspOrder(orderId);
                }
            });

            if(response.paymentStatus === PaymentTransactionStatusEnum.Completed) {
                await this._onPayBookingSuccess();
                return;
            }

            await this.services.loadingIndicator.execute({
                action: async () => {
                    await this.booking.session.resumeSessionTimer();
                }
            });


            if(await this.booking.session.isExpired()) {
                return;
            }

            switch (response.paymentStatus) {
                case PaymentTransactionStatusEnum.PendingReview:
                    this.resultDialogs.showPaymentInReview()
                    break;
                default:
                    await this._onPayBookingFailed({
                        status: response.paymentStatus,
                        shouldRefreshBooking: true,
                        apiErrorCode: response.apiErrorCode,
                        pspErrorDetails: response.pspErrorDetails
                    });

                    break;
            }

        } catch (err) {
            this.services.logger.error('completePayPalOrder failed!', err);
            await this._onPayBookingFailed({
                status: PaymentTransactionStatusEnum.Error,
                shouldRefreshBooking: true,
                apiErrorCode: null,
                pspErrorDetails: null
            });
        } finally {
            this.unselectAllPaymentMethods();
        }
    }

    async onPspOrderCanceled(): Promise<void> {
        try {

            this._paymentStrategy.cancelPspOrder(); //no need to wait for the promise;

            await this.booking.session.resumeSessionTimer();

            if(!await this.booking.session.isExpired()) {
                await this._onPayBookingFailed({
                    status: PaymentTransactionStatusEnum.Cancel,
                    shouldRefreshBooking: false,
                    apiErrorCode: null,
                    pspErrorDetails: null
                });
            }


        } finally {
            this.unselectAllPaymentMethods();
        }

    }




    private async _payBooking(): Promise<IPayBookingResponse | null> {
        if(this._preventDoublePayBooking) {
            return null;
        }

        this._preventDoublePayBooking = true;

        try {

            if (this.booking.balanceDue.amount < 0) {
                this.services.logger.error('Negative booking cannot be payed');
                this.services.alert.showError(this.services.language.translate('Booking with negative balance due cannot be saved'));
                return null;
            }

            if(!this.booking.travelConditionsAccepted) {
                this.booking.travelConditionsAcceptanceError = this.services.language.translate('You must accept the travel conditions');
                this.services.alert.showError(this.booking.travelConditionsAcceptanceError);
                return {
                    pspOrderId: null
                };
            }

            if(this.booking.balanceDue.amount === 0) {
                const result = await this.services.loadingIndicator.execute({
                    action: async () => {
                        if(this.booking.voucher.isApplied) {
                            const voucherApplyResult = await this.booking.voucher.addVoucherToBooking();
                            if(voucherApplyResult !== ValidationResultEnum.Success) {
                                this.services.alert.showError(this.services.language.translate('Sorry! There was an error trying to add your voucher to the booking'));
                                return null;
                            }
                        }
                        return await this._saveZeroBalanceDueBooking();
                    }
                });

                if(result !== ValidationResultEnum.Success) {
                    this.services.alert.showError(this.services.language.translate('There was an error saving your booking. Please try again latter.'));
                } else {
                    await this._onSaveZeroBalanceDueBookingSuccess();
                }
                return null;
            }

            const selectedPaymentMethods =  this.getSelectedPaymentMethods();
            if(selectedPaymentMethods.length === 0) {
                this.services.alert.showError(this.services.language.translate('Please select a payment method'));
                return null;
            }

            if(this.booking.invoice.invoiceRequested) {
                if(ValidationResultEnum.Success !== await this.booking.invoice.validate()) {
                    return null;
                }
            }


            const refreshTokenResult = await this.services.loadingIndicator.execute({
                action: async () => {
                    return this._tryRefreshToken();
                }
            });

            if(refreshTokenResult !== ValidationResultEnum.Success) {
                return null;
            }

            if(this._paymentStrategy.supportsCreditCardCollection) {
                if (DialogResult.Rejected === await this._paymentStrategy.collectCreditCardDetails()) {
                    return null;
                }
            }

            try {
                return await this._beginPayment(selectedPaymentMethods);
            } catch (err) {
                this.services.logger.error('_beginPayment failed', err);
                await this._onPayBookingFailed({
                    status: PaymentTransactionStatusEnum.Error,
                    shouldRefreshBooking: true,
                    apiErrorCode: null,
                    pspErrorDetails: null
                });
                return null;
            }
        } finally {
            this._preventDoublePayBooking = false;
        }
    }

    private get paymentAttempt(): number {
        const attempt = this.booking.storage.getItem(BookingSessionStorageKeys.paymentAttempt);
        if(attempt) {
            return parseInt(attempt);
        }

        return 1;
    }

    private set paymentAttempt(value: number) {
        this.booking.storage.setItem(BookingSessionStorageKeys.paymentAttempt, value.toString())
    }

    private async _beginPayment(selectedMethods: ISelectedPaymentMethod[]): Promise<IPayBookingResponse | null> {

        const strategyBeginPaymentResponse = await this.services.loadingIndicator.execute({
            action: async () => {
                return await this._paymentStrategy.beginPayment({
                    selectedMethods: selectedMethods,
                    paymentAttempt: this.paymentAttempt
                });
            }
        });


        switch (strategyBeginPaymentResponse.status) {
            case BeginPaymentStatusEnum.Failed:
                await this._onPayBookingFailed({
                    status: PaymentTransactionStatusEnum.Error,
                    apiErrorCode: strategyBeginPaymentResponse.apiErrorCode,
                    shouldRefreshBooking: true,
                    pspErrorDetails: strategyBeginPaymentResponse.pspErrorDetails
                })

                break;

            case BeginPaymentStatusEnum.Finalized:
                await this._onPayBookingSuccess();
                break;

            case BeginPaymentStatusEnum.PendingRedirect:
                if(strategyBeginPaymentResponse.redirectUrl) {
                    await this._payRedirect(strategyBeginPaymentResponse);
                    return null;
                } else {
                    return {
                        pspOrderId: strategyBeginPaymentResponse.pspOrderId
                    };
                }

            default:
                throw new Error(`Invalid begin payment status ${strategyBeginPaymentResponse.status}`);
        }

        return null;
    }

    private _getPaymentStatusFromUrl(url: string): PaymentStatusQueryParamValuesEnum | null {
        url = url?.toString()?.toLowerCase();
        if(!url) {
            return null;
        }

        //https://www.apsp.biz/pay/viva/successpage.aspx?t=748d45bd-259c-4b82-8c29-b3a53b581d3a&s=3874368424177183&lang=en-150&eventId=0&eci=1
        /*if(0 <= url.indexOf('viva/successpage.aspx')) {
            return PaymentStatusQueryParamValuesEnum.Success;
        }

         */

        if(0 <= url.indexOf('mobileapp/finalize')) {
            const urlBuilder = new URL(url);
            const callBackTypeParamValue = urlBuilder.searchParams.get('callbacktype')?.toString();
            switch (callBackTypeParamValue) {
                case '0':
                    return PaymentStatusQueryParamValuesEnum.Success;
                case '1':
                    return PaymentStatusQueryParamValuesEnum.Canceled;
                default:
                    return PaymentStatusQueryParamValuesEnum.Failed;
            }
        }


        return null;
    }

    private async _payRedirectWithAdvancedInAppBrowser(redirectUrl: string): Promise<void> {


        let lastUrl = "";

        const advancedInAppBrowser = await this.services.loadingIndicator.execute({
            action: async () => {
                return await this.services.window.openAdvancedInAppBrowser({
                    url: redirectUrl,
                    theme: this.services.theme.currentTheme,
                    enableDebug: !this.services.configuration.isProduction,
                    urlPatternsToOpenInExternalBrowser: [
                        '/cookies-policy',
                        '/privacy-notice',
                        '/terms-portal',
                        '/privacy',
                        '/terms'
                    ],
                    onUrlChanged: async (url) => {
                        lastUrl = url;
                        //this.services.logger.error('urlChanged: ' + url);
                        if(!Check.isNullOrUndefined(this._getPaymentStatusFromUrl(url))) {
                            await advancedInAppBrowser?.close();
                        }
                    }
                });
            }
        });

        this.booking.session.pauseSessionTimer();

        await advancedInAppBrowser.wait();

        await this.booking.session.resumeSessionTimer();


        const paymentStatusFromUrl = this._getPaymentStatusFromUrl(lastUrl);
        if(Check.isNullOrUndefined(paymentStatusFromUrl)) {
            await this._tryFinalizePendingPayment();
        } else {
            await this._finalizePayment(paymentStatusFromUrl);
        }

    }


    private _disposeActivateFromPaymentReaction(): void {
        if(this._activateFromPaymentReaction) {
            this._activateFromPaymentReaction();
            this._activateFromPaymentReaction = null;
        }
    }

    private _resolveRedirectPromise = () => {
        if(this._redirectPromiseResolver) {
            this._redirectPromiseResolver();
            this._redirectPromiseResolver = null;
        }
    }

    private async _payRedirect(strategyBeginPaymentResponse: IPaymentStrategyBeginPaymentResponse): Promise<void> {
        if(!strategyBeginPaymentResponse.redirectUrl) {
            this.services.logger.error(`Missing payment redirect URL`);
            await this._onPayBookingFailed({
                status: PaymentTransactionStatusEnum.Error,
                shouldRefreshBooking: true,
                apiErrorCode: null,
                pspErrorDetails: null
            });
            return;
        }



        if(this.services.device.isHybrid) {
            await this._payRedirectWithAdvancedInAppBrowser(strategyBeginPaymentResponse.redirectUrl!);
        } else {

            this.booking.session.pauseSessionTimer();
            //This reaction here is because in production after redirect from payment page back to the website
            //the browser might have cached our payment page so instead of a full page load it just activates the page
            this._activateFromPaymentReaction = reaction(() => this.services.application.isActive,
                async (isActive) => {
                    if(isActive) {

                        await this.booking.session.resumeSessionTimer();

                        if(this._shouldTryFinalizePendingPayment) {
                            await this._tryFinalizePendingPayment();
                        }

                        this._disposeActivateFromPaymentReaction();
                        this._resolveRedirectPromise();
                    }
                });

            const resultPromise =  new Promise<void>((resolve) => {
                this._redirectPromiseResolver = resolve;
            });

            await this.services.loadingIndicator.execute({
                action: async () => {
                    this.services.navigator.redirect(strategyBeginPaymentResponse.redirectUrl!);
                    await delay(Date.now(), 10000); // To keep the spinner running until the redirect actually happen.
                }
            })


            return resultPromise;
        }
    }

    private get isRedirectInProgress(): boolean {
        return this._paymentStrategy.isRedirectInProgress;
    }

    private get _shouldFinalizePayment(): boolean {
        return this.booking.bookingStrategyAdapter.supportsPayment
            && this.isRedirectInProgress
            && this.booking.bookingStrategyAdapter.getFinalizePaymentRoute().equals(this.services.navigator.currentRoute);
    }

    private get _shouldTryFinalizePendingPayment(): boolean {
        return this.booking.bookingStrategyAdapter.supportsPayment
            && this.isRedirectInProgress
            && this.booking.bookingStrategyAdapter.getPaymentRoute().equals(this.services.navigator.currentRoute);
    }

    private async _finalizePayment(paymentStatusFromQueryParams: PaymentStatusQueryParamValuesEnum): Promise<void> {

        this._disposeActivateFromPaymentReaction();

        this._resolveRedirectPromise();

        const onPaymentCanceled = async (paymentStatusResponse: IPaymentStatusResponse) => {

            this.booking.bookingStrategyAdapter.getPaymentRoute().activate({
                allowBack: false
            });

            await this._onPayBookingFailed({
                status: PaymentTransactionStatusEnum.Cancel,
                shouldRefreshBooking: false,
                apiErrorCode: paymentStatusResponse.apiErrorCode,
                pspErrorDetails: paymentStatusResponse.pspErrorDetails
            });
        }

        const onPaymentFailed = async (paymentStatusResponse: IPaymentStatusResponse) => {
            this.booking.bookingStrategyAdapter.getPaymentRoute().activate({
                allowBack: false
            });
            await this._onPayBookingFailed({
                status: PaymentTransactionStatusEnum.Error,
                shouldRefreshBooking: false,
                apiErrorCode: paymentStatusResponse.apiErrorCode,
                pspErrorDetails: paymentStatusResponse.pspErrorDetails
            });
        }

        const onPaymentFailedFatal = async (paymentStatusResponse: IPaymentStatusResponse) => {
            await this._onPayBookingFailed({
                status: PaymentTransactionStatusEnum.FatalError,
                shouldRefreshBooking: false,
                apiErrorCode: null,
                pspErrorDetails: paymentStatusResponse.pspErrorDetails
            });
        }



        const paymentStatus = await this.services.loadingIndicator.execute({
            action: async () => {
                const paymentStatusObserver = new PaymentStatusObserver(this.services, this._paymentStrategy);
                let timeout: TimeSpan | undefined = undefined;
                //if the status from query params is success then we will wait until we get a finalized status
                //This is because when we get success callback the status in apcopay can be OK or PENDING. So we need to wait for a final state
                if(paymentStatusFromQueryParams !== PaymentStatusQueryParamValuesEnum.Success) {
                    timeout = TimeSpan.fromSeconds(3);
                }
                return await paymentStatusObserver.start(timeout);
            }
        });

        if(paymentStatus.status === PaymentTransactionStatusEnum.Completed) {
            await this._onPayBookingSuccess();
            return;
        }

        if(await this.booking.session.isExpired()) {
            return;
        }

        switch (paymentStatus.status) {

            case PaymentTransactionStatusEnum.Cancel:
                await onPaymentCanceled(paymentStatus);
                break;
            case PaymentTransactionStatusEnum.FatalError:
                await onPaymentFailedFatal(paymentStatus);
                break;
            default:
                if(paymentStatusFromQueryParams === PaymentStatusQueryParamValuesEnum.Canceled) {
                    await onPaymentCanceled(paymentStatus);
                } else {
                    await onPaymentFailed(paymentStatus);
                }

        }
    }

    private async _tryFinalizePendingPayment(): Promise<void> {

        //we end-up here when the user press back button from payment provider page
        //so in this case we try to get a final status for 3 seconds.
        const paymentStatus = await this.services.loadingIndicator.execute({
            action: async () => {
                const paymentStatusObserver = new PaymentStatusObserver(this.services, this._paymentStrategy);
                return await paymentStatusObserver.start(TimeSpan.fromSeconds(4));
            }
        });


        if(paymentStatus.status === PaymentTransactionStatusEnum.NotFinalized) {
            await this.loadPaymentMethods({
                showLoadingIndicator: true
            });
        } else if(paymentStatus.status === PaymentTransactionStatusEnum.Completed) {
            await this._onPayBookingSuccess();
        } else {

            if(!await this.booking.session.isExpired()) {

                await this._onPayBookingFailed({
                    status: paymentStatus.status,
                    shouldRefreshBooking: false,
                    apiErrorCode: paymentStatus.apiErrorCode,
                    pspErrorDetails: paymentStatus.pspErrorDetails
                });
            }


        }
    }
}


