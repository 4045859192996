import React from "react";
import {observer} from "mobx-react";
import {
    ILowFareResult,
    LowFareStatusEnum
} from "../../../../services/low-fare/low-fare-readers/low-fare-reader.interface";
import {UnavailableDayComponent} from "./days/unavailable-day.component";
import {DaySpinnerComponent} from "./days/day-spinner.component";
import {SameDayComponent} from "./days/same-day.component";
import {SelectedDayComponent} from "./days/selected-day.component";
import {RegularDayComponent} from "./days/regular-day.component";
import {DayCellBox} from "./days/boxes/day-cell.box";
import styled from "styled-components";
import {DayPricesNotShowComponent} from "./days/day-prices-not-show.component";
import {
    FlightsDatesSelectorModel
} from "../../../../services/flight-search/dates-selection/flights-dates-selector.model";


const FlightDayContainerBox = styled(DayCellBox)`
    padding: 0;
    align-items: center;
`

interface FlightDayComponentProps {
    date: Date;
    captureSelectedDateElementRef?: (date: Date, element: HTMLElement | null) => void;
    shouldRenderPrice: boolean;
    flightsDatesSelector: FlightsDatesSelectorModel;
}

export const FlightDayComponent: React.FC<FlightDayComponentProps> = observer((props) => {

    const isSelected = props.flightsDatesSelector.isDateSelected(props.date);

    const captureDayContentBoxElement = (el: any) => {
        if(isSelected && props.captureSelectedDateElementRef) {
            props.captureSelectedDateElementRef(props.date, el);
        }
    }

    let departureLowFareResult: ILowFareResult = {
        status: LowFareStatusEnum.NoFare
    };

    let returnLowFareResult: ILowFareResult = {
        status: LowFareStatusEnum.NoFare
    };

    const renderPriceNotShown = () => {
        return (
            <FlightDayContainerBox ref={captureDayContentBoxElement}>
                <DayPricesNotShowComponent date={props.date} flightsDatesSelector={props.flightsDatesSelector}/>
            </FlightDayContainerBox>
        );
    }

    if(props.shouldRenderPrice) {
        departureLowFareResult = props.flightsDatesSelector.getDepartureLowFare(props.date);
        returnLowFareResult = props.flightsDatesSelector.getReturnLowFare(props.date);
        if(departureLowFareResult.status === LowFareStatusEnum.Disabled
            && returnLowFareResult.status === LowFareStatusEnum.Disabled) {
            return renderPriceNotShown();
        }
    } else {
        return renderPriceNotShown();
    }


    const renderDay = () => {
        if(departureLowFareResult.status === LowFareStatusEnum.Reading || returnLowFareResult.status === LowFareStatusEnum.Reading) {
            return (
                <DaySpinnerComponent/>
            );
        }



        if(props.flightsDatesSelector.isSameDateForDepartureAndReturn(props.date)) {
            return (
                <SameDayComponent departureLowFare={departureLowFareResult.price} returnLowFare={returnLowFareResult.price}/>
            )
        }

        if(isSelected) {
            return (
                <SelectedDayComponent date={props.date}
                                      flightsDatesSelector={props.flightsDatesSelector}
                                      departureLowFare={departureLowFareResult.price}
                                      returnLowFare={returnLowFareResult.price}/>
            );
        }

        if(!props.flightsDatesSelector.isDateAvailable(props.date)) {
            return (
                <UnavailableDayComponent date={props.date} flightsDatesSelector={props.flightsDatesSelector}/>
            )
        }

        return (
            <RegularDayComponent date={props.date} flightsDatesSelector={props.flightsDatesSelector} departureLowFare={departureLowFareResult.price} returnLowFare={returnLowFareResult.price}/>
        )

    }

    return (
        <FlightDayContainerBox ref={captureDayContentBoxElement}>
            {renderDay()}
        </FlightDayContainerBox>
    );
})
