import { observer } from "mobx-react";
import React from "react";
import {
    AdvancedJourneyDesignatorComponent
} from "../../../../../components/advanced-journey-designator/advanced-journey-designator.component";
import {IJourneyViewModel} from "../../../../../services/booking/models/journey/journey-view-model.interface";
import styled, {css} from "styled-components";
import {useServices} from "../../../../../hooks/use-services.hook";
import {arrowForwardOutline} from "ionicons/icons";
import {IonIcon} from "@ionic/react";
import {showFlightsToModifySelectionDialog} from "./show-flights-to-modify-selection-dialog";

const FlightContainerBox = styled.div<{$isCanceled: boolean}>`
    display: flex;
    flex-direction: column;
    padding: 0;
    border: 1px solid ${props => props.theme.border.mainColor};
    border-radius: ${props => props.theme.border.defaultRadius};
    overflow: hidden;
    
    ${props => props.$isCanceled
    ? css`
                border-color: ${props => props.theme.colors.danger};
        `
    : ''
}
    
`

const FlightCanceledHeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: ${props => props.theme.colors.danger};
    padding: ${props => props.theme.spacing.spacing8};
    font-size: ${props => props.theme.fontSize.caption};
    font-weight: ${props => props.theme.fontWeight.regular};
    background-color: ${props => props.theme.colors.danger};
    color: ${props => props.theme.colors.dangerContrast};
`

const ManageCanceledFlightBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.spacing8};
    cursor: pointer;
`

const JourneyDesignatorWrapperBox = styled.div`
    padding: ${props => props.theme.spacing.spacing12} ${props => props.theme.spacing.spacing20} ;
`

interface ManageMyBookingOneFlightComponentProps {
    journey: IJourneyViewModel;
    index: number;
}

export const ManageMyBookingOneFlightComponent: React.FC<ManageMyBookingOneFlightComponentProps> = observer((props) => {
    const services = useServices();
    const booking = services.booking.current;
    const journey = props.journey;
    const isCanceled = journey.isCanceled /* || props.index === 1*/;

    const onManageCanceledJourneyClick = async () => {
        if(booking.manageMyBooking.availableJourneysForDateChange.all(j => j.isCanceled)) {
            await booking.manageMyBooking.startFlightsChange();
        } else {
            await showFlightsToModifySelectionDialog(services);
        }
    }

    const renderManageCanceledFlight = () => {
        if(!journey.initialJourneyAllowsDateChange) {
            return null;
        }
        return (
            <ManageCanceledFlightBox onClick={onManageCanceledJourneyClick}>
                    <span>
                        {services.language.translate("Modify")}
                    </span>
                <IonIcon icon={arrowForwardOutline}/>
            </ManageCanceledFlightBox>
        )
    }
    const renderFlightCanceled = () => {
        if(!isCanceled) {
            return null;
        }

        return (
            <FlightCanceledHeaderBox>
                <span>
                    {services.language.translate("Flight Canceled")}
                </span>
                {renderManageCanceledFlight()}
            </FlightCanceledHeaderBox>
        );
    }

    return (
        <FlightContainerBox $isCanceled={isCanceled}>
            {renderFlightCanceled()}
            <JourneyDesignatorWrapperBox>
                <AdvancedJourneyDesignatorComponent journeyDesignator={journey.designator} isShownInShoppingCart={false}/>
            </JourneyDesignatorWrapperBox>

        </FlightContainerBox>
    );
})