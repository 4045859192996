import React from 'react';
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {CardBox} from "../../../../../basic-components/card/card.box";
import {ExtrasSectionComponentCommonProps} from "../extras-section.component.common-props";
import {PriceWithDiscountComponent} from "../../../../../components/price/price-with-discount.component";
import {StandardPriceLabelComponent} from "../../../../../components/price/standard-price-label.component";
import {ExtrasEditButtonComponent} from "../extras-edit-button.component";
import {ExtrasSelectedServicesCountComponent} from "../extras-selected-services-count.component";
import {
    SsrModificationBlockingReasonComponent
} from "../../../common-components/ssr-modification-blocking-reason.component";


const SectionBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: ${props => props.theme.spacing.spacing16};
    width: 100%;
    padding-bottom: ${props => props.theme.spacing.spacing20};
`

const HeaderBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    
    gap: ${props => props.theme.spacing.spacing16};
    
`

const FooterBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${props => props.theme.spacing.spacing8};
    width: 100%;
    
`

const BlockingReasonBox = styled.div`
    width: 100%;
    padding: 0 0 ${props => props.theme.spacing.cardDefaultPadding} 0;
`


const ServiceDescriptionBox = styled.div`
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    text-align: center;
`

const IconBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
`


const PriceLabelBox = styled(StandardPriceLabelComponent)`
    font-size: ${props => props.theme.fontSize.caption};
`


const PriceBox = styled(PriceWithDiscountComponent)`
    font-weight: ${props => props.theme.fontWeight.semiBold};
    font-size: ${props => props.theme.fontSize.h5};
`

export interface ExtrasSectionSmallTileComponentProps extends ExtrasSectionComponentCommonProps {
    renderIcon: () => React.ReactElement | null;
}

export const ExtrasSectionSmallTileComponent: React.FC<ExtrasSectionSmallTileComponentProps> = observer((props) => {

    const renderPrice = () => {
        if(props.numberOfSelectedServices > 0) {
            return (
                <ExtrasSelectedServicesCountComponent numberOfServices={props.numberOfSelectedServices}/>
            )
        }
        if(!props.modificationBlockingReason && props.minDiscountedPrice && props.minStandardPrice) {
            return (
                <PriceBox label={<PriceLabelBox/>}
                                discountedPrice={props.minDiscountedPrice}
                                standardPrice={props.minStandardPrice}/>
            );
        }



        return null;
    }

    const renderModificationBlockingReason = () => {
        if(props.modificationBlockingReason) {
            return (
                <BlockingReasonBox>
                    <SsrModificationBlockingReasonComponent reason={props.modificationBlockingReason}/>
                </BlockingReasonBox>
            )
        }

        return null;
    }

    const renderEditButton = () => {
        if(!props.allowEdit) {
            return null;
        }
        return (
            <ExtrasEditButtonComponent onClick={props.onEditButtonClick} hasPurchases={props.numberOfSelectedServices > 0}/>
        );
    }

    return (
        <SectionBox>
            <HeaderBox>
                <IconBox>
                    {props.renderIcon()}
                </IconBox>
                <ServiceDescriptionBox >
                    {props.sectionTitle}
                </ServiceDescriptionBox>
            </HeaderBox>
            <FooterBox>
                {renderEditButton()}
                {renderPrice()}
                {renderModificationBlockingReason()}
            </FooterBox>

        </SectionBox>
    );
});
