import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import styled from "styled-components";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";
import {StandardDialogPageComponent} from "../../modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../../modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../../modal/footer/standard-dialog-footer.component";
import {PrimaryButton} from "../../../basic-components/buttons/primary-button";
import {ExclamationDiamondIcon} from "../../../assets/svg/exclamation-diamond.icon";
import {useScreenMediaQueriesChecks} from "../../../hooks/use-screen-media-queries-checks.hook";


const BodyTextBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px 0;
    font-size: ${props => props.theme.fontSize.h4};
    flex-grow: 1;
    gap: ${props => props.theme.spacing.flexGap20};
`

const IconBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: ${props => props.theme.spacing.spacing20};
`

const ParagraphBox = styled.div`
    margin-bottom: 10px;
`

interface InvalidPromoCodeDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
}

export const InvalidPromoCodeDialogComponent: React.FC<InvalidPromoCodeDialogComponentProps> = observer((props) => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler}/>
            <StandardDialogContentComponent dialogHandler={props.dialogHandler}>
                <BodyTextBox>
                    <IconBox>
                        <ExclamationDiamondIcon size={screenMediaQuery.smallScreen ? 3 : 5} color={services.theme.currentTheme.colors.danger}/>
                    </IconBox>

                    <ParagraphBox dangerouslySetInnerHTML={{
                        __html: services.language.translate(`Unfortunately this promo code is not valid. Click on <b>Continue without promo</b> to  continue the booking flow with the full price.`)
                    }}/>
                    
                    <ParagraphBox dangerouslySetInnerHTML={{
                        __html: services.language.translate(`<b>Use the X button</b> instead to try another promotional code.`)
                    }}/>
                </BodyTextBox>

            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <PrimaryButton onClick={() => props.dialogHandler.accept()}>
                    {services.language.translate('Continue without promo')}
                </PrimaryButton>
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    );
});
