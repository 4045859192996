import React from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {
    IFlightDesignatorViewModel
} from "../../services/booking/models/designator/flight-designator-view-model.interface";

const CarrierBox = styled.div`
    font-size: ${props => props.theme.fontSize.caption};
    color: ${props => props.theme.colors.tertiaryTint};
`

export const OperatedByComponent: React.FC<{designator: IFlightDesignatorViewModel; className?: string}> = observer((props) => {
    const msg = props.designator.flightIdentifier?.getOperatedByMessage();
    if(!msg) {
        return null;
    }
    return (
        <CarrierBox className={props.className}>
            {msg}
        </CarrierBox>
    )
});
