import {SeatModel} from "./seat.model";
import {computed, makeObservable} from "mobx";
import {SeatsRowGroupType} from "./seats-row-group-type.enum";
import {ISeatsRowViewModel} from "./seats-row-view-model.interface";
import {Price} from "../../../currency/price";
import {SegmentSeatMapEditorModel} from "./segment-seat-map-editor.model";

export class SeatsRowModel implements ISeatsRowViewModel {
    constructor(private readonly seatMapEditor: SegmentSeatMapEditorModel,
                public readonly rowNumber: number) {
        makeObservable<this, '_seatsDictionary'>(this, {
            _seatsDictionary: computed,
            leftSeats: computed,
            rightSeats: computed,
            seatOnTheLeftWing: computed,
            seatOnTheRightWing: computed,
            minPrice: computed,
            isMiddleEmergencyRow: computed,
            hasExtraLegRoom: computed
        })
    }

    readonly seats: SeatModel[] = [];

    private get _seatsDictionary(): Record<string, SeatModel> {
        return this.seats.toDictionary(s => s.seatNumber);
    }

    get seatOnTheLeftWing(): SeatModel | null {
        const seat = this.leftSeats[0];
        if(seat?.isOnWing) {
            return seat;
        }
        return null;
    }

    get seatOnTheRightWing(): SeatModel | null {
        const seat = this.rightSeats[this.rightSeats.length - 1];
        if(seat?.isOnWing) {
            return seat;
        }
        return null;
    }

    private _getSideSeats(columns: string[]): Array<SeatModel | null> {
        const seats: Array<SeatModel | null> = [];

        for(let column of columns) {
            seats.push(this._seatsDictionary[`${this.rowNumber}${column}`] || null);
        }

        return seats;
    }

    private get _letterChunks(): Array<string[]> {
        return this.seatMapEditor.availableSeatsLetters.splitToChunks(this.seatMapEditor.availableSeatsLetters.length / 2);
    }

    get leftSeats(): Array<SeatModel | null> {
        return this._getSideSeats(this._letterChunks[0]);
    }

    get rightSeats(): Array<SeatModel | null> {
        return this._getSideSeats(this._letterChunks[1]);
    }

    get minPrice(): Price {
        return Price.min(this.seats.map(s => s.price));
    }

    get isMiddleEmergencyRow(): boolean {
        if(this.rowNumber <= 2) {
            return false;
        }

        return this.seats.some(seat => seat.isOnExitRow);
    }

    get hasExtraLegRoom(): boolean {
        return this.leftSeats.some(s => s?.hasExtraLegroom)
                || this.rightSeats.some(s => s?.hasExtraLegroom);
    }

    get location(): SeatsRowGroupType {
        return this.seats[0].location;
    }
}
