import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

//fill={props.color? props.color: "currentColor"}
export const BanIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={4} {...props} fill={"none"} viewBox="0 0 46 46" ratio={46 / 46}>
            <circle cx="23" cy="23" r="22" stroke="#DBE0E9" strokeWidth="2"/>
            <line x1="8.20711" y1="7.79289" x2="39.2071" y2="38.7929" stroke="#DBE0E9" strokeWidth="2"/>
        </SvgIcon>
    )
}
