import {IBundleType} from "./bundle-type.interface";
import {TimeSpan} from "../../types/time-span";
import {IServiceFactory} from "../service-factory.interface";
import {NullableString} from "../../types/nullable-types";

export class OldBundleType implements IBundleType {
    constructor(public readonly code: string,
                public readonly earliestCheckIn: TimeSpan,
                public readonly earliestCheckInWhenHasSeat: TimeSpan,
                private readonly services: IServiceFactory) {
    }

    get bundleName(): string {
        return this.code;
    }
    get shortName(): string {
        return this.code
    }

    readonly isFavouriteBundle = false;

    getHeaderBackgroundColor(): string {
        return this.services.theme.currentTheme.colors.primaryContrast;
    }

    getHeaderColor(): string {
        return this.services.theme.currentTheme.colors.primary;
    }

    getSelectedBorderColor(): string {
        return this.services.theme.currentTheme.colors.primary;
    }

    getEmphasiseSsrWordsColor(): NullableString {
        return null;
    }

    get isBasicBundle(): boolean {
        return false;
    }

    get prefix(): string {
        return "";
    }

    getUpgradeMessage(toBundle: IBundleType): string {
        return '';
    }

    appendVirtualSsrs(realSsrsIncludedInTheBundle: string[]): string[] {
        return realSsrsIncludedInTheBundle;
    }

    get requireBookingImpersonation(): boolean {
        return false;
    }

    get minTimeToChangeDateBeforeFlight(): TimeSpan {
        return TimeSpan.parse(this.services.configuration.data.minTimeToChangeDateBeforeFlight);
    }
}
