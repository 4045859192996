import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import { NullableString} from "../../types/nullable-types";

const FieldContainerBox = styled.div`
    width: 100%;
    margin-bottom: 10px;
`

const LabelBox = styled.div`
    font-size: ${props => props.theme.fontSize.h5};
    
    color: ${props => props.theme.colors.medium};
    margin-bottom: 5px;
`

const ValueBox =  styled.div`
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.h4};
    
`

interface FieldViewComponentProps {
    label: string;
    value: NullableString;
}

export const FieldViewComponent: React.FC<FieldViewComponentProps> = observer((props) => {
    return (
        <FieldContainerBox>
            <LabelBox>
                {props.label}
            </LabelBox>
            <ValueBox>
                {props.value}
            </ValueBox>
        </FieldContainerBox>
    );
});

