import React from "react";
import {observer} from "mobx-react";
import {StandardDialogPageComponent} from "../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../components/modal/header/standard-dialog-header.component";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";
import {StandardDialogContentComponent} from "../../../components/modal/content/standard-dialog-content.component";
import {
    SearchMaskWebappSmallScreenComponent
} from "../../../components/search-mask/webapp/small-screen/search-mask.webapp.small-screen.component";
import {useServices} from "../../../hooks/use-services.hook";
import {YesNoDialogPrimaryButton, YesNoDialogResult} from "../../../services/dialog-factory/yes-no-dialog.enums";
import styled from "styled-components";

const ResetMessageBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap16};
`

export const ChangeSearchDialogComponent: React.FC<{dialogHandler: IDialogConfirmationHandler}> = observer((props) => {
    const services = useServices();
    const onSearchClick = async () => {

        if(services.flightSearch.searchController.hasErrors()) {
            return;
        }

        const dialogResult = await services.dialogFactory.showYesNoDialog({
            primaryButton: YesNoDialogPrimaryButton.PrimaryButtonNo,
            message: (
                <ResetMessageBox>
                    <span>{services.language.translate('This action will reset you current booking selections.')}</span>
                    <span>{services.language.translate('Do you still want to continue?')}</span>
                </ResetMessageBox>
            )
        });

        if (dialogResult !== YesNoDialogResult.Yes) {
            return;
        }



        services.navigator.routes.home.activate({
            allowBack: false
        });

        await services.flightSearch.startBooking();
        props.dialogHandler.accept();

    }
    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler}/>
            <StandardDialogContentComponent dialogHandler={props.dialogHandler}>
                <SearchMaskWebappSmallScreenComponent onSearchClick={onSearchClick} isUsedInsideDialog={true}/>
            </StandardDialogContentComponent>
        </StandardDialogPageComponent>
    )
})