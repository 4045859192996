import styled, {css} from "styled-components";
import {PrimaryLabelBox} from "../../basic-components/label/primary-label.box";

export const FieldLabelBox = styled(PrimaryLabelBox)<{$hasError: boolean}>`
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.bold};
    
    margin-bottom: ${props => props.theme.spacing.spacing8};
    ${
        props => props.$hasError 
                    ? css`
                      color: ${props => props.theme.colors.danger};
                    `
                    : ''
    }
`
