import React from "react";
import styled from "styled-components";
import { PlaneDepartingLineFilledIcon } from "../../../../../assets/svg/plane-departing-line-filled.icon";
import { SeatCircleFilledIcon } from "../../../../../assets/svg/seat-circle-filled.icon";
import { useServices } from "../../../../../hooks/use-services.hook";
import { IMaturePassengerViewModel } from "../../../../../services/booking/models/passenger/mature-passenger-view-model.interface";
import { FlightItineraryAccordionHeaderComponent } from "../flight-itinerary-accordion-header.component";
import {
    FlightItineraryAccordionComponent,
    FlightItineraryInnerAccordionComponent
} from "../flight-itinerary-accordion.component";
import { FlightItineraryPassengerAccordionHeaderComponent } from "../flight-itinerary-passenger-accordion-header.component";
import {VerticalSpacer} from "../../../../../basic-components/spacing/vertical-spacer";
import {flightItinerarySpaceBetweenIconAndHeaderText} from "../css-constants";

const FlightItinerarySeatsContentBox = styled.div`
    display: flex;
    align-items: center;
    padding-left: ${props => `calc(${props.theme.spacing.cardDefaultPadding} + ${flightItinerarySpaceBetweenIconAndHeaderText(props.theme)})`};
    width: 60%;
`;

const FlightItinerarySeatsDesignatorBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
`

const FlightItinerarySeatsContentSeatsNumbersDetails = styled.div`
    font-size: ${props => props.theme.fontSize.h6};
    color: ${props => props.theme.colors.medium};
`;

const FlightItinerarySeatsContentSeatsNumbersDetailsIcon = styled.div`
    margin: 0 10px;
`

const FlightItinerarySeatsContentSeatsNumbersDetailsNumber = styled(FlightItinerarySeatsContentSeatsNumbersDetails)`
    margin-left: 24px;
    font-size: ${props => props.theme.fontSize.h5};
`

const FlightItinerarySeatsContentSeatsNumbersDetailsBold = styled(FlightItinerarySeatsContentSeatsNumbersDetails)`
    font-weight: ${props => props.theme.fontWeight.bold};

`;

interface FlightItinerarySeatsContentComponentProps {
    passenger: IMaturePassengerViewModel;
}

const FlightItinerarySeatsContentComponent: React.FC<FlightItinerarySeatsContentComponentProps> = (props) => {
    const services = useServices();
    const booking = services.booking.current;
    const passengersSegments = booking.getAllPassengersSegments();

    return(
        <div>
            {passengersSegments.filter(ps => ps.passengerKey === props.passenger.passengerKey).map(ps => {
                return(
                    <FlightItinerarySeatsContentBox key={ps.passengerSegmentUniqueKey}>
                        <FlightItinerarySeatsDesignatorBox>
                            <FlightItinerarySeatsContentSeatsNumbersDetailsBold>{ps.flightDesignator.origin.stationCode}</FlightItinerarySeatsContentSeatsNumbersDetailsBold>
                            <FlightItinerarySeatsContentSeatsNumbersDetailsIcon><PlaneDepartingLineFilledIcon color={services.theme.currentTheme.colors.medium} size={1.6} /></FlightItinerarySeatsContentSeatsNumbersDetailsIcon>
                            <FlightItinerarySeatsContentSeatsNumbersDetailsBold>{ps.flightDesignator.destination.stationCode}</FlightItinerarySeatsContentSeatsNumbersDetailsBold>
                        </FlightItinerarySeatsDesignatorBox>
                        <FlightItinerarySeatsContentSeatsNumbersDetailsNumber>{ps.assignedSeat?.seatNumber}</FlightItinerarySeatsContentSeatsNumbersDetailsNumber>
                    </FlightItinerarySeatsContentBox>
                )
            })}
        </div>
    )
}

export const FlightItinerarySeatsComponent: React.FC = () => {
    const services = useServices();

    const FlightItinerarySeatsHeader = () => {
        return(
            <FlightItineraryAccordionHeaderComponent renderIcon={() => <SeatCircleFilledIcon/>} title={services.language.translate('Seats')} />
        )
    }

    const FlightItinerarySeatsContent = () => {
        const passengers = services.booking.current.passengers;
        return (
            <div>
                {passengers.map((p) => {
                    return(
                        <React.Fragment key={p.passengerKey}>
                            <VerticalSpacer size={"medium"}/>
                            <FlightItineraryInnerAccordionComponent renderHeader={() => <FlightItineraryPassengerAccordionHeaderComponent showSeats={true} passenger={p}/>}
                                                                    renderContent={() => <FlightItinerarySeatsContentComponent passenger={p}/>}
                                                                    isExpanded={false}/>

                        </React.Fragment>
                    )
                })}
            </div>
        )
    }

    return(
        <FlightItineraryAccordionComponent renderHeader={FlightItinerarySeatsHeader} 
                                           renderContent={FlightItinerarySeatsContent}/>
    );
}
