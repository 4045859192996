import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {IPassengerTypeSelector} from "../../../../../services/passenger-types/passengers-types.service.interface";
import {
    QuantityDecrementButtonComponent, QuantityIncrementButtonComponent
} from "../../../../quantity-edit-buttons/quantity-edit-buttons";


const PassengerTypeInfoBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
`

const PassengerTypeNameBox = styled.div`
    font-size: ${props => props.theme.fontSize.h5};
    width: 100%;
`

const PassengerTypeAgeLimitsBox = styled.div`
    font-size: ${props => props.theme.fontSize.body2};
    color: ${props => props.theme.colors.medium};
    width: 100%;
`

const PassengerTypeCountBox = styled.div`
    font-size: ${props => props.theme.fontSize.h2};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    width: 100%;
    text-align: center;
`


interface PassengerTypeDropDownItemComponentProps {
    passengerTypeSelector: IPassengerTypeSelector;
}

export const PassengerTypeDropDownItemComponent: React.FC<PassengerTypeDropDownItemComponentProps> = observer((props) => {
    const increment = () => {
        props.passengerTypeSelector.count++;
    }

    const decrement = () => {
        props.passengerTypeSelector.count--;
    }

    return (
        <>
            <PassengerTypeInfoBox>
                <PassengerTypeNameBox>
                    {props.passengerTypeSelector.description}
                </PassengerTypeNameBox>
                <PassengerTypeAgeLimitsBox>
                    {props.passengerTypeSelector.ageLimitsDescription}
                </PassengerTypeAgeLimitsBox>
            </PassengerTypeInfoBox>

            <QuantityDecrementButtonComponent disabled={!props.passengerTypeSelector.allowDecrement} onClick={decrement}/>

            <PassengerTypeCountBox>
                {props.passengerTypeSelector.count}
            </PassengerTypeCountBox>

            <QuantityIncrementButtonComponent disabled={!props.passengerTypeSelector.allowIncrement} onClick={increment}/>

        </>
    )
});
