import React from "react";
import styled from "styled-components";

const IconWrapperBox = styled.div<{$isHidden?: boolean}>`
    display: flex;
    align-items: center;
    min-width: 18px;
    visibility: ${props => props.$isHidden ? "hidden" : "visible"};
`


export const FlightSearchDropDownIconComponent: React.FC<{icon: React.ReactElement; isHidden?: boolean}> = (props) => {
    return (
        <IconWrapperBox $isHidden={props.isHidden}>
            {props.icon}
        </IconWrapperBox>
    )
}
