import React from "react";
import {observer} from "mobx-react-lite";
import {NullableString} from "../../../../types/nullable-types";
import styled from "styled-components";
import {ExternalLinkComponent} from "../../../../components/external-link/external-link.component";
import {ExclamationDiamondIcon} from "../../../../assets/svg/exclamation-diamond.icon";
import {useServices} from "../../../../hooks/use-services.hook";


const ContainerBox = styled.div`
    display: flex;
    font-size: ${props => props.theme.fontSize.caption};
    color: ${props => props.theme.colors.dark};
    
    align-items: flex-start;

    svg {
        margin-right: 8px;
        flex-shrink: 0;
    }
`

const ReasonTitleBox = styled.div`
    font-weight: ${props => props.theme.fontWeight.semiBold};
`


const ReasonBox = styled.div`
    display: flex;
    flex-direction: column;
`

const ReasonDetailsBox = styled(ExternalLinkComponent)`
    color: ${props => props.theme.colors.notesTint};
`

interface FlightChangeBlockingReasonComponentProps {
    blockingReason: NullableString;
    title: string;

}

export const FlightChangeBlockingReasonComponent: React.FC<FlightChangeBlockingReasonComponentProps> = observer((props) => {
    const services = useServices();
    if(!props.blockingReason) {
        return null;
    }
    return (
        <ContainerBox>
            <ExclamationDiamondIcon size={1} color={services.theme.currentTheme.colors.danger}/>
            <ReasonBox>
                <ReasonTitleBox>
                    {props.title}
                </ReasonTitleBox>
                <ReasonDetailsBox innerHtml={props.blockingReason}/>
            </ReasonBox>

        </ContainerBox>
    );
});
