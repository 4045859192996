import styled, {css} from "styled-components";


export const BagsCategoryTitleBox = styled.div<{$hasError: boolean}>`
    font-size: ${props => props.theme.fontSize.h2};
    font-weight: ${props => props.theme.fontWeight.bold};
    
    ${props => props.$hasError 
            ? css`
                color: ${props => props.theme.colors.danger};
            `
            : ``
    }
`