import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';
export const PersonCircleFilledIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2} fill="none" {...props} viewBox="0 0 79 79" ratio={79 / 79}>
            <rect x="1.21875" y="1.21875" width="76.5625" height="76.5625" rx="38.2812" stroke="#102930"
                  strokeWidth="2.4375"/>
            <g clipPath="url(#clip0_36904_4554)">
                <path
                    d="M39.5001 41.6337C42.0459 41.6337 44.4874 40.6223 46.2876 38.8222C48.0878 37.022 49.0991 34.5804 49.0991 32.0346C49.0991 29.4888 48.0878 27.0472 46.2876 25.247C44.4874 23.4469 42.0459 22.4355 39.5001 22.4355C36.9542 22.4355 34.5127 23.4469 32.7125 25.247C30.9123 27.0472 29.901 29.4888 29.901 32.0346C29.901 34.5804 30.9123 37.022 32.7125 38.8222C34.5127 40.6223 36.9542 41.6337 39.5001 41.6337ZM33.1873 43.7668C27.2479 43.7668 22.4351 48.5797 22.4351 54.5191C22.4351 55.6523 23.355 56.5655 24.4815 56.5655H54.5186C55.6518 56.5655 56.5651 55.6456 56.5651 54.5191C56.5651 48.5797 51.7522 43.7668 45.8128 43.7668H33.1873Z"
                    fill="#102930"/>
            </g>
            <defs>
                <clipPath id="clip0_36904_4554">
                    <rect width="34.13" height="34.13" fill="white" transform="translate(22.4351 22.4355)"/>
                </clipPath>
            </defs>
        </SvgIcon>
    )
}
