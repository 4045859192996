import {ExtrasSsrsSectionRenderer} from "../../base-renderers/extras-ssrs-section.renderer";
import {IBookingViewModel} from "../../../../../services/booking/models/booking-view-model.interface";
import {NullableString} from "../../../../../types/nullable-types";
import React from "react";

export class PetsExtrasSectionRenderer extends ExtrasSsrsSectionRenderer {
    constructor(booking: IBookingViewModel) {
        super(booking.services.ssrTypes.getPetsSsrTypes(), booking);
    }

    getTitle(): string {
        return this.services.language.translate(`Pets`);
    }

    protected _renderDialogSubtitle(): NullableString | React.ReactElement {
        return this.services.language.translate("Don't leave your friend");
    }

    protected _getExtrasLargeTilePicture(): NullableString {
        return this.services.theme.currentTheme.assets.images.extras.pets_2x_jpg.toString();
    }

    protected _getMarketingMessage(): NullableString {
        return this.services.language.translate(`Bring 'em along! Your furry friends can fly with you. Book early to reserve your space before it's full.`);
    }
}
