import React from "react";
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";

export const StarsIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={3} {...props} viewBox="0 0 91 91" ratio={91 / 91}>
            <g clipPath="url(#clip0_36043_27071)">
                <path
                    d="M57.9061 27.0689C58.1371 27.8865 58.8836 28.4375 59.719 28.4375C60.5543 28.4375 61.3008 27.8865 61.5319 27.0689L63.9846 18.4844L72.5692 16.0316C73.3867 15.8006 73.9377 15.0541 73.9377 14.2188C73.9377 13.3834 73.3867 12.6369 72.5692 12.4059L63.9846 9.95312L61.5319 1.36855C61.3008 0.550977 60.5543 0 59.719 0C58.8836 0 58.1371 0.550977 57.9061 1.36855L55.4533 9.95312L46.8688 12.4059C46.0512 12.6369 45.5002 13.3834 45.5002 14.2188C45.5002 15.0541 46.0512 15.8006 46.8688 16.0316L55.4533 18.4844L57.9061 27.0689ZM37.9287 27.9754C36.3647 24.7939 31.8324 24.7939 30.2684 27.9754L22.0393 44.6824L3.64377 47.3662C0.160171 47.8639 -1.24393 52.165 1.2799 54.6355L14.5922 67.6457L11.4641 86.0057C10.8598 89.4893 14.5211 92.1553 17.6492 90.5023L34.1074 81.8289L50.5656 90.5023C53.6938 92.1553 57.3551 89.4893 56.7508 86.0057L53.6049 67.6457L66.9172 54.6355C69.441 52.165 68.0547 47.8639 64.5533 47.3662L46.1578 44.6824L37.9287 27.9754ZM76.2127 43.225L78.1678 50.0855C78.3455 50.7254 78.9498 51.1875 79.6252 51.1875C80.3006 51.1875 80.8871 50.7432 81.0826 50.0855L83.0377 43.225L89.8983 41.2699C90.5381 41.0922 91.0002 40.4879 91.0002 39.8125C91.0002 39.1371 90.5559 38.5506 89.8983 38.3551L83.0377 36.4L81.0826 29.5395C80.9049 28.8996 80.3006 28.4375 79.6252 28.4375C78.9498 28.4375 78.3633 28.8818 78.1678 29.5395L76.2127 36.4L69.3522 38.3551C68.7123 38.5328 68.2502 39.1371 68.2502 39.8125C68.2502 40.4879 68.6945 41.0744 69.3522 41.2699L76.2127 43.225Z"
                    fill="#FFC409"/>
            </g>
            <defs>
                <clipPath id="clip0_36043_27071">
                    <rect width="91" height="91" fill="white"/>
                </clipPath>
            </defs>

        </SvgIcon>
    )
}
