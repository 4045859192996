import React from 'react';
import styled, {ThemeProvider} from 'styled-components';
import {observer} from "mobx-react";
import {SearchMaskBookNowButtonComponent} from "../common/search-mask-book-now-button.component";
import {useServices} from "../../../hooks/use-services.hook";
import {
    DestinationStation,
    OriginStation,
    StationsContainerBox
} from "../webapp/small-screen/search-mask.webapp.small-screen.component";
import {
    SearchMaskSwapStationsButtonSmallScreenComponent
} from "../webapp/small-screen/search-mask-swap-stations-button.small-screen.component";
import {
    FlightDatesDropdownComponent
} from "../../flight-search/dates-selection/drop-down/flight-dates-dropdown.component";
import {
    PassengersTypesDropDownComponent
} from "../../flight-search/passenger-types-selection/drop-down/passengers-types-drop-down.component";
import {CardBox} from "../../../basic-components/card/card.box";
import {
    SearchMaskPsoMarketUserOptionsInputSmallScreenComponent
} from "../common/search-mask-pso-market-user-options-input.small-screen.component";
import {IonIcon} from "@ionic/react";
import {arrowDown} from "ionicons/icons";

export const SearchMaskBox = styled(CardBox)`
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.spacing12};
`

const HeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap12};
    width: 100%;
    justify-content: flex-start;
    color: ${props => props.theme.colors.mediumTint};
`

const TitleBox = styled.div`
    color: ${props => props.theme.colors.darkTint};
    font-size: ${props => props.theme.fontSize.h3};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

export const SearchMaskHybridComponent: React.FC = observer(() => {
    const services = useServices();

    const startBooking = async () => {
        await services.flightSearch.startBooking();
    }

    const startWizardSearch = () => {
        services.flightSearch.startWizardSearch()
    }

    return (
        <ThemeProvider theme={services.theme.createSearchMaskTheme()}>
            <SearchMaskBox>
                <HeaderBox>
                    <TitleBox>
                        {services.language.translate('Your next flight awaits')}
                    </TitleBox>
                    <IonIcon icon={arrowDown}/>
                </HeaderBox>
                <StationsContainerBox>
                    <SearchMaskSwapStationsButtonSmallScreenComponent/>
                    <OriginStation isReadonly={true} onClick={startWizardSearch}/>
                    <DestinationStation isReadonly={true}
                                        isOnSmallScreen={true}
                                        onClick={() => services.navigator.routes.flightSearch.destinationStation.activate()}/>
                </StationsContainerBox>
                <SearchMaskPsoMarketUserOptionsInputSmallScreenComponent/>
                <FlightDatesDropdownComponent isReadonly={true} onClick={() => services.navigator.routes.flightSearch.flightDates.activate()}/>
                <PassengersTypesDropDownComponent isReadonly={true} onClick={() => services.navigator.routes.flightSearch.passengers.activate()}/>
                <SearchMaskBookNowButtonComponent onClick={startBooking}/>
            </SearchMaskBox>
        </ThemeProvider>
    );
});
