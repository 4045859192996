import React from 'react';
import {PassengerTypeSelectorComponent} from "../../../components/passenger-type-selector/passenger-type-selector.component";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {IPassengerTypeSelector} from "../../../services/passenger-types/passengers-types.service.interface";
import styled from 'styled-components';
import {FlightSearchPage} from "../flight-search.page";
import {FlightSearchTitleComponent} from "../components/flight-search-title.component";
import {ExternalLinkComponent} from "../../../components/external-link/external-link.component";
import {PrimaryButton} from "../../../basic-components/buttons/primary-button";
import {PersonGroupFilledIcon} from "../../../assets/svg/person-group-filled.icon";

const PageContentBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap24};
    
`

const SubTitleBox = styled.div`
    width: 100%;
    font-size: ${props => props.theme.fontSize.h2};
    text-align: center;
`

const PassengerTypesContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap30};
    width: 100%;
`

const FooterBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: ${props => props.theme.fontSize.body1};
    gap: ${props => props.theme.spacing.flexGap16};
    line-height: 1.2;
    padding: ${props => props.theme.spacing.spacing30}
            ${props => props.theme.spacing.spacing12}
            ${props => props.theme.spacing.spacing16};
`


const OverMaxPassengersNumberText = styled(ExternalLinkComponent)`
    display: inline;
    color: ${props => props.theme.colors.medium};
`;

const ChildTravelConditionsTextBox = styled(ExternalLinkComponent)`
    color: ${props => props.theme.colors.medium};
`

const PassengerSelectPageFooter: React.FC = observer(() => {
    const services = useServices();
    const onStartBookingClick = async () => {
        await services.flightSearch.startBooking();
    }
    return (
        <PrimaryButton className={'tst-search-continue'} onClick={onStartBookingClick}>
            {services.language.translate('Continue')}
        </PrimaryButton>

    )
})


export const FlightSearchPassengersTypesSelectionPage: React.FC = observer(() => {
    const services = useServices();
    const passengerTypesSelector = services.flightSearch.searchController.passengers.getAll();

    const title = <FlightSearchTitleComponent icon={<PersonGroupFilledIcon size={1.3}/>} title={services.language.translate('Passengers')}/>


    return (
        <FlightSearchPage title={title}
                          renderFooter={() => <PassengerSelectPageFooter/>}>
            <PageContentBox>
                <SubTitleBox>
                    {services.language.translate('Are you flying with someone else?')}
                </SubTitleBox>

                <PassengerTypesContainerBox>
                    {passengerTypesSelector.map((pSelector: IPassengerTypeSelector) => <PassengerTypeSelectorComponent key={pSelector.description} passengerType={pSelector}/>)}
                </PassengerTypesContainerBox>

                <FooterBox>
                    <OverMaxPassengersNumberText innerHtml={services.externalLinks.groupBooking}/>
                    <ChildTravelConditionsTextBox innerHtml={services.externalLinks.childTravelConditions}/>
                </FooterBox>
            </PageContentBox>

        </FlightSearchPage>
    );
})
