import { TimeSpan } from "../../types/time-span";
import {NullableDate, NullableString, NullableUndefinedDate, NullableUndefinedString} from "../../types/nullable-types";
import {MonthModel} from "./month.model";

export enum TimeOfTheDay {
    Morning,
    Noon,
    Evening
}

export interface ITimeService {
    readonly currentDate: Date;
    parseIsoDate(dateAsString: string): Date;
    customFormat(date: Date, formatString: string): string;
    formatHHmm(date: NullableUndefinedDate | NullableUndefinedString): string;
    formatHHmmss(date: NullableUndefinedDate | NullableUndefinedString): string;
    formatYYY_MM_DD(date: NullableDate | NullableString): string;
    formatYYYMMDD(date: Date | string): string;
    formatDD_MM_YYY(date: NullableDate | NullableString): string;
    formatMM_DD_YYYY_withSlash(date: NullableDate | NullableString): string;
    formatBirthDate(date: Date | NullableUndefinedDate | NullableUndefinedString): string;
    tryConvertToDate(date: NullableUndefinedDate | NullableUndefinedString): NullableDate;

    formatUserFriendlyDate(date: NullableUndefinedDate | NullableUndefinedString): string;
    formatUserFriendlyDayNameDayMonth(date: NullableUndefinedDate | NullableUndefinedString): string;
    formatUserFriendlyMonthDay(date: NullableUndefinedDate | NullableUndefinedString): string

    shortDateInCurrentLocale(date: Date | string): string;
    addDays(toDate: Date | string, days: number): Date;
    addMonths(toDate: Date | string, months: number): Date;
    addYears(toDate: Date | string, years: number): Date;
    addHours(toDate: Date | string, hours: number): Date;
    addMinutes(toDate: Date | string, minutes: number): Date;
    addSeconds(toDate: Date | string, seconds: number): Date;
    addMilliseconds(toDate: Date | string, milliseconds: number): Date;
    addTimeSpan(toDate: Date | string, timeSpan: TimeSpan): Date;
    convertToDate(date: Date | string): Date;
    formatUtc(date: Date | string): string;
    lastDateOfTheMonth(year: number, month: number): Date;
    getDateRange(startDate: Date | string, endDate: Date | string): Date[];

    /**
     * Converts the date into a date with only year, month and day
     * @param date
     */
    makeShortDate(date: Date | string): Date;
    minDate(range: Date[]): Date;
    maxDate(range: Date[]): Date;
    differenceInHours(dateLeft: Date, dateRight: Date): number;
    differenceInCalendarDays(dateLeft: Date, dateRight: Date): number;
    differenceInCalendarYears(dateLeft: Date, dateRight: Date): number;
    formatTravelTime(travelTime: TimeSpan): string;
    computeAgeInYears(dateOfBirth: Date, referenceDate: Date): number;
    getMonthFullNameFromIndex(monthIndex: number): string;
    getMonthFullNameFromMonthNumber(monthNumber: number): string;
    getMonthAbbreviationFromIndex(monthIndex: number): string;
    getMonthAbbreviationFromMonthNumber(monthNumber: number): string;
    getWeekDaysNarrowNames(): string[];
    getWeekDaysAbbreviatedNames(): string[];
    getMonthCalendarWeeks(month: number, year: number): Array<Date[]>;
    getMonthsInRange(from: Date, to: Date): Array<MonthModel>;
    areDatesEqual(date1: NullableUndefinedDate, date2: NullableUndefinedDate): boolean;
    getDayNameAbbreviation(day: number): string;
    getDayFullName(day: number): string;
    getTimeOfTheDay(): TimeOfTheDay;
    formatYear2Digits(year: number): string;
}

