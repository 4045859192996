import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../../hooks/use-services.hook";
import {useScreenMediaQueriesChecks} from "../../../hooks/use-screen-media-queries-checks.hook";
import {BookingCodeComponent} from "../../../components/booking-code/booking-code.component";
import {PrintTicketButtonComponent} from "../../../components/print-ticket/print-ticket-button.component";

const CSS_CLASS_BOOKING_CODE_CONTENT = 'booking-code-content';

const HeaderContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.pageContentVerticalPadding};
    width: 100%;
    
`

const TitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.h1};
    font-weight: ${props => props.theme.fontWeight.bold};
`

const BottomSectionBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const BookingCode = styled(BookingCodeComponent)`
    flex-direction: row-reverse;
    justify-content: flex-end;
    & .${CSS_CLASS_BOOKING_CODE_CONTENT} {
        align-items: flex-start;
    }
`

export const ManageMyBookingDashboardHeaderTitleSmallScreenComponent: React.FC = observer(() => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();

    if(!screenMediaQuery.smallScreen) {
        return null;
    }

    const booking = services.booking.current;

    return (
        <HeaderContainerBox>
            <TitleBox>{services.language.translate('Manage my booking')}</TitleBox>
            <BottomSectionBox>
                <BookingCode recordLocator={booking.recordLocator} contentClassName={CSS_CLASS_BOOKING_CODE_CONTENT}/>
                <PrintTicketButtonComponent booking={booking} hideLabel={false}/>
            </BottomSectionBox>

        </HeaderContainerBox>
    );
})