import styled, {css} from "styled-components";
import React from "react";
import {observer} from "mobx-react";
import {
    chevronDownOutline,
    chevronUpOutline
} from "ionicons/icons";
import {IonIcon} from "@ionic/react";
import {
    IMaturePassengerViewModel
} from "../../../../services/booking/models/passenger/mature-passenger-view-model.interface";
import {IJourneyViewModel} from "../../../../services/booking/models/journey/journey-view-model.interface";
import {
    IBookingSsrsAggregatorViewModel
} from "../../../../services/booking/models/ssrs/aggregator/booking-ssrs-aggregator-view-model.interface";
import {JourneysTabsComponent} from "../../../../components/tabs/journeys-tabs/journeys-tabs.component";
import {TabWrapperComponent} from "../../../../components/tabs/tab-wrapper.component";

export const HeaderBox = styled.div<{$addBottomPadding: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: ${props => props.theme.spacing.spacing16};
    padding: ${props => props.theme.spacing.spacing16} ${props => props.theme.spacing.spacing24} 0 ${props => props.theme.spacing.spacing24};
    border-bottom: 1px solid ${props => props.theme.border.mainColor};
    ${props => props.$addBottomPadding 
        ? css`
                padding-bottom: ${props => props.theme.spacing.spacing16};
            ` 
            : ''
    }
    
`

const HeaderTopSectionBox = styled.div<{$hasChevron: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: ${props => props.$hasChevron ? "pointer": "default"};
    width: 100%;
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.bold};
`


const PassengerNameBox = styled.div`
  text-align: left;
  flex-grow: 1;
`

const ChevronBox = styled.div`
    font-size: 26px;
    color: ${props => props.theme.colors.medium};
`

interface PassengerBagsHeaderComponentProps {
    passenger: IMaturePassengerViewModel;
    journeys: IJourneyViewModel[];
    ssrsAggregator: IBookingSsrsAggregatorViewModel;
    currentJourney: IJourneyViewModel;
    showChevron: boolean;
    isExpanded: boolean;
    onExpandClick?: () => void;
    onJourneyTabClick: (journey: IJourneyViewModel) => void;
}
export const PassengerBagsHeaderComponent: React.FC<PassengerBagsHeaderComponentProps> = observer((props) => {

    const renderChevron = () => {
        if(!props.showChevron) {
            return null;
        }

        if(props.isExpanded) {
            return (
                <ChevronBox>
                    <IonIcon icon={chevronUpOutline} />
                </ChevronBox>
            );
        }

        return (
            <ChevronBox>
                <IonIcon icon={chevronDownOutline}/>
            </ChevronBox>
        );
    }

    const renderJourneysTabs = () => {
        if(props.ssrsAggregator.useSameOptionsForAllFlights) {
            return null;
        }

        if(props.ssrsAggregator.journeysSsrsBuckets.length === 1) {
            return null;
        }

        return (
            <TabWrapperComponent hideBottomBorder={true}>
                <JourneysTabsComponent journeys={props.journeys}
                                       currentJourney={props.currentJourney}
                                       onJourneyTabClick={props.onJourneyTabClick}/>
            </TabWrapperComponent>
        );
    }

    return (
        <HeaderBox $addBottomPadding={props.ssrsAggregator.useSameOptionsForAllFlights}>
            <HeaderTopSectionBox onClick={props.onExpandClick} $hasChevron={props.showChevron}>
                <PassengerNameBox>
                    {props.passenger.getFullName()}
                </PassengerNameBox>
                {renderChevron()}
            </HeaderTopSectionBox>
            {renderJourneysTabs()}
        </HeaderBox>

    )
});

