import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const PlusBigIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1} {...props} viewBox="0 0 21 22" ratio={21 / 22}>
            <path
                d="M12.8326 13.2613V21.0756H7.84603V13.2613H0.0722656V8.31256H7.84603V0.541504H12.8326V8.31526H20.6063V13.264L12.8326 13.2613Z"
                fill="#102930"/>
        </SvgIcon>
    )
}
