import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {ExtrasSectionSmallTileComponent} from "../../../base-component/small-tile/extras-section.small-tile.component";
import {ExtrasSeatsSectionComponentCommonProps} from "../extras-seats-section.component.common-props";

interface ExtrasSeatsSmallTileComponentProps extends ExtrasSeatsSectionComponentCommonProps {

}
export const ExtrasSeatsSmallTileComponent: React.FC<ExtrasSeatsSmallTileComponentProps> = observer((props) => {
    const services = useServices();
    const booking = services.booking.current;

    const selectedSeatsCount = props.countSelectedSeats();

    return(
        <ExtrasSectionSmallTileComponent sectionTitle={services.language.translate('Seats')}
                                           modificationBlockingReason={null}
                                           minDiscountedPrice={booking.minSeatsFee}
                                           minStandardPrice={booking.minSeatsFee}
                                           renderIcon={() => services.ssrTypes.STST.iconInExtrasSmallTile}
                                           onEditButtonClick={props.onEditButtonClick}
                                           numberOfSelectedServices={selectedSeatsCount}
                                           allowEdit={true}/>
    )
})
