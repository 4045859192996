import React from 'react';
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {IFlightSearchControllerViewModel} from "../../services/flight-search/flight-search-controller/flight-search-controller-view-model.interface";
import {CmsPageComponent} from "../cms/cms-page.component";
import {SpinnerComponent} from "../spinner/spinner.component";
import {useServices} from "../../hooks/use-services.hook";
import {CardBox} from "../../basic-components/card/card.box";
import {MAX_FRAME_WIDTH} from "./pso-css-constants";

const RootBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.pageContentVerticalPadding};
    width: min(100%, ${MAX_FRAME_WIDTH});
`

const TitleBox = styled.div`
    width: 100%;
    font-size: ${props => props.theme.fontSize.h1};
    font-weight: ${props => props.theme.fontWeight.bold};
`

const CmsPageContainerBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    width: 100%;
`


interface PsoUserAgreementPageContentComponentProps {
    searchController: IFlightSearchControllerViewModel;
}

export const PsoUserAgreementPageContentComponent: React.FC<PsoUserAgreementPageContentComponentProps> = observer((props) => {
    const services = useServices();
    const marketName = props.searchController.getPsoMarket()?.marketName;

    const renderContent = () => {
        if(marketName) {
            return (
                <CmsPageContainerBox>
                    <CmsPageComponent useUrl={services.cms.getPsoUrl(marketName)}/>
                </CmsPageContainerBox>

            );
        } else {
            return (<SpinnerComponent/>);
        }
    }

    return (
        <RootBox>
            <ContentBox>
                <TitleBox>
                    {services.language.translate('Territorial continuity regime')}
                </TitleBox>
                {renderContent()}
            </ContentBox>

        </RootBox>
    );
})
