import React from "react";
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";

export const PlusPriorityLargeIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={6} {...props} viewBox="0 0 113 95" ratio={113 / 95}>
            <path
                d="M89.103 17.6206C93.7222 17.6206 97.4668 13.876 97.4668 9.25682C97.4668 4.63765 93.7222 0.893066 89.103 0.893066C84.4838 0.893066 80.7393 4.63765 80.7393 9.25682C80.7393 13.876 84.4838 17.6206 89.103 17.6206Z"
                fill="#102930"/>
            <path
                d="M65.9255 88.1136C65.5106 89.1103 65.4702 90.2234 65.8117 91.2475C66.1533 92.2716 66.8536 93.1377 67.7837 93.6859C69.6426 94.6154 72.4303 93.6859 73.3598 90.8982L79.8646 71.383L73.3598 63.0193L65.9255 88.1136Z"
                fill="#102930"/>
            <path
                d="M111.408 46.2918L99.3267 35.1404L93.7506 25.8471C92.8219 24.9177 91.8901 23.0625 89.104 23.0625L83.5287 21.2005C79.8113 20.271 77.0233 20.271 75.1649 21.2005C73.5666 22.2176 66.9885 25.6032 64.0135 28.6348L53.7915 42.5746C53.3338 43.4327 53.0944 44.3902 53.0944 45.3627C53.0944 46.3352 53.3338 47.2927 53.7915 48.1507L49.1449 59.3014C48.6439 58.7961 47.9908 58.4696 47.286 58.3719L41.7106 55.5865C38.923 54.6571 36.1345 55.5865 34.2763 58.3742L24.0536 80.6755C23.1242 83.4632 24.0536 86.2516 26.8413 87.1803L30.5562 89.0393C30.5562 85.3244 34.271 82.5345 37.9905 82.5345C38.9199 82.5345 40.7781 83.464 41.7053 83.464L43.5643 79.7491L58.4328 49.0825C59.3623 49.0825 60.2918 48.153 61.2205 47.2235L70.5183 34.2071L77.0231 30.4922L72.3772 50.9384C71.4478 54.6533 73.3067 57.4432 75.1649 59.3022L85.3868 72.3118L90.9629 89.968C91.8924 91.827 93.7506 93.6829 96.539 92.7557C98.398 91.8262 100.254 89.968 99.3267 87.1796C98.1754 83.3423 94.6253 72.3118 94.6253 72.3118C94.6253 72.3118 93.0651 67.6652 92.8211 67.6652C90.3985 63.9696 86.3163 58.3719 86.3163 58.3719L90.9629 38.8568C95.6007 43.1607 99.9263 47.7357 105.831 52.7966C107.69 54.6555 110.478 53.7261 111.408 52.7966C113.267 50.9376 112.337 48.15 111.408 46.2918Z"
                fill="#102930"/>
            <path
                d="M37.1761 93.6867C39.229 93.6867 40.8933 92.0225 40.8933 89.9696C40.8933 87.9167 39.229 86.2524 37.1761 86.2524C35.1232 86.2524 33.459 87.9167 33.459 89.9696C33.459 92.0225 35.1232 93.6867 37.1761 93.6867Z"
                fill="#102930"/>
            <path
                d="M6.5611 28.7329H30.7229C31.2052 28.7003 31.6593 28.494 32.0011 28.1522C32.3429 27.8104 32.5492 27.3563 32.5818 26.874C32.5485 26.3921 32.3417 25.9387 31.9998 25.5975C31.658 25.2563 31.2041 25.0504 30.7221 25.0181H6.5611C6.07882 25.0507 5.62472 25.257 5.28292 25.5988C4.94112 25.9406 4.73479 26.3947 4.70215 26.877C4.73549 27.3588 4.94213 27.8121 5.28386 28.1533C5.6256 28.4945 6.07931 28.7004 6.5611 28.7329Z"
                fill="#102930"/>
            <path
                d="M27.0046 53.781H2.84357C2.36129 53.8136 1.90719 54.02 1.56539 54.3618C1.22359 54.7036 1.01726 55.1577 0.984619 55.64C1.01796 56.1217 1.2246 56.5751 1.56634 56.9162C1.90807 57.2574 2.36178 57.4633 2.84357 57.4959H27.0054C27.4876 57.4632 27.9417 57.2569 28.2835 56.9151C28.6253 56.5733 28.8317 56.1192 28.8643 55.6369C28.8309 55.1551 28.6242 54.7016 28.2823 54.3604C27.9404 54.0192 27.4865 53.8134 27.0046 53.781Z"
                fill="#102930"/>
            <path
                d="M14.9238 41.7228C14.9572 42.2046 15.1638 42.6579 15.5055 42.9991C15.8473 43.3403 16.301 43.5462 16.7828 43.5787H40.9446C41.4268 43.5461 41.881 43.3398 42.2228 42.998C42.5646 42.6562 42.7709 42.2021 42.8035 41.7198C42.8035 40.7903 42.8035 39.8608 41.874 39.8608H16.7828C16.3 39.8936 15.8455 40.1003 15.5037 40.4427C15.1618 40.7852 14.9558 41.24 14.9238 41.7228Z"
                fill="#102930"/>
        </SvgIcon>
    )
}
