import {
    IBookingSsrsAggregatorViewModel
} from "../../../../services/booking/models/ssrs/aggregator/booking-ssrs-aggregator-view-model.interface";
import {IJourneyViewModel} from "../../../../services/booking/models/journey/journey-view-model.interface";
import React from "react";
import {observer} from "mobx-react";
import {TabWrapperComponent} from "../../../../components/tabs/tab-wrapper.component";
import {JourneysTabsComponent} from "../../../../components/tabs/journeys-tabs/journeys-tabs.component";

interface SsrsSelectionJourneysTabsComponentProps {
    ssrsAggregator: IBookingSsrsAggregatorViewModel;
    journeys: IJourneyViewModel[];
    currentJourney: IJourneyViewModel
    onJourneyTabClick: (journey: IJourneyViewModel) => void;
    hasScroll: boolean;
    hideBorderBottom?: boolean;
}

export const SsrsSelectionJourneysTabsComponent: React.FC<SsrsSelectionJourneysTabsComponentProps> = observer((props) => {
    const journeys = props.journeys;
    const currentJourney = props.currentJourney;
    const shouldRenderJourneyTabs = !(props.ssrsAggregator.useSameOptionsForAllFlights || journeys.length <= 1);

    if(!shouldRenderJourneyTabs && !props.hasScroll) {
        return null;
    }

    return (
        <TabWrapperComponent hideBottomBorder={props.hideBorderBottom ?? true}>
            {shouldRenderJourneyTabs && <JourneysTabsComponent journeys={journeys}
                                                               currentJourney={currentJourney}
                                                               onJourneyTabClick={props.onJourneyTabClick}/>}
        </TabWrapperComponent>
    );
});
