import React from "react";
import {observer} from "mobx-react";
import {
    IJourneyHistoryViewModel
} from "../../../../../services/booking-history/models/journey-history-view-model.interface";
import styled from "styled-components";
import {useServices} from "../../../../../hooks/use-services.hook";
import {PlaneTicketsIcon} from "../../../../../assets/svg/plane-tickets.icon";

const MiddleSectionBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const TimeUntilDepartureContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const TimeUntilDepartureLabelBox = styled.div`
    color: ${props => props.theme.colors.medium};
    font-size: ${props => props.theme.fontSize.caption};
`

const TimeUntilDepartureBox = styled.div`
    font-size: ${props => props.theme.fontSize.h6};
    font-weight: ${props => props.theme.fontWeight.medium};
`

const FlightCanceledLabelBox = styled.div`
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    color: ${props => props.theme.colors.danger};
`

const RecordLocatorBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap16};
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`


export const FlightCardMiddleSectionComponent: React.FC<{flight: IJourneyHistoryViewModel}> = observer((props) => {
    const services = useServices();

    const renderFlightCanceledLabel = () => {
        return (
            <FlightCanceledLabelBox>
                {services.language.translate('Your flight has been canceled')}
            </FlightCanceledLabelBox>
        );
    }

    const renderTimeUntilDeparture = () => {
        return (
            <TimeUntilDepartureContainerBox>
                {props.flight.isFutureFlight &&
                    (<TimeUntilDepartureLabelBox>
                        {services.language.translate('Departing in:')}
                    </TimeUntilDepartureLabelBox>)
                }
                <TimeUntilDepartureBox>
                    {props.flight.timeUntilFlight}
                </TimeUntilDepartureBox>
            </TimeUntilDepartureContainerBox>
        );
    }

    const renderContent = () => {
        if (props.flight.isCanceled) {
            return renderFlightCanceledLabel();
        } else {
            return renderTimeUntilDeparture()
        }
    }

    const onRecordLocatorClickHandler = async () => {

        if(props.flight.recordLocator) {
            await services.device.copyToClipboard(props.flight.recordLocator)
        }
    }

    return (
        <MiddleSectionBox>
            {renderContent()}
            <RecordLocatorBox onClick={onRecordLocatorClickHandler}>
                {props.flight.recordLocator}
                <PlaneTicketsIcon size={1.4}/>
            </RecordLocatorBox>
        </MiddleSectionBox>
    )
})