import {ISsrAvailability} from "./ssr-availability.iterface";
import {ISsrType} from "../../../../ssr-types/ssr-types.service.interface";
import {IDotRezPassengerSsrAvailability} from "../../../../dot-rez-api/data-contracts/booking/ssrs-availability/ssr-availability.data-contracts";
import {SegmentModel} from "../../segment/segment.model";
import {NullableString} from "../../../../../types/nullable-types";

/**
 * The purpose of this class is to handle the case when there is no availability for a SSR.
 * In this case dotREZ doesn't return anything so we will have a null and if we don't handle this the SSR will disappear from UI.
 * But we still need to let the user change his own quantity.
 */
export class MissingSsrAvailabilityModel implements ISsrAvailability {
    constructor(private readonly ssrType: ISsrType, private readonly segment: SegmentModel) {
    }

    get limitPerPassenger(): number {
        return this.segment.passengers
                .max(passengerSegment => passengerSegment.getSsr(this.ssrType).currentQuantity)
    }

    get availableQuantity(): number {
        return this.segment.passengers
            .sum(passengerSegment => {
                const ssr = passengerSegment.getSsr(this.ssrType);
                return ssr.currentQuantity - ssr.newQuantity;
            });
    }

    getPassengerAvailability(passengerKey: string): IDotRezPassengerSsrAvailability | null {
        return null;
    }

    get feeCode(): NullableString {
        return null;
    }

}
