export const CSS_VAR_PAGE_HEADER_VERTICAL_PADDING = '--page-header-vertical-padding';
export const CSS_VAR_PAGE_HEADER_HORIZONTAL_PADDING = '--page-header-horizontal-padding';

export const CSS_VAR_PAGE_CONTENT_VERTICAL_PADDING = '--page-content-vertical-padding';
export const CSS_VAR_PAGE_CONTENT_HORIZONTAL_PADDING = '--page-content-horizontal-padding';

export const CSS_VAR_PAGE_FOOTER_VERTICAL_PADDING = '--page-footer-vertical-padding';
export const CSS_VAR_PAGE_FOOTER_HORIZONTAL_PADDING = '--page-footer-horizontal-padding';

export const CSS_VAR_DIALOG_VERTICAL_PADDING = '--dialog-vertical-padding';
export const CSS_VAR_DIALOG_HORIZONTAL_PADDING = '--dialog-horizontal-padding';