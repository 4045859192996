import React, {useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {useServices} from "../../hooks/use-services.hook";
import {ISupportedLanguageViewModel} from "../../services/language/language.service.interface";
import {IonIcon} from "@ionic/react";
import {globeOutline} from "ionicons/icons";
import {createPopper} from "@popperjs/core";
import {CardBox} from "../../basic-components/card/card.box";
import {isClickInsideElement} from "../../utils/is-click-inside-element";

const LanguageSwitcherBox = styled.div`
    display: flex;
    cursor: pointer;
    color: ${props => props.theme.colors.primaryShade};
`

const CurrentLanguageBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    text-transform: uppercase;
    font-size: ${props => props.theme.fontSize.h4};
`

const DropDownContainerBox = styled(CardBox)<{$isVisible: boolean}>`
    display: ${props => props.$isVisible ? 'flex' : "none"};
    flex-direction: column;
    isolation: isolate;
    border: 1px solid ${props => props.theme.border.mainColor};
    font-size: ${props => props.theme.fontSize.body1};
    color: ${props => props.theme.colors.dark};
`

const DropDownItemBox = styled.div<{$isPrevious: boolean}>`
    padding: ${props => props.theme.spacing.spacing20} ${props => props.theme.spacing.spacing48};
    border-bottom: 1px solid ${props => props.theme.border.mainColor};
    
    &:hover {
        background-color: ${props => props.theme.colors.lightShade};
        border-radius: ${props => props.theme.border.defaultRadius};
    }
    
    ${props => props.$isPrevious
            ? css`
                border-bottom-color: ${props => props.theme.colors.cardBackground};
            `
            : ''
    }
    
    &:last-of-type {
        border-bottom: none;
    }
    
    
`

interface DropDownItemComponentProps {
    index: number;
    currentHoverIndex: number;
    lang: ISupportedLanguageViewModel;
    onClick: (lang: ISupportedLanguageViewModel) => void;
    onHover: (index: number) => void;
}

const DropDownItemComponent: React.FC<DropDownItemComponentProps> = observer((props) => {

    return (
        <DropDownItemBox onClick={() => props.onClick(props.lang)}
                         $isPrevious={props.index === props.currentHoverIndex - 1}
                         onMouseOver={() => props.onHover(props.index)}>
            {props.lang.name}
        </DropDownItemBox>
    )
});

const DropDownItemsComponent: React.FC<{onLangSelected: (lang: ISupportedLanguageViewModel) => void}> = observer((props) => {
    const services = useServices();
    const [currentHoverIndex, setCurrentHoverIndex] = useState(-1);
    const supportedLanguages = services.language.supportedLanguages.filter(l => l.code !== services.language.currentLanguage);

    const renderOnLanguage = (lang: ISupportedLanguageViewModel, index: number) => {
        return (<DropDownItemComponent key={lang.code}
                                               index={index}
                                               currentHoverIndex={currentHoverIndex}
                                               lang={lang}
                                               onClick={props.onLangSelected}
                                               onHover={(idx) => setCurrentHoverIndex(idx)}/>);
    }

    return (
        <>
            {supportedLanguages.map(renderOnLanguage)}
        </>
    )
});

interface LanguageSwitcherComponentProps {
    className?: string;
}

export const LanguageSwitcherComponent: React.FC<LanguageSwitcherComponentProps> = observer((props) => {
    const services = useServices();
    const currentLangElementRef = useRef<HTMLDivElement | null>(null);
    const availableLangElementRef = useRef<HTMLDivElement | null>(null);
    const [showDropDown, setShowDropDown] = useState(false);

    useEffect(() => {
        let popper: any;
        const onDocumentClickHandler = (event: MouseEvent) => {

            if(currentLangElementRef.current && availableLangElementRef.current) {
                if(!isClickInsideElement(event, currentLangElementRef.current)
                    && !isClickInsideElement(event, availableLangElementRef.current)) {
                    //it means the user clicked outside
                    setShowDropDown(false)
                }
            }
        }
        if(currentLangElementRef.current && availableLangElementRef.current && showDropDown) {
            popper = createPopper(currentLangElementRef.current, availableLangElementRef.current, {
                placement: 'bottom-start',
                strategy: "absolute",
            })

            document.addEventListener('click', onDocumentClickHandler);
        }

        return () => {
            if(popper) {
                popper.destroy();
                document.removeEventListener('click', onDocumentClickHandler);
            }

        }

    }, [showDropDown]);

    const onClickHandler = () => {
        setShowDropDown(!showDropDown);
    }



    const onLangSelected = (lang: ISupportedLanguageViewModel) => {
        services.language.changeCurrentLanguage(lang.code);
        setShowDropDown(false);
    }

    return (
        <LanguageSwitcherBox className={props.className}>
            <CurrentLanguageBox ref={currentLangElementRef} onClick={onClickHandler}>
                <span>{services.language.currentLanguageIso2}</span>
                <IonIcon icon={globeOutline}/>
            </CurrentLanguageBox>
            <DropDownContainerBox ref={availableLangElementRef} $isVisible={showDropDown}>
                <DropDownItemsComponent onLangSelected={onLangSelected}/>
            </DropDownContainerBox>
        </LanguageSwitcherBox>

    )
});