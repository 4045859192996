import React, {useState} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {FlightsDatesSelectorModel} from "../../../../services/flight-search/dates-selection/flights-dates-selector.model";
import {MonthModel} from "../../../../services/time/month.model";
import {FlightDayComponent} from "../calendar/flight-day.component";
import {CalendarComponent} from "../../../calendar/calendar.component";
import {DepartureFlightSelectedDatePreviewComponent} from "../selected-date-preview/departure-flight-selected-date-preview.component";
import {ReturnFlightSelectedDatePreviewComponent} from "../selected-date-preview/return-flight-selected-date-preview.component";
import {FlightDirectionSelectorComponent} from "../../flight-direction/flight-direction-selector.component";
import {MonthSelectorComponent} from "../../../calendar/month-selector.component";
import {LowFareDisclaimerMessageForDropDownComponent} from "../../../low-fare-disclaimer-message/lowfare-disclamer-message.component";

const ContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`


const HeaderBox = styled.div`
    padding: ${props => props.theme.spacing.spacing8};
    
`

const ContentBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-grow: 1;
    border-radius: ${props => props.theme.border.radiusPx(2)};
    background-color: ${props => props.theme.colors.cardBackground};
`


const ContentSectionBox = styled.div`
    flex-basis: 50%;
    
    
`

const ContentLeftSectionBox = styled(ContentSectionBox)`
    border-right: 1px solid ${props => props.theme.border.mainColor};
    
`

const ContentRightSectionBox = styled(ContentSectionBox)`
    
`

const CalendarContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    
`

const DatesSelectionPreviewBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    padding: ${props => props.theme.spacing.spacing16};
    gap: ${props => props.theme.spacing.flexGap16}
`


const FooterBox = styled.div`
    padding: 4px ${props => props.theme.spacing.spacing8};
`

const FlightDirectionSelector = styled(FlightDirectionSelectorComponent)`
    padding: ${props => props.theme.spacing.spacing24};
`

function getInitialMonthIndex(departureDate: Date | null, availableMonths: MonthModel[]): number {
    if(!departureDate) {
        return 0;
    }

    const index = availableMonths.findIndex(m => m.year === departureDate.getFullYear() && m.month === departureDate.getMonth());

    if(index < 0) {
        return index;
    }

    if(index === availableMonths.length - 1) {
        return Math.max(0, index - 1);
    }

    return index;

}

export interface FlightDatesSelectorSwiperComponentProps {
    flightsDatesSelector: FlightsDatesSelectorModel;
    onDateClick: (date: Date) => void;
    captureSelectedDateElementRef?: (date: Date, element: HTMLElement | null) => void;
    onDone?: () => void;
    hideHeader?: boolean;
    hideFooter?: boolean;

}
export const FlightDatesSelectorSwiperComponent: React.FC<FlightDatesSelectorSwiperComponentProps> = observer((props) => {
    const datesSelector = props.flightsDatesSelector;
    const months = datesSelector.availableMonths;
    if(months.length === 1) {
        months.push(months[0].nextMonth);
    }
    const [selectedMonthIndex, setSelectedMonthIndex] = useState(Math.max(0, getInitialMonthIndex(datesSelector.departureDate, months)));

    if(months.length === 0) {
        return null;
    }

    let firstMonth = months[selectedMonthIndex];
    let secondMonth = months[selectedMonthIndex + 1];


    const renderDate = (date: Date) => {
        return (
            <FlightDayComponent date={date}
                                shouldRenderPrice={true}
                                flightsDatesSelector={props.flightsDatesSelector}
                                captureSelectedDateElementRef={props.captureSelectedDateElementRef}/>
        )
    }


    const onLeftButtonClick = () => {
        setSelectedMonthIndex(Math.max(0, selectedMonthIndex - 1))
    }

    const onRightButtonClick = () => {
        setSelectedMonthIndex(Math.min(months.length - 2, selectedMonthIndex + 1));
    }

    const onMonthSelectionChanged = (index: number) => {
        setSelectedMonthIndex(index);
    }

    const renderHeader = () => {
        if(props.hideHeader) {
            return null;
        }
        return (
            <HeaderBox>
                <MonthSelectorComponent availableMonths={months} currentIndex={selectedMonthIndex} onSelected={onMonthSelectionChanged}/>
            </HeaderBox>
        );
    }

    const renderLeftSectionFooter = () => {
        if(props.hideFooter) {
            return null;
        }

        return (
            <DatesSelectionPreviewBox>
                <DepartureFlightSelectedDatePreviewComponent flightsDatesSelector={props.flightsDatesSelector}/>
                <ReturnFlightSelectedDatePreviewComponent flightsDatesSelector={props.flightsDatesSelector}/>
            </DatesSelectionPreviewBox>
        );
    }

    const renderRightSectionFooter = () => {
        if(props.hideFooter) {
            return null;
        }
        return (
            <FlightDirectionSelector flightsDatesSelector={props.flightsDatesSelector} onDone={props.onDone}/>
        );
    }

    const renderPriceDisclaimer = () => {
        if(props.hideFooter) {
            return null;
        }

        return (
            <FooterBox>
                {<LowFareDisclaimerMessageForDropDownComponent/>}
            </FooterBox>
        );
    }

    return (
        <ContainerBox>
            {renderHeader()}
            <ContentBox>
                <ContentLeftSectionBox>
                    <CalendarContainerBox>
                        <CalendarComponent key={`${firstMonth.year}_${firstMonth.month}`}
                                           month={firstMonth.month}
                                           year={firstMonth.year}
                                           showLeftButton={months.length > 2 && selectedMonthIndex > 0}
                                           leftButtonDisabled={selectedMonthIndex === 0}
                                           onLeftButtonClick={onLeftButtonClick}
                                           renderDate={renderDate}
                                           onDateClick={props.onDateClick}/>
                        {renderLeftSectionFooter()}
                    </CalendarContainerBox>

                </ContentLeftSectionBox>

                <ContentRightSectionBox>
                    <CalendarContainerBox>
                        <CalendarComponent key={`${secondMonth.year}_${secondMonth.month}`}
                                           month={secondMonth.month}
                                           year={secondMonth.year}
                                           showRightButton={months.length > 2 && selectedMonthIndex < months.length - 2}
                                           rightButtonDisabled={selectedMonthIndex === months.length - 2}
                                           onRightButtonClick={onRightButtonClick}
                                           renderDate={renderDate}
                                           onDateClick={props.onDateClick}/>
                        {renderRightSectionFooter()}
                    </CalendarContainerBox>

                </ContentRightSectionBox>
            </ContentBox>

            {renderPriceDisclaimer()}

        </ContainerBox>
    )
})