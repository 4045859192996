import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import {NullablePrice} from "../../services/currency/price";
import styled from "styled-components";
import {PriceComponent, PriceLabelPositionEnum} from "./price.component";
import {Check} from "../../types/type-checking";
import {PRICE_DECIMAL_PART, PRICE_WHOLE_PART} from "./price-component-css-classes";

const HorizontalContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    
`

const VerticalContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    
`

const DiscountedPriceBox = styled(PriceComponent)`
  color: ${props => props.theme.colors.darkTint};
`

export enum PriceWithDiscountOrientation {
    Horizontal,
    Vertical
}


const StandardPriceBox = styled(PriceComponent)<{orientation: PriceWithDiscountOrientation}>`
  
  margin-left: ${props => props.orientation === PriceWithDiscountOrientation.Horizontal ? props.theme.spacing.spacing12 : "0"};

  & {
    .${PRICE_WHOLE_PART}, .${PRICE_DECIMAL_PART} {
      text-decoration: line-through;
      text-decoration-thickness: 2px;
    }
  }
`


interface PriceWithDiscountComponentProps extends PropsWithChildren {
    discountedPrice: NullablePrice;
    standardPrice: NullablePrice;
    className?: string;
    label?: string | React.ReactElement | null;
    labelPosition?: PriceLabelPositionEnum;
    orientation?: PriceWithDiscountOrientation;
}

export const PriceWithDiscountComponent: React.FC<PriceWithDiscountComponentProps> = observer((props) => {

    const orientation = Check.isNullOrUndefined(props.orientation) ? PriceWithDiscountOrientation.Vertical : props.orientation;
    const discountedPrice = props.discountedPrice ?? props.standardPrice;
    const standardPrice = props.standardPrice ?? props.discountedPrice;

    if(!discountedPrice || !standardPrice) {
        return null;
    }

    let labelPosition = props.labelPosition;
    if(Check.isNullOrUndefined(labelPosition)) {
        if(discountedPrice.equals(props.standardPrice)) {
            labelPosition = PriceLabelPositionEnum.Start;
        } else {
            labelPosition = PriceLabelPositionEnum.Top;
        }
    }


    if(discountedPrice.equals(props.standardPrice)) {
        return (
            <PriceComponent price={discountedPrice}
                            className={props.className}
                            label={props.label}/>
        );
    }

    let Box = VerticalContainerBox;


    if(orientation === PriceWithDiscountOrientation.Horizontal) {
        Box = HorizontalContainerBox;
    }

    return (
        <Box className={props.className}>
            <DiscountedPriceBox price={discountedPrice}
                                label={props.label}
                                labelPosition={labelPosition}/>
            <StandardPriceBox price={standardPrice} orientation={orientation} />
        </Box>
    )
});
