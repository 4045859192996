import React, {CSSProperties} from "react";
import {observer} from "mobx-react";
import {IBookingViewModel} from "../../services/booking/models/booking-view-model.interface";
import {useServices} from "../../hooks/use-services.hook";
import {TicketSegmentComponent} from "./components/ticket-segment.component";
import {getPageVerticalGap} from "./print-ticket.helpers";

interface PrintTicketComponentProps {
    booking: IBookingViewModel;
    elementRef?: React.MutableRefObject<HTMLDivElement | null>;

}
export const TicketComponent: React.FC<PrintTicketComponentProps> = observer((props) => {
    const services = useServices();
    const theme = services.theme.currentTheme;
    const styles: CSSProperties = {
        display: "flex",
        flexDirection: "column",
        gap: getPageVerticalGap(theme),
        width: '100%',
        fontSize: services.theme.currentTheme.fontSize.h5,
        lineHeight: 1.1,
    };

    const segments = props.booking.getAllSegments();

    return (
        <div style={styles} ref={props.elementRef}>
            {segments.map((s, i) => <TicketSegmentComponent key={s.segmentKey} booking={props.booking} segment={s} isLastOne={i === segments.length - 1}/>)}
        </div>
    )
})