import React from "react";
import {observer} from "mobx-react";
import {ExtrasSectionSmallTileComponent} from "./extras-section.small-tile.component";
import {ExtrasSsrSectionComponentCommonProps} from "../extras-ssr-section.component.common-props";

interface ExtrasSsrSectionSmallTileComponentProps extends ExtrasSsrSectionComponentCommonProps {
    renderIcon: () => React.ReactElement | null;
}
export const ExtrasSsrSectionSmallTileComponent: React.FC<ExtrasSsrSectionSmallTileComponentProps> = observer((props) => {

    const ssrsAggregator = props.ssrsAggregator;

    return (
        <ExtrasSectionSmallTileComponent sectionTitle={props.sectionTitle}
                                         minDiscountedPrice={ssrsAggregator.canBeModified ? ssrsAggregator.discountedMinPrice : null}
                                         minStandardPrice={ssrsAggregator.canBeModified ? ssrsAggregator.standardMinPrice : null}
                                         modificationBlockingReason={ssrsAggregator.modificationBlockingReason}
                                         renderIcon={props.renderIcon}
                                         onEditButtonClick={props.onEditButtonClick}
                                         numberOfSelectedServices={props.countSelectedSsrs()}
                                         allowEdit={ssrsAggregator.canBeModified}/>
    );
});
