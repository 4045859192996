import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {IonIcon} from "@ionic/react";
import {chevronDown, chevronUp} from "ionicons/icons";
import styled from "styled-components";
import {
    SwapStationsButtonBaseBox,
    SwapStationsChevronIconWrapperBox
} from "../boxes/swap-stations-button.base-boxes";

const SwapStationsButtonBox = styled(SwapStationsButtonBaseBox)`
    flex-direction: row;
    right: ${props => props.theme.spacing.spacing12};
    top: 50%;
    transform: translateY(-50%);
`


export const SearchMaskSwapStationsButtonSmallScreenComponent: React.FC = observer(() => {
    const services = useServices();
    return (
        <SwapStationsButtonBox onClick={() => services.flightSearch.switchStations()}>
            <SwapStationsChevronIconWrapperBox>
                <IonIcon icon={chevronUp}/>
            </SwapStationsChevronIconWrapperBox>
            <SwapStationsChevronIconWrapperBox>
                <IonIcon icon={chevronDown}/>
            </SwapStationsChevronIconWrapperBox>
        </SwapStationsButtonBox>
    );
})