import React, {useEffect, useState} from "react";
import styled, {css} from "styled-components";
import { CardBox } from "../../basic-components/card/card.box";
import {observer} from "mobx-react";
import {Check} from "../../types/type-checking";
import {scrollElementIntoViewLazySmooth} from "../../utils/scroll-element-into-view";
import {AirlineTheme} from "../../services/theme/interfaces/airline.theme";
import {ChevronDownIcon} from "../../assets/svg/chevron-down.icon";

const TRANSITION_TIME = '0.4s';

function computeRootTransition(isExpanded: boolean, isAnimated: boolean, theme: AirlineTheme) {
    if(isAnimated) {
        return css`
            gap: ${isExpanded ? theme.spacing.spacing20: "0"};
            transition: gap ${TRANSITION_TIME} ease-in-out;
            `
    }

    if(isExpanded) {
        return css`
                gap: ${theme.spacing.spacing20};
            `
    }



    return css`
                gap: 0;
            `;
}


export const AccordionBox = styled(CardBox)<{$animated: boolean; $isExpanded: boolean;}>`
    display: flex;
    flex-direction: column;
    padding: ${props => props.theme.spacing.spacing20};
    ${props => computeRootTransition(props.$isExpanded, props.$animated, props.theme)}
`

export const AccordionHeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
`

const AccordionHeaderContentBox = styled.div`
    flex-grow: 1;
`

const StandardArrowBox = styled.div<{$animated: boolean; $isExpanded: boolean;}>`
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.medium};
    ${props => props.$isExpanded
        ? css`
            transform: rotate(-180deg);
        `
        : ''
    }

    ${props => props.$animated
            ? css`
            transition: transform ${TRANSITION_TIME} ease-in-out;
        `
        : ''
    }
    
`


function computeContentTransition(isExpanded: boolean, isAnimated: boolean, scrollHeight: string) {
    if(isAnimated) {
        return css`
                height: ${isExpanded ? scrollHeight: "0"};
                transition: height ${TRANSITION_TIME} ease-in-out;
                overflow: hidden;
            `
    }

    if(!isExpanded) {
        return css`
                height: 0;
                overflow: hidden;
            `
    }

    return '';
}

export const AccordionContentBox = styled.div<{$isExpanded: boolean; $isAnimated: boolean; $scrollHeight: string}>`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${props => computeContentTransition(props.$isExpanded, props.$isAnimated, props.$scrollHeight)}
`

const ArrowBox = styled.div`
    flex-shrink: 0;
`

export interface IAccordionController {
    expand: () => void;
    collapse: () => void;
}

interface AccordionComponentProps {
    isExpanded?: boolean;
    renderHeader: () => React.ReactElement | string;
    renderContent: () => React.ReactElement;
    renderArrowUp?: () => React.ReactElement;
    renderArrowDown?: () => React.ReactElement;
    expandOnArrowClick?: boolean;
    className?: string;
    headerClassName?: string;
    provideController?: (controller: IAccordionController) => void;
    onChange?: (isExpanded: boolean) => void;
    scrollIntoViewOnExpand?: boolean;
    animated?: boolean;

}


export const AccordionComponent: React.FC<AccordionComponentProps> = observer((props) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(props.isExpanded ?? false);
    const rootElementRef = React.useRef<HTMLDivElement | null>(null);
    const contentElementRef = React.useRef<HTMLDivElement | null>(null);
    const [contentScrollHeight, setContentScrollHeight] = useState("0");
    const [isAnimated, setIsAnimated] = useState(false);

    let expandOnArrowClick: boolean = Check.isNullOrUndefined(props.expandOnArrowClick) ? true : props.expandOnArrowClick;



    useEffect(() => {
        const contentEl = contentElementRef.current;
        if(contentEl) {
            setContentScrollHeight(`${contentEl.scrollHeight}px`);
        }
    }, [isExpanded]);

    const updateExpandedState = (value: boolean) => {
        if(value === isExpanded) {
            return;
        }
        setIsExpanded(value);
        if(props.animated) {
            setIsAnimated(true);
        }
        props.onChange && props.onChange(value);
    }

    props.provideController && props.provideController({
        expand: () => updateExpandedState(true),
        collapse: () => updateExpandedState(false),
    });

    const onTransitionEnd = () => {
        setIsAnimated(false);
        if(isExpanded && props.scrollIntoViewOnExpand) {
            scrollElementIntoViewLazySmooth(rootElementRef.current, {
                block: "center"
            });

        }
    }

    const renderContent = () => {

        return(
            <AccordionContentBox ref={contentElementRef}
                                 $isExpanded={isExpanded}
                                 $isAnimated={isAnimated}
                                 $scrollHeight={contentScrollHeight}
                                 onTransitionEnd={onTransitionEnd}>
                {props.renderContent()}
            </AccordionContentBox>
        )
    }

    const renderArrow = () => {

        if(props.isExpanded && props.renderArrowUp) {
            return props.renderArrowUp();
        }

        if(!props.isExpanded && props.renderArrowDown) {
            return props.renderArrowDown();
        }

        return (
            <StandardArrowBox $isExpanded={isExpanded} $animated={isAnimated}>
                <ChevronDownIcon/>
            </StandardArrowBox>
        );
    }

    const onHeaderClick = () => {
        updateExpandedState(!isExpanded);
    }

    return (
        <AccordionBox ref={rootElementRef}
                      $isExpanded={isExpanded}
                      $animated={isAnimated}
                      className={props.className} >
            <AccordionHeaderBox className={props.headerClassName}>
                <AccordionHeaderContentBox onClick={onHeaderClick}>
                    {props.renderHeader()}
                </AccordionHeaderContentBox>
                <ArrowBox onClick={() => expandOnArrowClick && onHeaderClick()}>
                    {renderArrow()}
                </ArrowBox>
            </AccordionHeaderBox>
            {renderContent()}
        </AccordionBox>
    );
});
