import { ManageSingleSsrModel } from "./manage-single-ssr.model";
import { IManageSingleSsrsEditOptions } from "./manage-ssrs-view-model.interface";
import {CabinBagsDisclaimerForAtrAircraftComponent} from "../../../../../pages/booking-flow/bags-selection/components/cabin-bags/atr-disclaimer/cabin-bags-disclaimer-for-atr-aircraft.component";
import React from "react";

export class ManageMyBookingPriorityBoarding extends ManageSingleSsrModel{
    async edit(options?: IManageSingleSsrsEditOptions): Promise<void>{
        await super.edit({
            disclaimer: <CabinBagsDisclaimerForAtrAircraftComponent/>,
            ...options
        });
    }
}
