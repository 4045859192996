import {IServiceFactory} from "../../service-factory.interface";
import {BookingFlowStrategy} from "./booking-flow/booking-flow.strategy";
import {IDotRezBookingSession} from "../../dot-rez-api/session/booking-session/dot-rez-booking.session.interface";
import {IDotRezBookingSessionData} from "../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {IFlightSearchControllerViewModel} from "../../flight-search/flight-search-controller/flight-search-controller-view-model.interface";
import {FlightSearchControllerModel} from "../../flight-search/flight-search-controller/flight-search-controller.model";
import {createEmptyBookingSessionData} from "../models/create-empty-booking-session-data";
import {IDotRezInitialBookingData} from "./booking-initial-data.interface";
import {IBookingStateQueryBuilder} from "../../dot-rez-api/graph-ql/queries/booking-state-query-builder.interface";
import {IBookingStrategyOptions} from "./booking-strategy-base";
import {IDotRezBookingData} from "../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {Station} from "../../stations/station.service.interface";
import {ManageMyBookingStrategy} from "./manage-my-booking/manage-my-booking.strategy";
import {CheckInStrategy} from "./check-in/check-in.strategy";
import {ViewBookingDetailsStrategy} from "./view-booking-details/view-booking-details.strategy";
import {TransientBookingStrategy} from "./view-booking-details/transient-booking.strategy";


interface IRestoreBookingOptions {
    createQueryBuilder: (bookingSession: IDotRezBookingSession) => IBookingStateQueryBuilder;
}

export class BookingStrategyFactory {
    constructor(private readonly services: IServiceFactory) {

    }

    async startNewBookingStrategy(bookingSession: IDotRezBookingSession, searchController: IFlightSearchControllerViewModel): Promise<BookingFlowStrategy> {
        return new BookingFlowStrategy(this.services, {
            initialBookingData: null,
            bookingSession: bookingSession,
            bookingSessionData: createEmptyBookingSessionData(this.services.currency.current),
            searchController: searchController
        });
    }

    async tryRestoreBookingStrategy(): Promise<BookingFlowStrategy | null> {
        const strategyOptions = await this._tryRestoreStrategy({
            createQueryBuilder: bookingSession => this._createFullBookingStateQueryBuilder(bookingSession)
        });

        if(strategyOptions) {
            return new BookingFlowStrategy(this.services, strategyOptions);
        }

        return null;
    }



    async createManageMyBookingStrategy(bookingSession: IDotRezBookingSession, bookingData: IDotRezBookingData): Promise<ManageMyBookingStrategy> {
        await this._impersonate(bookingSession, bookingData);
        const queryBuilder = this._createFullBookingStateQueryBuilder(bookingSession, bookingData).useSegmentsDisruptionDelays();
        const bookingSessionData = this._createFullBookingSessionData(await queryBuilder.getBookingState());
        const searchController = this._createSearchController(bookingSession);

        return new ManageMyBookingStrategy(this.services, {
            bookingSession: bookingSession,
            bookingSessionData: bookingSessionData,
            searchController: searchController,
            initialBookingData: this._cloneObject({
                bookingData: bookingData,
                segmentsDelays: bookingSessionData.segmentsDelays
            })

        });
    }

    async tryRestoreManageMyBookingStrategy(): Promise<ManageMyBookingStrategy | null> {
        const strategyOptions = await this._tryRestoreStrategy({
            createQueryBuilder: bookingSession => this._createFullBookingStateQueryBuilder(bookingSession).useSegmentsDisruptionDelays()
        });

        if(strategyOptions) {
            return new ManageMyBookingStrategy(this.services, strategyOptions);
        }

        return null;
    }

    async createCheckInStrategy(bookingSession: IDotRezBookingSession, bookingData: IDotRezBookingData): Promise<CheckInStrategy> {
        const queryBuilder = this._createFullBookingStateQueryBuilder(bookingSession, bookingData);
        const bookingSessionData = this._createFullBookingSessionData(await queryBuilder.getBookingState());
        const searchController = this._createSearchController(bookingSession);

        return new CheckInStrategy(this.services, {
            bookingSession: bookingSession,
            bookingSessionData: bookingSessionData,
            searchController: searchController,
            initialBookingData: this._cloneObject({
                bookingData: bookingData,
                segmentsDelays: []
            })
        });
    }


    async tryRestoreCheckInStrategy(): Promise<CheckInStrategy | null> {
        const strategyOptions = await this._tryRestoreStrategy({
            createQueryBuilder: bookingSession => this._createFullBookingStateQueryBuilder(bookingSession)
        });

        if(strategyOptions) {
            return new CheckInStrategy(this.services, strategyOptions);
        }

        return null;
    }

    async createViewBookingDetailsStrategy(bookingSession: IDotRezBookingSession, bookingData: IDotRezBookingData): Promise<ViewBookingDetailsStrategy> {
        const queryBuilder = bookingSession.bookingStateQueryBuilder(bookingData).useBundlesAvailability().useCheckInRequirements();
        const bookingSessionData = this._createFullBookingSessionData(await queryBuilder.getBookingState());
        const searchController = this._createSearchController(bookingSession);

        return new ViewBookingDetailsStrategy(this.services, {
            bookingSession: bookingSession,
            bookingSessionData: bookingSessionData,
            searchController: searchController,
            initialBookingData: this._cloneObject({
                bookingData: bookingData,
                segmentsDelays: []
            })
        });
    }

    async tryRestoreViewBookingDetailsStrategy(): Promise<ViewBookingDetailsStrategy | null> {
        const strategyOptions = await this._tryRestoreStrategy({
            createQueryBuilder: bookingSession => bookingSession.bookingStateQueryBuilder().useBookingData().useBundlesAvailability().useCheckInRequirements()
        });

        if(strategyOptions) {
            return new ViewBookingDetailsStrategy(this.services, strategyOptions);
        }

        return null;
    }

    async createTransientBookingStrategy(bookingSession: IDotRezBookingSession, bookingData: IDotRezBookingData): Promise<TransientBookingStrategy> {
        const queryBuilder = this._createFullBookingStateQueryBuilder(bookingSession);
        const bookingSessionData = this._createFullBookingSessionData(await queryBuilder.getBookingState());
        const searchController = this._createSearchController(bookingSession);

        return new TransientBookingStrategy(this.services, {
            bookingSession: bookingSession,
            bookingSessionData: bookingSessionData,
            searchController: searchController,
            initialBookingData: this._cloneObject({
                bookingData: bookingData,
                segmentsDelays: []
            })
        });
    }

    private _cloneObject<T>(obj: T): T {
        return JSON.parse(JSON.stringify(obj));
    }

    private _createFullBookingSessionData(partialData: Partial<IDotRezBookingSessionData>): IDotRezBookingSessionData {
        return {
            ...createEmptyBookingSessionData(partialData.bookingData?.currencyCode ?? this.services.currency.current),
            ...partialData
        };
    }

    private _createSearchController(bookingSession: IDotRezBookingSession): FlightSearchControllerModel {
        return new FlightSearchControllerModel(this.services, 'activeBooking.searchControllerStorageKey', {
            bookingSession: bookingSession
        });
    }

    private _createFullBookingStateQueryBuilder(bookingSession: IDotRezBookingSession, initialBookingData?: IDotRezBookingData): IBookingStateQueryBuilder {
        let builder: IBookingStateQueryBuilder;
        if(initialBookingData) {
            builder = bookingSession.bookingStateQueryBuilder(initialBookingData);
        } else {
            builder = bookingSession.bookingStateQueryBuilder().useBookingData()
        }
        return builder.useBundlesAvailability()
                      .useSsrsAvailability()
                      .useSeatMaps()
                      .useCheckInRequirements();
    }

    private async _tryRestoreStrategy(options: IRestoreBookingOptions): Promise<IBookingStrategyOptions | null> {
        await this.services.user.profile.waitForProfileInitialization();

        const bookingSession = await this._tryRestoreBookingSession();
        if(!bookingSession) {
            return null;
        }

        const queryBuilder = options.createQueryBuilder(bookingSession);
        const bookingSessionData = this._createFullBookingSessionData(await queryBuilder.getBookingState());
        const searchController = this._createSearchController(bookingSession);

        if(!searchController.hasErrors()) {
            await searchController.applySearch();
        }

        let initialBookingData = this.services.sessionStorage.getJson<IDotRezInitialBookingData>('activeBooking.initialData') ?? null;

        return {
            bookingSession: bookingSession,
            bookingSessionData: this._createFullBookingSessionData(bookingSessionData),
            searchController: searchController,
            initialBookingData: initialBookingData
        };
    }


    private async _tryRestoreBookingSession(): Promise<IDotRezBookingSession | null> {

        const token = this.services.sessionStorage.getItem('activeBooking.token');
        if(!token) {
            return null;
        }

        try {
            return await this.services.dotRezApi.loadBookingSession(token);
        } catch (err) {
            this.services.logger.warning('Failed to load availability from storage', err);
            return null;
        }
    }

    private _getStation(stationCode: string): Station {
        return this.services.stations.getStation(stationCode);
    }

    private async _impersonate(bookingSession: IDotRezBookingSession, bookingData: IDotRezBookingData): Promise<void> {

        const bundleCode = bookingData?.journeys[0]?.segments[0]?.passengerSegment[0]?.value?.bundleCode

        if(bundleCode && this.services.bundleTypes.getBundleType(bundleCode).requireBookingImpersonation) {
            const psoMarket = this.services.stations.getPsoMarket(this._getStation(bookingData.journeys[0].designator.origin),
                                                                                    this._getStation(bookingData.journeys[0].designator.destination));
            const psoAgentName = psoMarket?.getAgentForBundle(bundleCode);
            if(psoAgentName) {
                await this.services.airlineWebapi.impersonateUser({
                    dotRezToken: bookingSession.token,
                    agentName: psoAgentName
                });
            }
        }
    }
}
