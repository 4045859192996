import React from "react";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import {ShoppingCartComponent} from "./shopping-cart.component";
import { BookingFlowPageFooterSmallScreenComponent } from "../../pages/booking-flow/screen-small/booking-flow-page-footer.small-screen.component";
import {StandardPageContentComponent} from "../../basic-components/page/standard-page-content/standard-page-content.component";
import {observer} from "mobx-react";
import {StandardDialogPageComponent} from "../modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../modal/header/standard-dialog-header.component";

interface ShoppingCartDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
    hideContinueButton?: boolean;
    continueButtonText?: string;
}


export const ShoppingCartDialogComponent: React.FC<ShoppingCartDialogComponentProps> = observer((props) => {

    const renderTitle = () => {
        return (<BookingFlowPageFooterSmallScreenComponent modalHandler={props.dialogHandler}
                                                           hideContinueButton={props.hideContinueButton}
                                                           continueButtonText={props.continueButtonText}/>)
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler}
                                           closeButtonVisibility={'not-render'}
                                           title={renderTitle()}>
            </StandardDialogHeaderComponent>
            <StandardPageContentComponent>
                <ShoppingCartComponent/>
            </StandardPageContentComponent>
        </StandardDialogPageComponent>
    )
});
