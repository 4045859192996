import {ServiceBase} from "../service-base";
import {IServiceFactory} from "../service-factory.interface";
import {makeObservable, observable, runInAction} from "mobx";
import {IPageFooterService} from "./page-footer.service.interface";

export class PageFooterService extends ServiceBase implements IPageFooterService {

    constructor(services: IServiceFactory) {
        super(services);
        makeObservable<this, '_htmlElement'>(this, {
            _htmlElement: observable.ref
        })
    }

    private _htmlElement: HTMLElement | null = null;
    setHtmlElement(element: HTMLElement | null) {
        runInAction(() => {
            this._htmlElement = element
        });
    }

    get height(): number {
        if(!this._htmlElement) {
            return 0;
        }

        return this._htmlElement.getBoundingClientRect().height;
    }
    get heightPx(): string {
        return this.height + 'px';
    }

}