import React from "react";
import {observer} from "mobx-react-lite";
import {PlatformBasedComponent} from "../../basic-components/layout/platform-based.component";
import {HomeWebappPage} from "./web/home.webapp.page";
import {HybridHomePage} from "./hybrid/hybrid-home.page";

export const HomePage: React.FC = observer(() => {
    return (
        <PlatformBasedComponent renderForWebApp={() => (<HomeWebappPage/>)}
                                renderForHybrid={() => (<HybridHomePage/>)}/>
    );
});
