import {IDotRezPassengerSegmentSeat} from "../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {PassengerSegmentModel} from "./passenger-segment.model";
import {ISeatModel} from "../seat-maps/seat.model.interface";
import {IPassengerSegmentViewModel} from "./passenger-segment-view-model.interface";
import {Price} from "../../../currency/price";
import { SeatsRowGroupType } from "../seat-maps/seats-row-group-type.enum";

export class AssignedSeatSurrogateModel implements ISeatModel {
    constructor(private readonly dotRezAssignedSeat: IDotRezPassengerSegmentSeat, private readonly passengerSegment: PassengerSegmentModel) {
    }

    readonly allowsPet: boolean = true;
    readonly location: SeatsRowGroupType = SeatsRowGroupType.first;
    get assignedTo(): IPassengerSegmentViewModel | null {
        return this.passengerSegment;
    }
    readonly hasExtraLegroom: boolean = false;
    isAssigmentInProgress: boolean = false;
    readonly isAvailable: boolean = true;//we consider that is SeatAvailabilityEnum.ReservedForPnr because it belongs to a passenger on this booking
    get isCheckedIn(): boolean {
        return this.passengerSegment.isCheckedIn
    }
    get price(): Price {
        const seatFee = this.passengerSegment.seatFee;
        if(seatFee) {
            return seatFee.priceToDisplay;
        }

        return this.passengerSegment.createPrice(0);
    }
    get rowNumber(): number {
        return parseInt(this.seatNumber);
    }
    get seatKey(): string {
        return this.dotRezAssignedSeat.unitKey;
    }
    get seatNumber(): string {
        return this.dotRezAssignedSeat.unitDesignator;
    }

    get seatLetter():string {
        return this.seatNumber.replace(this.rowNumber.toString(), "");
    }

    makeItAvailable(): void {
        throw new Error('makeItAvailable cannot be called when seat map is not available');
    }

    sellSeat(): Promise<void> {
        throw new Error('sellSeat cannot be called when seat map is not available');
    }

    get isPurchasedOnCurrentSession(): boolean {
        return false;
    }

    get isOnEmergencyRow(): boolean {
        return false;
    }

    get hasEmergencyRowInBeforeIt(): boolean {
        return false;
    }

    scrollIntoView() {
    }
}
