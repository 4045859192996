import React from 'react';
import {DialogCloseButtonBehavior} from "../../../dialog/dialog-enums";
import {IServiceFactory} from "../../../service-factory.interface";
import {BookingModel} from "../booking.model";
import {NullableString} from "../../../../types/nullable-types";
import {IPspError} from "../../../airline-webapi/responses/psp-error";
import {PaymentFailedFatalDialog} from "../../../../pages/booking-flow/payment/payment-failed-fatal.dialog";
import {PaymentFailedDialog} from "../../../../pages/booking-flow/payment/payment-failed.dialog";
import {PaymentSuccessfulDialog} from "../../../../pages/booking-flow/payment/payment-successful.dialog";

interface showPaymentFatalErrorOptions {
    pspErrorDetails: IPspError[] | null;
}

interface ShowPaymentFailedOptions {
    errorMessage: NullableString;
    pspErrorDetails: IPspError[] | null;
}

export class PaymentResultsDialogFactory {
    constructor(private readonly booking: BookingModel) {
    }

    private get services(): IServiceFactory {
        return this.booking.services;
    }

    showPaymentSuccess(options?: {customMessage: string}): void {
        //on purpose we don't wait for this dialog promise so we don't block the execution flow.
        //We just show the dialog and continue execution. Otherwise in flows like PayPal this promise won't resolve because the dialog is open
        //but the dialog is covered by paypal window which waits for this promise to be resolved
        this.services.dialog.showPopupDialog({
            shouldBeClosedOnlyByUser: true,
            maxHeight: "450px",
            width: '416px',
            closeButtonBehavior: DialogCloseButtonBehavior.None,
            render: dialogHandler => {
                return (<PaymentSuccessfulDialog dialogHandler={dialogHandler} customMessage={options?.customMessage}/>);
            }
        });
    }

    private _showPaymentFailedDialog(options: ShowPaymentFailedOptions): void {
        //on purpose we don't wait for this dialog promise so we don't block the execution flow.
        //We just show the dialog and continue execution. Otherwise in flows like PayPal this promise won't resolve because the dialog is open
        //but the dialog is covered by paypal window which waits for this promise to be resolved
        this.services.dialog.showPopupDialog({
            shouldBeClosedOnlyByUser: true,
            closeButtonBehavior: DialogCloseButtonBehavior.None,
            width: '450px',
            render: modalHandler => (<PaymentFailedDialog dialogHandler={modalHandler} errorMessage={options.errorMessage} pspErrorDetails={options.pspErrorDetails}/>)
        });
    }

    showPaymentFatalError(options: showPaymentFatalErrorOptions): void {
        //on purpose we don't wait for this dialog promise so we don't block the execution flow.
        //We just show the dialog and continue execution. Otherwise in flows like PayPal this promise won't resolve because the dialog is open
        //but the dialog is covered by paypal window which waits for this promise to be resolved
        this.services.dialog.showPopupDialog({
            shouldBeClosedOnlyByUser: true,
            closeButtonBehavior: DialogCloseButtonBehavior.None,
            width: '450px',
            render: modalHandler => (<PaymentFailedFatalDialog dialogHandler={modalHandler} pspErrorDetails={options.pspErrorDetails} />)
        });
    }

    showPaymentFailed(options: ShowPaymentFailedOptions): void {
        this._showPaymentFailedDialog(options);
    }

    showPaymentCanceled(): void {
        this._showPaymentFailedDialog({
            errorMessage: this.services.language.translate('Your transaction was canceled'),
            pspErrorDetails: null
        });
    }

    showPaymentInReview(): void {
        this.services.dialogFactory.showSimpleMessageDialog({
            title: this.services.language.translate('Payment review'),
            message: this.services.language.translate('Your payment is in review'),
            buttonText: this.services.language.translate('Continue')
        }).then(() => {
            return this.services.navigator.goHome();
        })
    }
}
