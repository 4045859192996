import React from "react";
import {observer} from "mobx-react";
import {
    IFlightDesignatorViewModel
} from "../../../../services/booking/models/designator/flight-designator-view-model.interface";
import {DirectFlightComponent} from "./direct-flight.component";
import {WithStopoverComponent} from "./with-stopover.component";

interface FlightConnectivityTypeComponentProps {
    designator: IFlightDesignatorViewModel;
    onExpandClick: () => void;
    isExpanded: boolean;
}
export const FlightConnectivityTypeComponent: React.FC<FlightConnectivityTypeComponentProps> = observer((props) => {
    if(props.designator.numberOfStops === 0) {
        return (
            // eslint-disable-next-line react/jsx-no-undef
            <DirectFlightComponent designator={props.designator}/>
        )

    }

    return (
        <WithStopoverComponent onClick={props.onExpandClick}
                               isExpanded={props.isExpanded}
                               designator={props.designator} />
    )
})