import React, {PropsWithChildren} from "react";
import styled from "styled-components";
import {observer} from "mobx-react";

const StandardDialogTitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.h4};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

interface StandardDialogTitleComponentProps extends PropsWithChildren {
    className?: string;
}
export const StandardDialogTitleComponent: React.FC<StandardDialogTitleComponentProps> = observer((props) => {
    return (
        <StandardDialogTitleBox className={props.className}>
            {props.children}
        </StandardDialogTitleBox>
    )
})