import React from 'react';
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {useServices} from "../../hooks/use-services.hook";
import {IonIcon} from "@ionic/react";
import {removeOutline} from "ionicons/icons";
import {useContainerMediaQueriesChecks} from "../../hooks/use-container-media-queries-checks.hook";

const AirlineCompanyInfoBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: ${props => props.theme.fontSize.breadcrumbResting};
    gap: ${props => props.theme.spacing.flexGap8};
    color: ${props => props.theme.colors.notesTint};
    ${(props) => props.theme.containerMediaQuery.sAndBelow} {
        border-radius: ${props => props.theme.border.defaultRadius};
    }
    
    ${props => props.theme.containerMediaQuery.smallScreen} {
        flex-direction: column;
        gap: 0;
    }
`


const AnchorBox = styled.a`
    text-decoration: underline;
    display: inline;
    color: ${props => props.theme.colors.notesTint};
    font-weight: ${props => props.theme.fontWeight.bold};
`

const AddressBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    text-align: center;
`

export const CopyRightComponent: React.FC<{className?: string}> = observer((props) => {
    const services = useServices();
    const mediaQuery = useContainerMediaQueriesChecks();

    const isInSmallScreenView = mediaQuery.smallScreen;

    return (
        <AirlineCompanyInfoBox className={props.className}>
            <AddressBox>{services.configuration.data.companyAddress}</AddressBox>
            {!isInSmallScreenView && (<IonIcon ios={removeOutline} md={removeOutline} />)}
            <AnchorBox href={services.externalLinksConfiguration.privacyPolicy} rel={"noreferrer"} target={"_blank"}>Privacy policy</AnchorBox>
        </AirlineCompanyInfoBox>
    )
})
