import React from "react";
import {observer} from "mobx-react";
import {MyTripsHistoryTypeEnum} from "./my-trips-history-type.enum";
import {FutureFlightCardComponent} from "./flight-cards/future-flight-card.component";
import {PastFlightCardComponent} from "./flight-cards/past-flight-card.component";
import {
    HomePageHybridContentBottomSectionComponent
} from "../../../content/home-page-hybrid-content-bottom-section.component";
import {MyTripsTab} from "../my-trips.tab";
import styled from "styled-components";
import {CallCenterPhoneComponent} from "../../../../../../components/call-center-phone/call-center-phone.component";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {NoBookingHistoryComponent} from "./no-booking-history/no-booking-history.component";
import {BookNewTravelButtonComponent} from "./buttons/book-new-travel-button.component";
import {RetrieveFutureTravelButtonComponent} from "./buttons/retrieve-future-travel-button.component";

const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-grow: 1;
    gap: ${props => props.theme.spacing.flexGap24};
`

const TripsContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap24};
`


const FutureFlightsComponent: React.FC<{tab: MyTripsTab}> = observer((props) => {
    const services = useServices();
    const flights = props.tab.futureFlights;
    if(flights.length === 0) {
        return (
            <NoBookingHistoryComponent tab={props.tab}
                                       message={services.language.translate('No upcoming travel was located.')}
                                       retrieveButtonText={services.language.translate('Retrieve upcoming travel')}/>
        )
    }

    return (
        <TripsContainerBox>
            {flights.map(f => <FutureFlightCardComponent key={f.uniqueKey} flight={f}/>)}
        </TripsContainerBox>
    )
})

const PastFlightsComponent: React.FC<{tab: MyTripsTab}> = observer((props) => {
    const services = useServices();
    const flights = props.tab.pastFlights;
    if(flights.length === 0) {
        return (
            <NoBookingHistoryComponent tab={props.tab}
                                       message={services.language.translate('No past travel was located.')}
                                       retrieveButtonText={services.language.translate('Retrieve past travel')}/>
        )
    }
    return (
        <TripsContainerBox>
            {flights.map(f => <PastFlightCardComponent key={f.uniqueKey} flight={f}/>)}
        </TripsContainerBox>
    )
})

interface MyTripsTabContentBottomSectionComponentProps {
    tab: MyTripsTab;
}
export const MyTripsTabContentBottomSectionComponent: React.FC<MyTripsTabContentBottomSectionComponentProps> = observer((props) => {
    const services = useServices();
    const renderFlights = () => {
        if (props.tab.currentHistoryView === MyTripsHistoryTypeEnum.Future) {
            return (<FutureFlightsComponent tab={props.tab}/>)
        } else {
            return (
                <PastFlightsComponent tab={props.tab}/>
            )
        }
    }

    const renderButton = () => {
        if(props.tab.currentHistoryView === MyTripsHistoryTypeEnum.Future) {
            if(props.tab.futureFlights.length > 0) {
                return (
                    <RetrieveFutureTravelButtonComponent tab={props.tab}/>
                );
            } else {
                return (
                    <BookNewTravelButtonComponent/>
                );
            }
        }

        return (
            <BookNewTravelButtonComponent/>
        );
    }

    const renderContent = () => {
        if(props.tab.futureFlights.length === 0 && props.tab.pastFlights.length === 0) {
            return (
                <CallCenterPhoneComponent title={services.language.translate('Need help finding your booking?')}/>
            );
        }

        return (
            <ContentBox>
                {renderFlights()}
                {renderButton()}
            </ContentBox>
        );
    }

    return (
        <HomePageHybridContentBottomSectionComponent>
            {renderContent()}
        </HomePageHybridContentBottomSectionComponent>

    )
})