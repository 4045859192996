import React from "react";
import {observer} from "mobx-react";
import {StandardDialogPageComponent} from "../modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../modal/header/standard-dialog-header.component";
import {useServices} from "../../hooks/use-services.hook";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import {IPersonTravelDocumentViewModel} from "../../services/user/models/person/person-travel-document-view-model.interface";
import {StandardDialogContentComponent} from "../modal/content/standard-dialog-content.component";
import {PersonTravelDocumentEditorComponent} from "./person-travel-document-editor.component";
import {StandardDialogFooterComponent} from "../modal/footer/standard-dialog-footer.component";
import {SaveButtonBox} from "../forms/buttons/save-button.box";
import {ValidationResultEnum} from "../../types/validation-result.enum";
import {DeleteButtonBox} from "../forms/buttons/delete-button.box";



interface CompanionTravelDocumentEditorDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
    travelDocument: IPersonTravelDocumentViewModel;
}
export const PersonTravelDocumentEditorDialogComponent: React.FC<CompanionTravelDocumentEditorDialogComponentProps> = observer((props) => {
    const services = useServices();
    const onSave = async () => {
        const result = await props.travelDocument.save();
        if(result === ValidationResultEnum.Success) {
            props.dialogHandler.accept();
        }
    }

    const onDelete = async () => {
        const result = await props.travelDocument.delete();
        if(result === ValidationResultEnum.Success) {
            props.dialogHandler.accept();
        }

    }

    const saveButtonText = () => {
        if(props.travelDocument.isNew) {
            return services.language.translate('Add document');
        } else {
            return services.language.translate('Save document');
        }
    }

    const dialogTitle = () => {
        if(props.travelDocument.isNew) {
            return services.language.translate('Add travel document');
        } else {
            return services.language.translate('Edit travel document');
        }
    }


    const renderDeleteButton = () => {
        if(props.travelDocument.isNew) {
            return null;
        }

        return (<DeleteButtonBox onClick={onDelete}>
            {services.language.translate('Delete document')}
        </DeleteButtonBox>);
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={dialogTitle()}
                                           dialogHandler={props.dialogHandler}/>
            <StandardDialogContentComponent dialogHandler={props.dialogHandler}>
                <PersonTravelDocumentEditorComponent travelDocument={props.travelDocument}/>
            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <SaveButtonBox onClick={onSave}>
                    {saveButtonText()}
                </SaveButtonBox>
                {renderDeleteButton()}
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    )
});
