import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {DayCellBox} from "./boxes/day-cell.box";
import {PlaneGoIcon} from "../../../../../assets/svg/plane-go.icon";
import {PlaneReturnsIcon} from "../../../../../assets/svg/plane-returns.icon";
import {NullableUndefinedPrice} from "../../../../../services/currency/price";
import {DayPriceComponent} from "./day-price.component";

const DayContentBox = styled(DayCellBox)`
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primaryContrast};
    cursor: pointer;
`

const PriceContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
    
`

const DeparturePriceBox = styled(PriceContainerBox)`
  color: ${props => props.theme.colors.lightShade};
`

const ReturnPriceBox = styled(PriceContainerBox)`
  
`

interface SameDayComponentProps {
  departureLowFare: NullableUndefinedPrice;
  returnLowFare: NullableUndefinedPrice;
}
export const SameDayComponent: React.FC<SameDayComponentProps> = observer((props) => {
  return (
      <DayContentBox>
        <DeparturePriceBox>
            <PlaneGoIcon size={0.75}/>
            <DayPriceComponent price={props.departureLowFare}/>
        </DeparturePriceBox>
        <ReturnPriceBox>
            <DayPriceComponent price={props.returnLowFare}/>
            <PlaneReturnsIcon size={0.75}/>
        </ReturnPriceBox>
      </DayContentBox>
  )
})