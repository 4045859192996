import {YesNoDialogPrimaryButton, YesNoDialogResult} from "../../dialog-factory/yes-no-dialog.enums";
import {
    PsoUserOptionWithSeasonDialogComponent
} from "../../../components/territorial-continuity-regime/pso-user-option-with-season-dialog.component";
import {FlightSearchStepBase} from "./flight-search-step.base";
import {
    IFlightSearchControllerViewModel,
} from "../flight-search-controller/flight-search-controller-view-model.interface";
import {DialogCloseButtonBehavior} from "../../dialog/dialog-enums";
import {PsoUserOptionsEnum} from "../flight-search-controller/pso.enums";

export class DestinationStationSelectionStep extends FlightSearchStepBase {

    get title(): string {
        return this.services.language.translate('Destination');
    }

    async next(): Promise<void> {
        const searchController = this.services.flightSearch.searchController
        const psoMarket = searchController.getPsoMarket();
        if(psoMarket) {
           await this._showPsoConfirmationDialog(searchController);
        } else {
            this._activateStepByRoute(this.routes.flightDates)
        }

    }

    private async _showPsoConfirmationDialog(searchController: IFlightSearchControllerViewModel): Promise<void> {
        const psoMarket = searchController.getPsoMarket();
        if(!psoMarket) {
            return;
        }


        if(psoMarket.activeSeason) {
            await this.services.dialog.showSheetModalDialog<PsoUserOptionsEnum>({
                closeButtonBehavior: DialogCloseButtonBehavior.Reject,
                render: dialogHandler => (<PsoUserOptionWithSeasonDialogComponent dialogHandler={dialogHandler} searchController={searchController}/>),
            })
        } else {
            let message: string;
            if(psoMarket.isSicilia) {
                message = this.services.language.translate('Are you resident/born in Sicily?');
            } else {
                message = this.services.language.translationFor('Are you a resident in {market}?').withParams({market: psoMarket.getMarketNameTranslation()})
            }
            const dialogResult = await this.services.dialogFactory.showYesNoDialog({
                title: this.services.language.translate('Resident and equivalent'),
                message: message,
                primaryButton: YesNoDialogPrimaryButton.PrimaryButtonYes
            });

            if(dialogResult === YesNoDialogResult.Yes) {
                searchController.psoUserOption = PsoUserOptionsEnum.Resident;

            } else {
                searchController.psoUserOption = PsoUserOptionsEnum.None;
            }
        }

        this._activateStepByRoute(this.routes.flightDates);

    }

    get route() {
        return this.routes.destinationStation;
    }


}
