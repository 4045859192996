import React, {useEffect, useRef, useState} from "react";
import {BoardingPassPrintButtonComponent} from "./print-button/boarding-pass-print-button.component";
import {BoardingPassShareButtonComponent} from "./share-icon/boarding-pass-share-button.component";
import {useServices} from "../../../../hooks/use-services.hook";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {IPassengerSegmentBoardingPassViewModel} from "../../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {BoardingPassesContainerComponent} from "../boarding-passes-container/boarding-passes-container.component";
import {
    IBoardingPassExportAction,
    PrintBoardingPassAction,
    ShareBoardingPassAction
} from "./boarding-pass-export-actions";

const ButtonsContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap12};
    font-weight: ${props => props.theme.fontWeight.bold};
`

interface BoardingPassExportButtonsComponentProps {
    bookingKey: string;
    boardingPasses: IPassengerSegmentBoardingPassViewModel[];
    fileName: string;
    emailSubject: string;
    emailBody: string;
    onExportStatusChanged?: (inProgress: boolean) => void;
}

const PrintAllTextBox = styled.span`
    font-size: ${props => props.theme.fontSize.h5};
    cursor: pointer;
`

const PrintButtonContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap8};
`

export const BoardingPassExportButtonsComponent: React.FC<BoardingPassExportButtonsComponentProps> = observer((props) => {
    const services = useServices();
    const printAction = useRef<IBoardingPassExportAction>(new PrintBoardingPassAction(props.fileName, services));
    const shareAction = useRef<IBoardingPassExportAction>(new ShareBoardingPassAction(props.fileName, props.emailSubject, props.emailBody, services));
    const boardingPassesContainerRef = React.useRef<HTMLDivElement | null>(null);
    const [canShare, setCanShare] = useState(false);

    useEffect(() => {
        services.pdfExporter.canShare().then(supportsShare => {
            setCanShare(supportsShare);
        })
    }, [services.pdfExporter]);

    const isExportActionInProgress = printAction.current.isExportInProgress || shareAction.current.isExportInProgress;

    const onPrintBoardingPass = async () => {
        if(!isExportActionInProgress) {
            await printAction.current.export(boardingPassesContainerRef);
        }
    };

    const onShareBoardingPass = async () => {
        if(!isExportActionInProgress) {
            await shareAction.current.export(boardingPassesContainerRef);
        }

    }
    const renderShareButton = () => {
        if(!canShare) {
            return null;
        }

        return (
            <BoardingPassShareButtonComponent onClick={onShareBoardingPass}
                                              isExportInProgress={shareAction.current.isExportInProgress}/>
        );
    }

    const renderBoardingPasses = () => {
        if(!isExportActionInProgress) {
            return null;
        }

        return (
            <BoardingPassesContainerComponent bookingKey={props.bookingKey}
                                              boardingPasses={props.boardingPasses}
                                              containerRef={boardingPassesContainerRef}/>
        );
    }

    const renderPrintAllText = () => {

        if(props.boardingPasses.length <= 1) {
            return null;
        }

        return (
            <PrintAllTextBox onClick={onPrintBoardingPass}>
                {services.language.translate('Print all')}
            </PrintAllTextBox>
        );
    }

    return (
        <ButtonsContainerBox>
            <PrintButtonContainerBox>
                <BoardingPassPrintButtonComponent onClick={onPrintBoardingPass} isExportInProgress={printAction.current.isExportInProgress}/>
                {renderPrintAllText()}
            </PrintButtonContainerBox>
            {renderShareButton()}
            {renderBoardingPasses()}
        </ButtonsContainerBox>
    )
})
