import {ISearchMaskControllerViewModel} from "./search-mask-controller.view-model.interface";
import {IDropDownController} from "../../forms/drop-down/models/drop-down-controller.interface";
import {IReactionDisposer, reaction} from "mobx";
import {IPsoPopperController} from "../common/search-mask-pso-market-user-options-popper.component";

export class SearchMaskControllerModel implements ISearchMaskControllerViewModel {

    private _flightDatesDropDownOpenReaction: IReactionDisposer | null = null;
    attachFlightDatesDropDownController(dropDownController: IDropDownController): void {
        if(this._flightDatesDropDownOpenReaction) {
            this._flightDatesDropDownOpenReaction();
        }
        this._flightDatesDropDownOpenReaction = reaction(() => dropDownController.isOpen, (isOpen) => {
            if(isOpen) {
                this._psoPopper?.close();
            }

        });
    }

    private _psoPopper: IPsoPopperController | null = null;

    attachPsoPopperController(psoPopper: IPsoPopperController): void {
        this._psoPopper = psoPopper;
    }

    dispose(): void {
        if(this._flightDatesDropDownOpenReaction) {
            this._flightDatesDropDownOpenReaction();
        }
    }

}