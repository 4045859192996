import React from "react";
import {ISsrType} from "../../../../services/ssr-types/ssr-types.service.interface";
import styled from "styled-components";
import {observer} from "mobx-react";

const BagIconBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-content: center;
    justify-content: center;
    justify-items: center;
    margin: ${props => props.theme.spacing.spacing8};
`

export const BagIconComponent: React.FC<{ssrType: ISsrType; className?: string}> = observer((props) => {

    return (
        <BagIconBox className={props.className}>
            {props.ssrType.largeIcon}
        </BagIconBox>
    )
});
