import React from 'react';
import styled from "styled-components";

const DayNumberBox = styled.span`
    display: flex;
    align-self: flex-end;
    font-weight: ${props => props.theme.fontWeight.medium};
    font-size: ${props => props.theme.fontSize.subtitle2};
    color: ${props => props.theme.colors.primary};
    margin-right: 4px;
    
`;



export const DayNumberComponent: React.FC<{date: Date; className?: string}> = (props) => {
    return (
        <DayNumberBox className={props.className}>
            {props.date.getDate()}
        </DayNumberBox>
    )
}

const UnavailableDayNumberBox = styled(DayNumberBox)`
    opacity: 0.4;
    color: ${props => props.theme.colors.medium};
`

export const UnavailableDayNumberComponent: React.FC<{date: Date; className?: string}> = (props) => {
    return (
        <UnavailableDayNumberBox className={props.className}>
            {props.date.getDate()}
        </UnavailableDayNumberBox>
    )
}