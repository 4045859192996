import {BookingStrategyBase} from "../booking-strategy-base";
import {Wizard} from "../../../../models/wizard/wizard";
import {ShoppingCartModeEnum} from "../booking-strategy.interface";
import {ISsrType} from "../../../ssr-types/ssr-types.service.interface";
import {IBookingStrategyAnalyticsConfiguration} from "../analytics/booking-strategy-analytics-configuration.interface";
import {ANALYTICS_AFFILIATIONS} from "../analytics/analytics-affiliations";
import {JourneyModel} from "../../models/journey/journey.model";
import {ManageMyBookingDashboardStep} from "../../steps/manage-my-booking/mmb-flow-dashboard.step";
import {ManageMyBookingFinalPriceStep} from "../../steps/manage-my-booking/mmb-flow-final-price.step";
import {ManageMyBookingPaymentStep} from "../../steps/manage-my-booking/mmb-flow-payment.step";
import {BookingFlowTypeEnum} from "../booking-flow-type.enum";
import {
    ManageMyBookingSelectNewFlightsDatesStep
} from "../../steps/manage-my-booking/mmb-flow-select-new-flights-dates.step";
import {
    ManageMyBookingSelectNewDepartingFlightStep
} from "../../steps/manage-my-booking/mmb-flow-select-new-departing-flight.step";
import {IFareToSell} from "../../models/booking-view-model.interface";
import {
    IDotRezPartialBookingSessionData
} from "../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {IRoundTripLowFareReader} from "../../../low-fare/low-fare-readers/low-fare-reader.interface";
import {
    ManageMyBookingSelectNewReturnFlightStep
} from "../../steps/manage-my-booking/mmb-flow-select-new-return-flight.step";
import {Price} from "../../../currency/price";
import {ManageMyBookingSeatsSelectionStep} from "../../steps/manage-my-booking/mmb-flow-seats-selection.step";
import {ManageMyBookingUnresolvedServicesStep} from "../../steps/manage-my-booking/mmb-flow-unresolved-services.step";
import {ManageMyBookingExtrasStep} from "../../steps/manage-my-booking/mmb-flow-extras.step";
import {NullableString} from "../../../../types/nullable-types";
import {
    ResellReturnJourneyBundleMutation
} from "../../models/mutation-actions/sell-bundle/resell-return-journey-bundle.mutation";
import {
    ResellDepartureJourneyBundleMutation
} from "../../models/mutation-actions/sell-bundle/resell-departure-journey-bundle.mutation";
import {ISessionStorageService} from "../../../storage/session-storage.service.interface";
import {IRoute} from "../../../navigation/models/route.interface";
import {ManageMyBookingFlowEnum} from "../../models/manage-my-booking/manage-my-booking-flow.enum";

export class ManageMyBookingStrategy extends BookingStrategyBase {

    get bookingFlowType(): BookingFlowTypeEnum {
        return BookingFlowTypeEnum.ManageMyBooking;
    }



    protected _createSteps(sessionStorage: ISessionStorageService): Wizard {
        let wizard: Wizard;
        switch (this.booking.manageMyBooking.currentFlow) {
            case ManageMyBookingFlowEnum.None:
            case ManageMyBookingFlowEnum.Regular:
                wizard = new Wizard(this.services, 'mmbRegular', sessionStorage);
                wizard.addStep(new ManageMyBookingDashboardStep(this.booking, wizard));
                wizard.addStep(new ManageMyBookingFinalPriceStep(this.booking, wizard));
                wizard.addStep(new ManageMyBookingPaymentStep(this.booking, wizard));
                return wizard;
            case ManageMyBookingFlowEnum.ChangeFlights:
                wizard = new Wizard(this.services, 'mmbChangeFlights', sessionStorage);
                wizard.addStep(new ManageMyBookingDashboardStep(this.booking, wizard));
                wizard.addStep(new ManageMyBookingSelectNewFlightsDatesStep(this.booking, wizard));
                wizard.addStep(new ManageMyBookingSelectNewDepartingFlightStep(this.booking, wizard));
                wizard.addStep(new ManageMyBookingSelectNewReturnFlightStep(this.booking, wizard));
                wizard.addStep(new ManageMyBookingUnresolvedServicesStep(this.booking, wizard));
                wizard.addStep(new ManageMyBookingSeatsSelectionStep(this.booking, wizard));
                wizard.addStep(new ManageMyBookingExtrasStep(this.booking, wizard));
                wizard.addStep(new ManageMyBookingFinalPriceStep(this.booking, wizard));
                wizard.addStep(new ManageMyBookingPaymentStep(this.booking, wizard));
                return wizard;
            default:
                throw new Error(`Invalid manage my booking flow ${this.booking.manageMyBooking.currentFlow}`);

        }
    }

    get analyticsConfiguration(): IBookingStrategyAnalyticsConfiguration {
        return {
            affiliation: ANALYTICS_AFFILIATIONS.manageBooking,
            shouldReportFares: false
        }
    }

    get availabilityPricingInformationMessage(): string {
        const standardMessage = this.services.language.translate('All prices are per passenger and include airport taxes');

        if(!this.isBookingInitialized) {
            return standardMessage;
        }

        // on manage my booking we want to show the bundle name above the fare when we have a disruption
        // because in this case we don't have the bundle selection step
        if(this.initialBookingSnapshot.canMoveDisruptedFlights) {
            return standardMessage;
        }

        return this.services.language.translate(`All prices are per passenger and include airport taxes, but doesn't include bundle price`);

    }


    shouldFilterOutJourney(journey: JourneyModel): boolean {

        return !journey.isFutureJourney;
    }

    get allowedShoppingCartModes(): ShoppingCartModeEnum[] {

        if(this.initialBookingSnapshot.isGDSBooking) {
            return [ShoppingCartModeEnum.ShowNewPurchasesOnly];
        }

        return [ShoppingCartModeEnum.ShowNewPurchasesOnly, ShoppingCartModeEnum.ShowAllPurchases];
    }

    get showStandardAndBlueBenefitsPrice(): boolean {
        return false;
    }

    canShowSsr(ssrType: ISsrType): boolean {
        return ssrType.canBeShownOnManageMyBookingFlow(this.booking);
    }

    async sellDepartureJourney(fareToSell: IFareToSell, onAfterSell: (bookingSessionData: IDotRezPartialBookingSessionData) => Promise<void>): Promise<void> {
        if(!this.booking.departureJourney) {
            throw new Error(`Cannot change departure journey because it doesn't exist`);
        }

        await this.booking.manageMyBooking.sellDepartureJourney(fareToSell, onAfterSell);
    }

    async sellDepartureJourneyBundle(bundleCode: string): Promise<void> {
        const departureJourney = this.booking.departureJourney;
        if(!departureJourney) {
            throw new Error('You cannot sell departure journey bundle if there is no departure journey');
        }

        if(departureJourney.currentBundleCode === bundleCode) {
            return;
        }

        this.booking.mutationsManager.startMutation(new ResellDepartureJourneyBundleMutation(this.booking, bundleCode));
        this.booking.mutationsManager.startRetrieveSsrsAvailability();
        this.booking.mutationsManager.startRetrieveSeatsMap();
    }

    async sellReturnJourney(fareToSell: IFareToSell, onAfterSell: (bookingSessionData: IDotRezPartialBookingSessionData) => Promise<void>): Promise<void> {
        await this.booking.manageMyBooking.sellReturnJourney(fareToSell, onAfterSell);
    }

    async sellReturnJourneyBundle(bundleCode: string): Promise<void> {
        const returnJourney = this.booking.returnJourney;

        if(!returnJourney) {
            throw new Error('You cannot sell return journey bundle if there is no return journey');
        }

        if(returnJourney.currentBundleCode === bundleCode) {
            return;
        }

        this.booking.mutationsManager.startMutation(new ResellReturnJourneyBundleMutation(this.booking, bundleCode));
        this.booking.mutationsManager.startRetrieveSsrsAvailability();
        this.booking.mutationsManager.startRetrieveSeatsMap();
    }

    getLowFaresReader(): IRoundTripLowFareReader {
        return this.booking.manageMyBooking.applyLowFareReaderDecorator(() => super.getLowFaresReader());
    }

    reduceDepartureFare(price: Price): Price {
        if(!this.isBookingInitialized) {
            super.reduceDepartureFare(price);
        }

        return this.booking.manageMyBooking.reduceDepartureFare(price);
    }

    reduceReturnFare(price: Price): Price {
        if(!this.isBookingInitialized) {
            super.reduceReturnFare(price);
        }

        return this.booking.manageMyBooking.reduceReturnFare(price);
    }

    get departureBundleNameToShowOnFare(): NullableString {
        if(!this.isBookingInitialized) {
            return null;
        }

        // on manage my booking we want to show the bundle name above the fare when we have a disruption
        // because in this case we don't have the bundle selection step
        if(this.initialBookingSnapshot.canMoveDisruptedFlights) {
            return this.booking.departureJourney?.selectedBundle?.bundleType.bundleName || null;
        }

        return null;

    }

    get returnBundleNameToShowOnFare(): NullableString {

        if(!this.isBookingInitialized) {
            return null;
        }

        // on manage my booking we want to show the bundle name above the fare when we have a disruption
        // because in this case we don't have the bundle selection step
        if(this.initialBookingSnapshot.canMoveDisruptedFlights) {
            return this.booking.returnJourney?.selectedBundle?.bundleType.bundleName || null;
        }

        return null;
    }

    getFinalPriceRoute(): IRoute {
        return this.services.navigator.routes.manageMyBooking.finalPrice;
    }
    getPaymentRoute(): IRoute {
        return this.services.navigator.routes.manageMyBooking.payment;
    }

    getFinalizePaymentRoute(): IRoute {
        return this.services.navigator.routes.manageMyBooking.finalizePayment;
    }

    async onPaymentSuccess(): Promise<void> {
        await this.services.booking.switchToManageMyBooking();
    }

    async goBackToFlightSelection(): Promise<void> {

        if(this.booking.manageMyBooking.onlyTheReturnJourneyIsSelected) {
            await this.steps.goToStepByRoute(this.services.navigator.routes.manageMyBooking.selectNewReturnFlight);
        } else {
            await this.steps.goToStepByRoute(this.services.navigator.routes.manageMyBooking.selectNewDepartingFlight);
        }

    }
}
