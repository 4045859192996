import React from "react";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../hooks/use-services.hook";
import {IDotRezSessionCountDownTimerViewModel} from "../../services/dot-rez-api/session/session-timer/dot-rez-session-timer-view-model.interface";
import { AlarmClockIcon } from "../../assets/svg/alarm-clock.icon";
import {StandardDialogPageComponent} from "../modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../modal/footer/standard-dialog-footer.component";
import {PrimaryButton} from "../../basic-components/buttons/primary-button";

const DialogContentContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${props => props.theme.spacing.spacing40};
    flex-grow: 1;
    font-size: ${props => props.theme.fontSize.h2};
    font-weight: ${props => props.theme.fontWeight.bold};
`

const ExpirationInfoContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`


const ExpireQuestionTextBox = styled.div`
    font-size: ${props => props.theme.fontSize.body1};
    font-weight: ${props => props.theme.fontWeight.regular};
    text-align: center;
    color: ${props => props.theme.colors.medium};
    justify-self: flex-end;
`

const ExpiredTextBox = styled.div`
    text-align: center;
    justify-self: flex-end;
`

const TimeLeftBox = styled.div`
    font-size: ${props => props.theme.fontSize.huge5};
`

interface SessionCountdownDialogComponentProps {
    countDownTimer: IDotRezSessionCountDownTimerViewModel;
    dialogHandler: IDialogConfirmationHandler;
    onButtonClick: () => Promise<void>;
}

export const SessionCountDownDialogComponent: React.FC<SessionCountdownDialogComponentProps> = observer((props) => {
    const services = useServices();
    const timeLeft = props.countDownTimer.sessionTimeLeft;
    if(!timeLeft) {
        props.dialogHandler.reject();
        return null;
    }

    const isExpired = timeLeft.totalMilliseconds <= 0;

    const renderContent = () => {
        if(isExpired) {
            return (
                <ExpiredTextBox>
                    {services.language.translate('Your session has expired')}
                </ExpiredTextBox>
            )
        } else {
            return (
                <>
                    <div>
                        {services.language.translate('Your session will expire in:')}
                    </div>
                    <TimeLeftBox>
                        {timeLeft.toMinutesAndSecondsString()}
                    </TimeLeftBox>
                </>
            );
        }
    }

    const getButtonText = () => {
        if (isExpired) {
            return services.language.translate('Start over');
        } else {
            return services.language.translate('Yes, I need more time to decide');
        }
    }

    const renderContinueQuestion = () => {
        if(isExpired) {
            return null;
        }

        return (
            <ExpireQuestionTextBox>
                {services.language.translate('Would you like to continue your session?')}
            </ExpireQuestionTextBox>
        );
    }

    const onButtonClick = async () => {
        props.dialogHandler.accept();
        await props.onButtonClick();
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler}/>

            <StandardDialogContentComponent dialogHandler={props.dialogHandler}>
                <DialogContentContainerBox>
                    <AlarmClockIcon size={5.5} color={isExpired  ? services.theme.currentTheme.colors.danger : services.theme.currentTheme.colors.secondary}/>
                    <ExpirationInfoContainerBox>
                        {renderContent()}
                    </ExpirationInfoContainerBox>

                </DialogContentContainerBox>


            </StandardDialogContentComponent>

            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                {renderContinueQuestion()}
                <PrimaryButton onClick={onButtonClick}>
                    {getButtonText()}
                </PrimaryButton>
            </StandardDialogFooterComponent>

        </StandardDialogPageComponent>
    )
});
