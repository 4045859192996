import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {
    IMaturePassengerViewModel
} from "../../../../services/booking/models/passenger/mature-passenger-view-model.interface";
import {IJourneyViewModel} from "../../../../services/booking/models/journey/journey-view-model.interface";
import {
    IBookingSsrsAggregatorViewModel
} from "../../../../services/booking/models/ssrs/aggregator/booking-ssrs-aggregator-view-model.interface";
import {PassengerJourneySsrsSelectionComponent} from "./passenger-journey-ssrs-selection.component";
import {SameForAllFlightsToggleComponent} from "../same-for-all-flights-toggle.component";


const PassengerSsrsBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap20};
    width: 100%;
`

const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap16};
    width: 100%;
    
`

const ContentTopSectionBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const PassengerNameBox = styled.div`
    font-size: ${props => props.theme.fontSize.body1};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    color: ${props => props.theme.colors.mediumShade};
    flex-grow: 1;
`


interface PassengerSsrsSelectionComponentProps {
    passenger: IMaturePassengerViewModel;
    journey: IJourneyViewModel;
    ssrsAggregator: IBookingSsrsAggregatorViewModel;
    showSameForAllFlights?: boolean;
    renderCustomTitle?: () => React.ReactElement | string;
}

export const PassengerSsrsSelectionComponent: React.FC<PassengerSsrsSelectionComponentProps> = observer((props) => {

    const currentJourney = props.journey



    const renderSameForAllFlights = () => {
        if(props.showSameForAllFlights && props.ssrsAggregator.allowUseSameOptionsForAllFlights) {
            return (
                <SameForAllFlightsToggleComponent ssrsAggregator={props.ssrsAggregator} />
            )
        }
        return null;
    }

    return (
        <PassengerSsrsBox>
            <ContentBox>
                <ContentTopSectionBox>
                    <PassengerNameBox>
                        {(props.renderCustomTitle && props.renderCustomTitle()) ?? props.passenger.getFullName()}
                    </PassengerNameBox>
                    {renderSameForAllFlights()}
                </ContentTopSectionBox>

                <PassengerJourneySsrsSelectionComponent passenger={props.passenger} journey={currentJourney} ssrsAggregator={props.ssrsAggregator}/>
            </ContentBox>
        </PassengerSsrsBox>
    );
});
