import React from "react";
import styled from "styled-components";
import {TimeOrStationCodeBox} from "../../boxes/time-or-station-code.box";
import {Station} from "../../../../services/stations/station.service.interface";

const SideSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1px;
    
    
`

const FlightDateBox = styled.div`
    color: ${props => props.theme.colors.notesTint};
`

const StationNameBox = styled.div`
    display: flex;
    text-align: center;
    align-items: center;
`

const TimeBox = styled.div`
    font-size: ${props => props.theme.fontSize.h5};
`

interface LeftSectionComponentProps {
    isShownInShoppingCart: boolean;
    station: Station;
    formattedDepartureTime: string;
    formattedDepartureDate: string;
    className?: string;
}

export const SideSectionComponent: React.FC<LeftSectionComponentProps> = (props) => {

    const renderStationNameOrTime = () => {
        if(props.isShownInShoppingCart) {
            return (
                <TimeBox>
                    {props.formattedDepartureTime}
                </TimeBox>
            )
        } else {
            return (
                <StationNameBox>
                    {props.station.stationName}
                </StationNameBox>
            )
        }
    }

    return (
        <SideSectionBox className={props.className}>
            <TimeOrStationCodeBox>
                {props.isShownInShoppingCart ? props.station.stationCode : props.formattedDepartureTime}
            </TimeOrStationCodeBox>
            <FlightDateBox>
                {props.formattedDepartureDate}
            </FlightDateBox>
            {renderStationNameOrTime()}
        </SideSectionBox>
    )
}