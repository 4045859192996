import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {NotebookWithPenIcon} from "../../../../../assets/svg/notebook-with-pen.icon";
import {useServices} from "../../../../../hooks/use-services.hook";
import {CardBox} from "../../../../../basic-components/card/card.box";

const ContainerBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${props => props.theme.spacing.flexGap20};
    border: none;
    box-shadow: none;
`

const TitleText = styled.div`
    color: ${props => props.theme.colors.medium};
    font-size: ${props => props.theme.fontSize.subtitle1};
    font-weight: ${props => props.theme.fontWeight.medium};
`

const ChangeFlightButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap8};
    font-size: ${props => props.theme.fontSize.caption};
    cursor: pointer;
`


export const PsoBundlePlaceholderComponent: React.FC = observer(() => {
    const services = useServices();
    const onChangeSearch = async () => {
        await services.dialogFactory.showChangeFlightSearch();
    }
    return (
        <ContainerBox>
            <TitleText>
                {services.language.translate('Not interested in PSO Fares?')}
            </TitleText>
            <ChangeFlightButtonContainer onClick={onChangeSearch}>
                <NotebookWithPenIcon/>
                <span>{services.language.translate('Click here to change your search')}</span>
            </ChangeFlightButtonContainer>
        </ContainerBox>
    )
})