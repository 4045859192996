import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {RequestInvoiceToggleComponent} from "../invoice/request-invoice-toggle.component";
import styled from "styled-components";
import {PenToSquareIcon} from "../../../assets/svg/pen-to-square.icon";


const ContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${props => props.theme.spacing.pageContentVerticalPadding};
    color: ${props => props.theme.colors.mediumShade};
    padding: 0 ${props => props.theme.spacing.spacing8};
`


const InvoiceViewContainerBox = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: ${props => props.theme.spacing.spacing16};
    row-gap: 4px;
    width: 100%;
    font-size: ${props => props.theme.fontSize.body2};
    gap: ${props => props.theme.spacing.flexGap8};
    
`

const LabelBox = styled.div`
    font-weight: ${props => props.theme.fontWeight.bold};
`

const DetailsBox = styled.div`
    
`

const ModifyButtonBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap8};
    color: ${props => props.theme.colors.notesTint};
    font-size: ${props => props.theme.fontSize.caption};
    cursor: pointer;
`


export const InvoiceViewContainerComponent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;

    const renderInvoiceToggle = () => {
        if(booking.balanceDue.amount <= 0) {
            return  null;
        }

        if(!booking.invoice.isInvoiceRequestAllowed) {
            return null;
        }

        return (
            <RequestInvoiceToggleComponent invoice={booking.invoice} />
        )
    }

    const onModifyClick = async () => {
        await booking.invoice.showInvoiceDialog();
    }

    const renderInvoiceView = () => {
        if(!booking.invoice.invoiceRequested) {
            return null;

        }

        return (
            <InvoiceViewContainerBox>
                <LabelBox>
                    {services.language.translate('Billed to')}
                </LabelBox>
                <ModifyButtonBox onClick={onModifyClick}>
                    <span>
                        {services.language.translate('Modify')}
                    </span>

                    <PenToSquareIcon color={services.theme.currentTheme.colors.dark}/>
                </ModifyButtonBox>
                <DetailsBox>
                    {booking.invoice.renderInvoiceView()}
                </DetailsBox>

            </InvoiceViewContainerBox>
        )
    }

    return (
        <ContainerBox>
            {renderInvoiceToggle()}
            {renderInvoiceView()}
        </ContainerBox>
    )
})

