import React from "react";
import {observer} from "mobx-react";
import {StandardDialogPageComponent} from "../../modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../modal/header/standard-dialog-header.component";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";
import {StandardDialogFooterComponent} from "../../modal/footer/standard-dialog-footer.component";
import styled from "styled-components";
import {StandardDialogContentComponent} from "../../modal/content/standard-dialog-content.component";

const MaxBatchSizeWarningBox = styled.div`
    font-size: ${props => props.theme.fontSize.h5};
    
    text-align: justify;
`

export const WalletBatchSizeWarningDialogComponent: React.FC<{message: string; dialogHandler: IDialogConfirmationHandler;}> = observer((props) => {
    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler}/>
            <StandardDialogContentComponent dialogHandler={props.dialogHandler}>
                <MaxBatchSizeWarningBox>
                    {props.message}
                </MaxBatchSizeWarningBox>
            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}/>
        </StandardDialogPageComponent>
    )
})