import React from "react";
import { useServices } from "../../../../../../../hooks/use-services.hook";
import styled from 'styled-components';
import { observer } from "mobx-react";
import { SwiperSlide } from 'swiper/react'
import { NextFlightComponent } from "./next-flight.component";
import {SwiperComponent} from "../../../../../../../basic-components/swiper/swiper.component";
import {Pagination} from "swiper";
import {
    SWIPER_PAGINATION_BULLET_ACTIVE_CSS_CLASS,
    SWIPER_PAGINATION_BULLET_CSS_CLASS,
    SWIPER_PAGINATION_CSS_CLASS
} from "../../../../../../../basic-components/swiper/swiper-consts";

export const ContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap30};
`


export const TitleBox = styled.div`
    color: ${props => props.theme.colors.success};
    font-size: ${props => props.theme.fontSize.h1};
    font-weight: ${props => props.theme.fontWeight.bold};
`

const SwiperBox = styled(SwiperComponent)`
    & .${SWIPER_PAGINATION_CSS_CLASS} {
        bottom: 4px;
    }

    & .${SWIPER_PAGINATION_BULLET_CSS_CLASS} {
        background: rgba(255, 255, 255, 0.8);
       
    }
    
    & .${SWIPER_PAGINATION_BULLET_CSS_CLASS}.${SWIPER_PAGINATION_BULLET_ACTIVE_CSS_CLASS} {
        background: rgba(255, 255, 255);
    }
`

export const NextFlightsComponent: React.FC = observer(() => {
    const services = useServices();
    const nextFlights = services.bookingHistory.getNextFlights();

    if (nextFlights.length === 0) {
        return null;
    }



    return (<ContainerBox>
                <TitleBox>
                    {services.language.translate('Your upcoming travel:')}
                </TitleBox>
                <SwiperBox modules={[Pagination]}
                           pagination={true}
                           autoHeight={true}
                           slidesPerView={1}>
                    {nextFlights.map(nextFlight =>
                        <SwiperSlide key={nextFlight.uniqueKey}>
                            <NextFlightComponent key={nextFlight.uniqueKey} flight={nextFlight}/>
                        </SwiperSlide>)}
                </SwiperBox>

            </ContainerBox>);
})
