import React from "react";
import {observer} from "mobx-react";
import {IonIcon} from "@ionic/react";
import {ellipseOutline} from "ionicons/icons";
import styled from "styled-components";

const StopPointBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.tertiary};
    font-size: 12px;
`


export const StopPointComponent: React.FC<{className?: string}> = observer((props) => {
    return (
        <StopPointBox className={props.className}>
            <IonIcon ios={ellipseOutline} md={ellipseOutline}/>
        </StopPointBox>
    );
})