import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {
    FlightDatesDropdownComponent
} from "../../../flight-search/dates-selection/drop-down/flight-dates-dropdown.component";
import {
    PassengersTypesDropDownComponent
} from "../../../flight-search/passenger-types-selection/drop-down/passengers-types-drop-down.component";
import {
    SearchMaskBookNowButtonComponent
} from "../../common/search-mask-book-now-button.component";

import styled, {css, ThemeProvider} from "styled-components";
import {CardBox} from "../../../../basic-components/card/card.box";
import {
    OriginStationDropDownComponent
} from "../../../flight-search/stations-selection/drop-down/origin-station-drop-down.component";
import {
    DestinationStationDropDownComponent
} from "../../../flight-search/stations-selection/drop-down/destination-station-drop-down.component";
import {
    SearchMaskPsoMarketUserOptionsInputSmallScreenComponent
} from "../../common/search-mask-pso-market-user-options-input.small-screen.component";
import {
    SearchMaskSwapStationsButtonSmallScreenComponent
} from "./search-mask-swap-stations-button.small-screen.component";

export const FieldsCardBox = styled(CardBox)<{$isUsedInsideDialog: boolean}>`
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.spacing16};
    
    ${props => props.$isUsedInsideDialog
        ? css`
            border: none;
            background-color: ${props => props.theme.colors.dialogContentBackground};
            box-shadow: none;
            padding: 0;
        `
        : ''
    }
    
    
`

export const StationsContainerBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0;
    width: 100%;
`

export const OriginStation = styled(OriginStationDropDownComponent)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    
    not(&:focus-within) {
        border-bottom: none;
    }
`

export const DestinationStation = styled(DestinationStationDropDownComponent)`
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    
`

interface SearchMaskWebappSmallScreenComponentProps {
    onSearchClick?: () => Promise<void>;
    isUsedInsideDialog?: boolean;
}

export const SearchMaskWebappSmallScreenComponent: React.FC<SearchMaskWebappSmallScreenComponentProps> = observer((props) => {

    const services = useServices();

    const onSearchClick = async () => {
        if(props.onSearchClick) {
            await props.onSearchClick();
        } else {
            await services.flightSearch.startBooking();
        }
    }

    return (
        <ThemeProvider theme={services.theme.createSearchMaskTheme()}>
            <FieldsCardBox $isUsedInsideDialog={Boolean(props.isUsedInsideDialog)}>
                <StationsContainerBox>
                    <SearchMaskSwapStationsButtonSmallScreenComponent/>
                    <OriginStation/>
                    <DestinationStation isOnSmallScreen={true}/>
                </StationsContainerBox>
                <SearchMaskPsoMarketUserOptionsInputSmallScreenComponent/>
                <FlightDatesDropdownComponent/>
                <PassengersTypesDropDownComponent/>
                <SearchMaskBookNowButtonComponent onClick={onSearchClick}/>
            </FieldsCardBox>
        </ThemeProvider>
    );
})