import React from 'react';
import {observer} from "mobx-react-lite";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";
import styled from "styled-components";
import {XCloseSmallIcon} from "../../../assets/svg/x-close-small.icon";

const CloseButtonBox = styled.div<{$isHidden: boolean}>`
    visibility: ${props => props.$isHidden ? 'hidden' : 'visible'};
    cursor: pointer;
`

export type DialogCloseButtonVisibility = 'visible' | 'hidden' | 'not-render';

interface DialogCloseButtonComponentProps {
    dialogHandler: IDialogConfirmationHandler;
    onClick?: () => void;
    visibility: DialogCloseButtonVisibility;
}
export const DialogCloseButtonComponent: React.FC<DialogCloseButtonComponentProps> = observer((props) => {

    if(props.visibility === 'not-render') {
        return null;
    }
    const onClickHandler = () => {
        if(props.onClick) {
            props.onClick();
        } else {
            props.dialogHandler.onXCloseButtonClick();
        }
    }

    return (
        <CloseButtonBox onClick={onClickHandler} $isHidden={props.visibility === 'hidden'}>
            <XCloseSmallIcon size={2}/>
        </CloseButtonBox>
    )
});
