import {makeObservable, observable, runInAction} from "mobx";
import {PaymentMethodGroupModel} from "./payment-method-group.model";
import {CreditCardMethodsGroupModel} from "./credit-card-methods-group.model";
import {PaypalMethodGroupModel} from "./paypal-method-group.model";
import {IServiceFactory} from "../../../../service-factory.interface";
import {IPaymentSelectionHandlerViewModel} from "./payment-selection-handler.view-model.interface";
import {IPaymentMethodGroupViewModel} from "./payment-method-group.view-model.interface";
import React from "react";
import {
    SaveBookingButtonComponent
} from "../../../../../pages/booking-flow/payment/save-booking-button/save-booking-button.component";
import {BookingSessionStorageKeys} from "../../storage/booking-storage.interface";
import {IBookingViewModel} from "../../booking-view-model.interface";
import {PaymentMethodsGroupTypeEnum} from "./payment-method-group-type.enum";

export class PaymentSelectionHandlerModel implements IPaymentSelectionHandlerViewModel {
    constructor(private readonly services: IServiceFactory) {

        this.groups = [new CreditCardMethodsGroupModel(services), new PaypalMethodGroupModel(services)];
        const selectedPaymentGroupType = parseInt(services.booking.current.storage.getItem(BookingSessionStorageKeys.selectedPaymentGroup) ?? PaymentMethodsGroupTypeEnum.CreditCards.toString());
        this._selectedGroup = this.groups.find(g => g.groupType === selectedPaymentGroupType) ?? this.groups[0];

        makeObservable<this, '_selectedGroup'>(this, {
            _selectedGroup: observable.ref
        });
    }

    public readonly groups: PaymentMethodGroupModel[];

    private _selectedGroup: IPaymentMethodGroupViewModel;
    get booking(): IBookingViewModel {
        return this.services.booking.current;
    }

    get selectedGroup(): IPaymentMethodGroupViewModel {
        return this._selectedGroup;
    }

    set selectedGroup(value: IPaymentMethodGroupViewModel) {
        runInAction(() => {
            this._selectedGroup = value;
            this.booking.storage.setItem(BookingSessionStorageKeys.selectedPaymentGroup, value.groupType.toString())

        });
    }

    renderPayButton(): React.ReactElement {
        if(this.booking.balanceDue.amount === 0) {
            return (
                <SaveBookingButtonComponent/>
            )
        } else {
            return this.selectedGroup.renderPayButton();
        }
    }
}