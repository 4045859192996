import {IAlertService} from "./alert.service.interface";
import {ServiceBase} from "../service-base";
import {ErrorMessageDialogComponent} from "../../components/error-message/error-message-dialog.component";
import {DialogCloseButtonBehavior} from "../dialog/dialog-enums";

export class AlertService extends ServiceBase implements IAlertService {

    showError(message: string): void {
       this.showErrorAsync(message);
    }

    async showErrorAsync(message: string): Promise<void> {
        await this.services.dialog.showPopupDialog({
            closeButtonBehavior: DialogCloseButtonBehavior.Accept,
            backdropDismiss: true,
            width: '416px',
            maxHeight: '480px',
            render: dialogHandler => (<ErrorMessageDialogComponent dialogHandler={dialogHandler} message={message}/>)
        })
    }
}
