import React from "react";
import {observer} from "mobx-react";
import {NullableString} from "../../types/nullable-types";
import styled from "styled-components";
import { ExclamationTriangle } from "../../assets/svg/exclamation-triangle.icon";

const ErrorMessageBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: ${props => props.theme.spacing.flexGap8};
    color: ${props => props.theme.colors.danger};
    font-size: ${props => props.theme.fontSize.h6};
    font-weight: ${props => props.theme.fontWeight.bold};
    
`


const ErrorMessageTitleBox = styled.div`
    
`

interface ErrorMessageTextBoxComponentProps {
    errorMessage: NullableString;
}

export const ErrorMessageTextBoxComponent: React.FC<ErrorMessageTextBoxComponentProps> = observer((props) => {
    if(!props.errorMessage) {
        return null;
    }
    return (
        <ErrorMessageBox>
            <ExclamationTriangle size={1.5}/>
            <ErrorMessageTitleBox>{props.errorMessage}</ErrorMessageTitleBox>
        </ErrorMessageBox>
    )
});
