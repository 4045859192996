import React from "react";
import {observer} from "mobx-react";
import {StringFieldEditorComponent} from "../../../../components/forms/string-field/string-field-editor.component";
import {FormComponent} from "../../../../components/forms/form.component";
import {DateFieldEditorComponent} from "../../../../components/forms/date-time/date-field-editor.component";
import {GenderFieldEditorComponent} from "../../../../components/forms/gender/gender-field-editor.component";
import {useServices} from "../../../../hooks/use-services.hook";
import { CountryPickerComponent } from "../../../../components/country-picker/country-picker.component";
import {IUserProfileEditorViewModel} from "../../../../services/user/models/profile/user-profile-editor-view-model.interface";
import {FieldsSectionComponent} from "../../../../components/forms-view/fields-section.component";


interface ProfileEditFormComponentProps {
    userProfileEditor: IUserProfileEditorViewModel;
}

export const UserProfileEditComponent: React.FC<ProfileEditFormComponentProps> = observer((props) => {
    const services = useServices();
    const time = services.time;
    const maxBirthDate = time.addYears(time.makeShortDate(time.currentDate), -18);
    const fields = props.userProfileEditor.fields;

    return (
        <FormComponent title={props.userProfileEditor.getFullName()}>

            <FieldsSectionComponent title={services.language.translate(`Personal details`)}>
                <DateFieldEditorComponent field={fields.dateOfBirth}
                                          maximumValue={maxBirthDate}/>
                <GenderFieldEditorComponent field={fields.gender} useGenderAsNumber={true}/>
                <CountryPickerComponent key={fields.nationality.value}
                                        field={fields.nationality}
                                        popupHeaderTitle={services.language.translate('Search citizenship')}/>
            </FieldsSectionComponent>

            <FieldsSectionComponent title={services.language.translate(`Contact details`)}>
                <StringFieldEditorComponent field={fields.phoneNumber} />
            </FieldsSectionComponent>


        </FormComponent>
    )
});
