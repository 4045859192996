import { IAirlineWebapiService } from "./airline-webapi.service.interface";
import { HttpVerb } from "../../types/http-verb";
import { buildFullUrl, isHttpErrorStatusCode } from "../http/http-helpers";
import { APP_VERSION } from "../../version";
import { IGetConfigurationsResponse } from "./responses/get-configurations.response";
import { ServiceBase } from "../service-base";
import { ILoginRequest } from "./requests/login.request";
import { ILoginResponse } from "./responses/login.response";
import { ICreateDotRezTokenRequest } from "./requests/create-dot-rez-token.request";
import { ICreateDotRezTokenResponse } from "./responses/create-dot-rez-token.response";
import { IRegisterUserRequest } from "./requests/register-user.request";
import { AirlineWebapiResponse } from "./airline-webapi-response";
import { AirlineWebapiErrorCodesEnum } from "./airline-webapi-error-codes.enum";
import { IDotRezCompanion } from "../dot-rez-api/data-contracts/user/companion-data-contracts";
import { IAddCompanionRequest } from "./requests/add-companion.request";
import { IUpdateCompanionRequest } from "./requests/update-companion.request";
import { IAddCompanionTravelDocumentRequest } from "./requests/add-companion-travel-document.request";
import { IDotRezPersonTravelDocument } from "../dot-rez-api/data-contracts/user/person.data-contracts";
import { IUpdateCompanionTravelDocumentRequest } from "./requests/update-companion-travel-document.request";
import { IDeleteCompanionTravelDocumentRequest } from "./requests/delete-companion-travel-document.request";
import { IDeleteCompanionRequest } from "./requests/delete-companion.request";
import { IValidateInvoiceRequest } from "./requests/validate-invoice.request";
import { IValidateInvoiceResponse } from "./responses/validate-invoice.response";
import { IGetBookingsByCustomerNumberResponse } from "./responses/get-past-bookings.response";
import { IValidateBlueBenefitsSubscriptionsRequest } from "./requests/validate-blue-benefits-subscriptions.request";
import { IValidateBlueBenefitsSubscriptionsResponse } from "./responses/validate-blue-benefits-subscriptions.response";
import {IMaintenanceConfiguration} from "../configuration/objects/maintenance-configuration.interface";
import {ISegmentsDelayResponse} from "./responses/segments-delay.response";
import {ITravelInsuranceQuotesRequest} from "./requests/travel-insurance-quotes.request";
import {ITravelInsuranceQuotePackage} from "./responses/travel-insurance-quote.response";
import {DeeplinkAgentTokenRequest} from "./requests/deeplink-agent-token.request";
import {DeeplinkAgentTokenResponse} from "./responses/deeplink-agent-token.response";
import {IImpersonateUserRequest} from "./requests/impersonate-user.request";
import {ICreateDotRezAnonymousTokenResponse} from "./responses/create-dotrez-anonymous-token.response";
import {IClientPaymentCallbacks} from "./requests/begin-payment-base.request";
import {ICreateAppleBoardingPassesRequest} from "./requests/create-apple-boarding-passes.request";
import {ICreateAppleBoardingPassesResponse} from "./responses/create-apple-boarding-passes.response";
import {
    ICreateAppleBoardingPassesBundleResponse
} from "./responses/create-apple-boarding-pass-bundle.response";
import {ICreateAppleBoardingPassesBundleRequest} from "./requests/create-apple-boarding-passes-bundle.request";
import {IUpdateBoardingPassesInWalletsRequest} from "./requests/update-boarding-passes-in-wallets.request";
import {IBookingPaymentMethodsRequest} from "./requests/booking-payment-methods.request";
import {IBookingPaymentMethodsResponse} from "./responses/booking-payment-methods.response";
import {IBeginBookingPaymentRequest} from "./requests/begin-booking-payment.request";
import {IBeginBookingPaymentResponse} from "./responses/begin-booking-payment.response";
import {IBookingPaymentStatusRequest} from "./requests/booking-payment-status.request";
import {IBookingPaymentStatusResponse} from "./responses/booking-payment-status.response";
import {IApplyPsoDiscountFeeRequest} from "./requests/apply-pso-discount-fee.request";
import {ICreateBookingFeeRequest} from "./requests/booking-fee-create.request";
import {NullableString} from "../../types/nullable-types";
import {IOverrideBookingFeeRequest} from "./requests/booking-fee-override.request";
import {IDeleteBookingFeeRequest} from "./requests/booking-fee-delete.request";
import {IMoveFlightRequest} from "./requests/move-flight.request";
import {IMoveFlightSeatsRequest} from "./requests/move-flight-seats.request";
import {IMoveFlightSeatsResponse} from "./responses/move-flight-seats.response";
import {IAddSeatRequest} from "./requests/add-seat.request";
import {IAddSeatResponse} from "./responses/add-seat.response";
import {ICompletePspOrderRequest} from "./requests/complete-psp-order.request";
import {ICompletePspOrderResponse} from "./responses/complete-psp-order.response";
import {ICancelPspOrderRequest} from "./requests/cancel-psp-order.request";
import {IApplyPsoDiscountFeeResponse} from "./responses/apply-pso-discount-fee.response";

interface IFetchOptions {
    pathName: string;
    body?: any;
    doNotThrowOnError?: boolean;
}

export class AirlineWebapiService extends ServiceBase implements IAirlineWebapiService {

    private buildAirlineWebApiUrl(path: string): string {
        return buildFullUrl(this.services.configuration.airlineWebapiUrl, path);
    }

    private async _fetchData<TData = any>(method: HttpVerb, options: IFetchOptions): Promise<AirlineWebapiResponse<TData>> {

        const url = this.buildAirlineWebApiUrl(options.pathName);
        const fetchResponse = await fetch(url, {
            method: method,
            body: options.body && JSON.stringify(options.body),
            headers: {
                'Content-Type': 'application/json',
                ...this.services.configuration.envConfigs.ngrokHeadersForWebApi
            }
        });

        let fetchResponseData = await fetchResponse.json();

        if (isHttpErrorStatusCode(fetchResponse.status) || fetchResponseData.errorCode !== AirlineWebapiErrorCodesEnum.Success) {
            if (options.doNotThrowOnError) {
                return new AirlineWebapiResponse<TData>(fetchResponseData.errorCode || AirlineWebapiErrorCodesEnum.GeneralError, fetchResponseData.data);
            } else {
                throw new Error(`Failed to fetch from ${url}`);
            }
        }

        return new AirlineWebapiResponse<TData>(fetchResponseData.errorCode, fetchResponseData.data);
    }


    private async get<TData = any>(options: IFetchOptions): Promise<AirlineWebapiResponse<TData>> {
        return await this._fetchData('GET', options);
    }

    private async post<TData = any>(options: IFetchOptions): Promise<AirlineWebapiResponse<TData>> {
        return await this._fetchData('POST', options);
    }

    private async patch<TData = any>(options: IFetchOptions): Promise<AirlineWebapiResponse<TData>> {
        return await this._fetchData('PATCH', options);
    }

    private async put<TData = any>(options: IFetchOptions): Promise<AirlineWebapiResponse<TData>> {
        return await this._fetchData('PUT', options);
    }

    private async delete<TData = any>(options: IFetchOptions): Promise<AirlineWebapiResponse<TData>> {
        return await this._fetchData('DELETE', options);
    }

    async getConfigurations(language: string): Promise<IGetConfigurationsResponse> {
        let pathName = '/api/ClientConfigurations/v1/mobileApp';
        if(this.services.layout.shouldUseWebappLayout) {
            pathName = '/api/ClientConfigurations/v1/website';
        }

        const response = await this.post<IGetConfigurationsResponse>({
            pathName: pathName,
            body: {
                language: language,
                clientVersion: APP_VERSION,
                deviceType: this.services.device.deviceType
            }
        });

        return response.data;
    }

    async createDotRezAnonymousToken(): Promise<ICreateDotRezAnonymousTokenResponse> {
        let pathName = '/api/user/v1/mobileapp/anonymousToken';
        if(this.services.layout.shouldUseWebappLayout) {
            pathName = '/api/user/v1/website/anonymousToken'
        }
        const response = await this.post<ICreateDotRezAnonymousTokenResponse>({
            pathName: pathName,
            body:{}
        });

        return response.data;
    }

    async getMaintenance(): Promise<IMaintenanceConfiguration> {
        const response = await this.get<IMaintenanceConfiguration>({
            pathName: '/api/ClientConfigurations/maintenance'
        });
        return response.data;
    }


    private async _createClientPaymentCallbacks(callbacks: IClientPaymentCallbacks): Promise<string> {

        const callbacksObj = {
            origin: window.location.origin,
            ...callbacks
        }

        return await this.services.crypto.encryptMessage(JSON.stringify(callbacksObj));
    }

    async validateBlueAirInvoice(request: IValidateInvoiceRequest): Promise<IValidateInvoiceResponse> {
        const response = await this.post<IValidateInvoiceResponse>({
            pathName: '/api/Invoice/v1/validateInvoice',
            body: {
                invoiceValidator: request.invoiceValidator
            },
            doNotThrowOnError: true
        });
        return response.data;
    }

    async login(request: ILoginRequest): Promise<AirlineWebapiResponse<ILoginResponse>> {
        return await this.post<ILoginResponse>({
            pathName: `/api/User/v1/login`,
            body: request,
            doNotThrowOnError: true
        });
    }

    async createDotRezToken(request: ICreateDotRezTokenRequest): Promise<AirlineWebapiResponse<ICreateDotRezTokenResponse>> {
        return await this.post<ICreateDotRezTokenResponse>({
            pathName: '/api/User/v1/createDotRezToken',
            body: request,
            doNotThrowOnError: true
        });
    }

    async registerUser(request: IRegisterUserRequest): Promise<AirlineWebapiResponse<{}>> {
        return await this.post<{}>({
            pathName: '/api/User/v1/register',
            body: request,
            doNotThrowOnError: true
        });
    }

    async confirmRegistration(customerNumber: string): Promise<void> {
        await this.post({
            pathName: '/api/User/v1/confirmRegistration',
            body: {
                customerNumber: customerNumber
            }
        });
    }

    async sendResetPasswordLink(emailAddress: string): Promise<AirlineWebapiResponse<{}>> {
        return await this.post<{}>({
            pathName: '/api/PasswordRecovery/v1/sendPasswordResetLink',
            body: {
                emailAddress: emailAddress
            },
            doNotThrowOnError: true
        });
    }

    async resetPassword(resetPasswordToken: string, emailAddress: string, newPassword: string): Promise<AirlineWebapiResponse<ILoginResponse>> {
        return await this.post<ILoginResponse>({
            pathName: '/api/PasswordRecovery/v1/resetPassword',
            body: {
                token: resetPasswordToken,
                emailAddress: emailAddress,
                newPassword: newPassword
            },
            doNotThrowOnError: true
        });
    }

    async getCompanions(dotRezToken: string): Promise<IDotRezCompanion[]> {
        const response = await this.get<IDotRezCompanion[]>({
            pathName: `/api/Companions/v1/getCompanions?dotRezToken=${dotRezToken}`
        });

        return response.data;
    }

    async addCompanion(request: IAddCompanionRequest): Promise<IDotRezCompanion> {
        const response = await this.post<IDotRezCompanion>({
            pathName: `/api/Companions/v1/addCompanion`,
            body: request
        });

        return response.data;
    }

    async updateCompanion(request: IUpdateCompanionRequest): Promise<IDotRezCompanion> {
        const response = await this.patch({
            pathName: `/api/Companions/v1/updateCompanion`,
            body: request
        });
        return response.data;
    }

    async deleteCompanion(request: IDeleteCompanionRequest): Promise<void> {
        await this.delete({
            pathName: `/api/Companions/v1/deleteCompanion`,
            body: request
        });
    }

    async addCompanionTravelDocument(request: IAddCompanionTravelDocumentRequest): Promise<IDotRezPersonTravelDocument> {
        const response = await this.post<IDotRezPersonTravelDocument>({
            pathName: `/api/Companions/v1/addTravelDocument`,
            body: request
        });

        return response.data;
    }

    async updateCompanionTravelDocument(request: IUpdateCompanionTravelDocumentRequest): Promise<IDotRezPersonTravelDocument> {
        const response = await this.put<IDotRezPersonTravelDocument>({
            pathName: `/api/Companions/v1/updateTravelDocument`,
            body: request
        });

        return response.data;
    }

    async deleteCompanionTravelDocument(request: IDeleteCompanionTravelDocumentRequest): Promise<void> {
        await this.delete({
            pathName: `/api/Companions/v1/deleteTravelDocument`,
            body: request
        })
    }

    async getBookingsByCustomerNumber(dotRezToken: string): Promise<IGetBookingsByCustomerNumberResponse> {
        const response = await this.get<IGetBookingsByCustomerNumberResponse>({
            pathName: `/api/BookingHistory/v1/getBookingsByCustomerNumber?dotrezToken=${dotRezToken}`,
        });

        return response.data;
    }

    async getValidBlueBenefitsSubscriptions(request: IValidateBlueBenefitsSubscriptionsRequest): Promise<IValidateBlueBenefitsSubscriptionsResponse> {
        const response = await this.post<IValidateBlueBenefitsSubscriptionsResponse>({
            pathName: '/api/BlueBenefits/v1/validate',
            body: request
        });

        return response.data;
    }

    async getSegmentsDelays(recordLocator: string): Promise<ISegmentsDelayResponse> {
        const response = await this.get<ISegmentsDelayResponse>({
            pathName: `/api/BookingOperations/v1/segments/delays?RecordLocator=${recordLocator}`
        });

        return response.data;
    }

    async getTravelInsuranceQuote(request: ITravelInsuranceQuotesRequest): Promise<ITravelInsuranceQuotePackage> {
        const response = await this.post<ITravelInsuranceQuotePackage>({
            pathName: `/api/TravelInsurance/v1/quotes`,
            body: request
        });

        return response.data;
    }

    async getDeepLinkAgentToken(request: DeeplinkAgentTokenRequest): Promise<DeeplinkAgentTokenResponse> {
        const response = await this.post<DeeplinkAgentTokenResponse>({
            pathName: '/api/User/v2/deepLinkAgentToken', body: {
                agentName: request.agentName
            }
        });

        return response.data;
    }

    async impersonateUser(request: IImpersonateUserRequest): Promise<void> {
        await this.post({
            pathName: '/api/User/v1/impersonate', body: {
                agentName: request.agentName,
                dotRezToken: request.dotRezToken
            }
        });
    }

    async createAppleBoardingPasses(request: ICreateAppleBoardingPassesRequest): Promise<ICreateAppleBoardingPassesResponse> {
        const response = await this.post<ICreateAppleBoardingPassesResponse>({
            pathName: '/api/boardingPass/apple/v1/create',
            body: request
        });

        return response.data;
    }

    async createAppleBoardingPassesBundle(request: ICreateAppleBoardingPassesBundleRequest): Promise<ICreateAppleBoardingPassesBundleResponse> {
        const response = await this.post<ICreateAppleBoardingPassesBundleResponse>({
            pathName: '/api/boardingPass/apple/v1/create/bundle',
            body: request
        });

        return response.data;
    }

    async updateBoardingPassesInWallets(request: IUpdateBoardingPassesInWalletsRequest): Promise<void> {
        await this.post({
            pathName: '/api/boardingPass/wallets/v1/pushUpdates',
            body: request
        });
    }


    async getBookingPaymentMethods(request: IBookingPaymentMethodsRequest): Promise<IBookingPaymentMethodsResponse> {
        const response = await this.get<IBookingPaymentMethodsResponse>({
            pathName: `/api/bookingPayment/v1/methods?dotRezToken=${request.dotRezToken}`
        })

        return response.data;
    }

    async beginBookingPayment(request: IBeginBookingPaymentRequest): Promise<IBeginBookingPaymentResponse> {
        let pathName = `/api/bookingPayment/v1/website/begin`;
        if(this.services.device.isHybrid) {
            pathName = `/api/bookingPayment/v1/mobileApp/begin`;
        }

        const {callbacks, ...restOfTheRequestParams} = request;

        const response = await this.post<IBeginBookingPaymentResponse>({
            pathName: pathName,
            body: {
                ...restOfTheRequestParams,
                clientVersion: APP_VERSION,
                channel: this.services.booking.getPaymentChannel(),
                clientOS: this.services.device.getOperatingSystemName(),
                clientCallbacks: await this._createClientPaymentCallbacks(callbacks)
            }
        });

        return response.data;
    }

    async completePspOrder(request: ICompletePspOrderRequest): Promise<ICompletePspOrderResponse> {
        const response = await this.post<ICompletePspOrderResponse>({
            pathName: '/api/bookingPayment/v1/completePspOrder',
            body: request
        });

        return response.data;
    }

    async cancelPspOrder(request: ICancelPspOrderRequest): Promise<void> {
        await this.post({
            pathName: '/api/bookingPayment/v1/cancelPspOrder',
            body: request
        });
    }

    async getBookingPaymentStatus(request: IBookingPaymentStatusRequest): Promise<IBookingPaymentStatusResponse> {
        const response = await this.get<IBookingPaymentStatusResponse>({
            pathName: `/api/bookingPayment/v1/status/${request.paymentToken}`
        })

        return response.data;
    }

    async applyPsoDiscountFee(request: IApplyPsoDiscountFeeRequest): Promise<IApplyPsoDiscountFeeResponse> {
        let payload = JSON.stringify(request);

        payload = await this.services.crypto.encryptMessage(payload);

        const response = await this.post<IApplyPsoDiscountFeeResponse>({
            pathName: `/api/BookingOperations/v2/fees/marketDiscount`,
            body: {
                token: payload
            }
        });

        return response.data;
    }

    async createBookingFee(request: ICreateBookingFeeRequest): Promise<NullableString> {
        let payload = JSON.stringify(request);
        payload = await this.services.crypto.encryptMessage(payload);

        await this.post({
            pathName: `/api/BookingOperations/v1/fees`,
            body: {
                token: payload
            }
        });

        return null;
    }
    async overrideBookingFee(request: IOverrideBookingFeeRequest): Promise<void> {
        await this.put({
            pathName: `/api/BookingOperations/v1/fees/${request.feeKey}`,
            body: {
                dotRezToken: request.dotRezToken,
                amount: request.amount
            }
        });
    }
    async deleteBookingFee(request: IDeleteBookingFeeRequest): Promise<void> {
        await this.delete({
            pathName: `/api/BookingOperations/v1/fees/${request.feeKey}?dotRezToken=${request.dotRezToken}`
        });
    }

    async moveFlight(request: IMoveFlightRequest): Promise<void> {
        await this.post({
            pathName: '/api/BookingOperations/v1/moveFlight',
            body: {
                token: await this.services.crypto.encryptMessage(JSON.stringify(request))
            }
        });
    }

    async moveFlightSeats(request: IMoveFlightSeatsRequest): Promise<IMoveFlightSeatsResponse> {
        const response = await this.post<IMoveFlightSeatsResponse>({
            pathName: '/api/BookingOperations/v1/moveFlight/seats',
            body: {
                token: await this.services.crypto.encryptMessage(JSON.stringify(request))
            }
        });

        return response.data;
    }

    async addSeat(request: IAddSeatRequest): Promise<IAddSeatResponse> {
        const response = await this.post<IAddSeatResponse>({
            pathName: '/api/BookingOperations/v1/seats',
            body: request
        });

        return response.data;
    }

}
