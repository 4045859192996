import React, {useState} from "react";
import {observer} from "mobx-react";
import {IPromotionalViewModel} from "../../../services/booking/models/promotions/promotional-view-model.interface";
import styled from "styled-components";
import {IonIcon} from "@ionic/react";
import {closeCircleOutline} from "ionicons/icons";
import {setElementFocus} from "../../../utils/set-element-focus";
import {Check} from "../../../types/type-checking";
import {InputBaseBox} from "../../../basic-components/input/input-base.box";

const PromotionalCodeContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    background-color: ${props => props.theme.colors.cardBackground};
    color: ${props => props.theme.colors.cardColor};
    border: 1px solid ${props => props.theme.border.mainColor};
    font-size: ${props => props.theme.fontSize.body2};
    border-radius: ${props => props.theme.border.defaultRadius};
    padding: ${props => props.theme.spacing.spacing24} ${props => props.theme.spacing.spacing20};
`



export const CodeInputBox = styled(InputBaseBox)`
    flex-grow: 1;
    &::placeholder {
      opacity: 1;
    }
`

export const RemoveCodeIconBox = styled(IonIcon)`
    font-size: ${props => props.theme.fontSize.body1};
    color: ${props => props.theme.colors.medium};
    cursor: pointer;
`

interface RemovePromotionalButtonComponentProps {
    promotional: IPromotionalViewModel;
    onRemoved?: () => void;
}
const RemovePromotionalButtonComponent: React.FC<RemovePromotionalButtonComponentProps> = observer((props) => {
    if(!props.promotional.code.value) {
        return null;
    }

    const onClickHandler = async () => {
        await props.promotional.remove();
        if(props.onRemoved) {
            props.onRemoved();
        }
    }

    return(
        <RemoveCodeIconBox icon={closeCircleOutline} onClick={onClickHandler}/>
    );
});

interface PromotionalInputComponentProps {
    promotional: IPromotionalViewModel;
    placeHolder: string;
    captureInputElementRef?: (element: any) => void;
    className?: string;
    onClear?: () => void;
}

export const PromotionalCodeInputComponent: React.FC<PromotionalInputComponentProps> = observer((props) => {
    const code = props.promotional.code;
    const [elementRef, setElementRef] = useState(null);

    const captureElementInputRef = (element: any) => {
        setElementRef(element);
        if(props.captureInputElementRef) {
            props.captureInputElementRef(element);
        }
    };

    const onRemovedHandler = () => {
        setValue(null);
        if(elementRef) {
            setElementFocus(elementRef);
        }

        if(props.onClear) {
            props.onClear();
        }

    }

    const setValue = (value: string | number | null | undefined) => {
        if(Check.isNullOrUndefined(value) || Check.isEmpty(value)) {
            code.setValue(null);
        } else {
            code.setValue(value.toString());
        }
    }

    return (
        <PromotionalCodeContainerBox>
            <CodeInputBox placeholder={props.placeHolder}
                          onChange={(e) => setValue(e.target.value)}
                          value={code.value ?? ""}
                          disabled={props.promotional.isApplied || !props.promotional.isAllowed}
                          ref={captureElementInputRef}
                          className={props.className}>
            </CodeInputBox>
            <RemovePromotionalButtonComponent promotional={props.promotional} onRemoved={onRemovedHandler}/>
        </PromotionalCodeContainerBox>
    );
});
