import {ExtrasSingleSsrSectionRenderer} from "../../base-renderers/extras-single-ssr-section-renderer";
import {IBookingViewModel} from "../../../../../services/booking/models/booking-view-model.interface";
import {NullableString} from "../../../../../types/nullable-types";

export class AirportCheckInExtrasSectionRenderer extends ExtrasSingleSsrSectionRenderer {
    constructor(booking: IBookingViewModel) {
        super(booking.services.ssrTypes.CKN, booking);
    }

    protected _getMarketingMessage(): NullableString {
        return this.services.language.translate('Service from a real person, book now for assurance and a piece of mind.')
    }

    protected _getExtrasLargeTilePicture(): NullableString {

        return this.services.theme.currentTheme.assets.images.extras.airport_checkin_2x_jpg.toString();
    }
}
