import React from "react";
import {ISvgIconProps, SvgIcon} from "../../../../assets/svg/svg-icon.component";

export const PbrdSsrLargeIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={3.5} sizeIsHeight {...props} viewBox="0 0 139 51" ratio={139 / 51}>
            <g opacity="0.3">
                <path
                    d="M65.7596 49.4361C65.7595 49.6223 65.6855 49.8009 65.5537 49.9326C65.422 50.0643 65.2434 50.1383 65.0571 50.1383H35.0326C34.8463 50.1383 34.6676 50.0643 34.5359 49.9326C34.4042 49.8009 34.3301 49.6223 34.3301 49.4361C34.33 49.3438 34.3482 49.2524 34.3835 49.1672C34.4188 49.0819 34.4705 49.0044 34.5357 48.9392C34.601 48.8739 34.6784 48.8222 34.7637 48.7868C34.8489 48.7515 34.9403 48.7333 35.0326 48.7333H65.0573C65.1496 48.7333 65.241 48.7515 65.3262 48.7868C65.4115 48.8222 65.4889 48.8739 65.5542 48.9392C65.6194 49.0044 65.6711 49.0819 65.7064 49.1672C65.7417 49.2524 65.7599 49.3438 65.7598 49.4361H65.7596Z"
                    fill="#282538"/>
            </g>
            <path
                d="M64.3428 31.449H64.6462C64.8442 31.449 65.0402 31.41 65.2231 31.3343C65.406 31.2585 65.5722 31.1475 65.7122 31.0075C65.8522 30.8675 65.9632 30.7013 66.039 30.5184C66.1147 30.3355 66.1537 30.1395 66.1537 29.9415V22.9071C66.1537 22.7091 66.1147 22.5131 66.039 22.3302C65.9632 22.1474 65.8522 21.9812 65.7122 21.8412C65.5722 21.7013 65.406 21.5902 65.2231 21.5145C65.0402 21.4388 64.8442 21.3998 64.6462 21.3998H64.3428V31.449Z"
                fill="#253E2B"/>
            <path
                d="M54.7772 1.95024V1.64628C54.7772 1.44832 54.7382 1.25229 54.6624 1.06939C54.5867 0.886494 54.4756 0.72031 54.3357 0.580327C54.1957 0.440344 54.0295 0.329303 53.8466 0.253545C53.6637 0.177786 53.4677 0.138794 53.2697 0.138794H46.234C46.0361 0.138794 45.8401 0.177786 45.6572 0.253545C45.4743 0.329303 45.3081 0.440344 45.1681 0.580327C45.0281 0.72031 44.9171 0.886494 44.8413 1.06939C44.7656 1.25229 44.7266 1.44832 44.7266 1.64628V1.95L54.7772 1.95024Z"
                fill="#253E2B"/>
            <path
                d="M40.1361 43.4886H37.9944C37.8384 43.4886 37.7119 43.6151 37.7119 43.7711V49.3189C37.7119 49.4749 37.8384 49.6013 37.9944 49.6013H40.1361C40.2921 49.6013 40.4186 49.4749 40.4186 49.3189V43.7711C40.4186 43.6151 40.2921 43.4886 40.1361 43.4886Z"
                fill="#282538"/>
            <path
                d="M61.5463 43.4888H59.4046C59.2485 43.4888 59.1221 43.6152 59.1221 43.7713V49.319C59.1221 49.475 59.2485 49.6015 59.4046 49.6015H61.5463C61.7023 49.6015 61.8287 49.475 61.8287 49.319V43.7713C61.8287 43.6152 61.7023 43.4888 61.5463 43.4888Z"
                fill="#282538"/>
            <path
                d="M65.2022 46.3324V12.2795C65.2022 10.8938 64.9293 9.52176 64.3991 8.24159C63.8689 6.96142 63.0917 5.79822 62.1119 4.8184C61.1322 3.83859 59.969 3.06135 58.6888 2.53106C57.4087 2.00077 56.0366 1.72781 54.651 1.72778H44.8925C42.094 1.72778 39.4102 2.83948 37.4313 4.8183C35.4525 6.79713 34.3408 9.48099 34.3408 12.2795V46.3324C34.3408 46.5304 34.3798 46.7264 34.4556 46.9093C34.5313 47.0922 34.6424 47.2583 34.7824 47.3983C34.9224 47.5383 35.0885 47.6493 35.2714 47.725C35.4543 47.8007 35.6504 47.8397 35.8483 47.8397H63.695C64.0947 47.8397 64.4781 47.6809 64.7607 47.3982C65.0434 47.1155 65.2022 46.7322 65.2022 46.3324Z"
                fill="#4EA046"/>
            <path
                d="M63.4896 20.5682V13.0028C63.4786 10.7375 62.5695 8.56899 60.9618 6.97303C59.3541 5.37706 57.179 4.48394 54.9136 4.48959H44.6321C42.3667 4.48381 40.1914 5.37687 38.5836 6.97285C36.9758 8.56883 36.0666 10.7374 36.0557 13.0028V20.5685L63.4896 20.5682Z"
                fill="#338141"/>
            <path
                d="M62.7918 19.5131V12.2801C62.7893 10.1219 61.9309 8.05294 60.4048 6.52693C58.8788 5.00091 56.8098 4.1425 54.6517 4.14001H44.8935C42.7354 4.1425 40.6664 5.00093 39.1405 6.52696C37.6146 8.05299 36.7563 10.122 36.7539 12.2801V19.5131H62.7918Z"
                fill="#9CE29C"/>
            <path
                d="M38.2664 12.0265C38.3328 10.3132 39.0598 8.69216 40.295 7.503C41.5301 6.31383 43.1776 5.64889 44.8922 5.64749H54.6504C56.365 5.64935 58.0124 6.31441 59.2477 7.50344C60.483 8.69246 61.2104 10.3133 61.2777 12.0265H61.5792C61.5119 10.2333 60.7528 8.53583 59.461 7.29027C58.1692 6.04471 56.4452 5.34787 54.6507 5.34595H44.8922C43.0979 5.34824 41.3743 6.04524 40.0828 7.29075C38.7912 8.53627 38.0322 10.2335 37.9648 12.0265H38.2664Z"
                fill="#4EA046"/>
            <path d="M36.754 21.3884L36.2324 46.5151H63.3132L62.7918 21.3884H36.754Z" fill="#338141"/>
            <path d="M36.7539 21.3883H62.7918V45.4275H36.7539V21.3883Z" fill="#9CE29C"/>
            <path
                d="M34.123 43.8989V46.4755C34.1231 46.8809 34.2842 47.2696 34.5708 47.5562C34.8574 47.8429 35.2462 48.0039 35.6515 48.004H38.2287L34.123 43.8989Z"
                fill="#3A4151"/>
            <path
                d="M61.3164 48.0042H63.8935C64.2989 48.0042 64.6877 47.8431 64.9744 47.5565C65.2611 47.2699 65.4222 46.8811 65.4222 46.4757V43.8983L61.3164 48.0042Z"
                fill="#3A4151"/>
            <path d="M61.557 25.7327H38.1396V26.1507H61.557V25.7327Z" fill="#4EA046"/>
            <path d="M38.6182 27.3459L38.4703 26.2519H38.596V25.5945H37.9929V26.2519H38.1186L37.9707 27.3459H38.6182Z"
                  fill="#545460"/>
            <path d="M38.0332 26.1932V25.6565H38.5555L38.0332 26.1932Z" fill="#AFB1B1"/>
            <path d="M38.4619 13.3644L38.3141 12.2704H38.4397V11.613H37.8366V12.2704H37.9623L37.8145 13.3644H38.4619Z"
                  fill="#545460"/>
            <path d="M37.876 12.2109V11.6739H38.3999L37.876 12.2109Z" fill="#AFB1B1"/>
            <g opacity="0.3">
                <path
                    d="M24.5108 49.2736C24.5098 49.3883 24.4862 49.5016 24.4413 49.6072C24.3965 49.7128 24.3313 49.8085 24.2495 49.8888C24.1676 49.9692 24.0708 50.0326 23.9644 50.0755C23.8581 50.1185 23.7443 50.14 23.6296 50.139H1.07744C0.962765 50.14 0.848994 50.1185 0.742646 50.0755C0.636298 50.0326 0.539449 49.9692 0.457625 49.8888C0.375802 49.8085 0.31061 49.7128 0.265769 49.6072C0.220928 49.5016 0.19732 49.3883 0.196289 49.2736C0.19732 49.1589 0.220928 49.0456 0.265769 48.94C0.31061 48.8345 0.375802 48.7388 0.457625 48.6584C0.539449 48.5781 0.636298 48.5146 0.742646 48.4717C0.848994 48.4288 0.962765 48.4072 1.07744 48.4082H23.6296C23.7443 48.4072 23.8581 48.4288 23.9644 48.4717C24.0708 48.5146 24.1676 48.5781 24.2495 48.6584C24.3313 48.7388 24.3965 48.8345 24.4413 48.94C24.4862 49.0456 24.5098 49.1589 24.5108 49.2736Z"
                    fill="#282538"/>
            </g>
            <path
                d="M21.9835 15.9457L19.8668 14.9587C19.1832 14.6399 18.1896 15.3237 17.6488 16.4832L14.6009 23.0195C14.0598 24.18 14.1751 25.3797 14.8587 25.6985L16.9754 26.6855C17.6577 27.0037 18.653 26.3207 19.1937 25.1611L22.2416 18.6249C22.7823 17.4653 22.6674 16.2646 21.9835 15.9457Z"
                fill="#253E2B"/>
            <path
                d="M4.44049 14.9597L2.32379 15.9467C1.64021 16.2655 1.52529 17.4661 2.066 18.6257L5.11391 25.162C5.65508 26.3225 6.64825 27.0052 7.33183 26.6865L9.44854 25.6995C10.1308 25.3813 10.2474 24.1799 9.70665 23.0203L6.65874 16.4841C6.11803 15.3245 5.1244 14.6408 4.44049 14.9597Z"
                fill="#253E2B"/>
            <path
                d="M23.4536 47.427V27.4921C23.4535 25.213 22.548 23.0273 20.9364 21.4157C19.3248 19.8042 17.1391 18.8989 14.86 18.8989H9.44868C7.16962 18.8989 4.98391 19.8042 3.37237 21.4158C1.76083 23.0273 0.855469 25.213 0.855469 27.4921V47.4284C0.855469 47.8504 1.02308 48.2551 1.32143 48.5535C1.61978 48.8519 2.02445 49.0196 2.44643 49.0197H21.8615C22.0707 49.0198 22.2778 48.9787 22.4711 48.8987C22.6643 48.8187 22.8399 48.7013 22.9877 48.5534C23.1356 48.4055 23.2529 48.2298 23.3328 48.0366C23.4127 47.8433 23.4538 47.6361 23.4536 47.427Z"
                fill="#253E2B"/>
            <path
                d="M23.7316 46.7688V26.3406C23.7316 25.1843 23.5039 24.0393 23.0613 22.971C22.6188 21.9027 21.9702 20.932 21.1525 20.1143C20.3349 19.2967 19.3642 18.6481 18.2958 18.2057C17.2275 17.7632 16.0825 17.5355 14.9262 17.5355H9.38223C7.04698 17.5355 4.80737 18.4632 3.1561 20.1145C1.50483 21.7657 0.577148 24.0053 0.577148 26.3406V46.7688C0.577243 47.2009 0.748851 47.6153 1.05428 47.921C1.3597 48.2267 1.77397 48.3987 2.2061 48.3992H22.1019C22.5342 48.3989 22.9487 48.227 23.2542 47.9213C23.5598 47.6156 23.7315 47.201 23.7316 46.7688Z"
                fill="#338141"/>
            <path
                d="M23.7316 27.201V26.3406C23.7316 25.1843 23.5039 24.0393 23.0613 22.971C22.6188 21.9027 21.9702 20.932 21.1525 20.1143C20.3349 19.2967 19.3642 18.6481 18.2958 18.2057C17.2275 17.7632 16.0825 17.5355 14.9262 17.5355H9.38223C7.04698 17.5355 4.80737 18.4632 3.1561 20.1145C1.50483 21.7657 0.577148 24.0053 0.577148 26.3406V27.201H23.7316Z"
                fill="#338141"/>
            <path opacity="0.1"
                  d="M9.38262 25.3896H14.9266C16.2048 25.3892 17.4635 25.0756 18.5926 24.4763C19.7216 23.877 20.6866 23.0102 21.4033 21.9518C20.6867 20.8933 19.7216 20.0264 18.5926 19.4271C17.4635 18.8277 16.2048 18.5141 14.9266 18.5135H9.38262C8.10438 18.514 6.84573 18.8276 5.71673 19.427C4.58773 20.0264 3.62277 20.8932 2.90625 21.9518C3.62278 23.0103 4.58776 23.8771 5.71676 24.4764C6.84576 25.0757 8.10441 25.3892 9.38262 25.3896Z"
                  fill="#D8D8DA"/>
            <path
                d="M1.74025 24.6459C2.12804 22.9077 3.09609 21.3533 4.48505 20.2386C5.87401 19.1239 7.6011 18.5153 9.38206 18.513H14.926C16.7069 18.5154 18.434 19.124 19.8229 20.2387C21.2119 21.3534 22.18 22.9077 22.5678 24.6459H22.9012C22.5107 22.8197 21.5062 21.1826 20.0549 20.0073C18.6037 18.832 16.7935 18.1896 14.926 18.1871H9.38206C7.51466 18.1896 5.70452 18.8321 4.25336 20.0074C2.8022 21.1827 1.79767 22.8198 1.40723 24.6459H1.74025Z"
                fill="#9CE29C"/>
            <path d="M23.732 26.8519H0.577148V27.4743H23.732V26.8519Z" fill="#494B5C"/>
            <path
                d="M4.03793 30.4536H4.45557C4.72911 30.4533 4.99137 30.3445 5.18479 30.1511C5.37821 29.9577 5.487 29.6954 5.48728 29.4219V27.8833C5.48709 27.753 5.43525 27.6281 5.34313 27.5359C5.251 27.4438 5.12609 27.392 4.9958 27.3918H3.49734C3.36705 27.392 3.24216 27.4438 3.15003 27.5359C3.0579 27.6281 3.00605 27.753 3.00586 27.8833V29.4219C3.00624 29.6954 3.11511 29.9577 3.30859 30.1511C3.50207 30.3445 3.76436 30.4533 4.03793 30.4536ZM3.37439 27.8833C3.37439 27.8507 3.38734 27.8194 3.4104 27.7963C3.43346 27.7733 3.46473 27.7603 3.49734 27.7603H4.9958C5.01195 27.7603 5.02795 27.7635 5.04286 27.7697C5.05778 27.7758 5.07133 27.7849 5.08275 27.7963C5.09417 27.8077 5.10322 27.8213 5.1094 27.8362C5.11558 27.8511 5.11877 27.8671 5.11877 27.8833V29.4219C5.11881 29.509 5.1017 29.5953 5.06839 29.6758C5.03508 29.7563 4.98623 29.8294 4.92465 29.8911C4.86306 29.9527 4.78993 30.0016 4.70944 30.0349C4.62896 30.0683 4.54269 30.0854 4.45557 30.0854H4.03793C3.86195 30.0854 3.69318 30.0155 3.56874 29.8911C3.4443 29.7666 3.37439 29.5979 3.37439 29.4219V27.8833Z"
                fill="#D8D8DA"/>
            <path d="M5.0119 26.4459H3.46289V27.9136H5.0119V26.4459Z" fill="#525568"/>
            <path
                d="M19.8573 30.4541H20.2749C20.5484 30.4538 20.8107 30.345 21.0041 30.1516C21.1975 29.9582 21.3063 29.6959 21.3066 29.4224V27.8838C21.3064 27.7535 21.2546 27.6286 21.1625 27.5364C21.0703 27.4443 20.9454 27.3925 20.8151 27.3923H19.3163C19.1861 27.3925 19.0612 27.4443 18.9691 27.5365C18.8771 27.6286 18.8253 27.7535 18.8252 27.8838V29.4224C18.8255 29.696 18.9343 29.9583 19.1278 30.1517C19.3213 30.3451 19.5837 30.4539 19.8573 30.4541ZM19.1937 27.8838C19.1937 27.8676 19.1968 27.8516 19.2029 27.8367C19.2091 27.8218 19.2181 27.8083 19.2295 27.7968C19.2409 27.7854 19.2544 27.7763 19.2693 27.7702C19.2842 27.764 19.3002 27.7608 19.3163 27.7608H20.8151C20.8313 27.7608 20.8472 27.764 20.8621 27.7702C20.877 27.7763 20.8906 27.7854 20.902 27.7968C20.9133 27.8083 20.9224 27.8218 20.9285 27.8367C20.9346 27.8516 20.9378 27.8676 20.9377 27.8838V29.4224C20.9378 29.5095 20.9207 29.5957 20.8874 29.6762C20.8541 29.7567 20.8053 29.8298 20.7437 29.8914C20.6822 29.953 20.6091 30.0019 20.5287 30.0353C20.4482 30.0687 20.362 30.0859 20.2749 30.0859H19.8573C19.7701 30.086 19.6838 30.0688 19.6033 30.0355C19.5227 30.0022 19.4496 29.9533 19.388 29.8917C19.3263 29.83 19.2775 29.7569 19.2441 29.6764C19.2108 29.5958 19.1937 29.5095 19.1937 29.4224V27.8838Z"
                fill="#D8D8DA"/>
            <path d="M20.8303 26.4464H19.2812V27.914H20.8303V26.4464Z" fill="#525568"/>
            <path d="M22.3456 31.4276H1.63672V31.672H22.3456V31.4276Z" fill="#9CE29C"/>
            <path d="M2.05576 33.0806L1.90663 31.9782H2.03317V31.3157H1.4259V31.9782H1.55244L1.40332 33.0806H2.05576Z"
                  fill="#F1B14F"/>
            <path d="M1.4668 31.918V31.3771H1.99341L1.4668 31.918Z" fill="#D8D8DA"/>
            <path d="M1.93565 26.0698L1.78651 24.9675H1.91305V24.305H1.30578V24.9675H1.43233L1.2832 26.0698H1.93565Z"
                  fill="#F1B14F"/>
            <path d="M1.3457 24.9078V24.3668H1.87231L1.3457 24.9078Z" fill="#D8D8DA"/>
            <path
                d="M128.366 16.0254C130.428 16.0254 132.099 14.3537 132.099 12.2916C132.099 10.2294 130.428 8.55774 128.366 8.55774C126.304 8.55774 124.632 10.2294 124.632 12.2916C124.632 14.3537 126.304 16.0254 128.366 16.0254Z"
                fill="#102930"/>
            <path
                d="M118.02 47.4952C117.834 47.9402 117.816 48.4371 117.969 48.8943C118.121 49.3515 118.434 49.7381 118.849 49.9829C119.679 50.3978 120.923 49.9829 121.338 48.7384L124.242 40.0262L121.338 36.2924L118.02 47.4952Z"
                fill="#102930"/>
            <path
                d="M138.324 28.8248L132.931 23.8465L130.441 19.6977C130.027 19.2828 129.611 18.4546 128.367 18.4546L125.878 17.6233C124.218 17.2084 122.974 17.2084 122.144 17.6233C121.43 18.0774 118.494 19.5888 117.166 20.9422L112.602 27.1654C112.398 27.5484 112.291 27.9759 112.291 28.41C112.291 28.8442 112.398 29.2716 112.602 29.6547L110.528 34.6327C110.304 34.4071 110.013 34.2613 109.698 34.2177L107.209 32.9742C105.965 32.5593 104.72 32.9742 103.89 34.2187L99.3265 44.1747C98.9116 45.4192 99.3265 46.664 100.571 47.0786L102.229 47.9085C102.229 46.2501 103.888 45.0046 105.548 45.0046C105.963 45.0046 106.793 45.4195 107.207 45.4195L108.037 43.7611L114.674 30.0706C115.089 30.0706 115.504 29.6557 115.919 29.2408L120.07 23.4299L122.974 21.7714L120.9 30.8992C120.485 32.5576 121.314 33.8031 122.144 34.633L126.707 40.4409L129.197 48.3231C129.612 49.153 130.441 49.9815 131.686 49.5676C132.516 49.1527 133.344 48.3231 132.931 47.0783C132.417 45.3652 130.832 40.4409 130.832 40.4409C130.832 40.4409 130.135 38.3665 130.026 38.3665C128.945 36.7167 127.122 34.2177 127.122 34.2177L129.197 25.5056C131.267 27.427 133.198 29.4694 135.834 31.7287C136.664 32.5586 137.909 32.1437 138.324 31.7287C139.154 30.8988 138.739 29.6544 138.324 28.8248Z"
                fill="#102930"/>
            <path
                d="M105.185 49.9834C106.101 49.9834 106.844 49.2404 106.844 48.3239C106.844 47.4074 106.101 46.6645 105.185 46.6645C104.268 46.6645 103.525 47.4074 103.525 48.3239C103.525 49.2404 104.268 49.9834 105.185 49.9834Z"
                fill="#102930"/>
            <path
                d="M91.5164 20.9864H102.303C102.518 20.9719 102.721 20.8797 102.874 20.7272C103.026 20.5746 103.118 20.3718 103.133 20.1565C103.118 19.9414 103.026 19.739 102.873 19.5867C102.72 19.4343 102.518 19.3425 102.303 19.328H91.5164C91.3011 19.3426 91.0984 19.4347 90.9458 19.5873C90.7932 19.7399 90.7011 19.9426 90.6865 20.1579C90.7014 20.373 90.7937 20.5754 90.9462 20.7277C91.0988 20.88 91.3013 20.9719 91.5164 20.9864Z"
                fill="#102930"/>
            <path
                d="M100.643 32.1686H89.8572C89.6419 32.1832 89.4392 32.2753 89.2866 32.4279C89.134 32.5805 89.0419 32.7832 89.0273 32.9985C89.0422 33.2136 89.1345 33.416 89.287 33.5683C89.4396 33.7206 89.6421 33.8125 89.8572 33.8271H100.644C100.859 33.8125 101.062 33.7204 101.214 33.5678C101.367 33.4152 101.459 33.2125 101.474 32.9972C101.459 32.7821 101.366 32.5796 101.214 32.4273C101.061 32.275 100.859 32.1831 100.643 32.1686Z"
                fill="#102930"/>
            <path
                d="M95.251 26.7852C95.2659 27.0003 95.3581 27.2027 95.5107 27.355C95.6632 27.5073 95.8658 27.5992 96.0809 27.6138H106.867C107.083 27.5992 107.285 27.5071 107.438 27.3545C107.591 27.2019 107.683 26.9992 107.697 26.7839C107.697 26.3689 107.697 25.954 107.282 25.954H96.0809C95.8653 25.9686 95.6624 26.0609 95.5098 26.2138C95.3572 26.3666 95.2652 26.5697 95.251 26.7852Z"
                fill="#102930"/>
            <path
                d="M81.3714 36.4164V39.9049H79.1452V36.4164H75.6748V34.2071H79.1452V30.7379H81.3714V34.2083H84.8418V36.4176L81.3714 36.4164Z"
                fill="#102930"/>
        </SvgIcon>
    )
}
