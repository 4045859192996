import React from "react";
import {observer} from "mobx-react";
import {RoutedPageComponent} from "../../basic-components/page/routed-page/routed-page.component";
import {StandardPageHeaderComponent} from "../../basic-components/page/standard-page-header/standard-page-header.component";
import {useServices} from "../../hooks/use-services.hook";
import {StandardPageContentComponent} from "../../basic-components/page/standard-page-content/standard-page-content.component";
import {StandardPageFooterComponent} from "../../basic-components/page/standard-page-footer/standard-page-footer.component";
import {HybridHomePageBottomNavbarComponent} from "../home/hybrid/footer/navbar/hybrid-home-page-bottom-navbar.component";
import {IMyAccountDashboardItem} from "./my-account-dashboard-item.interface";
import {MyProfileDashboardItem} from "./items/my-profile-dashboard.item";
import {MyDocumentsDashboardItem} from "./items/my-documents-dashboard.item";
import {BlueBenefitsDashboardItem} from "./items/blue-benefits.item";
import {MyCompanionsDashboardItem} from "./items/my-companions.item";
import {MyPreferencesDashboardItem} from "./items/my-preferences.item";
import styled from "styled-components";
import {DashboardRowComponent} from "./components/dashboard-row.component";

const DashboardItemsGridBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
    
`

export const MyAccountDashboardPage: React.FC = observer(() => {
    const services = useServices();

    const dashboardItems: IMyAccountDashboardItem[] = [
        new MyProfileDashboardItem(services),
        new MyDocumentsDashboardItem(services),
        new BlueBenefitsDashboardItem(services),
        new MyCompanionsDashboardItem(services),
        new MyPreferencesDashboardItem(services)
    ];

    const rows = dashboardItems.splitToChunks(2);


    return (
        <RoutedPageComponent>
            <StandardPageHeaderComponent renderTopMiddleSection={() => services.language.translate('My account')} hideLogo={true}/>
            <StandardPageContentComponent>
                <DashboardItemsGridBox>
                    {rows.map(row => <DashboardRowComponent items={row} key={row.map(item => item.text).join()}/>)}
                </DashboardItemsGridBox>
            </StandardPageContentComponent>
            <StandardPageFooterComponent>
                <HybridHomePageBottomNavbarComponent/>
            </StandardPageFooterComponent>
        </RoutedPageComponent>
    )
});
