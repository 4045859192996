import { observer } from "mobx-react";
import React, {useState} from "react";
import { StandardDialogHeaderComponent } from "../../../../components/modal/header/standard-dialog-header.component";
import { StandardDialogPageComponent } from "../../../../components/modal/page/standard-dialog-page.component";
import { useServices } from "../../../../hooks/use-services.hook";
import { IDialogConfirmationHandler } from "../../../../services/dialog/dialog.service.interface";
import {StandardDialogContentComponent} from "../../../../components/modal/content/standard-dialog-content.component";
import styled from "styled-components";
import {useScreenMediaQueriesChecks} from "../../../../hooks/use-screen-media-queries-checks.hook";
import {
    SeatSelectionPageContentLargeScreenComponent
} from "../large-screen/seat-selection-page-content.large-screen.component";
import {
    SeatSelectionPageContentSmallScreenComponent
} from "../small-screen/seat-selection-page-content.small-screen.component";
import {StandardDialogFooterComponent} from "../../../../components/modal/footer/standard-dialog-footer.component";
import {
    SeatMapsNavigationButtonsComponent
} from "../components/navigation-buttons/seat-maps-navigation-buttons.component";

const DialogContent = styled(StandardDialogContentComponent)`
    --padding-top: 0;
    --padding-start: ${props => props.theme.spacing.spacing8};
    --padding-end: ${props => props.theme.spacing.spacing8};
`


interface SeatSelectionDialogComponentProps{
    dialogHandler: IDialogConfirmationHandler;
}
export const SeatSelectionDialogComponent: React.FC<SeatSelectionDialogComponentProps> = observer((props) => {
    const services = useServices();
    const [scrollTop, setScrollTop] = useState(0);
    const screenMediaQuery = useScreenMediaQueriesChecks();

    const renderSeatMap = () => {
        if(screenMediaQuery.smallScreen) {
            return (<SeatSelectionPageContentSmallScreenComponent dialogHandler={props.dialogHandler}/>)
        } else {
            return (
                <SeatSelectionPageContentLargeScreenComponent dialogHandler={props.dialogHandler} scrollTop={scrollTop}/>
            )
        }
    }

    const renderFooter = () => {
        if(screenMediaQuery.smallScreen) {
            return (
                <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                    <SeatMapsNavigationButtonsComponent dialogHandler={props.dialogHandler} removePreviousButtonWhenDisabled={true}/>
                </StandardDialogFooterComponent>
            );
        }
        return null;
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate('Seat selection')}
                                           renderTitleOnTheFirstRow={screenMediaQuery.smallScreen}
                                           dialogHandler={props.dialogHandler}
                                           showBottomBorder={'never'}/>

            <DialogContent dialogHandler={props.dialogHandler} onScroll={event => setScrollTop(event.detail.scrollTop)}>
                {renderSeatMap()}
            </DialogContent>
            {renderFooter()}
        </StandardDialogPageComponent>
    );
});

