import React from 'react';
import {observer} from "mobx-react-lite";
import {useServices} from "../../hooks/use-services.hook";
import {SearchBookingBasePage} from "./search-booking-base.page";

export const CheckInSearchPage: React.FC = observer(() => {
    const services = useServices();

    return (
        <SearchBookingBasePage title={services.language.translate('Check-in')}
                               retrieveBookingButtonText={services.language.translate('Start Check-in')}
                               onRetrieveBookingButtonClick={async (searchParams) => {
                                   await searchParams.startCheckIn();
                               }}/>
    );

});
