import React from "react";
import {observer} from "mobx-react";
import {BookingFlowSmallScreenPage} from "../screen-small/booking-flow.small-screen.page";
import {useServices} from "../../../hooks/use-services.hook";
import {BlueBenefitsPackageToPurchaseComponent} from "./components/blue-benefits-package-to-purchase.component";
import {StandardPageContentComponent} from "../../../basic-components/page/standard-page-content/standard-page-content.component";
import styled from "styled-components";

const BlueBenefitsPurchasePageContentBox = styled(StandardPageContentComponent)`
    display: flex;
    flex-direction: column;
    align-content: space-evenly;
`

const BlueBenefitsDescriptionBox = styled.div`
    text-align: center;
    margin-bottom: 25px;
`;

const BlueBenefitsDescriptionTitleBox = styled.div`
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.bold};
    margin-top: 20px;
`;

const BlueBenefitsDescriptionInfoBox = styled.div`
    font-size: ${props => props.theme.fontSize.h5};
    
    color: ${props => props.theme.colors.medium};
    padding: 10px 15px 0;
`;

const BlueBenefitsPurchasePageContent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;
    return (
        <BlueBenefitsPurchasePageContentBox>
            <BlueBenefitsDescriptionBox>
                <BlueBenefitsDescriptionTitleBox>
                    {services.language.translate('Travel More with Blue Benefits')}
                </BlueBenefitsDescriptionTitleBox>
                <BlueBenefitsDescriptionInfoBox>
                    {services.language.translate('Your selection offers discounts for tickets and luggage for a year, for you and your family and friends.')}
                </BlueBenefitsDescriptionInfoBox>
            </BlueBenefitsDescriptionBox>
            {booking.blueBenefits.availablePackagesToPurchase.map(p => <BlueBenefitsPackageToPurchaseComponent key={p.blueBenefitsPackage.packageCode} packageToPurchase={p}/> )}
        </BlueBenefitsPurchasePageContentBox>
    )
});



export const BlueBenefitsPurchasePage: React.FC = observer(() => {
    return (
        <BookingFlowSmallScreenPage headerOptions={{
                                        title: "Blue Benefits"
                                    }}
                                    contentOptions={{
                                        renderContent: () => <BlueBenefitsPurchasePageContent/>
                                    }}
                                    shoppingCartOptions={{
                                        showPromotionCode: false
                                    }} />

    );
});
