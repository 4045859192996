import {IAssetResolver} from "../asset-resolver.interface";
import {DefaultTheme} from "styled-components";
import {AirlineThemeColors, IThemeBorders, IBoxShadows, IThemeSpacing} from "../interfaces/airline.theme";
import {GlobalStyles} from "../global-styles";
import {makeAssets} from "../models/make-assets";
import {ScreenMediaQueries} from "../../media-query/screen/screen-media-queries";
import {
    CSS_VAR_DIALOG_HORIZONTAL_PADDING,
    CSS_VAR_DIALOG_VERTICAL_PADDING,
    CSS_VAR_PAGE_CONTENT_HORIZONTAL_PADDING,
    CSS_VAR_PAGE_CONTENT_VERTICAL_PADDING,
    CSS_VAR_PAGE_FOOTER_HORIZONTAL_PADDING,
    CSS_VAR_PAGE_FOOTER_VERTICAL_PADDING,
    CSS_VAR_PAGE_HEADER_HORIZONTAL_PADDING,
    CSS_VAR_PAGE_HEADER_VERTICAL_PADDING
} from "../theme-css-variables";


const DARK ='var(--ion-color-dark)';
const DARK_CONTRAST ='var(--ion-color-dark-contrast)';

const Colors: AirlineThemeColors = {

    secondWebView: {
        toolbar: {
            backgroundColor: "#22AD5C",
            color: "#000000"
        }
    },

    /** primary **/
    primary:'var(--ion-color-primary)',
    primaryRgb:'var(--ion-color-primary-rgb)',
    primaryContrast:'var(--ion-color-primary-contrast)',
    primaryContrastRgb:'var(--ion-color-primary-contrast-rgb)',
    primaryShade:'var(--ion-color-primary-shade)',
    primaryTint:'var(--ion-color-primary-tint)',

    /** secondary **/
    secondary:'var(--ion-color-secondary)',
    secondaryRgb:'var(--ion-color-secondary-rgb)',
    secondaryContrast:'var(--ion-color-secondary-contrast)',
    secondaryContrastRgb:'var(--ion-color-secondary-contrast-rgb)',
    secondaryShade:'var(--ion-color-secondary-shade)',
    secondaryTint:'var(--ion-color-secondary-tint)',

    /** tertiary **/
    tertiary:'var(--ion-color-tertiary)',
    tertiaryRgb:'var(--ion-color-tertiary-rgb)',
    tertiaryContrast:'var(--ion-color-tertiary-contrast)',
    tertiaryContrastRgb:'var(--ion-color-tertiary-contrast-rgb)',
    tertiaryShade:'var(--ion-color-tertiary-shade)',
    tertiaryTint:'var(--ion-color-tertiary-tint)',
    tertiaryVeryLight: 'var(--ion-color-tertiary-very-light)',


/** success **/
    success:'var(--ion-color-success)',
    successRgb:'var(--ion-color-success-rgb)',
    successContrast:'var(--ion-color-success-contrast)',
    successContrastRgb:'var(--ion-color-success-contrast-rgb)',
    successShade:'var(--ion-color-success-shade)',
    successTint:'var(--ion-color-success-tint)',
    successVeryLight: "var(--ion-color-success-very-light)",

    /** warning **/
    warning:'var(--ion-color-warning)',
    warningRgb:'var(--ion-color-warning-rgb)',
    warningContrast:'var(--ion-color-warning-contrast)',
    warningContrastRgb:'var(--ion-color-warning-contrast-rgb)',
    warningShade:'var(--ion-color-warning-shade)',
    warningTint:'var(--ion-color-warning-tint)',

    notes: "var(--ion-color-notes)",
    notesRgb: "var(--ion-color-notes-rgb)",
    notesContrast: "var(--ion-color-notes-contrast)",
    notesShade: "var(--ion-color-notes-shade)",
    notesTint: "var(--ion-color-notes-tint)",
    /** danger **/
    danger:'var(--ion-color-danger)',
    dangerRgb:'var(--ion-color-danger-rgb)',
    dangerContrast:'var(--ion-color-danger-contrast)',
    dangerContrastRgb:'var(--ion-color-danger-contrast-rgb)',
    dangerShade:'var(--ion-color-danger-shade)',
    dangerTint:'var(--ion-color-danger-tint)',

    /** dark **/
    dark:'var(--ion-color-dark)',
    darkRgb:'var(--ion-color-dark-rgb)',
    darkContrast:'var(--ion-color-dark-contrast)',
    darkContrastRgb:'var(--ion-color-dark-contrast-rgb)',
    darkShade:'var(--ion-color-dark-shade)',
    darkTint:'var(--ion-color-dark-tint)',

    /** medium **/
    medium:'var(--ion-color-medium)',
    mediumRgb:'var(--ion-color-medium-rgb)',
    mediumContrast:'var(--ion-color-medium-contrast)',
    mediumContrastRgb:'var(--ion-color-medium-contrast-rgb)',
    mediumShade:'var(--ion-color-medium-shade)',
    mediumTint:'var(--ion-color-medium-tint)',

    /** light **/
    light:'var(--ion-color-light)',
    lightRgb:'var(--ion-color-light-rgb)',
    lightContrast:'var(--ion-color-light-contrast)',
    lightContrastRgb:'var(--ion-color-light-contrast-rgb)',
    lightShade:'var(--ion-color-light-shade)',
    lightTint:'var(--ion-color-light-tint)',

    cardLabelBackgroundWarring: "#FFF0B5",
    cardLabelColorWarning: 'var(--ion-color-dark-tint)',

    infoAlertBackground: "#E9F9FF",
    infoAlertTitleColor: "#0B76B7",
    infoAlertBorderColor: "#0B76B7",
    infoAlertContentColor: "#637381",

    warningAlertBackground: "#FFFBEB",
    warningAlertTitleColor: "#9D5425",
    warningAlertBorderColor: "#FBBF24",
    warningAlertContentColor: "#D0915C",

    errorAlertBackground: "#FEF3F3",
    errorAlertTitleColor: "#BC1C21",
    errorAlertBorderColor: "#F23030",
    errorAlertContentColor: "#F56060",

    successAlertBackground: "#DAF8E6",
    successAlertTitleColor: "#102930",
    successAlertBorderColor: "#22AD5C",
    successAlertContentColor: "#637381",


    pageHeaderBackground: DARK_CONTRAST,
    pageHeaderColor: DARK,

    pageContentBackground: 'var(--ion-color-light-tint)',
    pageContentColor: 'var(--ion-color-light-contrast)',

    pageFooterLargeScreenBackground: 'var(--ion-color-light-tint)',
    pageFooterLargeScreenColor: 'var(--ion-color-light-contrast)',
    pageFooterSmallScreenBackground: DARK_CONTRAST,
    pageFooterSmallScreenColor: DARK,

    dialogHeaderBackground: DARK_CONTRAST,
    dialogHeaderColor: DARK,

    dialogContentBackground: DARK_CONTRAST,
    dialogContentColor: DARK,

    dialogFooterBackground: DARK_CONTRAST,
    dialogFooterColor: DARK,

    cardBackground: DARK_CONTRAST,
    cardColor: DARK,

    inputFieldBackground: DARK_CONTRAST,
    inputFieldColor: DARK,
    buttonDisabledBackgroundColor: 'var(--ion-color-light-shade)',
    buttonDisabledTextColor: "#FFFFFF"

}

function makeSpacing(): IThemeSpacing {

    const basicSpacings = {
        spacing8: '0.5rem',
        spacing12: '0.75rem',
        spacing16: '1rem',
        spacing20: '1.25rem',
        spacing24: '1.5rem',
        spacing30: '1.875rem',
        spacing32: '2rem',
        spacing36: '2.25rem',
        spacing40: '2.5rem',
        spacing48: '3rem',
    };

    return {
        ...basicSpacings,
        flexGap8: basicSpacings.spacing8,
        flexGap12: basicSpacings.spacing12,
        flexGap16: basicSpacings.spacing16,
        flexGap20: basicSpacings.spacing20,
        flexGap24: basicSpacings.spacing24,
        flexGap30: basicSpacings.spacing30,
        flexGap40: basicSpacings.spacing40,


        pageHeaderVerticalPadding: `var(${CSS_VAR_PAGE_HEADER_VERTICAL_PADDING})`,
        pageHeaderHorizontalPadding: `var(${CSS_VAR_PAGE_HEADER_HORIZONTAL_PADDING})`,

        pageContentVerticalPadding: `var(${CSS_VAR_PAGE_CONTENT_VERTICAL_PADDING})`,
        pageContentHorizontalPadding: `var(${CSS_VAR_PAGE_CONTENT_HORIZONTAL_PADDING})`,

        pageFooterVerticalPadding: `var(${CSS_VAR_PAGE_FOOTER_VERTICAL_PADDING})`,
        pageFooterHorizontalPadding: `var(${CSS_VAR_PAGE_FOOTER_HORIZONTAL_PADDING})`,

        dialogVerticalPadding: `var(${CSS_VAR_DIALOG_VERTICAL_PADDING})`,
        dialogHorizontalPadding: `var(${CSS_VAR_DIALOG_HORIZONTAL_PADDING})`,
        cardDefaultPadding: '1.125rem',
    };
}

function makeThemeBorder(): IThemeBorders {
    const borderRadius = (multiplier: number = 1) => {
        return 8 * multiplier;
    }

    const borderRadiusPx = (multiplier: number = 1) => {
        return borderRadius(multiplier) + 'px';
    }

    return {
        radius: borderRadius,
        radiusPx: borderRadiusPx,
        defaultRadius: borderRadiusPx(1),
        mainColor: 'var(--ion-color-main-border)',
        focusedColor: 'var(--ion-color-medium)'
    };
}


function makeCardTheme(): IBoxShadows {
    return {
        shadow2: '0px 5px 12px 0px #0000001A',
        shadow3: '0px 4px 12px 0px #0D0A2C0F',
        shadow5: '0px 12px 34px 0px #0D0A2C14, 0px 34px 26px 0px #0D0A2C0D',
        shadow6: '0 8px 60px #0D0A2C0D, 0 -8px 60px #0D0A2C0D, 8px 0 60px #0D0A2C0D, -8px 0 60px #0D0A2C0D',
        dropShadow: '0px 5px 12px 0px #0000001A',
        footerSmallScreen: '0 -4px 12px 0 rgba(13,10,44,0.06)'
    }
}

export function makeTheme(assetsResolver: IAssetResolver): DefaultTheme {


    return {
        globalStyles: (<GlobalStyles/>),
        assets: makeAssets(assetsResolver),
        colors: Colors,
        fontWeight: {
            thin: '100',
            extraLight: '200',
            light: '300',
            regular: '400',
            medium: '500',
            semiBold: '600',
            bold: '700',
            extraBold: '800',
        },
        fontSize: {

            huge6: '2.375rem',//38
            huge5: '2.25rem', //36
            huge4: '2.125rem', //34
            huge3: '2rem',  //32
            huge2: '1.875rem', //30
            huge1: '1.75rem', //28

            h1: '1.625rem', //26
            h2: '1.5rem', //24
            h3: '1.375rem', //22
            h4: '1.25rem', //20
            h5: '1.125rem', //18
            h6: '1rem', //16
            body1: '1.125rem', //18
            body2: '0.875rem', //14
            subtitle1: '1rem', //16
            subtitle2: '0.875rem', //14
            button: '0.875rem', //14
            caption: '0.75rem', //12
            footNote: '0.75rem',
            smallText: '0.625rem', //10
            overline: '0.625rem', //10
            breadcrumbResting: '1rem', //16
            breadcrumbActive: '1rem', //16
            warningAlertSmall: '0.75rem',
            warningAlertMedium: '0.875rem',
            warningAlertLarge: '1.125rem'
        },
        spacing: makeSpacing(),
        border: makeThemeBorder(),
        boxShadow: makeCardTheme(),
        containerMediaQuery: ScreenMediaQueries,
        screenMediaQuery: ScreenMediaQueries
    }


}
