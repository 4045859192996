import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../../../../../hooks/use-services.hook";

const TextBox = styled.div`
    color: ${props => props.theme.colors.success};
    font-size: ${props => props.theme.fontSize.huge3};
    font-weight: ${props => props.theme.fontWeight.bold};
    
    
`

export const BookTabGreetingMessageComponent: React.FC = observer(() => {
    const services = useServices();
    return (
        <TextBox>
            {services.language.translate('Welcome, your app experience is ready.')}
        </TextBox>
    )
})