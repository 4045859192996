import React from 'react';
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";

export const PlaneDepartingLineFilledIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.2} {...props} viewBox="0 0 22 17.778" ratio={22/17.778}>
            <g fill={props.color? props.color: "currentColor"} transform="translate(0 0)"><path d="M0,1H22V0H0Z" transform="translate(0.001 16.778)" /><path d="M1.871,10.256S5.9,11.879,6.94,11.567L18.993,7.919a2.3,2.3,0,0,0,1.558-2.282,1.936,1.936,0,0,0-2.356-1.1l-5,1.5L4.887.045a.317.317,0,0,0-.376.037l-.8.889a.331.331,0,0,0-.037.394L7.884,7.644,4.511,8.661.955,6.177a.268.268,0,0,0-.348.046L.084,6.8a.364.364,0,0,0-.046.394A22.535,22.535,0,0,0,1.871,10.256Z" /></g>
        </SvgIcon>
    )
}
