import React, {CSSProperties, PropsWithChildren} from "react";
import {IPassengerSegmentBoardingPassViewModel} from "../../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {BoardingPassFieldLabelComponent} from "../label/boarding-pass-field-label.component";
import {BoardingPassFieldValueComponent} from "../value/boarding-pass-field-value.component";
import {PlaneGoIcon} from "../../../../assets/svg/plane-go.icon";


const BoardingPassDesignatorBox: React.FC<PropsWithChildren> = (props) => {
    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    };

    return (
        <div style={styles}>
            {props.children}
        </div>
    );
}

const BoardingPassCarrierBox: React.FC<PropsWithChildren> = (props) => {
    const services = useServices();
    const styles: CSSProperties = {
        fontSize: services.theme.currentTheme.fontSize.body2,
        color: services.theme.currentTheme.colors.mediumShade,
    };

    return (
        <div style={styles}>
            {props.children}
        </div>
    );
}


const StationsAndFlightNumberBox: React.FC<PropsWithChildren> = (props) => {
    const services = useServices();
    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        lineHeight: '1',
        width: '100%',
        marginBottom: services.theme.currentTheme.spacing.spacing8
    };

    return (
        <div style={styles}>
            {props.children}
        </div>
    );
}

const StationCodeBox: React.FC<{stationCode: string}> = (props) => {
    const services = useServices();
    const theme = services.theme.currentTheme;
    const styles: CSSProperties = {
        fontSize: theme.fontSize.huge5,
        fontWeight: theme.fontWeight.bold
    };

    return (
        <BoardingPassFieldValueComponent styles={styles}>
            {props.stationCode}
        </BoardingPassFieldValueComponent>
    );
}

interface SectionBoxProps extends PropsWithChildren {
    styles: CSSProperties;
}
const SectionBox: React.FC<SectionBoxProps> = (props) => {
    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...props.styles
    };

    return (
        <div style={styles}>
            {props.children}
        </div>
    )
}

interface StationSectionBoxProps extends PropsWithChildren {
    stationCode: string;
    stationName: string;
    styles?: CSSProperties;
}

const StationSectionBox: React.FC<StationSectionBoxProps> = (props) => {
    const services = useServices();
    const theme = services.theme.currentTheme;
    const styles: CSSProperties = {
        ...props.styles
    }

    const stationNameStyles: CSSProperties = {
        fontSize: theme.fontSize.subtitle2,
        fontWeight: theme.fontWeight.medium,
        color: 'inherit'
    }

    return (
        <SectionBox styles={styles}>
            <StationCodeBox stationCode={props.stationCode}/>
            <BoardingPassFieldLabelComponent styles={stationNameStyles}>
                {props.stationName}
            </BoardingPassFieldLabelComponent>
        </SectionBox>
    );
}

const DepartureSectionBox: React.FC<Omit<StationSectionBoxProps, 'styles'>> = (props) => {
    return (
        <StationSectionBox {...props}>
            {props.children}
        </StationSectionBox>
    )
}

const ReturnStationSectionBox: React.FC<Omit<StationSectionBoxProps, 'styles'>> = (props) => {

    return (
        <StationSectionBox {...props}>
            {props.children}
        </StationSectionBox>
    )
}


const FlightNumberSectionBox: React.FC<{flightNumber: string}> = observer((props) => {
    const services = useServices();
    const styles: CSSProperties = {
        alignItems: 'center',
        flexGrow: 1,
        color: services.theme.currentTheme.colors.darkTint,
        fontSize: services.theme.currentTheme.fontSize.body1,
    };

    return (
        <SectionBox styles={styles}>
            <PlaneGoIcon size={1.8}/>
            <div>{props.flightNumber}</div>
        </SectionBox>
    )
});


interface BoardingPassDesignatorComponentProps {
    boardingPass: IPassengerSegmentBoardingPassViewModel
}
export const BoardingPassDesignatorComponent: React.FC<BoardingPassDesignatorComponentProps> = (props) => {
    const services = useServices();
    const designator = props.boardingPass.designator;


    const renderCarrier = () => {
        if(props.boardingPass.designator.carrier) {
            return (
                <BoardingPassCarrierBox>
                    {services.language.translationFor('Operated by {carrier}').withParams({carrier: props.boardingPass.designator.carrier})}
                </BoardingPassCarrierBox>
            );
        }
        return null;
    }
    return (
        <BoardingPassDesignatorBox>
            <StationsAndFlightNumberBox>
                <DepartureSectionBox stationCode={designator.origin.code} stationName={designator.origin.name}/>
                <FlightNumberSectionBox flightNumber={props.boardingPass.fullFlightIdentifier}/>
                <ReturnStationSectionBox stationCode={designator.destination.code} stationName={designator.destination.name}/>
            </StationsAndFlightNumberBox>
            {renderCarrier()}
        </BoardingPassDesignatorBox>

    );
}
