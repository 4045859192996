import {IPdfExporterStrategy} from "./pdf-exporter.strategy.interface";
import {IPrintPdfOptions, ISharePdfOptions} from "../pdf-exporter.service.interface";
import {IServiceFactory} from "../../service-factory.interface";
import {Directory, Filesystem} from "@capacitor/filesystem";
import {FileOpener} from "@capacitor-community/file-opener";
import {Share} from '@capacitor/share';

const html2pdf: any = require('html2pdf.js');

interface ISavePdfOptions {
    element: Element;
    fileName: string;
    directory: Directory;
}

export class PdfExporterHybridStrategy implements IPdfExporterStrategy {
    constructor(private readonly services: IServiceFactory) {
    }

    async canShare(): Promise<boolean> {
        const result = await Share.canShare();

        return result.value;
    }

    async printPdf(options: IPrintPdfOptions): Promise<void> {
        try {

            const filePath = await this._savePdf({
                element: options.element,
                fileName: options.fileName,
                directory: Directory.Documents
            })

            await FileOpener.open({
                filePath: filePath
            });

        } catch (err) {
            this.services.logger.error('Failed to print pdf', err);
        }

    }

    async sharePdf(options: ISharePdfOptions): Promise<void> {

        try {
            const filePath = await this._savePdf({
                element: options.element,
                fileName: options.fileName,
                directory: Directory.Cache
            })

            await Share.share({
                title: options.subject,
                text: options.message,
                url: filePath,
                dialogTitle: 'Share PDF...'
            });

        } catch (err) {
            this.services.logger.error('Failed to share boarding pass', err);
        }
    }

    private async _savePdf(options: ISavePdfOptions): Promise<string> {
        const pdfBlob = await this._createPdfBlob(options.element);
        const pdfContent = await this._blobToBase64(pdfBlob);
        const fileName = this._appendFileExtension(options.fileName);

        await Filesystem.writeFile({
            path: fileName,
            data: pdfContent,
            directory: options.directory,
            recursive: true
        });

        const filePath = await Filesystem.getUri({
            path: fileName,
            directory: options.directory
        })

        return filePath.uri;
    }

    private _appendFileExtension(fileName: string) {
        if(fileName.toLowerCase().endsWith('.pdf')) {
            return fileName;
        }

        return fileName + '.pdf';
    }
    private _createPdfBlob(element: Element): Promise<Blob> {
        return html2pdf()
            .set({
                margin: 5,
                jsPDF: {format: 'a4'}
            })
            .from(element)
            .toPdf()
            .get('pdf')
            .then((pdf: any) => {
                return pdf.output('blob');
            })
    }

    private _blobToBase64(blob: Blob): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                if(reader.result) {
                    resolve((reader.result as string).split(',')[1]) // Get only the base64 part
                } else {
                    reject('Could not convert PDF blob to base64');
                }

            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }


}
