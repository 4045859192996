import React from "react";
import {useServices} from "../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import styled from "styled-components";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";
import {StandardDialogPageComponent} from "../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../components/modal/header/standard-dialog-header.component";
import {
    StandardDialogContentComponent
} from "../../../components/modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../../../components/modal/footer/standard-dialog-footer.component";
import {ContinueButtonComponent} from "../../../components/continue-button/continue-button.component";
import {StarsIcon} from "../../../assets/svg/stars.icon";
import {VerticalSpacer} from "../../../basic-components/spacing/vertical-spacer";

const PaymentSuccessfulContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    flex-grow: 1;
    width: 100%;
    
`

const PaymentCompleteTextBox = styled.div`
    font-size: ${props => props.theme.fontSize.h2};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`;


const YourAllSetTextBox = styled.div`
    font-size: ${props => props.theme.fontSize.huge5};
    font-weight: ${props => props.theme.fontWeight.bold};
`;

const LookingForwardBox = styled.div`
    color: ${props => props.theme.colors.medium};
`

interface PaymentSuccessfulDialogProps {
    dialogHandler: IDialogConfirmationHandler;
    customMessage?: string;
}

export const PaymentSuccessfulDialog: React.FC<PaymentSuccessfulDialogProps> = observer((props) => {
    const services = useServices();

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler}/>

            <StandardDialogContentComponent dialogHandler={props.dialogHandler} fullContentHeight={true}>
                <PaymentSuccessfulContent>
                    <StarsIcon size={7}/>
                    <VerticalSpacer/>
                    <PaymentCompleteTextBox>
                        {props.customMessage || services.language.translate('Payment complete')}
                    </PaymentCompleteTextBox>
                    <YourAllSetTextBox>
                        {services.language.translate(`You're all set!`)}
                    </YourAllSetTextBox>
                </PaymentSuccessfulContent>
            </StandardDialogContentComponent>

            <StandardDialogFooterComponent dialogHandler={props.dialogHandler} horizontallyCentered={true}>
                <LookingForwardBox>
                    {services.language.translate(`We're looking forward to having you on board.`)}
                </LookingForwardBox>
                <ContinueButtonComponent onClick={() => props.dialogHandler.accept()} fullWidth={true}/>
            </StandardDialogFooterComponent>

        </StandardDialogPageComponent>
    )
});
