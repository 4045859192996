import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {FlightSelectionPageContentComponent} from "../flight-selection-page-content.component";
import {ReturnFlightBundlesComponent} from "./return-flight-bundles.component";
import {ReturnFlightSelectionAvailabilityComponent} from "./return-flight-selection-availability.component";

export interface ReturnFlightSelectionPageContentProps {
    allowBundleSelection: boolean;
}

export const ReturnFlightSelectionPageContent: React.FC<ReturnFlightSelectionPageContentProps> = observer((props) => {
    const services = useServices();
    const availability = services.booking.availability;

    const renderAvailability = () => {
        return (<ReturnFlightSelectionAvailabilityComponent/>)
    }

    const getBundleRenderer = () => {
        if(!props.allowBundleSelection) {
            return undefined;
        }

        return () => {
            return (
                <ReturnFlightBundlesComponent/>
            );
        }
    }

    return (
        <FlightSelectionPageContentComponent bundlesSectionTitle={services.language.translate('Your bundle')}
                                             renderAvailability={renderAvailability}
                                             journey={services.booking.current.returnJourney}
                                             pricingInformationMessage={availability.pricingInformationMessage}
                                             trips={availability.returnTrips}
                                             renderBundles={getBundleRenderer()}/>
    );
});

