import React from "react";
import {useServices} from "../../../hooks/use-services.hook";
import {PrintTicketButtonComponent} from "../../../components/print-ticket/print-ticket-button.component";
import {observer} from "mobx-react";

export const ManageMyBookingDashboardHeaderTopMiddleSectionComponent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;
    /*
    const renderShowTicketButton = () => {
        if(services.configuration.envConfigs.currentEnvironmentType === ApplicationEnvironmentTypeEnum.Development) {
            return (<ShowTicketDialogButtonComponent booking={booking}/>);
        }
        return null;
    }
    */

    return (
        <PrintTicketButtonComponent booking={booking} hideLabel={false}/>
    )
})
