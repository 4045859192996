import React, {useState} from "react";
import {observer} from "mobx-react";
import {IPassengerViewModel} from "../../../../../services/booking/models/passenger/passenger-view-model.interface";
import {useServices} from "../../../../../hooks/use-services.hook";
import {DialogCloseButtonBehavior} from "../../../../../services/dialog/dialog-enums";
import {ManageMyBookingPassengerDialogComponent} from "./manage-my-booking-passenger-dialog.component";
import {PersonCircleFilledIcon} from "../../../../../assets/svg/person-circle-filled.icon";
import styled from "styled-components";
import {CardBox} from "../../../../../basic-components/card/card.box";
import {ManageMyBookingEditSectionButtonComponent} from "../edit-button/manage-my-booking-edit-section-button.component";
import {NeutralButton} from "../../../../../basic-components/buttons/neutral-button";
import {WheelchairIcon} from "../../../../../assets/svg/wheelchair.icon";
import {
    IMaturePassengerViewModel
} from "../../../../../services/booking/models/passenger/mature-passenger-view-model.interface";
import {ChildHandsUpCircleFilledIcon} from "../../../../../assets/svg/child-hands-up-circle-filled.icon";
import {InfantCircleFilledIcon} from "../../../../../assets/svg/infant-circle-filled.icon";


const ContainerBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    box-shadow: none;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap16};
`

const HeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`

const BodyBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap30};
    
`

const IconBox = styled.div`
    background-color: white;
`


const PassengerDetailsBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap16};
`

const PassengerNameBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${props => props.theme.fontSize.body1};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

const RequestSpecialAssistanceButton = styled(NeutralButton)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: ${props => props.theme.spacing.flexGap8};
    font-size: ${props => props.theme.fontSize.body2};
    font-weight: ${props => props.theme.fontWeight.medium};
`

const SpecialAssistanceButtonTextBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: ${props => props.theme.spacing.flexGap8};
    flex-grow: 1;
`

const SpecialAssistanceCountBox = styled.div`
    background-color: ${props => props.theme.colors.tertiaryTint};
    color: ${props => props.theme.colors.tertiaryContrast};
    padding: ${props => props.theme.spacing.spacing8} ${props => props.theme.spacing.spacing16};
    border-radius: 20px;
`


export const ManageMyBookingPassengerComponent: React.FC<{passenger: IPassengerViewModel}> = observer((props) => {
    const services = useServices();
    const booking = services.booking.current;
    const [aggregator] = useState(booking.createSsrsAggregator({
        ssrTypes: services.ssrTypes.getSpecialAssistanceSsrTypes(),
        onlyForPassengerKeys: [props.passenger.passengerKey]
    }));
    const onEditButtonClickHandler = async () => {
        const blockingReason = props.passenger.nameChangeBlockingReason;
        if(blockingReason) {
            services.alert.showError(blockingReason);
            return;
        }

        await services.dialog.showPopupDialog({
            closeButtonBehavior: DialogCloseButtonBehavior.Reject,
            width: '420px',
            render: (dialogHandler) => <ManageMyBookingPassengerDialogComponent dialogHandler={dialogHandler} passenger={props.passenger} />,
            onReject: async () => {
                props.passenger.cancelChanges();
            }
        });
    }

    const requestSpecialAssistanceText = services.language.translate('Request Special Assistance')

    const onRequestSpecialAssistanceClick = async () => {
        await services.dialogFactory.showSsrsSelector({
            closeButtonBehavior: DialogCloseButtonBehavior.Accept,
            renderTitle: () => requestSpecialAssistanceText,
            renderSubtitle: () => null,
            ssrsAggregator: aggregator,
            passengers: [props.passenger as IMaturePassengerViewModel]
        });
    }

    const renderPersonIcon = () => {
        if(props.passenger.passengerType.isChild) {
            return (
                <ChildHandsUpCircleFilledIcon size={4}/>
            );
        } else if(props.passenger.passengerType.isInfant) {
            return (
                <InfantCircleFilledIcon size={4}/>
            )
        } else {
            return (<PersonCircleFilledIcon size={4}/>);
        }
    }

    const renderSpecialAssistanceCount = () => {
        if(aggregator.totalSsrsQuantity === 0) {
            return null;
        }
        return (
            <SpecialAssistanceCountBox>
                {aggregator.totalSsrsQuantity}
            </SpecialAssistanceCountBox>
        )
    }

    const renderSpecialAssistanceButton = () => {
        if(!props.passenger.canHaveSpecialAssistance) {
            return null;
        }

        return (
            <RequestSpecialAssistanceButton onClick={onRequestSpecialAssistanceClick} fullWidth={true}>
                <SpecialAssistanceButtonTextBox>
                    <WheelchairIcon size={1.4}/>
                    <span>
                        {requestSpecialAssistanceText}
                    </span>
                </SpecialAssistanceButtonTextBox>
                {renderSpecialAssistanceCount()}
            </RequestSpecialAssistanceButton>
        );
    }

    return (
        <ContainerBox>
            <HeaderBox>
                <ManageMyBookingEditSectionButtonComponent onClick={onEditButtonClickHandler} label={services.language.translate('Modify')}/>
            </HeaderBox>
            <BodyBox>
                <IconBox>
                    {renderPersonIcon()}
                </IconBox>
                <PassengerDetailsBox>
                    <PassengerNameBox>
                        {props.passenger.getFullName()}
                    </PassengerNameBox>
                    {renderSpecialAssistanceButton()}
                </PassengerDetailsBox>

            </BodyBox>

        </ContainerBox>
    )
})