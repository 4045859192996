import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {ReturnFlightSelectionPageContent} from "./return-flight-selection-page-content.component";
import {BookingFlowPage} from "../../booking-flow.page";

export const ReturnFlightSelectionPage: React.FC = observer(() => {
    const services = useServices();



    return (
        <BookingFlowPage headerOptions={{
                            title: services.language.translate('Return Flight')
                         }}
                         contentOptions={{
                             renderContent: () => (<ReturnFlightSelectionPageContent allowBundleSelection={true}/>)
                         }}
                         shoppingCartOptions={{
                             showPromotionCode: false
                         }}/>
    );
});
