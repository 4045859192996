import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import {useServices} from "../../hooks/use-services.hook";
import {IFeeViewModel} from "../../services/booking/models/base-models/fees/fee-view-model.interface";
import {NullablePrice, Price} from "../../services/currency/price";
import styled, {css} from "styled-components";
import {AdvancedJourneyDesignatorComponent} from "../advanced-journey-designator/advanced-journey-designator.component";
import {IJourneyShoppingCartViewModel} from "../../services/booking/models/shopping-cart/journey/journey-shopping-cart-view-model.interface";
import {ShoppingCartModeToggleComponent} from "./shopping-cart-mode-toggle.component";
import {ShoppingCartPassengerTypeFareDetailsButtonComponent} from "./price-details/passenger-type-fare/shopping-cart-passenger-type-fare-details-button.component";
import {ShoppingCartFeeDetailsButtonComponent} from "./price-details/fee-details/shopping-cart-fee-details-button.component";
import {PriceComponent} from "../price/price.component";
import {CardBox} from "../../basic-components/card/card.box";


const ShoppingCartContainerBox = styled.div<{hideBorders?: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${props => props.theme.spacing.spacing24};
    width: 100%;
    ${
    props => props.hideBorders
        ? css`
                    ${ShoppingCartSectionBox} {
                      border: none;
                    }
                `
        : ''
}
`

const SectionHeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    color: ${props => props.theme.colors.lightContrast};
    background-color: ${props => props.theme.colors.light};
    padding: ${props => props.theme.spacing.spacing16};
    border-top-left-radius: calc(${props => props.theme.border.defaultRadius} - 2px);
    border-top-right-radius: calc(${props => props.theme.border.defaultRadius} - 2px);
    overflow: hidden;
`;



const SummaryGridBox = styled.div<{$hasPriceDiffButton: boolean}>`
    display: grid;
    
    row-gap: ${props => props.theme.spacing.spacing8};
    font-size: ${props => props.theme.fontSize.subtitle2};
    width: 100%;
    ${props => props.$hasPriceDiffButton 
            ? css`
                grid-template-columns: 2.3fr 1fr 20px  1fr;
            `
            :css`
                grid-template-columns: 2.5fr 1fr 1fr;
            `
    }
`;

const RowSeparatorBox = styled.hr`
    width: 100%;
    background-color: ${props => props.theme.border.mainColor};
    grid-column: 1 / -1;
    &:last-of-type {
        display: none;
    }
`

const JourneySellsBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.spacing16};

    border-bottom: 1px solid ${props => props.theme.border.mainColor};
    padding-bottom: ${props => props.theme.spacing.spacing16};
    &:last-of-type {
        border-bottom: none;
        padding-bottom: unset;
    }
`

const SummaryDescriptionColBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 2px;
    font-size: ${props => props.theme.fontSize.caption};
    font-weight: ${props => props.theme.fontWeight.regular};
`;

const PassengerTypeFareDescriptionBox = styled(SummaryDescriptionColBox)`
    font-size: ${props => props.theme.fontSize.subtitle2};
    font-weight: ${props => props.theme.fontWeight.medium};
`


const SummaryDetailsColBox = styled.div`;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: end;
    color: ${props => props.theme.colors.notesTint};
    font-weight: ${props => props.theme.fontWeight.medium};
`;

const FeeDescriptionBox = styled.div`
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

const FeeDetailsBox = styled.div`
    font-weight: ${props => props.theme.fontWeight.regular};
`

const FeeRowComponent: React.FC<{fee: IFeeViewModel}> = observer((props) => {
    const services = useServices();
    if(!props.fee.shouldBeDisplayedInShoppingCart) {
        return null;
    }

    const renderFeeDetailsButton = () => {
        if(!services.booking.current.shoppingCart.shouldShowPriceDifferences) {
            return null;
        }

        return (
            <SummaryDetailsColBox>
                <ShoppingCartFeeDetailsButtonComponent description={props.fee.description}
                                                       initialPrice={props.fee.initialPrice}
                                                       currentPrice={props.fee.currentPrice}/>
            </SummaryDetailsColBox>
        );
    }

    return (
        <>
            <SummaryDescriptionColBox>
                <FeeDescriptionBox>{props.fee.description}</FeeDescriptionBox>
                <FeeDetailsBox>{props.fee.details}</FeeDetailsBox>
            </SummaryDescriptionColBox>

            <SummaryDetailsColBox>
                {`${props.fee.quantity} x ${props.fee.priceToDisplay.toString()}`}
            </SummaryDetailsColBox>

            {renderFeeDetailsButton()}

            <SummaryDetailsColBox>
                {props.fee.totalToDisplay.toString()}
            </SummaryDetailsColBox>

            <RowSeparatorBox/>

        </>
    )
})


const FeesCollectionComponent: React.FC<{fees: IFeeViewModel[]}> = observer((props) => {
    return (
        <>
            {props.fees.map(fee => <FeeRowComponent key={fee.feeGroupKey} fee={fee}/>)}
        </>
    )
});

interface PassengerTypeFareComponentProps {
    description: string;
    quantity: number;
    initialBundlePrice?: NullablePrice;
    currentBundlePrice?: Price;
    initialFare: NullablePrice;
    currentFare: Price;
    fareToDisplay: Price;
    totalToDisplay: Price;
}


const PassengerTypeFareComponent: React.FC<PassengerTypeFareComponentProps> = observer((props) => {
    const services = useServices();

    const renderFareDetailsButton = () => {
        if(!services.booking.current.shoppingCart.shouldShowPriceDifferences) {
            return null;
        }
        return (
            <SummaryDetailsColBox>
                <ShoppingCartPassengerTypeFareDetailsButtonComponent description={props.description}
                                                                     initialBundlePrice={props.initialBundlePrice}
                                                                     currentBundlePrice={props.currentBundlePrice}
                                                                     initialFare={props.initialFare}
                                                                     currentFare={props.currentFare}/>
            </SummaryDetailsColBox>
        )
    }
    return (
        <>
            <PassengerTypeFareDescriptionBox>
                {props.description}
            </PassengerTypeFareDescriptionBox>
            <SummaryDetailsColBox>
                {`${props.quantity} x ${props.fareToDisplay.toString()}`}
            </SummaryDetailsColBox>
            {renderFareDetailsButton()}
            <SummaryDetailsColBox>
                {props.totalToDisplay.toString()}
            </SummaryDetailsColBox>
            <RowSeparatorBox/>
        </>

    )
});

const JourneyInfantShoppingCartComponent: React.FC<{journeyShoppingCart: IJourneyShoppingCartViewModel}> = observer((props) => {
    const infantShoppingCart = props.journeyShoppingCart.journeyInfantsShoppingCartModel;
    if(!infantShoppingCart) {
        return null;
    }
    return (
        <PassengerTypeFareComponent description={infantShoppingCart.description}
                                    quantity={infantShoppingCart.fareQuantity}
                                    initialFare={infantShoppingCart.initialFare}
                                    currentFare={infantShoppingCart.currentFare}
                                    fareToDisplay={infantShoppingCart.fareToDisplay}
                                    totalToDisplay={infantShoppingCart.totalFareToDisplay}/>
    );
});


const JourneyFaresComponent: React.FC<{journeyShoppingCart: IJourneyShoppingCartViewModel}> = observer((props) => {
    const services = useServices();

    const passengerTypesShoppingCarts = props.journeyShoppingCart.passengerTypesShoppingCarts.filter(sc => sc.fareQuantity > 0);

    if(passengerTypesShoppingCarts.length === 0) {
        return null;
    }

    return (
        <JourneySellsBox>
            <AdvancedJourneyDesignatorComponent journeyDesignator={props.journeyShoppingCart.designator} isShownInShoppingCart={true}/>
            <RowSeparatorBox/>
            <SummaryGridBox $hasPriceDiffButton={services.booking.current.shoppingCart.shouldShowPriceDifferences}>
                {
                    passengerTypesShoppingCarts.map(sc => <PassengerTypeFareComponent key={sc.uniqueKey}
                                                                                      description={sc.description}
                                                                                      quantity={sc.fareQuantity}
                                                                                      initialBundlePrice={sc.initialBundlePrice}
                                                                                      currentBundlePrice={sc.currentBundlePrice}
                                                                                      initialFare={sc.initialFare}
                                                                                      currentFare={sc.currentFare}
                                                                                      fareToDisplay={sc.fareToDisplay}
                                                                                      totalToDisplay={sc.totalFareToDisplay}/>)
                }
                <JourneyInfantShoppingCartComponent journeyShoppingCart={props.journeyShoppingCart}/>
            </SummaryGridBox>

            {/*<GrandTotalComponent description={services.language.translate('Flight total')} total={props.journeyShoppingCart.faresTotalToDisplay}/>*/}

        </JourneySellsBox>
    )
});



const JourneyExtrasComponent: React.FC<{journeyShoppingCart: IJourneyShoppingCartViewModel}> = observer((props) => {
    const services = useServices();
    const shoppingCart = props.journeyShoppingCart;
    const allFees = [
        ...shoppingCart.seatsFees,
        ...shoppingCart.ssrsFees,
        ...shoppingCart.otherFees
    ]

    return (
        <JourneySellsBox>

            <AdvancedJourneyDesignatorComponent journeyDesignator={props.journeyShoppingCart.designator} isShownInShoppingCart={true}/>

            <RowSeparatorBox/>

            <SummaryGridBox $hasPriceDiffButton={services.booking.current.shoppingCart.shouldShowPriceDifferences}>
                <FeesCollectionComponent fees={allFees}/>
            </SummaryGridBox>

            {/*<GrandTotalComponent description={services.language.translate('Extras total')} total={shoppingCart.extrasTotalToDisplay}/>*/}

        </JourneySellsBox>
    )
})



const BookingLevelFeesComponent: React.FC<{bookingLevelFees: IFeeViewModel[]; showSectionsBoxShadow?: boolean}> = observer((props) => {
    const services = useServices();
    const feesToDisplay = props.bookingLevelFees.filter(f => f.shouldBeDisplayedInShoppingCart);
    if(feesToDisplay.length === 0) {
        return null;
    }

    const total = Price.sumAll(feesToDisplay.map(f => f.totalToDisplay), services.booking.current.createPrice(0));

    return (
        <ShoppingCartSectionComponent title={services.language.translate('Other services')} total={total} showSectionsBoxShadow={props.showSectionsBoxShadow}>
            <SummaryGridBox $hasPriceDiffButton={services.booking.current.shoppingCart.shouldShowPriceDifferences}>
                <FeesCollectionComponent fees={feesToDisplay}/>
            </SummaryGridBox>

        </ShoppingCartSectionComponent>
    )
});

const ShoppingCartSectionBox = styled(CardBox)<{$showSectionsBoxShadow?: boolean}>`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.spacing8};
    padding: 0;
    width: 100%;
    ${props => !props.$showSectionsBoxShadow && css`box-shadow: none`}
`


const ShoppingCartSectionBodyBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.spacing16};
    padding: 0 ${props => props.theme.spacing.spacing16} ${props => props.theme.spacing.spacing16} ${props => props.theme.spacing.spacing16};
    width: 100%;
`

const SectionTitleBox = styled.div`
    flex-grow: 1;
`

interface ShoppingCartSectionComponentProps extends PropsWithChildren {
    title: string;
    total?: Price;
    showSectionsBoxShadow?: boolean;
    className?: string;
}

const ShoppingCartSectionComponent: React.FC<ShoppingCartSectionComponentProps> = observer((props) => {

    const renderTotal = () => {
        if(props.total) {
            return (<PriceComponent price={props.total} />)
        }
        return null;
    }
    return (
        <ShoppingCartSectionBox $showSectionsBoxShadow={props.showSectionsBoxShadow} className={props.className}>
            <SectionHeaderBox>
                <SectionTitleBox>
                    {props.title}
                </SectionTitleBox>
                {renderTotal()}
            </SectionHeaderBox>
            <ShoppingCartSectionBodyBox>
                {props.children}
            </ShoppingCartSectionBodyBox>
        </ShoppingCartSectionBox>
    );
});

const ShoppingCartFaresSectionComponent = styled(ShoppingCartSectionComponent)`
    &  ${SectionHeaderBox} {
        background-color: ${props => props.theme.colors.lightShade};
    }
`

const NoPurchasesOnCurrentSessionBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: ${props => props.theme.colors.notesTint};
    font-size: ${props => props.theme.fontSize.body2};
    height: 100%;
    
`

interface ShoppingCartComponentProps {
    hideBorders?: boolean;
    showSectionsBoxShadow?: boolean;
}

export const ShoppingCartComponent: React.FC<ShoppingCartComponentProps> = observer((props) => {
    const services = useServices();
    const booking = services.booking.current;

    const journeysShoppingCarts = booking.shoppingCart.journeysShoppingCartsToDisplay;

    const renderFlightsFares = () => {
        const journeysWithFareChanges = journeysShoppingCarts.filter(j => j.shouldDisplayFares);
        if(journeysWithFareChanges.length === 0) {
            return null;
        }

        const total = Price.sumAll(journeysWithFareChanges.map(j => j.faresTotalToDisplay), booking.createPrice(0));
        return (
            <ShoppingCartFaresSectionComponent title={services.language.translate('Flights')} total={total} showSectionsBoxShadow={props.showSectionsBoxShadow}>
                {journeysWithFareChanges.map(j => <JourneyFaresComponent key={`Flights_${j.designator.uniqueKey}`} journeyShoppingCart={j}/>)}
            </ShoppingCartFaresSectionComponent>
        )


    }

    const renderExtras = () => {
        const journeysWithExtrasChanges = journeysShoppingCarts.filter(j => j.shouldDisplayExtras);
        if(journeysWithExtrasChanges.length > 0 && booking.departureJourney?.selectedBundle) {
            const total = Price.sumAll(journeysWithExtrasChanges.map(j => j.extrasTotalToDisplay), booking.createPrice(0));
            return (
                <ShoppingCartSectionComponent title={services.language.translate('Extras')} total={total} showSectionsBoxShadow={props.showSectionsBoxShadow}>
                    {journeysWithExtrasChanges.map(jsc => <JourneyExtrasComponent key={`Extras_${jsc.designator.uniqueKey}`} journeyShoppingCart={jsc}/>)}
                </ShoppingCartSectionComponent>
            );
        }

        return null;
    }

    const renderAllServices = () => {
        const fares = renderFlightsFares();
        const extras = renderExtras();

        if(!fares && !extras) {
            return (
                <NoPurchasesOnCurrentSessionBox>
                    {services.language.translate('No purchases on current session')}
                </NoPurchasesOnCurrentSessionBox>
            );
        }

        return (
            <>
                {fares}
                {extras}
            </>

        )

    }


    return (
        <ShoppingCartContainerBox hideBorders={props.hideBorders}>
            <ShoppingCartModeToggleComponent shoppingCart={booking.shoppingCart}/>
            {renderAllServices()}
            <BookingLevelFeesComponent bookingLevelFees={booking.shoppingCart.bookingLevelFeesToDisplay} showSectionsBoxShadow={props.showSectionsBoxShadow}/>
            {/*
            <ShoppingCartSectionComponent title={services.language.translate('Final Price')}>
                <GrandTotalComponent description={services.language.translate('Journey total')}
                                     total={booking.shoppingCart.computedTotalToDisplay}/>
                <GrandTotalComponent description={services.language.translate('Balance due')}
                                     total={booking.shoppingCart.balanceDue}/>
            </ShoppingCartSectionComponent>
            */}
        </ShoppingCartContainerBox>
    );
});
