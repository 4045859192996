import React from "react";
import {useServices} from "../../../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {IAvailableFareViewModel} from "../../../../../services/flight-search/models/fares/available-fare-view-model.interface";
import {
    PriceWithDiscountComponent,
    PriceWithDiscountOrientation
} from "../../../../../components/price/price-with-discount.component";
import classNames from "classnames";
import {PriceLabelPositionEnum} from "../../../../../components/price/price.component";
import {AirlineTheme} from "../../../../../services/theme/interfaces/airline.theme";
import {PrimaryButton} from "../../../../../basic-components/buttons/primary-button";
import { FarePromoMessageComponent } from "./fare-promo-message.component";
import {useContainerMediaQueriesChecks} from "../../../../../hooks/use-container-media-queries-checks.hook";

const FareContainerButtonBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 0;
    width: 100%;
`;

function computeFareButtonColors(theme: AirlineTheme, isSelected: boolean) {


    if(isSelected) {
        return css`
                background-color: ${theme.colors.primaryShade};
                border-color: ${theme.colors.primaryShade};
                color: ${theme.colors.primaryContrast};
            `
    } else {
        return css`
          background-color: ${theme.colors.primary};
          color: ${theme.colors.primaryContrast};
            `
    }
}

const FareButtonBox = styled(PrimaryButton)<{$isSelected: boolean, $hasBlueBenefits: boolean}>`
    flex-direction: column;
    gap: 4px;
    padding: ${props => props.theme.spacing.spacing12} ${props => props.theme.spacing.spacing32};
    cursor: ${props => props.$isSelected ? "default" : "pointer"};
    font-size: ${props => props.theme.fontSize.h1};
    width: 100%;
    ${props => computeFareButtonColors(props.theme, props.$isSelected)};
    
    
    
`;

const FareSelectedBox = styled.div`
    
    
`

const FarePriceDescriptionBox = styled.div<{$hasBlueBenefits: boolean}>`
    font-size: ${props => props.theme.fontSize.body2};
    font-weight: ${props => props.theme.fontWeight.regular};
`

const PromoMessageContainerBox = styled.div`
    position: absolute;
    top: calc(-1 * ${props => props.theme.spacing.cardDefaultPadding});
    right: 0;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    
`




interface JourneyFareComponentProps {
    fare: IAvailableFareViewModel;
}

export const AvailableFareComponent: React.FC<JourneyFareComponentProps> = observer((props) => {

    const services = useServices();
    const mediaQuery = useContainerMediaQueriesChecks();

    const renderPriceSign = () => {
        if(props.fare.isPriceReduced) {
            return <span>+</span>
        }
        return null;
    }

    const renderPromotionalMessage = () => {

        const promotionalMessage =  props.fare.promotionalMessage;
        //const promotionalMessage =  "Cumperi 1, primești încă 1 gratis";
        //const promotionalMessage =  "25% Discount";

        if(!promotionalMessage) {
            return null;
        }

        if(mediaQuery.smallScreen) {
            return null;
        }


        return (
            <PromoMessageContainerBox>
                <FarePromoMessageComponent promoMessage={promotionalMessage}/>
            </PromoMessageContainerBox>

        );
    }

    const renderPriceWithDiscount = () => {
        if(props.fare.isSelected) {
            return (
                <FareSelectedBox>
                    {services.language.translate('Selected')}
                </FareSelectedBox>
            );
        }

        return (
            <PriceWithDiscountComponent discountedPrice={props.fare.discountedPrice}
                                  standardPrice=
                                      {
                                          /*
                                             if the price is reduced we will show the + sign
                                             but we don't want to show both standard price and the discounted price.
                                             This happens on manage my booking flow when we show the difference between the initial journey fare and the new journey fare
                                             but if the new journey fare has a discount then we would show the + sign and standard fare and discounted fare
                                             and this will look strange.
                                             So this is the reason for the condition here.
                                          */
                                          props.fare.isPriceReduced ? props.fare.discountedPrice : props.fare.totalPrice
                                      }
                                  label={renderPriceSign()}
                                  labelPosition={PriceLabelPositionEnum.Start}
                                  orientation={PriceWithDiscountOrientation.Horizontal}>
            </PriceWithDiscountComponent>
        );
    }

    const sellFare = async () => {
        if(props.fare.isSelected) {
            return;
        }
        await props.fare.sell();
    }



    return (
        <FareContainerButtonBox onClick={sellFare}
                                className={classNames('tst-fare', {
                                    'tst-fare-standard': !props.fare.hasBlueBenefits,
                                    'tst-fare-blue-benefits': props.fare.hasBlueBenefits
                                })}>
            {renderPromotionalMessage()}
            <FareButtonBox $isSelected={props.fare.isSelected} $hasBlueBenefits={props.fare.hasBlueBenefits}>
                <FarePriceDescriptionBox $hasBlueBenefits={props.fare.hasBlueBenefits} className={'tst-price-name'}>
                    {/*props.fare.hasBlueBenefits && <DiscountDiagonalNorthEastTagFilledIcon color={services.theme.currentTheme.colors.warning} />*/}
                    {props.fare.discountTypeName}
                </FarePriceDescriptionBox>
                {renderPriceWithDiscount()}
            </FareButtonBox>
        </FareContainerButtonBox>
    );
});
