import React from 'react';
import {IServiceFactory} from "../../../../service-factory.interface";
import {BizbSsrLargeIcon} from "./bizb.ssr.large-icon";
import {BizbSsrSmallIcon} from "./bizb.ssr.small-icon";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../../ssrs-preferred-orders-in-shopping-cart";
import {CheckInBagsSsrBase} from "../check-in-bags-ssr-base";
import {ISsrType} from "../../../ssr-types.service.interface";

export default class BizBSsrType extends CheckInBagsSsrBase {
    constructor(services: IServiceFactory) {
        super('BIZB', services);
    }

    get isAvailable(): boolean {
        return true;
    }

    get bagWeightKg(): number {
        return 23;
    }
    get smallIcon() {
        return (<BizbSsrSmallIcon />);
    }

    get largeIcon() {
        return (<BizbSsrLargeIcon/>);
    }

    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.BIZB;
    }

    get isSoldOnlyInBundle(): boolean {
        return true;
    }

    get aliasFor(): ISsrType | null {
        return this.services.ssrTypes.SBAG;
    }
}
