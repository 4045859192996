import React, {useRef, useState} from "react";
import {IBookingViewModel} from "../../services/booking/models/booking-view-model.interface";
import {useServices} from "../../hooks/use-services.hook";
import {TicketComponent} from "./ticket.component";
import styled from "styled-components";
import {delay} from "../../utils/util-functions";
import {PrinterIcon} from "../../assets/svg/printer.icon";
import {observer} from "mobx-react";
import {IonSpinnerComponent} from "../spinner/ion-spinner.component";
import {AirlineCompanyName} from "../../global-constants";

const PrintTicketButtonBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    gap: ${props => props.theme.spacing.flexGap8};
`

const SpinnerBox = styled(IonSpinnerComponent)`
    width: 100%;
    height: 100%;
`

const IconBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
`

export const PrintTicketButtonComponent: React.FC<{booking: IBookingViewModel; hideLabel: boolean}> = observer((props) => {
    const services = useServices();
    const elementRef = useRef<HTMLDivElement | null>(null);
    const [isPrintingInProgress, setIsPrintingInProgress] = useState(false);

    if(props.booking.balanceDue.amount > 0 || props.booking.hasPurchasesOnCurrentSession || props.booking.hasChangedSegments) {
        return null;
    }

    const waitForElementReady = () => {
        return new Promise<void>(resolve => {
            const interval =  setInterval(() => {
                if(elementRef.current) {
                    clearInterval(interval);
                    resolve();
                }
            }, 100);
        });
    }

    const onClickHandler = async () => {
        if(isPrintingInProgress) {
            return;
        }

        setIsPrintingInProgress(true);

        try {
            const startTime = Date.now();
            await waitForElementReady();
            await delay(startTime, 1000);

            await services.pdfExporter.printPdf({
                element: elementRef.current as Element,
                fileName: `${AirlineCompanyName}_FlightTicket_${props.booking.recordLocator}.pdf`
            });
        } finally {
            setIsPrintingInProgress(false);
        }
    }

    const renderIcon = () => {
        if(isPrintingInProgress) {
            return (
                <SpinnerBox/>
            );
        }

        return (
            <PrinterIcon/>
        )
    }

    const renderButtonText = () => {
        if (props.hideLabel) {
            return null;
        }

        return (<div>{services.language.translate('Download Ticket')}</div>);
    }

    return (
        <PrintTicketButtonBox onClick={onClickHandler}>
            {renderButtonText()}
            <IconBox>
                {renderIcon()}
            </IconBox>
            <div style={{display: 'none'}}>
                {isPrintingInProgress && <TicketComponent booking={props.booking} elementRef={elementRef}/>}

            </div>
        </PrintTicketButtonBox>
    )
})