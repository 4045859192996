import React from "react";
import {observer} from "mobx-react";
import {Bag3dIconComponent} from "../../bag-icon/bag-3d-icon.component";
import {useServices} from "../../../../../../hooks/use-services.hook";

export const SmallCabinBag3dIconComponent: React.FC<{className?: string}> = observer((props) => {
    const services = useServices();
    return (
        <Bag3dIconComponent width={122}
                            height={122}
                            className={props.className}
                            imageUrl={services.theme.currentTheme.assets.images.bags.small_cabin_bag_big_png.toString()}/>
    )
})