import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../hooks/use-services.hook";
import {BlueBenefitsCurrentUserSubscriptionComponent} from "./components/user-subscription/blue-benefits-current-user-subscription.component";
import {RoutedPageComponent} from "../../basic-components/page/routed-page/routed-page.component";
import {StandardPageHeaderComponent} from "../../basic-components/page/standard-page-header/standard-page-header.component";
import {StandardPageContentComponent} from "../../basic-components/page/standard-page-content/standard-page-content.component";
import {SpinnerComponent} from "../../components/spinner/spinner.component";
import {BlueBenefitsSubscriptionStatus} from "../../services/blue-benefits/models/subscriptions/blue-benefits-subscription.interface";
import {BlueBenefitsPackagesPresentationComponent} from "./components/packages-info/blue-benefits-packages-presentation.component";

export const BlueBenefitsPage: React.FC = observer(() => {
    const services = useServices();
    const userProfile = services.user.profile;
    if(!userProfile.isProfileInitialized) {
        return <SpinnerComponent/>;
    }

    const subscriptionStatus = userProfile.blueBenefitsSubscription.subscriptionStatus;

    const getTitle = () => {
        if(subscriptionStatus === BlueBenefitsSubscriptionStatus.noSubscription) {
            return services.language.translationFor('{blueBenefits} Packages').withParams({blueBenefits: 'Blue Benefits'});
        } else {
            return services.language.translationFor('{blueBenefits} Subscription').withParams({blueBenefits: 'Blue Benefits'});
        }
    }

    return (
        <RoutedPageComponent>
            <StandardPageHeaderComponent renderTopMiddleSection={getTitle} hideLogo={true} />
            <StandardPageContentComponent>
                {
                    subscriptionStatus === BlueBenefitsSubscriptionStatus.noSubscription
                    ? <BlueBenefitsPackagesPresentationComponent/>
                    : <BlueBenefitsCurrentUserSubscriptionComponent />
                }

            </StandardPageContentComponent>
        </RoutedPageComponent>
    )
});
