import React from "react";
import {Price} from "../../services/currency/price";
import styled, {css} from "styled-components";
import {NullableString} from "../../types/nullable-types";
import {PRICE_DECIMAL_PART, PRICE_WHOLE_PART} from "./price-component-css-classes";



const PriceDecimalPartBox = styled.span`
    font-size: 75%;
    line-height: 1;
`;

const LabelBox = styled.span`
    margin-right: 4px;
`


export enum PriceLabelPositionEnum {
    Start,
    Top
}

const PriceComponentBox = styled.span<{$labelPosition: PriceLabelPositionEnum}>`
  display: flex;
  
  
  ${props => props.$labelPosition === PriceLabelPositionEnum.Start 
        ? css`
            flex-direction: row;
        `
        : css`
            flex-direction: column;
            align-items: flex-start;
            align-content: flex-start;
            justify-content: flex-start;
        `}
`

const PriceValueContainerBox = styled.span`
    
`


interface PriceComponentProps {
    price: Price;
    className?: string;
    label?: string | React.ReactElement | null;
    labelPosition?: PriceLabelPositionEnum;
}


export const PriceComponent: React.FC<PriceComponentProps> = (props) => {
    const formattedPrice = props.price.toString();

    const decimalSeparatorIndex =  Math.max(formattedPrice.indexOf('.'), formattedPrice.indexOf(','));
    let priceWholePart: NullableString;
    let priceDecimalPart: NullableString = null;

    if(decimalSeparatorIndex >= 0) {
        priceWholePart = formattedPrice.substring(0, decimalSeparatorIndex);
        priceDecimalPart = formattedPrice.substring(decimalSeparatorIndex);
    } else {
        priceWholePart = formattedPrice;
    }

    const renderLabel = () => {
        if(props.label) {
            return (
                <LabelBox>
                    {props.label}
                </LabelBox>
            );
        } else {
            return null;
        }
    }

    const labelPosition = props.labelPosition || PriceLabelPositionEnum.Start;

    return (
        <PriceComponentBox $labelPosition={labelPosition}  className={props.className}>
            {renderLabel()}
            <PriceValueContainerBox>
                <span className={PRICE_WHOLE_PART}>{priceWholePart}</span>
                <PriceDecimalPartBox className={PRICE_DECIMAL_PART}>
                    {priceDecimalPart}
                </PriceDecimalPartBox>
            </PriceValueContainerBox>

        </PriceComponentBox>
    );
}

