import {BookingModel} from "../../booking.model";
import {ISsrType} from "../../../../ssr-types/ssr-types.service.interface";
import {
    IBookingSsrsAggregatorOptions,
    IBookingSsrsAggregatorViewModel
} from "./booking-ssrs-aggregator-view-model.interface";
import {computed, makeObservable} from "mobx";
import {Price} from "../../../../currency/price";
import {NullableString} from "../../../../../types/nullable-types";
import {IServiceFactory} from "../../../../service-factory.interface";
import {SsrModel} from "../ssr.model";
import {ISsrTypeCount} from "../ssr-type-count.interface";
import {IJourneySsrsBucket} from "../journey-ssrs-bucket.interface";

export class BookingSsrsAggregator implements IBookingSsrsAggregatorViewModel {
    constructor(private readonly booking: BookingModel,
                private readonly options: IBookingSsrsAggregatorOptions) {

        if(options.journeysSsrsBuckets) {
            this.journeysSsrsBuckets = options.journeysSsrsBuckets;
        } else if(options.ssrTypes) {
            this.journeysSsrsBuckets = booking.filteredJourneys.map(j => j.createSsrsBucket({
                ssrTypes: options.ssrTypes!,
                onlyForPassengersKeys: options.onlyForPassengerKeys ?? [],
                useSameOptionsForAllPassengersDefaultValue: options.useSameOptionsForAllPassengersDefaultValue
            }));
        } else {
            throw new Error('You need to provide journeysSsrsBuckets or ssrTypes')
        }

        makeObservable(this, {
            isCompletelyIncludedInBundle: computed,
            canBeModifiedForAtLeastOneFlight: computed,
            modificationBlockingReason: computed,
            discountedMinPrice: computed,
            hasPurchases: computed,
            standardMinPrice: computed,
            countAllSsrsPerBooking: computed,
            totalSsrsQuantity: computed,
            totalPrice: computed,
            totalPriceOnCurrentSession: computed
        });
    }



    public get ssrTypes(): ISsrType[] {
        if(this.options.ssrTypes) {
            return this.options.ssrTypes;
        }

        return this.journeysSsrsBuckets[0].getAllSsrsInTheBucket().distinct(ssr => ssr.ssrType.ssrCode, ssr => ssr.ssrType);

    }

    private get services(): IServiceFactory {
        return this.booking.services;
    }

    readonly journeysSsrsBuckets: IJourneySsrsBucket[];

    get allowUseSameOptionsForAllFlights(): boolean {
        return !this.isCompletelyIncludedInBundle && this.journeysSsrsBuckets.all(j => j.allowUseSameOptionsForAllFlights);
    }

    get useSameOptionsForAllFlights(): boolean {
        return this.journeysSsrsBuckets.all(j => j.useSameOptionsForAllFlights);
    }

    set useSameOptionsForAllFlights(value: boolean){
        this.journeysSsrsBuckets.forEach(j => {
            j.useSameOptionsForAllFlights = value;
        });
    }

    get allowUseSameOptionsForAllPassengers(): boolean {
        return this.journeysSsrsBuckets.all(j => j.allowUseSameOptionsForAllPassengers);
    }

    get useSameOptionsForAllPassengers(): boolean {
        return this.journeysSsrsBuckets.all(j => j.useSameOptionsForAllPassengers);
    }

    set useSameOptionsForAllPassengers(value: boolean) {
        for(let j of this.journeysSsrsBuckets) {
            j.useSameOptionsForAllPassengers = value;
        }
    }

    get countPassengers(): number {
        return this.journeysSsrsBuckets[0].countPassengers;
    }

    private _findSsrsByType(ssrType: ISsrType): SsrModel[] {
        if(!this.ssrTypes.map(ssrType => ssrType.ssrCode).includes(ssrType.ssrCode)) {
            throw new Error(`SSR type ${ssrType.ssrCode} is not included in this bucket`);
        }
        return this.journeysSsrsBuckets.selectMany(j => j.getAllSsrsInTheBucket().filter(ssr => ssr.ssrType.ssrCode === ssrType.ssrCode));
    }

    somePassengersHaveThisSsr(ssrType: ISsrType): boolean {
        for(let ssr of this._findSsrsByType(ssrType)) {
            if(ssr.passengersSsrEditors.some(p => p.newQuantity > 0)) {
                return true;
            }
        }

        return false;
    }

    get hasPurchases(): boolean {
        for(let j of this.journeysSsrsBuckets) {
            if(j.getAllSsrsInTheBucket().some(ssr => ssr.totalQuantity > 0)) {
                return true;
            }
        }

        return false;
    }

    allPassengersHaveThisSsr(ssrType: ISsrType): boolean {
        for(let ssr of this._findSsrsByType(ssrType)) {
            if(ssr.passengersSsrEditors.some(p => p.newQuantity === 0)) {
                return false;
            }
        }

        return true;
    }

    get discountedMinPrice(): Price | null {
        return Price.min(this.journeysSsrsBuckets.map(j => j.discountedMinPrice));
    }

    get standardMinPrice(): Price | null {
        return Price.min(this.journeysSsrsBuckets.map(j => j.standardMinPrice));
    }

    get canBeModifiedForAtLeastOneFlight(): boolean {
        return this.journeysSsrsBuckets.some(j => j.canBeModified);
    }

    get isCompletelyIncludedInBundle(): boolean {
        return this.journeysSsrsBuckets.all(j => j.isCompletelyIncludedInBundle);

    }

    get canBeModified(): boolean {
        return this.canBeModifiedForAtLeastOneFlight && !this.isCompletelyIncludedInBundle;
    }

    get totalPrice(): Price {
        return  Price.sumAll(this.journeysSsrsBuckets.map(jb => jb.totalPrice), this.booking.createPrice(0));
    }

    get totalPriceOnCurrentSession(): Price {
        return  Price.sumAll(this.journeysSsrsBuckets.map(jb => jb.totalPriceOnCurrentSession), this.booking.createPrice(0));
    }

    get modificationBlockingReason(): NullableString {
        if(this.canBeModified) {
            return null;
        }


        if(this.isCompletelyIncludedInBundle) {
            return this.services.language.translate('Included in bundle');
        }

        for(let j of this.journeysSsrsBuckets) {
            for(let ssr of j.getAllSsrsInTheBucket()) {
                const reason = ssr.modificationBlockingReason;
                if(reason) {
                    return reason;
                }
            }
        }

        return null;
    }

    get countAllSsrsPerBooking(): ISsrTypeCount[] {
        const result: Record<string, ISsrTypeCount> = {};

        for(let j of this.journeysSsrsBuckets) {
            for(let ssr of j.getAllSsrsInTheBucket()) {
                if(!result[ssr.ssrType.ssrCode]) {
                    result[ssr.ssrType.ssrCode] = {
                        ssrType: ssr.ssrType,
                        count: 0
                    }
                }

                result[ssr.ssrType.ssrCode].count += ssr.totalQuantity;
            }
        }

        return Object.values(result).filter(ssrCount => ssrCount.count > 0);
    }

    get totalSsrsQuantity(): number {
        return this.countAllSsrsPerBooking.sum(ssr => ssr.count);
    }
}
