import styled, {css} from "styled-components";
import React from "react";
import {useScreenMediaQueriesChecks} from "../../hooks/use-screen-media-queries-checks.hook";
import {InputBaseBox} from "./input-base.box";

const DropDownInputBaseBox = styled(InputBaseBox)<{$hasSelection: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    cursor: pointer;
    ${props => !props.$hasSelection
    ? css`
                color: ${props => props.theme.colors.medium};
        `
    : ''
}
    &:focus-within {
        &::placeholder {
            display: none;
        }
    }
`


export const ReadonlyDropDownInputComponent: React.FC<{value: string, hasSelection: boolean}> = (props) => {
    const mediaQuery = useScreenMediaQueriesChecks();

    if(mediaQuery.smallScreen) {
        return (<DropDownInputBaseBox as={"div"} $hasSelection={props.hasSelection}>
            {props.value}
        </DropDownInputBaseBox>);
    }

    return (
        <DropDownInputBaseBox type={"text"}
                              readOnly={true}
                              value={props.value}
                              $hasSelection={props.hasSelection}/>
    )
}