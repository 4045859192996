import {IBookingViewModel} from "../../../../services/booking/models/booking-view-model.interface";
import {IExtrasSectionRenderer} from "../../../booking-flow/extras-selection/base-renderers/extras-section-renderer.interface";
import {SeatsExtrasSectionRenderer} from "../../../booking-flow/extras-selection/sections/seats/seats.extras-section.renderer";
import {AirportCheckInExtrasSectionRenderer} from "../../../booking-flow/extras-selection/sections/airport-check-in/airport-check-in.extras-section.renderer";
import {EarlyCheckInExtrasSectionRenderer} from "../../../booking-flow/extras-selection/sections/early-check-in/early-check-in.extras-section.renderer";
import {BusTransferExtrasSectionRenderer} from "../../../booking-flow/extras-selection/sections/bus-transfer/bus-transfer.extras-section.renderer";
import {BusinessLoungeExtrasSectionRenderer} from "../../../booking-flow/extras-selection/sections/business-lounge/business-lounge.extras-section.renderer";
import {FastTrackExtrasSectionRenderer} from "../../../booking-flow/extras-selection/sections/fast-track/fast-track.extras-section.renderer";
import {PetsExtrasSectionRenderer} from "../../../booking-flow/extras-selection/sections/pets/pets.extras-section.renderer";
import {SpecialEquipmentExtrasSectionRenderer} from "../../../booking-flow/extras-selection/sections/special-equipment/special-equipment.extras-section.renderer";
import {IExtrasSectionsRenderersFactory} from "../../../booking-flow/extras-selection/base-renderers/extras-sections-renderers-factory.interface";
import {BagsExtrasSectionRenderer} from "../../../booking-flow/extras-selection/sections/bags/bags-extras-section.renderer";
import {ExtrasRenderersSorter} from "../../../booking-flow/extras-selection/base-renderers/extras-renderers-sorter";
import {makeObservable, observable, runInAction} from "mobx";

export class ManageMyBookingServicesSectionFactory implements IExtrasSectionsRenderersFactory {
    constructor(booking: IBookingViewModel) {
        makeObservable<this, '_customSectionsOrder'>(this, {
            _customSectionsOrder: observable.ref
        })

        this._sections = ExtrasRenderersSorter.sortRenderers([
            new FastTrackExtrasSectionRenderer(booking),
            new AirportCheckInExtrasSectionRenderer(booking),
            new SeatsExtrasSectionRenderer(booking),
            new EarlyCheckInExtrasSectionRenderer(booking),
            new BagsExtrasSectionRenderer(booking),
            new BusTransferExtrasSectionRenderer(booking),
            new BusinessLoungeExtrasSectionRenderer(booking),
            new SpecialEquipmentExtrasSectionRenderer(booking),
            new PetsExtrasSectionRenderer(booking)
        ]);
    }

    private _customSectionsOrder: IExtrasSectionRenderer[] | null = null;

    private readonly _sections: IExtrasSectionRenderer[];
    getExtrasSections(): IExtrasSectionRenderer[] {
        if(this._customSectionsOrder) {
            return this._customSectionsOrder;
        }
        return this._sections;
    }

    setCustomOrder(sections: IExtrasSectionRenderer[]) {
        runInAction(() => {
            this._customSectionsOrder = sections
        })
    }
}
