import {ILanguageService} from "../../../services/language/language.service.interface";

export function translateIncludedBags(language: ILanguageService, numberOfIncludedBags: number): string {
    if(numberOfIncludedBags === 1) {
        return language.translate('One bag included');
    } else {
        return language.translationFor('{numberOfBags} bags included').withParams({
            numberOfBags: numberOfIncludedBags
        });
    }
}


