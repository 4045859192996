import {OnlineCheckInBlockerSsr} from "../online-check-in-blocker-ssr";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../ssrs-preferred-orders-in-shopping-cart";
import {
    SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE, SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE,
    SSR_LARGE_ICON_SIZE,
    SsrQuantityEditorStyle
} from "../../ssr-types.service.interface";
import { SsrCategoryEnum } from "../../ssr-category.enum";
import React from "react";
import {PetIcon} from "../../../../assets/svg/pet.icon";

export abstract class PetsSsrTypeBase extends OnlineCheckInBlockerSsr {
    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.PETS;
    }

    get preferredQuantityEditorStyle(): SsrQuantityEditorStyle {
        return SsrQuantityEditorStyle.plusMinusButtons;
    }

    get category(): SsrCategoryEnum {
        return SsrCategoryEnum.Pets;
    }

    get smallIcon(): React.ReactElement | null {
        return <PetIcon/>;
    }

    get largeIcon(): React.ReactElement | null {
        return <PetIcon size={SSR_LARGE_ICON_SIZE}/>;
    }

    get iconInExtrasFirstLargeTile(): React.ReactElement | null {
        return (<PetIcon size={SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE}/>);
    }

    get iconInExtrasSmallTile(): React.ReactElement | null {
        return (<PetIcon size={SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE}/>);
    }

}
