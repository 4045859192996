import React, {CSSProperties} from "react";
import {ISegmentViewModel} from "../../../services/booking/models/segment/segment-view-model.interface";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {PlaneHorizontalFilledIcon} from "../../../assets/svg/plane-horizontal-filled.icon";
import {
    IFlightDesignatorViewModel
} from "../../../services/booking/models/designator/flight-designator-view-model.interface";

const FlightNumberComponent: React.FC<{flightNumber: string}> = observer((props) => {
    const services = useServices();
    const styles: CSSProperties = {

        fontWeight: services.theme.currentTheme.fontWeight.bold
    };

    return (
        <div style={styles}>
            {services.language.translationFor('Flight number: {flightNumber}').withParams({flightNumber: props.flightNumber})}
        </div>
    )
})

const FlightDurationComponent: React.FC<{travelTime: string}> = observer((props) => {

    return (
        <div>
            {props.travelTime}
        </div>
    )
})

const CarrierComponent: React.FC<{designator: IFlightDesignatorViewModel}> = observer((props) => {
    if(!props.designator.flightIdentifier) {
        return null;
    }

    return (
        <div>
            {props.designator.flightIdentifier.getOperatedByMessage()}
        </div>
    )
})


const LineBox: React.FC = observer(() => {
    const services = useServices();
    const styles: CSSProperties = {
        flexGrow: 1,
        height: '1px',
        backgroundColor: services.theme.currentTheme.border.mainColor
    };

    return (
        <div style={styles}/>
    )

})

const HorizontalPlaneBox: React.FC = observer(() => {
    const services = useServices();
    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: 'center',
        width: '100%',
        gap: services.theme.currentTheme.spacing.flexGap12
    };

    return (
        <div style={styles}>
            <LineBox/>
            <PlaneHorizontalFilledIcon size={1.6}/>
            <LineBox/>
        </div>

    )
})

export const TicketSegmentFlightDetailsComponent: React.FC<{segment: ISegmentViewModel}> = observer((props) => {
    const services = useServices();
    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center",
        justifyContent: 'center',
        flexGrow: 1,
        gap: services.theme.currentTheme.spacing.flexGap8
    };


    return (
        <div style={styles}>
            <FlightNumberComponent flightNumber={props.segment.identifier.fullIdentifierCode()}/>
            <FlightDurationComponent travelTime={services.time.formatTravelTime(props.segment.designator.travelTime)}/>
            <HorizontalPlaneBox/>
            <CarrierComponent designator={props.segment.designator}/>
        </div>
    );
})
