import React from 'react';
import {SsrTypeBase} from "../../ssr-type-base";
import {IServiceFactory} from "../../../service-factory.interface";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../ssrs-preferred-orders-in-shopping-cart";
import { CheckInOnlineSmallIcon } from './check-in-online.small-icon';
import {
    SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE, SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE,
    SSR_LARGE_ICON_SIZE
} from "../../ssr-types.service.interface";

export default class LcknSsrType extends SsrTypeBase {
    constructor(services: IServiceFactory) {
        super('LCKN', services);
    }

    get smallIcon(): React.ReactElement | null {
        return (<CheckInOnlineSmallIcon/>);
    }

    get largeIcon(): React.ReactElement | null {
        return (<CheckInOnlineSmallIcon size={SSR_LARGE_ICON_SIZE}/>);
    }

    get iconInExtrasFirstLargeTile(): React.ReactElement | null {
        return (<CheckInOnlineSmallIcon size={SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE}/>);
    }

    get iconInExtrasSmallTile(): React.ReactElement | null {
        return (<CheckInOnlineSmallIcon size={SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE}/>);
    }

    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.FLEX
    }

}
