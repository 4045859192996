import React from "react";
import {useServices} from "../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import styled from "styled-components";
import {XCloseSmallIcon} from "../../../assets/svg/x-close-small.icon";
import {useScreenMediaQueriesChecks} from "../../../hooks/use-screen-media-queries-checks.hook";
import {ChevronBackIcon} from "../../../assets/svg/chevron-back.icon";

const BackButtonBox = styled.div<{$isHidden: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.spacing8};
    visibility: ${props => props.$isHidden ? "hidden" : "visible"};
    cursor: pointer;
`

const ArrowBackWrapperBox = styled.div`
    padding: 6px 4px;
`


interface PageBackButtonComponentProps {
    isHidden?: boolean;
    onClick?: () => void;
    useCloseButtonInsteadOfBackButton?: boolean;
}
export const PageBackButtonComponent: React.FC<PageBackButtonComponentProps> = observer((props) => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();
    if(!props.onClick && !services.navigator.canGoBack) {
        return null;
    }

    const isHidden = Boolean(props.isHidden) || (!props.onClick && !services.navigator.canGoBack);

    const onClickHandler = () => {
        if(props.onClick) {
            props.onClick();
        } else if(services.navigator.canGoBack){
            services.navigator.goBack();
        }
    }

    const renderBackText = () => {
        if(screenMediaQuery.smallScreen) {
            return null;
        }
        return (
            <span>
                {services.language.translate('Back')}
            </span>
        )
    }

    const backText = renderBackText();

    const renderBackButton = () => {
        if(props.useCloseButtonInsteadOfBackButton) {
            return (<XCloseSmallIcon size={3.3}/>);
        }

        if(backText) {
            return (<ChevronBackIcon size={1.6} />)
        }

        return (
            <ArrowBackWrapperBox>
                <ChevronBackIcon size={1.6} />
            </ArrowBackWrapperBox>
        )
    }

    return (
        <BackButtonBox onClick={onClickHandler} $isHidden={isHidden}>
            {renderBackButton()}
            {renderBackText()}
        </BackButtonBox>
    );
})
