import {NullableStation, Station} from "../stations/station.service.interface";
import {IFlightSearchService, IStartBookingOptions,} from "./flight-search.service.interface";
import {IServiceFactory} from "../service-factory.interface";
import {reaction} from "mobx";
import {Wizard} from "../../models/wizard/wizard";
import {ServiceBase} from "../service-base";
import {LocalStorageKeys} from "../storage/local-storage-keys";
import {NullableDate} from "../../types/nullable-types";
import {TimeSpan} from "../../types/time-span";
import {
    IFlightSearchControllerViewModel,
} from "./flight-search-controller/flight-search-controller-view-model.interface";
import {FlightSearchControllerModel} from "./flight-search-controller/flight-search-controller.model";
import {FlightSearchDeepLinkHandler} from "./flight-search-deep-link-handler";
import {PsoUserAgreementEnum} from "./flight-search-controller/pso.enums";
import {RoundTripLowFareReaderFactory} from "../low-fare/round-trip-low-fare-reader-factory";
import {IRoundTripLowFareReader} from "../low-fare/low-fare-readers/low-fare-reader.interface";

export abstract class FlightSearchBaseService extends ServiceBase implements IFlightSearchService {
    constructor(services: IServiceFactory) {
        super(services);

        this.searchController = new FlightSearchControllerModel(this.services, 'flightSearch.searchControllerStorageKey', {
            getLowFareReader: () => this.getLowFaresReader()
        });
        this._deepLinkHandler = new FlightSearchDeepLinkHandler(this, this.services);


        this._subscribeToReactions();
    }

    private _appDeactivationTime: NullableDate = null;
    protected readonly _deepLinkHandler: FlightSearchDeepLinkHandler;

    private _lowFareReaderFactory: RoundTripLowFareReaderFactory | null = null;
    getLowFaresReader(): IRoundTripLowFareReader {
        if(!this._lowFareReaderFactory) {
            this._lowFareReaderFactory = new RoundTripLowFareReaderFactory(this.services,
                this.searchController,
                false,
                () => this.services.currency.current);
        }
        return this._lowFareReaderFactory.lowFareReader;

    }

    protected abstract _goToFlightSelection(options?: IStartBookingOptions): Promise<void>;
    protected abstract _createSteps(): Wizard;
    protected abstract _onSearchMaskErrors(): void;
    abstract startWizardSearch(): void;
    get hasDeepLinkErrors(): boolean {
        return this._deepLinkHandler.hasDeepLinkErrors;
    }

    private _steps: Wizard | null = null;
    get steps(): Wizard {
        if(!this._steps) {
            this._steps = this._createSteps();
        }
        return this._steps;
    }

    private _subscribeToReactions(): void {
        reaction(() => this.services.application.isActive,
            async (isActive) => {
                if(isActive) {
                    if(this._appDeactivationTime && this.services.navigator.routes.flightSearch.hasActiveChildRoute) {
                        const timeSinceAppDeactivate = TimeSpan.fromMilliseconds(Date.now() - this._appDeactivationTime.getTime());
                        if(this.services.configuration.dotRezSessionTimeout.totalMilliseconds < timeSinceAppDeactivate.totalMilliseconds) {
                            await this.services.navigator.goHome();
                        }
                    }
                    this._appDeactivationTime = null;
                } else {
                    this._appDeactivationTime = this.services.time.currentDate;
                }
            });

    }


    readonly searchController: IFlightSearchControllerViewModel;



    private _getRecentlySearch(key: LocalStorageKeys): Station[] {
        let result: Station[] = [];
        const savedStations = this.services.localStorage.getJson<string[]>(key) || [];

        for(let stationCode of savedStations) {
            const s = this.services.stations.tryGetStation(stationCode);
            if(s) {
                result.push(s);
            }
        }

        return result;
    }

    getRecentlySearchedOrigins(): Station[] {
        return this._getRecentlySearch('flightSearch.recentlySearchedOriginStations');
    }
        
    getRecentlySearchedDestinations(): Station[] {
        return this._getRecentlySearch('flightSearch.recentlySearchedDestinationStations');
    }        
        
    private _saveRecentlySearch(key: LocalStorageKeys, station: NullableStation) {
        if(!station) {
            return;
        }
        let recentlySearch = this.services.localStorage.getJson<string[]>(key) || [];        
        const stationIndex = recentlySearch.indexOf(station.stationCode);

        if (stationIndex >= 0) {
            recentlySearch.splice(stationIndex, 1);
        }

        recentlySearch.splice(0, 0, station.stationCode); 

        recentlySearch = recentlySearch.slice(0, 3);

        this.services.localStorage.setJson(key, recentlySearch);
    }

    async startBooking(options?: IStartBookingOptions): Promise<void> {
        if(this.searchController.hasErrors()) {
            this._onSearchMaskErrors();
            return;
        }



        await this._goToFlightSelection(options);
        //reset the user agreement so on next search to ask the user again about special price T&C
        this.searchController.psoUserAgreement = PsoUserAgreementEnum.None;


        this._saveRecentlySearch('flightSearch.recentlySearchedOriginStations', this.searchController.departureOrigin);
        this._saveRecentlySearch('flightSearch.recentlySearchedDestinationStations', this.searchController.departureDestination);
    }

    switchStations() {
        let tempStation = this.searchController.departureOrigin;
        let departureDate = this.searchController.departureDate;
        let returnDate = this.searchController.returnDate;
        let psoUserOption = this.searchController.psoUserOption;
        this.searchController.departureOrigin = this.searchController.departureDestination;
        this.searchController.departureDestination = tempStation;
        this.searchController.departureDate = departureDate;
        this.searchController.returnDate = returnDate;
        this.searchController.psoUserOption = psoUserOption;
    }
}
