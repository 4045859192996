import React from "react";
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";

export const DiscountDiagonalNorthWestTagEmptyIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.3} {...props} viewBox="0 0 19 16">
            <path
                d="M4 7.17169V2.49982C4 2.22482 4.225 1.99982 4.5 1.99982H9.17188C9.4375 1.99982 9.69063 2.10607 9.87813 2.29357L15.3781 7.79357C15.7688 8.18419 15.7688 8.81857 15.3781 9.20919L11.2063 13.3779C10.8156 13.7686 10.1813 13.7686 9.79063 13.3779L4.29062 7.87794C4.10312 7.69044 3.99687 7.43732 3.99687 7.17169H4ZM3 7.17169C3 7.70294 3.20938 8.21232 3.58438 8.58732L9.08437 14.0873C9.86562 14.8686 11.1313 14.8686 11.9125 14.0873L16.0844 9.91544C16.8656 9.13419 16.8656 7.86857 16.0844 7.08732L10.5844 1.58732C10.2094 1.21232 9.7 1.00294 9.16875 1.00294H4.5C3.67188 0.999816 3 1.67169 3 2.49982V7.17169ZM6.5 5.24982C6.69891 5.24982 6.88968 5.1708 7.03033 5.03015C7.17098 4.88949 7.25 4.69873 7.25 4.49982C7.25 4.3009 7.17098 4.11014 7.03033 3.96949C6.88968 3.82883 6.69891 3.74982 6.5 3.74982C6.30109 3.74982 6.11032 3.82883 5.96967 3.96949C5.82902 4.11014 5.75 4.3009 5.75 4.49982C5.75 4.69873 5.82902 4.88949 5.96967 5.03015C6.11032 5.1708 6.30109 5.24982 6.5 5.24982Z"
                fill={props.color? props.color: "currentColor"}/>
        </SvgIcon>
    )
}
