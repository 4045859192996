import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {UpsellSuggestionAcceptButtonComponent} from "./upsell-suggestion-accept-button.component";
import {UpsellSuggestionRejectButtonComponent} from "./upsell-suggestion-reject-button.component";

const GridBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 3px 1fr;
    width: 100%;
    align-items: flex-end;
    
`

const ButtonWrapperBox = styled.div`
    width: 100%;
`

const LeftButtonWrapperBox = styled(ButtonWrapperBox)`
    padding-right: ${props => props.theme.spacing.spacing12};
    ${props => props.theme.containerMediaQuery.smallScreen} {
        padding-right: ${props => props.theme.spacing.spacing8};
    }
`

const RightButtonWrapperBox = styled(ButtonWrapperBox)`
    padding-left: ${props => props.theme.spacing.spacing12};

    ${props => props.theme.containerMediaQuery.smallScreen} {
        padding-left: ${props => props.theme.spacing.spacing8};
    }
`


interface UpsellSuggestionComponentProps {
    acceptButtonLabel: string;
    rejectButtonLabel: string;
    acceptButtonClick: () => void;
    rejectButtonClick: () => void;
    renderIcon?: () => React.ReactElement;
}

export const UpsellSuggestionComponent: React.FC<UpsellSuggestionComponentProps> = observer((props) => {
    return (
        <GridBox>

            <LeftButtonWrapperBox>
                <UpsellSuggestionRejectButtonComponent label={props.rejectButtonLabel} onClick={props.rejectButtonClick}/>
            </LeftButtonWrapperBox>

            <span/>

            <RightButtonWrapperBox>
                <UpsellSuggestionAcceptButtonComponent label={props.acceptButtonLabel} onClick={props.acceptButtonClick} renderIcon={props.renderIcon}/>
            </RightButtonWrapperBox>

        </GridBox>
    )
})