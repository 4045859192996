import React from "react";
import {observer} from "mobx-react";
import {IJourneyViewModel} from "../../../services/booking/models/journey/journey-view-model.interface";
import {DesignatorComponent, DesignatorDisplayMode} from "../../designator/designator.component";
import {TabComponent} from "../tab.component";


interface JourneysTabsComponentProps {
    journeys: IJourneyViewModel[];
    currentJourney: IJourneyViewModel;
    onJourneyTabClick: (journey: IJourneyViewModel) => void;
}

export const JourneysTabsComponent: React.FC<JourneysTabsComponentProps> = observer((props) => {

    const Tab = TabComponent<IJourneyViewModel>;

    const renderTabItemContent = (journey: IJourneyViewModel) => {
        return (
            <DesignatorComponent displayMode={DesignatorDisplayMode.stationsCodeOnly} designator={journey.designator}/>
        )
    }

    return (
        <Tab items={props.journeys}
             renderItem={renderTabItemContent}
             getItemKey={j => j.journeyKey}
             onItemClick={props.onJourneyTabClick}
             selectedItem={props.currentJourney}/>
    );
})