import React, {PropsWithChildren, useEffect, useRef} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {IonHeader} from "@ionic/react";
import {useServices} from "../../../../hooks/use-services.hook";
import {AirlineHomePageSmallLogoIcon} from "../../../../assets/svg/airline-home-page-small-logo.icon";
import {TestModeMenuComponent} from "../../../../basic-components/test-mode/test-mode-menu.component";
import {LanguageSwitcherComponent} from "../../../../components/language-switcher/language-switcher.component";
import {BurgerMenuComponent} from "./burger-menu/burger-menu.component";

const HomePageHeaderBox = styled(IonHeader)`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.colors.darkTint};
    color: ${props => props.theme.colors.darkContrast};
    width: 100%;
    padding: calc(env(safe-area-inset-top) + ${props => props.theme.spacing.spacing48}) ${props => props.theme.spacing.spacing16} ${props => props.theme.spacing.spacing16} ${props => props.theme.spacing.spacing16};
    gap: ${props => props.theme.spacing.spacing16};
    box-shadow: none;
   
`

const TopSectionBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    
`

const TopLeftSectionBox = styled.div`
`

const TopRightSectionBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: ${props => props.theme.spacing.spacing16};
`

const BottomSectionBox = styled.div`
    width: 100%;
`

const LanguageSwitcher = styled(LanguageSwitcherComponent)`
    color: ${props => props.theme.colors.darkContrast};
`


interface HybridHomePageHeaderComponentProps extends PropsWithChildren {
}
export const HybridHomePageHeaderComponent: React.FC<HybridHomePageHeaderComponentProps> = observer((props) => {
    const services = useServices();
    const headerRef = useRef<HTMLIonHeaderElement | null>(null);


    useEffect(() => {
        services.pageHeader.setHtmlElement(headerRef.current);
    }, [services.pageHeader]);


    return (
        <HomePageHeaderBox ref={headerRef}>
            <TopSectionBox>
                <TopLeftSectionBox onClick={() => services.homePage.activateBookTab()}>
                    <AirlineHomePageSmallLogoIcon/>
                </TopLeftSectionBox>
                <TopRightSectionBox>
                    <LanguageSwitcher/>
                    <BurgerMenuComponent/>
                    <TestModeMenuComponent/>
                </TopRightSectionBox>

            </TopSectionBox>
            <BottomSectionBox>
                {props.children}
            </BottomSectionBox>

        </HomePageHeaderBox>
    )
});
