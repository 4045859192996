import React from "react";
import {observer} from "mobx-react";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import {useServices} from "../../hooks/use-services.hook";
import {StandardDialogPageComponent} from "../modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../modal/content/standard-dialog-content.component";
import {FormComponent} from "../forms/form.component";
import {PasswordFieldEditorComponent} from "../forms/password/password-field-editor.component";
import {EmailFieldEditorComponent} from "../forms/contact/email-field-editor.component";
import {IPasswordResetFormViewModel} from "../../services/user/models/password-reset/password-reset-form-view-model.interface";
import {ValidationResultEnum} from "../../types/validation-result.enum";
import {PrimaryButton} from "../../basic-components/buttons/primary-button";


interface ResetPasswordDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
    form: IPasswordResetFormViewModel;
}

export const ResetPasswordDialogComponent: React.FC<ResetPasswordDialogComponentProps> = observer((props) => {
    const services = useServices();
    const form = props.form;

    const onClickHandler = async () => {

        const result = await form.executeResetPassword();
        if(result === ValidationResultEnum.Success) {
            props.dialogHandler.accept();
        }
    }
    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate('Password Reset')}
                                           dialogHandler={props.dialogHandler}/>
            <StandardDialogContentComponent dialogHandler={props.dialogHandler}>
                <FormComponent>
                    <EmailFieldEditorComponent field={form.fields.emailAddress}/>
                    <PasswordFieldEditorComponent field={form.fields.newPassword}/>
                    <PrimaryButton onClick={onClickHandler}>
                        {services.language.translate('Reset my password')}
                    </PrimaryButton>
                </FormComponent>
            </StandardDialogContentComponent>
        </StandardDialogPageComponent>
    )
});
