import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {IAvailableTripViewModel} from "../../../services/flight-search/models/available-trip-view-model.interface";
import {AvailableTripsListComponent} from "./components/availability/available-trips-list.component";
import {scrollElementIntoViewSmooth} from "../../../utils/scroll-element-into-view";
import {IJourneyViewModel} from "../../../services/booking/models/journey/journey-view-model.interface";
import {NullableString} from "../../../types/nullable-types";
import {BundlesSkeletonsComponent} from "./components/skeleton/bundles-skeletons.component";


const PageContentBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.pageContentVerticalPadding};
`

export const BundlesSelectionTitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.h3};
    font-weight: ${props => props.theme.fontWeight.bold};
    width: 100%;
`

function computeBundlesAndTitleContainerBoxHeight(hasFareSelected: boolean, hasBundles: boolean) {

    if(hasFareSelected && !hasBundles) {
        return (
            css`
                min-height: 100vh;
            `
        )
    }

    return css`
            min-height: 100%;
    `
}

const BundlesAndTitleContainerBox = styled.div<{$hasFareSelected: boolean; $hasBundles: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${props => props.theme.spacing.pageContentVerticalPadding};
    padding-top: ${props => props.theme.spacing.pageContentVerticalPadding};
    width: 100%;
    ${props => computeBundlesAndTitleContainerBoxHeight(props.$hasFareSelected, props.$hasBundles)}
   
`

const BundlesContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% + 2 * ${props => props.theme.spacing.pageContentHorizontalPadding});
    ${props => props.theme.containerMediaQuery.smallScreen} {
        width: 100%;
    }

`

const PricingInfoBox = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    font-size: ${props => props.theme.fontSize.footNote};
    color: rgba(${props => props.theme.colors.darkRgb}, 0.5);
    
`


interface FlightSelectionPageContentComponentProps {
    trips: IAvailableTripViewModel[];
    pricingInformationMessage: string;
    renderAvailability: () => React.ReactElement;
    bundlesSectionTitle?: NullableString;
    renderBundles?: () => React.ReactElement;
    journey: IJourneyViewModel | null;
}

export const FlightSelectionPageContentComponent: React.FC<FlightSelectionPageContentComponentProps> = observer((props) => {

    const bundlesContainerRef = useRef<HTMLDivElement | null>(null);

    const isFareSelected = props.trips.some(t => t.journeys.some(j => j.fares.some(f => f.isSelected)));
    const allowBundleSelection = Boolean(props.renderBundles);
    const bundlesCount = props.journey?.bundlesAvailability?.length ?? 0
    let hasBundles = bundlesCount > 0;

    //hasBundles = false;

    useEffect(() => {
        if(allowBundleSelection && bundlesContainerRef.current && isFareSelected && !props.journey?.selectedBundle) {
            scrollElementIntoViewSmooth(bundlesContainerRef.current, {
                block: "start",
                inline: "start"
            });
        }
    })

    const renderBundles = () => {
        if(!props.renderBundles) {
            return null;
        }

        if(!isFareSelected) {
            return null;
        }


        if(!hasBundles) {

            return (
                <>
                    <BundlesSelectionTitleBox>
                        {props.bundlesSectionTitle}
                    </BundlesSelectionTitleBox>
                    <BundlesContainerBox>
                        <BundlesSkeletonsComponent useOnlyOneSkeleton={false}/>
                    </BundlesContainerBox>


                </>

            )
        }

        return (
            <>
                <BundlesSelectionTitleBox>
                    {props.bundlesSectionTitle}
                </BundlesSelectionTitleBox>
                <BundlesContainerBox>
                    {props.renderBundles()}
                </BundlesContainerBox>

            </>
        );

    }

    const renderPricingInfo = () => {
        if(props.trips.length === 0) {
            return null;
        }

        return (
            <PricingInfoBox>
                {props.pricingInformationMessage}
            </PricingInfoBox>
        );

    }

    return (
        <PageContentBox>

            {props.renderAvailability()}

            {renderPricingInfo()}

            <AvailableTripsListComponent trips={props.trips}/>

            <BundlesAndTitleContainerBox ref={bundlesContainerRef} $hasFareSelected={isFareSelected} $hasBundles={hasBundles}>
                {renderBundles()}
            </BundlesAndTitleContainerBox>

        </PageContentBox>

    );
});
