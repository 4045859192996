import {JourneySsrsBucketModel} from "../journey-ssrs-bucket.model";
import {SsrModel} from "../ssr.model";
import {JourneyModel} from "../../journey/journey.model";
import {IJourneyAllBagsBucketViewModel} from "./journey-all-bags-bucket-view-model.interface";
import {PassengerAllBagsModel} from "./passenger-all-bags.model";
import {computed, makeObservable, observable, runInAction} from "mobx";
import {ISsrType} from "../../../../ssr-types/ssr-types.service.interface";

export class JourneyAllBagsBucketModel extends JourneySsrsBucketModel implements IJourneyAllBagsBucketViewModel {
    constructor(parentJourney: JourneyModel) {
        super(parentJourney, {
            ssrTypes: [
                parentJourney.services.ssrTypes.PBRD,
                ...parentJourney.services.ssrTypes.getCheckInBagsThatCanBeSoldIndividually()
            ],
            onlyForPassengersKeys: []
        });
        this.passengersBags = parentJourney.booking.passengers.map(p => new PassengerAllBagsModel(p, this));
        makeObservable(this, {
            checkInBagsSsrs: computed,
            validationsActivationTime: observable.ref
        });
    }


    getBagSsrModel(ssrType: ISsrType): SsrModel {
        const bucket = this.getAllSsrsInTheBucket().find(ssr => ssr.ssrType.ssrCode === ssrType.ssrCode);
        if(bucket) {
            return bucket;
        }
        throw new Error(`There is no bag bucket for ${ssrType.ssrCode} ssr.`);
    }

    get checkInBagsSsrs(): SsrModel[] {
        return this.getAllSsrsInTheBucket().filter(ssr => ssr.ssrType.ssrCode !== this.ssrTypesService.PBRD.ssrCode)
                                           .sort((s1, s2) => s1.ssrType.preferredOrderInLists - s2.ssrType.preferredOrderInLists);
    }

    get priorityBoarding(): SsrModel {
        return this.getBagSsrModel(this.ssrTypesService.PBRD);
    }

    readonly passengersBags: PassengerAllBagsModel[];

    validationsActivationTime: number = 0;
    activateValidations(): void {
        this.passengersBags.forEach(p => p.enableValidations());
        runInAction(() => {
            this.validationsActivationTime = Date.now();
        })

        //The setTimeout here is to allow DOM to stabilize before trying to scroll element into the view.
        setTimeout(() => {
            const firstWithErrors = this.passengersBags.find(p => p.hasErrors);

            if(firstWithErrors) {
                firstWithErrors.scrollIntoView();
            }
        }, 500);


    }

    get hasErrors(): boolean {
        return this.passengersBags.some(p => p.cabinBagErrorMessage || p.checkInBagsErrorMessage);
    }

    protected _setUseSameOptionsForAllFlights(value: boolean) {
        super._setUseSameOptionsForAllFlights(value);

        if(value) {
            const thisJourneyPassengerBagsDictionary = this.passengersBags.toDictionary(p => p.passenger.passengerKey)

            for(let bucket of this.getOtherJourneysBuckets()) {
                const otherJourneyBags = bucket as JourneyAllBagsBucketModel;
                for(let passengerBags of otherJourneyBags.passengersBags) {
                    passengerBags.setSkipCheckInBags(thisJourneyPassengerBagsDictionary[passengerBags.passenger.passengerKey].skipCheckInBags);
                }
            }
        }
    }

}

