import React from 'react';
import {IServiceFactory} from "../../../service-factory.interface";
import {SbusSsrSmallIcon} from "./sbus-ssr.small-icon";
import {SsrTypeBase} from "../../ssr-type-base";
import {
    SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE, SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE,
    SSR_LARGE_ICON_SIZE
} from "../../ssr-types.service.interface";

export default class SbusSsrType extends SsrTypeBase {
    constructor(services: IServiceFactory) {
        super('SBUS', services);
    }

    get smallIcon(): React.ReactElement | null {
        return (<SbusSsrSmallIcon/>);
    }

    get largeIcon(): React.ReactElement | null {
        return (<SbusSsrSmallIcon size={SSR_LARGE_ICON_SIZE}/>);
    }

    get iconInExtrasFirstLargeTile(): React.ReactElement | null {
        return (<SbusSsrSmallIcon size={SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE}/>);
    }

    get iconInExtrasSecondLargeTile(): React.ReactElement | null {
        return (<SbusSsrSmallIcon size={2.2}/>);
    }

    get iconInExtrasSmallTile(): React.ReactElement | null {
        return (<SbusSsrSmallIcon size={SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE}/>);
    }
}
