import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {FlightDatesAvailabilityComponent} from "../components/availability/flight-dates-availability.component";

export const ReturnFlightSelectionAvailabilityComponent: React.FC = observer(() => {
    const services = useServices();

    const availability = services.booking.availability;

    if(!availability.returnOrigin || !availability.returnDestination || !availability.returnDate) {
        return null;
    }

    const onDateChanged = async (date: Date) => {
        await availability.changeReturnDate(date);
    }

    return (
        <FlightDatesAvailabilityComponent flightSchedule={availability.returnFlightSchedule}
                                          currentScheduleSelectedDate={availability.returnDate}
                                          onDateSelected={onDateChanged}
                                          getLowFare={(date) => availability.getReturnLowFare(date)}/>
    );
});
