import React from "react";
import { observer } from "mobx-react";
import { useServices } from "../../../../hooks/use-services.hook";
import styled from "styled-components";
import { FormViewComponent } from "../../../../components/forms-view/form-view.component";
import {PersonTravelDocumentViewComponent} from "../../../../components/person/person-travel-document-view.component";

const TravelDocumentTitleBox = styled.div`
    color: ${props => props.theme.colors.secondary};
    font-weight: ${props => props.theme.fontWeight.bold};
    font-size: ${props => props.theme.fontSize.h5};
    padding-bottom: 15px;
    
`
const TravelDocumentElementBox = styled.div`
    padding-bottom: 4px;
    margin-bottom: 14px;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -1px;
        left: 0;
        background-color: ${props => props.theme.colors.medium};
    }

    &:last-of-type {
        margin-bottom: 0;

        &:after {
            display: none;
        }
    }
`

export const UserTravelDocumentListComponent: React.FC = observer(() => {
    const services = useServices();

    const userProfile = services.user.profile.getEditor();
    const travelDocuments = services.user.profile.travelDocuments;


    return (
        <FormViewComponent title={userProfile.getFullName()}>
            <TravelDocumentTitleBox>
                {services.language.translate('Travel documents')}
            </TravelDocumentTitleBox>
            {travelDocuments.map(dt =>
                <TravelDocumentElementBox key={dt.travelDocumentKey}>
                    <PersonTravelDocumentViewComponent key={dt.travelDocumentKey}
                        travelDocument={dt}
                    />
                </TravelDocumentElementBox>
            )}
        </FormViewComponent>
    )
});
