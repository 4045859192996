import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../hooks/use-services.hook";
import styled from "styled-components";
import {PrimaryButtonInverted} from "../../basic-components/buttons/primary-button-inverted";


const ContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.spacing16};
`

const TitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.h4};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

const ButtonAnchorBox = styled.a`
    text-decoration: none;
    width: 100%;
`

const ButtonBox = styled(PrimaryButtonInverted)`
    font-size: ${props => props.theme.fontSize.h4};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

export const CallCenterPhoneComponent: React.FC<{title?: string}> = observer((props) => {
    const services = useServices();
    const phoneNumber = services.configuration.data.callCenterPhoneNumber;
    if(!phoneNumber) {
        return null;
    }

    const renderTitle = () => {
        if(!props.title) {
            return null;
        }

        return (
            <TitleBox>
                {props.title}
            </TitleBox>
        )
    }

    return (
        <ContainerBox>
            {renderTitle()}
            <ButtonAnchorBox href={`tel:${phoneNumber}`}>
                <ButtonBox fullWidth={true} showArrow={true}>
                    {services.language.translate('Contact Support')}
                </ButtonBox>
            </ButtonAnchorBox>
        </ContainerBox>


    )
})