import {useContainerMediaQueriesChecks} from "../../../../hooks/use-container-media-queries-checks.hook";

export function useBagLargeIconSize(): number {
    const mediaQuery = useContainerMediaQueriesChecks();
    if(mediaQuery.xsAndBelow) {
        return 2.5;
    }
    if(mediaQuery.sAndBelow) {
        return 3;
    }

    return 3.5;
}