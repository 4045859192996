import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";

const PromotionalMessageBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: ${props => props.theme.colors.dangerTint};
    color: ${props => props.theme.colors.dangerContrast};
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.medium};
    padding: 2px ${props => props.theme.spacing.spacing16};
    border-radius: 20px;
    white-space: nowrap;
`


export const FarePromoMessageComponent: React.FC<{promoMessage: string}> = observer((props) => {

    return (
        <PromotionalMessageBox>
            {props.promoMessage}
        </PromotionalMessageBox>
    )
})