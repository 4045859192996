import styled, {css} from "styled-components";

export const BagsStepIndicationBox = styled.div<{$hasError: boolean}>`
    font-size: ${props => props.theme.fontSize.h6};
    ${props => props.$hasError
            ? css`
                color: ${props => props.theme.colors.danger};
            `
                
            : `
            `
    }
`