import {PaymentStepBase} from "../common/payment-step-base";
import {IRoute} from "../../../navigation/models/route.interface";
import {IManageMyBookingRoutes} from "../../../navigation/routes/manage-my-booking/manage-my-booking.routes.interface";

export class ManageMyBookingPaymentStep extends PaymentStepBase<IManageMyBookingRoutes> {

    protected get routes(): IManageMyBookingRoutes {
        return this.services.navigator.routes.manageMyBooking;
    }

    get route(): IRoute {
        return this.routes.payment;
    }

}
