import styled from "styled-components";
import {computeSpacerSize, SpacerSize} from "./spacer-size";
import React from "react";
import {observer} from "mobx-react-lite";
import {useServices} from "../../hooks/use-services.hook";


const HorizontalSpacerBox = styled.div<{$width: string}>`
  width: ${props => props.$width};
`

interface HorizontalSpacerProps {
    isVisible?: boolean;
    size?: SpacerSize;
    className?: string;
}

export const HorizontalSpacer: React.FC<HorizontalSpacerProps> = observer((props) => {
    const services = useServices();
    const isVisible = props.isVisible ?? true;
    if (!isVisible) {
        return null;
    }
    return (
        <HorizontalSpacerBox $width={computeSpacerSize(props.size, services.theme.currentTheme)}
                             className={props.className}/>
    );
});

