import React, {useState} from "react";
import {observer} from "mobx-react";
import {ExtrasSectionsRenderersFactory} from "./base-renderers/extras-sections-renderers-factory";
import {useServices} from "../../../hooks/use-services.hook";
import {ExtrasTilesComponent} from "./extras-tiles.component";

export const ExtrasPageContentComponent: React.FC = observer(() => {
    const services = useServices();
    const [sectionsFactory] = useState(new ExtrasSectionsRenderersFactory(services.booking.current));

    return (
        <ExtrasTilesComponent sectionsFactory={sectionsFactory}/>
    )
})