import {Wizard} from "../../../../models/wizard/wizard";
import {CheckInFlowFlightSelectionStep} from "../../steps/check-in-flow/check-in-flow-flight-selection.step";
import {CheckInFlowSeatsSelectionStep} from "../../steps/check-in-flow/check-in-flow-seats-selection.step";
import {CheckInFlowExtrasSelectionStep} from "../../steps/check-in-flow/check-in-flow-extras-selection.step";
import {CheckInFlowPassengersDetailsStep} from "../../steps/check-in-flow/check-in-flow-passengers-details.step";
import {CheckInFlowPaymentStep} from "../../steps/check-in-flow/check-in-flow-payment.step";
import {JourneyModel} from "../../models/journey/journey.model";
import {BookingStrategyBase} from "../booking-strategy-base";
import {CheckInFlowFinalPriceStep} from "../../steps/check-in-flow/check-in-flow-final-price.step";
import {ISsrType} from "../../../ssr-types/ssr-types.service.interface";
import {ANALYTICS_AFFILIATIONS} from "../analytics/analytics-affiliations";
import {IBookingStrategyAnalyticsConfiguration} from "../analytics/booking-strategy-analytics-configuration.interface";
import {BookingFlowTypeEnum} from "../booking-flow-type.enum";
import {IRoundTripLowFareReader} from "../../../low-fare/low-fare-readers/low-fare-reader.interface";
import {VoidRoundTripLowFareReader} from "../../../low-fare/low-fare-readers/void-low-fare-reader";
import {ShoppingCartModeEnum} from "../booking-strategy.interface";
import {ISessionStorageService} from "../../../storage/session-storage.service.interface";

import {CheckInFlowBoardingPassStep} from "../../steps/check-in-flow/check-in-flow-boarding-pass.step";
import {IRoute} from "../../../navigation/models/route.interface";
import {ValidationResultEnum} from "../../../../types/validation-result.enum";


export class CheckInStrategy extends BookingStrategyBase {

    get bookingFlowType(): BookingFlowTypeEnum {
        return BookingFlowTypeEnum.CheckIn;
    }


    protected _createSteps(sessionStorage: ISessionStorageService): Wizard {
        const wizard = new Wizard(this.services, 'checkinStrategy', sessionStorage);
        wizard.addStep(new CheckInFlowFlightSelectionStep(this.booking, wizard));
        wizard.addStep(new CheckInFlowSeatsSelectionStep(this.booking, wizard));
        //wizard.addStep(new CheckInFlowBagSelectionStep(this.booking, wizard));
        wizard.addStep(new CheckInFlowExtrasSelectionStep(this.booking, wizard));
        wizard.addStep(new CheckInFlowPassengersDetailsStep(this.booking, wizard));
        wizard.addStep(new CheckInFlowFinalPriceStep(this.booking, wizard));
        wizard.addStep(new CheckInFlowPaymentStep(this.booking, wizard));
        wizard.addStep(new CheckInFlowBoardingPassStep(this.booking, wizard));
        return wizard;
    }


    shouldFilterOutJourney(journey:JourneyModel): boolean {
        return  !journey.isJourneySelected || !journey.isOpenForCheckIn || !journey.isOnlineCheckInAllowed;
    }

    get allowedShoppingCartModes(): ShoppingCartModeEnum[] {
        if(this.initialBookingSnapshot.isGDSBooking) {
            return [ShoppingCartModeEnum.ShowNewPurchasesOnly];
        }
        return [ShoppingCartModeEnum.ShowNewPurchasesOnly, ShoppingCartModeEnum.ShowAllPurchases];
    }

    canShowSsr(ssrType: ISsrType): boolean {
        return ssrType.canBeShownOnCheckInFlow(this.booking);
    }

    get analyticsConfiguration(): IBookingStrategyAnalyticsConfiguration {
        return {
            affiliation: ANALYTICS_AFFILIATIONS.checkIn,
            shouldReportFares: false
        }
    }

    getLowFaresReader(): IRoundTripLowFareReader {
        return new VoidRoundTripLowFareReader();
    }

    getFinalPriceRoute(): IRoute {
        return this.services.navigator.routes.checkIn.finalPrice;
    }
    getPaymentRoute(): IRoute {
        return this.services.navigator.routes.checkIn.payment;
    }

    getFinalizePaymentRoute(): IRoute {
        return this.services.navigator.routes.checkIn.finalizePayment;
    }

    async onPaymentSuccess(): Promise<void> {
        if(ValidationResultEnum.Success === await this.booking.executeCheckIn({saveTravelDocuments: false})) {
            this.services.navigator.routes.checkIn.boardingPass.activate({
                allowBack: false
            });
        } else {
            await this.services.navigator.goHome();
        }
    }



}
