import React, {ChangeEvent} from "react";
import {observer} from "mobx-react";
import {IStandardDropDownListViewModel} from "../../models/list/standard-drop-down-list-view-model.interface";
import styled from "styled-components";
import {InputBaseBox} from "../../../../../../basic-components/input/input-base.box";
import {useScreenMediaQueriesChecks} from "../../../../../../hooks/use-screen-media-queries-checks.hook";

const InputBox = styled(InputBaseBox)`
`


interface StandardDropDownInputComponentProps<TFieldValue> {
    listViewModel: IStandardDropDownListViewModel<TFieldValue>
    captureInputRef: (element: HTMLInputElement) => void;
    placeHolder?: string;
    className?: string;
}

export const StandardDropDownInputComponent = observer(<TFieldValue extends any>(props: StandardDropDownInputComponentProps<TFieldValue>) => {
    const screenMediaQuery = useScreenMediaQueriesChecks();
    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        props.listViewModel.searchText = event.target.value ?? "";
    }

    const getInputPlaceHolder = () => {
        if(props.listViewModel.isDropDownOpen) {
            return props.listViewModel.selectedItem?.getDisplayText() ?? props.placeHolder ?? "";
        } else {
            return props.placeHolder ?? "";
        }
    }

    return <InputBox ref={props.captureInputRef}
                     autoComplete="off"
                     readOnly={screenMediaQuery.smallScreen}
                     value={props.listViewModel.searchText}
                     placeholder={getInputPlaceHolder()}
                     onChange={onInputChange}
                     className={props.className} />
});
