import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react-lite";
import {StandardDialogPageComponent} from "../../../modal/page/standard-dialog-page.component";
import {StandardDialogContentComponent} from "../../../modal/content/standard-dialog-content.component";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import { StandardDialogHeaderComponent } from "../../../modal/header/standard-dialog-header.component";
import styled from "styled-components";

const DialogContentBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap20};
`

interface ShoppingCartPriceDetailsDialogComponentProps extends PropsWithChildren {
    dialogHandler: IDialogConfirmationHandler;
    title: string;
}

export const ShoppingCartPriceDetailsDialogComponent: React.FC<ShoppingCartPriceDetailsDialogComponentProps> = observer((props) => {


    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={props.title} dialogHandler={props.dialogHandler} renderTitleOnTheFirstRow={true} />
            <StandardDialogContentComponent dialogHandler={props.dialogHandler}>
                <DialogContentBox>
                    {props.children}
                </DialogContentBox>
            </StandardDialogContentComponent>
        </StandardDialogPageComponent>
    );
});
