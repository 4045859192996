import React from "react";
import {observer} from "mobx-react";
import {InputContainerComponent} from "./input-container/input-container.component";
import {FieldInputContainerComponentProps, InputContainerComponentProps} from "./inputs-common.props";

export const FieldInputContainerComponent: React.FC<FieldInputContainerComponentProps> = observer(props => {

    if(props.field.isHidden) {
        return null;
    }

    const captureElementRef = (element: HTMLDivElement) => {
        props.field.attachContainerDOMElement(element);
        if(props.captureInputContainerRef) {
            props.captureInputContainerRef(element);
        }
    }

    const renderLabel = () => {
        if(props.renderLabel) {
            const renderLabel = props.renderLabel;
            return () => renderLabel();
        }

        return () => props.field.fieldName;
    }

    const inputContainerProps: InputContainerComponentProps = {
        ...props,
        captureInputContainerRef: captureElementRef,
        renderLabel: renderLabel(),
        errorMessage: props.field.error
    }

    return (
        <InputContainerComponent {...inputContainerProps}/>
    )
});
