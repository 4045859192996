import React from "react";
import {observer} from "mobx-react";
import {PenToSquareIcon} from "../../../../../assets/svg/pen-to-square.icon";
import styled from "styled-components";
import {useServices} from "../../../../../hooks/use-services.hook";

const ModifyButtonContainerBox = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${props => props.theme.fontSize.button};
    color: ${props => props.theme.colors.notesTint};
    gap: ${props => props.theme.spacing.flexGap8};
    background-color: inherit;
    cursor: pointer;
`

interface ManageMyBookingEditButtonComponentProps {
    label: string;
    onClick: () => void;
}
export const ManageMyBookingEditSectionButtonComponent: React.FC<ManageMyBookingEditButtonComponentProps> = observer((props) => {
    const services = useServices();
    return (
        <ModifyButtonContainerBox onClick={props.onClick}>
            <div>
                {props.label}
            </div>
            <PenToSquareIcon color={services.theme.currentTheme.colors.dark}/>
        </ModifyButtonContainerBox>
    )
})