import React from "react";
import {observer} from "mobx-react-lite";
import {
    ExtrasSectionLargeTileComponent
} from "../../../base-component/large-tile/extras-section.large-tile.component";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {ExtrasSeatsSectionComponentCommonProps} from "../extras-seats-section.component.common-props";
import {NullableString} from "../../../../../../types/nullable-types";


export interface ExtrasSeatsLargeTileComponentProps extends ExtrasSeatsSectionComponentCommonProps {
    isFirstTile: boolean;
    getMarketingMessage: () => NullableString;
    imageUrl: NullableString;
}

export const ExtrasSeatsLargeTileComponent: React.FC<ExtrasSeatsLargeTileComponentProps> = observer((props) => {
    const services = useServices();
    const booking = services.booking.current;

    const selectedSeatsCount = props.countSelectedSeats();


    return (
        <ExtrasSectionLargeTileComponent sectionTitle={services.language.translate('Seats')}
                                         renderFirstTileIcon={() => services.ssrTypes.STST.iconInExtrasFirstLargeTile}
                                         renderSecondTileIcon={() => services.ssrTypes.STST.iconInExtrasSecondLargeTile}
                                         modificationBlockingReason={null}
                                         numberOfSelectedServices={selectedSeatsCount}
                                         minDiscountedPrice={booking.minSeatsFee}
                                         minStandardPrice={booking.minSeatsFee}
                                         onEditButtonClick={props.onEditButtonClick}
                                         isFirstTile={props.isFirstTile}
                                         getMarketingMessage={props.getMarketingMessage}
                                         allowEdit={true}
                                         imageUrl={props.imageUrl}/>
    )
});
