import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../../../hooks/use-services.hook";
import {LanguageSwitcherComponent} from "../../../../components/language-switcher/language-switcher.component";
import {HybridAppVersionComponent} from "./hybrid-app-version.component";


const SideMenuFooterBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap20};
    padding: ${props => props.theme.spacing.pageContentVerticalPadding} 
             ${props => props.theme.spacing.pageContentHorizontalPadding}
             calc(env(safe-area-inset-bottom) + ${props => props.theme.spacing.pageContentVerticalPadding})
             ${props => props.theme.spacing.pageContentHorizontalPadding};
`

const TopSectionBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: ${props => props.theme.fontSize.h6};
    font-size: ${props => props.theme.fontSize.h6};
`


const CurrencyContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap8};
    opacity: 0.4;
`

const LabelBox = styled.div`
    color: ${props => props.theme.colors.primaryShade};
`

const CurrencyValueBox = styled.div`
    padding: 2px 10px;
    border: 1px solid ${props => props.theme.colors.dark};
    border-radius: 30px;
`

const LanguageSwitcherContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap8};
`



const BottomSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
`

export const HybridPageSideMenuFooterComponent: React.FC = observer(() => {
    const services = useServices();
    return (
        <SideMenuFooterBox>
            <TopSectionBox>
                <CurrencyContainerBox>
                    <LabelBox>
                        {services.language.translate('Currency')}
                    </LabelBox>
                    <CurrencyValueBox>
                        {services.currency.current}
                    </CurrencyValueBox>
                </CurrencyContainerBox>

                <LanguageSwitcherContainerBox>
                    <LabelBox>
                        {services.language.translate('Language')}
                    </LabelBox>
                    <LanguageSwitcherComponent/>
                </LanguageSwitcherContainerBox>
            </TopSectionBox>

            <BottomSectionBox>
               <HybridAppVersionComponent/>
            </BottomSectionBox>

        </SideMenuFooterBox>
    );
})