import React from "react";
import {observer} from "mobx-react";
import {
    FlightsDatesSelectorModel
} from "../../../../services/flight-search/dates-selection/flights-dates-selector.model";
import styled from "styled-components";
import {PlaneGoIcon} from "../../../../assets/svg/plane-go.icon";
import {useServices} from "../../../../hooks/use-services.hook";

const ContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    min-height: 100px;
    height: 100%;
    width: 100%;
    text-align: center;
    gap: ${props => props.theme.spacing.spacing16};
    padding: ${props => props.theme.spacing.spacing20};
    
    
`

const StationsBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${props => props.theme.fontSize.body2};
    gap: ${props => props.theme.spacing.spacing8};
`

const ErrorBox = styled.div`
    color: ${props => props.theme.colors.danger};
    font-size: ${props => props.theme.fontSize.h4};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

const FooterBox = styled.div`
    color: ${props => props.theme.colors.medium};
    font-size: ${props => props.theme.fontSize.h6};
    font-weight: ${props => props.theme.fontWeight.medium};
`

export const ScheduleLoadingErrorComponent: React.FC<{flightsDatesSelector: FlightsDatesSelectorModel;}> = observer((props) => {
    const services = useServices();
    const datesSelector = props.flightsDatesSelector;
    const origin = datesSelector.flightSearchController.departureOrigin;
    const destination = datesSelector.flightSearchController.departureDestination;

    const renderStations = () => {
        if(!origin || !destination) {
            return null;
        }

        return (
            <StationsBox>
                <div>
                    {`${origin.stationName} (${origin.stationCode})`}
                </div>
                <PlaneGoIcon size={1}/>
                <div>
                    {`${destination.stationName} (${destination.stationCode})`}
                </div>
            </StationsBox>
        )
    }

    return (
        <ContainerBox>
            {renderStations()}

            <ErrorBox>
                {datesSelector.scheduleLoadingError}
            </ErrorBox>

            <FooterBox>
                {services.language.translate('Please select different airports')}
            </FooterBox>

        </ContainerBox>
    )
})