import {BookingStepBase} from "./booking-step.base";
import {IOnBeforeActivationContext, IWizardStep} from "../../../../models/wizard/wizard.step.interface";
import {waitForBookingMutations} from "../../models/mutation-actions/wait-for-booking-mutations";
import {DialogResult} from "../../../dialog/dialog-enums";
import {PrePaymentOperations} from "./pre-payment-operations";

export abstract class PaymentStepBase<TRoutes> extends BookingStepBase {
    protected abstract get routes(): TRoutes;

    get title(): string {
        return this.services.language.translate('Payment');
    }

    get allowSwipeForward(): boolean {
        return false;
    }

    async next(): Promise<void> {

        this.services.analytics.paymentEvents.logAddPaymentInfo(this.booking)
        await this.booking.paymentHandler.payBooking();
    }

    get isVisible(): boolean {
        return this.booking.balanceDue.amount > 0 || this.booking.voucher.isApplied;
    }

    acceptActivation(): boolean {
        return super.acceptActivation() && this.booking.travelConditionsAccepted;
    }

    protected async _onBeforeActivation(context: IOnBeforeActivationContext): Promise<DialogResult> {
        if(context.routingGuardContext.isForwardNavigation && this.booking.bookingStrategyAdapter.hideFinalPriceStep) {
            const operations = new PrePaymentOperations(this.booking, {loadPaymentMethodsAsync: false});
            await operations.executePrepaymentOperations();
        }
        return await super._onBeforeActivation(context);
    }

    protected async _onActivated(previousStep: IWizardStep | null): Promise<void> {
        await waitForBookingMutations(this.booking);


        return super._onActivated(previousStep);
    }


}
