import React, {useState} from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import styled from "styled-components";
import {IInvoiceViewModel} from "../../../services/booking/models/invoice/invoice-view-model.interface";
import {IonToggle, ToggleChangeEventDetail} from "@ionic/react";
import {IonToggleCustomEvent} from "@ionic/core/dist/types/components";
import {DialogResult} from "../../../services/dialog/dialog-enums";

const ContainerBox = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    column-gap: ${props => props.theme.spacing.flexGap16};
    width: 100%;
`


const LabelBox = styled.div`
    font-weight: ${props => props.theme.fontWeight.bold};
`

const RequestInvoiceNoteBox = styled.div`
    grid-column: 2/3;    
`

export const RequestInvoiceToggleComponent: React.FC<{invoice: IInvoiceViewModel}> = observer((props) => {
    const services = useServices();
    const [toggleState, setToggleState] = useState(props.invoice.invoiceRequested);

    const onToggleChanged = async (event: IonToggleCustomEvent<ToggleChangeEventDetail>) => {

        setToggleState(event.detail.checked);

        if(event.detail.checked) {
            const dialogResult = await props.invoice.showInvoiceDialog();
            props.invoice.invoiceRequested = (dialogResult === DialogResult.Accepted);
        } else {
            props.invoice.invoiceRequested = false;
        }

        setToggleState(props.invoice.invoiceRequested);
    }

    return (

        <ContainerBox >
            <IonToggle checked={toggleState}
                       onIonChange={onToggleChanged}/>
            <LabelBox>
                {services.language.translate('Request invoice')}
            </LabelBox>

            <RequestInvoiceNoteBox>
                {services.language.translate('The invoice must be requested at the same time as the purchase.')}
            </RequestInvoiceNoteBox>


        </ContainerBox>
    );
});
