import React from 'react';
import {observer} from "mobx-react-lite";
import {IonIcon} from "@ionic/react";
import styled from "styled-components";
import {chevronBack, chevronBackOutline, chevronForward, chevronForwardOutline} from "ionicons/icons";
import classNames from "classnames";
import {v4 as uuidv4} from "uuid";
import {
    SWIPER_NAV_BUTTON_CSS_CLASS, SWIPER_NAV_BUTTON_DISABLED_CSS_CLASS,
    SWIPER_NAV_BUTTON_NEXT_CSS_CLASS,
    SWIPER_NAV_BUTTON_PREV_CSS_CLASS
} from "./swiper-consts";

export type SwiperNavigationButtonSize = "small" | "medium" | "large" | "extraLarge";

export const SWIPER_BUTTON_HIDDEN_CLASS_NAME = 'swiper-button-hidden';

function computeNavigationButtonFontSize(size: SwiperNavigationButtonSize): string {
    switch (size) {
        case "small":
            return "16px";
        case "medium":
            return "32px";
        case "large":
            return "48px"
        case "extraLarge":
            return "64px"
    }
}

export function createSwiperPrevButtonSelector(): string {
    return `prevBut_${uuidv4()}`;
}

export function createSwiperNextButtonSelector(): string {
    return `nextBut_${uuidv4()}`;
}

const SwiperButtonBox = styled.button<{size: SwiperNavigationButtonSize}>`
  
  font-size: ${props => computeNavigationButtonFontSize(props.size)};
  cursor: pointer;
  background-color: inherit;
  color: ${props => props.theme.colors.lightContrast};
  &.${SWIPER_NAV_BUTTON_DISABLED_CSS_CLASS} {
    color: ${props => props.theme.colors.mediumTint};
  }
  
  &.${SWIPER_BUTTON_HIDDEN_CLASS_NAME} {
    display: none;
  }
 
`

interface SwiperNavigationButtonComponentProps {
    ios: string;
    md: string;
    selector: string;
    size: SwiperNavigationButtonSize;
    isHidden?: boolean;
    className?: string;
    onClick?: () => void;
}

const SwiperNavigationButtonComponent: React.FC<SwiperNavigationButtonComponentProps> = observer((props) => {

    const cssClasses = classNames(props.selector, props.className, SWIPER_NAV_BUTTON_CSS_CLASS, {
        [SWIPER_BUTTON_HIDDEN_CLASS_NAME]: Boolean(props.isHidden)
    });

    return (
        <SwiperButtonBox className={cssClasses}
                         size={props.size}
                         onClick={props.onClick}>
            <IonIcon ios={props.ios} md={props.md}/>
        </SwiperButtonBox>
    );
});




interface SwiperNavigationConcreteButtonComponentProps extends Omit<SwiperNavigationButtonComponentProps, 'ios' | 'md'> {

}

const SwiperNavigationBackButtonBox = styled(SwiperNavigationButtonComponent)`
    
`

export const SwiperNavigationBackButtonComponent: React.FC<SwiperNavigationConcreteButtonComponentProps> = observer((props) => {

    const cssClasses = classNames(props.className, SWIPER_NAV_BUTTON_PREV_CSS_CLASS);

    return (<SwiperNavigationBackButtonBox {...props} className={cssClasses}
                                             ios={chevronBackOutline}
                                             md={chevronBack}/>)
});

const SwiperNavigationNextButtonBox = styled(SwiperNavigationButtonComponent)`
    
`


export const SwiperNavigationNextButtonComponent: React.FC<SwiperNavigationConcreteButtonComponentProps> = observer((props) => {

    const cssClasses = classNames(props.className, SWIPER_NAV_BUTTON_NEXT_CSS_CLASS);
    return (<SwiperNavigationNextButtonBox {...props} className={cssClasses}
                                             ios={chevronForward}
                                             md={chevronForwardOutline}/>)
});

