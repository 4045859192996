import {BookingModel} from "../booking.model";
import {IPassengerType} from "../../../passenger-types/passengers-types.service.interface";
import {
    DotRezPassengerAddressStatusApi,
    DotRezPassengerAddressStatusGraphQL,
    IDotRezBookingPassenger,
    IDotRezPassengerAddressGraphQL,
    IDotRezPassengerInfo,
    IDotRezPassengerName,
    IDotRezPassengerTravelDocument
} from "../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {InfantPassengerModel} from "./infant-passenger.model";
import {IMaturePassengerViewModel} from "./mature-passenger-view-model.interface";
import {computed, makeObservable, observable, runInAction} from "mobx";
import {
    IDotRezUpdatePassengerDetailsRequest
} from "../../../dot-rez-api/data-contracts/requests/booking/update-passenger-details.request";
import {Price} from "../../../currency/price";
import {PassengerSegmentModel} from "../passenger-segment/passenger-segment.model";
import {PassengerModelBase} from "./passenger-model-base";
import {ICheckInRestriction} from "../check-in-restrictions/check-in-restriction.interface";
import {TravelDocumentValidationRestriction} from "../check-in-restrictions/travel-document-validation-restriction";
import {SegmentModel} from "../segment/segment.model";
import {PassengerSegmentSsrEditorModel} from "../ssrs/passenger-segment-ssr-editor.model";
import {ISsrTypeCount} from "../ssrs/ssr-type-count.interface";
import {FeeTypeEnum} from "../../../dot-rez-api/data-contracts/enums/fee-type.enum";
import {NullableString} from "../../../../types/nullable-types";
import {FormFields, IFormField} from "../../../../models/forms/form-field.interface";
import {IPassengerInfoFields} from "./passenger-info-fields.interface";
import {PassengerFeeModel} from "../fees/passenger/passenger-fee.model";
import {PassengerNameChangeMutation} from "../mutation-actions/name-change/passenger-name-change.mutation";
import {JourneyModel} from "../journey/journey.model";
import {BookingSessionStorageKeys} from "../storage/booking-storage.interface";
import {IActivateErrorsValidationOptions} from "../../../../models/forms/form-model.interface";


export class MaturePassengerModel extends PassengerModelBase implements IMaturePassengerViewModel {
    constructor(passengerKey: string,
                public readonly passengerIndex: number,
                booking: BookingModel) {
        super(passengerKey, booking);

        this._isPrimaryContact = this.booking.storage.getItem(BookingSessionStorageKeys.primaryContact) === passengerKey;

        if(this.data.infant) {
            this.infant = new InfantPassengerModel(this, () => this.data, () => this.dataOnPreviousSession);
        }

        makeObservable<this, '_isPrimaryContact'>(this, {
            _isPrimaryContact: observable.ref,
            fees: computed,
            passengerTypeIndex: computed,
            filteredPassengerSegments: computed,
            unfilteredPassengerSegments: computed,
            hasPerformedCheckInOnAnySegment: computed
        });
    }

    get discountCode(): string {
        return this.data.discountCode;
    }

    readonly infant: InfantPassengerModel | null = null;

    private get dataOnPreviousSession(): IDotRezBookingPassenger | null {
        if(!this.booking.initialBookingSnapshot) {
            return null;
        }

        for(let passengerData of this.booking.initialBookingSnapshot.bookingData.passengers) {
            if(passengerData.key === this.passengerKey) {
                return passengerData.value;
            }
        }
        return null;
    }

    private get data(): IDotRezBookingPassenger {
        for(let passengerData of this.booking.bookingData.passengers) {
            if(passengerData.key === this.passengerKey) {
                return passengerData.value;
            }
        }
        throw new Error(`There is no passenger with key ${this.passengerKey}`);
    }

    get passengerType(): IPassengerType {
        return this.booking.services.passengerTypes.getPassengerType(this.data.passengerTypeCode);
    }

    get passengerTypeIndex(): number {
        return this.booking.passengers
                .filter(p => p.passengerType.description === this.passengerType.description)
                .findIndex(p => p.passengerKey === this.passengerKey);
    }

    get canHaveSpecialAssistance(): boolean {
        return true;
    }

    private _isPrimaryContact: boolean;
    get isPrimaryContact(): boolean {
        if(this.booking.shouldSyncContactWithPrimaryPassenger) {
            return this._isPrimaryContact;
        }

        return false;
    }

    set isPrimaryContact(value: boolean) {
        if(this.isPrimaryContact === value) {
            return;
        }

        if(value) {
            const currentPrimaryContactPassenger = this.booking.passengers.find(p => p.passengerKey !== this.passengerKey && p.isPrimaryContact);
            if(currentPrimaryContactPassenger) {
                currentPrimaryContactPassenger.isPrimaryContact = false;
            }

            this.booking.storage.setItem(BookingSessionStorageKeys.primaryContact, this.passengerKey);

            if(currentPrimaryContactPassenger) {
                this.booking.contact.fields.emailAddress.setValue(null);
                this.booking.contact.fields.countryCode.setValue(null);
                this.booking.contact.fields.phoneNumber.setValue(null);
            }

        } else if(this.isPrimaryContact) {
            this.booking.storage.setItem(BookingSessionStorageKeys.primaryContact, 'none');
        }

        runInAction(() => {
            this._isPrimaryContact = value;
        });
    }

    get nameOnPreviousSession(): IDotRezPassengerName | null {
        return this.dataOnPreviousSession?.name ?? null;
    }

    get name(): IDotRezPassengerName {
        if(!this.data.name) {
            this.data.name =  {
                title: null,
                first: null,
                last: null
            }
        }
        return this.data.name;
    }

    get infoOnPreviousSession(): IDotRezPassengerInfo | null {
        return this.dataOnPreviousSession?.info ?? null;
    }


    get info(): IDotRezPassengerInfo {
        return this.data.info;
    }

    get travelDocuments(): IDotRezPassengerTravelDocument[] {
        if(!this.data.travelDocuments) {
            runInAction(() => {
                this.data.travelDocuments = [];
            });
        }
        return this.data.travelDocuments;
    }

    get addressesOnPreviousSession(): IDotRezPassengerAddressGraphQL[] {
        return this.dataOnPreviousSession?.addresses ?? [];
    }

    get addresses(): IDotRezPassengerAddressGraphQL[] {
        if (this.data.addresses) {
            return this.data.addresses;
        }
        return [];
    }

    private _extractPassengersSegmentsFromJourneys(journeys: JourneyModel[]) : PassengerSegmentModel[] {
        const result: PassengerSegmentModel[] = [];

        for(let journey of journeys) {
            for(let segment of journey.segments) {
                for(let passengerSegment of segment.passengers) {
                    if(passengerSegment.passengerKey === this.passengerKey) {
                        result.push(passengerSegment);
                    }
                }
            }
        }

        return result;
    }

    get filteredPassengerSegments(): PassengerSegmentModel[] {
        return this._extractPassengersSegmentsFromJourneys(this.booking.filteredJourneys);
    }


    get unfilteredPassengerSegments(): PassengerSegmentModel[] {
        return this._extractPassengersSegmentsFromJourneys(this.booking.unfilteredJourneys);
    }

    get hasPerformedCheckInOnAnySegment(): boolean {
        return this.unfilteredPassengerSegments.some(passengerSegment => passengerSegment.isCheckedIn);
    }

    commitChanges() {
        super.commitChanges();

        runInAction(() => {
            if(!this.hasPerformedCheckInOnAnySegment) {
                this.name.first = this.fields.firstName.value?.toUpperCase()?.trim() || null;
                this.name.last = this.fields.lastName.value?.toUpperCase()?.trim() || null;
                this.name.title = this.computeTitle();
                this.data.customerNumber = this.fields.customerNumber.value;
            }

            this.data.info.dateOfBirth = this.services.time.formatBirthDate(this.fields.dateOfBirth.value) || null;
            this.data.info.gender = this.fields.gender.value;
            this.data.info.nationality = this.fields.nationality.value;
        });
    }

    activateErrorsValidation(options?: IActivateErrorsValidationOptions): IFormField[] {
        const errors = super.activateErrorsValidation(options);

        if(this.isEligibleForPsoMarketDiscount) {
            return [
                ...errors,
                ...this.travelDocument.activateErrorsValidation(options)
            ];
        }

        return errors;
    }

    private async _updatePassengerDetails(): Promise<void> {
        if(!this.hasChanges()) {
            return;
        }

        const updatePassengerRequest: Partial<IDotRezUpdatePassengerDetailsRequest> = {
            info: {
                gender: this.fields.gender.value,
                nationality: this.fields.nationality.value,
                dateOfBirth: this.services.time.formatBirthDate(this.fields.dateOfBirth.value) || null
            }
        }

        if(!this.hasPerformedCheckInOnAnySegment) {
            updatePassengerRequest.customerNumber = this.customerNumber;
            updatePassengerRequest.discountCode = this.discountCode;
            updatePassengerRequest.name = {
                title: this.computeTitle(),
                last: this.fields.lastName.value?.toUpperCase()?.trim() || null,
                first: this.fields.firstName.value?.toUpperCase()?.trim() || null
            }
        }

        await this.booking.session.updatePassengerDetails(this.passengerKey, updatePassengerRequest);

        if(this.isEligibleForPsoMarketDiscount) {
            await this._saveAddress();
        }

        this.commitChanges();
    }

    async savePassengerDetails(): Promise<void> {
        await this._updatePassengerDetails();

        if(this.isEligibleForPsoMarketDiscount) {
            await this.travelDocument.saveTravelDocument();
        }
    }

    private async _saveAddress(): Promise<void> {
        if(this.addresses.length === 0) {

            const addressKey = await this.booking.session.addPassengerAddress(this.passengerKey, {
                status: DotRezPassengerAddressStatusApi.Contact,
                city: this.fields.addressCity.value,
                lineThree: this.fields.psoPassengerType.value

            });
            runInAction(() => {
                this.addresses.push({
                    passengerAddressKey: addressKey,
                    city: this.fields.addressCity.value,
                    status: DotRezPassengerAddressStatusGraphQL.Contact
                });
            })
        } else {
            await this.booking.session.updatePassengerAddress(this.passengerKey, {
                passengerAddressKey: this.addresses[0].passengerAddressKey,
                city: this.fields.addressCity.value,
                lineThree: this.fields.psoPassengerType.value
            });
        }
    }

    async saveTravelDocument(travelDocument: IDotRezPassengerTravelDocument): Promise<NullableString> {

        await this._updatePassengerDetails();

        if(this.isOnDomesticFlight && !this.isEligibleForPsoMarketDiscount) {
            return null;
        }

        let saveTravelDocRequest: Partial<IDotRezPassengerTravelDocument> = travelDocument;
        if(this.hasPerformedCheckInOnAnySegment) {
            saveTravelDocRequest = {
                ...saveTravelDocRequest,
                name: this.name // if the  passenger is checked in we keep the same values for the name for the document because dotREZ doesn't allow changing this for checked in passengers
            }
        }

        const {passengerTravelDocumentKey} = await this.booking.session.savePassengerTravelDocument(this.passengerKey, saveTravelDocRequest);
        return passengerTravelDocumentKey;
    }

    getUserDependentCheckInBlockingRestrictions(): ICheckInRestriction[] {
        const restrictions: ICheckInRestriction[] = [];
        const language = this.services.language;
        if(this.travelDocument.hasErrors()) {
            restrictions.push(new TravelDocumentValidationRestriction(language.translate('Invalid travel document information')));
        }

        if(this.infant?.travelDocument.hasErrors()) {
            restrictions.push(new TravelDocumentValidationRestriction(language.translationFor('Infant passenger {infantName} assigned to this passenger has invalid travel document information').withParams({infantName: this.infant.getFullName()})));
        }
        return restrictions;
    }

    getUserIndependentCheckInBlockingRestrictions(): ICheckInRestriction[] {
        return [];
    }

    get fees(): PassengerFeeModel[] {
        return this.data.fees.map(f => new PassengerFeeModel(f, this));
    }

    getSpoilageFee(): PassengerFeeModel | null {
        return this.fees.find(f => f.feeType === FeeTypeEnum.SpoilageFee) || null;
    }

    getBundleFee(segment: SegmentModel): Price {
        const fee = this.fees.find(f => f.feeType === FeeTypeEnum.ServiceBundle && (f.flightReference === segment.flightReference));
        if(fee) {
            return fee.totalToDisplay;
        }
        return this.booking.createPrice(0);
    }

    getSsrsCountForJourney(journeyKey: string): ISsrTypeCount[] {
        const journey = this.booking.filteredJourneys.find(j => j.journeyKey === journeyKey);
        if(!journey) {
            return [];
        }

        let journeySsrsCount: ISsrTypeCount[] = [];
        const passengerSegments = journey.getAllPassengersSegments().filter(ps => ps.passengerKey === this.passengerKey);

        const allPassengerJourneySsrsGroupedBySsrCode = passengerSegments.reduce((allSsr: PassengerSegmentSsrEditorModel[], passengerSegment) =>
                                        allSsr.concat(passengerSegment.getSoldSsrsEditors()), [])
            .groupByKey(passengerSegmentSsr => passengerSegmentSsr.ssrType.ssrCode);


        Object.keys(allPassengerJourneySsrsGroupedBySsrCode).forEach(ssrCode => {
            const ssrItems = allPassengerJourneySsrsGroupedBySsrCode[ssrCode];

            if(ssrItems[0].ssrType.shouldBeSoldPerSegment) {
                journeySsrsCount.push({
                    ssrType: ssrItems[0].ssrType,
                    count: ssrItems.sum(item => item.newQuantityToDisplay)
                });
            } else {
                journeySsrsCount.push({
                    ssrType: ssrItems[0].ssrType,
                    count: ssrItems[0].newQuantityToDisplay
                });
            }
        });

        journeySsrsCount = journeySsrsCount.sort((s1, s2) => {
            if(s1.ssrType.preferredOrderInLists < s2.ssrType.preferredOrderInLists) {
                return -1
            }
            return 1;
        });

        return this.services.ssrTypes.filterVisibleSsrs(journeySsrsCount);
    }

    protected _getInitialCustomerNumber(): NullableString {
        return this.data.customerNumber;
    }

    protected _onFieldsCreated(fields: FormFields<IPassengerInfoFields>) {
        super._onFieldsCreated(fields);
        fields.firstName.onChange(value => {
            if(this.isPrimaryContact) {
                this.booking.contact.fields.firstName.setValue(value);
            }
        });

        fields.lastName.onChange(value => {
            if(this.isPrimaryContact) {
                this.booking.contact.fields.lastName.setValue(value);
            }
        })
    }

    markAsBlueBenefitsPassenger(): void  {
        this.companion.attachCompanion(this.services.user.profile.asCompanion());
    }

    get nameChangeBlockingReason(): NullableString {
        if(this.booking.passengersNamesChangeBlockingReason) {
            return this.booking.passengersNamesChangeBlockingReason;
        }

        if(this.booking.blueBenefits.isBookingWithBlueBenefits && this.passengerIndex === 0) {
            return this.services.language.translate('Name cannot be changed for the BlueBenefits subscription holder!');
        }

        if (this.hasPerformedCheckInOnAnySegment) {
            return this.services.language.translate('Name cannot be changed for checked-in in passengers!');
        }

        return null;
    }

    protected _startNameChangeMutation() {
        this.booking.mutationsManager.startMutation(new PassengerNameChangeMutation(this, {
            onCompleted: () => {
                this.companion.detachCompanion();
                this.commitChanges();
            }
        }));
    }



}
