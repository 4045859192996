import React from "react";
import {observer} from "mobx-react";
import {RoutedPageComponent} from "../../../basic-components/page/routed-page/routed-page.component";
import {StandardPageHeaderComponent} from "../../../basic-components/page/standard-page-header/standard-page-header.component";
import {useServices} from "../../../hooks/use-services.hook";
import {StandardPageContentComponent} from "../../../basic-components/page/standard-page-content/standard-page-content.component";
import {UserProfileViewComponent} from "./components/user-profile-view.component";
import {StandardPageFooterComponent} from "../../../basic-components/page/standard-page-footer/standard-page-footer.component";
import {EditButtonComponent} from "../../../components/forms-view/buttons/edit-button.component";
import {SpinnerComponent} from "../../../components/spinner/spinner.component";
import {ExternalLinkComponent} from "../../../components/external-link/external-link.component";
import styled from "styled-components";

const RemoveMyAccountLinkBox = styled(ExternalLinkComponent)`
    display: block;
    align-content: center;
    text-align: center;
    margin: 8px 0 15px 0;
    width: 100%;
    font-size: ${props => props.theme.fontSize.body2};
    
`

export const UserProfilePage: React.FC = observer(() => {
    const services = useServices();

    const userProfile = services.user.profile;

    const renderPageContent = () => {

        if(!userProfile.isProfileInitialized) {
            return (
                <SpinnerComponent/>
            );
        }

        return (<UserProfileViewComponent userProfileEditor={userProfile.getEditor()} />)
    }

    const renderPageFooter = () => {
        if(!userProfile.isProfileInitialized) {
            return null;
        }

        const onEditAccount = async () => {
            await userProfile.getEditor().edit();
        }

        return (
            <StandardPageFooterComponent>
                <EditButtonComponent text={services.language.translate('Edit your profile')}
                                     onClick={onEditAccount}/>
                {services.device.isIOS && <RemoveMyAccountLinkBox innerHtml={services.externalLinks.removeMyAccount}/>}
            </StandardPageFooterComponent>
        );
    }

    return (
        <RoutedPageComponent>
            <StandardPageHeaderComponent renderTopMiddleSection={() => services.language.translate('My Profile')}
                                         hideLogo={true}/>
            <StandardPageContentComponent>
                {renderPageContent()}
            </StandardPageContentComponent>
            {renderPageFooter()}
        </RoutedPageComponent>
    )
});
