import {ITravelDocumentTypeService} from "./travel-document-type.service.interface";
import {TravelDocumentTypeModel} from "./travel-document-type.model";
import {ServiceBase} from "../service-base";
import {NullableString, NullableUndefinedString} from "../../types/nullable-types";
import {IServiceFactory} from "../service-factory.interface";

export class TravelDocumentTypeService extends ServiceBase implements ITravelDocumentTypeService {
    constructor(services: IServiceFactory) {
        super(services);
        this._allDocumentTypes =  [
            new TravelDocumentTypeModel(this.passportCode, () => this.services.language.translate('Passport')),
            new TravelDocumentTypeModel(this.identityCardCode, () => this.services.language.translate('Identity card'))
        ];
    }

    readonly passportCode: string = 'P';
    readonly identityCardCode: string = 'I';

    private readonly _allDocumentTypes: TravelDocumentTypeModel[];
    getAllDocumentTypes(): TravelDocumentTypeModel[] {
        return this._allDocumentTypes;
    }

    getDocumentTypesForPsoMarket(): TravelDocumentTypeModel[] {
        return this.getAllDocumentTypes().filter(d => d.value === this.identityCardCode);
    }

    getDocumentType(code: string): TravelDocumentTypeModel {
        const docType = this.getAllDocumentTypes().find(d => d.value === code);
        if(docType) {
            return docType;
        }
        throw new Error(`Invalid document type code ${code}`);
    }

    tryGetDocumentTypeName(code: NullableUndefinedString): NullableString {
        if(!code) {
            return "";
        }

        const docType = this.getAllDocumentTypes().find(d => d.value === code);
        if(docType) {
            return docType.text;
        } else {
            return "";
        }
    }
}
