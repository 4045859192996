import React from "react";
import {observer} from "mobx-react";
import {FlightsDatesSelectorModel} from "../../../../services/flight-search/dates-selection/flights-dates-selector.model";
import {FlightDayComponent} from "../calendar/flight-day.component";
import {MonthModel} from "../../../../services/time/month.model";
import {CalendarComponent} from "../../../calendar/calendar.component";
import styled from "styled-components";

const ContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap30};
    background-color: ${props => props.theme.colors.cardBackground};
    color: ${props => props.theme.colors.cardColor};
`

interface FlightDatesSelectorListComponentProps {
    captureSelectedDateElementRef?: (date: Date, element: HTMLElement | null) => void;
    onDateSelected: (date: Date) => void;
    flightsDatesSelector: FlightsDatesSelectorModel;
}
export const FlightDatesSelectorListComponent: React.FC<FlightDatesSelectorListComponentProps> = observer((props) => {
    const datesSelector = props.flightsDatesSelector;
    const months = datesSelector.availableMonths;

    if(months.length === 0) {
        return null;
    }

    const renderDate = (date: Date, isInViewPort: boolean) => {
        return (
            <FlightDayComponent date={date}
                                captureSelectedDateElementRef={props.captureSelectedDateElementRef}
                                shouldRenderPrice={isInViewPort}
                                flightsDatesSelector={props.flightsDatesSelector}/>
        )
    }

    const renderOneCalendar = (month: MonthModel) => {
        return (
            <CalendarComponent key={month.key}
                               month={month.month}
                               year={month.year}
                               renderDate={(date, isInViewPort) => renderDate(date, isInViewPort)}
                               onDateClick={props.onDateSelected}/>
        );
    }

    return (<ContainerBox>
        {
            months.map(renderOneCalendar)
        }
    </ContainerBox>);

})