import React, {CSSProperties} from "react";
import {observer} from "mobx-react";
import {ISegmentViewModel} from "../../../services/booking/models/segment/segment-view-model.interface";
import {useServices} from "../../../hooks/use-services.hook";
import {Station} from "../../../services/stations/station.service.interface";
import {TicketSegmentFlightDetailsComponent} from "./ticket-segment-flight-details.component";



const StationNameBox: React.FC<{stationName: string}> = observer((props) => {
    const services = useServices();
    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: "center",
        fontSize: services.theme.currentTheme.fontSize.h2,
        fontWeight: services.theme.currentTheme.fontWeight.bold,
        width: '100%',
        textAlign: 'center'
    };

    return (
        <div style={styles}>
            {props.stationName}
        </div>
    );
})

const StationCodeBox: React.FC<{stationCode: string}> = observer((props) => {
    const services = useServices();
    const styles: CSSProperties = {
        fontSize: services.theme.currentTheme.fontSize.h2,
        fontWeight: services.theme.currentTheme.fontWeight.bold
    };

    return (
        <div style={styles}>
            {props.stationCode}
        </div>
    );
})

const DepartureDateAndTime: React.FC<{departureDate: string; departureTime: string}> = observer((props) => {
    const services = useServices();
    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: services.theme.currentTheme.spacing.flexGap12
    };

    return (
        <div style={styles}>
            <span>{props.departureDate}</span>
            <span>|</span>
            <span>{props.departureTime}</span>
        </div>
    )
})

const StationComponent: React.FC<{station: Station; departureDate: string; departureTime: string}> = observer((props) => {

    const services = useServices();
    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: services.theme.currentTheme.spacing.flexGap12
    };

    return (
        <div style={styles}>
            <StationNameBox stationName={props.station.stationName}/>
            <StationCodeBox stationCode={props.station.stationCode}/>
            <DepartureDateAndTime departureDate={props.departureDate} departureTime={props.departureTime}/>
        </div>
    )
})


export const TicketSegmentDetailsComponent: React.FC<{segment: ISegmentViewModel}> = observer((props) => {
    const services = useServices();
    const rootStyles: CSSProperties = {
        pageBreakInside: "avoid",
        breakInside: "avoid"
    }

    const gridStyles: CSSProperties = {
        display: 'grid',
        gridTemplateColumns: '1fr 3fr 1fr',
        padding: `${services.theme.currentTheme.spacing.cardDefaultPadding}`,
        backgroundColor: services.theme.currentTheme.colors.light,
        border: `1px solid ${services.theme.currentTheme.border.mainColor}`,
        borderRadius: services.theme.currentTheme.border.defaultRadius
    };


    return (
        <div style={rootStyles}>
            <div style={gridStyles}>
                <StationComponent station={props.segment.designator.origin}
                                  departureDate={props.segment.designator.formatDepartureDate()}
                                  departureTime={props.segment.designator.formatDepartureTime()}/>
                <TicketSegmentFlightDetailsComponent segment={props.segment}/>
                <StationComponent station={props.segment.designator.destination}
                                  departureDate={props.segment.designator.formatArrivalDate()}
                                  departureTime={props.segment.designator.formatArrivalTime()}/>
            </div>
        </div>
    )
})