import styled, {css} from "styled-components";
import {IonHeader} from "@ionic/react";

export const StandardPageHeaderBox = styled(IonHeader)<{$addBottomPadding: boolean}>`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: calc(env(safe-area-inset-top) + ${props => props.theme.spacing.pageHeaderVerticalPadding}) ${props => props.theme.spacing.pageHeaderHorizontalPadding} 0 ${props => props.theme.spacing.pageHeaderHorizontalPadding};
    background-color: ${props => props.theme.colors.pageHeaderBackground};
    color: ${props => props.theme.colors.pageHeaderColor};
    ${props => props.$addBottomPadding 
            ? css`
                padding-bottom: ${props => props.theme.spacing.pageHeaderVerticalPadding};
            `
            :''
    }
`