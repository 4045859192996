export const SEAT_MAPS_QUERY = `
    seatMapsv2(request: {includePropertyLookup: true}) {
        seatMap {
          departureStation,
          arrivalStation,
          equipmentType,
          equipmentTypeSuffix,
          decks {
            key,
            value {
              compartments {
                key,
                value {
                  units {
                    unitKey,
                    availability,
                    designator,
                    type,
                    group,
                    properties {
                      code,
                      value
                    }
                  }
                }
              }
            }
          }
        }
        fees {
          key,
          value {
            passengerKey,
            groups {
              key,
              value {
                group,
                fees {
                  serviceCharges {
                    type,
                    amount
                  }
                }
              }
            }
          }
        }
      }
`
