import React from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {renderInputError, renderInputIcon, renderInputLabel} from "./input-container.helpers";
import {InputContainerComponentProps} from "../inputs-common.props";


const RootContainerBox = styled.div<{$hasError: boolean}>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${props => props.theme.fontSize.h6};
    background-color: inherit;
    color: inherit;
    width: 100%;
    
    
    ${props => props.$hasError
            ? css`
                color: ${props => props.theme.colors.danger};
            `
            : ``
    }
    
`


const ContentSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    width: 100%;
`

export const InputAndIconContainerBox = styled.div<{$hasError: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 6px;
    width: 100%;
    //min-height: 34px;
    
    border-radius: ${props => props.theme.border.defaultRadius};
    padding: ${props => props.theme.spacing.spacing24} ${props => props.theme.spacing.spacing20};
    
    ${props => props.$hasError
            ? css`
            border: 1px solid ${props => props.theme.colors.danger};    
            `
            : css`
            border: 1px solid ${props => props.theme.border.mainColor};
            &:focus-within {
                border-color: ${props => props.theme.border.focusedColor};
            }
        `
    }
`

const InputContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`

const LabelContainerBox = styled.div`
    padding-left: 4px;
    font-weight: ${props => props.theme.fontWeight.medium};
`

const ErrorContainerBox =  styled.div`
    width: 100%;
    font-size: ${props => props.theme.fontSize.subtitle2};
    padding-left: 4px;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
`

const IconContainerBox = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    height: 100%;
    
`

interface InputContainerOutsideBordersStyleComponentProps extends Omit<InputContainerComponentProps, 'inputStyle'> {

}

export const InputContainerOutsideBordersStyleComponent: React.FC<InputContainerOutsideBordersStyleComponentProps> = observer((props) => {
    const hasError = Boolean(props.errorMessage);
    const specificCssClasses = props.cssClassesForOutsideBordersStyle;
    const commonCssClasses = props.cssClasses;
    return (
        <RootContainerBox ref={props.captureInputContainerRef}
                          onClick={props.onClick}
                          $hasError={hasError}
                          className={props.className}>
            <ContentSectionBox className={specificCssClasses?.contentSectionContainer}>
                {renderInputLabel(props, LabelContainerBox)}
                <InputAndIconContainerBox $hasError={hasError} className={specificCssClasses?.inputAndIconContainer} ref={props.captureInputRef}>
                    {renderInputIcon(props, IconContainerBox)}
                    <InputContainerBox className={commonCssClasses?.inputContainer}>
                        {props.renderInput(hasError)}
                    </InputContainerBox>

                </InputAndIconContainerBox>
                {renderInputError(props, ErrorContainerBox)}
            </ContentSectionBox>

        </RootContainerBox>
    );
});