import React from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {DayCellBox} from "./boxes/day-cell.box";
import {DayNumberComponent} from "./day-number.component";
import {DayPriceComponent} from "./day-price.component";
import {FlightsDatesSelectorModel} from "../../../../../services/flight-search/dates-selection/flights-dates-selector.model";
import {NullableUndefinedPrice} from "../../../../../services/currency/price";


const DayContentBox = styled(DayCellBox)<{$isInsideSelection: boolean;}>`
    cursor: cell;
    ${props => props.$isInsideSelection
            ? css`
                background-color: ${props => props.theme.colors.light};
            `
            : ''
    }
`;

interface RegularDayComponentProps {
    date: Date;
    flightsDatesSelector: FlightsDatesSelectorModel;
    departureLowFare: NullableUndefinedPrice;
    returnLowFare: NullableUndefinedPrice;
}
export const RegularDayComponent: React.FC<RegularDayComponentProps> = observer((props) => {
    let price: NullableUndefinedPrice;

    if(props.flightsDatesSelector.isOnDepartureDateStrategy) {
        price = props.departureLowFare;
    } else {
        price = props.returnLowFare;
    }


    return (
        <DayContentBox $isInsideSelection={props.flightsDatesSelector.isDateInsideSelection(props.date)}>
            <DayNumberComponent date={props.date}/>
            <DayPriceComponent price={price}/>
        </DayContentBox>
    )
})