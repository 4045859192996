import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {
    IFlightDesignatorViewModel
} from "../../../../services/booking/models/designator/flight-designator-view-model.interface";
import {JourneyTravelTimeComponent} from "../common/journey-travel-time.component";
import {PlaneGoComponent} from "../common/plane-go.component";
import {StopPointComponent} from "../common/stop-point.component";
import {FlightNumberComponent} from "../common/flight-number.component";
import {AdvancedDesignatorOperatedByComponent} from "../common/advanced-designator-operated-by.component";
import {FlightConnectivityTypeComponent} from "../common/flight-connectivity-type.component";


const MiddleSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 100%;
    gap: 4px;
`

const PlaneAndLinesContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 4px;
    
`

export const HorizontalLineBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    &:before {
        content: "";
        height: 1px;
        background-color: ${props => props.theme.border.mainColor};
        width: 50%;
    }
    
    &:after {
        content: "";
        height: 1px;
        background-color: ${props => props.theme.border.mainColor};
        width: 50%;
    }
`

const FlightDetailsBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
`

interface MiddleSectionCollapsedComponentProps {
    journeyDesignator: IFlightDesignatorViewModel;
    onExpandClick: () => void;
}
export const MiddleSectionCollapsedComponent: React.FC<MiddleSectionCollapsedComponentProps> = observer((props) => {


    let segmentDesignator: IFlightDesignatorViewModel | null = null;

    if(props.journeyDesignator.innerDesignators.length === 1) {
        segmentDesignator = props.journeyDesignator.innerDesignators[0];
    }

    const renderPlane = (designator: IFlightDesignatorViewModel) => {
        return (
            <HorizontalLineBox>
                <PlaneGoComponent designator={designator}/>
            </HorizontalLineBox>
        );
    }



    const renderPlaneAndHorizontalLine = () => {
        if(props.journeyDesignator.numberOfStops === 0) {
            return (
                <PlaneAndLinesContainerBox>
                    {renderPlane(props.journeyDesignator.innerDesignators[0])}
                </PlaneAndLinesContainerBox>
            )
        }

        return (
            <PlaneAndLinesContainerBox>
                {renderPlane(props.journeyDesignator.innerDesignators[0])}
                <StopPointComponent/>
                {renderPlane(props.journeyDesignator.innerDesignators[1])}
            </PlaneAndLinesContainerBox>
        )
    }

    return (
        <MiddleSectionBox>
            {renderPlaneAndHorizontalLine()}
            <AdvancedDesignatorOperatedByComponent designator={segmentDesignator}/>
            <FlightDetailsBox>

                <JourneyTravelTimeComponent journeyDesignator={props.journeyDesignator}/>
                <FlightConnectivityTypeComponent designator={props.journeyDesignator} onExpandClick={props.onExpandClick} isExpanded={false}/>
                <FlightNumberComponent designator={segmentDesignator}/>
            </FlightDetailsBox>


        </MiddleSectionBox>
    )

});