import {DeviceType, IDeviceService} from "./device.service.interface";
import {PlatformDetection} from "./platform-detection";
import {makeObservable, observable, runInAction} from "mobx";
import { Clipboard } from '@awesome-cordova-plugins/clipboard';

export class DeviceService implements IDeviceService {
    constructor() {

        makeObservable<this, '_showCopyToClipboardToast'>(this, {
            isWindows: observable.ref,
            _showCopyToClipboardToast: observable.ref
        });

        this._deviceDetection = new PlatformDetection();
        this._deviceDetection.getOperatingSystem().then(operatingSystem => {
            runInAction(() => {
                this.isWindows = (operatingSystem === "windows");
                this.isMac = (operatingSystem === "mac")
            })
        })
    }

    isWindows: boolean = false;
    isMac: boolean = false;

    private _showCopyToClipboardToast: boolean = false;
    get showCopyToClipboardToast(): boolean {
        return this._showCopyToClipboardToast;
    }

    set showCopyToClipboardToast(value: boolean) {
        runInAction(() => {
            this._showCopyToClipboardToast = value;
        })
    }

    async getDeviceId(): Promise<string> {
        return await this._deviceDetection.getDeviceId();
    }

    private readonly _deviceDetection: PlatformDetection;
    get deviceType(): DeviceType {
        return this._deviceDetection.deviceType;
    }

    get isIOS(): boolean {
        return this._deviceDetection.isIOS;
    }

    get isAndroid(): boolean {
        return this._deviceDetection.isAndroid;
    }

    get isWeb(): boolean {
        return this._deviceDetection.isWeb;
    }

    get isHybrid(): boolean {
        return this._deviceDetection.isHybrid;
    }

    get isMobile(): boolean {
        return this._deviceDetection.isMobile;
    }

    get isAndroidMobileHybrid(): boolean {
        return this._deviceDetection.isAndroidMobileHybrid;
    }

    getOperatingSystemName(): string {
        if(this.isAndroid) {
            return "Android";
        }
        if(this.isIOS) {
            return "iOS";
        }

        if(this.isWindows) {
            return "Windows";
        }

        if(this.isMac) {
            return "Mac";
        }

        return "Other";
    }

    async copyToClipboard(text: string): Promise<void> {
        if(this.isHybrid) {
            await Clipboard.copy(text);
            if(!this.isAndroid) {
                this.showCopyToClipboardToast = true;
            }
        } else if(navigator?.clipboard) {
            await navigator.clipboard.writeText(text);
            this.showCopyToClipboardToast = true;
        }

    }

}
