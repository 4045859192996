import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../../../../../../hooks/use-services.hook";
import {PrimaryButtonInverted} from "../../../../../../../basic-components/buttons/primary-button-inverted";
import {DialogCloseButtonBehavior} from "../../../../../../../services/dialog/dialog-enums";
import {RetrieveBookingDialogComponent} from "../../../../../../retrieve-booking/retrieve-booking-dialog.component";
import {MyTripsTab} from "../../my-trips.tab";

const ContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-self: flex-end;
    gap: ${props => props.theme.spacing.flexGap8};
`

const LabelBox = styled.div`
    font-size: ${props => props.theme.fontSize.subtitle1};
    color: ${props => props.theme.colors.mediumShade};
`

const Button = styled(PrimaryButtonInverted)`
    font-size: ${props => props.theme.fontSize.h6};
    font-weight: ${props => props.theme.fontWeight.medium};
    
`

export const RetrieveFutureTravelButtonComponent: React.FC<{tab: MyTripsTab}> = observer((props) => {
    const services = useServices();
    const onClick = async () => {
        await services.dialog.showPopupDialog({
            closeButtonBehavior: DialogCloseButtonBehavior.Reject,
            render: dialogHandler => (<RetrieveBookingDialogComponent dialogHandler={dialogHandler} retrieveBooking={searchParams => props.tab.retrieveBooking(searchParams)}/>)
        })
    }
    return (
        <ContainerBox>
            <LabelBox>
                {services.language.translate('Not seeing a booking?')}
            </LabelBox>
            <Button fullWidth={false} showArrow={true} onClick={onClick}>
                {services.language.translate('Retrieve it now')}
            </Button>
        </ContainerBox>

    )

})