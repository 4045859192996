import styled, {css} from "styled-components";
import {IBookingShoppingCartViewModel} from "../../services/booking/models/shopping-cart/booking/booking-shopping-cart-view-model.interface";
import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../hooks/use-services.hook";
import {ShoppingCartModeEnum} from "../../services/booking/booking-strategies/booking-strategy.interface";
import {IonIcon} from "@ionic/react";
import {checkmark} from "ionicons/icons";

const BORDER_RADIUS = '20px';

const ShoppingCartModeTogglesBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: ${props => props.theme.spacing.spacing8};
`

const ShoppingCartModesContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-radius: ${BORDER_RADIUS};
    padding: 0;
    font-size: ${props => props.theme.fontSize.subtitle2};
    box-shadow: ${props => props.theme.boxShadow.shadow2};
`

const TitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.body2};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    color: ${props => props.theme.colors.darkTint};
`

const DisplayModeOptionBox = styled.div<{$isSelected: boolean}>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: ${props => props.theme.spacing.spacing8} ${props => props.theme.spacing.spacing16};
    flex-basis: 50%;
    border: 1px solid ${props => props.theme.border.mainColor};
    cursor: pointer;
    ${props => props.$isSelected 
        ? css`
            color: ${props => props.theme.colors.dark};
            background-color: ${props => props.theme.colors.successVeryLight};
        `
        : css`
            color: ${props => props.theme.colors.mediumTint};
        `
    }
    &:first-of-type {
        border-top-left-radius: ${BORDER_RADIUS};
        border-bottom-left-radius: ${BORDER_RADIUS};
    }
    &:last-of-type {
        border-top-right-radius: ${BORDER_RADIUS};
        border-bottom-right-radius: ${BORDER_RADIUS};
    }
`

const DisplayModeOptionComponent: React.FC<{mode: ShoppingCartModeEnum; shoppingCart: IBookingShoppingCartViewModel;}> = observer((props) => {
    const services = useServices();

    const isSelected = props.mode === props.shoppingCart.currentShoppingCartMode;

    const getOptionText = () => {
        if(props.mode === ShoppingCartModeEnum.ShowAllPurchases) {
            return services.language.translate('All purchases');
        } else {
            return services.language.translate('Only differences');
        }
    }

    const renderCheckMark = () => {
        if(!isSelected) {
            return null;
        }

        return (
            <IonIcon icon={checkmark}/>
        )
    }

    return (
        <DisplayModeOptionBox $isSelected={isSelected} onClick={() => props.shoppingCart.currentShoppingCartMode = props.mode}>
            {renderCheckMark()}
            <span>
                {getOptionText()}
            </span>

        </DisplayModeOptionBox>
    )
})

interface ShoppingCartModeToggleComponentProps {
    shoppingCart: IBookingShoppingCartViewModel;
}

export const ShoppingCartModeToggleComponent: React.FC<ShoppingCartModeToggleComponentProps> = observer((props) => {
    const services = useServices();
    if(props.shoppingCart.allowedShoppingCartModes.length <= 1) {
        return null;
    }



    const renderOption = (mode: ShoppingCartModeEnum) => {
        return (
            <DisplayModeOptionComponent key={mode} mode={mode} shoppingCart={props.shoppingCart}/>
        )
    }

    return (
        <ShoppingCartModeTogglesBox>
            <TitleBox>
                {services.language.translate('Showing')}
            </TitleBox>
            <ShoppingCartModesContainerBox>
                {props.shoppingCart.allowedShoppingCartModes.map(mode => renderOption(mode))}
            </ShoppingCartModesContainerBox>

        </ShoppingCartModeTogglesBox>
    );
});
