import styled from "styled-components";
import {
    StandardPageContentComponent
} from "../../../../basic-components/page/standard-page-content/standard-page-content.component";

export const HomePageStandardContentWebappComponent = styled(StandardPageContentComponent)`
    &::part(scroll){
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 100%;
    }
`

