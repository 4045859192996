import React from "react";
import {observer} from "mobx-react";
import {MyTripsTab} from "../my-trips.tab";
import {MyTripsTabContentTopSectionComponent} from "./my-trips-tab-content-top-section.component";
import {MyTripsTabContentBottomSectionComponent} from "./my-trips-tab-content-bottom-section.component";

interface MyTripsTabContentComponentProps {
    tab: MyTripsTab;
}
export const MyTripsTabContentComponent: React.FC<MyTripsTabContentComponentProps> = observer((props) => {


    return (
        <>
            {<MyTripsTabContentTopSectionComponent tab={props.tab} />}
            {<MyTripsTabContentBottomSectionComponent tab={props.tab} />}
        </>
    )
})