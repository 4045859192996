import React from "react";
import {observer} from "mobx-react-lite";
import {useScreenMediaQueriesChecks} from "../../hooks/use-screen-media-queries-checks.hook";

interface ScreenSizeBasedComponentProps {
    renderForSmallScreen: () => React.ReactElement;
    renderForLargeScreen: () => React.ReactElement;
}
export const ScreenSizeBasedComponent: React.FC<ScreenSizeBasedComponentProps> = observer((props) => {
    const mediaQuery = useScreenMediaQueriesChecks();
    if(mediaQuery.smallScreen) {
        return props.renderForSmallScreen();
    } else {
        return props.renderForLargeScreen();
    }
});
