import {MyAccountDashboardItemBase} from "../my-account-dashboard-item-base";
import React from "react";
import {GearIcon} from "../../../assets/svg/gear.icon";

export class MyPreferencesDashboardItem extends MyAccountDashboardItemBase {
    get text(): string {
        return this.services.language.translate('My Preferences');
    }

    renderIcon(): React.ReactElement {
        return <GearIcon />;
    }

    protected _requiresLogin(): boolean {
        return false;
    }

    protected async _onExecute(): Promise<void> {

    }
}
