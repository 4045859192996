import './extensions/array.extensions';
import React from 'react';
import {createRoot} from "react-dom/client";
import App from './app';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {ServiceFactoryContext} from "./react-contexts/service-factory-context";
import {setupIonicReact } from "@ionic/react";
import {AirlineAppPublicObject} from "./airline-app-public-object";
//import {setupTrackJs} from "./services/trackjs.setup";
import {AirlineCompanyNameAsIdentifier} from "./global-constants";
import {APP_ROOT_ELEMENT_ID} from "./services/layout/layout.service.interface";
import {LayoutDetection} from "./services/layout/layout-detection";
import {IServiceFactory} from "./services/service-factory.interface";
import {WebappServiceFactory} from "./services/webapp-service-factory";
import {HybridServiceFactory} from "./services/hybrid-service-factory";

//setupTrackJs();

setupIonicReact ({
    //swipeBackEnabled: true
});

const layoutDetection = new LayoutDetection();

let serviceFactory: IServiceFactory;
if(layoutDetection.shouldUseWebappLayout) {
    serviceFactory = new WebappServiceFactory();
} else {
    serviceFactory = new HybridServiceFactory();
}

Object.defineProperty(window, AirlineCompanyNameAsIdentifier, {
    value: new AirlineAppPublicObject(),
    writable: false,
    configurable: false
});

const domElement = document.getElementById(APP_ROOT_ELEMENT_ID);
if(!domElement) {
    throw new Error('root element not found');
}
const root = createRoot(domElement);

/*
root.render(<React.StrictMode>
    <ServiceFactoryContext.Provider value={serviceFactory}>
        <App />
    </ServiceFactoryContext.Provider>
</React.StrictMode>)
 */

root.render(<ServiceFactoryContext.Provider value={serviceFactory}>
    <App />
</ServiceFactoryContext.Provider>)




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
