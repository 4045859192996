import {ExtrasSingleSsrSectionRenderer} from "../../base-renderers/extras-single-ssr-section-renderer";
import {IBookingViewModel} from "../../../../../services/booking/models/booking-view-model.interface";
import {NullableString} from "../../../../../types/nullable-types";

export class BusinessLoungeExtrasSectionRenderer extends ExtrasSingleSsrSectionRenderer {
    constructor(booking: IBookingViewModel) {
        super(booking.services.ssrTypes.LOU, booking);
    }

    protected _getExtrasLargeTilePicture(): NullableString {
        return this.services.theme.currentTheme.assets.images.extras.business_lounge_2x_jpg.toString();
    }

    protected _getMarketingMessage(): NullableString {
        return this.services.language.translate('Get some extra work done or just get away from the noise. A premium experience is just what you need.');

    }
}
