import React from "react";
import {IFormField} from "../../../models/forms/form-field.interface";
import {NullableNumber} from "../../../types/nullable-types";
import {observer} from "mobx-react";
import {Check} from "../../../types/type-checking";
import styled from "styled-components";
import {InputBaseBox} from "../../../basic-components/input/input-base.box";
import {FieldInputContainerComponent} from "../field-input-container.component";
import {FieldEditorCommonProps} from "../inputs-common.props";

const NumericInputBox = styled(InputBaseBox)`
  
`

interface NumericFieldEditorComponentProps extends FieldEditorCommonProps {
    field: IFormField<NullableNumber>;
    inputMode: 'numeric' | 'decimal';
    placeholder?: string;
    minValue?: number;
    maxValue?: number;
}

export const NumericFieldEditorComponent: React.FC<NumericFieldEditorComponentProps> = observer((props) => {

    const setValue = (value: string | number | null | undefined) => {
        if(Check.isNullOrUndefined(value)) {
            props.field.setValue(null);
        } else if(Check.isNumber(value)) {
            props.field.setValue(value);
        } else {
            const valueAsNumber = parseInt(value);
            if(Check.isNumber(valueAsNumber)) {
                props.field.setValue(valueAsNumber);
            } else {
                props.field.setValue(null);
            }
        }
    }

    const renderInput = () => {
        return (
            <NumericInputBox    type={"number"}
                                inputMode={props.inputMode}
                                value={props.field.value || ""}
                                min={props.minValue}
                                max={props.maxValue}
                                placeholder={props.placeholder}
                                onChange={(e) => setValue(e.target.value)}
                                readOnly={props.field.isReadOnly}
                                ref={props.field.attachInputDOMElement}/>
        );
    }

    return (
        <FieldInputContainerComponent {...props} renderInput={renderInput}/>
    );
});
