import React from 'react';
import {useServices} from "../../hooks/use-services.hook";
import styled from 'styled-components';
import {Station} from "../../services/stations/station.service.interface";
import {MediumLabelBox} from "../../basic-components/label/medium-label.box";
import {observer} from "mobx-react-lite";
import {CardBox} from "../../basic-components/card/card.box";

const ListBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const StationsGroupHeaderBox = styled.div`
    width: 100%;
    font-size: ${props => props.theme.fontSize.h2};
    padding: ${props => props.theme.spacing.spacing20} ${props => props.theme.spacing.spacing12};
    
`

const StationsGroupBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`

const StationContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    padding: ${props => props.theme.spacing.spacing16};
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.colors.light};
    cursor: pointer;
    font-size: ${props => props.theme.fontSize.h3};
    &:last-of-type {
        border-bottom: none;
    }
`

const StationDetailsBox = styled.div`
    
    flex-grow: 1;
`

const StationNameBox = styled.div`
`

const StationCountryBox = styled(MediumLabelBox)`
    font-size: ${props => props.theme.fontSize.h6};
    
`

const StationCodeBox = styled.div`
    display: flex;
    align-items: center;
`



interface StationComponentProps {
    station: Station;
    onSelect: (station: Station) => void;
}

const StationComponent: React.FC<StationComponentProps> = observer((props) => {
    const onClickHandler = () => {
        props.onSelect(props.station)
    }
    return (
        <StationContainerBox onClick={onClickHandler}>
            <StationDetailsBox>
                <StationNameBox>
                    {props.station.stationName}
                </StationNameBox>
                <StationCountryBox>
                    {props.station.countryName}
                </StationCountryBox>
            </StationDetailsBox>
            <StationCodeBox>
                {props.station.stationCode}
            </StationCodeBox>
        </StationContainerBox>
    )
})

interface StationsByLetterComponentProps {
    letter: string;
    stations: Station[];
    onSelect: (station: Station) => void;
}

const StationsByLetterComponent: React.FC<StationsByLetterComponentProps> = observer((props) => {
    return (
        <div>
            <StationsGroupHeaderBox>
                {props.letter}
            </StationsGroupHeaderBox>
            <StationsGroupBox>
                {props.stations.map(station => 
                    <StationComponent 
                        key={station.stationCode} 
                        station={station} 
                        onSelect={props.onSelect}
                    />)}
            </StationsGroupBox>
        </div>
    )
});

interface RecentlySearchedStationsListProps {
    onSelect: (station: Station) => void;
    recentlySearchedStations: Station[];
}

const RecentlySearchedStationsListComponent: React.FC<RecentlySearchedStationsListProps> = observer((props) => {
    const services = useServices();
    
    if (props.recentlySearchedStations.length === 0) {
        return null;
    }

    return (
        <div>
            <StationsGroupHeaderBox>
                {services.language.translate('Recent Searches')}                
            </StationsGroupHeaderBox>
            <StationsGroupBox>
                {props.recentlySearchedStations.map(recentlySearchedStation => 
                    <StationComponent 
                        key={recentlySearchedStation.stationCode} 
                        station={recentlySearchedStation} 
                        onSelect={props.onSelect}
                    />)}
            </StationsGroupBox>
        </div>
    );
})

export interface StationsListComponentProps {
    stations: Station[];
    recentlySearchedStations: Station[];
    onSelect: (station: Station) => void;
}


export const StationsListComponent: React.FC<StationsListComponentProps> = observer((props) => {
    const services = useServices();

    const stationsAlphabetically = services.stations.groupStationsAlphabetically(props.stations);

    return (
        <ListBox>
            <RecentlySearchedStationsListComponent
                onSelect={props.onSelect}
                recentlySearchedStations={props.recentlySearchedStations}
            />
            {Object.keys(stationsAlphabetically).map(letter =>
                <StationsByLetterComponent
                    key={letter}
                    letter={letter}
                    stations={stationsAlphabetically[letter]}
                    onSelect={props.onSelect}
                />)}
        </ListBox>
    );
})
