import React from "react";
import {observer} from "mobx-react";
import {MustChooseAnOptionComponent} from "../../must-choose-an-option/must-choose-an-option.component";
import styled from "styled-components";
import {
    IPassengerAllBagsViewModel
} from "../../../../../../services/booking/models/ssrs/bags/passenger-all-bags-view-model.interface";

const MustChooseAnOptionContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    ${props => props.theme.containerMediaQuery.smallScreen} {
        align-items: center;
    }
`

export const CheckInBagsMustChooseAnOptionComponent: React.FC<{passengerBags: IPassengerAllBagsViewModel; hasError: boolean}> = observer((props) => {
    if(props.passengerBags.checkInBags.some(b => b.quantityIncludedInBundle > 0)) {
        return null;
    }

    if(props.passengerBags.checkInBags.some(b => b.minAllowedQuantity > 0)) {
        return null;
    }

    return (
        <MustChooseAnOptionContainerBox>
            <MustChooseAnOptionComponent arrowDirection={"up"} hasError={props.hasError}/>
        </MustChooseAnOptionContainerBox>
    )
})