import React from 'react';
import {observer} from "mobx-react";
import {AdvancedJourneyDesignatorComponent} from "../../../../../components/advanced-journey-designator/advanced-journey-designator.component";
import {AvailableFareListComponent} from "./available-fare-list.component";
import styled, {css} from 'styled-components';
import { CardBox } from '../../../../../basic-components/card/card.box';
import {IAvailableJourneyViewModel} from "../../../../../services/flight-search/models/available-journey-view-model.interface";
import {FarePromoMessageComponent} from "./fare-promo-message.component";
import {useContainerMediaQueriesChecks} from "../../../../../hooks/use-container-media-queries-checks.hook";

const AvailableJourneyBox = styled(CardBox)<{$isSelected: boolean;}>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    
    ${props => props.$isSelected 
            ? css`
                border: 1px solid ${props => props.theme.colors.success};
            `
            : ''
    }
`

const DesignatorAndFareContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    width: 100%;
    gap: ${props => props.theme.spacing.spacing40};

    ${(props) => props.theme.containerMediaQuery.smallScreen} {
        flex-direction: column;
        gap: ${props => props.theme.spacing.spacing20};
    }

`

const DesignatorContainerBox = styled.div`
    flex-basis: 100%;
`

const PromoMessageContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    width: 100%;
`

interface JourneyComponentProps {
    journey: IAvailableJourneyViewModel;
}


export const AvailableJourneyComponent: React.FC<JourneyComponentProps> = observer((props) => {
    const mediaQuery = useContainerMediaQueriesChecks();
    const isJourneySelected = Boolean(props.journey.fares.find(f => f.isSelected));

    const renderPromotionalMessage = () => {
        const promotionalMessage = props.journey.fares[0]?.promotionalMessage;
        //const promotionalMessage =  "Cumperi 1, primești încă 1 gratis";
        //const promotionalMessage =  "25% Discount";

        if(!promotionalMessage) {
            return null;
        }

        if(!mediaQuery.smallScreen) {
            return null;
        }

        return (
            <PromoMessageContainerBox>
                <FarePromoMessageComponent promoMessage={promotionalMessage}/>
            </PromoMessageContainerBox>

        );
    }



    return (
        <AvailableJourneyBox $isSelected={isJourneySelected}>
            {renderPromotionalMessage()}
            <DesignatorAndFareContainerBox>
                <DesignatorContainerBox>
                    <AdvancedJourneyDesignatorComponent journeyDesignator={props.journey.designator}
                                                        isShownInShoppingCart={false} />
                </DesignatorContainerBox>

                <AvailableFareListComponent fares={props.journey.fares}/>
            </DesignatorAndFareContainerBox>

        </AvailableJourneyBox>
    );
});


