import {MyAccountDashboardItemBase} from "../my-account-dashboard-item-base";
import React from "react";
import { PersonGroupFilledIcon } from "../../../assets/svg/person-group-filled.icon";

export class MyCompanionsDashboardItem extends MyAccountDashboardItemBase {
    get text(): string {
        return this.services.language.translate('My Companions');
    }

    renderIcon(): React.ReactElement {
        return <PersonGroupFilledIcon/>;
    }

    protected _requiresLogin(): boolean {
        return true;
    }

    protected async _onExecute(): Promise<void> {
        this.services.navigator.routes.user.companions.activate();
    }
}
