import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';
//props.color? props.color: "currentColor"
export const PlaneReturnsIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.5} {...props} viewBox="0 0 11 10" ratio={11 / 10}>
            <path
                d="M1.79474 3.93637L3.63432 3.93354L6.96618 0.65588C7.70869 -0.0759936 8.41063 0.926554 7.92339 1.64141L6.24308 3.92809L9.4888 3.92672L9.93227 3.46608C10.8044 2.54173 10.8469 3.52581 10.8363 4.26144L10.8414 4.96953C10.8479 5.72161 10.0519 5.73635 9.65427 5.74856L6.27851 5.75531L7.98208 8.00374C8.48382 8.7164 7.79982 9.72256 7.03908 8.99414L3.68528 5.76694L1.67477 5.77336C-0.803944 5.77343 0.507585 3.93869 1.79474 3.93637Z"
                fill={props.color? props.color: "currentColor"}/>
        </SvgIcon>
    )
}
