import {BookingModel} from "../booking.model";
import {IBookingInsuranceViewModel, InsuranceUrls} from "./booking-insurance-view-model.interface";
import {Price} from "../../../currency/price";
import {IReactionDisposer, makeObservable, observable, reaction, runInAction} from "mobx";
import {
    ITravelInsuranceQuoteJourney, ITravelInsuranceQuoteSegment,
    ITravelInsuranceQuotesRequest
} from "../../../airline-webapi/requests/travel-insurance-quotes.request";
import {IServiceFactory} from "../../../service-factory.interface";
import {ITravelInsuranceQuotePackage} from "../../../airline-webapi/responses/travel-insurance-quote.response";
import {BookingSessionStorageKeys} from "../storage/booking-storage.interface";
import {NullableUndefinedBoolean, NullableString, NullableUndefinedString} from "../../../../types/nullable-types";
import {BookingInsuranceQuoteModel} from "./booking-insurance-quote.model";
import {SellInsuranceMutation} from "../mutation-actions/insurance/sell-insurance.mutation";
import {RemoveInsuranceMutation} from "../mutation-actions/insurance/remove-insurance.mutation";
import {Check} from "../../../../types/type-checking";
import {scrollElementIntoViewSmooth} from "../../../../utils/scroll-element-into-view";
import {FeeCodeEnum} from "../../../dot-rez-api/data-contracts/enums/fee-type.enum";


interface IPersistedQuotes {
    quotePackage: ITravelInsuranceQuotePackage;
    departureJourneyKey: NullableUndefinedString;
    returnJourneyKey: NullableUndefinedString;
    countryCode: NullableUndefinedString;
    departureJourneyBundle: NullableUndefinedString;
    returnJourneyBundle: NullableUndefinedString;
    promoCode: NullableUndefinedString;

}

export class BookingInsuranceModel implements IBookingInsuranceViewModel {
    constructor(private readonly booking: BookingModel) {
        if(this.services.configuration.envConfigs.enableInsurance) {
            this.persistedQuotes = this.booking.storage.getJson(BookingSessionStorageKeys.insuranceQuotes) || null;
            const hasInsurance = this.booking.storage.getItem(BookingSessionStorageKeys.hasInsurance) || null;
            if(!Check.isNullOrUndefined(hasInsurance)) {
                this._hasInsurance = (hasInsurance === 'true');
            }
            makeObservable<this, '_isValidationActive' | '_hasInsurance'>(this, {
                persistedQuotes: observable.ref,
                _isValidationActive: observable.ref,
                _hasInsurance: observable.ref
            });

            this._subscribeToReactions();
        } else {
            this._hasInsurance = false;
        }
    }

    private _subscribeToReactions(): void {
        if(!this.booking.bookingStrategyAdapter.allowSellInsurance) {
            return;
        }

        //We use setTimeout here because BookingInsuranceModel is created in the constructor of the BookingModel
        //and inside BookingInsuranceModel we want to access something from BookingModel so this creates a circular dependency
        setTimeout(() => {
            this._reactions.push(reaction(() => [
                    this.booking.bookingData,
                    this.booking.ssrsAvailability,
                    this.booking.contact.fields.countryCode.value
                ],
                () => {
                    this._loadInsuranceQuotesPromise = this._loadInsuranceQuotes();
                },
                {
                    fireImmediately: true
                }));
        });
    }

    private _reactions: IReactionDisposer[] = [];
    persistedQuotes: IPersistedQuotes | null = null;

    dispose(): void {
        this._reactions.forEach(r => r());
        this._reactions = [];
    }

    get hasInsuranceSsrAvailability(): boolean {
        const insuranceSsrCode = this.services.ssrTypes.INS1.ssrCode;
        return this.booking.ssrsAvailability.segmentSsrs.some(segmentSsrs => Boolean(segmentSsrs.ssrs.find(ssr => ssr.ssrCode === insuranceSsrCode)))

    }

    get hasQuote(): boolean {
        return (this.persistedQuotes?.quotePackage?.quotes.length ?? 0) > 0;
    }

    private get services(): IServiceFactory {
        return this.booking.services;
    }

    private get _quotesAlreadyLoaded(): boolean {
        if(!this.persistedQuotes) {
            return false;
        }

        return this.booking.departureJourney?.journeyKey === this.persistedQuotes.departureJourneyKey
        && this.booking.departureJourney?.selectedBundle?.bundleCode === this.persistedQuotes.departureJourneyBundle
        && this.booking.returnJourney?.journeyKey === this.persistedQuotes.returnJourneyKey
        && this.booking.returnJourney?.selectedBundle?.bundleCode === this.persistedQuotes.returnJourneyBundle
        && this.booking.contact.fields.countryCode.value === this.persistedQuotes.countryCode
        && this.booking.promoCode.code.value === this.persistedQuotes.promoCode;
    }

    get quote(): BookingInsuranceQuoteModel {
        if(!this.persistedQuotes?.quotePackage) {
            throw new Error('There is no available quote for this booking');
        }
        return new BookingInsuranceQuoteModel(this.persistedQuotes.quotePackage, this.booking);
    }

    private _loadInsuranceQuotesPromise: Promise<void> = Promise.resolve();

    async waitForQuotes(): Promise<void> {
        try {
            await this._loadInsuranceQuotesPromise;
        } catch (err) {
            this.services.logger.error('Wait for quotes failed', err);
        }

    }

    private async _loadInsuranceQuotes(): Promise<void> {
        if(!this.booking.departureJourney?.selectedBundle) {
            return;
        }

        if(!this.booking.flightSearchController.isOneWay && !this.booking.returnJourney?.selectedBundle) {
            return;
        }

        if(!this.booking.contact.fields.countryCode.value) {
            return;
        }

        if(!this.hasInsuranceSsrAvailability) {
            return;
        }

        if(this._quotesAlreadyLoaded) {
            return;
        }

        const endDate = this.booking.returnJourney
            ? this.booking.returnJourney.designator.arrivalDate.toISOString()
            : this.services.time.addDays(this.booking.departureJourney.designator.departureDate, this.services.configuration.data.insurance.insuranceDaysForOneWayFlight).toISOString();
        const request: ITravelInsuranceQuotesRequest = {
            isOneWayTrip: this.booking.flightSearchController.isOneWay,
            currency: this.booking.currency,
            customerCountry: this.booking.contact.fields.countryCode.value!,
            customerLanguage: this.services.language.currentLanguageIso2,
            departureCountry: this.booking.departureJourney.designator.origin.countryCode,
            destinationCountry: this.booking.departureJourney.designator.destination.countryCode,
            numberOfAdults: this.booking.passengers.countAdults(),
            numberOfChildren: this.booking.passengers.countChildren(),
            numberOfInfants: this.booking.passengers.countInfants(),
            salesChannel: "in-path-app",
            totalTicketsPrice: Price.sumAll(this.booking.shoppingCart.journeysAllPurchases.map(j => j.faresTotalToDisplay), this.booking.createPrice(0)).amount,
            policyStartDate: this.booking.bookingCreationDate.toISOString(),
            policyEndDate: endDate,
            tripStartDate: this.booking.departureJourney.designator.departureDate.toISOString(),
            tripEndDate: endDate,
            journeys: this.booking.unfilteredJourneys.map(j => {
                const journeyForQuote: ITravelInsuranceQuoteJourney = {
                    departureCountry: j.designator.origin.countryCode,
                    arrivalCountry: j.designator.destination.countryCode,
                    departureDateTime: j.designator.departureDate.toISOString(),
                    arrivalDateTime: j.designator.arrivalDate.toISOString(),
                    segments: j.segments.map(s => {
                        const segmentForQuote: ITravelInsuranceQuoteSegment =  {
                            departureCountry: s.designator.origin.countryCode,
                            arrivalCountry: s.designator.destination.countryCode,
                            departureAirport: s.designator.origin.stationCode,
                            arrivalAirport: s.designator.destination.stationCode,
                            departureDateTime: s.designator.departureDate.toISOString(),
                            arrivalDateTime: s.designator.arrivalDate.toISOString(),
                            flightNumber: s.identifier.fullIdentifierCode()
                        }

                        return segmentForQuote;
                    })
                }

                return journeyForQuote;
            })
        }

        const quotesPartialData = {
            departureJourneyKey: this.booking.departureJourney?.journeyKey,
            departureJourneyBundle: this.booking.departureJourney?.selectedBundle?.bundleCode,
            returnJourneyKey: this.booking.returnJourney?.journeyKey,
            returnJourneyBundle: this.booking.returnJourney?.selectedBundle?.bundleCode,
            countryCode: this.booking.contact.fields.countryCode.value,
            promoCode: this.booking.promoCode.code.value

        }

        try {

            if(this.hasSelectedInsurance) {
                await this.removeInsurance();
            }

            const response = await this.services.airlineWebapi.getTravelInsuranceQuote(request);

            runInAction(() => {
                this.persistedQuotes = {
                    quotePackage: response,
                    ...quotesPartialData
                }

                this.booking.storage.setJson(BookingSessionStorageKeys.insuranceQuotes, this.persistedQuotes);
            });
        } catch (err) {
            this.services.logger.error('Failed to load insurance quotes', err);
            runInAction(() => {
                this.persistedQuotes = {
                    quotePackage: {
                        quotes: [],
                        packageId: "",
                        pdsUrl: "",
                        total: 0
                    },
                    ...quotesPartialData
                }
            });
        } finally {
            this.hasSelectedInsurance = null;
            runInAction(() => this._isValidationActive = false);
        }
    }

    private _hasInsurance: NullableUndefinedBoolean = null;

    get hasPurchasedInsurance(): boolean {
        return this.booking.passengers.some(p => p.fees.some(f => f.feeCode === FeeCodeEnum.Insurance));
    }

    get hasSelectedInsurance(): NullableUndefinedBoolean {
        return this._hasInsurance;
    }

    set hasSelectedInsurance(value: NullableUndefinedBoolean) {

        runInAction(() => {
            this._hasInsurance = value;
            if(Check.isNullOrUndefined(value)) {
                this.booking.storage.removeItem(BookingSessionStorageKeys.hasInsurance)
            } else {
                this.booking.storage.setItem(BookingSessionStorageKeys.hasInsurance, value.toString())
            }

        });
    }

    get insuranceUrls(): InsuranceUrls | null {
        if(!this.hasPurchasedInsurance) {
            return null;
        }

        const extractUrl = (prefix: string) => {
            for(let comment of this.booking.bookingData.comments) {
                if(comment.text?.startsWith(prefix)) {
                    return comment.text.split('|')[1];
                }
            }

            return "";
        }


        return {
            url: extractUrl('INS_URL'),
            pdfUrl: extractUrl('INS_PDF'),
            accountUrl: extractUrl('INS_ACC'),
        };
    }

    async sellInsurance(): Promise<void> {
        if(this.hasSelectedInsurance) {
            return;
        }

        this.hasSelectedInsurance = true;

        this.booking.mutationsManager.startMutation(new SellInsuranceMutation(this.booking));
    }

    removeInsurance(): Promise<void> {

        const shouldRemoveInsurance = this.hasSelectedInsurance;

        this.hasSelectedInsurance = false;

        if(!shouldRemoveInsurance) {
            return Promise.resolve();
        }

        return new Promise<void>((resolve, reject) => {
            this.booking.mutationsManager.startMutation(new RemoveInsuranceMutation(this.booking, {
                onCompleted: () => resolve(),
                onError: (err) => reject(err)
            }));
        });
    }

    private _isValidationActive: boolean = false;
    get validationError(): NullableString {
        if(!this._isValidationActive) {
            return null;
        }

        if(Check.isNullOrUndefined(this.hasSelectedInsurance)) {
            return this.services.language.translate('Please select travel insurance option!');
        }

        return null;

    }
    activateValidationErrors(): void {
        runInAction(() => {
            this._isValidationActive = true;
        });

        if(this.validationError) {
            scrollElementIntoViewSmooth(this._domElement);
        }
    }

    private _domElement: HTMLElement | null = null;
    attachValidatedDOMElement(element: HTMLElement | null): void {
        this._domElement = element;
    }

}
