import {FinalPriceBaseStep} from "../common/final-price-base-step";
import {ICheckInRoutes} from "../../../navigation/routes/check-in/check-in.routes.interface";

export class CheckInFlowFinalPriceStep extends FinalPriceBaseStep<ICheckInRoutes> {

    get route() {
        return this.routes.finalPrice;
    }

    protected get routes(): ICheckInRoutes {
        return this.services.navigator.routes.checkIn;
    }

    protected async _goToPaymentStep(): Promise<void> {
        this._activateStepByRoute(this.routes.payment);
    }

}
