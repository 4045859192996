import React, {CSSProperties, PropsWithChildren} from "react";
import {useServices} from "../../../../hooks/use-services.hook";

interface BoardingPassFieldLabelComponentProps extends PropsWithChildren {
    styles?: CSSProperties;
}
export const BoardingPassFieldLabelComponent: React.FC<BoardingPassFieldLabelComponentProps> = (props) => {
    const services = useServices();
    const theme = services.theme.currentTheme;
    const styles: CSSProperties = {
        fontSize: theme.fontSize.body2,
        fontWeight: theme.fontWeight.medium,
        color: theme.colors.mediumShade,
        ...props.styles
    };

    return (
        <div style={styles}>
            {props.children}
        </div>
    );
}
