export const CSS_VALUE_EMERGENCY_ROW_MARKER_WIDTH = '8px';
export const CSS_VAR_SEAT_WIDTH = '--seat-width';
export const CSS_VAR_SEAT_HEIGHT = '--seat-height';

export const CSS_VAR_EXTRA_LEG_ROOM_SEAT_INDICATOR = '--seat-extra-leg-room-indicator';

export const CSS_VAR_EXTRA_LEG_ROOM_SEAT_INDICATOR_RADIUS = '--seat-extra-leg-room-indicator-radius';

export const CSS_VAR_SEAT_FONT_SIZE = '--seat-font-size';

export const CSS_VALUE_SEAT_WIDTH_SMALL = '2.875rem';
export const CSS_VALUE_SEAT_HEIGHT_SMALL = '3.3125rem';

export const CSS_VALUE_SEAT_WIDTH_LARGE = '3.1875rem';
export const CSS_VALUE_SEAT_HEIGHT_LARGE = '3.5rem';


export const ATR_SEATS_PER_ROW_COUNT = 5;