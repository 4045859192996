import {CheckInBagsSsrBase} from "../check-in-bags-ssr-base";
import {IServiceFactory} from "../../../../service-factory.interface";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../../ssrs-preferred-orders-in-shopping-cart";
import React from "react";
import {XsbgSsrLargeIcon} from "./xsbg.ssr.large-icon";
import { XsbgSsrSmallIcon } from "./xsbg.ssr.small-icon";

export default class XsbgSsrType extends CheckInBagsSsrBase {
    constructor(services: IServiceFactory) {
        super('XSBG', services);
    }

    get isAvailable(): boolean {
        return this.services.configuration.data.enable10KgBag;
    }

    get bagWeightKg(): number {
        return 10;
    }

    get smallIcon() {
        return (<XsbgSsrSmallIcon/>);
    }

    get largeIcon() {
        return (<XsbgSsrLargeIcon/>);
    }


    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.XSBG;
    }
}
