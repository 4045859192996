import React from "react";
import {observer} from "mobx-react";
import {IonMenu} from "@ionic/react";
import {HYBRID_HOME_PAGE_ID, HYBRID_HOME_PAGE_SIDE_MENU_ID} from "../hybrid-home-page-constants";
import {useServices} from "../../../../hooks/use-services.hook";
import styled from "styled-components";
import {HybridPageSideMenuContentComponent} from "./hybrid-page-side-menu-content.component";
import {HybridPageSideMenuHeaderComponent} from "./hybrid-page-side-menu-header.component";
import {HybridPageSideMenuFooterComponent} from "./hybrid-page-side-menu-footer.component";

const IonMenuBox = styled(IonMenu)`

    ${props => props.theme.screenMediaQuery.xsAndBelow} {
        width: 100vw;
        &::part(container) {
            width: 100vw;
        }    
    }
`

export const HybridPageSideMenuComponent: React.FC = observer(() => {
    const services = useServices();
    if(services.layout.shouldUseWebappLayout) {
        return null;
    }
    return (
        <IonMenuBox menuId={HYBRID_HOME_PAGE_SIDE_MENU_ID} contentId={HYBRID_HOME_PAGE_ID} side={"end"}>
            <HybridPageSideMenuHeaderComponent/>
            <HybridPageSideMenuContentComponent/>
            <HybridPageSideMenuFooterComponent/>

        </IonMenuBox>
    );
})