import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../hooks/use-services.hook";

const AlertContainerBox = styled.div<{$fontSize: string}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: ${props => props.$fontSize};
    font-weight: ${props => props.theme.fontWeight.medium};
`


const AlertTitleBox = styled.div<{$fullWidth?: boolean}>`
    width: ${props => props.$fullWidth ? "100%" : "fit-content"};
`

const InfoAlertTitleBox = styled(AlertTitleBox)`
    color: ${props => props.theme.colors.infoAlertTitleColor};
`

const SuccessAlertTitleBox = styled(AlertTitleBox)`
    color: ${props => props.theme.colors.successAlertTitleColor};
`


const WarningAlertTitleBox = styled(AlertTitleBox)`
    color: ${props => props.theme.colors.warningAlertTitleColor};
`

const ErrorAlertTitleBox = styled(AlertTitleBox)`
    color: ${props => props.theme.colors.errorAlertTitleColor};
`

const AlertContentBox = styled.div<{$fullWidth?: boolean}>`
    display: flex;
    flex-direction: column;
    gap: 4px;
    
    padding: ${props => props.theme.spacing.spacing16} ${props => props.theme.spacing.spacing12};
    border-radius: ${props => props.theme.border.defaultRadius};
    
    width: ${props => props.$fullWidth ? "100%" : "fit-content"};
    line-height: 1.2;
`

const InfoAlertContentBox = styled(AlertContentBox)`
    background-color: ${props => props.theme.colors.infoAlertBackground};
    color: ${props => props.theme.colors.infoAlertContentColor};
    border-left: 6px solid ${props => props.theme.colors.infoAlertBorderColor};
`

const SuccessAlertContentBox = styled(AlertContentBox)`
    background-color: ${props => props.theme.colors.successAlertBackground};
    color: ${props => props.theme.colors.successAlertContentColor};
    border-left: 6px solid ${props => props.theme.colors.successAlertBorderColor};
`

const WarningAlertContentBox = styled(AlertContentBox)`
    background-color: ${props => props.theme.colors.warningAlertBackground};
    color: ${props => props.theme.colors.warningAlertContentColor};
    border-left: 6px solid ${props => props.theme.colors.warningShade};
`

const ErrorAlertContentBox = styled(AlertContentBox)`
    background-color: ${props => props.theme.colors.errorAlertBackground};
    color: ${props => props.theme.colors.errorAlertContentColor};
    border-left: 6px solid ${props => props.theme.colors.errorAlertBorderColor};
`




export type AlertBoxSize = 'small' | 'medium' | 'large';

export type AlertBoxSeverity = "info" | "warning" | "success" | "error";

interface AlertBoxComponentProps extends PropsWithChildren {
    severity: AlertBoxSeverity;
    size: AlertBoxSize;
    title?: string | React.ReactElement;
    className?: string;
    fullWidth?: boolean;
    contentCssClass?: string;

}

export const AlertBoxComponent: React.FC<AlertBoxComponentProps> = observer((props) => {
    const services = useServices();
    const severity = props.severity ?? "warning";

    let fontSize: string;
    switch (props.size) {
        case "small":
            fontSize = services.theme.currentTheme.fontSize.warningAlertSmall;
            break;
        case "medium":
            fontSize = services.theme.currentTheme.fontSize.warningAlertMedium;
            break;
        default:
            fontSize = services.theme.currentTheme.fontSize.warningAlertLarge;
            break;
    }

    const renderTitle = () => {
        if(!props.title) {
            return null;
        }
        let TitleBox = WarningAlertTitleBox;
        if(severity === "success") {
            TitleBox = SuccessAlertTitleBox;
        } else if(severity === "error") {
            TitleBox = ErrorAlertTitleBox;
        } else if(severity === "info") {
            TitleBox = InfoAlertTitleBox;
        }

        return (
            <TitleBox $fullWidth={props.fullWidth}>
                {props.title}
            </TitleBox>
        )
    }

    let ContentBox = WarningAlertContentBox;
    if(severity === "success") {
        ContentBox = SuccessAlertContentBox;
    } else if(severity === "error") {
        ContentBox = ErrorAlertContentBox;
    } else if(severity === "info") {
        ContentBox = InfoAlertContentBox;
    }



    return (
        <AlertContainerBox $fontSize={fontSize} className={props.className}>
            <ContentBox $fullWidth={props.fullWidth} className={props.contentCssClass}>
                {renderTitle()}
                {props.children}
            </ContentBox>
        </AlertContainerBox>
    )
})