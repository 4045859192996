import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {BookingFlowPage} from "../../../booking-flow/booking-flow.page";
import {ExtrasPageContentComponent} from "../../../booking-flow/extras-selection/extras-page-content.component";




export const ManageMyBookingExtrasSelectionPage: React.FC = observer(() => {
    const services = useServices();
    return (
        <BookingFlowPage headerOptions={{
                            title: services.language.translate('Extras')
                         }}
                         contentOptions={{
                             renderContent: () => <ExtrasPageContentComponent/>
                         }}
                         shoppingCartOptions={{
                             showPromotionCode: false
                         }}/>
    );
})
