import React from "react";
import {IPassengerViewModel} from "../../../../services/booking/models/passenger/passenger-view-model.interface";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import styled from "styled-components";
import {IonToggle} from "@ionic/react";

const PrimaryContactToggleBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    gap: 6px;
    font-size: ${props => props.theme.fontSize.subtitle2};
    font-weight: ${props => props.theme.fontWeight.medium};
`

const LabelBox = styled.div`
    flex-grow: 1;
`

export const PrimaryContactToggleComponent: React.FC<{passenger: IPassengerViewModel}> = observer((props) => {
    const services = useServices();
    if (!props.passenger.passengerType.canBeMadePrimaryContact) {
        return null;
    }

    const onLabelClick = () => {
        props.passenger.isPrimaryContact = !props.passenger.isPrimaryContact;
    }

    return (
        <PrimaryContactToggleBox>
            <LabelBox onClick={onLabelClick}>
                {services.language.translate('Make this passenger the default contact')}
            </LabelBox>
            <IonToggle checked={props.passenger.isPrimaryContact}
                       onIonChange={(e) => props.passenger.isPrimaryContact = e.detail.checked}/>
        </PrimaryContactToggleBox>
    );
});
