import React from "react";
import {useServices} from "../../../../hooks/use-services.hook";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import styled from "styled-components";
import {StandardDialogPageComponent} from "../../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../../components/modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../../../../components/modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../../../../components/modal/footer/standard-dialog-footer.component";
import {AlertBoxComponent} from "../../../../components/alert-box/alert-box.component";
import {PlusBigIcon} from "../../../../assets/svg/plus-big.icon";
import {UpsellSuggestionComponent} from "../../../../components/upsell-suggestion-button/upsell-suggestion.component";
import {ShootingStarIcon} from "../../../../assets/svg/shooting-star.icon";
import {
    Priority3DIconComponent,
    SmallCabinBagSmaller3DIconComponent,
    TrolleyBagIconComponent
} from "./cabin-bags/priority-boarding/priority-boarding-icons.component";
import {IMediaChecks} from "../../../../services/media-query/media-queries.interface";
import {useContainerMediaQueriesChecks} from "../../../../hooks/use-container-media-queries-checks.hook";



const DialogContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: ${props => props.theme.spacing.spacing20};
`


const SubtitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.body1};
    text-align: justify;
`


const GridBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 3px 1fr;
    width: 100%;
    
    
`

const BagsContainerBox = styled(GridBox)`
    align-items: center;
    justify-content: center;
`

const VerticalLine = styled.div`
    border-left: 1px solid ${props => props.theme.border.mainColor};
    align-self: stretch;
`


const ChairImageBox = styled.img<{$shrinkPercentage: number}>`
    margin: ${props => props.theme.spacing.spacing16};
    max-height: calc(315px * ${props => props.$shrinkPercentage});
    max-width: calc(165px * ${props => props.$shrinkPercentage});
    
`

const SmallBagDescriptionBox = styled.div`
    text-align: center;
    padding-right: ${props => props.theme.spacing.spacing8};
`

const SmallBagNameBox = styled.div`
    font-size: ${props => props.theme.fontSize.body1};
    color: ${props => props.theme.colors.mediumShade};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    text-align: center;
`

const SmallBagDimensionsBox = styled.div`
    font-size: ${props => props.theme.fontSize.caption};
    color: ${props => props.theme.colors.mediumShade};
    text-align: center;
`

const PriorityBoardingDescriptionBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${props => props.theme.spacing.spacing8};
`

const PriorityIncludedBagsContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
`

const Priority3DIconWrapperBox = styled.div`
    margin-top: -1.2rem;
`

export enum PriorityBoardingSuggestionChoice {
    ChoosePriority = 1,
    ChooseSmallCabinBag = 2,
}


function get3DIconsShrinkPercentage(containerMediaQuery: IMediaChecks) {
    if(containerMediaQuery.xsAndBelow) {
        return 0.7;
    }

    if(containerMediaQuery.smallScreen) {
        return 0.8;
    }

    return 0.9;
}

interface PriorityBoardingSuggestionDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
}


export const PriorityBoardingSuggestionDialogComponent: React.FC<PriorityBoardingSuggestionDialogComponentProps> = (props) => {
    const services = useServices();
    const containerMediaQuery = useContainerMediaQueriesChecks();

    const onChoosePriority = () => {
        props.dialogHandler.accept(PriorityBoardingSuggestionChoice.ChoosePriority);
    };

    const onChooseSmallBag = () => {
        props.dialogHandler.accept(PriorityBoardingSuggestionChoice.ChooseSmallCabinBag);
    };

    const acceptButtonLabel = services.language.translationFor(`I'd like priority with 2 cabin bags`).withParams({
        ssrName: services.ssrTypes.PBRD.name
    });
    const rejectButtonLabel = services.language.translate('One bag under seat is sufficient');


    const chairShrinkPercentage = get3DIconsShrinkPercentage(containerMediaQuery);

    const getPriorityIconSize = () => {

        if(containerMediaQuery.smallScreen) {
            return 6;
        }

        return 7
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate('Just one small cabin bag ?')}
                                           dialogHandler={props.dialogHandler}/>
            <StandardDialogContentComponent dialogHandler={props.dialogHandler}>
                <DialogContentBox>
                    <SubtitleBox>
                        {services.language.translate('If you bring a second bag or if your bag is too big to fit under the seat you will be charged an additional fee at the boarding gate.')}
                    </SubtitleBox>
                    <AlertBoxComponent severity={"warning"}
                                       size={"large"}
                                       fullWidth={true}
                                       title={services.language.translate('If your hand luggage exceeds the permitted size, it will be checked at the price of up to €120!')}/>

                    <BagsContainerBox>
                        <SmallBagDescriptionBox>
                            <ChairImageBox $shrinkPercentage={chairShrinkPercentage}
                                           src={services.theme.currentTheme.assets.images.under_chair_bag_animation_gif.toString()}/>
                            <SmallBagNameBox>{services.language.translate('Small bag under the seat')}</SmallBagNameBox>
                            <SmallBagDimensionsBox>{services.language.translate('40 cm x 30 cm x 20 cm; max 10 kg')}</SmallBagDimensionsBox>
                        </SmallBagDescriptionBox>
                        <VerticalLine/>
                        <PriorityBoardingDescriptionBox>
                            <PriorityIncludedBagsContainerBox>
                                <SmallCabinBagSmaller3DIconComponent getCustomShrinkPercentage={get3DIconsShrinkPercentage}/>
                                <TrolleyBagIconComponent getCustomShrinkPercentage={get3DIconsShrinkPercentage}/>
                            </PriorityIncludedBagsContainerBox>
                            <PlusBigIcon/>
                            <Priority3DIconWrapperBox>
                                <Priority3DIconComponent getCustomIconSize={getPriorityIconSize}/>
                            </Priority3DIconWrapperBox>
                        </PriorityBoardingDescriptionBox>

                    </BagsContainerBox>

                </DialogContentBox>
            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler} horizontallyCentered={true}>
                <UpsellSuggestionComponent acceptButtonLabel={acceptButtonLabel}
                                           rejectButtonLabel={rejectButtonLabel}
                                           acceptButtonClick={onChoosePriority}
                                           rejectButtonClick={onChooseSmallBag}
                                           renderIcon={() => (<ShootingStarIcon/>)}/>

            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    )
}
