import React from "react";
import {observer} from "mobx-react";
import {
    IFlightDesignatorViewModel
} from "../../../../services/booking/models/designator/flight-designator-view-model.interface";
import {OperatedByComponent} from "../../../designator/operated-by.component";

export const AdvancedDesignatorOperatedByComponent: React.FC<{designator: IFlightDesignatorViewModel | null}> = observer((props) => {
    if(!props.designator) {
        return null;
    }

    return (
        <OperatedByComponent designator={props.designator}/>
    );
})