import React, {useRef} from "react";
import {useServices} from "../../hooks/use-services.hook";
import styled from "styled-components";
import {observer} from "mobx-react";
import {NullableNumber} from "../../types/nullable-types";
import {Check} from "../../types/type-checking";
import useIsInViewPort from "../../hooks/use-is-in-view-port.hook";
import {BackNavigationButtonComponent, ForwardNavigationButtonComponent} from "./navigation-buttons.component";

const CalendarBox = styled.div`
    display: flex;
    flex-direction: column;
    color: ${props => props.theme.colors.dark};
     font-size: ${props => props.theme.fontSize.body1};
    padding: 0 ${props => props.theme.spacing.spacing16};
    width: 100%;

`

const MonthNameContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    
    
`

const MonthNameBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.spacing20};
    flex-grow: 1;
    text-align: center;
    justify-content: center;
    font-size: ${props => props.theme.fontSize.h2};
    font-weight: ${props => props.theme.fontWeight.bold};
    
`

const CalendarHeaderBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 1;
    padding: ${props => props.theme.spacing.spacing16} 0;
`



const CellBox = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 52px;
`

const DayNameBox = styled(CellBox)`
    width: calc(100%/7);
    font-weight: ${props => props.theme.fontWeight.medium};
`

const DayBox = styled(CellBox)<{$borderRight: string; $borderBottom: string;}>`
    border-right: ${props => props.$borderRight} solid ${props => props.theme.border.mainColor};
    border-bottom: ${props => props.$borderBottom} solid ${props => props.theme.border.mainColor};
`


const WeekBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const WeekHeaderBox = styled(WeekBox)`
`

interface MonthCalendarHeaderComponentProps {
    month: number;
    year: number;
    showLeftButton: boolean;
    showRightButton: boolean;
    leftButtonDisabled: boolean;
    rightButtonDisabled: boolean;
    onLeftButtonClick?: () => void;
    onRightButtonClick?: () => void;
}


const CalendarHeaderComponent: React.FC<MonthCalendarHeaderComponentProps> = observer((props) => {
    const services = useServices();

    return (
        <CalendarHeaderBox>
            <MonthNameContainerBox>
                <BackNavigationButtonComponent iconSize={"large"} isVisible={props.showLeftButton} disabled={props.leftButtonDisabled} onClick={props.onLeftButtonClick}/>
                <MonthNameBox>
                    <span>
                        {services.time.getMonthFullNameFromIndex(props.month)}
                    </span>
                    <span>
                        {props.year}
                    </span>
                </MonthNameBox>

                <ForwardNavigationButtonComponent iconSize={"large"} isVisible={props.showRightButton} disabled={props.rightButtonDisabled} onClick={props.onRightButtonClick}/>

            </MonthNameContainerBox>


            <DayNamesHeaderComponent/>
        </CalendarHeaderBox>
    );
});

const DayNamesHeaderComponent: React.FC = observer(() => {
    const services = useServices();
    return (
        <WeekHeaderBox>
            {services.time.getWeekDaysAbbreviatedNames().map((dayName, index) => <DayNameBox key={`${dayName}_${index}`}>{dayName}</DayNameBox>)}
        </WeekHeaderBox>
    );
});

const WeeksContainerBox = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
`

function computeDayBorders(weekIndex: number, dayIndexInTheWeek: number, lastWeekIndex: number, isNotInTheMonth: boolean) {
    let right = "1px";
    let bottom = "1px";

    if(dayIndexInTheWeek === 6 || isNotInTheMonth) {
        right = "0"
    }

    if(weekIndex === lastWeekIndex) {
        bottom = "0";
    }

    return {
        $borderRight: right,
        $borderBottom: bottom,
    }
}

interface DateComponentProps {
    date: Date;
    month: number;
    year: number;
    weekIndex: number;
    dayIndexInTheWeek: number;
    lastWeekIndex: number;
    renderDate?: (date: Date) => React.ReactElement;
    onClick?: (date: Date) => void;
}


const DateComponent: React.FC<DateComponentProps> = observer((props) => {
    const date = props.date;
    let day: NullableNumber = date.getDate();
    let isNotInTheMonth = date.getMonth() !== props.month || date.getFullYear() !== props.year;


    if(date.getMonth() !== props.month || date.getFullYear() !== props.year) {
        day = null;
    }


    const onClickHandler = () => {
        if(!props.onClick) {
            return;
        }
        if(Check.isNullOrUndefined(day)) {
            return;
        }


        props.onClick(date);
    }

    const getDayContent = () => {
        if(isNotInTheMonth) {
            return null;
        }

        if(props.renderDate) {
            return props.renderDate(date);
        }

        return day;
    }



    return (
        <DayBox key={date.toDateString()}
                onClick={onClickHandler}
                {...computeDayBorders(props.weekIndex, props.dayIndexInTheWeek, props.lastWeekIndex, isNotInTheMonth)}>
            {getDayContent()}
        </DayBox>
    );
})


interface CalendarContentComponentProps {
    month: number;
    year: number;
    onDateClick: (date: Date) => void;
    renderDate?: (date: Date) => React.ReactElement;
}

const CalendarContentComponent: React.FC<CalendarContentComponentProps> = observer((props) => {
    const services = useServices();
    const weeks = services.time.getMonthCalendarWeeks(props.month, props.year);
    const lastWeekIndex = weeks.length - 1;
    const renderDate = (date: Date, weekIndex: number, dayIndexInTheWeek: number) => {
        return (
            <DateComponent key={date.toDateString()}
                           date={date}
                           renderDate={props.renderDate}
                           month={props.month}
                           year={props.year}
                           weekIndex={weekIndex}
                           dayIndexInTheWeek={dayIndexInTheWeek}
                           lastWeekIndex={lastWeekIndex}
                           onClick={props.onDateClick}/>
        );
    }

    const renderWeek = (week: Date[], weekIndex: number) => {
        return (
            <React.Fragment key={weekIndex}>
                {week.map((date, dayIndexInTheWeek) => renderDate(date, weekIndex, dayIndexInTheWeek))}
            </React.Fragment>
        )
    }

    return (
        <WeeksContainerBox>
            {weeks.map(renderWeek)}
        </WeeksContainerBox>
    );
});

interface CalendarComponentProps {
    month: number;
    year: number;
    onDateClick: (date: Date) => void;
    renderDate: (date: Date, isInViewPort: boolean) => React.ReactElement;
    showLeftButton?: boolean;
    showRightButton?: boolean;
    leftButtonDisabled?: boolean;
    rightButtonDisabled?: boolean;
    onLeftButtonClick?: () => void;
    onRightButtonClick?: () => void;
}

export const CalendarComponent: React.FC<CalendarComponentProps> = observer((props) => {
    let elementRef = useRef<HTMLDivElement | null>(null)
    let isInViewPort = useIsInViewPort(elementRef)


    const contentProps: CalendarContentComponentProps = {
        month: props.month,
        year: props.year,
        onDateClick: props.onDateClick,
        renderDate: (date) => props.renderDate(date, isInViewPort)
    }



    return (
        <CalendarBox ref={elementRef}>
            <CalendarHeaderComponent month={props.month}
                                     year={props.year}
                                     showLeftButton={props.showLeftButton ?? false}
                                     showRightButton={props.showRightButton ?? false}
                                     leftButtonDisabled={props.leftButtonDisabled ?? false}
                                     rightButtonDisabled={props.rightButtonDisabled ?? false}
                                     onLeftButtonClick={props.onLeftButtonClick}
                                     onRightButtonClick={props.onRightButtonClick}/>
            <CalendarContentComponent {...contentProps}/>
        </CalendarBox>
    );
});
