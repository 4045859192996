import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import {PrimaryButton} from "../../basic-components/buttons/primary-button";
import {useServices} from "../../hooks/use-services.hook";
import styled, {css} from "styled-components";
import {PrimaryButtonInverted} from "../../basic-components/buttons/primary-button-inverted";


const ContinueButton = styled(PrimaryButton)<{$inactive?: boolean}>`
    font-size: ${props => props.theme.fontSize.h4};
    ${
        props => props.fullWidth 
                ? css`` 
                : css`
                    max-width: 120px;
                `
    }
        
`

const ContinueButtonInverted = styled(PrimaryButtonInverted)<{$inactive?: boolean}>`
    font-size: ${props => props.theme.fontSize.h4};
    ${
          props => props.fullWidth
                  ? css``
                  : css`
                    max-width: 120px;
                `
    }
`


interface ContinueButtonComponentProps extends PropsWithChildren {
    onClick: () => void;
    className?: string;
    useInvertedButton?: boolean;
    fullWidth?: boolean
    inactive?: boolean;
    customButtonText?: string;
}
export const ContinueButtonComponent: React.FC<ContinueButtonComponentProps> = observer((props) => {
    const services = useServices();
    const Button = props.useInvertedButton ? ContinueButtonInverted : ContinueButton;
    return (
        <Button onClick={props.onClick} fullWidth={props.fullWidth} className={props.className} inactive={props.inactive}>
            {props.customButtonText ?? services.language.translate('Continue')}
        </Button>
    );
});
