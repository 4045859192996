import React from "react";
import {observer} from "mobx-react";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import {ISsrType} from "../../services/ssr-types/ssr-types.service.interface";
import {useServices} from "../../hooks/use-services.hook";
import styled from "styled-components";
import { ExclamationCircle } from "../../assets/svg/exclamation-circle.icon";
import {StandardDialogPageComponent} from "../modal/page/standard-dialog-page.component";
import {StandardDialogContentComponent} from "../modal/content/standard-dialog-content.component";
import {ISeatViewModel} from "../../services/booking/models/seat-maps/seat-view-model.interface";
import {PrimaryButton} from "../../basic-components/buttons/primary-button";
import {PrimaryButtonInverted} from "../../basic-components/buttons/primary-button-inverted";

const DialogContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap40};
    padding-top: ${props => props.theme.spacing.spacing30};
`


const MessageBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
`

const ButtonsContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap20};
`

interface SeatSsrValidationDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
    seats: ISeatViewModel[];
    ssrType: ISsrType;
}
export const SeatSsrValidationDialogComponent: React.FC<SeatSsrValidationDialogComponentProps> = observer((props) => {
    const services = useServices();
    const removeSeat = async () => {
        props.dialogHandler.accept();
    }

    const keepSeat = () => {
        props.dialogHandler.reject();
    }

    const distinctSeats = props.seats.distinct(seat => seat.seatNumber, seat => seat.seatNumber);

    const getMessage = () => {

        if(distinctSeats.length === 1) {
            return services.language.translationFor(`Your seat {seatNumber} doesn't allow {ssrName}`)
                                    .withParams({seatNumber: distinctSeats[0], ssrName: props.ssrType.name});
        } else {

            return services.language.translationFor(`Your seats {seatsNumbers} don't allow {ssrName}`)
                                    .withParams({seatsNumbers: distinctSeats.join(', '), ssrName: props.ssrType.name});
        }
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogContentComponent dialogHandler={props.dialogHandler}>
                <DialogContentBox>
                    <ExclamationCircle />
                    <MessageBox>
                        {getMessage()}
                    </MessageBox>
                    <ButtonsContainerBox>
                        <PrimaryButton onClick={keepSeat} fullWidth={true}>
                            {
                                distinctSeats.length === 1
                                    ? services.language.translate('I want to keep my seat')
                                    : services.language.translate('I want to keep my seats')
                            }
                        </PrimaryButton>
                        <PrimaryButtonInverted onClick={removeSeat} fullWidth={true}>
                            {
                                distinctSeats.length === 1
                                    ? services.language.translate('I will choose a different seat')
                                    : services.language.translate('I will choose different seats')
                            }
                        </PrimaryButtonInverted>
                    </ButtonsContainerBox>

                </DialogContentBox>

            </StandardDialogContentComponent>

        </StandardDialogPageComponent>
    );
});

