import {NullableDate, NullableString} from "../../../../types/nullable-types";
import {NullableGender} from "../../../dot-rez-api/data-contracts/enums/gender.enum";

export enum PsoPassengerTypeEnum {
    Resident = "RESIDENT",
    Native = "NATIVE"
}

export interface IPassengerInfoFields {
    firstName: NullableString;
    lastName: NullableString;
    gender: NullableGender;
    dateOfBirth: NullableDate;
    customerNumber: NullableString;
    nationality: NullableString;
    psoPassengerType: PsoPassengerTypeEnum | null;
    addressCity: NullableString;
}
