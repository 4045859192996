import React from "react";
import {observer} from "mobx-react";
import {
    IFlightSearchControllerViewModel
} from "../../../services/flight-search/flight-search-controller/flight-search-controller-view-model.interface";
import styled from "styled-components";
import {
    PsoUserOptionComponent
} from "../../territorial-continuity-regime/pso-user-option.component";
import {PsoUserOptionsEnum} from "../../../services/flight-search/flight-search-controller/pso.enums";

const UserOptionBox = styled(PsoUserOptionComponent)`
    background-color: inherit;
    border: none;
    padding: 0;
    width: fit-content;
`


interface SearchMaskPsoMarketUserOptionsInlineComponentProps {
    searchController: IFlightSearchControllerViewModel;
}

export const SearchMaskPsoMarketUserOptionsInlineComponent: React.FC<SearchMaskPsoMarketUserOptionsInlineComponentProps> = observer((props) => {
    const searchController = props.searchController;

    const psoMarket = searchController.getPsoMarket();
    if(!psoMarket) {
        return null;
    }

    const renderSeasonOption = () => {
        if(!psoMarket.activeSeason) {
            return null;
        }

        return (
            <UserOptionBox optionValue={PsoUserOptionsEnum.Worker} searchController={searchController}/>
        );
    }

    return (
        <>
            <UserOptionBox optionValue={PsoUserOptionsEnum.Resident} searchController={searchController}/>
            {renderSeasonOption()}
        </>
    )
})