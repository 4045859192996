import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';
export const InfantCircleFilledIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.4} fill="none" {...props} viewBox="0 0 79 79" ratio={79 / 79}>
            <rect x="1.21875" y="1.21875" width="76.5625" height="76.5625" rx="38.2812" stroke="#102930"
                  strokeWidth="2.4375"/>
            <path
                d="M33.1386 26.0879C33.1386 25.3677 33.2805 24.6545 33.5561 23.9891C33.8317 23.3237 34.2357 22.7191 34.745 22.2099C35.2542 21.7006 35.8588 21.2966 36.5242 21.021C37.1896 20.7454 37.9028 20.6035 38.623 20.6035C39.3432 20.6035 40.0564 20.7454 40.7218 21.021C41.3872 21.2966 41.9918 21.7006 42.501 22.2099C43.0103 22.7191 43.4143 23.3237 43.6899 23.9891C43.9655 24.6545 44.1074 25.3677 44.1074 26.0879C44.1074 26.8081 43.9655 27.5213 43.6899 28.1867C43.4143 28.8521 43.0103 29.4567 42.501 29.9659C41.9918 30.4752 41.3872 30.8792 40.7218 31.1548C40.0564 31.4304 39.3432 31.5723 38.623 31.5723C37.9028 31.5723 37.1896 31.4304 36.5242 31.1548C35.8588 30.8792 35.2542 30.4752 34.745 29.9659C34.2357 29.4567 33.8317 28.8521 33.5561 28.1867C33.2805 27.5213 33.1386 26.8081 33.1386 26.0879ZM24.5845 30.3916C25.5748 29.0281 27.4791 28.7311 28.8425 29.7213L31.6 31.7246C33.6414 33.21 36.1017 34.0098 38.623 34.0098C41.1443 34.0098 43.6046 33.21 45.646 31.7246L48.4035 29.7137C49.7669 28.7234 51.6712 29.0281 52.6615 30.384C53.6517 31.7398 53.347 33.6518 51.9912 34.642L49.2337 36.6529C48.1978 37.407 47.0933 38.0393 45.9355 38.5572V41.3223H31.3105V38.5572C30.1527 38.0469 29.0482 37.407 28.0123 36.6529L25.2548 34.642C23.8914 33.6518 23.5943 31.7475 24.5845 30.384V30.3916ZM31.4248 44.4682L36.0408 48.5053L34.0603 51.3389L35.9113 53.1898C37.0996 54.3781 37.0996 56.3053 35.9113 57.5012C34.723 58.6971 32.7959 58.6895 31.6 57.5012L27.9437 53.8449C26.8925 52.7938 26.7478 51.1484 27.5933 49.9373L31.4171 44.4682H31.4248ZM41.2128 48.5053L45.8289 44.4682L49.6527 49.9373C50.4982 51.1484 50.3535 52.7938 49.3099 53.8373L45.6537 57.4936C44.4654 58.6818 42.5382 58.6818 41.3423 57.4936C40.1464 56.3053 40.1541 54.3781 41.3423 53.1822L43.1933 51.3312L41.2128 48.4977V48.5053Z"
                fill={props.color? props.color: "currentColor"}/>
        </SvgIcon>
    )
}
