import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {useServices} from "../../hooks/use-services.hook";
import {IonPopover} from "@ionic/react";
import {NullableNumber} from "../../types/nullable-types";
import {CardBox} from "../../basic-components/card/card.box";

const PopoverNotificationAnchorBox = styled.div`
    width: 30%;
    height: 1px;
`

const PopoverContentBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: ${props => props.theme.fontSize.subtitle2};
    overflow: hidden;
`


export const ShoppingCartNotificationsComponent: React.FC = observer(() => {
    const services = useServices();
    const elementRef = useRef<any>();
    const [popoverState, setPopoverState] = useState({
        showPopover: false,
        event: undefined
    });

    const booking = services.booking.current;
    const notification = booking.shoppingCart.notifications.current;

    useEffect(() => {
        let timeout: NullableNumber = null;
        if(notification) {
            timeout = setTimeout(() => {
                elementRef.current?.click(); //Because popover can only work with a click event
            });
        }

        return () => {
            if(timeout) {
                clearTimeout(timeout);
            }
        }
    })

    const onNotificationDismiss = () => {
        setPopoverState({showPopover: false, event: undefined});
        booking.shoppingCart.notifications.current?.dismiss();
    }

    return (
        <>
            <PopoverNotificationAnchorBox ref={elementRef} onClick={(e: any) => {
                e.persist();
                setPopoverState({showPopover: true, event: e})
            }}/>

            <IonPopover isOpen={popoverState.showPopover && Boolean(notification)}
                        className={'shopping-cart-notifications-popover'}
                        onDidDismiss={onNotificationDismiss}
                        event={popoverState.event}
                        showBackdrop={false}
                        reference={"trigger"}
                        side={"top"}>
                <PopoverContentBox>
                    {notification?.message}
                </PopoverContentBox>
            </IonPopover>
        </>
    )
});
