import React from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {CardBox} from "../../../../basic-components/card/card.box";
import {useServices} from "../../../../hooks/use-services.hook";
import {
    IPaymentMethodGroupViewModel
} from "../../../../services/booking/models/payment/payment-selection/payment-method-group.view-model.interface";
import {
    IPaymentSelectionHandlerViewModel
} from "../../../../services/booking/models/payment/payment-selection/payment-selection-handler.view-model.interface";

const ContainerBox = styled.div<{$disabled: boolean}>`
    display: flex;
    flex-direction: column;
    color: ${props => props.theme.colors.dark};
    opacity: ${props => props.$disabled ? 0.4 : 1};
`

const HeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding-left: ${props => props.theme.spacing.spacing24};
    font-size: ${props => props.theme.fontSize.h4};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    gap: 4px;
    ${props => props.theme.containerMediaQuery.xsAndBelow} {
        padding-left: ${props => props.theme.spacing.spacing16};
    }
`

const HeaderItemBox = styled.div<{$isSelected: boolean; $isDisabled: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${props => props.theme.spacing.spacing16} ${props => props.theme.spacing.spacing24};
    border-top-left-radius: ${props => props.theme.border.defaultRadius};
    border-top-right-radius: ${props => props.theme.border.defaultRadius};
    border: 1px solid  ${props => props.theme.colors.lightShade};
    border-bottom: none;
    overflow: hidden;
    /*box-shadow: 0 34px 26px rgba(13, 10, 44, 0.05), 0 12px 60px rgba(13, 10, 44, 0.08);*/
    ${props => props.$isSelected
        ? css`
            background-color: ${props => props.theme.colors.cardBackground};
        `
        : css`
            background-color: ${props => props.theme.colors.lightShade};
        `
    }

    ${props => props.$isSelected || props.$isDisabled
            ? css`
                cursor: default;
            `   
            : css`
                cursor: pointer;
            `
    }
    
    
    
`

const ContentBox = styled(CardBox)`
    padding: ${props => props.theme.spacing.spacing24};
    border: none;
    ${props => props.theme.containerMediaQuery.xsAndBelow} {
        padding: ${props => props.theme.spacing.spacing20} ${props => props.theme.spacing.spacing16};
    }
`

interface PaymentMethodGroupsTabsComponentProps {
    selectionHandler: IPaymentSelectionHandlerViewModel;

}

export const PaymentMethodGroupsTabsComponent: React.FC<PaymentMethodGroupsTabsComponentProps> = observer((props) => {

    const services = useServices();
    const isDisabled = services.booking.current.balanceDue.amount <= 0;
    const renderHeaderItem = (group: IPaymentMethodGroupViewModel) => {

        const onClickHandler = () => {
            if(!isDisabled) {
                props.selectionHandler.selectedGroup = group
            }

        }
        return (
            <HeaderItemBox key={group.groupType}
                           $isSelected={props.selectionHandler.selectedGroup.groupType === group.groupType}
                           $isDisabled={isDisabled}
                           onClick={onClickHandler}>
                {group.renderTabHeader()}
            </HeaderItemBox>
        )
    }


    return (
        <ContainerBox $disabled={isDisabled}>
            <HeaderBox>
                {props.selectionHandler.groups.map(renderHeaderItem)}
            </HeaderBox>
            <ContentBox>
                {props.selectionHandler.selectedGroup.renderTabContent()}
            </ContentBox>
        </ContainerBox>
    );
})