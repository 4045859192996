import React from "react";
import {observer} from "mobx-react";
import {StandardDialogPageComponent} from "../modal/page/standard-dialog-page.component";
import {useServices} from "../../hooks/use-services.hook";
import {StandardDialogContentComponent} from "../modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../modal/footer/standard-dialog-footer.component";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import styled from "styled-components";
import {YesNoDialogPrimaryButton} from "../../services/dialog-factory/yes-no-dialog.enums";
import {PrimaryButton} from "../../basic-components/buttons/primary-button";
import {NeutralButton} from "../../basic-components/buttons/neutral-button";
import {ExclamationDiamondIcon} from "../../assets/svg/exclamation-diamond.icon";
import {useScreenMediaQueriesChecks} from "../../hooks/use-screen-media-queries-checks.hook";


const DialogTextBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    flex-grow: 1;
    font-size: ${props => props.theme.fontSize.h2};
    font-weight: ${props => props.theme.fontWeight.bold};
    gap: ${props => props.theme.spacing.spacing48};
`

const ButtonsContainer = styled.div<{$flexDirection: string}>`
    display: flex;
    flex-direction: ${props => props.$flexDirection};
    gap: ${props => props.theme.spacing.spacing20};
    align-items: center;
    justify-content: center;
`

const PrimaryButtonBox = styled(PrimaryButton)`
    min-width: 170px;
    ${props => props.theme.screenMediaQuery.smallScreen} {
        min-width: 120px;
    }
`

const NeutralButtonBox = styled(NeutralButton)`
    min-width: 170px;
    ${props => props.theme.screenMediaQuery.smallScreen} {
        min-width: 120px;
    }
`

interface YesNoDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
    message: string | React.ReactElement;
    primaryButton: YesNoDialogPrimaryButton;
    yesButtonText?: string;
    noButtonText?: string;

}
export const YesNoDialogComponent: React.FC<YesNoDialogComponentProps> = observer((props) => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();
    let yesButtonText = props.yesButtonText;
    let noButtonText = props.noButtonText;

    let buttonsFlexDirection = "column";


    if(!yesButtonText && !noButtonText) {
        yesButtonText = services.language.translate('Yes');
        noButtonText = services.language.translate('No');
        buttonsFlexDirection = "row"
    }

    const buttonsFullWidth = buttonsFlexDirection === 'column';

    const onYesClick = async () => {
        props.dialogHandler.accept();
    }

    const onNoClick = async () => {
        props.dialogHandler.reject();
    }

    const renderPrimaryButton = () => {
        if(props.primaryButton === YesNoDialogPrimaryButton.PrimaryButtonNo) {
            return (
                <PrimaryButtonBox onClick={onNoClick} fullWidth={buttonsFullWidth}>
                    {noButtonText}
                </PrimaryButtonBox>
            )
        } else {
            return (<PrimaryButtonBox onClick={onYesClick} fullWidth={buttonsFullWidth}>
                {yesButtonText}
            </PrimaryButtonBox>);
        }
    }

    const renderSecondaryButton = () => {
        if(props.primaryButton === YesNoDialogPrimaryButton.PrimaryButtonYes) {
            return (
                <NeutralButtonBox onClick={onNoClick} fullWidth={buttonsFullWidth}>
                    {noButtonText}
                </NeutralButtonBox>
            )
        } else {
            return (<NeutralButtonBox onClick={onYesClick} fullWidth={buttonsFullWidth}>
                {yesButtonText}
            </NeutralButtonBox>);
        }
    }

    return (
        <StandardDialogPageComponent>
            {/*<StandardDialogHeaderComponent dialogHandler={props.dialogHandler}/>*/}
            <StandardDialogContentComponent dialogHandler={props.dialogHandler} fullContentHeight={true}>
                <DialogTextBox>
                    <ExclamationDiamondIcon size={screenMediaQuery.smallScreen ? 3 : 5.65} color={services.theme.currentTheme.colors.tertiaryTint}/>
                    {props.message}
                </DialogTextBox>
            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <ButtonsContainer $flexDirection={buttonsFlexDirection}>
                    {renderPrimaryButton()}
                    {renderSecondaryButton()}
                </ButtonsContainer>

            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    );
});
