import React from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {CheckboxComponent, CheckBoxLabelPlacement} from "../../../basic-components/checkbox/checkbox.component";

const ContainerBox = styled.div<{$isSelected: boolean; $fullWidth: boolean; $checkboxLabelPlacement: CheckBoxLabelPlacement}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    
    border-radius: ${props => props.theme.border.defaultRadius};
    ${props => props.$isSelected
            ? css`
                border: 1px solid ${props => props.theme.colors.primary};
                background-color: ${props => props.theme.colors.cardBackground};
            `
            : css`
                border: 1px solid ${props => props.theme.colors.dark};
            `
    }

    ${props => props.$fullWidth
            ? css`
                width: 100%;
            `
            : css`
                width: fit-content;
            `
    }

    ${props => props.$checkboxLabelPlacement === "stacked"
            ? css`
                padding: ${props => props.theme.spacing.spacing16};
            `
            : css`
                padding: ${props => props.theme.spacing.spacing8} ${props => props.theme.spacing.spacing16};
            `
    }
`

const CheckBox = styled(CheckboxComponent)<{$fullWidth: boolean}>`
    ${props => props.$fullWidth 
            ? css`
                width: 100%;
            `
            : ``
    }
`

interface SameForAllComponentProps {
    label: string;
    checked: () => boolean;
    onChanged: (checked: boolean) => void;
    className?: string;
    checkBoxClassName?: string;
    fullWidth?: boolean;
    checkboxLabelPlacement?: CheckBoxLabelPlacement;
}

export const SameForAllComponent: React.FC<SameForAllComponentProps> = observer((props) => {
    const isSelected = props.checked();
    const fullWidth = props.fullWidth ?? false;
    const checkboxLabelPlacement = props.checkboxLabelPlacement ?? "start"
    return (
        <ContainerBox className={props.className} $isSelected={isSelected} $fullWidth={fullWidth} $checkboxLabelPlacement={checkboxLabelPlacement}>
            <CheckBox checked={isSelected}
                      className={props.checkBoxClassName}
                      label={props.label}
                      labelPlacement={checkboxLabelPlacement}
                      $fullWidth={fullWidth}
                      onChange={isChecked => props.onChanged(isChecked)}/>
        </ContainerBox>
    )
});



