import styled from "styled-components";
import React from "react";
import {observer} from "mobx-react";

const ContainerBox = styled.div`
    display: flex;
    flex-direction: column;
`
const TitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.h1};
    font-weight: ${props => props.theme.fontWeight.bold};
`

const SubTitleBox = styled(TitleBox)<{$isVisible: boolean}>`
    color: ${props => props.theme.colors.success};
    visibility: ${props => props.$isVisible ? "visible": "hidden"};
`

interface HybridHomePageTabTitleComponentProps {
    title: string;
    subtitle?: string;
}
export const HybridHomePageTabTitleComponent: React.FC<HybridHomePageTabTitleComponentProps> = observer((props) => {

    return (
        <ContainerBox>
            <TitleBox>
                {props.title}
            </TitleBox>
            <SubTitleBox $isVisible={Boolean(props.subtitle)}>
                {props.subtitle ?? "Placeholder"}
            </SubTitleBox>
        </ContainerBox>
    )
})

