import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {
    FlightDatesDropdownComponent
} from "../../../flight-search/dates-selection/drop-down/flight-dates-dropdown.component";
import {
    PassengersTypesDropDownComponent
} from "../../../flight-search/passenger-types-selection/drop-down/passengers-types-drop-down.component";
import {
    SearchMaskBookNowButtonComponent
} from "../../common/search-mask-book-now-button.component";
import {useServices} from "../../../../hooks/use-services.hook";
import {
    OriginStationDropDownComponent
} from "../../../flight-search/stations-selection/drop-down/origin-station-drop-down.component";
import {
    DestinationStationDropDownComponent
} from "../../../flight-search/stations-selection/drop-down/destination-station-drop-down.component";
import {CardBox} from "../../../../basic-components/card/card.box";
import {SearchMaskPsoMarketUserOptionsPopperComponent} from "../../common/search-mask-pso-market-user-options-popper.component";
import {
    SearchMaskSwapStationsButtonLargeScreenComponent
} from "./search-mask-swap-stations-button.lage-screen.component";
import {SearchMaskControllerModel} from "../search-mask-controller.model";


const FieldsCardBox = styled(CardBox)`
    display: grid;
    column-gap: ${props=> props.theme.spacing.spacing20};
    row-gap: ${props=> props.theme.spacing.spacing8};
    grid-template-columns: 2.8fr 1.2fr 1fr 0.9fr;
    grid-template-rows: repeat(2, auto);
    padding: ${props => props.theme.spacing.spacing40} ${props => props.theme.spacing.spacing20};
    align-items: stretch;
    justify-content: stretch;
    justify-items: stretch;
    width: 100%;
`

const FirstRowCell = styled.div`
    grid-row: 1/2;
    display: flex;
    flex-direction: column;
    align-items: center;
`


const SecondRowCell = styled.div`
    grid-row: 2/3;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
`


const StationsContainerBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    gap: 0;
`

const OriginStation = styled(OriginStationDropDownComponent)`
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    padding-right: ${props => props.theme.spacing.spacing30};
    flex-basis: 50%;

    not(&:focus-within) {
        border-right: none;
    }
`

const DestinationStation = styled(DestinationStationDropDownComponent)`
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    padding-left: ${props => props.theme.spacing.spacing30};
    flex-basis: 50%;
`

export const SearchMaskWebappLageScreenComponent: React.FC = observer(() => {
    const services = useServices();
    const searchMaskControllerRef = useRef(new SearchMaskControllerModel());

    useEffect(() => {
        const searchMaskController = searchMaskControllerRef.current;
        return () => {
            searchMaskController?.dispose();
        }
    }, []);

    return (
        <FieldsCardBox>
            <FirstRowCell style={{gridColumn:'2/3'}}>
                <SearchMaskPsoMarketUserOptionsPopperComponent searchMaskController={searchMaskControllerRef.current}/>
            </FirstRowCell>
            <SecondRowCell>
                <StationsContainerBox>

                    <SearchMaskSwapStationsButtonLargeScreenComponent/>

                    <OriginStation/>

                    <DestinationStation isOnSmallScreen={false}/>

                </StationsContainerBox>
            </SecondRowCell>

            <SecondRowCell>
                <FlightDatesDropdownComponent searchMaskController={searchMaskControllerRef.current}/>
            </SecondRowCell>

            <SecondRowCell>
                <PassengersTypesDropDownComponent/>
            </SecondRowCell>

            <SecondRowCell>
                <SearchMaskBookNowButtonComponent onClick={async () => await services.flightSearch.startBooking()}/>
            </SecondRowCell>


        </FieldsCardBox>

    );
})