import React from "react";
import {observer} from "mobx-react";
import {ISsrTypeCount} from "../../../../services/booking/models/ssrs/ssr-type-count.interface";
import {useServices} from "../../../../hooks/use-services.hook";
import styled, {css} from "styled-components";
import {ban, checkmark, checkmarkOutline} from "ionicons/icons";
import {IonIcon} from "@ionic/react";
import {ErrorMessageTextBoxComponent} from "../../../../components/error-message/error-message-text-box.component";
import {IPassengerAllBagsViewModel} from "../../../../services/booking/models/ssrs/bags/passenger-all-bags-view-model.interface";

const PassengerBagsSummaryBox = styled.div<{$allowClick: boolean}>`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.spacing16};
    width: 100%;
    padding: ${props => props.theme.spacing.spacing24};
    ${props => props.$allowClick 
            ? css`
                    cursor: pointer;
            `
            : `
            `
    }
`

const SelectedBagsListBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    font-size: ${props => props.theme.fontSize.body1};
`

const SelectedBagBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.spacing8};
`

const NoSelectedBagBox = styled(SelectedBagBox)`
    color: ${props => props.theme.colors.medium};
    justify-content: center;
`

const BagNameAndQuantityBox = styled.span`
    flex-grow: 1;
`

const SelectedBagComponent: React.FC<{bagCount: ISsrTypeCount}> = observer((props) => {
  return (
      <SelectedBagBox>
          <IonIcon ios={checkmarkOutline} md={checkmark}/>
          <BagNameAndQuantityBox>
              {`${props.bagCount.count} x ${props.bagCount.ssrType.name}`}
            </BagNameAndQuantityBox>
        </SelectedBagBox>
    )
});


interface PassengerBagsSummaryComponentProps {
    passengerBags: IPassengerAllBagsViewModel;
    allowClick: boolean;
    onClick: () => void;
}
export const PassengerBagsSummaryComponent: React.FC<PassengerBagsSummaryComponentProps> = observer((props) => {
    const services = useServices();
    const cabinBag = props.passengerBags.cabinBag;
    const checkinBags = props.passengerBags.checkInBags;

    const cabinBagSsrCount: ISsrTypeCount[] = [];
    if(cabinBag.newQuantityWasSet) {
        if(cabinBag.newQuantity === 0) {
            cabinBagSsrCount.push({
                ssrType: services.ssrTypes.SCBG,
                count: 1
            })
        } else {
            cabinBagSsrCount.push({
                ssrType: cabinBag.ssrType,
                count: cabinBag.newQuantityToDisplay
            });
        }
    }

    const checkInBagsSsrCount = checkinBags.filter(ssrEditor => ssrEditor.newQuantity > 0)
        .map(ssrEditor => {
            return {
                ssrType: ssrEditor.ssrType,
                count: ssrEditor.newQuantityToDisplay
            };
        });

    const allBagsCount = [
        ...cabinBagSsrCount,
        ...checkInBagsSsrCount
    ];

    const captureElementRef = (el: any) => {
        props.passengerBags.attachCabinBagsContainerDOMElement(el);
        props.passengerBags.attachCheckInBagsContainerDOMElement(el);
    }

    const renderBags = () => {
        if(allBagsCount.length === 0 ) {
            return (<NoSelectedBagBox>
                <IonIcon icon={ban}/>
                <div>
                    {services.language.translate('No bags selected')}
                </div>

            </NoSelectedBagBox>)

        }

        return allBagsCount.map(bag => <SelectedBagComponent key={bag.ssrType.ssrCode} bagCount={bag}/>);
    }

    const onClick = () => {
        if(props.allowClick) {
            props.onClick();
        }
    }

    return (
        <PassengerBagsSummaryBox ref={captureElementRef} onClick={onClick} $allowClick={props.allowClick}>
            <SelectedBagsListBox>
                {renderBags()}
            </SelectedBagsListBox>
            <ErrorMessageTextBoxComponent errorMessage={props.passengerBags.cabinBagErrorMessage}/>
            <ErrorMessageTextBoxComponent errorMessage={props.passengerBags.checkInBagsErrorMessage}/>
        </PassengerBagsSummaryBox>

    );
});
