import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../../../hooks/use-services.hook";

const GreetingMessageBox = styled.div`
    font-size: ${props => props.theme.fontSize.huge3};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.colors.primaryShade};
`


export const HomePageGreetingMessageComponent: React.FC<{className?: string}> = observer((props) => {
    const services = useServices();
    return (
        <GreetingMessageBox className={props.className}>
            {services.language.translationFor("It's {today}, let's find some flights!").withParams({
                today: services.time.getDayFullName(services.time.currentDate.getDay())
            })}
        </GreetingMessageBox>
    );
})