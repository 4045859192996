import {makeObservable, observable, computed, runInAction} from "mobx";
import { IPassengerSegmentViewModel } from "../passenger-segment/passenger-segment-view-model.interface";
import {
    IBookingSeatsMapsEditorViewModel,
} from "./booking-seats-maps-editor-view-model.interface";
import {BookingModel} from "../booking.model";
import {SegmentSeatMapEditorModel} from "./segment-seat-map-editor.model";
import {BookingSessionStorageKeys} from "../storage/booking-storage.interface";
import {NullableString} from "../../../../types/nullable-types";
import {SeatModel} from "./seat.model";
import {PetSeatFilterModel} from "./filters/pet-seat-filter.model";
import {ChildSeatFilterModel} from "./filters/child-seat-filter.model";
import {ISeatFilterModel} from "./filters/seat-filter-model.interface";
import {SpecialAssistanceSeatFilterModel} from "./filters/special-assistance-seat-filter.model";
import {PassengerSegmentModel} from "../passenger-segment/passenger-segment.model";

export class BookingSeatsMapsEditorModel implements IBookingSeatsMapsEditorViewModel {

    constructor(private booking: BookingModel) {
        this._currentSegmentKey = this.booking.storage.getItem(BookingSessionStorageKeys.currentSegmentSeatMap) || null;
        makeObservable<this, '_currentSegmentKey'>(this, {
            _currentSegmentKey: observable.ref,
            currentSeatMap: computed,
            seatMaps: computed,
            allPassengerSegments: computed
        });

        this.seatFilters = [
            new ChildSeatFilterModel(this.booking.services),
            new SpecialAssistanceSeatFilterModel(this.booking.services),
            new PetSeatFilterModel(this.booking.services)
        ]
    }

    private _currentSegmentKey: NullableString = null;
    private _previousSegmentKey: NullableString = null;

    readonly seatFilters: ISeatFilterModel[];

    get currentSeatMapIndex(): number {
        if(!this._currentSegmentKey) {
            return 0;
        }

        return this.seatMaps.findIndex(s => s.segmentKey === this._currentSegmentKey);
    }

    get previousSeatMapIndex(): number {
        if(!this._previousSegmentKey) {
            return -1;
        }

        return this.seatMaps.findIndex(s => s.segmentKey === this._previousSegmentKey);
    }

    shouldAllowSeatByFilters(seat: SeatModel): boolean {
        const appliedFilters = this.seatFilters.filter(f => f.isApplied);
        if(appliedFilters.length === 0) {
            return true;
        }

        return appliedFilters.all(f => f.shouldAllowSeat(seat));
    }


    private _setCurrentSegmentKey(segmentKey: NullableString) {
        if(this._currentSegmentKey === segmentKey) {
            return;
        }

        runInAction(() => {
            this._previousSegmentKey = this._currentSegmentKey;
            this._currentSegmentKey = segmentKey;
        });


        if(segmentKey) {
            this.booking.storage.setItem(BookingSessionStorageKeys.currentSegmentSeatMap, segmentKey);
        } else {
            this.booking.storage.removeItem(BookingSessionStorageKeys.currentSegmentSeatMap);
        }
    }

    get seatMaps(): SegmentSeatMapEditorModel[] {
        return this.booking.filteredJourneys
                           .selectMany(j => j.segments.map(s => s.seatMapEditor));
    }

    get currentSeatMap(): SegmentSeatMapEditorModel | null {
        if(this._currentSegmentKey) {
            const seatMapEditor = this.seatMaps.find(s => s.segmentKey === this._currentSegmentKey) || null;
            if(seatMapEditor) {
                return seatMapEditor;
            }
        }

        return this.seatMaps[0] || null;
    }



    setCurrentPassengerSegment(passengerSegment: IPassengerSegmentViewModel): void {

        this._setCurrentSegmentKey(passengerSegment.segmentKey);

        if(this.currentSeatMap) {
            this.currentSeatMap.setCurrentPassenger(passengerSegment.passengerKey);
        }
    }

    get allPassengerSegments(): PassengerSegmentModel[] {
        return this.seatMaps.selectMany(sm => sm.segment.passengers);
    }

    moveToNextPassengerWithoutASeat(): void {
        const firstPassengerSegmentWithoutSeat = this.allPassengerSegments.find(p => !p.assignedSeat);
        if (firstPassengerSegmentWithoutSeat) {
            this.setCurrentPassengerSegment(firstPassengerSegmentWithoutSeat);
        }
    }

    moveToNextPassengerWithoutASeatOnCurrentSeatMap(): void {
        const firstPassengerSegmentWithoutSeat = this.allPassengerSegments.find(p => !p.assignedSeat && p.segment.segmentKey === this.currentSeatMap?.segmentKey);
        if (firstPassengerSegmentWithoutSeat) {
            this.setCurrentPassengerSegment(firstPassengerSegmentWithoutSeat);
        }
    }
}
