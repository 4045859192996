import {FlightSearchBaseService} from "./flight-search.base.service";
import {Wizard} from "../../models/wizard/wizard";
import {IStartBookingOptions} from "./flight-search.service.interface";

export class FlightSearchWebappService extends FlightSearchBaseService {

    protected async _goToFlightSelection(options?: IStartBookingOptions): Promise<void> {
        await this.services.booking.startNewBooking(this.searchController, {
            useHistoryReplace: options?.useRouteReplace
        });
    }

    protected _createSteps(): Wizard {
        return new Wizard(this.services, 'flightSearch');
    }

    protected _onSearchMaskErrors(): void {
        if(this.searchController.activateErrorsValidation().length > 0) {
            this._deepLinkHandler.hasDeepLinkErrors = true;
        }
    }

    startWizardSearch() {

    }
}
