import React from "react";
import {IHomePageTabViewModel} from "../../../../../services/home-page/tabs/home-page.tab.interface";
import {HomePageTabBase} from "../../../../../services/home-page/tabs/home-page-tab-base";
import {
    IJourneyHistoryViewModel
} from "../../../../../services/booking-history/models/journey-history-view-model.interface";
import {HybridHomePageHeaderComponent} from "../../header/hybrid-home-page-header.component";
import {PersonWithShieldIcon} from "../../../../../assets/svg/person-with-shield.icon";
import {MyTripsTabContentComponent} from "./components/my-trips-tab-content.component";
import {IServiceFactory} from "../../../../../services/service-factory.interface";
import {HomePageService} from "../../../../../services/home-page/home-page.service";
import {MyTripsHistoryTypeEnum} from "./components/my-trips-history-type.enum";
import {makeObservable, observable, runInAction} from "mobx";
import {ISearchBookingParamsEditor} from "../../../../../services/booking/search-booking-params.editor";
import {
    IBookingHistoryViewModel
} from "../../../../../services/booking-history/models/booking-history-view-model.interface";
import {CSS_CLASS_ANIMATE_FLIGHT_CARD} from "./my-trips-tab-constants";


export class MyTripsTab extends HomePageTabBase implements IHomePageTabViewModel {

    constructor(services: IServiceFactory, homePageService: HomePageService) {
        super(services, homePageService);
        makeObservable<this, '_currentHistoryView'>(this, {
            _currentHistoryView: observable.ref
        });
    }

    private _currentHistoryView: MyTripsHistoryTypeEnum = MyTripsHistoryTypeEnum.Future;
    get currentHistoryView(): MyTripsHistoryTypeEnum {
        return this._currentHistoryView;
    }
    set currentHistoryView(value: MyTripsHistoryTypeEnum) {
        runInAction(() => {
            this._currentHistoryView = value;
        })
    }


    async retrieveBooking(searchParams: ISearchBookingParamsEditor): Promise<IBookingHistoryViewModel | null> {
        const retrievedBooking =  await searchParams.retrieveBookingToHistory();
        if(!retrievedBooking) {
            return null;
        }

        if(retrievedBooking.flights.length === 0) {
            return null;
        }

        let flights = retrievedBooking.flights.filter(f => f.isFutureFlight);
        if(flights.length > 0) {
            this.currentHistoryView = MyTripsHistoryTypeEnum.Future;
        } else {
            flights = retrievedBooking.flights.sort((f1, f2) => new Date(f2.designator.departureDate).getTime() - new Date(f1.designator.departureDate).getTime());
            this.currentHistoryView = MyTripsHistoryTypeEnum.Past;
        }


        const getFlightCardElement = (elementId: string) => {
            let element = this.services.document.getElementById(elementId);
            if(element) {
                return Promise.resolve(element);
            }

            return new Promise<HTMLElement | null>(resolve => {
                const startTime = Date.now();
                const intervalRef = setInterval(() => {
                    let el = this.services.document.getElementById(elementId);
                    if(el) {
                        clearInterval(intervalRef);
                        resolve(el)
                    } else if(Date.now() - startTime >= 1000) {
                        clearInterval(intervalRef);
                        resolve(null);
                    }
                }, 50);
            })
        }


        for(let i = 0; i < flights.length; i++) {
            const element = await getFlightCardElement(flights[i].uniqueKey);
            if(element) {
                if(i === 0) {
                    element.scrollIntoView({
                        block: "center",
                        inline: "center",
                    });
                }

                element.classList.add(CSS_CLASS_ANIMATE_FLIGHT_CARD);
            }
        }

        return retrievedBooking;
    }

    get isEmpty(): boolean {
        return this.futureFlights.length === 0 && this.pastFlights.length === 0;
    }

    get futureFlights(): IJourneyHistoryViewModel[] {
        return this.services.bookingHistory.myFutureFlights;
    }

    get pastFlights(): IJourneyHistoryViewModel[] {
        return this.services.bookingHistory.myPastFlights;
    }

    get text(): string {
        return this.services.language.translate('My trips');
    }

    renderIcon(): React.ReactElement {
        return (
            <PersonWithShieldIcon size={2.5}/>
        );
    }

    renderHeader(): React.ReactElement | null {
        return (
            <HybridHomePageHeaderComponent/>
        );
    }

    renderContent(): React.ReactElement {
        return (<MyTripsTabContentComponent tab={this}/>);
    }

    renderFooter(): React.ReactElement | null {
        return null;
    }
}


