import React from "react";
import {observer} from "mobx-react";
import {IonSkeletonText} from "@ionic/react";
import styled from "styled-components";


const ButtonSkeletonBox = styled(IonSkeletonText)`
    padding: ${props => props.theme.spacing.spacing12} ${props => props.theme.spacing.spacing24};
    border-radius: ${props => props.theme.border.defaultRadius};
    border-width: 1px;
    border-style: solid;
    font-size: ${props => props.theme.fontSize.body1};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    height: auto;
`

const ButtonSkeletonPrimaryBox = styled(ButtonSkeletonBox)`
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
`

const ButtonSkeletonSecondaryBox = styled(ButtonSkeletonBox)`
    background-color: ${props => props.theme.colors.light};
    color: ${props => props.theme.colors.light};
    border-color: ${props => props.theme.colors.light};
`


export const NextFlightActionButtonsSkeletonsComponent: React.FC = observer(() => {

    return (
        <>
            <ButtonSkeletonPrimaryBox animated>

            </ButtonSkeletonPrimaryBox>
            <ButtonSkeletonSecondaryBox animated>

            </ButtonSkeletonSecondaryBox>
        </>
    );
});
