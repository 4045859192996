import React from "react";
import {observer} from "mobx-react";
import {StandardDialogPageComponent} from "../../../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../../../components/modal/header/standard-dialog-header.component";
import {IDialogConfirmationHandler} from "../../../../../services/dialog/dialog.service.interface";
import {useServices} from "../../../../../hooks/use-services.hook";
import {StandardDialogContentComponent} from "../../../../../components/modal/content/standard-dialog-content.component";
import {IPassengerViewModel} from "../../../../../services/booking/models/passenger/passenger-view-model.interface";
import {ManageMyBookingApplyChangesButtonComponent} from "../apply-changes-button/manage-my-booking-apply-changes-button.component";
import {ValidationResultEnum} from "../../../../../types/validation-result.enum";
import {StandardDialogFooterComponent} from "../../../../../components/modal/footer/standard-dialog-footer.component";
import styled from "styled-components";
import {
    PersonNameFieldEditorComponent
} from "../../../../../components/forms/person/person-name-field-editor.component";

const PassengerFieldsBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap20};
    width: 100%;
`

interface ManageMyBookingPassengersDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
    passenger: IPassengerViewModel;
}

export const ManageMyBookingPassengerDialogComponent: React.FC<ManageMyBookingPassengersDialogComponentProps> = observer((props) => {
    const services = useServices();
    const onChangeName = async () => {

        if(props.passenger.hasChanges()) {
            if(ValidationResultEnum.Success === await props.passenger.executeNameChange()) {
                props.dialogHandler.accept();
            }
        } else {
            props.dialogHandler.reject();
        }
    }


    const fields = props.passenger.fields;
    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler}
                                           title={services.language.translate('Name change')}/>
            <StandardDialogContentComponent dialogHandler={props.dialogHandler}>
                <PassengerFieldsBox>
                    <PersonNameFieldEditorComponent key={fields.firstName.fieldName}
                                                    field={fields.firstName}
                                                    className={'tst-first-name'}/>
                    <PersonNameFieldEditorComponent key={fields.lastName.fieldName}
                                                    field={fields.lastName}
                                                    className={'tst-last-name'}/>
                </PassengerFieldsBox>
            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <ManageMyBookingApplyChangesButtonComponent onClick={onChangeName} disabled={!props.passenger.hasChanges()}/>
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    )
});
