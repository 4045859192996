import React from "react";
import {observer} from "mobx-react";
import styled, {keyframes} from "styled-components";


const rotateAnimation = keyframes`
    0%   {transform: rotate(0deg)}
    100%   {transform: rotate(360deg)}
`

const prixClipFix = keyframes`
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
`

const SpinnerContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    font-size: ${props => props.theme.fontSize.caption};
    font-weight: ${props => props.theme.fontWeight.bold};
    border-radius: 50%;
    box-shadow: ${props => props.theme.boxShadow.shadow6};
`

const SpinnerBox = styled.span`
    width: 120px;
    height: 120px;
    border-radius: 50%;
    position: relative;
    animation: ${rotateAnimation} 1s linear infinite;
    &:before, &:after {
        content: "";
        box-sizing: border-box;
        position: absolute;
        inset: 0;
        border-radius: 50%;
        border: 5px solid ${props => props.theme.colors.primary};
        animation: ${prixClipFix} 2s linear infinite ;
    }
    &:after {
        inset: 8px;
        transform: rotate3d(90, 90, 0, 180deg );
        border-color: #FF3D00;
    }
`


export enum SpinnerStyle {
    LoadingIndicator,
    Spinner
}
interface SpinnerComponentProps {
    innerMessage?: string;
    promotionalMessageTitle?: string | React.ReactElement | null;
    promotionalMessage?: string | React.ReactElement | null;
    style?: SpinnerStyle
    size?: number
    className?: string;
}
export const SpinnerComponent: React.FC<SpinnerComponentProps> = observer((props) => {

    //const style = Check.isNullOrUndefined(props.style) ? SpinnerStyle.Spinner : props.style;


    return (
        <SpinnerContainerBox className={props.className}>
            <SpinnerBox/>
        </SpinnerContainerBox>
    );
});
