import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {PrimaryButton} from "../../../../../basic-components/buttons/primary-button";
import {useServices} from "../../../../../hooks/use-services.hook";
import {
    ISegmentSeatMapEditorViewModel
} from "../../../../../services/booking/models/seat-maps/segment-seat-map-editor-view-model.interface";
import {IDialogConfirmationHandler} from "../../../../../services/dialog/dialog.service.interface";
import {
    GoToPreviousButtonComponent
} from "../../../../../basic-components/buttons/go-to-previous.button.box";

const ButtonContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: ${props => props.theme.spacing.spacing16};
    line-height: 1;
`

const ContinueButton = styled(PrimaryButton)`
    font-size: ${props => props.theme.fontSize.h4};
    gap: ${props => props.theme.spacing.spacing12};
`


export const SeatMapsNavigationButtonsComponent: React.FC<{dialogHandler: IDialogConfirmationHandler | null; removePreviousButtonWhenDisabled?: boolean}> = observer((props) => {
    const services = useServices();
    const seatMapsEditor = services.booking.current.seatsMapsEditors;

    let currentSeatMapIndex = 0;
    if(seatMapsEditor.currentSeatMap) {
        currentSeatMapIndex = seatMapsEditor.seatMaps.findIndex(sm => sm.segmentKey === seatMapsEditor.currentSeatMap?.segmentKey)
    }

    const moveToFirstPassengerWithoutSeat = (targetSeatMap: ISegmentSeatMapEditorViewModel) => {
        let firstPassengerWithoutSeat = targetSeatMap.segment.passengers.find(p => !p.hasSeat);
        if(firstPassengerWithoutSeat) {
            seatMapsEditor.setCurrentPassengerSegment(firstPassengerWithoutSeat);
        } else {
            seatMapsEditor.setCurrentPassengerSegment(targetSeatMap.segment.passengers[0]);
        }
    }

    const onNextClick = async () => {
        if(!seatMapsEditor.currentSeatMap) {
            return;
        }

        if(currentSeatMapIndex === seatMapsEditor.seatMaps.length - 1) {
            if(props.dialogHandler) {
                props.dialogHandler.accept();
            } else {
                await services.booking.current.steps.nextStep()
            }

        } else {
            moveToFirstPassengerWithoutSeat(seatMapsEditor.seatMaps[currentSeatMapIndex + 1]);

        }
    }

    const onPreviousClick = () => {
        if(currentSeatMapIndex <= 0) {
            return;
        }

        moveToFirstPassengerWithoutSeat(seatMapsEditor.seatMaps[currentSeatMapIndex - 1]);
    }

    const renderNextButtonText = () => {
        if(currentSeatMapIndex === seatMapsEditor.seatMaps.length - 1) {
            return services.language.translate('Continue')
        } else {
            return services.language.translate('Next flight')
        }
    }

    const renderPreviousButton = () => {
        const isDisabled = currentSeatMapIndex === 0;
        if(isDisabled && props.removePreviousButtonWhenDisabled) {
            return null;
        }

        return (
            <GoToPreviousButtonComponent onClick={onPreviousClick} disabled={isDisabled}/>
        )
    }

    return (
        <ButtonContainerBox>
            {renderPreviousButton()}

            <ContinueButton fullWidth={true} onClick={onNextClick} showArrow={true}>
                {renderNextButtonText()}
            </ContinueButton>
        </ButtonContainerBox>
    )
})