import React from "react";
import {observer} from "mobx-react-lite";
import {RoutedPageComponent} from "../../basic-components/page/routed-page/routed-page.component";
import {
    BookingSearchComponent,
    BookingSearchComponentProps
} from "../home/hybrid/common/booking-search/booking-search.component";
import styled from "styled-components";
import {CopyRightComponent} from "../../components/airline-company-info/copy-right.component";
import {
    StandardPageHeaderComponent
} from "../../basic-components/page/standard-page-header/standard-page-header.component";
import {HomePageStandardContentWebappComponent} from "../home/web/boxes/home-page-content-wrapper.webapp.box";

const BACKGROUND_SHIFT_SIZE = "-50px";

const StandardPageContentBox = styled(HomePageStandardContentWebappComponent)`

    --padding-start: 0;
    --padding-end: 0;
    
    ${props => props.theme.screenMediaQuery.smallScreen} {
        --padding-top: 0;
    }

`

const PageContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    flex-grow: 1;
    
    
`

const PictureAndSearchContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    padding-top: ${props => props.theme.spacing.spacing48};
    ${props => props.theme.screenMediaQuery.smallScreen} {
        padding-top: 0;
    }
`

const PictureContainerBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: min(100vw, 1200px);
    height: 380px;
    border-radius: ${props => props.theme.border.defaultRadius};
    background-size: 100% 380px;
    background-repeat: no-repeat;
    background-position: top center;
    background-image: url("${props => props.theme.assets.images.retrieve_booking_page_banner_pc_png.toString()}");
    ${props => props.theme.screenMediaQuery.smallScreen} {
        background-image: url("${props => props.theme.assets.images.retrieve_booking_page_banner_mobile_png.toString()}");
        height: 430px;
        background-size: 100% 430px;
        background-position: center ${BACKGROUND_SHIFT_SIZE};
        border-radius: unset;
    }
    
`

const SearchComponentContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 80%;
    bottom: 0;
    transform: translateY(35%);
    ${props => props.theme.screenMediaQuery.smallScreen} {
        width: 90%;
        transform: translateY(calc(46% + ${BACKGROUND_SHIFT_SIZE}));
    }
`


export const SearchBookingBasePage: React.FC<BookingSearchComponentProps> = observer((props) => {

    return (
        <RoutedPageComponent>
            <StandardPageHeaderComponent useLargeLogoOnSmallScreen={true} />
            <StandardPageContentBox>
                <PageContentBox>
                    <PictureAndSearchContainerBox>
                        <PictureContainerBox>
                            <SearchComponentContainerBox>
                                <BookingSearchComponent {...props}/>
                            </SearchComponentContainerBox>

                        </PictureContainerBox>
                    </PictureAndSearchContainerBox>
                    <CopyRightComponent/>
                </PageContentBox>

            </StandardPageContentBox>

        </RoutedPageComponent>
    );
})
