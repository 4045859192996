import React, {CSSProperties, PropsWithChildren} from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";

const BookingReferenceBox: React.FC<PropsWithChildren> = observer((props) => {
    const services = useServices();
    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        lineHeight: '1',
        color: services.theme.currentTheme.colors.darkTint,
    };

    return (
        <div style={styles}>
            {props.children}
        </div>
    )
});

export const BoardingPassRecordLocatorComponent: React.FC<{recordLocator: string}> = (props) => {
    const services = useServices();
    const theme = services.theme.currentTheme;
    const labelStyle: CSSProperties = {
        textAlign: 'center',
        fontSize: theme.fontSize.body2,
    }

    const valueStyles: CSSProperties = {
        fontSize: theme.fontSize.h2,
        fontWeight: theme.fontWeight.bold
    }

    return (
        <BookingReferenceBox>
            <div style={labelStyle}>
                {services.language.translate('Booking code')}
            </div>
            <div style={valueStyles}>
                {props.recordLocator}
            </div>
        </BookingReferenceBox>
    );
}
