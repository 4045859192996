import React, {CSSProperties, MutableRefObject, PropsWithChildren, useState} from "react";
import {IPassengerSegmentBoardingPassViewModel} from "../../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {BoardingPassRecordLocatorComponent} from "../record-locator/boarding-pass-record-locator.component";
import {BoardingPassDesignatorComponent} from "../designator/boarding-pass-designator.component";
import {BoardingPassFlightDetailsComponent} from "../flight-details/boarding-pass-flight-details.component";
import {BoardingPassBagsComponent} from "../bags/boarding-pass-bags.component";
import {BoardingPassPassengerComponent} from "../passenger/boarding-pass-passenger.component";
import {BoardingPassCarrierLogoComponent} from "../carrier-logo/boarding-pass-carrier-logo.component";
import {BoardingPassBarcodeComponent} from "../bar-code/boarding-pass-bar-code.component";
import styled from "styled-components";
import {BoardingPassExportButtonsComponent} from "../export-buttons/boarding-pass-export-buttons.component";
import {BoardingPassHeaderComponent, BoardingPassHeaderRightSectionBox} from "../header/boarding-pass-header.component";
import {AirlineCompanyName} from "../../../../global-constants";

const ExportBoardingPassButtonsBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`

interface BoardingPassCardBoxProps extends PropsWithChildren {
    captureElementRef: MutableRefObject<HTMLDivElement | null>;
}
const BoardingPassCardBox: React.FC<BoardingPassCardBoxProps> = observer((props) => {
    const services = useServices();
    const theme = services.theme.currentTheme;
    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '420px',
        width: '100%',
        backgroundColor: 'white',
        gap: theme.spacing.flexGap20,
        color: theme.colors.dark,
        boxShadow: theme.boxShadow.shadow5,
        padding: theme.spacing.cardDefaultPadding,
        border: `1px solid ${theme.border.mainColor}`,
        borderRadius: theme.border.defaultRadius
    };

    return (
        <div style={styles} ref={props.captureElementRef}>
            {props.children}
        </div>
    )
});


interface BoardingPassComponentProps {
    bookingKey: string;
    currentBoardingPass: IPassengerSegmentBoardingPassViewModel;
    allBoardingPassesForCurrentPassenger: IPassengerSegmentBoardingPassViewModel[];
    hideExportButtons: boolean
}

export const BoardingPassComponent: React.FC<BoardingPassComponentProps> = (props) => {
    const services = useServices();
    const [isExportActionInProgress, setIsExportActionInProgress] = useState(false);
    const boardingPassContainerRef = React.useRef<HTMLDivElement | null>(null);
    const origin = props.currentBoardingPass.designator.origin.code;
    const destination = props.currentBoardingPass.designator.destination.code;

    const shouldRenderExportButtons = !(props.hideExportButtons || isExportActionInProgress)

    const renderExportButtons = () => {
        if (!shouldRenderExportButtons) {
            return null;
        }

        const departureDate = services.time.formatYYYMMDD(props.currentBoardingPass.designator.departureDate);
        const fileName = `${AirlineCompanyName}_Pass_${props.currentBoardingPass.fullName}_${origin}_${destination}_${departureDate}.pdf`;

        return (
            <ExportBoardingPassButtonsBox>
                <BoardingPassExportButtonsComponent bookingKey={props.bookingKey}
                                                    boardingPasses={[props.currentBoardingPass]}
                                                    onExportStatusChanged={(inProgress) => setIsExportActionInProgress(inProgress)}
                                                    fileName={fileName}
                                                    emailSubject={services.language.translationFor('Boarding pass for {passengerName}').withParams({
                                                        passengerName: props.currentBoardingPass.fullName
                                                    })}
                                                    emailBody={services.language.translationFor('Boarding pass for flight {origin} - {destination} on {flightDate}').withParams({
                                                        origin: origin,
                                                        destination: destination,
                                                        flightDate: services.time.formatUserFriendlyDate(props.currentBoardingPass.boardingTime)
                                                    })}/>
            </ExportBoardingPassButtonsBox>
        );
    }


    return (
        <BoardingPassCardBox captureElementRef={boardingPassContainerRef}>

            <BoardingPassHeaderComponent>
                <BoardingPassCarrierLogoComponent/>

                <BoardingPassHeaderRightSectionBox>
                    <BoardingPassRecordLocatorComponent recordLocator={props.currentBoardingPass.recordLocator}/>
                    {renderExportButtons()}
                </BoardingPassHeaderRightSectionBox>

            </BoardingPassHeaderComponent>


            <BoardingPassBarcodeComponent barcode={props.currentBoardingPass.barCode}/>

            <BoardingPassDesignatorComponent boardingPass={props.currentBoardingPass}/>

            <BoardingPassFlightDetailsComponent boardingPass={props.currentBoardingPass}/>

            <BoardingPassPassengerComponent boardingPass={props.currentBoardingPass}/>

            <BoardingPassBagsComponent boardingPass={props.currentBoardingPass}/>


        </BoardingPassCardBox>
    )
}
