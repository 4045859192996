import React from 'react';
import {ISvgIconProps, SvgIcon} from '../../../../assets/svg/svg-icon.component';

export const CheckInOnlineSmallIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.5} sizeIsHeight {...props} viewBox="0 0 20 28" ratio={20 / 28}>
            <path d="M20 24.6982V2.84909C19.999 2.09376 19.6986 1.36964 19.1645 0.835542C18.6304 0.301442 17.9062 0.000962206 17.1509 0L2.84909 0C2.09376 0.000962206 1.36964 0.301442 0.835542 0.835542C0.301442 1.36964 0.000962206 2.09376 0 2.84909L0 24.6836C0.000962206 25.439 0.301442 26.1631 0.835542 26.6972C1.36964 27.2313 2.09376 27.5318 2.84909 27.5327H17.1509C17.5241 27.5337 17.8938 27.4611 18.239 27.3192C18.5841 27.1773 18.8979 26.9687 19.1625 26.7055C19.4271 26.4423 19.6372 26.1296 19.7809 25.7851C19.9246 25.4407 19.999 25.0714 20 24.6982ZM2.84909 25.1855C2.7203 25.184 2.59718 25.1322 2.5061 25.0412C2.41503 24.9501 2.36324 24.827 2.36182 24.6982V21.3891C2.49577 21.3299 2.60132 21.2208 2.656 21.085C2.71068 20.9491 2.71016 20.7973 2.65455 20.6618C2.59657 20.5307 2.4933 20.4248 2.36364 20.3636V2.84909C2.36553 2.72061 2.41753 2.59796 2.50855 2.50727C2.59958 2.41659 2.72243 2.36504 2.85091 2.36364H17.1527C17.2815 2.36505 17.4046 2.41685 17.4957 2.50792C17.5868 2.599 17.6386 2.72212 17.64 2.85091V20.3055H17.1527C17.0052 20.3055 16.8637 20.3641 16.7593 20.4684C16.655 20.5728 16.5964 20.7143 16.5964 20.8618C16.5964 21.0094 16.655 21.1509 16.7593 21.2552C16.8637 21.3596 17.0052 21.4182 17.1527 21.4182H17.6364V24.6909C17.6349 24.8197 17.5832 24.9428 17.4921 25.0339C17.401 25.125 17.2779 25.1768 17.1491 25.1782L2.84727 25.1927L2.84909 25.1855Z"
                  fill={props.color? props.color: "currentColor"}/>
            <path d="M1.12891 20.3477V25.904H18.8725V20.3477H1.12891ZM9.99981 25.1095C9.50968 25.1097 9.03699 24.9275 8.67368 24.5985C8.31038 24.2695 8.08242 23.8172 8.03415 23.3294C7.98587 22.8416 8.12073 22.3534 8.4125 21.9595C8.70427 21.5657 9.13208 21.2945 9.61275 21.1985C10.0934 21.1026 10.5926 21.1889 11.0131 21.4407C11.4337 21.6924 11.7456 22.0915 11.8882 22.5604C12.0308 23.0294 11.9939 23.5346 11.7847 23.9778C11.5755 24.4211 11.2089 24.7706 10.7562 24.9586C10.5164 25.058 10.2594 25.1093 9.99981 25.1095Z"
                  fill={props.color? props.color: "currentColor"}/>
            <path d="M6.07061 9.41797L9.92697 13.2725L8.64152 14.558L4.78516 10.7034L6.07061 9.41797Z"
                  fill={props.color? props.color: "currentColor"}/>
            <path d="M16.1345 9.24542L9.70538 15.6727L8.41992 14.3872L14.849 7.95996L16.1345 9.24542Z"
                  fill={props.color? props.color: "currentColor"}/>
        </SvgIcon>
    )
}
