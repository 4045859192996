import React from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {PrimaryButton} from "../../basic-components/buttons/primary-button";
import {useServices} from "../../hooks/use-services.hook";
import {PrimaryButtonInverted} from "../../basic-components/buttons/primary-button-inverted";
import {
    IFlightSearchControllerViewModel
} from "../../services/flight-search/flight-search-controller/flight-search-controller-view-model.interface";
import {useScreenMediaQueriesChecks} from "../../hooks/use-screen-media-queries-checks.hook";
import {MAX_FRAME_WIDTH} from "./pso-css-constants";
import {PsoUserAgreementEnum} from "../../services/flight-search/flight-search-controller/pso.enums";

const RootBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
`

const ContentBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: ${props => props.theme.spacing.spacing16};
    width: min(100%, ${MAX_FRAME_WIDTH});
    padding-right: ${props => props.theme.spacing.spacing16};
    ${(props) => props.theme.containerMediaQuery.smallScreen} {
        flex-direction: column-reverse;
        padding-right: 0;
    }
`

const AgreeButton = styled(PrimaryButton)`
    min-width: 150px;
    padding: ${props => props.theme.spacing.spacing20} ${props => props.theme.spacing.spacing48};
`

const DontAgreeButton = styled(PrimaryButtonInverted)`
    min-width: 160px;
    background-color: ${props => props.theme.colors.light};
    padding: ${props => props.theme.spacing.spacing20} ${props => props.theme.spacing.spacing48};
`



interface PsoUserAgreementPageFooterComponentProps {
    flightSearchController: IFlightSearchControllerViewModel;
}

export const PsoUserAgreementPageFooterComponent: React.FC<PsoUserAgreementPageFooterComponentProps> = observer((props) => {
    const services = useServices();
    const searchController = props.flightSearchController;
    const screenMediaQuery = useScreenMediaQueriesChecks();

    const onAgreeClick = async () => {
        searchController.psoUserAgreement = PsoUserAgreementEnum.Agree;

    }

    const onDoNotAgreeClick = async () => {
        searchController.psoUserAgreement = PsoUserAgreementEnum.DontAgree;
        await services.booking.startNewBooking(searchController);
    }

    return (
        <RootBox>
            <ContentBox>
                <DontAgreeButton onClick={onDoNotAgreeClick} fullWidth={screenMediaQuery.smallScreen}>
                    {services.language.translate('Continue without PSO')}
                </DontAgreeButton>
                <AgreeButton onClick={onAgreeClick} fullWidth={screenMediaQuery.smallScreen}>
                    {services.language.translate('Agree and Continue')}
                </AgreeButton>
            </ContentBox>
        </RootBox>

    )
});
