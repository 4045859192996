import React from "react";
import {observer} from "mobx-react";
import {FlightsDatesSelectorModel} from "../../../services/flight-search/dates-selection/flights-dates-selector.model";
import {FlightDatesSelectorSkeletonsComponent} from "./skeletons/flight-dates-selector-skeletons.component";
import {
    FlightDatesSelectorSwiperComponent,
    FlightDatesSelectorSwiperComponentProps
} from "./swiper/flight-dates-selector-swiper.component";
import {FlightDatesSelectorListComponent} from "./list/flight-dates-selector-list.component";
import {useContainerMediaQueriesChecks} from "../../../hooks/use-container-media-queries-checks.hook";
import {ScheduleLoadingErrorComponent} from "./schedule-error/schedule-loading-error.component";


interface FlightDatesSelectorComponentProps {
    captureSelectedDateElementRef?: (date: Date, element: HTMLElement | null) => void;
    onDateSelected?: (date: Date) => void;
    flightsDatesSelector: FlightsDatesSelectorModel;
    swiperOptions?: Pick<FlightDatesSelectorSwiperComponentProps, 'hideFooter' | 'hideHeader' | 'onDone'>
}

export const FlightDatesSelectorComponent: React.FC<FlightDatesSelectorComponentProps> = observer((props) => {
    const containerMediaQueries = useContainerMediaQueriesChecks();

    if(props.flightsDatesSelector.scheduleLoadingError) {
        return (
            <ScheduleLoadingErrorComponent flightsDatesSelector={props.flightsDatesSelector}/>
        );
    }

    //return (<FlightDatesSelectorSkeletonsComponent />)

    if(props.flightsDatesSelector.isLoadingSchedule) {
        return (<FlightDatesSelectorSkeletonsComponent />)
    }

    const onDateClick = (date: Date) => {
        if(!props.flightsDatesSelector.isDateAvailable(date)) {
            return;
        }

        props.flightsDatesSelector.selectDate(date);
        if(props.onDateSelected) {
            props.onDateSelected(date);
        }
    }

    if(containerMediaQueries.smallScreen) {
        return (
            <FlightDatesSelectorListComponent flightsDatesSelector={props.flightsDatesSelector}
                                              onDateSelected={onDateClick}
                                              captureSelectedDateElementRef={props.captureSelectedDateElementRef}/>
        );
    } else {
        return (
            <FlightDatesSelectorSwiperComponent flightsDatesSelector={props.flightsDatesSelector}
                                                onDateClick={onDateClick}
                                                captureSelectedDateElementRef={props.captureSelectedDateElementRef}
                                                onDone={props.swiperOptions?.onDone}
                                                {...props.swiperOptions}/>
        );
    }

});
