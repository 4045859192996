import {ApplicationEnvironmentTypeEnum, IConfigurationObject} from "./configuration-object.interface";
import {TimeSpan} from "../../types/time-span";
import {NullableString} from "../../types/nullable-types";
import {SsrsConfiguration} from "./objects/ssrs/ssrs.configuration";
import {AppVersionUpgradeModeEnum} from "../airline-webapi/responses/get-configurations.response";
import {EnvConfigs} from "./env.configs";

export const SystemCodes = {
    ACE: "ACE"
}

export interface IDotRezApiSubscriptionKeyHeader {
    'Ocp-Apim-Subscription-Key': string;
}


export interface IConfigurationService {
    readonly envConfigs: EnvConfigs;
    readonly airlineWebapiUrl: string;
    readonly dotRezApiUrl: string;
    readonly dotRezApiSubscriptionKeyHeader: IDotRezApiSubscriptionKeyHeader;
    readonly appVersionUpgradeMode: AppVersionUpgradeModeEnum;
    readonly data: IConfigurationObject;
    readonly noBundleEarliestCheckIn: TimeSpan;
    getErrorDescription(errorCode: NullableString): NullableString;
    readonly isProduction: boolean;
    readonly dotRezSessionTimeout: TimeSpan;
    readonly ssrs: SsrsConfiguration;
    getBundleVirtualSsrs(bundleCode: string): string[];
    readonly defaultCountryCode: string;
    setPreferredApiOrigin(apiOrigin: string): void;
    readonly currentEnvironmentType: ApplicationEnvironmentTypeEnum;
    loadConfigurations(): Promise<void>;
    enableHybridTestMode(password: string): boolean;
    readonly isHybridTestModeEnabled: boolean;
    readonly isAnalyticsEnabled: boolean;
}
