import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {MonthModel} from "../../services/time/month.model";
import styled from "styled-components";
import {BackNavigationButtonComponent, ForwardNavigationButtonComponent} from "./navigation-buttons.component";
import {useServices} from "../../hooks/use-services.hook";

const MonthSelectorBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${props => props.theme.colors.darkTint};
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    gap: ${props => props.theme.spacing.spacing8};
    padding: ${props => props.theme.spacing.spacing12} ${props => props.theme.spacing.spacing36};
`

const MonthsContainerBox = styled.div<{$columnsCount: number}>`
    display: grid;
    grid-template-columns: repeat(${props => props.$columnsCount}, 1fr);
    align-items: center;
    flex-grow: 1;
`

const MonthBox = styled.div`
    padding: ${props => props.theme.spacing.spacing8} ${props => props.theme.spacing.spacing12};
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
`

const SelectedMonthBox = styled(MonthBox)`
    background-color: ${props => props.theme.colors.darkTint};
    color: ${props => props.theme.colors.darkContrast};
`

const LeftSelectedMonthBox = styled(SelectedMonthBox)`
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
`

const RightSelectedMonthBox = styled(SelectedMonthBox)`
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
`

interface MonthComponentProps {
    month: MonthModel;
    index: number;
    isLeftSelected: boolean;
    isRightSelected: boolean;
    onClick: (index: number) => void;
}

const MonthComponent: React.FC<MonthComponentProps> = observer((props) => {
    const services = useServices();
    let text = props.month.abbreviatedName;

    if(!props.month.isInCurrentYear) {
        text = `${text} ${services.time.formatYear2Digits(props.month.year)}`;
    }


    const onClick = () => {
        props.onClick(props.index);
    }

    if(props.isLeftSelected) {
        return (
            <LeftSelectedMonthBox>
                {text}
            </LeftSelectedMonthBox>
        );
    } else if(props.isRightSelected) {
        return (
            <RightSelectedMonthBox onClick={onClick}>
                {text}
            </RightSelectedMonthBox>
        );
    }

    return (
        <MonthBox onClick={onClick}>
            {text}
        </MonthBox>
    )
})

interface IMonthInChunk {
    month: MonthModel;
    index: number;
}

const CHUNK_SIZE = 12;

function computeChunkIndex(currentIndex: number): number {
    return Math.max(0, (Math.ceil((currentIndex + 1) / CHUNK_SIZE) - 1));
}

interface MonthSelectorComponentProps {
    availableMonths: MonthModel[];
    currentIndex: number;
    onSelected: (index: number) => void;
}
export const MonthSelectorComponent: React.FC<MonthSelectorComponentProps> = observer((props) => {
    const [currentMonthIndex, setCurrentMonthIndex] = useState(props.currentIndex);
    const [currentChunkIndex, setCurrentChunkIndex] = useState(computeChunkIndex(props.currentIndex));

    useEffect(() => {
        if(props.currentIndex !== currentMonthIndex) {
            setCurrentMonthIndex(props.currentIndex);
            setCurrentChunkIndex(computeChunkIndex(props.currentIndex));
        }
    }, [currentMonthIndex, props.currentIndex]);

    const chunks = props.availableMonths.map((m, index) => {
        const monthInChunk: IMonthInChunk =  {
            month: m,
            index: index
        }
        return monthInChunk;
    }).splitToChunks(CHUNK_SIZE);



    let months = chunks[currentChunkIndex];
    
    if(months.length < CHUNK_SIZE && chunks.length > 1) {
        //borrow months from previous chunk
        const prevChunk = chunks[currentChunkIndex - 1];
        const quantityToBorrow = CHUNK_SIZE - months.length;
        months = [
            ...prevChunk.slice(prevChunk.length - quantityToBorrow, prevChunk.length),
            ...months,
        ]
    }

    if(months[0].index === currentMonthIndex + 1) {
        months.splice(0, 0, {
            month: props.availableMonths[currentMonthIndex],
            index: currentMonthIndex
        });
    } else if(months[months.length - 1].index === currentMonthIndex && currentMonthIndex < props.availableMonths.length - 1) {
        months.push({
            month: props.availableMonths[currentMonthIndex + 1],
            index: currentMonthIndex + 1
        });
    }

    const onBackClick = () => {
        setCurrentChunkIndex(Math.max(0, currentChunkIndex - 1));
    }

    const onForwardClick = () => {
        setCurrentChunkIndex(Math.min(chunks.length - 1, currentChunkIndex + 1));
    }

    const onMonthSelected = (index: number) => {
        if(index === currentMonthIndex) {
            return;
        }

        let newMonthIndex: number = index;
        if(index === props.availableMonths.length - 1) {
            newMonthIndex = index - 1;

        }

        setCurrentMonthIndex(newMonthIndex);
        setCurrentChunkIndex(computeChunkIndex(newMonthIndex));
        props.onSelected(newMonthIndex);


    }

    const renderOneMonth = (monthInChunk: IMonthInChunk) => {

        return (
            <MonthComponent key={monthInChunk.month.key}
                            index={monthInChunk.index}
                            month={monthInChunk.month}
                            isLeftSelected={monthInChunk.index === currentMonthIndex}
                            isRightSelected={monthInChunk.index === currentMonthIndex + 1}
                            onClick={onMonthSelected}/>
        );
    }


    return (
        <MonthSelectorBox>
            <BackNavigationButtonComponent iconSize={"small"}
                                           isVisible={currentChunkIndex >  0}
                                           disabled={currentChunkIndex === 0}
                                           onClick={onBackClick}/>

            <MonthsContainerBox $columnsCount={Math.max(CHUNK_SIZE, months.length)}>
                {months.map(renderOneMonth)}
            </MonthsContainerBox>

            <ForwardNavigationButtonComponent iconSize={"small"}
                                              isVisible={currentChunkIndex < chunks.length - 1}
                                              disabled={currentChunkIndex === chunks.length - 1}
                                              onClick={onForwardClick}/>

        </MonthSelectorBox>
    )
})