import React from 'react';
import {observer} from "mobx-react-lite";
import {useServices} from "../../../hooks/use-services.hook";
import {handleBookingFlowLogoClick} from "../booking-flow-logo-click-handler";
import {
    StandardPageHeaderLargeScreenComponent
} from "../../../basic-components/page/standard-page-header/standard-page-header.large-screen.component";
import {
    WizardProgressIndicatorComponent
} from "../../../components/wizard-progress-indicator/wizard-progress-indicator.component";
import {
    BookingFlowPageHeaderRouteInfoLargeScreenComponent
} from "./booking-flow-page-header-route-info.large-screen.component";


interface BookingFlowPageHeaderLargeScreenComponentProps {
    dontAskForConfirmationOnLogoClick?: boolean;
    hideWizardProgressIndicator?: boolean;
    renderTopMiddleSection?: () => (string | React.ReactElement | null);
}
export const BookingFlowPageHeaderLargeScreenComponent: React.FC<BookingFlowPageHeaderLargeScreenComponentProps> = observer((props) => {
    const services = useServices();
    const onLogoClick = async () => {
        await handleBookingFlowLogoClick(services, props.dontAskForConfirmationOnLogoClick);
    }


    const renderWizardSteps = () => {
        if(props.hideWizardProgressIndicator) {
            return null;
        }
        return (
            <WizardProgressIndicatorComponent getWizard={() => services.booking.steps}/>
        );
    }


    const renderTopMiddleSection = () => {
        if(props.renderTopMiddleSection) {
            return props.renderTopMiddleSection();
        }
        return (
            <BookingFlowPageHeaderRouteInfoLargeScreenComponent/>
        );
    }

    return (
        <StandardPageHeaderLargeScreenComponent onLogoButtonClick={onLogoClick}
                                                renderTopMiddleSection={renderTopMiddleSection} >
            {renderWizardSteps()}
        </StandardPageHeaderLargeScreenComponent>
    );
});
