import React from 'react';
import {IBookingViewModel} from "../../../../services/booking/models/booking-view-model.interface";
import {IExtrasSectionRenderer, IRenderLargeTileOptions} from "./extras-section-renderer.interface";
import {IServiceFactory} from "../../../../services/service-factory.interface";
import {v4 as uuidv4} from "uuid";
import {ISsrType} from "../../../../services/ssr-types/ssr-types.service.interface";
import {NullableString} from "../../../../types/nullable-types";


export abstract class ExtrasSectionRendererBase implements IExtrasSectionRenderer {
    constructor(protected readonly booking: IBookingViewModel) {
        this.id = uuidv4();
    }

    readonly id: string;
    abstract get ssrTypes(): ISsrType[];
    abstract shouldRender(): boolean;
    abstract get canBeModified(): boolean;
    abstract get isFulfilled(): boolean;

    abstract renderLargeTile(options: IRenderLargeTileOptions): React.ReactElement;
    abstract renderSmallTile(): React.ReactElement;
    protected abstract _getExtrasLargeTilePicture(): NullableString;
    abstract getTitle(): string | React.ReactElement;

    protected get services(): IServiceFactory {
        return this.booking.services;
    }



}
