import styled from "styled-components";

export const CardBox = styled.div`
    display: flex;
    align-items: center;
    padding: ${props => props.theme.spacing.cardDefaultPadding};
    border-radius: ${props => props.theme.border.defaultRadius};
    border: 1px solid ${props => props.theme.border.mainColor};
    box-shadow: ${props => props.theme.boxShadow.shadow5};
    background-color: ${props => props.theme.colors.cardBackground};
    color: ${props => props.theme.colors.cardColor};
`
