import React from 'react';
import { ISvgIconProps, SvgIcon } from "./svg-icon.component";


export const ExclamationDiamondIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1} {...props} viewBox="0 0 24 24" ratio={24 / 24}>
            <g clipPath="url(#clip0_35638_52328)">
                <path
                    d="M10.6734 0.548438C11.4047 -0.182812 12.5906 -0.182812 13.3266 0.548438L23.4516 10.6734C24.1828 11.4047 24.1828 12.5906 23.4516 13.3266L13.3266 23.4516C12.5953 24.1828 11.4094 24.1828 10.6734 23.4516L0.548438 13.3266C-0.182812 12.5953 -0.182812 11.4094 0.548438 10.6734L10.6734 0.548438ZM12 6C11.3766 6 10.875 6.50156 10.875 7.125V12.375C10.875 12.9984 11.3766 13.5 12 13.5C12.6234 13.5 13.125 12.9984 13.125 12.375V7.125C13.125 6.50156 12.6234 6 12 6ZM13.5 16.5C13.5 16.1022 13.342 15.7206 13.0607 15.4393C12.7794 15.158 12.3978 15 12 15C11.6022 15 11.2206 15.158 10.9393 15.4393C10.658 15.7206 10.5 16.1022 10.5 16.5C10.5 16.8978 10.658 17.2794 10.9393 17.5607C11.2206 17.842 11.6022 18 12 18C12.3978 18 12.7794 17.842 13.0607 17.5607C13.342 17.2794 13.5 16.8978 13.5 16.5Z"
                    fill={props.color? props.color: "currentColor"}/>
            </g>
            <defs>
                <clipPath id="clip0_35638_52328">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </SvgIcon>
    )
}
