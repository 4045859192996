import React from "react";
import {observer} from "mobx-react";
import {IPassengerViewModel} from "../../../../services/booking/models/passenger/passenger-view-model.interface";
import {IPersonTravelDocumentViewModel} from "../../../../services/user/models/person/person-travel-document-view-model.interface";
import styled from "styled-components";
import {useServices} from "../../../../hooks/use-services.hook";

const AvailableTravelDocumentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12px 5px 4px;
  width: 100%;
`

const CountryCodeBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    border: 1px solid rgba(${props => props.theme.colors.secondaryRgb}, 0.75);
    font-size: ${props => props.theme.fontSize.h4};
    font-weight: ${props => props.theme.fontWeight.bold};
    width: 40px;
    height: 40px;
    color: ${props => props.theme.colors.secondary};
    background-color: ${props => props.theme.colors.tertiaryTint};
`

const DocumentInfoBox = styled.div`
    font-size: ${props => props.theme.fontSize.caption};
    text-align: center;
    color: ${props => props.theme.colors.secondary};
    
    padding-top: 6px;
`


interface PassengerAvailableTravelDocumentComponentProps {
    passenger: IPassengerViewModel;
    travelDocument: IPersonTravelDocumentViewModel;
}

export const PassengerAvailableTravelDocumentComponent: React.FC<PassengerAvailableTravelDocumentComponentProps> = observer((props) => {
    const services = useServices();
    const onClickHandler = () => {
        props.passenger.travelDocument.copyFrom(props.travelDocument);
    }
    return (
        <AvailableTravelDocumentBox onClick={onClickHandler}>
            <CountryCodeBox>
                {props.travelDocument.fields.issuedByCode.value}
            </CountryCodeBox>
            <DocumentInfoBox>
                {services.travelDocumentType.tryGetDocumentTypeName(props.travelDocument.fields.documentTypeCode.value)}
            </DocumentInfoBox>
            <DocumentInfoBox>
                {props.travelDocument.fields.number.value}
            </DocumentInfoBox>
        </AvailableTravelDocumentBox>
    )
});
