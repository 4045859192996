import React from "react";
import {BookingFlowPageProps} from "./booking-flow-page.props";
import {observer} from "mobx-react-lite";
import {ScreenSizeBasedComponent} from "../../basic-components/layout/screen-size-based.component";
import {BookingFlowSmallScreenPage} from "./screen-small/booking-flow.small-screen.page";
import {BookingFlowLargeScreenPage} from "./screen-large/booking-flow.large-screen.page";
import {useServices} from "../../hooks/use-services.hook";

export const BookingFlowPage: React.FC<BookingFlowPageProps> = observer(props => {

    const services = useServices();

    if(!services.booking.currentPageCanBeRendered) {
        return null;
    }

    if(!services.booking.steps.currentStep?.isVisible) {
        return null;
    }


    return (
        <ScreenSizeBasedComponent renderForSmallScreen={() => <BookingFlowSmallScreenPage {...props}/>}
                                  renderForLargeScreen={() => <BookingFlowLargeScreenPage {...props}/>}/>
    );
})
