import React from "react";
import {IBundleViewModel} from "../../../../../services/booking/models/bundle/bundle-view-model.interface";
import {observer} from "mobx-react";

import styled from "styled-components";
import {useServices} from "../../../../../hooks/use-services.hook";
import {useContainerMediaQueriesChecks} from "../../../../../hooks/use-container-media-queries-checks.hook";

const BundleContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: ${props => props.theme.spacing.spacing16};
    flex-grow: 1;
    gap: ${props => props.theme.spacing.flexGap8};
    
`

const IncludedSsrsContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap8};
`

const BenefitInfoBox = styled.div<{$isHidden: boolean}>`
    color: ${props => props.theme.colors.notesTint};
    font-size: ${props => props.theme.fontSize.caption};
    width: 100%;
    text-align: center;
    visibility: ${props => props.$isHidden ? "hidden" : "visible"};
`



export const BundleContentComponent: React.FC<{bundle: IBundleViewModel}> = observer((props) => {
    const services = useServices();
    const containerMediaQuery = useContainerMediaQueriesChecks();


    const renderBenefitInfo = () => {
        const isHidden = !props.bundle.bundleType.isFavouriteBundle;
        if(containerMediaQuery.smallScreen && isHidden) {
            return null;
        }
        return (
            <BenefitInfoBox $isHidden={!props.bundle.bundleType.isFavouriteBundle}>
                {services.language.translate('You save 40% compared to buying separately!')}
            </BenefitInfoBox>
        );
    }

    return (
        <BundleContentBox>
            {renderBenefitInfo()}
            <IncludedSsrsContainerBox>
                {props.bundle.includedSsrs.filter(ssr => ssr.quantityToDisplay > 0)
                    .map(ssr => ssr.ssrType.renderInBundle(ssr.quantityToDisplay))}
            </IncludedSsrsContainerBox>
        </BundleContentBox>
    )
});
