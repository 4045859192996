import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const DollarCircleIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.8} {...props} viewBox="0 0 32 32" ratio={32 / 32}>
            <rect x="1" y="1" width="30" height="30" rx="15" stroke="#92949C" strokeWidth="2" fill={"white"}/>
            <path
                d="M16.742 8.662V11.497H14.753V8.662H16.742ZM16.742 20.812V23.881H14.753V20.812H16.742ZM15.59 22.234C15.056 22.234 14.555 22.195 14.087 22.117C13.625 22.039 13.205 21.943 12.827 21.829C12.455 21.709 12.134 21.589 11.864 21.469L11.657 19.237C12.125 19.483 12.68 19.705 13.322 19.903C13.964 20.095 14.648 20.191 15.374 20.191C16.142 20.191 16.697 20.08 17.039 19.858C17.381 19.636 17.552 19.294 17.552 18.832V18.733C17.552 18.433 17.477 18.187 17.327 17.995C17.183 17.797 16.94 17.623 16.598 17.473C16.256 17.323 15.791 17.17 15.203 17.014C14.375 16.786 13.694 16.528 13.16 16.24C12.632 15.946 12.242 15.583 11.99 15.151C11.738 14.713 11.612 14.164 11.612 13.504V13.396C11.612 12.388 11.951 11.614 12.629 11.074C13.313 10.534 14.321 10.264 15.653 10.264C16.481 10.264 17.192 10.339 17.786 10.489C18.38 10.639 18.878 10.813 19.28 11.011L19.487 13.09C19.037 12.85 18.521 12.652 17.939 12.496C17.363 12.334 16.733 12.253 16.049 12.253C15.539 12.253 15.134 12.301 14.834 12.397C14.54 12.493 14.327 12.634 14.195 12.82C14.069 13.006 14.006 13.231 14.006 13.495V13.522C14.006 13.792 14.072 14.026 14.204 14.224C14.342 14.422 14.585 14.605 14.933 14.773C15.281 14.935 15.776 15.103 16.418 15.277C17.234 15.505 17.9 15.751 18.416 16.015C18.938 16.279 19.325 16.621 19.577 17.041C19.829 17.461 19.955 18.016 19.955 18.706V18.868C19.955 19.978 19.592 20.818 18.866 21.388C18.146 21.952 17.054 22.234 15.59 22.234Z"
                fill="#808289"/>
        </SvgIcon>
    )
}
