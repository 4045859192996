import {BookingStrategyBase} from "../booking-strategy-base";
import { ANALYTICS_AFFILIATIONS } from "../analytics/analytics-affiliations";
import {IBookingStrategyAnalyticsConfiguration} from "../analytics/booking-strategy-analytics-configuration.interface";
import {BookingFlowTypeEnum} from "../booking-flow-type.enum";
import {IRoundTripLowFareReader} from "../../../low-fare/low-fare-readers/low-fare-reader.interface";
import {VoidRoundTripLowFareReader} from "../../../low-fare/low-fare-readers/void-low-fare-reader";
import {BookingStorage} from "../../models/storage/booking-storage";
import {ISsrType} from "../../../ssr-types/ssr-types.service.interface";
import {ShoppingCartModeEnum} from "../booking-strategy.interface";
import {EmptyWizard} from "../../../../models/wizard/empty-wizard";
import {IWizard} from "../../../../models/wizard/wizard.interface";
import {IRoute} from "../../../navigation/models/route.interface";

export class ViewBookingDetailsStrategy extends BookingStrategyBase {

    get allowedShoppingCartModes(): ShoppingCartModeEnum[] {
        if(this.initialBookingSnapshot.isGDSBooking) {
            return [ShoppingCartModeEnum.ShowNewPurchasesOnly];
        }
        return [ShoppingCartModeEnum.ShowAllPurchases];
    }

    canShowSsr(ssrType: ISsrType): boolean {
        return false;
    }

    get bookingFlowType(): BookingFlowTypeEnum {
        return BookingFlowTypeEnum.None;
    }

    protected _createSteps(): IWizard {
        return new EmptyWizard();
    }

    createBookingStorage(token: string): BookingStorage {
        return new BookingStorage(token);
    }

    get analyticsConfiguration(): IBookingStrategyAnalyticsConfiguration {
        return {
            affiliation: ANALYTICS_AFFILIATIONS.flightItinerary,
            shouldReportFares: false
        }
    }

    getLowFaresReader(): IRoundTripLowFareReader {
        return new VoidRoundTripLowFareReader()
    }

    get supportsPayment(): boolean {
        return false;
    }

    getFinalPriceRoute(): IRoute {
        throw new Error('Invalid operation getFinalPriceRoute for ViewBookingDetailsStrategy');
    }
    getPaymentRoute(): IRoute {
        throw new Error('Invalid operation getPaymentRoute for ViewBookingDetailsStrategy');
    }

    getFinalizePaymentRoute(): IRoute {
        throw new Error('Invalid operation getFinalizePaymentRoute for ViewBookingDetailsStrategy');
    }

    async onPaymentSuccess(): Promise<void> {
        throw new Error('Invalid operation onPaymentSuccess for ViewBookingDetailsStrategy');
    }
}
