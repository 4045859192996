import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {PlaneHorizontalFilledIcon} from "../../../assets/svg/plane-horizontal-filled.icon";
import {useServices} from "../../../hooks/use-services.hook";
import {PointingHandIcon} from "../../../assets/svg/pointing-hand.icon";

import {SCROLLBAR_WIDTH} from "../../../services/theme/global-styles";
import {
    HorizontalLineBox
} from "../../advanced-journey-designator/components/collapsed/middle-section-collapsed.component";

const SkeletonBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: ${props => props.theme.spacing.spacing16};
    padding-right: calc(${props => props.theme.spacing.spacing16} - ${SCROLLBAR_WIDTH});
`

const JourneysContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 1px dashed ${props => props.theme.border.mainColor};
    border-radius: ${props => props.theme.border.defaultRadius};
    gap: ${props => props.theme.spacing.spacing16};
`

const JourneySkeletonBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: ${props => props.theme.spacing.spacing12};
    border: 1px solid ${props => props.theme.border.mainColor};
    padding: ${props => props.theme.spacing.spacing8};
    border-radius: ${props => props.theme.border.defaultRadius};
    
`


const StationSkeletonBox = styled.div`
    background-color: ${props => props.theme.colors.lightShade};
    width: 56px;
    height: 8px;
`

const PointingHandBox = styled.div`
    position: absolute;
    right: -15px;
    top: 35%;
`

const SelectButtonSkeleton = styled.div`
    background-color: ${props => props.theme.colors.lightShade};
    width: 92px;
    height: 21px;
    border-radius: 4px;
`

const TextBox = styled.div`
    color: ${props => props.theme.colors.mediumShade};
`

const JourneySkeletonComponent: React.FC<{showPointingHand: boolean}> = observer((props) => {
    const services = useServices();

    const renderHandPointer = () => {
        if(!props.showPointingHand) {
            return null;
        }
        return (
            <PointingHandBox>
                <PointingHandIcon/>
            </PointingHandBox>
        )
    }

    return (
        <JourneySkeletonBox>
            <StationSkeletonBox/>
            <HorizontalLineBox>
                <PlaneHorizontalFilledIcon color={services.theme.currentTheme.colors.medium} size={1.3}/>
            </HorizontalLineBox>

            <StationSkeletonBox/>
            <SelectButtonSkeleton/>
            {renderHandPointer()}
        </JourneySkeletonBox>
    )
})

export const ShoppingCartJourneySkeletonComponent: React.FC<{numberOfJourneySkeletons: number}> = observer((props) => {
    const services = useServices();
    const journeysSkeletons = Array(props.numberOfJourneySkeletons).fill(1);

    return (
        <SkeletonBox>
            <JourneysContainerBox>
                {journeysSkeletons.map((_, index) => (<JourneySkeletonComponent key={index} showPointingHand={index === Math.floor(props.numberOfJourneySkeletons/ 2) }/>))}
                <TextBox>
                    {services.language.translate('Choose your flight')}
                </TextBox>
            </JourneysContainerBox>

        </SkeletonBox>
    )
})