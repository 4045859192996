import React from "react";
import {observer} from "mobx-react-lite";
import {ExtrasSectionLargeTileComponent} from "./extras-section.large-tile.component";
import {NullableString} from "../../../../../types/nullable-types";
import {ExtrasSsrSectionComponentCommonProps} from "../extras-ssr-section.component.common-props";


interface ExtrasSsrSectionLargeTileComponentProps extends ExtrasSsrSectionComponentCommonProps{
    isFirstTile: boolean;
    renderFirstTileIcon: () => React.ReactElement | null;
    renderSecondTileIcon: () => React.ReactElement | null;
    getMarketingMessage: () => NullableString;
    imageUrl: NullableString;
}

export const ExtrasSsrSectionLargeTileComponent: React.FC<ExtrasSsrSectionLargeTileComponentProps> = observer((props) => {

    return (
        <ExtrasSectionLargeTileComponent sectionTitle={props.sectionTitle}
                                         numberOfSelectedServices={props.countSelectedSsrs()}
                                         minDiscountedPrice={props.ssrsAggregator.discountedMinPrice}
                                         minStandardPrice={props.ssrsAggregator.standardMinPrice}
                                         modificationBlockingReason={props.ssrsAggregator.modificationBlockingReason}
                                         renderFirstTileIcon={props.renderFirstTileIcon}
                                         renderSecondTileIcon={props.renderSecondTileIcon}
                                         onEditButtonClick={props.onEditButtonClick}
                                         isFirstTile={props.isFirstTile}
                                         getMarketingMessage={props.getMarketingMessage}
                                         allowEdit={props.ssrsAggregator.canBeModified}
                                         imageUrl={props.imageUrl}/>
    );

});
