import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {EXTERNAL_LINK_CSS_CLASS_NAME} from "../../services/external-links/external-links.service.interface";

const ExternalLinkBox = styled.div`
    color: ${props => props.theme.colors.mediumShade};
    > a.${EXTERNAL_LINK_CSS_CLASS_NAME} {
        color: ${props => props.theme.colors.mediumShade};  
      font-weight: ${props => props.theme.fontWeight.bold};
      width: 100%;
      height: 100%;
    }
`

interface ExternalLinkComponentProps {
    innerHtml: string;
    className?: string;
    onClick?: () => void;
}
export const ExternalLinkComponent: React.FC<ExternalLinkComponentProps> = observer((props) => {
    const containerElementRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        let links: NodeListOf<HTMLElement>;
        const anchorClickHandler = (event: MouseEvent) => {
            event.stopPropagation();
        }

        const containerElement = containerElementRef.current;

        if(containerElement) {
            links = containerElement.querySelectorAll('.link-external');

            if(links) {
                links.forEach(link => {
                    link.addEventListener('click', anchorClickHandler);
                })

            }
        }

        return () => {
            if(links) {
                links.forEach(a => {
                    a.removeEventListener('click', anchorClickHandler);
                });
            }
        }
    });
    return (
        <ExternalLinkBox className={props.className} ref={containerElementRef} onClick={props.onClick} dangerouslySetInnerHTML={{
            __html: props.innerHtml
        }}/>
    )
});
