import React, {CSSProperties, PropsWithChildren} from "react";
import {
    IBoardingPassSsr,
    IPassengerSegmentBoardingPassViewModel
} from "../../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {useServices} from "../../../../hooks/use-services.hook";
import {BoardingPassCabinBagsComponent} from "./cabin-bags/boarding-pass-cabin-bags.component";
import {BoardingPassOtherSsrsComponent} from "../other-ssrs/boarding-pass-other-ssrs.component";

const BagsBox: React.FC<PropsWithChildren> = (props) => {
    const services = useServices();
    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: "row",
        alignItems: 'flex-start',
        justifyContent: "space-between",
        gap: services.theme.currentTheme.spacing.spacing20,
        borderTop: `1px solid ${services.theme.currentTheme.colors.mediumTint}`,
        paddingTop: services.theme.currentTheme.spacing.spacing20,
        width: "100%",
    }
    return (
        <div style={styles}>
            {props.children}
        </div>
    )
}


function getOtherSsrs(boardingPass: IPassengerSegmentBoardingPassViewModel, hasPriority: boolean): IBoardingPassSsr[] {
    const otherSsrs = [...boardingPass.otherSsrs];
    if(boardingPass.hasInfant) {
        otherSsrs.push({
            ssrCode: "INFT",
            count: 1
        });
    }
    if(!hasPriority) {
        return otherSsrs;
    }

    return otherSsrs.map(ssr => {
        if(ssr.ssrCode === "BCBG") {
            return {
                ssrCode: ssr.ssrCode,
                count: ssr.count - 1
            }
        }
        return ssr;
    }).filter(ssr => ssr.count > 0);
}

export const BoardingPassBagsComponent: React.FC<{boardingPass: IPassengerSegmentBoardingPassViewModel}> = (props) => {

    const hasPriority = props.boardingPass.priorityBoarding.count > 0;

    let otherSsrs = getOtherSsrs(props.boardingPass, hasPriority);

    const hasOtherSsrs = otherSsrs.length > 0

    return (
        <BagsBox>
            <BoardingPassCabinBagsComponent boardingPass={props.boardingPass} hasOtherSsrs={hasOtherSsrs}/>
            {/*<BoardingPassPriorityBoardingStatusComponent hasPriority={hasPriority}/>*/}
            <BoardingPassOtherSsrsComponent otherSsrs={otherSsrs}/>
        </BagsBox>
    )
}
