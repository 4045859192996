import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {IJourneyViewModel} from "../../../services/booking/models/journey/journey-view-model.interface";
import styled, {css} from "styled-components";
import {CardBox} from "../../../basic-components/card/card.box";
import {OnlinePreCheckInRestrictionsComponent} from "../passengers/components/online-check-in-validation-dialog.component";
import {CheckboxComponent} from "../../../basic-components/checkbox/checkbox.component";
import {BookingCodeComponent} from "../../../components/booking-code/booking-code.component";
import {BookingFlowPage} from "../../booking-flow/booking-flow.page";
import {
    AdvancedJourneyDesignatorComponent
} from "../../../components/advanced-journey-designator/advanced-journey-designator.component";
import {AlertBoxComponent} from "../../../components/alert-box/alert-box.component";
import {useScreenMediaQueriesChecks} from "../../../hooks/use-screen-media-queries-checks.hook";
import {
    BookingFlowContinueButtonComponent
} from "../../booking-flow/common-components/continue-button/booking-flow-continue-button.component";
import {NeutralButton} from "../../../basic-components/buttons/neutral-button";


const PageContentBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: ${props => props.theme.spacing.spacing30};
`

const PageTitleLargeScreenBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

export const HeaderTitleLargeScreenComponent: React.FC = observer(() => {
    const services = useServices();
    return (
        <PageTitleLargeScreenBox>
            <div>{services.language.translate('Your flight')}</div>
            <BookingCodeComponent recordLocator={services.booking.current.recordLocator}/>
        </PageTitleLargeScreenBox>
    )
})

const JourneyForCheckInBox = styled(CardBox)<{$allowClick: boolean}>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: ${props => props.$allowClick ? "pointer": "default"};
    gap: ${props => props.theme.spacing.cardDefaultPadding};
`

const DesignatorAndCheckInStatusBox = styled.div`
    
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: ${props => props.theme.spacing.spacing16};
`


const PreCheckInRestrictionsTitleBox = styled.div`
    font-weight: ${props => props.theme.fontWeight.bold};
`
const PreCheckInRestrictionsBox = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: ${props => props.theme.spacing.flexGap16};
    gap: ${props => props.theme.spacing.flexGap8};
`

const JourneyStatusAsTopLabelBox = styled.div<{$isCheckinOpen: boolean}>`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
   
    border-radius: 20px;
    font-size: ${props => props.theme.fontSize.caption};
    padding: 6px ${props => props.theme.spacing.spacing12};
    text-align: center;
    white-space: nowrap;
     ${props => props.$isCheckinOpen
    ? css`
        background-color: ${props => props.theme.colors.success};
        color: ${props => props.theme.colors.successContrast};
        `
    : css`
        background-color: ${props => props.theme.colors.danger};
        color: ${props => props.theme.colors.dangerContrast};
    `
    }
   
    
`

const JourneyCheckInStatusComponent: React.FC<{journey: IJourneyViewModel;}> = observer((props) => {
    const services = useServices();

    if(props.journey.isOnlineCheckInAllowed) {
        if(props.journey.isOpenForCheckIn) {
            return (
                <JourneyStatusAsTopLabelBox $isCheckinOpen={true}>
                    {services.language.translate('Check-in open')}
                </JourneyStatusAsTopLabelBox>
            )

        } else {
            return (
                <JourneyStatusAsTopLabelBox $isCheckinOpen={false}>
                    {props.journey.isCanceled ? services.language.translate('Flight Canceled') : services.language.translate('Check-in not open')}
                </JourneyStatusAsTopLabelBox>
            );
        }



    } else {
        let blockingReason = props.journey.getFirstUserIndependentCheckInBlockingRestrictionsMessage();
        if(!blockingReason) {
            blockingReason = services.language.translate('Online check-in is not available for this flight');
        }

        return (
            <AlertBoxComponent severity={"error"} size={"medium"} fullWidth={true}>
                {blockingReason}
            </AlertBoxComponent>
        )

    }
});


interface JourneyForCheckInComponentProps {
    journey: IJourneyViewModel;
    shouldShowCheckBox: boolean;
}

const JourneyForCheckInComponent: React.FC<JourneyForCheckInComponentProps> = observer((props) => {

    const shouldRenderCheckbox = props.shouldShowCheckBox && props.journey.isOpenForCheckIn;

    const onClick = () => {
        if(!shouldRenderCheckbox) {
            return;
        }

        props.journey.isJourneySelected = !props.journey.isJourneySelected;
    }

    const renderCheckBox = () => {
        if(!shouldRenderCheckbox) {
            return null;
        }

        return (
            <CheckboxComponent checked={props.journey.isJourneySelected} isReadOnly={true}/>
        );
    }

    return (
        <JourneyForCheckInBox $allowClick={shouldRenderCheckbox} onClick={onClick}>
            <DesignatorAndCheckInStatusBox>
                <AdvancedJourneyDesignatorComponent journeyDesignator={props.journey.designator}
                                                    isShownInShoppingCart={false}/>
                <JourneyCheckInStatusComponent journey={props.journey}/>
            </DesignatorAndCheckInStatusBox>
            {renderCheckBox()}
        </JourneyForCheckInBox>

    );
});

const JourneysForCheckInComponent: React.FC = observer(() => {
    const services = useServices();
    const unfilteredJourneys =  services.booking.current.unfilteredJourneys;
    const numberOfJourneysOpenForCheckIn = unfilteredJourneys.filter(j => j.isOpenForCheckIn).length;
    return (
        <>
            {
                unfilteredJourneys.filter(j => j.isFutureJourney)
                    .map((j) => {
                        return (
                            <JourneyForCheckInComponent key={j.journeyKey}
                                                        journey={j}
                                                        shouldShowCheckBox={numberOfJourneysOpenForCheckIn > 1}/>

                        );
                    })
            }
        </>
    );
});


const GoToBoardingPassesButtonBox = styled(NeutralButton)`
    min-width: min(100%, 340px);
    color: ${props => props.theme.colors.darkTint};
    border-color: ${props => props.theme.colors.darkTint};
    background-color: transparent;
`

const GoToBoardingPassesButtonComponent: React.FC = observer(() => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();
    const booking = services.booking.current;
    if(booking.balanceDue.amount > 0) {
        return null;
    }


    const checkedInPassengerSegments = booking.getAllPassengersSegments().filter(passengerSegment => passengerSegment.isCheckedIn);

    if(checkedInPassengerSegments.length === 0) {
        return null;
    }

    const showBoardingPasses = async () => {
        await services.booking.current.showBoardingPasses();
    }

    return (
        <GoToBoardingPassesButtonBox onClick={showBoardingPasses}
                                     fullWidth={screenMediaQuery.smallScreen}>
            {services.language.translate('View boarding pass')}
        </GoToBoardingPassesButtonBox>
    );
});

const SmallScreenTitleBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
const SmallScreenTitleComponent: React.FC = observer(() => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();
    if(!screenMediaQuery.smallScreen) {
        return null;
    }
    const booking = services.booking.current;
    return (
        <SmallScreenTitleBox>
            <BookingCodeComponent recordLocator={booking.recordLocator}/>
        </SmallScreenTitleBox>

    );
});

const PreCheckInRestrictionsSegmentsBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap30};
`

const PreCheckInRestrictionsComponent: React.FC = observer(() => {
    const services = useServices();
    const segments = services.booking.current.unfilteredJourneys.selectMany(j => j.segments);


    if(segments.all(segment => segment.passengers.all(passengerSegment => passengerSegment.getPreCheckInRestrictions().length === 0))) {
        return null;
    }

    return (
        <PreCheckInRestrictionsBox>
            <PreCheckInRestrictionsTitleBox>
                {services.language.translate('The following passengers cannot perform online check-in')}
            </PreCheckInRestrictionsTitleBox>

            <PreCheckInRestrictionsSegmentsBox>
                {segments.map(segment => <OnlinePreCheckInRestrictionsComponent key={segment.segmentKey} segment={segment} onBeforeRestrictionActionClick={() => {}}/>)}
            </PreCheckInRestrictionsSegmentsBox>

        </PreCheckInRestrictionsBox>
    )
});


const CheckInFlightSelectionPageContent: React.FC = observer(() => {
    return (
        <PageContentBox>
            <SmallScreenTitleComponent/>
            <JourneysForCheckInComponent/>
            <PreCheckInRestrictionsComponent/>
        </PageContentBox>);
});

const FooterBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap30};
    ${props => props.theme.containerMediaQuery.smallScreen} {
        flex-direction: column;
        gap: ${props => props.theme.spacing.flexGap16};
    }
`

const ContinueButton = styled(BookingFlowContinueButtonComponent)`
    min-width: min(100%, 340px);
    
`

const FooterComponent: React.FC = observer(() => {
    const screenMediaQuery = useScreenMediaQueriesChecks();
    const services = useServices();
    const canRenderThePage = services.booking.currentPageCanBeRendered;
    if(!canRenderThePage) {
        return null;
    }

    return (
        <FooterBox>
            <GoToBoardingPassesButtonComponent/>
            <ContinueButton fullWidth={screenMediaQuery.smallScreen} />
        </FooterBox>
    )
})

export const CheckInFlightSelectionPage: React.FC = observer(() => {
    const services = useServices();
    const canRenderThePage = services.booking.currentPageCanBeRendered;
    const screenMediaQuery = useScreenMediaQueriesChecks();
    const renderTitle = () => {

        if(!canRenderThePage || screenMediaQuery.smallScreen) {
            return services.language.translate('Your flight');
        }

        return (
            <HeaderTitleLargeScreenComponent/>
        )

    }


    return (
        <BookingFlowPage headerOptions={{
                            title: renderTitle(),
                         }}
                         contentOptions={{
                             renderContent: () => (<CheckInFlightSelectionPageContent/>),
                             maxContentWidth: "840px"
                         }}
                         shoppingCartOptions={{
                             hideShoppingCart: true,
                             hideContinueButton: true,
                             showPromotionCode: false
                         }}
                         footerOptions={{
                             renderExtraFooterContent: () => (<FooterComponent/>)
                         }}/>
    );
});
