import React from "react";
import {observer} from "mobx-react";
import { IJourneyViewModel } from "../../../../../services/booking/models/journey/journey-view-model.interface";
import { IMaturePassengerViewModel } from "../../../../../services/booking/models/passenger/mature-passenger-view-model.interface";
import {
    FlightItineraryInnerAccordionComponent
} from "../flight-itinerary-accordion.component";
import styled from "styled-components";
import { PersonCircleFilledIcon } from "../../../../../assets/svg/person-circle-filled.icon";
import {FootNoteComponent} from "../../../../../components/foot-note/foot-note.component";
import {ISsrTypeCount} from "../../../../../services/booking/models/ssrs/ssr-type-count.interface";
import {VerticalSpacer} from "../../../../../basic-components/spacing/vertical-spacer";
import {flightItinerarySpaceBetweenIconAndHeaderText} from "../css-constants";

const AccordionHeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const AccordionHeaderIconBox = styled.div``


const AccordionHeaderTitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.h6};
    color: ${props => props.theme.colors.primaryTint};
    margin-left: ${props => props.theme.spacing.spacing12};
`;

export const PassengerSsrTypeCountBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${props => props.theme.fontSize.h6};
    
`;

export const SsrIconBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    margin-right: ${props => props.theme.spacing.spacing12};
    padding-left: 0;
`;

const SsrTypeDescriptionBox = styled.div`
    display: flex;
    flex-direction: column;
    
    flex-grow: 1;
`
const SsrTypeNameBox = styled.div`
    font-weight: ${props => props.theme.fontWeight.bold};
`

const SsrDetailsBox = styled(FootNoteComponent)`
  font-weight: ${props => props.theme.fontWeight.bold};
`

const PassengerSsrsBox = styled.div`
  padding-left: ${props => `calc(${props.theme.spacing.cardDefaultPadding} + ${flightItinerarySpaceBetweenIconAndHeaderText(props.theme)})`};
`

const SsrTypeCountComponent: React.FC<{ssrTypeCount: ISsrTypeCount}> = observer((props) => {
    const ssrTypeCount = props.ssrTypeCount;
    return (
        <PassengerSsrTypeCountBox>
            <SsrIconBox>{ssrTypeCount.ssrType.smallIcon}</SsrIconBox>
            <SsrTypeDescriptionBox>
                <SsrTypeNameBox>{ssrTypeCount.count + ' x ' + ssrTypeCount.ssrType.name}</SsrTypeNameBox>
                <SsrDetailsBox>{ssrTypeCount.ssrType.details}</SsrDetailsBox>
            </SsrTypeDescriptionBox>
        </PassengerSsrTypeCountBox>
    )
});


interface SsrPassengerComponentProps {
    journey: IJourneyViewModel;
    passenger: IMaturePassengerViewModel;
}

export const FlightItineraryExtrasPassengerSsrsComponent: React.FC<SsrPassengerComponentProps> = observer((props) => {

    const renderContent = () => {
        return (
            <PassengerSsrsBox>
                {
                    props.passenger.getSsrsCountForJourney(props.journey.journeyKey)
                        .filter(journeySsrTypeCount => journeySsrTypeCount.ssrType.shouldShowInFlightItineraryExtras)
                        .map(journeySsrTypeCount => {
                            return (
                                <React.Fragment key={journeySsrTypeCount.ssrType.ssrCode}>
                                    <SsrTypeCountComponent ssrTypeCount={journeySsrTypeCount}/>
                                    <VerticalSpacer size={"small"}/>
                                </React.Fragment>);

                        })
                }
            </PassengerSsrsBox>
        )
    }

    const renderHeader = () => {
        return (
            <AccordionHeaderBox>
                <AccordionHeaderIconBox>
                    <PersonCircleFilledIcon size={2.4} />
                </AccordionHeaderIconBox>
                <AccordionHeaderTitleBox>
                    {props.passenger.getFullName()}
                </AccordionHeaderTitleBox>
            </AccordionHeaderBox>
        )
    }

    return (
        <FlightItineraryInnerAccordionComponent renderHeader={renderHeader}
                                           renderContent={renderContent}/>
    );
});
