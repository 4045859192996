import React from 'react';
import {AvailableFareComponent} from "./available-fare.component";
import {observer} from "mobx-react";
import {IAvailableFareViewModel} from "../../../../../services/flight-search/models/fares/available-fare-view-model.interface";
import styled from "styled-components";

const AvailableFareListBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 40%;
    ${props => props.theme.containerMediaQuery.xlAndBelow} {
        flex-basis: 50%;
    }

    ${props => props.theme.containerMediaQuery.lAndBelow} {
        flex-basis: 60%;
    }
`

interface AvailableFareListComponentProps {
    fares: IAvailableFareViewModel[];
}

export const AvailableFareListComponent: React.FC<AvailableFareListComponentProps> = observer((props) => {
    return (
        <AvailableFareListBox>
            {props.fares.map(fare => (<AvailableFareComponent key={fare.fareUniqueId} fare={fare} />))}
        </AvailableFareListBox>
    );
});
