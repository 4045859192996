import {IServiceFactory} from "../../../../service-factory.interface";
import React from "react";
import {BagSsrLargeIcon} from "./bag.ssr.large-icon";
import {BagSsrSmallIcon} from "./bag.ssr.small-icon";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../../ssrs-preferred-orders-in-shopping-cart";
import {CheckInBagsSsrBase} from "../check-in-bags-ssr-base";
import {
    ISsr3DIconProps,
    SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE,
    SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE
} from "../../../ssr-types.service.interface";


export default class BagSsrType extends CheckInBagsSsrBase {
    constructor(services: IServiceFactory) {
        super('BAG', services);
    }

    get isAvailable(): boolean {
        return this.services.configuration.data.enable32KgBag;
    }

    get bagWeightKg(): number {
        return 32;
    }

    get smallIcon() {
        return (<BagSsrSmallIcon/>);
    }
    get largeIcon() {
        return <BagSsrLargeIcon/>
    }

    get iconInExtrasSmallTile(): React.ReactElement | null {
        return (<BagSsrSmallIcon size={SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE} />);
    }

    get iconInExtrasFirstLargeTile(): React.ReactElement | null {
        return (<BagSsrSmallIcon size={SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE} />);
    }

    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.BAG
    }

    get3DIcon(): ISsr3DIconProps | null {
        return {
            width: 160,
            height: 176,
            imageUrl: this.services.theme.currentTheme.assets.images.bags.bag_32kg_png.toString()
        };
    }
}


