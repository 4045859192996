import React from "react";
import {observer} from "mobx-react";
import {IonIcon, IonMenuToggle} from "@ionic/react";
import {menuOutline} from "ionicons/icons";
import styled from "styled-components";

const BurgerMenuBox = styled(IonMenuToggle)`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    font-size: 24px;
    margin-bottom: -4px;
    
`

export const BurgerMenuComponent: React.FC = observer(() => {
    return (
        <BurgerMenuBox>
            <IonIcon icon={menuOutline}/>
        </BurgerMenuBox>
    )
});