import React, {useRef} from "react";
import {observer} from "mobx-react";
import styled, { css } from "styled-components";
import {CardBox} from "../../../../../basic-components/card/card.box";
import {IBundleViewModel} from "../../../../../services/booking/models/bundle/bundle-view-model.interface";
import {BundleHeaderComponent} from "./bundle-header.component";
import {BundleContentComponent} from "./bundle-content.component";
import { BundleFooterComponent } from "./bundle-footer.component";
import {
    SSR_COLORED_IN_BUNDLE,
    SSR_BOLDED_IN_BUNDLE
} from "../../../../../services/ssr-types/ssr-translation-formatter";
import {NullableString} from "../../../../../types/nullable-types";


function emphasiseWords(emphasiseColor: NullableString) {
    if(!emphasiseColor) {
        return '';
    }

    return css`
        & {
            font-weight: ${props => props.theme.fontWeight.regular};
            & .${SSR_COLORED_IN_BUNDLE} {
                color: ${emphasiseColor};
            }

            & .${SSR_BOLDED_IN_BUNDLE} {
                font-weight: ${props => props.theme.fontWeight.bold};
            }
        }
    `
}


const BundleBox = styled(CardBox)<{$isSelected: boolean, $disableNotSelected: boolean; $isFavouriteBundle: boolean; $emphasiseColor: NullableString; $selectedBorderColor: string}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: ${props => props.$isFavouriteBundle ? "unset" : "hidden"}; /*For classic bundle we can't put hidden because absolute position for Customer Favourite label will not work anymore*/
    opacity: ${props => !props.$isSelected && props.$disableNotSelected ? '0.35' : '1'};
    padding: 0;
    width: 100%;
    cursor: ${props => props.$isSelected ? "default" : (props.$disableNotSelected ? "not-allowed" : "pointer")};
    ${props => props.$isSelected ? 
        css`
            border: 1px solid ${props.$selectedBorderColor};
        `
        : css`
            border: 1px solid ${props => props.theme.border.mainColor};
        `
    }
    
    ${props => emphasiseWords(props.$emphasiseColor)}

    ${props => props.theme.containerMediaQuery.smallScreen} {
        height: fit-content;
    }
`

interface BundleComponentProps {
    bundle: IBundleViewModel;
    disableNotSelected: boolean;
    footerButtonType: "selected" | "continue",
    showReturnToDepartureLink: boolean;
    onSellBundleClick?: (bundle: IBundleViewModel) => void;
}

export const BundleComponent: React.FC<BundleComponentProps> = observer((props) => {

    const elementRef = useRef<any>(null);
    const bundle = props.bundle;

    const scrollElementIntoView = () => {
        elementRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center"
        });
    }

    const onClick = async () => {
        if(props.onSellBundleClick) {
            props.onSellBundleClick(props.bundle);
        }

        setTimeout(() => {
            scrollElementIntoView();
        }, 250);

    }

    return (
        <BundleBox $isSelected={bundle.isSelected}
                   $disableNotSelected={props.disableNotSelected}
                   $isFavouriteBundle={bundle.bundleType.isFavouriteBundle}
                   $emphasiseColor={bundle.bundleType.getEmphasiseSsrWordsColor()}
                   $selectedBorderColor={bundle.bundleType.getSelectedBorderColor()}
                   ref={elementRef}
                   onClick={onClick}>
            <BundleHeaderComponent bundle={bundle}/>
            <BundleContentComponent bundle={bundle}/>
            <BundleFooterComponent bundle={bundle}
                                   showContinueButton={props.footerButtonType === "continue"}
                                   showReturnToDepartureLink={props.showReturnToDepartureLink}
                                   isDisabled={!bundle.isSelected && props.disableNotSelected}/>
        </BundleBox>

    );
});
