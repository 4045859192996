import {ExtrasSectionRendererBase} from "./extras-section-renderer.base";
import {IBookingViewModel} from "../../../../services/booking/models/booking-view-model.interface";
import {ISsrType} from "../../../../services/ssr-types/ssr-types.service.interface";
import {ExtrasSsrSectionLargeTileComponent} from "../base-component/large-tile/extras-ssr-section.large-tile.component";
import React from "react";
import {ExtrasSsrSectionSmallTileComponent} from "../base-component/small-tile/extras-ssr-section.small-tile.component";
import {NullableString} from "../../../../types/nullable-types";
import {IBookingSsrsAggregatorViewModel} from "../../../../services/booking/models/ssrs/aggregator/booking-ssrs-aggregator-view-model.interface";
import {IRenderLargeTileOptions} from "./extras-section-renderer.interface";
import {DialogCloseButtonBehavior} from "../../../../services/dialog/dialog-enums";


export abstract class ExtrasSsrsSectionRenderer extends ExtrasSectionRendererBase {
    constructor(private readonly _ssrTypes: ISsrType[], booking: IBookingViewModel) {
        super(booking);
        this._ssrsAggregator = this._createSsrsAggregator();
    }

    abstract getTitle(): string | React.ReactElement;
    protected abstract _getMarketingMessage(): NullableString;


    get ssrTypes(): ISsrType[] {
        return this._ssrTypes;
    }

    get canBeModified(): boolean {
        return this._ssrsAggregator.canBeModified;
    }

    get isFulfilled(): boolean {

        for(let ssrType of this.ssrTypes) {
            for(let ps of this.booking.getAllPassengersSegments()) {
                if(ps.getSsr(ssrType).newQuantity === 0) {
                    return false;
                }
            }
        }

        return true;

    }

    //TODO - override this in all required SSRs
    protected _renderDialogSubtitle(): (NullableString | React.ReactElement) {
        if(this.ssrTypes.length === 1) {
            return this.ssrTypes[0].details;
        }
        return null;
    }

    protected readonly _ssrsAggregator: IBookingSsrsAggregatorViewModel;

    shouldRender(): boolean {
        if(!this.ssrTypes.some(ssrType => this.booking.shouldShowSsrOnCurrentFlow(ssrType))) {
            return false;
        }

        return this.ssrTypes.some(ssrType => this.booking.hasAvailabilityForSSR(ssrType))
                || this.ssrTypes.some(ssrType => this.booking.hasPurchasesForSSR(ssrType));
    }

    protected _createSsrsAggregator(): IBookingSsrsAggregatorViewModel {
        return this.booking.createSsrsAggregator({
            ssrTypes: this.ssrTypes
        });
    }

    protected _renderFirstLargeTileIcon(): React.ReactElement | null {
        return this.ssrTypes.find(ssrType => ssrType.iconInExtrasFirstLargeTile)?.iconInExtrasFirstLargeTile ?? null;
    }
    protected _renderSecondLargeTileIcon(): React.ReactElement | null {
        return this.ssrTypes.find(ssrType => ssrType.iconInExtrasSecondLargeTile)?.iconInExtrasSecondLargeTile ?? null;
    }

    protected _renderSmallTileIcon(): React.ReactElement | null {
        return this.ssrTypes.find(ssrType => ssrType.iconInExtrasSmallTile)?.iconInExtrasSmallTile ?? null;
    }




    private _showEditDialog = async () => {

        this.services.analytics.extrasSelectionEvents.logSelectItem(this.booking, this.ssrTypes.map( s => s.ssrCode));

        await this.services.dialogFactory.showSsrsSelector({
            closeButtonBehavior: DialogCloseButtonBehavior.Accept,
            ssrsAggregator: this._ssrsAggregator,
            renderTitle: () => this.getTitle(),
            renderSubtitle: () => this._renderDialogSubtitle(),
            renderIcon: () => this._renderSmallTileIcon()
        })


    }

    private _countSelectedSsrs() {
        return this._ssrsAggregator.totalSsrsQuantity;
    }

    renderLargeTile(options: IRenderLargeTileOptions): React.ReactElement {
        return (
            <ExtrasSsrSectionLargeTileComponent ssrsAggregator={this._ssrsAggregator}
                                                sectionTitle={this.getTitle()}
                                                renderFirstTileIcon={() => this._renderFirstLargeTileIcon()}
                                                renderSecondTileIcon={() => this._renderSecondLargeTileIcon()}
                                                isFirstTile={options.isFirstTile}
                                                getMarketingMessage={() => this._getMarketingMessage()}
                                                countSelectedSsrs={() => this._countSelectedSsrs()}
                                                onEditButtonClick={() => this._showEditDialog()}
                                                imageUrl={this._getExtrasLargeTilePicture()}/>
        );
    }

    renderSmallTile(): React.ReactElement {
        return (
            <ExtrasSsrSectionSmallTileComponent sectionTitle={this.getTitle()}
                                                renderIcon={() => this._renderSmallTileIcon()}
                                                ssrsAggregator={this._ssrsAggregator}
                                                countSelectedSsrs={() => this._countSelectedSsrs()}
                                                onEditButtonClick={() => this._showEditDialog()}/>
        );
    }
}
