import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {IPassengerAllBagsViewModel} from "../../../../../services/booking/models/ssrs/bags/passenger-all-bags-view-model.interface";
import {BagsSelectionContainerBox} from "../../boxes/bags-selection-container.box";
import {CheckInBagSelectionComponent} from "./check-in-bag/check-in-bag-selection.component";
import {CheckInBagsInfoComponent} from "./check-in-bags-info/check-in-bags-info.component";
import {NoCheckInBagsComponent} from "./no-check-in-bags/no-check-in-bags.component";
import {useContainerMediaQueriesChecks} from "../../../../../hooks/use-container-media-queries-checks.hook";
import {BagsSelectionStepBulletComponent} from "../bags-selection-step-bullet/bags-selection-step-bullet.component";
import {
    CheckInBagsMustChooseAnOptionComponent
} from "./must-choose-an-option/check-in-bags-must-choose-an-option.component";



const CheckInBagsBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap20};
`

const CheckInBagsContainerBox = styled(BagsSelectionContainerBox)`
    background-color: ${props => props.theme.colors.successVeryLight};
    padding-top: ${props => props.theme.spacing.spacing16};
`



const BagsAndMustChooseContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap16};
    width: 100%;
`

const StepBulletContainerBox = styled.div`
    position: absolute;
    top: 0;
    padding: 0;
    transform: translateY(-50%);
`

interface PassengerCheckInBagsSelectionComponentProps {
    passengerBags: IPassengerAllBagsViewModel;
}

export const PassengerCheckInBagsSelectionComponent: React.FC<PassengerCheckInBagsSelectionComponentProps> = observer((props) => {

    const mediaQuery = useContainerMediaQueriesChecks();

    const isInSmallScreen = mediaQuery.smallScreen;
    const checkInBags = props.passengerBags.checkInBags;

    const captureElementRef = (el: any) => {
        props.passengerBags.attachCheckInBagsContainerDOMElement(el);
    }

    const hasError = Boolean(props.passengerBags.checkInBagsErrorMessage);

    const renderStepBullet = () => {

        return (
            <StepBulletContainerBox>
                <BagsSelectionStepBulletComponent stepNumber={2} hasError={hasError}/>
            </StepBulletContainerBox>
        );
    }


    return (
        <CheckInBagsContainerBox ref={captureElementRef}>
            {renderStepBullet()}
            <CheckInBagsInfoComponent passengerBags={props.passengerBags}/>
            <BagsAndMustChooseContainerBox>
                <CheckInBagsBox>
                    {!isInSmallScreen && <NoCheckInBagsComponent key={"NO_CHECKIN_BAG"} passengerBags={props.passengerBags}/> }
                    {checkInBags.map(bag => (<CheckInBagSelectionComponent key={bag.ssrType.ssrCode} bagSsrEditor={bag}/>))}
                </CheckInBagsBox>

                {isInSmallScreen && <NoCheckInBagsComponent key={"NO_CHECKIN_BAG"} passengerBags={props.passengerBags}/> }

                <CheckInBagsMustChooseAnOptionComponent passengerBags={props.passengerBags} hasError={hasError}/>
            </BagsAndMustChooseContainerBox>
        </CheckInBagsContainerBox>
    );
});
