import React from "react";
import {observer} from "mobx-react";
import {PromotionalBannersFeedsComponent} from "./promotional-banners-feeds.component";
import {
    MaintenanceAnnouncementComponent
} from "../../../../../../components/maintenance/maintenance-announcement.component";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {
    HomePageHybridContentTopSectionComponent
} from "../../../content/home-page-hybrid-content-top-section.component";
import {NextFlightsComponent} from "./next-flights/next-flights.component";
import {
    HomePageHybridContentBottomSectionComponent
} from "../../../content/home-page-hybrid-content-bottom-section.component";
import styled from "styled-components";
import {
    SearchMaskBookNowButtonComponent
} from "../../../../../../components/search-mask/common/search-mask-book-now-button.component";
import {SearchMaskHybridComponent} from "../../../../../../components/search-mask/hybrid/search-mask.hybrid.component";
import {BookTabGreetingMessageComponent} from "./book-tab-greeting-message.component";
import {VerticalSpacer} from "../../../../../../basic-components/spacing/vertical-spacer";

const BookNewTravelButton = styled(SearchMaskBookNowButtonComponent)`
    font-size: ${props => props.theme.fontSize.h4};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

const ContentBottomSection = styled(HomePageHybridContentBottomSectionComponent)`
    flex-grow: unset;
`


const SiteUnderMaintenanceComponent: React.FC = observer(() => {
    const services = useServices();
    if (!services.maintenance.data.enableMaintenanceAnnouncement) {
        return <></>;
    }

    const startIsoDate = services.time.parseIsoDate(services.maintenance.data.startDateMaintenanceAnnouncement!)
    const endIsoDate = services.time.parseIsoDate(services.maintenance.data.endDateMaintenanceAnnouncement!)

    const startDate = services.time.formatUserFriendlyDate(startIsoDate);
    const startHour = services.time.formatHHmm(startIsoDate);
    const endHour = services.time.formatHHmm(endIsoDate);
    const weekDayAbbreviation = services.time.getDayFullName(startIsoDate.getDay());
    return (
        <MaintenanceAnnouncementComponent>
            {services.language.translationFor(`Our mobile app is undergoing scheduled maintenance on {weekDayAbbreviation} {startDate}, from {startHour} to {endHour}. During this period booking, check-in and manage my booking functions will be unavailable.`)
                .withParams({
                    startDate: startDate,
                    weekDayAbbreviation: weekDayAbbreviation,
                    startHour: startHour,
                    endHour: endHour,
                })
            }
        </MaintenanceAnnouncementComponent>
    )
})

export const BookTabContentComponent: React.FC = observer(() => {
    const services = useServices();
    const nextFlights = services.bookingHistory.getNextFlights();

    const renderContentTopSection = () => {
        if(nextFlights.length === 0) {
            return (
                <HomePageHybridContentTopSectionComponent>
                    <BookTabGreetingMessageComponent/>
                </HomePageHybridContentTopSectionComponent>
            );
        }

        return (
            <HomePageHybridContentTopSectionComponent>
                <NextFlightsComponent />
            </HomePageHybridContentTopSectionComponent>
        );
    }

        const renderSearchMask = () => {
        if (services.maintenance.data.enableMaintenancePage) {
            return null;
        }

        if(nextFlights.length > 0) {
            return null;
        }

        return (
            <SearchMaskHybridComponent />
        );
    }

    const renderBookNewTravelButton = () => {
        if (services.maintenance.data.enableMaintenancePage) {
            return null;
        }
        if(nextFlights.length === 0) {
            return null;
        }

        return (
            <>
                <VerticalSpacer size={"small"}/>
                <BookNewTravelButton label={services.language.translate("Book new travel")} onClick={() => services.flightSearch.steps.start()}/>
            </>

        );
    }

    const renderContentBottomSection = () => {
        return (
            <ContentBottomSection>
                {renderBookNewTravelButton()}
                {renderSearchMask()}
            </ContentBottomSection>
        )
    }

    return <>
        {<SiteUnderMaintenanceComponent/>}
        {renderContentTopSection()}
        {renderContentBottomSection()}
        <VerticalSpacer size={"small"}/>
        <PromotionalBannersFeedsComponent />
    </>;
})