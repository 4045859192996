
import {BookingModel} from "../../booking.model";
import {IFeeModel} from "../../base-models/fees/fee-model.interface";
import {mergeFeesCollections} from "../shopping-cart.helpers";
import {IBookingShoppingCartViewModel} from "./booking-shopping-cart-view-model.interface";
import {Price} from "../../../../currency/price";
import {computed, makeObservable, observable, runInAction} from "mobx";
import {OtherFeeModel} from "../../base-models/fees/other/other-fee.model";
import {ShoppingCartModeEnum} from "../../../booking-strategies/booking-strategy.interface";
import {JourneyShoppingCartModel} from "../journey/journey-shopping-cart.model";
import {FeeDecorator} from "../../base-models/shopping-cart/fee-decorator";
import {BookingShoppingCartNotificationsModel} from "./booking-shopping-cart-notifications.model";
import {IServiceFactory} from "../../../../service-factory.interface";
import {AnalyticsConstants, IAnalyticsItem} from "../../analytics/google-analytics.intefaces";

export class BookingShoppingCartModel implements IBookingShoppingCartViewModel {
    constructor(private readonly booking: BookingModel) {
        this._currentShoppingCartMode = booking.allowedShoppingCartModes[0];
        this.notifications = new BookingShoppingCartNotificationsModel(this);
        makeObservable<this, '_currentShoppingCartMode'>(this, {
            allBookingLevelFees: computed,
            _currentShoppingCartMode: observable.ref,
            journeysNewPurchases: computed,
            journeysAllPurchases: computed
        });
    }

    get services(): IServiceFactory {
        return this.booking.services;
    }

    readonly notifications: BookingShoppingCartNotificationsModel;

    get allowedShoppingCartModes(): ShoppingCartModeEnum[] {
        return this.booking.allowedShoppingCartModes;
    }


    private _currentShoppingCartMode: ShoppingCartModeEnum;
    get currentShoppingCartMode(): ShoppingCartModeEnum {
        if(this.allowedShoppingCartModes.length === 1) {
            return this.allowedShoppingCartModes[0];
        }
        return this._currentShoppingCartMode;
    }
    set currentShoppingCartMode(value: ShoppingCartModeEnum) {
        runInAction(() => {
            this._currentShoppingCartMode = value;
        })
    }

    get journeysAllPurchases(): JourneyShoppingCartModel[] {
        return this.booking.unfilteredJourneys.map((j, index) => new JourneyShoppingCartModel(() => this.booking.unfilteredJourneys[index], ShoppingCartModeEnum.ShowAllPurchases));
    }

    get journeysNewPurchases(): JourneyShoppingCartModel[] {
        return this.booking.filteredJourneys.map((j, index) => new JourneyShoppingCartModel(() => this.booking.filteredJourneys[index], ShoppingCartModeEnum.ShowNewPurchasesOnly));
    }

    get journeysShoppingCartsToDisplay(): JourneyShoppingCartModel[] {
        if(this.currentShoppingCartMode === ShoppingCartModeEnum.ShowAllPurchases) {
            return this.journeysAllPurchases;
        } else {
            return this.journeysNewPurchases;
        }
    }

    get allBookingLevelFees(): IFeeModel[] {
        let result: IFeeModel[] = this.booking.getBookingLevelFees()
                                              .map(fee => new OtherFeeModel(fee, this.booking.initialBookingSnapshot.getInitialBookingLevelFeePrice(fee.feeKey)));


        return mergeFeesCollections([result]);
    }

    get bookingLevelFeesToDisplay(): IFeeModel[] {
        if(this.currentShoppingCartMode === ShoppingCartModeEnum.ShowAllPurchases) {
            return this.allBookingLevelFees.map(f => new FeeDecorator(f, this.currentShoppingCartMode));
        } else {
            return this.allBookingLevelFees.filter(f => f.isPurchasedOnCurrentSession).map(f => new FeeDecorator(f, this.currentShoppingCartMode));
        }
    }


    get computedTotalToDisplay(): Price {
        return Price.sumAll([
            ...this.journeysShoppingCartsToDisplay.map(j => j.grandTotalToDisplay),
            ...this.bookingLevelFeesToDisplay.map(f => f.totalToDisplay)
        ], this.booking.createPrice(0));
    }

    get hasPurchaseOnCurrentSession(): boolean {
        return this.journeysAllPurchases.some(j => j.hasChangesOnCurrentSession)
    }

    get balanceDue(): Price {
        return this.booking.balanceDue;
    }

    getBookingLevelFeesAnalyticsPurchaseItems(): IAnalyticsItem[] {
        return this.booking.getBookingLevelFees()
            .filter(fee => fee.isPurchasedOnCurrentSession)
            .map(fee => {
                return {
                    item_name: `${AnalyticsConstants.bookingFees}|${fee.feeCode}`,
                    item_id: `${AnalyticsConstants.bookingFees}|${fee.feeKey}|${fee.feeCode}`,
                    item_brand: this.booking.analyticsConfiguration.itemBrand,
                    item_category: `${fee.feeType}|${fee.feeCode}`,
                    item_category2: fee.passengerTypeCode,
                    price: fee.priceToDisplay.amount,
                    discount: fee.initialPrice?  fee.initialPrice?.amount - fee.priceToDisplay.amount: 0,
                    quantity: fee.quantity,
                }
            });
    }

    get shouldShowPriceDifferences(): boolean {
        return false;
        /*
        if(this.allowedShoppingCartModes.length === 1 && this.allowedShoppingCartModes[0] === ShoppingCartModeEnum.ShowAllPurchases) {
            return false;
        }

        return true;
         */
    }
}
