import React from "react";
import {observer} from "mobx-react";
import {StationDropDownComponent} from "./station-drop-down.component";
import {useServices} from "../../../../hooks/use-services.hook";
import {FieldEditorCommonProps} from "../../../forms/inputs-common.props";
import {PlaneDepartingLineNotFilledIcon} from "../../../../assets/svg/plane-departing-line-not-filled.icon";


export const OriginStationDropDownComponent: React.FC<FieldEditorCommonProps> = observer((props) => {
    const services = useServices();
    const searchController = services.flightSearch.searchController;

    const getOriginStations = () => {
        return searchController.availableDepartureStations;
    }

    const renderDepartureNoStationsMessage = () => {
        return services.language.translate('No departure available');
    }


    return (
        <StationDropDownComponent {...props}
                                  field={searchController.fields.departureOrigin}
                                  renderLabel={() => services.language.translate('Departure')}
                                  renderIcon={() => <PlaneDepartingLineNotFilledIcon size={1}/>}
                                  getStations={getOriginStations}
                                  placeholder={services.language.translate('Select departure')}
                                  noStationsMessage={renderDepartureNoStationsMessage}/>
    )
})