import React from 'react';
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {FlightDatesAvailabilityComponent} from "../components/availability/flight-dates-availability.component";

export const DepartingFlightSelectionAvailabilityComponent: React.FC = observer(() => {
    const services = useServices();
    const availability = services.booking.availability;

    if(!availability.departureOrigin || !availability.departureDestination || !availability.departureDate) {
        return null;
    }

    const onDateChanged = async (date: Date) => {
        await availability.changeDepartingDate(date);
    }

    return (
        <FlightDatesAvailabilityComponent flightSchedule={availability.departureFlightSchedule}
                                          currentScheduleSelectedDate={availability.departureDate}
                                          onDateSelected={onDateChanged}
                                          getLowFare={(date) => availability.getDepartureLowFare(date)}/>

    );
});
