import React from "react";
import {observer} from "mobx-react";
import {
    IAeroitaliaInvoiceViewModel
} from "../../../../services/booking/models/invoice/aeroitalia/aeroitalia.invoice-view-model.interface";
import {NullableString} from "../../../../types/nullable-types";
import {ItalyCustomerTypeEnum} from "../../../../services/booking/models/invoice/aeroitalia/italy-customer-type.enum";


export const AeroitaliaInvoiceViewComponent: React.FC<{invoice: IAeroitaliaInvoiceViewModel}> = observer((props) => {
    const lines: NullableString[] = [];
    if(props.invoice.fields.customerType.value === ItalyCustomerTypeEnum.ItalianPhysicalPerson) {
        lines.push(`${props.invoice.fields.firstName.value} ${props.invoice.fields.lastName.value}`);
    } else {
        lines.push(props.invoice.fields.customerName.value);
    }

    lines.push(`${props.invoice.fields.address.value} ${props.invoice.fields.addressNumber.value}`);

    if(props.invoice.fields.zipCode.value) {
        lines.push(`${props.invoice.fields.city.value}, ${props.invoice.fields.zipCode.value}`);
    } else {
        lines.push(props.invoice.fields.city.value);
    }


    return (
        <div>
            {lines.map(l => <div>{l}</div>)}
        </div>
    )
})