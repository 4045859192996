import React from "react";
import {observer} from "mobx-react";
import {NullableDate} from "../../../../types/nullable-types";
import {NullablePrice} from "../../../../services/currency/price";
import styled, {css} from "styled-components";
import {DayPriceComponent} from "../calendar/days/day-price.component";
import {useServices} from "../../../../hooks/use-services.hook";
import {closeOutline} from "ionicons/icons";
import {IonIcon} from "@ionic/react";
import {Station} from "../../../../services/stations/station.service.interface";
import {PlaneGoIcon} from "../../../../assets/svg/plane-go.icon";

const ContainerBox = styled.div<{$showBottomBorder: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    border: none;
    border-radius: ${props => props.theme.border.defaultRadius};
    padding: ${props => props.theme.spacing.spacing8} ${props => props.theme.spacing.spacing16};
    gap: 4px;
    ${props => props.$showBottomBorder 
        ? css`
            border-bottom: 4px solid ${props => props.theme.colors.primary};
            box-shadow: 4px 6px 3px ${props => props.theme.colors.light};
        `
        : css`
            border-bottom: none;
            box-shadow: none;
        `}
    
`



const TopSectionBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.spacing8};
`

const BottomSectionBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${props => props.theme.fontSize.h2};
    font-weight: bold;
    color: ${props => props.theme.colors.dark};
`

const RemoveButtonBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 21px;
    height: 21px;
    cursor: pointer;
    font-size: 20px;
`

const StationBox = styled.div`
    font-size: ${props => props.theme.fontSize.body2};
`

const FlightRouteBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.spacing8};
`

const SelectDateBox = styled.div`
    color: ${props => props.theme.colors.medium};
`

const DayPrice = styled(DayPriceComponent)`
    box-shadow: 3px 3px 3px ${props => props.theme.colors.light};
    border-radius: ${props => props.theme.border.defaultRadius};
    padding: 4px;
    border: 1px solid ${props => props.theme.border.mainColor};
`


interface FlightSelectedDatePreviewComponentProps {
    origin: Station;
    destination: Station;
    selectedDate?: NullableDate;
    price: NullablePrice;
    onRemoveButtonClick: () => void;
    showBottomBorder: boolean;
}
export const FlightSelectedDatePreviewComponent: React.FC<FlightSelectedDatePreviewComponentProps> = observer((props) => {
    const services = useServices();

    const renderBottomSectionContent = () => {
        if(props.selectedDate) {
            return (
                <>
                    <div>
                        {services.time.formatUserFriendlyDate(props.selectedDate)}
                    </div>
                    <RemoveButtonBox onClick={props.onRemoveButtonClick}>
                        <IonIcon icon={closeOutline}/>
                    </RemoveButtonBox>

                </>
            )
        } else {
            return (
                <SelectDateBox>
                    {services.language.translate('Select date')}
                </SelectDateBox>
            )
        }
    }


    return (
        <ContainerBox $showBottomBorder={props.showBottomBorder}>
            <TopSectionBox>
                <FlightRouteBox>
                    <StationBox>
                        {props.origin.stationCode}
                    </StationBox>
                    <PlaneGoIcon size={1}/>
                    <StationBox>
                        {props.destination.stationCode}
                    </StationBox>
                </FlightRouteBox>
                <DayPrice price={props.price}/>
            </TopSectionBox>
            <BottomSectionBox>
                {renderBottomSectionContent()}
            </BottomSectionBox>
        </ContainerBox>
    )

})