import React from "react";
import {observer} from "mobx-react";
import {IBlueBenefitDiscountInfo} from "../../../../services/blue-benefits/models/packs/blue-benefits-package.interface";
import styled from "styled-components";
import {BlueBenefitsDiscountIcon} from "../../../../assets/svg/blue-benefits-discount.icon";

const DiscountInfoContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin-top: 10px;
    min-height: 80px;
    background-color: ${props => props.theme.colors.light};
    padding: 5px;
    border-radius: 5px;
`

const DiscountInfoIconBox = styled.div`
    display: flex;
    margin-right: 15px;
    margin-left: 15px;
    justify-content: center;
`

const DiscountDetailsBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
    
    margin-left: 5px;
`

const DiscountValueBox = styled.div`
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.colors.secondary};
`

const DiscountDescriptionBox = styled.div`
    font-size: ${props => props.theme.fontSize.h6};
    color: ${props => props.theme.colors.medium};
    margin-top: 2px;
`

interface BlueBenefitsDiscountInfoComponentProps {
    discountInfo: IBlueBenefitDiscountInfo;
}

export const BlueBenefitsDiscountInfoComponent: React.FC<BlueBenefitsDiscountInfoComponentProps> = observer((props) => {
    return (
        <DiscountInfoContainerBox>
            <DiscountInfoIconBox>
                <BlueBenefitsDiscountIcon/>
            </DiscountInfoIconBox>
            <DiscountDetailsBox>
                <DiscountValueBox>{props.discountInfo.value}</DiscountValueBox>
                <DiscountDescriptionBox>{props.discountInfo.description}</DiscountDescriptionBox>
            </DiscountDetailsBox>

        </DiscountInfoContainerBox>
    )
});
