import React from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {
    IDateSelectionStrategy
} from "../../../../services/flight-search/dates-selection/strategy/date-selection-strategy.interface";
import {useServices} from "../../../../hooks/use-services.hook";
import {PlaneGoIcon} from "../../../../assets/svg/plane-go.icon";
import {
    FlightsDatesSelectorModel
} from "../../../../services/flight-search/dates-selection/flights-dates-selector.model";


const HeaderContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    padding-left: ${props => props.theme.spacing.flexGap20};
    gap: 4px;
`

const HeaderItemBox = styled.div<{$isSelected: boolean}>`
    padding: ${props => props.theme.spacing.spacing16} ${props => props.theme.spacing.spacing24};
    border-top-left-radius: ${props => props.theme.border.defaultRadius};
    border-top-right-radius: ${props => props.theme.border.defaultRadius};
    
    ${props => props.$isSelected
    ? css`
                background-color: ${props => props.theme.colors.cardBackground};
                cursor: default;
        `
    : css`
                background-color: ${props => props.theme.colors.lightShade};
                opacity: 0.5;
                cursor: pointer;
        `
}
`

const DesignatorContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    font-size: ${props => props.theme.fontSize.body2};
`


const HeaderItemWithoutDateSelectionBox = styled.div`
    display: flex;
    flex-direction: row;
    gap: 6px;
    font-size: ${props => props.theme.fontSize.h4};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

const HeaderItemWithDateSelectedBox = styled.div`
    display: flex;
    flex-direction: column;
`

const SelectedDateBox = styled.div`
    font-size: ${props => props.theme.fontSize.h2};
    font-weight: ${props => props.theme.fontWeight.bold};
`

const HeaderItemComponent: React.FC<{dateSelectionStrategy: IDateSelectionStrategy}> = observer((props) => {
    const services = useServices();
    const strategy = props.dateSelectionStrategy;
    const selectedDate = strategy.getCurrentDate();

    const onClickHandler = () => {
        strategy.setAsCurrentStrategy();
    }

    const renderContent = () => {
        if(selectedDate) {
            return (
                <HeaderItemWithDateSelectedBox>
                    <DesignatorContainerBox>
                        <div>{strategy.origin.stationCode}</div>
                        <PlaneGoIcon size={0.8}/>
                        <div>{strategy.destination.stationCode}</div>
                    </DesignatorContainerBox>
                    <SelectedDateBox>
                        {services.time.formatUserFriendlyDate(selectedDate)}
                    </SelectedDateBox>
                </HeaderItemWithDateSelectedBox>
            );
        } else {
            return (
                <HeaderItemWithoutDateSelectionBox>
                    <div>{strategy.origin.stationCode}</div>
                    <PlaneGoIcon size={1}/>
                    <div>{strategy.destination.stationCode}</div>
                </HeaderItemWithoutDateSelectionBox>
            )
        }
    }

    return (
        <HeaderItemBox $isSelected={props.dateSelectionStrategy.isCurrentStrategy} onClick={onClickHandler}>
            {renderContent()}
        </HeaderItemBox>
    )


})

export const ManageMyBookingNewFlightsDatesSelectionHeaderLargeScreenComponent: React.FC<{flightsDatesSelector: FlightsDatesSelectorModel}> = observer((props) => {
    return (
        <HeaderContainerBox>
            {props.flightsDatesSelector.strategies.map(s => <HeaderItemComponent key={s.uniqueKey} dateSelectionStrategy={s}/>)}
        </HeaderContainerBox>
    );
})