import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";

const TabWrapperBox = styled.div<{$hideBottomBorder: boolean}>`
    width: 100%;
    ${props => props.$hideBottomBorder
        ? ''
        : css`
            border-bottom: 1px solid ${props => props.theme.colors.lightShade};
        `
    }
`

interface TabWrapperComponentProps extends PropsWithChildren {
    className?: string;
    hideBottomBorder?: boolean;
}
export const TabWrapperComponent: React.FC<TabWrapperComponentProps> = observer((props) => {
    return (
        <TabWrapperBox className={props.className}
                       $hideBottomBorder={Boolean(props.hideBottomBorder)}>
            {props.children}
        </TabWrapperBox>
    )
})