import React, {useState} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {PrimaryButton} from "../../../../basic-components/buttons/primary-button";
import {ExternalLinkComponent} from "../../../../components/external-link/external-link.component";
import {useServices} from "../../../../hooks/use-services.hook";
import {useScreenMediaQueriesChecks} from "../../../../hooks/use-screen-media-queries-checks.hook";
import {IonToggle} from "@ionic/react";

const FooterBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-items: center;
    gap: ${props => props.theme.spacing.spacing20};
    padding: ${props => props.theme.spacing.pageFooterVerticalPadding} ${props => props.theme.spacing.pageFooterHorizontalPadding};
    width: 100%;
    ${(props) => props.theme.containerMediaQuery.smallScreen} {
        flex-direction: column;
    }
`

const TermsAndConditionsToggleBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.spacing12};
`

const TermsAndConditionsBox = styled(ExternalLinkComponent)`
    font-size: ${props => props.theme.fontSize.body1};
`

const AcceptButton = styled(PrimaryButton)`
    min-width: 200px;
`





export const DangerousGoodsAgreementFooterComponent: React.FC = observer(() => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();
    const [accepted, setAccepted] = useState(false);
    const onAgree = () => {
        services.booking.current.dangerousGoodsTermsAndConditionsAccepted = true;
    }


    return (
        <FooterBox>
            <TermsAndConditionsToggleBox>
                <IonToggle checked={accepted} onIonChange={(e) => setAccepted(e.detail.checked)}/>
                <TermsAndConditionsBox innerHtml={services.externalLinks.dangerousGoods}/>
            </TermsAndConditionsToggleBox>

            <AcceptButton onClick={onAgree}  fullWidth={screenMediaQuery.smallScreen} disabled={!accepted}>
                {services.language.translate('Continue')}
            </AcceptButton>

        </FooterBox>
    );
})