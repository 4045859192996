import styled from "styled-components";
import {CardBox} from "../../../basic-components/card/card.box";

export const BookingFlowLargeScreenPageRightSectionBodyBox = styled(CardBox)`
    
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
    padding: 0;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
    scrollbar-gutter: stable;
    &:hover {
        overflow-y: auto; /* Show scrollbar on hover */
    }
    
    ${props => props.theme.screenMediaQuery.pointerNone} {
        overflow-y: auto;
    }
    
`