import React, {CSSProperties, PropsWithChildren} from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";

export const BoardingPassHeaderComponent: React.FC<PropsWithChildren> = observer((props) => {
    const services = useServices();
    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        gap: services.theme.currentTheme.spacing.flexGap12

    };

    return (
        <div style={styles}>
            {props.children}
        </div>
    )
})

export const BoardingPassHeaderRightSectionBox: React.FC<PropsWithChildren> = observer((props) => {
    const services = useServices();
    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: services.theme.currentTheme.spacing.flexGap12
    };

    return (
        <div style={styles}>
            {props.children}
        </div>
    )
})