import React from "react";
import {observer} from "mobx-react";
import {
    IFlightDesignatorViewModel
} from "../../../../services/booking/models/designator/flight-designator-view-model.interface";
import styled from "styled-components";
import {LeftSectionComponent} from "../common/left-section.component";
import {MiddleSectionCollapsedComponent} from "./middle-section-collapsed.component";
import {RightSectionComponent} from "../common/right-section.component";
import {GridBaseBox} from "../../boxes/grid-base.box";



const GridBox = styled(GridBaseBox)`
    gap: ${props => props.theme.spacing.spacing8};
    font-size: ${props => props.theme.fontSize.caption};
`

interface AdvancedJourneyDesignatorCollapsedComponentProps {
    journeyDesignator: IFlightDesignatorViewModel;
    isShownInShoppingCart: boolean;
    onExpandClick: () => void;
}
export const AdvancedJourneyDesignatorCollapsedComponent: React.FC<AdvancedJourneyDesignatorCollapsedComponentProps> = observer((props) => {

    return (
        <GridBox>
            <LeftSectionComponent isShownInShoppingCart={props.isShownInShoppingCart}
                                  station={props.journeyDesignator.origin}
                                  formattedDepartureTime={props.journeyDesignator.formatDepartureTime()}
                                  formattedDepartureDate={props.journeyDesignator.formatDepartureDate()}/>

            <MiddleSectionCollapsedComponent journeyDesignator={props.journeyDesignator}
                                             onExpandClick={props.onExpandClick}/>

            <RightSectionComponent isShownInShoppingCart={props.isShownInShoppingCart}
                                   station={props.journeyDesignator.destination}
                                   formattedDepartureTime={props.journeyDesignator.formatArrivalTime()}
                                   formattedDepartureDate={props.journeyDesignator.formatArrivalDate()}/>
        </GridBox>

    );
})