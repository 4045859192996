import React, {useRef} from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {AirlineLogoLargeMulticolorIcon} from "../../../assets/svg/airline-logo-large-multicolor.icon";
import {useServices} from "../../../hooks/use-services.hook";
import {StandardPageHeaderComponentProps} from "./standard-page-header.component.props";
import {TestModeMenuComponent} from "../../test-mode/test-mode-menu.component";
import {LanguageSwitcherComponent} from "../../../components/language-switcher/language-switcher.component";
import {StandardPageHeaderBox} from "./standard-page-header-box";
import {usePageHeader} from "../../../services/page-header/use-page-header.hook";
import {useScreenMediaQueriesChecks} from "../../../hooks/use-screen-media-queries-checks.hook";

const HeaderContainerBox = styled(StandardPageHeaderBox)`
    box-shadow: ${props => props.theme.boxShadow.shadow2};
`

const TopSectionBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    width: 100%;
`

const TopLeftSectionBox = styled.div`
`

const TopMiddleSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex-grow: 1;
    font-size: ${props => props.theme.fontSize.h6};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

const TopRightSectionBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${props => props.theme.spacing.flexGap8};
`

const LogoBox = styled.div`
    cursor: pointer;
`

const BottomSectionBox = styled.div`
    width: 100%;
`


export const StandardPageHeaderLargeScreenComponent: React.FC<StandardPageHeaderComponentProps> = observer((props) => {
    const services = useServices();
    const mediaQuery = useScreenMediaQueriesChecks();
    const containerRef = useRef<HTMLIonHeaderElement | null>(null);
    usePageHeader(containerRef);


    const onLogoButtonClick = async () => {
        if(props.onLogoButtonClick) {
            props.onLogoButtonClick();
        } else {
            await services.navigator.goHome();
        }
    }

    const renderTopMiddle = () => {
        if(props.renderTopMiddleSection) {
            return props.renderTopMiddleSection();
        }

        return null;
    }

    const hasChildren = React.Children.count(props.children) > 0;

    return (
        <HeaderContainerBox ref={containerRef} $addBottomPadding={Boolean(props.addBottomPadding) || !hasChildren}>
            <TopSectionBox>
                <TopLeftSectionBox>
                    <LogoBox onClick={onLogoButtonClick}>
                        <AirlineLogoLargeMulticolorIcon size={mediaQuery.smallScreen ? 2 : 2.5}/>
                    </LogoBox>
                </TopLeftSectionBox>

                <TopMiddleSectionBox>
                    {renderTopMiddle()}
                </TopMiddleSectionBox>

                <TopRightSectionBox>
                    <LanguageSwitcherComponent/>
                    <TestModeMenuComponent/>
                </TopRightSectionBox>

            </TopSectionBox>

            <BottomSectionBox>
                {props.children}
            </BottomSectionBox>

        </HeaderContainerBox>
    )
});
