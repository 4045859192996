import React, {useRef} from 'react';
import {StandardDialogPageComponent} from "../../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../../components/modal/header/standard-dialog-header.component";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import {StandardDialogContentComponent} from "../../../../components/modal/content/standard-dialog-content.component";
import {useServices} from "../../../../hooks/use-services.hook";
import {NullableString} from "../../../../types/nullable-types";
import {FormModel} from "../../../../models/forms/form.model";
import {FormFields} from "../../../../models/forms/form-field.interface";
import {PasswordFieldEditorComponent} from "../../../../components/forms/password/password-field-editor.component";
import {StandardDialogFooterComponent} from "../../../../components/modal/footer/standard-dialog-footer.component";
import {PrimaryButton} from "../../../../basic-components/buttons/primary-button";
import { menuController } from '@ionic/core/components';
import {HYBRID_HOME_PAGE_SIDE_MENU_ID} from "../hybrid-home-page-constants";

export interface ITestModePasswordForm {
    readonly password:NullableString;
}

export class TestModePasswordForm extends FormModel<ITestModePasswordForm> {
    protected _createFields(): FormFields<ITestModePasswordForm> {
        return {
            password: this._createField({
                fieldName: () => this.services.language.translate('Password'),
            }),
        }
    }
}


export const HybridTestModeToggleDialogComponent: React.FC<{dialogHandler: IDialogConfirmationHandler}> = (props) => {
    const services = useServices();
    const formRef = useRef(new TestModePasswordForm(services));
    const form = formRef.current;

    const onEnableTestModeClick = async () => {
        if(form.fields.password.value) {
            services.configuration.enableHybridTestMode(form.fields.password.value);
            if(services.configuration.isHybridTestModeEnabled) {
                await menuController.close(HYBRID_HOME_PAGE_SIDE_MENU_ID);
                props.dialogHandler.accept();
                await services.dialogFactory.showSimpleMessageDialog({
                    message: "Test mode enabled",
                    buttonText: "OK"
                })
            }
        }
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler} title={"Enable test mode"}/>
            <StandardDialogContentComponent dialogHandler={props.dialogHandler}>
                <PasswordFieldEditorComponent field={form.fields.password}/>
            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <PrimaryButton onClick={onEnableTestModeClick} fullWidth={true}>
                    Enable test mode
                </PrimaryButton>
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    )
}