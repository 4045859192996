import {PaymentMethodGroupModel} from "./payment-method-group.model";
import React from "react";
import {IServiceFactory} from "../../../../service-factory.interface";
import {PaypalPaymentMethodTabHeaderComponent} from "../../../../../pages/booking-flow/payment/paypal/paypal-payment-method-tab-header.component";
import {PaypalPaymentMethodTabContentComponent} from "../../../../../pages/booking-flow/payment/paypal/paypal-payment-method-tab-content.component";
import {PaypalButtonComponent} from "../../../../../pages/booking-flow/payment/paypal/paypal-button.component";
import {PaymentMethodsGroupTypeEnum} from "./payment-method-group-type.enum";

export class PaypalMethodGroupModel extends PaymentMethodGroupModel {

    constructor(services: IServiceFactory) {
        super(PaymentMethodsGroupTypeEnum.PayPal, services);
    }

    renderTabHeader(): React.ReactElement {
        return (
            <PaypalPaymentMethodTabHeaderComponent/>
        );
    }

    renderTabContent(): React.ReactElement {
        return (
            <PaypalPaymentMethodTabContentComponent />
        )
    }

    renderPayButton(): React.ReactElement {
        return (
            <PaypalButtonComponent/>
        );
    }


}