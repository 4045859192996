import React from "react";
import { useServices } from "../../../../hooks/use-services.hook";
import { observer } from "mobx-react-lite";
import { IPaymentMethodViewModel } from "../../../../services/booking/models/payment/payment-methods/payment-method.view-model.interface";
import { CreditCardPaymentMethodComponent } from "./credit-card-payment-method.component";
import styled from "styled-components";
import {IPaymentHandlerViewModel} from "../../../../services/booking/models/payment/payment-handler-view-model.interface";

const ContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap24};
    width: 100%;
`

const PaymentMethodsContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: ${props => props.theme.spacing.spacing24};
    width: 100%;
    ${props => props.theme.containerMediaQuery.xsAndBelow} {
        gap: ${props => props.theme.spacing.spacing12};
    }
`

const FooterNoteBox = styled.div`
    width: 100%;
    text-align: center;
    font-size: ${props => props.theme.fontSize.breadcrumbResting};
    color: ${props => props.theme.colors.notesTint}
`


interface CreditCardPaymentMethodsTabContentComponentProps {
    paymentMethods: IPaymentMethodViewModel[],
    paymentHandler: IPaymentHandlerViewModel;
}
export const CreditCardPaymentMethodsTabContentComponent: React.FC<CreditCardPaymentMethodsTabContentComponentProps> = observer((props) => {

    const services = useServices();
    return (
        <ContainerBox>
            <PaymentMethodsContainerBox>

                {props.paymentMethods.filter(pm => !pm.hasCustomRenderer)
                                      .map(method => <CreditCardPaymentMethodComponent key={method.code} paymentMethod={method} />)}



            </PaymentMethodsContainerBox>
            <FooterNoteBox dangerouslySetInnerHTML={{
                __html: services.language.translate('Select a payment method and click <b>Pay Now</b> button to continue')
            }}/>

        </ContainerBox>

    )
});
