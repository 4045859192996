import React from 'react';
import {IServiceFactory} from "../../../../service-factory.interface";
import {SbagSsrSmallIcon} from "./sbag.ssr.small-icon";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../../ssrs-preferred-orders-in-shopping-cart";
import {CheckInBagsSsrBase} from "../check-in-bags-ssr-base";
import {SbagSsrLargeIcon} from "./sbag.ssr.large-icon";
import {
    ISsr3DIconProps,
    SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE,
    SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE
} from "../../../ssr-types.service.interface";

export default class SbagSsrType extends CheckInBagsSsrBase {
    constructor(services: IServiceFactory) {
        super('SBAG', services);
    }

    get isAvailable(): boolean {
        return true;
    }

    get bagWeightKg(): number {
        return 23;
    }
    get smallIcon() {
        return (<SbagSsrSmallIcon />);
    }

    get largeIcon() {
        return (<SbagSsrLargeIcon/>);
    }

    get iconInExtrasSmallTile(): React.ReactElement | null {
        return (<SbagSsrSmallIcon size={SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE} />);
    }

    get iconInExtrasFirstLargeTile(): React.ReactElement | null {
        return (<SbagSsrSmallIcon size={SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE} />);
    }

    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.SBAG;
    }

    get3DIcon(): ISsr3DIconProps | null {
        return {
            width: 160,
            height: 176,
            imageUrl: this.services.theme.currentTheme.assets.images.bags.bag_23kg_png.toString()
        };
    }

}
