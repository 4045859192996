import React from 'react';
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {PlaneHorizontalFilledIcon} from "../../../../assets/svg/plane-horizontal-filled.icon";

const PlaneIconBox = styled.div`
    margin: 0 ${props => props.theme.spacing.spacing8};
`

interface DesignatorPlaneIconComponentProps {
    className?: string;
}

export const DesignatorPlaneIconComponent: React.FC<DesignatorPlaneIconComponentProps> = observer((props) => {
    return (
        <PlaneIconBox className={props.className}>
            <PlaneHorizontalFilledIcon />
        </PlaneIconBox>
    );
});
