import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../hooks/use-services.hook";
import {CmsPageKey, CmsPageUrlBuilderParams} from "../../services/cms/cms.service.interface";


const IframeBox = styled.iframe`
    width: 100%;
    min-height: 1500px;
    ${props => props.theme.containerMediaQuery.xsAndBelow} {
        min-height: 3200px;
    }
    border: none;
`

/*<iframe sandbox="allow-scripts allow-top-navigation allow-pointer-lock allow-same-origin allow-popups" onload="$(this).contents().find('a').on('click', function(e) {e.preventDefault(); var url = e.target.href; window.open(url, '_system', 'location=yes'); });"></iframe>*/

interface CmsPageComponentProps {
    useUrl?: string;
    useParams?: {
        pageKey: CmsPageKey;
        urlBuilderParams?: Record<CmsPageUrlBuilderParams, string>;
    },
    className?: string;


}
export const CmsPageComponent: React.FC<CmsPageComponentProps> = observer((props) => {
    const services = useServices();
    let url = props.useUrl;
    if(!url && props.useParams) {
        url = services.cms.getPageUrl(props.useParams.pageKey, props.useParams.urlBuilderParams);
    }

    if(!url) {
        services.logger.error('No parameters provided for CmsPageComponent');
        return null;
    }

    return (
        <IframeBox className={props.className} src={url}/>
    );
});
