import React from "react";
import {useServices} from "../../../../hooks/use-services.hook";
import {TabComponent} from "../../../../components/tabs/tab.component";
import {
    ISegmentSeatMapEditorViewModel
} from "../../../../services/booking/models/seat-maps/segment-seat-map-editor-view-model.interface";
import {observer} from "mobx-react";
import {DesignatorComponent, DesignatorDisplayMode} from "../../../../components/designator/designator.component";
import {TabWrapperComponent} from "../../../../components/tabs/tab-wrapper.component";
import {DEFAULT_ANIMATION_TIME} from "../../../../global-constants";
import styled from "styled-components";

const Tabs = styled(TabWrapperComponent)`
    width: unset;
    flex-grow: 1;
`

export const SeatMapsFlightsTabsLargeScreenComponent: React.FC = observer(() => {
    const services = useServices();
    const seatMapsEditor = services.booking.current.seatsMapsEditors;
    const seatMaps = seatMapsEditor.seatMaps;

    if(!seatMapsEditor.currentSeatMap) {
        return null;
    }

    const renderTabItem = (segmentSeatMap: ISegmentSeatMapEditorViewModel) => {
        return (
            <DesignatorComponent displayMode={DesignatorDisplayMode.stationsCodeOnly} designator={segmentSeatMap.segment.designator}/>
        )
    }

    const onClick = (segmentSeatMap: ISegmentSeatMapEditorViewModel) => {

        if(seatMapsEditor.currentSeatMap?.segmentKey === segmentSeatMap.segmentKey) {
            return;
        }

        const firstPassengerWithoutSeat = segmentSeatMap.segment.passengers.find(p => !p.hasSeat);
        const firstPassengerWithSeat = segmentSeatMap.segment.passengers.find(p => p.hasSeat);

        if(firstPassengerWithoutSeat) {
            seatMapsEditor.setCurrentPassengerSegment(firstPassengerWithoutSeat);
        } else {
            seatMapsEditor.setCurrentPassengerSegment(segmentSeatMap.segment.passengers[0]);
        }

        if(firstPassengerWithSeat?.assignedSeat) {
            //set timeout for the DOM to stabilize
            setTimeout(() => {
                if(firstPassengerWithSeat?.assignedSeat) {
                    firstPassengerWithSeat?.assignedSeat.scrollIntoView();
                }
            },  DEFAULT_ANIMATION_TIME + 300);

        }

    }

    const Tab = TabComponent<ISegmentSeatMapEditorViewModel>;
    return (
        <Tabs hideBottomBorder={true}>
            <Tab items={seatMaps}
                 selectedItem={seatMapsEditor.currentSeatMap}
                 renderItem={renderTabItem}
                 getItemKey={(sm) => sm.segmentKey}
                 onItemClick={onClick}/>
        </Tabs>

    )
})