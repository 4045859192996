import React from 'react';
import {IServiceFactory} from "../../../service-factory.interface";
import {LouSsrSmallIcon} from "./lou-ssr.small-icon";
import {SsrTypeBase} from "../../ssr-type-base";
import {
    SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE, SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE,
    SSR_LARGE_ICON_SIZE
} from "../../ssr-types.service.interface";


export default class LouSsrType extends SsrTypeBase {
    constructor(services: IServiceFactory) {
        super('LOU', services);
    }

    get smallIcon(): React.ReactElement | null {
        return (<LouSsrSmallIcon/>);
    }

    get largeIcon(): React.ReactElement | null {
        return (<LouSsrSmallIcon size={SSR_LARGE_ICON_SIZE}/>);
    }

    get iconInExtrasFirstLargeTile(): React.ReactElement | null {
        return (<LouSsrSmallIcon size={SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE}/>);
    }

    get iconInExtrasSmallTile(): React.ReactElement | null {
        return (<LouSsrSmallIcon size={SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE}/>);
    }
}
