import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../../../../hooks/use-services.hook";
import {PeopleIcon} from "../../../../../assets/svg/people.icon";
import {
    DiscountDiagonalNorthWestTagEmptyIcon
} from "../../../../../assets/svg/discount-diagonal-north-west-tag-empty.icon";

const ContainerBox = styled.div`
    display: grid;
    grid-template-columns: 21px 1fr;
    align-items: center;
    column-gap: ${props => props.theme.spacing.spacing8};
    row-gap: ${props => props.theme.spacing.spacing16};
    background-color: ${props => props.theme.colors.tertiaryVeryLight};
    color: ${props => props.theme.colors.tertiaryShade};
    font-size: ${props => props.theme.fontSize.h6};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    line-height: 1;
    padding: ${props => props.theme.spacing.spacing12};
    border-radius: ${props => props.theme.border.defaultRadius};
    width: 100%;
    ${props => props.theme.screenMediaQuery.smallScreen} {
        position: absolute;
        left: 50%;
        top: ${props => props.theme.spacing.spacing8};
        transform: translateX(-50%);
        z-index: 1;
        font-size: ${props => props.theme.fontSize.h6};
        width: calc(100% - 2 * ${props => props.theme.spacing.pageContentHorizontalPadding});
    }
    
`

export const SeatMapMarketingMessageComponent:React.FC<{className?: string}> = observer((props) => {
    const services = useServices();
    return (
        <ContainerBox className={props.className}>
            <DiscountDiagonalNorthWestTagEmptyIcon size={1.1}/>
            <div>{services.language.translate('Now is the cheapest time to book!')}</div>
            <PeopleIcon size={1}/>
            <div>{services.language.translate('Sit next to your travel partner')}</div>
        </ContainerBox>
    )
})