import {IServiceFactory} from "../../../service-factory.interface";
import {TimeSpan} from "../../../../types/time-span";
import {ISsrRawInAllBundlesConfiguration} from "./ssrs-raw-configuration.interface";
import {TimePeriod} from "../../../../types/time-period";
import {SsrConfigurationPeriod} from "./ssr-configuration-period";

const DEFAULT_MIN_TIME_TO_USE_BEFORE_FLIGHT = TimeSpan.fromHours(4);
const DEFAULT_MAX_TIME_TO_USE_BEFORE_FLIGHT = TimeSpan.fromHours(168);
export class SsrInAllBundlesConfiguration extends SsrConfigurationPeriod {

}

export class SsrInAllBundlesPeriod {
    constructor(startDate: string,
                endDate: string,
                bundles: Record<string, ISsrRawInAllBundlesConfiguration>,
                private readonly services: IServiceFactory) {
        this.period = new TimePeriod(this.services.time.parseIsoDate(startDate), this.services.time.parseIsoDate(endDate));
        Object.keys(bundles).forEach(bundleCode => {
            this.bundles[bundleCode] = new SsrInAllBundlesConfiguration(bundles[bundleCode].minHoursToUseBeforeFlight, bundles[bundleCode].maxHoursToUseBeforeFlight, services);
        });
    }

    private bundles: Record<string, SsrInAllBundlesConfiguration> = {};

    private readonly period: TimePeriod;
    containsDate(date: Date): boolean {
        return this.period.contains(date);
    }

    canBeUsed(flightDate: Date, bundleCode: string): boolean {
        let bundlePeriod = this.bundles[bundleCode];
        if(!bundlePeriod) {
            bundlePeriod = new SsrInAllBundlesConfiguration(DEFAULT_MIN_TIME_TO_USE_BEFORE_FLIGHT.totalHours, DEFAULT_MAX_TIME_TO_USE_BEFORE_FLIGHT.totalHours, this.services)
        }
        return Boolean(bundlePeriod.canBeUsed(flightDate));
    }

    getMinTimeToUseBeforeFlight(bundleCode: string): TimeSpan {
        return this.bundles[bundleCode]?.minTimeToUseBeforeFlight ?? DEFAULT_MIN_TIME_TO_USE_BEFORE_FLIGHT;
    }

    getMaxTimeToUseBeforeFlight(bundleCode: string): TimeSpan | null {
        return this.bundles[bundleCode]?.maxTimeToUseBeforeFlight ?? DEFAULT_MAX_TIME_TO_USE_BEFORE_FLIGHT;
    }
}
