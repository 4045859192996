import React from 'react';
import {observer} from "mobx-react-lite";
import {useServices} from "../../../hooks/use-services.hook";
import {InputContainerComponent} from "../../forms/input-container/input-container.component";
import styled from "styled-components";
import { SearchMaskPsoMarketUserOptionsInlineComponent } from './search-mask-pso-market-user-options-inline.component';


const OptionsContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${props => props.theme.spacing.flexGap8};
`


export const SearchMaskPsoMarketUserOptionsInputSmallScreenComponent: React.FC = observer(() => {
    const services = useServices();
    const searchController = services.flightSearch.searchController;

    if(!searchController.getPsoMarket()) {
        return null;
    }


    const renderInput = () => {
        return (
            <OptionsContainerBox>
                <SearchMaskPsoMarketUserOptionsInlineComponent searchController={searchController}/>
            </OptionsContainerBox>
        )
    }

    const errorMessage = searchController.fields.psoUserOption.error
        || searchController.fields.psoUserAgreement.error;

    return (
        <InputContainerComponent renderInput={renderInput} errorMessage={errorMessage}/>
    );
})
