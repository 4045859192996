import React from "react";
import {observer} from "mobx-react";
import {FormComponent} from "../forms/form.component";
import {StringFieldEditorComponent} from "../forms/string-field/string-field-editor.component";
import {FormFields} from "../../models/forms/form-field.interface";
import {ILoginFormFields} from "../../services/user/models/login/login-form-fields.interface";
import {useServices} from "../../hooks/use-services.hook";
import {PasswordFieldEditorComponent} from "../forms/password/password-field-editor.component";
import styled from "styled-components";
import {ForgotPasswordDialogComponent} from "./forgot-password-dialog.component";
import {ForgotPasswordInstructionDialogComponent} from "./forgot-password-instruction-dialog.component";
import {PrimaryButton} from "../../basic-components/buttons/primary-button";

const FormComponentSubtitleBox = styled.div`
    color: ${props => props.theme.colors.medium};
    font-size: ${props => props.theme.fontSize.body2};
    text-align: center;
    
    margin-bottom: 15px;
    letter-spacing: 1px;
`

const ForgotPasswordBox = styled.div`
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.h6};
    text-align: center;
    
    font-weight: ${props => props.theme.fontWeight.bold};
    letter-spacing: 1px;
`

const ContinueAsAGuestBox = styled.div`
    margin-top: 20px;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.h6};
    text-align: center;
    
    font-weight: ${props => props.theme.fontWeight.regular};
    letter-spacing: 1px;
    text-decoration: underline;
`


interface LoginFormComponentProps {
    loginFormFields: FormFields<ILoginFormFields>;
    onSubmit: () => Promise<void>;
    onContinueAsAGuest?: () => void;
}

export const LoginFormComponent: React.FC<LoginFormComponentProps> = observer((props) => {
    const services = useServices();
    const fields = props.loginFormFields;

    const submit = async () => {
        await props.onSubmit();
    }

    const onForgotPasswordClick = async () => {
        await services.dialog.showStandardDialog({
            render: dialogHandler => (<ForgotPasswordDialogComponent dialogHandler={dialogHandler}/>),
            onAccept: async () => {
                await services.dialog.showPopupDialog({
                    render: dialogHandler => (<ForgotPasswordInstructionDialogComponent dialogHandler={dialogHandler}/>),
                });
            }
        });
    }

    const renderContinueAsAGuest = () => {
        if(!props.onContinueAsAGuest) {
            return null;
        }

        return (
            <ContinueAsAGuestBox onClick={props.onContinueAsAGuest}>
                {services.language.translate('Continue as a guest')}
            </ContinueAsAGuestBox>
        );
    }

    return (
        <FormComponent>
            <FormComponentSubtitleBox>
                {services.language.translate('Speed up the booking process!')}
            </FormComponentSubtitleBox>
            <StringFieldEditorComponent field={fields.userName}/>
            <PasswordFieldEditorComponent field={fields.password}/>
            <PrimaryButton onClick={submit}>
                {services.language.translate('Login')}
            </PrimaryButton>

            <ForgotPasswordBox onClick={onForgotPasswordClick}>
                {services.language.translate('Forgot password?')}
            </ForgotPasswordBox>

            {renderContinueAsAGuest()}
        </FormComponent>
    )
});
