import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const ChildHandsUpCircleFilledIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.4} fill="none" {...props} viewBox="0 0 79 79" ratio={79 / 79}>
            <rect x="1.21875" y="1.21875" width="76.3861" height="76.5625" rx="38.193" stroke="#102930"
                  strokeWidth="2.4375"/>
            <path
                d="M44.2884 24.875C44.2884 23.5821 43.7747 22.3421 42.8605 21.4279C41.9463 20.5136 40.7063 20 39.4134 20C38.1204 20 36.8805 20.5136 35.9662 21.4279C35.052 22.3421 34.5384 23.5821 34.5384 24.875C34.5384 26.1679 35.052 27.4079 35.9662 28.3221C36.8805 29.2364 38.1204 29.75 39.4134 29.75C40.7063 29.75 41.9463 29.2364 42.8605 28.3221C43.7747 27.4079 44.2884 26.1679 44.2884 24.875ZM36.435 32.8959C34.6298 32.2561 33.0454 31.0449 31.9561 29.4072L30.4708 27.1754C29.7243 26.0557 28.2161 25.7586 27.0888 26.5051C25.9614 27.2516 25.6643 28.7674 26.4108 29.8871L27.9038 32.1113C29.2825 34.1756 31.1639 35.798 33.3196 36.8645V56.5625C33.3196 57.9107 34.4089 59 35.7571 59C37.1054 59 38.1946 57.9107 38.1946 56.5625V49.25H40.6321V56.5625C40.6321 57.9107 41.7214 59 43.0696 59C44.4179 59 45.5071 57.9107 45.5071 56.5625V36.8797C47.7237 35.798 49.6509 34.1223 51.0448 31.9895L52.4311 29.8643C53.1624 28.7369 52.8425 27.2287 51.7151 26.4898C50.5878 25.751 49.0796 26.0709 48.3407 27.2059L46.9544 29.3234C45.2938 31.8676 42.4679 33.4062 39.4286 33.4062C38.4688 33.4062 37.5319 33.2539 36.6407 32.9645C36.5722 32.9416 36.5036 32.9111 36.435 32.8959Z"
                fill={props.color? props.color: "currentColor"}/>
        </SvgIcon>
    )
}
