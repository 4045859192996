import React from "react";
import {observer} from "mobx-react";
import {StationDropDownComponent} from "./station-drop-down.component";
import {useServices} from "../../../../hooks/use-services.hook";
import {FieldEditorCommonProps} from "../../../forms/inputs-common.props";
import {FlightSearchDropDownIconComponent} from "../../common/flight-search-drop-down-icon.component";
import {PlaneDepartingLineNotFilledIcon} from "../../../../assets/svg/plane-departing-line-not-filled.icon";

interface DestinationStationDropDownComponentProps extends FieldEditorCommonProps{
    isOnSmallScreen: boolean;
}
export const DestinationStationDropDownComponent: React.FC<DestinationStationDropDownComponentProps> = observer((props) => {

    const services = useServices();


    const searchController = services.flightSearch.searchController;
    const getDestinationStations = () => {
        return searchController.availableReturnStations;
    }


    const renderDestinationNoStationMessage = () => {
        if(!searchController.departureOrigin) {
            return services.language.translate('Select departure first');
        }

        return services.language.translate('No destination available');
    }

    const renderIcon = () => {

        if(props.isOnSmallScreen) {
            return (<FlightSearchDropDownIconComponent isHidden={true} icon={<PlaneDepartingLineNotFilledIcon size={1}/>}/>);
        }

        return null;
    }

    return (
        <StationDropDownComponent  {...props}
                                   field={searchController.fields.departureDestination}
                                   renderLabel={() => services.language.translate('Destination')}
                                   renderIcon={renderIcon}
                                   getStations={getDestinationStations}
                                   placeholder={services.language.translate('Select destination')}
                                   noStationsMessage={renderDestinationNoStationMessage}/>
    )
})