import React, {CSSProperties, MutableRefObject, PropsWithChildren} from 'react';
import {observer} from "mobx-react-lite";
import {useServices} from "../../../../hooks/use-services.hook";
import {IPassengerSegmentBoardingPassViewModel} from "../../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {BoardingPassesChunkComponent} from "./boarding-passes-chunk.component";
import {useScreenMediaQueriesChecks} from "../../../../hooks/use-screen-media-queries-checks.hook";

interface BoardingPassesContainerComponentProps extends PropsWithChildren {
    bookingKey: string;
    containerRef?: MutableRefObject<HTMLDivElement | null>;
    boardingPasses: IPassengerSegmentBoardingPassViewModel[];
}

export const BoardingPassesContainerComponent: React.FC<BoardingPassesContainerComponentProps> = observer((props) => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();
    const isRenderedForPrinting = Boolean(props.containerRef);

    let chunkSize = 2;
    if(!isRenderedForPrinting && (props.boardingPasses.length === 1 || screenMediaQuery.xsAndBelow)) {
        chunkSize = 1;
    }

    const boardingPassesByPassengerKey = props.boardingPasses.groupByKey(bp => bp.passengerKey);
    const boardingPassesChunks = props.boardingPasses.splitToChunks(chunkSize);

    const renderBoardingPasses =  () => {
        let styles: CSSProperties = {};

        if(!isRenderedForPrinting) {
            styles = {
                display: 'flex',
                flexDirection: 'column',
                gap: services.theme.currentTheme.spacing.flexGap20,
            }
        }

        return (
            <div style={styles} ref={props.containerRef}>
                {boardingPassesChunks.map((chunk, index) => {
                    return (
                        <BoardingPassesChunkComponent key={index}
                                                      bookingKey={props.bookingKey}
                                                      chunkSize={chunkSize}
                                                      boardingPasses={chunk}
                                                      allBoardingPassesByPassengerKey={boardingPassesByPassengerKey}
                                                      hideExportButtons={isRenderedForPrinting}
                                                      pageBreak={index < boardingPassesChunks.length - 1}/>

                    )
                })}
            </div>
        )
    }

    if(isRenderedForPrinting) {
        return (
            <div style={{display: 'none'}}>
                {renderBoardingPasses()}
            </div>
        )
    } else {
        return renderBoardingPasses();
    }

})
