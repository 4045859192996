import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import styled from "styled-components";

const ExtrasSelectedServicesCountBox = styled.div`
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    
`

export const ExtrasSelectedServicesCountComponent: React.FC<{numberOfServices: number}> = observer((props) => {
    const services = useServices();

    const computeText = () => {
        if(props.numberOfServices === 1) {
            return services.language.translate('1 selection');
        } else {
            return services.language.translationFor('{x} selections').withParams({
                x: props.numberOfServices.toString()
            })
        }
    }

    return (
        <ExtrasSelectedServicesCountBox>
            {computeText()}
        </ExtrasSelectedServicesCountBox>
    )

});