import {Asset} from "./asset";
import {IAssetResolver} from "../asset-resolver.interface";
export function makeAssets(assetsResolver: IAssetResolver) {
	return {
		fonts: {

		},
		icons: {
			favicon_png: new Asset('icons/favicon.png', assetsResolver),
			icon_png: new Asset('icons/icon.png', assetsResolver),
		},
		images: {
			add_to_apple_wallet_png: new Asset('images/add-to-apple-wallet.png', assetsResolver),
			airplane_window_view_png: new Asset('images/airplane_window_view.png', assetsResolver),
			bags: {
				bag_23kg_png: new Asset('images/bags/bag-23kg.png', assetsResolver),
				bag_32kg_png: new Asset('images/bags/bag-32kg.png', assetsResolver),
				small_cabin_bag_big_png: new Asset('images/bags/small-cabin-bag-big.png', assetsResolver),
				small_cabin_bag_small_png: new Asset('images/bags/small-cabin-bag-small.png', assetsResolver),
				trolley_png: new Asset('images/bags/trolley.png', assetsResolver),
			},
			dangerous_goods_jpg: new Asset('images/dangerous_goods.jpg', assetsResolver),
			extras: {
				airport_checkin_2x_jpg: new Asset('images/extras/airport-checkin@2x.jpg', assetsResolver),
				airport_transfer_2x_jpg: new Asset('images/extras/airport-transfer@2x.jpg', assetsResolver),
				bags_2x_jpg: new Asset('images/extras/bags@2x.jpg', assetsResolver),
				business_lounge_2x_jpg: new Asset('images/extras/business-lounge@2x.jpg', assetsResolver),
				early_checkin_2x_jpg: new Asset('images/extras/early-checkin@2x.jpg', assetsResolver),
				fast_track_2x_jpg: new Asset('images/extras/fast-track@2x.jpg', assetsResolver),
				pets_2x_jpg: new Asset('images/extras/pets@2x.jpg', assetsResolver),
				seat_selection_2x_jpg: new Asset('images/extras/seat-selection@2x.jpg', assetsResolver),
				sporting_equipment_2x_jpg: new Asset('images/extras/sporting-equipment@2x.jpg', assetsResolver),
			},
			retrieve_booking_page_banner_mobile_png: new Asset('images/retrieve-booking-page-banner-mobile.png', assetsResolver),
			retrieve_booking_page_banner_pc_png: new Asset('images/retrieve-booking-page-banner-pc.png', assetsResolver),
			splash_png: new Asset('images/splash.png', assetsResolver),
			under_chair_bag_animation_gif: new Asset('images/under_chair_bag_animation.gif', assetsResolver),
		},
	}
}