import React from 'react';
import {observer} from "mobx-react";
import styled from "styled-components";
import {VerticalLineBox} from "./vertical-line.box";
import {PlaneGoComponent} from "../common/plane-go.component";
import {
    IFlightDesignatorViewModel
} from "../../../../services/booking/models/designator/flight-designator-view-model.interface";
import {FlightNumberComponent} from "../common/flight-number.component";
import {AdvancedDesignatorOperatedByComponent} from "../common/advanced-designator-operated-by.component";

const SectionBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
`


const PlaneAndFlightNumberBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    transform: translateX(calc(50% - 10px));
`


export const MiddleSectionExpandedComponent: React.FC<{designator: IFlightDesignatorViewModel}> = observer((props) => {
    return (
        <SectionBox>
            <VerticalLineBox/>
            <PlaneAndFlightNumberBox>
                <PlaneGoComponent designator={props.designator}/>
                <FlightNumberComponent designator={props.designator}/>
            </PlaneAndFlightNumberBox>
            <AdvancedDesignatorOperatedByComponent designator={props.designator}/>
            <VerticalLineBox/>
        </SectionBox>
    )
})