import React from "react";
import {observer} from "mobx-react-lite";
import {StandardDialogPageComponent} from "../modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../modal/footer/standard-dialog-footer.component";
import styled from "styled-components";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import {PrimaryButton} from "../../basic-components/buttons/primary-button";
import {ExclamationDiamondIcon} from "../../assets/svg/exclamation-diamond.icon";
import {useScreenMediaQueriesChecks} from "../../hooks/use-screen-media-queries-checks.hook";
import {useServices} from "../../hooks/use-services.hook";

const DialogTextBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${props => props.theme.spacing.spacing20};
    width: 100%;
    flex-grow: 1;
    color: ${props => props.theme.colors.dark};
    font-size: ${props => props.theme.fontSize.h3};
`


interface SimpleMessageDialogComponentProps {
    title?: string;
    message: string;
    dialogHandler: IDialogConfirmationHandler;
    buttonText: string;
}

export const SimpleMessageDialogComponent: React.FC<SimpleMessageDialogComponentProps> = observer((props) => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();
    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={props.title} dialogHandler={props.dialogHandler}/>
            <StandardDialogContentComponent dialogHandler={props.dialogHandler}>
                <DialogTextBox>
                    <ExclamationDiamondIcon size={screenMediaQuery.smallScreen ? 3 : 5.65} color={services.theme.currentTheme.colors.tertiaryTint}/>
                    {props.message}
                </DialogTextBox>
            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <PrimaryButton onClick={() => props.dialogHandler.accept()}>
                    {props.buttonText}
                </PrimaryButton>
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    );
});
