import React from "react";
import {observer} from "mobx-react";
import {NeutralButton} from "../../basic-components/buttons/neutral-button";

interface UpsellSuggestionRejectButtonComponentProps {
    label: string;
    onClick: () => void;
}
export const UpsellSuggestionRejectButtonComponent: React.FC<UpsellSuggestionRejectButtonComponentProps> = observer((props) => {
    return (
        <NeutralButton onClick={props.onClick} fullWidth={true}>
            {props.label}
        </NeutralButton>
    )
})