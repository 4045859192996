import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {FlightsDatesSelectorModel} from "../../../../services/flight-search/dates-selection/flights-dates-selector.model";
import {
    DepartureFlightSelectedDatePreviewComponent
} from "../selected-date-preview/departure-flight-selected-date-preview.component";
import {ReturnFlightSelectedDatePreviewComponent} from "../selected-date-preview/return-flight-selected-date-preview.component";


const FlightsDatesSelectionHeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: ${props => props.theme.spacing.spacing8};
`


interface FlightsDatesSelectionHeaderComponentProps {
    flightsDatesSelector: FlightsDatesSelectorModel;
}

export const FlightsDatesSelectionHeaderComponent: React.FC<FlightsDatesSelectionHeaderComponentProps> = observer((props) => {

    return (
        <FlightsDatesSelectionHeaderBox>
            <DepartureFlightSelectedDatePreviewComponent flightsDatesSelector={props.flightsDatesSelector}/>
            <ReturnFlightSelectedDatePreviewComponent flightsDatesSelector={props.flightsDatesSelector}/>
        </FlightsDatesSelectionHeaderBox>
    )
});
