import {IServiceFactory} from "../../../service-factory.interface";
import {TimeSpan} from "../../../../types/time-span";
import {IReactionDisposer, makeObservable, observable, reaction, runInAction} from "mobx";
import {IDialogConfirmationHandler} from "../../../dialog/dialog.service.interface";
import {IDotRezSessionTimerModel} from "./dot-rez-session-timer.interface";
import {DialogCloseButtonBehavior} from "../../../dialog/dialog-enums";
import {
    SessionCountDownDialogComponent
} from "../../../../components/session-timeout/session-count-down-dialog.component";
import React from "react";
import {NullableNumber} from "../../../../types/nullable-types";
import {Check} from "../../../../types/type-checking";
import {isDotRezSessionExpiredError} from "../dot-rez-exception";
import {IDotRezSessionCountDownTimerViewModel} from "./dot-rez-session-timer-view-model.interface";


const COUNT_DOWN_TIMER_VALUE = TimeSpan.fromMinutes(1);

enum TryRefreshTokenResultEnum {
    Success,
    Expired,
    Failed
}

interface DotRezBookingSessionTimerOptions {
    disableCountDownDialog?: boolean;
}

export class DotRezBookingSessionTimerModel implements IDotRezSessionTimerModel, IDotRezSessionCountDownTimerViewModel {
    constructor(private readonly token: string,
                private readonly services: IServiceFactory,
                private readonly options?: DotRezBookingSessionTimerOptions) {

        this._sessionExpirationDate = Date.now() + this._totalSessionTimeout.totalMilliseconds;

        makeObservable<this, '_countDownTick' | '_sessionExpirationDate'>(this, {
            _countDownTick: observable.ref,
            _sessionExpirationDate: observable.ref
        });

        this._startSessionTimer();

        this._reactions.push(reaction(
            () => this.services.navigator.currentRoute,
            () => {
                if (this.services.navigator.routes.home.isActive) {
                    this.dispose();
                }
            }));

        this._reactions.push(reaction(
            () => this.services.application.isActive,
            async (isActive) => {
                if (isActive && !this._isPaused) {
                    if(await this.isExpired()) {
                        this.setExpired(); //in order to show the dialog;
                    } else if(TryRefreshTokenResultEnum.Success !== await this._executeRefreshToken()) {
                        this.setExpired();
                    }

                }
            }));
    }


    private _reactions: IReactionDisposer[] = [];
    private _sessionTimerRef: any = null;
    private _countDownTimerRef: any = null;
    private _sessionExpirationDate: number;
    private _sessionExpiredDialog: IDialogConfirmationHandler | null = null;
    private _tryRefreshTokenPromise: Promise<TryRefreshTokenResultEnum> = Promise.resolve(TryRefreshTokenResultEnum.Success);

    private _countDownTick: NullableNumber = null;
    public get sessionTimeLeft(): TimeSpan | null {
        if(Check.isNullOrUndefined(this._countDownTick)) {
            return null;
        }

        return TimeSpan.fromMilliseconds(Math.max(0, Math.floor(this._sessionExpirationDate - this._countDownTick)));
    }

    private async _waitForRefreshToken(): Promise<void> {
        try {
            await this._tryRefreshTokenPromise;
        } catch (err) {
            this.services.logger.error('_waitForRefreshToken failed', err);
        }

    }

    extendSessionLifetime(): void {
        runInAction(() => {
            this._sessionExpirationDate = Date.now() + this._totalSessionTimeout.totalMilliseconds;
        });

        this._stopCountDownTimer();
        this._stopSessionTimer();
        this._startSessionTimer();
    }

    private _isPaused: boolean = false;
    pause(): void {
        if(this._isPaused) {
            return;
        }
        this._stopCountDownTimer();
        this._stopSessionTimer();
        this._isPaused = true;
    }
    async resume(): Promise<void> {
        if(!this._isPaused) {
            return;
        }

        try {
            const result = await this._tryRefreshToken();
            if(result !== TryRefreshTokenResultEnum.Success) {
                this.setExpired();
            }
        } finally {
            this._isPaused = false;
        }




    }

    async isExpired(): Promise<boolean> {
        if(this._isPaused) {
            return false;
        }
        
        await this._waitForRefreshToken();

        return this._sessionExpirationDate <= Date.now();
    }


    setExpired(): void {
        runInAction(() => {
            this._sessionExpirationDate = Date.now() - 1000;
        });

        this._stopSessionTimer();
        this._stopCountDownTimer();
        this._showSessionExpiredDialog();
    }

    private _isDisposed: boolean = false;

    dispose(): void {
        this._disposeReactions();
        this._stopCountDownTimer();
        this._stopSessionTimer();
        this._isDisposed = true;
    }

    private _disposeReactions(): void {
        this._reactions.forEach(r => r());
        this._reactions = [];
    }

    private _stopSessionTimer(): void {
        if(this._sessionTimerRef) {
            clearTimeout(this._sessionTimerRef);
            this._sessionTimerRef = null;
        }
    }

    private async _goHome(): Promise<void> {
        this.services.dialog.forceCloseAllDialogs();
        await this.services.navigator.goHome();
    }

    private async _disposeAndGoToHome(): Promise<void> {
        this.dispose();
        await this._goHome();
    }

    private get _totalSessionTimeout(): TimeSpan {
        try {
            return TimeSpan.parse(this.services.configuration.data.dotRezSessionTimeout);
        } catch (err) {
            this.services.logger.error('Failed to parse dotRezSessionTimeout', err);
            return TimeSpan.fromMinutes(15);
        }
    }


    private _showSessionExpiredDialog(): void {
        if(this.options?.disableCountDownDialog) {
            return;
        }
        
        runInAction(() => {
            this._countDownTick = Date.now();
        });

        if(this._sessionExpiredDialog || this._isDisposed) {
            return;
        }

        const onButtonClick = async () => {
            if(await this.isExpired()) {
                await this._disposeAndGoToHome();
            } else {
                const result = await this._executeRefreshToken();

                if(result !== TryRefreshTokenResultEnum.Success) {
                    if(result === TryRefreshTokenResultEnum.Expired) {
                        this.services.alert.showError(this.services.language.translate('Your session has expired'));
                    } else {
                        this.services.alert.showError(this.services.language.translate('There was an error trying to extend your session'));
                    }
                    await this._disposeAndGoToHome();
                }
            }
        }

        const onCloseDialog = async () => {
            this._sessionExpiredDialog = null;
        }

        this.services.dialog.showPopupDialog({
            closeButtonBehavior: DialogCloseButtonBehavior.None,
            width: '420px',
            render: dialogHandler => {
                this._sessionExpiredDialog = dialogHandler;
                return (<SessionCountDownDialogComponent countDownTimer={this}
                                                         dialogHandler={dialogHandler}
                                                         onButtonClick={onButtonClick}/>);
            },
            onAccept: onCloseDialog,
            onReject: onCloseDialog
        });
    }

    private _stopCountDownTimer(): void {
        if(this._countDownTimerRef) {
            clearInterval(this._countDownTimerRef);
            this._countDownTimerRef = null;
        }
    }

    private _startCountDownTimer(): void {
        this._countDownTimerRef = setInterval(() => {
            runInAction(() => {
                this._countDownTick = Date.now();
            });
        }, 1000);
    }

    private _startSessionTimer(): void {
        const timeUntilExpiration = this._sessionExpirationDate - Date.now();
        if(timeUntilExpiration <= COUNT_DOWN_TIMER_VALUE.totalMilliseconds) {
            this._showSessionExpiredDialog();
        } else {
            this._sessionTimerRef = setTimeout(async () => {
                this._startCountDownTimer();
                this._showSessionExpiredDialog();
            }, timeUntilExpiration - COUNT_DOWN_TIMER_VALUE.totalMilliseconds);
        }
    }

    private _executeRefreshToken(): Promise<TryRefreshTokenResultEnum> {
        this._tryRefreshTokenPromise = this._tryRefreshToken();
        return this._tryRefreshTokenPromise;
    }

    private async _tryRefreshToken(): Promise<TryRefreshTokenResultEnum> {
        try {
            await this.services.dotRezApi.tryRefreshToken(this.token);
            this.extendSessionLifetime();
            return TryRefreshTokenResultEnum.Success;
        } catch (err) {

            const isSessionExpired = isDotRezSessionExpiredError(err);
            if(isSessionExpired) {
                return TryRefreshTokenResultEnum.Expired;
            }

            if(!isSessionExpired) {
                this.services.logger.error('Failed to refresh dotREZ token', err);
            }

            return TryRefreshTokenResultEnum.Failed;
        }
    }
}

