import {
    FieldInputContainerComponentProps
} from "../inputs-common.props";
import styled, {css} from "styled-components";
import {FieldInputContainerComponent} from "../field-input-container.component";
import PhoneInput from "react-phone-input-2";
import React from "react";
import {PhoneNumberInputComponentProps} from "./phone-number-input.component.props";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";


const PHONE_CONTAINER_ELEMENT_CSS_CLASS = 'react-phone-container-element';
const PHONE_INPUT_ELEMENT_CSS_CLASS = 'react-phone-input-element';
const PHONE_BUTTON_ELEMENT_CSS_CLASS = 'react-phone-button-element';


const ReactPhoneInputComponent = styled(PhoneInput)<{$hasError: boolean}>`


    width: 100%;
    margin-top: 2px;
    & .${PHONE_CONTAINER_ELEMENT_CSS_CLASS} {
        width: 100%;
        border-radius: ${props => props.theme.border.defaultRadius};
        border: none
    }

    & .${PHONE_INPUT_ELEMENT_CSS_CLASS} {

        width: 100%;
        height: 100%;
        border-top-right-radius: ${props => props.theme.border.defaultRadius};
        border-bottom-right-radius: ${props => props.theme.border.defaultRadius};
        padding-top: ${props => props.theme.spacing.spacing12};
        padding-bottom: ${props => props.theme.spacing.spacing12};
        border: none
        ${props => props.$hasError
                ? css`
                    color: ${props => props.theme.colors.danger};
                    border-color: ${props => props.theme.colors.danger};
                `
                : css`
                    border-color: ${props => props.theme.border.mainColor};
                `
        }

        ${props => props.disabled && css`
            background-color: ${props => props.theme.colors.light};
        `}
    }

    & .${PHONE_BUTTON_ELEMENT_CSS_CLASS} {
        border-top-left-radius: ${props => props.theme.border.defaultRadius};
        border-bottom-left-radius: ${props => props.theme.border.defaultRadius};
        border: none;
        ${props => props.$hasError
                ? css`
                    color: ${props => props.theme.colors.danger};
                    //border-color: ${props => props.theme.colors.danger};
                `
                : css`
                    //border-color: ${props => props.theme.border.mainColor};
                `
        }
    }
`



export const PhoneNumberInputInsideBordersComponent: React.FC<PhoneNumberInputComponentProps> = observer((props) => {
    const services = useServices();
    const onChange = (value: string) => {
        props.field.setValue(value);
    }

    const renderInput = () => {
        return (
            <ReactPhoneInputComponent country={services.configuration.defaultCountryCode.toLowerCase()}
                                      $hasError={props.field.hasError}
                                      value={props.field.value}
                                      onChange={onChange}
                                      enableSearch={true}
                                      disabled={props.isReadOnly}
                                      containerClass={PHONE_CONTAINER_ELEMENT_CSS_CLASS}
                                      inputClass={PHONE_INPUT_ELEMENT_CSS_CLASS}
                                      buttonClass={PHONE_BUTTON_ELEMENT_CSS_CLASS}/>
        );
    }


    const fieldContainerProps: FieldInputContainerComponentProps = {
        ...props,
        renderInput: renderInput
    }

    return (
        <FieldInputContainerComponent {...fieldContainerProps} />
    )

})