export enum PsoUserOptionsEnum {
    None,
    Resident,
    Worker
}

export enum PsoUserAgreementEnum {
    None,
    Agree,
    DontAgree
}