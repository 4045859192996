import React from "react";
import {observer} from "mobx-react-lite";
import {useServices} from "../../../../hooks/use-services.hook";
import {BookingFlowSmallScreenPage} from "../../../booking-flow/screen-small/booking-flow.small-screen.page";
import {JourneySelectorComponent} from "../../common/components/journey-selector.component";
import {IJourneySnapshotViewModel} from "../../../../services/booking/models/snapshots/journey/journey-snapshot-view-model.interface";
import styled from "styled-components";

const FlightsSelectionPageHeaderTextBox = styled.div`
    font-size: ${props => props.theme.fontSize.h2};
    font-weight: ${props => props.theme.fontWeight.bold};
    text-align: center;
`


const JourneyToRefundSelectorComponent: React.FC<{journey: IJourneySnapshotViewModel}> = observer((props) => {
    const services = useServices();

    const onSelectHandler = () => {
        props.journey.isSelectedForRefund = !props.journey.isSelectedForRefund;
    }

    return (
        <JourneySelectorComponent designator={props.journey.designator}
                                  canBeUnselected={!props.journey.isCanceled}
                                  onSelect={onSelectHandler}
                                  isSelected={() => props.journey.isSelectedForRefund}
                                  isJourneyCanceled={props.journey.isCanceled}
                                  changeBlockingReason={props.journey.refundBlockingReason}
                                  changeBlockingReasonTitle={services.language.translate('Not eligible for refund')}>

        </JourneySelectorComponent>
    )
});

const RefundSummaryComponent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;
    if(!booking.manageMyBooking.hasJourneysThatAllowRefund) {
        return null;
    }
    return (
        <div>
            {booking.manageMyBooking.totalToRefund.toString()}
        </div>
    )
})

const ManageMyBookingFlightsToRefundSelectionPageContent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;
    return (
        <>
            <FlightsSelectionPageHeaderTextBox>
                {services.language.translate('Select the flight(s) that you want to be refunded into your wallet')}
            </FlightsSelectionPageHeaderTextBox>
            {
                booking.manageMyBooking.availableJourneysForRefund.map(j => <JourneyToRefundSelectorComponent key={j.journeyKey} journey={j}/>)
            }

            <RefundSummaryComponent/>

        </>
    );
})


export const ManageMyBookingFlightsToRefundSelectionPage: React.FC = observer(() => {
    const services = useServices();

    return (
        <BookingFlowSmallScreenPage headerOptions={{
                                        title:services.language.translate('Refund to wallet')
                                    }}
                                    footerOptions={{
                                        hideFooter: !services.booking.currentPageCanBeRendered || !services.booking.current.manageMyBooking.hasJourneysThatAllowRefund
                                    }}
                                    shoppingCartOptions={{
                                        showPromotionCode: false,
                                        hideShoppingCart: true
                                    }}
                                    contentOptions={{
                                        renderContent: () => <ManageMyBookingFlightsToRefundSelectionPageContent/>
                                    }}
        />
    );
})
