import React, {useRef} from "react";
import {observer} from "mobx-react";
import {
    IPassengerSegmentBoardingPassViewModel
} from "../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {useServices} from "../../hooks/use-services.hook";
import {
    AddToWalletBoardingPassesSelectionDialogComponent
} from "./selection-dialog/add-to-wallet-boarding-passes-selection-dialog.component";
import {BoardingPassesToWalletSelector} from "./selection-dialog/models/boarding-passes-to-wallet-selector";
import {DialogCloseButtonBehavior} from "../../services/dialog/dialog-enums";
import {AddBoardingPassesToWalletButtonComponent} from "./add-boarding-passes-to-wallet-button.component";


interface AddAllBoardingPassesToWalletButtonComponentProps {
    bookingKey: string;
    boardingPasses: IPassengerSegmentBoardingPassViewModel[];
}

export const AddAllBoardingPassesToWalletButtonComponent: React.FC<AddAllBoardingPassesToWalletButtonComponentProps> = observer((props) => {
    const services = useServices();
    const eligibleBoardingPasses = services.mobileWallet.filterEligiblePasses(props.boardingPasses);
    const selectorRef = useRef(new BoardingPassesToWalletSelector(services, eligibleBoardingPasses, 10, services.mobileWallet.providerName ));

    if(eligibleBoardingPasses.length === 0) {
        return null;
    }

    const renderGroupAddToWalletButton = (selectedBoardingPasses: IPassengerSegmentBoardingPassViewModel[]) => {
        return (
            <AddBoardingPassesToWalletButtonComponent bookingKey={props.bookingKey} boardingPasses={selectedBoardingPasses}/>
        );
    }

    const onClickHandler = async () => {
        const isOnlyOnePassenger = 1 === eligibleBoardingPasses.distinct(bp => bp.passengerKey, bp => bp.passengerKey).length
        if(isOnlyOnePassenger) {
            await services.mobileWallet.addBoardingPassesToWallet(props.bookingKey, eligibleBoardingPasses);
        } else {
            await services.dialog.showStandardDialog({
                closeButtonBehavior: DialogCloseButtonBehavior.Accept,
                render: dialogHandler => (<AddToWalletBoardingPassesSelectionDialogComponent
                                                                                             dialogHandler={dialogHandler}
                                                                                             title={services.language.translationFor('Add to {walletProvider}').withParams({walletProvider: services.mobileWallet.providerName})}
                                                                                             selector={selectorRef.current}
                                                                                             renderAddToWalletButton={renderGroupAddToWalletButton}/>)

            });
        }

    }

    return services.mobileWallet.renderAddToWalletButton(onClickHandler);
})