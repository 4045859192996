import React from 'react';
import {observer} from "mobx-react-lite";
import {useServices} from "../../../../hooks/use-services.hook";
import {BoardingPassExportButtonsComponent} from "../export-buttons/boarding-pass-export-buttons.component";
import {IPassengerSegmentBoardingPassViewModel} from "../../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {AirlineCompanyName} from "../../../../global-constants";

interface BoardingPassExportAllButtonComponentProps {
    bookingKey: string;
    boardingPasses: IPassengerSegmentBoardingPassViewModel[];
}


export const BoardingPassExportAllButtonComponent: React.FC<BoardingPassExportAllButtonComponentProps> = observer((props) => {
    const services = useServices();
    const boardingPasses = props.boardingPasses;

    if(boardingPasses.length <= 1) {
        return null;
    }

    const origin = props.boardingPasses[0].designator.origin.code;
    const destination = props.boardingPasses[0].designator.destination.code;
    const departureDate = services.time.formatYYYMMDD(props.boardingPasses[0].designator.departureDate);
    const fileName = `${AirlineCompanyName}_Pass_${origin}_${destination}_${departureDate}.pdf`;

    return (
        <BoardingPassExportButtonsComponent bookingKey={props.bookingKey}
                                            boardingPasses={boardingPasses}
                                            fileName={fileName}
                                            emailSubject={services.language.translate('Boarding pass')}
                                            emailBody={services.language.translationFor('Boarding pass for flight {origin} - {destination}').withParams({
                                                origin: origin,
                                                destination: destination
                                            })}/>
    )
})
