import styled from "styled-components";

export const SwapStationsChevronIconWrapperBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const SwapStationsButtonBaseBox = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    font-size: 13px;
    background-color: ${props => props.theme.colors.cardBackground};
    border: none;
    box-shadow: ${props => props.theme.boxShadow.shadow2};
    border-radius: 8px;
    padding: 5px 7px;
    cursor: pointer;
`