import React, {PropsWithChildren, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import styled, {css, keyframes} from "styled-components";
import {reaction} from "mobx";

const swipeAnimationForward = keyframes`
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
 
`

const swipeAnimationBackward = keyframes`
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
`

function getAnimation(forward: boolean) {
    if(forward) {
        return swipeAnimationForward;
    } else {
        return swipeAnimationBackward;
    }
}


const AnimationContainerBox = styled.div<{$animated: boolean; $forward: boolean}>`
    ${
        props => props.$animated
            ? css`
                    animation: ${getAnimation(props.$forward)} 0.3s ease-out forwards;
            `
            :``
    }
`

interface SeatMapAnimationComponentProps extends PropsWithChildren {

}
export const SeatMapAnimationComponent: React.FC<SeatMapAnimationComponentProps> = observer((props) => {
    const services = useServices();
    const [animated, setAnimated] = useState(false);
    const seatMaps = services.booking.current.seatsMapsEditors;

    let forward = true;
    if(seatMaps.currentSeatMapIndex < seatMaps.previousSeatMapIndex) {
        forward = false;
    }

    useEffect(() => {
        const r = reaction(() => seatMaps.currentSeatMap, () => {
            setAnimated(true);
        });

        return () => r();

    }, [seatMaps.currentSeatMap]);

    return (

            <AnimationContainerBox $animated={animated} $forward={forward} onAnimationEnd={() => setAnimated(false)}>
                {props.children}
            </AnimationContainerBox>


    )
})