import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {handleBookingFlowLogoClick} from "../booking-flow-logo-click-handler";
import styled from "styled-components";
import {
    WizardProgressIndicatorComponent
} from "../../../components/wizard-progress-indicator/wizard-progress-indicator.component";
import {
    StandardPageHeaderSmallScreenComponent
} from "../../../basic-components/page/standard-page-header/standard-page-header.small-screen.component";

const TopMiddleSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap8};
`


interface BookingFlowPageHeaderSmallScreenComponentProps extends PropsWithChildren {
    title: string | React.ReactElement | null;
    hideWizardProgressIndicator?: boolean;
    onBackButtonClick?: () => void;
    dontAsForConfirmationOnLogoClick?: boolean;
    useLargeLogoOnSmallScreen?: boolean;
}

export const BookingFlowPageHeaderSmallScreenComponent: React.FC<BookingFlowPageHeaderSmallScreenComponentProps> = observer((props) => {
    const services = useServices();
    if(!services.booking.currentPageCanBeRendered) {
        return null;
    }

    const onLogoClick = async () => {
        await handleBookingFlowLogoClick(services, props.dontAsForConfirmationOnLogoClick);
    }

    const renderTopMiddleSection = () => {
        return (
            <TopMiddleSectionBox>
                <div>
                    {props.title}
                </div>
                {!props.hideWizardProgressIndicator && <WizardProgressIndicatorComponent getWizard={() => services.booking.steps} />}
            </TopMiddleSectionBox>
        )
    }

    return (
        <StandardPageHeaderSmallScreenComponent renderTopMiddleSection={renderTopMiddleSection}
                                                hideLogo={false}
                                                onBackButtonClick={props.onBackButtonClick}
                                                onLogoButtonClick={onLogoClick}
                                                addBottomPadding={!props.hideWizardProgressIndicator}
                                                useLargeLogoOnSmallScreen={props.useLargeLogoOnSmallScreen}>
            {props.children}
        </StandardPageHeaderSmallScreenComponent>
    )
});
