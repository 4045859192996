import React from 'react';
import {ExtrasSectionRendererBase} from "../../base-renderers/extras-section-renderer.base";
import {ExtrasSeatsLargeTileComponent} from "./large-tile/extras-seats.large-tile.component";
import {ExtrasSeatsSmallTileComponent} from "./small-tile/extras-seats.small-tile.component";
import { ISsrType } from '../../../../../services/ssr-types/ssr-types.service.interface';
import {IRenderLargeTileOptions} from "../../base-renderers/extras-section-renderer.interface";
import {Check} from "../../../../../types/type-checking";
import {SeatSelectionDialogComponent} from "../../../seat-selection/dialogs/seat-selection-dialog.component";
import {NullableString} from "../../../../../types/nullable-types";

export class SeatsExtrasSectionRenderer extends ExtrasSectionRendererBase {

    getTitle(): string {
        return this.services.language.translate('Seats');
    }

    get ssrTypes(): ISsrType[] {
        return [];
    }

    shouldRender(): boolean {
        return true;
    }

    get canBeModified(): boolean {
        return true;
    }

    get isFulfilled(): boolean {
        return this.booking.getAllPassengersSegments().all(ps => ps.hasSeat);
    }

    private _countSelectedSeats(): number {
        return this.services.booking.current.getAllPassengersSegments().filter(s => s.hasSeat).length
    }

    private async _showEditDialog(): Promise<void> {
        const firstPassengerSegmentWithoutSeat = this.booking.getAllPassengersSegments().findFirst(s => Check.isNullOrUndefined(s.assignedSeat));

        if(firstPassengerSegmentWithoutSeat){
            this.booking.seatsMapsEditors.setCurrentPassengerSegment(firstPassengerSegmentWithoutSeat);
        }

        await this.services.dialog.showStandardDialog({
            forceFullScreenOnLargeScreens: true,
            render: (dialogHandler) => (<SeatSelectionDialogComponent dialogHandler={dialogHandler} />)
        });
    }

    private _getMarketingMessage(): string {
        return this.services.language.translate('Are you traveling together? Fear of getting a middle seat? Book now for less and enjoy your trip more!');
    }

    renderLargeTile(options: IRenderLargeTileOptions): React.ReactElement {
        return (
            <ExtrasSeatsLargeTileComponent isFirstTile={options.isFirstTile}
                                           countSelectedSeats={() => this._countSelectedSeats()}
                                           onEditButtonClick={() => this._showEditDialog()}
                                           getMarketingMessage={() => this._getMarketingMessage()}
                                           imageUrl={this._getExtrasLargeTilePicture()}/>
        )
    }

    renderSmallTile(): React.ReactElement {
        return (
            <ExtrasSeatsSmallTileComponent countSelectedSeats={() => this._countSelectedSeats()}
                                           onEditButtonClick={() => this._showEditDialog()}/>
        )
    }

    protected _getExtrasLargeTilePicture(): NullableString {
        return this.services.theme.currentTheme.assets.images.extras.seat_selection_2x_jpg.toString();
    }

}
