import {ICurrencyService} from "./currency.service.interface";
import {action, makeObservable, observable, reaction} from "mobx";
import {Price} from "./price";
import {ServiceBase} from "../service-base";
import {IServiceFactory} from "../service-factory.interface";
import {Check} from "../../types/type-checking";
import {NonEnvLocalStorageKeys} from "../storage/local-storage-keys";

interface IExchangeRate {
    fromCurrency: string;
    toCurrency: string;
    exchangeRate: number;
    isUpdateInProgress: boolean;
}

export class CurrencyService extends ServiceBase implements ICurrencyService {
    constructor(services: IServiceFactory) {
        super(services)
        this.current = localStorage.getItem(NonEnvLocalStorageKeys.userProfileCurrency) || 'EUR';
        makeObservable(this, {
            current: observable.ref,
            setCurrentCurrency: action.bound,
            _exchangeRates: observable
        });

        reaction(() => this.services.application.isActive,
            async (isActive) => {
                if(isActive) {
                    for (let exchangeRate of Object.values(this._exchangeRates)) {
                        this._updateExchangeRate(exchangeRate.fromCurrency, exchangeRate.toCurrency);
                    }
                }
            });
    }

    _exchangeRates: Record<string,  IExchangeRate> = {};

    current: string;
    formatMoney(value: number): string {
       return this.formatMoneyForCurrency(value, this.current);
    }

    private _composeExchangeRateKey(fromCurrencyCode: string, toCurrencyCode: string): string {
        return `${fromCurrencyCode}_${toCurrencyCode}`;
    }

    convertAmount(fromCurrencyCode: string, toCurrencyCode: string, amount: number): Price {

        if(fromCurrencyCode === toCurrencyCode) {
            return this.createPrice(amount, toCurrencyCode);
        }

        const cachedExchangeRate = this._exchangeRates[this._composeExchangeRateKey(fromCurrencyCode, toCurrencyCode)];

        if(Check.isNullOrUndefined(cachedExchangeRate)) {
            this._updateExchangeRate(fromCurrencyCode, toCurrencyCode);
            return this.createPrice(amount, toCurrencyCode); //TODO - this is not really ok here
        }

        return this.createPrice(Math.round(amount * cachedExchangeRate.exchangeRate * 100)/100, toCurrencyCode);
    }

    private async _updateExchangeRate(fromCurrencyCode: string, toCurrencyCode: string): Promise<void> {

        const key = this._composeExchangeRateKey(fromCurrencyCode, toCurrencyCode);
        let exchangeRate = this._exchangeRates[key];

        if(exchangeRate) {
            if(exchangeRate.isUpdateInProgress) {
                return;
            }
        } else {
            exchangeRate = {
                fromCurrency: fromCurrencyCode,
                toCurrency: toCurrencyCode,
                exchangeRate: 1,
                isUpdateInProgress: true
            }

            this._exchangeRates[key] = exchangeRate;
        }
        const session = await this.services.user.getSession();

        const conversionResponse = await session.convertCurrency({
            fromCurrencyCode: fromCurrencyCode,
            toCurrencyCode: toCurrencyCode,
            amount: 1
        });

        this._exchangeRates[key] = {
            fromCurrency: fromCurrencyCode,
            toCurrency: toCurrencyCode,
            exchangeRate: conversionResponse.exchangeRate,
            isUpdateInProgress: false
        }
    }

    setCurrentCurrency(value:string) {
        this.current = value;
        localStorage.setItem(NonEnvLocalStorageKeys.userProfileCurrency, value);
    }

    formatNumber(value: number): string {
        return new Intl.NumberFormat(this.services.language.currentLanguage)
            .format(value)
            .replace('\u00A0', ' ') ;
    }

    formatMoneyForCurrency(value: number, currency: string): string {
        let formatOptions: Intl.NumberFormatOptions = {
            style: 'currency',
            currency: currency
        };

        switch (currency) {
            case 'USD':
            case 'EUR':
            case 'GBP':
                formatOptions.currencyDisplay = 'narrowSymbol';
                break;
        }

        let formattedMoney: string;
        try {
            formattedMoney = new Intl.NumberFormat(this.services.language.currentLanguage, formatOptions).format(value);
        } catch (err) {
            formattedMoney = new Intl.NumberFormat(this.services.language.currentLanguage, {
                ...formatOptions,
                currencyDisplay: 'symbol'
            }).format(value);
        }

        return formattedMoney.replace('\u00A0', ' ') ;
    }

    createPrice(amount: number, currency?: string): Price {
        return new Price(amount, currency || this.current, this);
    }

    getCurrencySymbol(currency: string): string {
        switch (currency) {
            case 'EUR':
                return "€";
            case 'USD':
                return "$"
            default:
                return currency;
        }
    }

}
