import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {PrimaryButton} from "../../../../basic-components/buttons/primary-button";

export const SaveBookingButtonComponent: React.FC = observer(() => {
    const services = useServices();

    const onPayButtonClick = async () => {
        await services.booking.steps.nextStep();
    }

    return  (
        <PrimaryButton onClick={onPayButtonClick} fullWidth={true}>
            {services.language.translate('Save Booking')}
        </PrimaryButton>
    );
})
