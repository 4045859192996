import React from "react";
import styled from "styled-components";
import {observer} from "mobx-react";

const FormTitleContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const FormTitleTextBox =  styled.div`
    
    font-size: ${props => props.theme.fontSize.h4};
    font-weight: ${props => props.theme.fontWeight.bold};
`

interface FormTitleComponentProps {
    title: string;
}
export const FormTitleComponent: React.FC<FormTitleComponentProps> = observer((props) => {
    return (
        <FormTitleContainerBox>
            <FormTitleTextBox>
                {props.title}
            </FormTitleTextBox>
        </FormTitleContainerBox>
    )
});
