import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import {ContainerMediaQueriesChecksContext} from "../../../react-contexts/container-media-queries-checks.context";
import styled, {ThemeProvider} from "styled-components";
import {CopyRightComponent} from "../../../components/airline-company-info/copy-right.component";
import {useServices} from "../../../hooks/use-services.hook";
import {useScreenMediaQueriesChecks} from "../../../hooks/use-screen-media-queries-checks.hook";
import {ContainerMediaQueryChecks} from "../../../services/media-query/container/container-media-query-checks";
import {BookingFlowPageWidthConstrainComponent} from "../booking-flow-page-width-constrain.component";
import {NullableUndefinedBoolean} from "../../../types/nullable-types";



const LeftSectionMediaChecksContainerBox = styled.div`
    container-type: inline-size;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100%;
    flex-grow: 1;
`

const LeftSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    flex-grow: 1;
    padding: 0 ${props => props.theme.spacing.pageContentHorizontalPadding} ${props => props.theme.spacing.pageContentVerticalPadding} ${props => props.theme.spacing.pageContentHorizontalPadding};
    gap: ${props => props.theme.spacing.pageContentVerticalPadding};
    ${props => props.theme.screenMediaQuery.lAndBelow} {
        padding: 0 ${props => props.theme.spacing.spacing16} ${props => props.theme.spacing.pageContentVerticalPadding} ${props => props.theme.spacing.spacing16};
    }
`

const LeftSectionHeaderBox = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.colors.lightTint};
    font-size: ${props => props.theme.fontSize.h1};
    font-weight: ${props => props.theme.fontWeight.bold};
    width: 100%;
`


const LeftSectionBodyBox = styled.div`
    flex-grow: 1;
`

const LeftSectionFooterBox = styled.div`
    padding-top: ${props => props.theme.spacing.spacing48};
`

interface BookingFlowLargeScreenPageLeftSectionComponentProps {
    title: string | React.ReactElement | null;
    renderContent: () => React.ReactElement;
    maxPageContentWidth?: string;
    hideCopyRight: NullableUndefinedBoolean;
}
export const BookingFlowLargeScreenPageLeftSectionComponent: React.FC<BookingFlowLargeScreenPageLeftSectionComponentProps> = observer((props) => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();
    const leftContainerMediaChecks = useRef(new ContainerMediaQueryChecks(screenMediaQuery));
    const leftSectionRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const leftMediaChecks = leftContainerMediaChecks.current;
        if (leftSectionRef.current) {
            leftMediaChecks.setElement(leftSectionRef.current);
        }

        return () => {
            leftMediaChecks?.dispose();
        }
    }, []);

    const renderSectionContent = () => {
        return (
            <LeftSectionMediaChecksContainerBox ref={leftSectionRef}>

                <ContainerMediaQueriesChecksContext.Provider value={leftContainerMediaChecks.current}>
                    <ThemeProvider theme={services.theme.applyContainerMediaQueries()}>
                        <LeftSectionBox>
                            <LeftSectionHeaderBox>
                                {props.title}
                            </LeftSectionHeaderBox>
                            <LeftSectionBodyBox>
                                {props.renderContent()}
                            </LeftSectionBodyBox>
                            <LeftSectionFooterBox>
                                {!props.hideCopyRight && <CopyRightComponent/>}
                            </LeftSectionFooterBox>
                        </LeftSectionBox>
                    </ThemeProvider>
                </ContainerMediaQueriesChecksContext.Provider>

            </LeftSectionMediaChecksContainerBox>
        )
    }

    if(props.maxPageContentWidth) {
        return (
            <BookingFlowPageWidthConstrainComponent maxPageContentWidth={props.maxPageContentWidth}>
                {renderSectionContent()}
            </BookingFlowPageWidthConstrainComponent>
        );
    }
    return renderSectionContent();
})