import React, {useState} from 'react';
import {Station} from "../../../services/stations/station.service.interface";
import {StationsSearchComponent} from "../../../components/stations-search/stations-search.component";
import {StationsListComponent} from "../../../components/stations-list/stations-list.component";
import {FlightSearchPage} from "../flight-search.page";
import {FlightSearchTitleComponent} from "../components/flight-search-title.component";
import {observer} from "mobx-react";

interface StationSelectionPageProps {
    titleText: string;
    plainIcon: React.ReactNode;
    stations: Station[];
    recentlySearchedStations: Station[];
    onStationSelected: (station: Station) => void;
}

export const FlightSearchStationSelectionPage: React.FC<StationSelectionPageProps> = observer((props) => {
    const [filteredStations, setFilteredStations] = useState<Station[]>(props.stations);
    const [recentlySearchStation, setRecentlySearchStation] = useState<Station[]>(props.recentlySearchedStations);

    const renderContent = () => {
        return (
            <StationsListComponent stations={filteredStations}
                                   onSelect={(station) => props.onStationSelected(station)}
                                   recentlySearchedStations={recentlySearchStation}/>
        );
    }

    const onSearch = (stations: Station[]) => {
        setFilteredStations(stations);
        if (stations.length !== props.stations.length) {
            setRecentlySearchStation([]);
        } else {
            setRecentlySearchStation(props.recentlySearchedStations)
        }
    }

    return (
        <FlightSearchPage
            title={<FlightSearchTitleComponent title={props.titleText} icon={props.plainIcon}/>}
            renderToolbar={() => <StationsSearchComponent stations={props.stations}
                                                          onSearch={onSearch}/>}>
            {renderContent()}
        </FlightSearchPage>
    );

});
