import React from "react";
import {observer} from "mobx-react";
import {FlightCardContentBox} from "../../../../common/flight-card/flight-card-content.box";
import styled from "styled-components";
import {ExclamationDiamondIcon} from "../../../../../../../assets/svg/exclamation-diamond.icon";
import {useServices} from "../../../../../../../hooks/use-services.hook";
import {PrimaryButton} from "../../../../../../../basic-components/buttons/primary-button";
import {RetrieveBookingDialogComponent} from "../../../../../../retrieve-booking/retrieve-booking-dialog.component";
import {DialogCloseButtonBehavior} from "../../../../../../../services/dialog/dialog-enums";
import {MyTripsTab} from "../../my-trips.tab";

const CardContainerBox = styled(FlightCardContentBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    min-height: 214px;
    width: 100%;
    text-align: center;
    font-size: ${props => props.theme.fontSize.h6};
    font-weight: ${props => props.theme.fontSize.h6};
`


const TopSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-grow: 1;
    gap: ${props => props.theme.spacing.flexGap20};
`

const BottomSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

export const NoBookingHistoryComponent: React.FC<{message: string; retrieveButtonText: string; tab: MyTripsTab}> = observer((props) => {
    const services = useServices();
    const onRetrieveBookingClick = async () => {
        await services.dialog.showPopupDialog({
            closeButtonBehavior: DialogCloseButtonBehavior.Reject,
            render: dialogHandler => (<RetrieveBookingDialogComponent dialogHandler={dialogHandler}
                                                                      retrieveBooking={searchParams => props.tab.retrieveBooking(searchParams)}/>)
        })
    }
    return (
        <CardContainerBox>
            <TopSectionBox>
                <ExclamationDiamondIcon color={services.theme.currentTheme.colors.lightShade} size={4}/>
                <span>
                    {props.message}
                </span>
            </TopSectionBox>

            <BottomSectionBox>
                <PrimaryButton fullWidth={true} onClick={onRetrieveBookingClick}>
                    {props.retrieveButtonText}
                </PrimaryButton>
            </BottomSectionBox>

        </CardContainerBox>
    )
})