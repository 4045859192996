import React from "react";
import {observer} from "mobx-react";
import {FormViewComponent} from "../../../components/forms-view/form-view.component";
import {FieldsSectionComponent} from "../../../components/forms-view/fields-section.component";
import {useServices} from "../../../hooks/use-services.hook";
import {AccordionBox, AccordionComponent, AccordionContentBox, AccordionHeaderBox} from "../../../components/accordion/accordion.component";
import styled from "styled-components";
import {ICompanionViewModel} from "../../../services/user/models/companion/companion-view-model.interface";
import {IonItem, IonItemOption, IonItemOptions, IonItemSliding} from "@ionic/react";
import {GenderFieldViewComponent} from "../../../components/forms-view/gender-field/gender-field-view.component";
import {DateFieldViewComponent} from "../../../components/forms-view/date-field/date-field-view.component";
import {CountryFieldViewComponent} from "../../../components/forms-view/country-field/country-field-view.component";
import {CompanionTravelDocumentsListViewComponent} from "./companion-travel-documents-list-view.component";
import { EditPencilIcon } from "../../../assets/svg/edit-pencil.icon";
import { RecicleBinIcon } from "../../../assets/svg/recicle-bin.icon";
import { PersonCircleFilledIcon } from "../../../assets/svg/person-circle-filled.icon";
import {SlideDeleteButtonBox} from "../../../components/forms/buttons/slide-delete-button.box";

const CompanionNameHeaderBox = styled.div`
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.bold};
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        margin-right: 10px;
    }
`

const CompanionDetailsViewComponent: React.FC<{companion: ICompanionViewModel}> = observer((props) => {
    const services = useServices();
    const companion = props.companion;
    return (
        <FormViewComponent>
             <FieldsSectionComponent title={services.language.translate('Personal details')}>
                 <DateFieldViewComponent field={companion.fields.dateOfBirth}/>
                 <GenderFieldViewComponent field={companion.fields.gender}/>
                 <CountryFieldViewComponent field={companion.fields.nationality}/>
             </FieldsSectionComponent>

            <FieldsSectionComponent title={services.language.translate('Travel documents')}>
                <CompanionTravelDocumentsListViewComponent companion={companion}/>
            </FieldsSectionComponent>
        </FormViewComponent>
    );
});

const IonItemOptionBox = styled(IonItemOption)`
`

const IonItemOptionsBox = styled(IonItemOptions)`
    border: none;
`

const DeleteButtonComponent: React.FC<{companion: ICompanionViewModel}> = observer((props) => {
    const services = useServices();
    const onClickHandler = async () => {
        await props.companion.delete();
    }
    return (
        <SlideDeleteButtonBox onClick={onClickHandler}>
            {services.language.translate('Delete')} <RecicleBinIcon size={1.4}/>
        </SlideDeleteButtonBox>
    )
});

const EditButtonBox = styled.button`
  background-color: transparent;
  color: ${props => props.theme.colors.secondary};
  font-size: ${props => props.theme.fontSize.h5};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15px;

  svg {
      margin-left: 5px;
  }
`

const EditButtonComponent: React.FC<{companion: ICompanionViewModel}> = observer((props) => {
    const services = useServices();
    const onClickHandler = async () => {
        await props.companion.edit();
    }
    return (
        <EditButtonBox onClick={onClickHandler}>
            {services.language.translate('Edit')} <EditPencilIcon size={1.4}/>
        </EditButtonBox>
    )
});

const IonItemBox = styled(IonItem)`
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  --background: transparent;
`


const AccordionHeaderComponent: React.FC<{companion: ICompanionViewModel}> = observer((props) => {
    return (
        <IonItemSliding>
            <IonItemOptionsBox>
                <IonItemOptionBox>
                    <DeleteButtonComponent companion={props.companion}/>
                </IonItemOptionBox>
            </IonItemOptionsBox>
            <IonItemBox lines="none">
                <CompanionNameHeaderBox>
                    <PersonCircleFilledIcon /> {props.companion.getFullName()}
                </CompanionNameHeaderBox>
            </IonItemBox>
        </IonItemSliding>
    )
});

const CompanionViewComponentBox = styled(AccordionComponent)`
    border-radius: 10px;
    box-shadow: 0 10px 50px rgba(${props => props.theme.colors.darkRgb}, 0.05);
    border: 0.5px solid rgba(${props => props.theme.colors.tertiaryRgb}, 0.1);
    margin-bottom: 10px;
    margin-top: 10px;
    ${AccordionHeaderBox} {
        padding: 8px 16px;
    }
    ${AccordionContentBox} {
        padding: 0 16px 16px;

        ${AccordionBox} {
            margin: 0 0 10px;

        }
    }
`

export const CompanionViewComponent: React.FC<{companion: ICompanionViewModel}> = observer((props) => {
    const renderEditButton = () => {
        return (<EditButtonComponent companion={props.companion}/>);
    }
    return (
        <CompanionViewComponentBox renderHeader={() => <AccordionHeaderComponent companion={props.companion}/>}
                                    renderContent={() => <CompanionDetailsViewComponent companion={props.companion}/>}
                                    renderArrowUp={renderEditButton}
                                    renderArrowDown={renderEditButton}
                                    expandOnArrowClick={false}
                                    scrollIntoViewOnExpand={true}/>
    );
});
