import React, {CSSProperties, PropsWithChildren} from "react";
import {IPassengerSegmentBoardingPassViewModel} from "../../../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {BoardingPassBagsSectionComponent} from "../common/boarding-pass-bags-section.component";
import {useServices} from "../../../../../hooks/use-services.hook";
import {BoardingPassBagsSectionTitleComponent} from "../common/boarding-pass-bags-section-title.component";
import {PbrdSsrLargeIcon} from "../../../../../services/ssr-types/ssrs/pbrd/pbrd.ssr.large-icon";

const CabinBagIconBox: React.FC<PropsWithChildren> = (props) => {
    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,

    };
    return (
        <div style={styles}>
            {props.children}
        </div>
    );
};

export const BoardingPassCabinBagsComponent: React.FC<{boardingPass: IPassengerSegmentBoardingPassViewModel; hasOtherSsrs: boolean}> = (props) => {
    const services = useServices();
    const hasPriority = props.boardingPass.priorityBoarding.count > 0;

    if(props.boardingPass.isInfant) {
        return null;
    }

    const ssrType = hasPriority
                    ? services.ssrTypes.getSsrType(props.boardingPass.priorityBoarding.ssrCode)
                    : services.ssrTypes.SCBG;

    const rootStyles: CSSProperties = {
        width: '100%'
    };


    const renderIcon = () => {
        if(ssrType.ssrCode === services.ssrTypes.PBRD.ssrCode) {
            return (
                <PbrdSsrLargeIcon/>
            )
        }

        return ssrType.largeIcon;
    }

    return (
        <BoardingPassBagsSectionComponent styles={rootStyles}>
            <BoardingPassBagsSectionTitleComponent title={ssrType.name}/>
            <CabinBagIconBox>
                {renderIcon()}
            </CabinBagIconBox>
        </BoardingPassBagsSectionComponent>
    )
}
