import {
    SsrTranslationFormatter
} from "../../../ssr-types/ssr-translation-formatter";


export default (useHtml: boolean) => {
    const {bolded, colored, coloredBold} = SsrTranslationFormatter.create(useHtml);
    return {
        "PBRD": "Priority + 2 Cabin bags",
        "PBRD_DETAILS": "Priority + 10Kg Cabin Bag + Backpack",
        "PBRD_IN_BUNDLE": `${bolded("Priority")} boarding`,
        "SBAG": `${coloredBold("23Kg")} Check-in Bag`,
        "SBAG_DETAILS": "80 cm x 50 cm x 30 cm",
        "BAG": `${coloredBold("32Kg")} Check-in Bag`,
        "BAG_DETAILS": "80 cm x 50 cm x 30 cm",
        "SCBG": "Small cabin bag",
        "SCBG_DETAILS": `${colored("40x30x20 cm, max 10 kg, under the seat")}`,
        "STST": "Seat",
        "STST_DETAILS": "Standard seats",
        "FLX": `${bolded("No flight change fee")}`,
        "FLX_DETAILS": "No flight change fee when changing flight online (up to 4 hrs pre-departure); Only pay the fare difference if applicable",
        "WCHS": "Wheelchair assistance",
        "WCHS_DETAILS": "Wheelchair required for transfer, passenger can walk, but cannot use stairs",
        "WCHR": "Wheelchair required for transfer",
        "WCHR_DETAILS": "Required for transfer to/from aircraft, can use steps",
        "WCHC": "Wheelchair required to/from cabin seat",
        "WCHC_DETAILS": "Wheelchair required to/from cabin seat, passenger immobile",
        "PREG": "Pregnant passenger",
        "PREG_DETAILS": "Pregnant passenger up to 36 weeks",
        "DPNA": "Passenger with disabilities",
        "DPNA_DETAILS": "Passenger with intellectual or developmental disabilities",
        "DEAF": "Deaf customer",
        "DEAF_DETAILS": "Passenger with partial or total hearing impairment",
        "BLD": "Blind customer",
        "BLD_DETAILS": "Passenger with visual disabilities or completely visually impaired",
        "CHMA": "Menu pancakes stuffed with chicken and cream cheese",
        "HFML": "Meatballs in red sauce, mashed potatoes and green beans menu",
        "KRMN": "Cold plate menu",
        "LSML": "Lasagna Menu",
        "NBML": "Grilled chicken with tomato sauce, baked potatoes and broccoli menu",
        "SPML": "Spaghetti with vegetables menu",
        "VLML": "Ham and vegetables omelette menu",
        "VOML": "Chicken with rice menu",
        "PETC": "Pet in cabin",
        "PETC_DETAILS": "10 kg including the cage or soft sided carrier",
        "AVIH": "Pet in hold",
        "AVIH_DETAILS": "32 kg (cage + pet)",
        "WEAP": "Weapon",
        "SPEQ": "Sports equipment",
        "BIKE": "Bike",
        "STEX": `${bolded("Premium")} Seats`,
        "STEX_DETAILS": "Extra legroom or standard seats",
        "CKN": "Airport check-in",
        "CKN_DETAILS": `${colored("Free at the airport")}`,
        "ACKN": "Airport check-in",
        "ACKN_DETAILS": `${colored("Free at the airport")}`,
        "PCKN": `${coloredBold("Early")} Check-in`,
        "PCKN_DETAILS": `${bolded("Free")} advanced check-in ${bolded("30 days")} out & at the airport`,
        "UM": "Unaccompanied minor",
        "UMFR": "Unaccompanied minor",
        "UMNR": "Unaccompanied minor, Romanian",
        "BCBG": `${bolded("Large cabin bag")}`,
        "BCBG_DETAILS": `Trolley bag ${colored("55x40x20 cm")}; MAX 10kg`,
        "LCKN": "Online check-in",
        "LCKN_DETAILS": "Free 24h before flight",
        "XSBG": "10Kg Check-in Bag",
        "XSBG_DETAILS": "55 cm x 40 cm x 20 cm",
        "INS1": "Travel insurance",
        "ECKN": "Early online check-in",
        "ECKN_DETAILS": "Allows online check-in within {time} before flight",
        "SBUS": "Transfer to and from the airport",
        "SBUS_DETAILS": "Transfer service between Bacău Airport and Bacău Central Station",
        "LOU": "Business Lounge",
        "LOU_DETAILS": "Business Lounge Access * Only for flights departing from Bacău",
        "FAST": "Fast Track",
        "FAST_DETAILS": "Skip the queues with Fast Track * only for flights departing from Bacău"
    }

};


