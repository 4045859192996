import React from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import { InformationCircleIcon } from "../../../../assets/svg/information-circle.icon";
import { useServices } from "../../../../hooks/use-services.hook";

export const ShoppingCartPriceDetailsButtonBox = styled.div`
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 4px;
`


interface ShoppingCartPriceDetailsButtonComponentProps {
    onClick: () => void;
}
export const ShoppingCartPriceDetailsButtonComponent: React.FC<ShoppingCartPriceDetailsButtonComponentProps> = observer((props) => {
    const services = useServices();
    
    return (
        <ShoppingCartPriceDetailsButtonBox onClick={props.onClick}>
            <InformationCircleIcon size={1} color={services.theme.currentTheme.colors.medium} />
        </ShoppingCartPriceDetailsButtonBox>
    )
});
