import React from "react";
import {observer} from "mobx-react";
import {
    IBookingSsrsAggregatorViewModel
} from "../../../../services/booking/models/ssrs/aggregator/booking-ssrs-aggregator-view-model.interface";
import {PriceComponent} from "../../../../components/price/price.component";
import {StandardDialogFooterComponent} from "../../../../components/modal/footer/standard-dialog-footer.component";
import styled from "styled-components";
import {useServices} from "../../../../hooks/use-services.hook";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import {PrimaryShadeButtonInverted} from "../../../../basic-components/buttons/primary-shade-button-inverted";
import {IJourneyViewModel} from "../../../../services/booking/models/journey/journey-view-model.interface";
import {GoToPreviousButtonComponent} from "../../../../basic-components/buttons/go-to-previous.button.box";
import {IonSpinnerComponent} from "../../../../components/spinner/ion-spinner.component";

const FooterComponent = styled(StandardDialogFooterComponent)`
    flex-direction: row;
    background-color: ${props => props.theme.colors.primaryShade};
    color: ${props => props.theme.colors.light};
    overflow: hidden;
`

const LeftSectionBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap12};
    flex-grow: 1;
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

const RightSectionBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.spacing16};
`

const ContinueButton = styled(PrimaryShadeButtonInverted)`
    border: none;
    min-width: 12.5rem;
`

const PreviousFlightButtonBox = styled(GoToPreviousButtonComponent)`
    background-color: inherit;
    border-color: ${props => props.theme.colors.light};
    color: ${props => props.theme.colors.light};
`

const SpinnerBox = styled(IonSpinnerComponent)`
    --color: ${props => props.theme.colors.primaryContrast};
`


interface SsrsSelectionDialogFooterComponentProps {
    ssrsAggregator: IBookingSsrsAggregatorViewModel;
    dialogHandler: IDialogConfirmationHandler;
    onGoToJourney?: (IJourneyViewModel: IJourneyViewModel) => void;
    currentSelectedJourney?: IJourneyViewModel;
}
export const SsrsSelectionDialogFooterComponent: React.FC<SsrsSelectionDialogFooterComponentProps> = observer((props) => {
    const services = useServices();
    const renderPrice = () => {
        if(services.booking.current.mutationsManager.isBookingTotalUpdateInProgress) {
            return (
                <SpinnerBox />
            )
        }

        return (
            <PriceComponent price={props.ssrsAggregator.totalPriceOnCurrentSession}/>
        );

    }

    const getSelectedJourneyIndex = () => {
        return props.ssrsAggregator.journeysSsrsBuckets.findIndex(j => j.parentJourney.journeyKey === props.currentSelectedJourney?.journeyKey);
    }



    const lastJourneyIndex = props.ssrsAggregator.journeysSsrsBuckets.length - 1;

    const goToNextJourney = () => {
        if(!props.onGoToJourney) {
            return;
        }
        const selectedJourneyIndex = getSelectedJourneyIndex();

        if(selectedJourneyIndex < lastJourneyIndex) {
            props.onGoToJourney(props.ssrsAggregator.journeysSsrsBuckets[selectedJourneyIndex + 1].parentJourney)
        } else {
            props.dialogHandler.accept()
        }
    }

    const goToPreviousJourney = () => {
        if(!props.onGoToJourney) {
            return;
        }
        const selectedJourneyIndex = getSelectedJourneyIndex();

        if(selectedJourneyIndex > 0) {
            props.onGoToJourney(props.ssrsAggregator.journeysSsrsBuckets[selectedJourneyIndex - 1].parentJourney)
        }
    }

    const renderRightSection = () => {
        if(!props.currentSelectedJourney ||
            !props.onGoToJourney
            || props.ssrsAggregator.useSameOptionsForAllFlights) {
            return (
                <RightSectionBox>
                    <ContinueButton onClick={() => props.dialogHandler.accept()}>
                        {services.language.translate('Continue')}
                    </ContinueButton>
                </RightSectionBox>

            )
        } else {
            const selectedJourneyIndex = getSelectedJourneyIndex();
            const isOnLastFlight = selectedJourneyIndex === lastJourneyIndex;
            return (
                <RightSectionBox>
                    {selectedJourneyIndex > 0 && (<PreviousFlightButtonBox onClick={goToPreviousJourney} disabled={!isOnLastFlight}/>)}
                    <ContinueButton onClick={goToNextJourney} showArrow={!isOnLastFlight}>
                        {isOnLastFlight
                                ? services.language.translate('Continue')
                                : services.language.translate('Next flight')
                        }
                    </ContinueButton>
                </RightSectionBox>
            )
        }

    }

    return (
        <FooterComponent dialogHandler={props.dialogHandler}>
            <LeftSectionBox>
                <div>{services.language.translate('Total')}</div>
                {renderPrice()}
            </LeftSectionBox>
            {renderRightSection()}
        </FooterComponent>
    )
})