import {BookingMutationActionBase} from "../booking-mutation-action-base";
import {MaturePassengerModel} from "../../passenger/mature-passenger.model";
import {
    IDotRezPartialBookingSessionData
} from "../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {IMutationOptions} from "../mutation-options.interface";
import {TimeSpan} from "../../../../../types/time-span";
import {FeeCodeEnum} from "../../../../dot-rez-api/data-contracts/enums/fee-type.enum";

export class PassengerNameChangeMutation extends BookingMutationActionBase {
    constructor(protected readonly passenger: MaturePassengerModel,
                options: IMutationOptions) {
        super(passenger.booking, options);
    }

    async _runMutation(): Promise<IDotRezPartialBookingSessionData> {
        try {
            await this.session.changePassengerName(this.passenger.passengerKey, {
                customerNumber: null,
                name: {
                    first: this.passenger.fields.firstName.value,
                    last: this.passenger.fields.lastName.value
                }
            });

            return await this.session.bookingStateQueryBuilder().useBookingData().getBookingState();

        } catch (err) {
            this.services.logger.error('Passenger name change failed', err);
            this.passenger.cancelChanges();
            this.services.alert.showError(this.services.language.translate('Passenger name change failed'));
            return {};
        }

    }

    async onAfterBookingSessionDataUpdated(): Promise<void> {
        await super.onAfterBookingSessionDataUpdated();
        if(this.passenger.fees.some(f => f.feeCode === FeeCodeEnum.NameChangeFee)) {
            this.booking.shoppingCart.notifications.push({
                message: this.services.language.translate('Your shopping cart was updated with the name change fee.'),
                timeout: TimeSpan.fromSeconds(5)
            });
        }
    }
}
