import React, {useState} from "react";
import {useServices} from "../../../../hooks/use-services.hook";
import {observer} from "mobx-react-lite";
import {PaymentAccordionHeaderComponent} from "../components/payment-accordion/payment-accordion-header.component";
import {PaymentAccordionComponent} from "../components/payment-accordion/payment-accordion.component";
import styled from 'styled-components';
import {
    PromotionalCodeInputComponent
} from "../../../../components/promotionals/common/promotional-code-input.component";
import {setElementFocus} from "../../../../utils/set-element-focus";
import {
    IVoucherViewModel
} from "../../../../services/booking/models/promotions/voucher/voucher-view-model.interface";
import {PrimaryButton} from "../../../../basic-components/buttons/primary-button";
import {AlertBoxComponent, AlertBoxSeverity} from "../../../../components/alert-box/alert-box.component";
import {
    IPromotionalApplyStatus
} from "../../../../services/booking/models/promotions/promotional-apply-status.interface";
import {
    PromotionalApplyStatusEnum
} from "../../../../services/booking/models/promotions/promotional-apply-status.enum";
import {PaymentVoucherCodeSimpleIcon} from "../../../../assets/svg/payment-voucher-code-simple.icon";

const CSS_CLASS_VOUCHER_ALERT_BOX = 'voucher-alert-box';

const ContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${props => props.theme.spacing.spacing16};
    
`
const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${props => props.theme.spacing.spacing8};
`

const InputLabelBox = styled.div`
    font-size: ${props => props.theme.fontSize.subtitle1};
    font-weight: ${props => props.theme.fontWeight.medium};
`

const InputContainerBox = styled.div<{$isExpanded: boolean}>`
    display: ${props => props.$isExpanded ? "flex": "none"} ;
    flex-direction: row;
    align-items: stretch;
    gap: ${props => props.theme.spacing.spacing24};
    width: 100%;
`


const ApplyVoucherButton = styled(PrimaryButton)`
    font-size: ${props => props.theme.fontSize.h3};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    min-width: 174px;
    ${props => props.theme.containerMediaQuery.xsAndBelow} {
        min-width: unset;
    }
`


const VoucherAlertComponent = styled(AlertBoxComponent)`
    & .${CSS_CLASS_VOUCHER_ALERT_BOX} {
        padding: ${props => props.theme.spacing.spacing30};
    }
`

const VoucherApplyResultComponent: React.FC<{result: IPromotionalApplyStatus}> = (props) => {
    const services = useServices();
    let severity: AlertBoxSeverity;
    let title: string;


    if(props.result.status === PromotionalApplyStatusEnum.NotApplied) {
        return null;
    }

    if(props.result.status === PromotionalApplyStatusEnum.Success) {
        severity = "success";
        title = services.language.translate('Voucher confirmed');
    } else {
        severity = "error"
        title = services.language.translate('Voucher not applicable');
    }



    return (
        <VoucherAlertComponent severity={severity}
                               size={"large"}
                               title={title}
                               fullWidth={true}
                               contentCssClass={CSS_CLASS_VOUCHER_ALERT_BOX}>
            <div dangerouslySetInnerHTML={{
                __html: props.result.message ?? ""
            }}/>
        </VoucherAlertComponent>
    );
}




interface VoucherCodeComponentProps {
    voucher: IVoucherViewModel;
}
export const VoucherCodeComponent: React.FC<VoucherCodeComponentProps> = observer((props) => {
    const services = useServices();
    const [isExpanded, setIsExpanded] = useState(Boolean(props.voucher.code.value));



    if(!props.voucher.shouldBeVisible) {
        return null;
    }


    const renderVoucherCodeHeader = () => {
        return (
            <PaymentAccordionHeaderComponent renderIcon={() => <PaymentVoucherCodeSimpleIcon />} title={services.language.translate('Apply a voucher')} />
        )
    }

    const captureInputElementRef = (element: any) =>  {
        setElementFocus(element);
    }

    const onApplyVoucher = async () => {
        await props.voucher.apply();
    }


    const renderApplyVoucherResult = () => {
        if(!props.voucher.code.value?.trim()) {
            return null;
        }
        return (
            <VoucherApplyResultComponent result={props.voucher.applyStatus} />
        );
    }

    const renderAccordionContent = () => {
        return (
            <ContainerBox className={'VoucherAccordionDetailContentBox'}>
                <ContentBox className={'VoucherAccordionContentBox'}>
                    <InputLabelBox>
                        {services.language.translate('Insert your voucher code')}
                    </InputLabelBox>
                    <InputContainerBox $isExpanded={isExpanded}>

                        <PromotionalCodeInputComponent promotional={props.voucher}
                                                       placeHolder={""}
                                                       captureInputElementRef={captureInputElementRef}/>
                        <ApplyVoucherButton onClick={onApplyVoucher}
                                            disabled={!Boolean(props.voucher.code.value) || props.voucher.isApplied || !props.voucher.isAllowed}
                                            className={'tst-apply-voucher'}>
                            {services.language.translate('Apply')}
                        </ApplyVoucherButton>
                    </InputContainerBox>


                </ContentBox>
                {renderApplyVoucherResult()}
                {props.voucher.getVoucherNotAllowedMessage()}
            </ContainerBox>
        )
    }

    return (
        <PaymentAccordionComponent renderHeader={renderVoucherCodeHeader}
                                   renderContent={renderAccordionContent}
                                   onExpandStateChange={(expanded) => setIsExpanded(expanded)}
                                   isExpanded={isExpanded}/>
    )
});
