import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import {NullableString} from "../../../../../types/nullable-types";
import styled, {css} from "styled-components";
import {CardBox} from "../../../../../basic-components/card/card.box";

export const BagContainerBox = styled(CardBox)<{$isSelected: boolean; $isClickable: boolean}>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    flex-basis: 100%;
    box-shadow: ${props => props.theme.boxShadow.shadow2};
    gap: ${props => props.theme.spacing.spacing12};
    padding: ${props => props.theme.spacing.spacing24};
    font-size: ${props => props.theme.fontSize.body1};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    cursor: ${props => (props.$isSelected || !props.$isClickable) ? "default" : "pointer"};

    ${
    props => props.$isSelected
        ? css`
                  border: 1px solid ${props => props.theme.colors.success};
            `
        : css`
                  border: 1px solid ${props => props.theme.colors.light};
            `
    }

    ${props => props.theme.screenMediaQuery.xsAndBelow} {
        padding: ${props => props.theme.spacing.spacing20} ${props => props.theme.spacing.spacing12};
    }
`

const TopLabelBox = styled.span`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${props => props.theme.colors.cardLabelBackgroundWarring};
    color: ${props => props.theme.colors.cardLabelColorWarning};
    border-radius: 20px;
    font-size: ${props => props.theme.fontSize.caption};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    padding: 6px ${props => props.theme.spacing.spacing12};
    text-align: center;
    white-space: nowrap;
    
    ${props => props.theme.screenMediaQuery.xsAndBelow} {
        width: 80%;
    }
    
`


interface BagContainerComponentProps extends PropsWithChildren {
    isSelected: boolean;
    isClickable: boolean;
    topLabel?: NullableString;
    onClick?: () => void;
    className?: string;
}
export const BagContainerComponent: React.FC<BagContainerComponentProps> = observer((props) => {
    const onClick = () => {
        if(props.onClick) {
            props.onClick();
        }
    }

    const renderTopLabel = () => {
        if(props.topLabel) {
            return (
                <TopLabelBox>
                    {props.topLabel}
                </TopLabelBox>
            )
        }

        return null;
    }

    return (
        <BagContainerBox $isSelected={props.isSelected} onClick={onClick} className={props.className} $isClickable={props.isClickable}>
            {renderTopLabel()}
            {props.children}
        </BagContainerBox>
    )
})