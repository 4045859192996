import styled, {keyframes} from "styled-components";
import {CardBox} from "../../../../../basic-components/card/card.box";
import {CSS_CLASS_ANIMATE_FLIGHT_CARD} from "../../tabs/my-trips/my-trips-tab-constants";

const focusAnimation = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
 
`

export const FlightCardContentBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap20};
    ${props => props.theme.spacing.spacing12};
    &.${CSS_CLASS_ANIMATE_FLIGHT_CARD} {
        animation: ${focusAnimation} 0.4s ease-out 3;
    }
    
`