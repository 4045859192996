import styled from "styled-components";
import {
    QuantityDecrementButtonComponent,
    QuantityIncrementButtonComponent
} from "../../../../../../components/quantity-edit-buttons/quantity-edit-buttons";
import React from "react";
import {
    IPassengerSsrEditorViewModel
} from "../../../../../../services/booking/models/ssrs/passenger-ssr-editor-view-model.interface";
import {observer} from "mobx-react";

const QuantityEditButtonsContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: ${props => props.theme.spacing.spacing8};
    line-height: 1;
`

const DecrementButtonComponent = styled(QuantityDecrementButtonComponent)`
    margin-left: ${props => props.theme.spacing.spacing8};
`

const IncrementButtonComponent = styled(QuantityIncrementButtonComponent)`
    margin-right: ${props => props.theme.spacing.spacing8};
`



const BagQuantityBox = styled.div`
    text-align: center;
    font-size: ${props => props.theme.fontSize.h2};
    
    
`

export const CheckInBagQuantityButtonsComponent: React.FC<{ssrEditor: IPassengerSsrEditorViewModel;}> = observer((props) => {
    const onDecrement = async () => {
        props.ssrEditor.newQuantity--;
    }

    const onIncrement = async () => {
        props.ssrEditor.newQuantity++;
    }

    const canIncrement = props.ssrEditor.canBeModifiedForAtLeastOneSegment && props.ssrEditor.newQuantity < props.ssrEditor.maxAllowedQuantity;
    const canDecrement = props.ssrEditor.canBeModifiedForAtLeastOneSegment && props.ssrEditor.newQuantity > props.ssrEditor.minAllowedQuantity;

    return (
        <QuantityEditButtonsContainerBox>
            <DecrementButtonComponent disabled={!canDecrement}
                                      onClick={onDecrement}/>
            <BagQuantityBox className='tst-bag-quantity'>
                {props.ssrEditor.newQuantityToDisplay}
            </BagQuantityBox>

            <IncrementButtonComponent disabled={!canIncrement}
                                      onClick={onIncrement}/>

        </QuantityEditButtonsContainerBox>
    )
});

