import React from "react";
import {PrimaryButton} from "../../basic-components/buttons/primary-button";
import styled from "styled-components";
import {observer} from "mobx-react";
import {RankingStarIcon} from "../../assets/svg/ranking-star.icon";

const ButtonComponent = styled(PrimaryButton)`
    flex-direction: column;
    gap: ${props => props.theme.spacing.spacing12};
`


interface UpsellSuggestionButtonComponentProps {
    label: string;
    renderIcon?: () => React.ReactElement;
    onClick: () => void;
}

export const UpsellSuggestionAcceptButtonComponent: React.FC<UpsellSuggestionButtonComponentProps> = observer((props) => {

    const renderIcon = () => {
        if(props.renderIcon) {
            return props.renderIcon();
        }

        return (
            <RankingStarIcon/>
        );
    }

    return (
        <ButtonComponent onClick={props.onClick} fullWidth={true}>
            {renderIcon()}
            <span>
                {props.label}
            </span>

        </ButtonComponent>
    )
});