import React from 'react';
import styled from "styled-components";
import {PrimaryButtonInverted} from "../../../../basic-components/buttons/primary-button-inverted";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";

export const ExtrasEditButtonBox = styled(PrimaryButtonInverted)`
    font-size: ${props => props.theme.fontSize.h6};
    font-weight: ${props => props.theme.fontWeight.medium};
    padding: ${props => props.theme.spacing.spacing12} ${props => props.theme.spacing.spacing24};
    background-color: ${props => props.theme.colors.primaryContrast};
    min-width: 100px;
`

interface ExtrasEditButtonComponentProps {
    hasPurchases: boolean;
    onClick: () => void;
}
export const ExtrasEditButtonComponent: React.FC<ExtrasEditButtonComponentProps> = observer((props) => {
    const services = useServices();
    return (
        <ExtrasEditButtonBox onClick={props.onClick}>
            {props.hasPurchases ? services.language.translate('Modify') : services.language.translate('Add')}
        </ExtrasEditButtonBox>
    )
})