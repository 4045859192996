import React from 'react';
import {SeatFilterBaseModel} from "./seat-filter-base.model";
import {SeatModel} from "../seat.model";
import {ChildFaceIcon} from "../../../../../assets/svg/child-face.icon";

export class ChildSeatFilterModel extends SeatFilterBaseModel {

    get description(): string {
        return this.services.language.translate('Seats for children');
    }

    shouldAllowSeat(seat: SeatModel): boolean {
        return seat.allowChild;
    }

    renderIcon(): React.ReactElement {
        return (<ChildFaceIcon size={1.4} />);
    }
}
