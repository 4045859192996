import React from "react";
import {observer} from "mobx-react";
import {
    FlightsDatesSelectorModel
} from "../../../../services/flight-search/dates-selection/flights-dates-selector.model";
import {
    FlightsDatesSelectionHeaderComponent
} from "../../../../components/flight-search/dates-selection/header/flights-dates-selection-header.component";
import styled, {css} from "styled-components";
import {ZIndex} from "../../../../types/z-index";

const ContainerBox = styled.div<{$showBottomBorder: boolean}>`
    position: sticky;
    top: 0;
    background-color: ${props => props.theme.colors.cardBackground};
    z-index: ${ZIndex.StickyHeaders};
    padding: 0 ${props => props.theme.spacing.pageContentHorizontalPadding} ${props => props.theme.spacing.spacing8} ${props => props.theme.spacing.pageContentHorizontalPadding};
    margin: 0 calc(-1 * ${props => props.theme.spacing.pageContentHorizontalPadding});
    ${props => props.$showBottomBorder
            ? css`
                border-bottom: 1px solid ${props => props.theme.border.mainColor};
            `
            : ``
    }
`

export const ManageMyBookingNewFlightsDatesSelectionHeaderSmallScreenComponent: React.FC<{flightsDatesSelector: FlightsDatesSelectorModel; showBottomBorder: boolean}> = observer((props) => {
    return (
        <ContainerBox $showBottomBorder={props.showBottomBorder}>
            <FlightsDatesSelectionHeaderComponent flightsDatesSelector={props.flightsDatesSelector} />
        </ContainerBox>

    )
})