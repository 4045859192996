import {CmsPageKey, CmsPageUrlBuilderParams, ICmsService} from "./cms.service.interface";
import {ServiceBase} from "../service-base";

export class CmsService extends ServiceBase implements ICmsService {
    getPageUrl(key: CmsPageKey, urlBuilderParams?: Record<CmsPageUrlBuilderParams, string>): string {


        let pageUrl = this.services.configuration.data.cms.pages[key];
        if(pageUrl.startsWith("http:") || pageUrl.startsWith("https:")) {
            return this._applyUrlReplacements(pageUrl);
        }

        if(pageUrl.startsWith('/')) {
            pageUrl = pageUrl.substring(1);
        }

        let baseUrl = this.services.configuration.data.cms.baseUrl;
        if(!baseUrl.endsWith('/')) {
            baseUrl = baseUrl + '/';
        }

        return this._applyUrlReplacements(baseUrl + pageUrl, urlBuilderParams);
    }

    getPsoUrl(marketName: string): string {
        return this.getPageUrl('pso', {
            market: marketName
        });
    }

    private _applyUrlReplacements(url: string, urlBuilderParams?: Record<CmsPageUrlBuilderParams, string>) {
        url = url.replaceAll('{language}', this.services.language.currentLanguageIso2);

        if(urlBuilderParams) {
            Object.keys(urlBuilderParams).forEach(key => {
                url = url.replaceAll(`{${key}}`, urlBuilderParams[key as CmsPageUrlBuilderParams])
            })
        }

        const urlBuilder = new URL(url);
        urlBuilder.searchParams.append("antiCacheKey", Date.now().toString());
        return urlBuilder.href;
    }
}
