import React from 'react';
import {ISvgIconProps, SvgIcon} from '../../../../assets/svg/svg-icon.component';
//fill={props.color? props.color: "currentColor"}
export const SbusSsrSmallIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.6} sizeIsHeight {...props} viewBox="0 0 30 22" ratio={30 / 32}>
            <path
                d="M3 3.875V8H7.5V3.5H3.375C3.16875 3.5 3 3.66875 3 3.875ZM25.5938 8L21.8016 3.5H18V8H25.5938ZM15 8V3.5H10.5V8H15ZM27.75 17H27C27 19.4844 24.9844 21.5 22.5 21.5C20.0156 21.5 18 19.4844 18 17H12C12 19.4844 9.98438 21.5 7.5 21.5C5.01562 21.5 3 19.4844 3 17H2.25C1.00781 17 0 15.9922 0 14.75V3.875C0 2.00938 1.50938 0.5 3.375 0.5H9H16.5H21.8016C22.6875 0.5 23.5266 0.889062 24.0984 1.56875L29.2969 7.74219C29.7516 8.28125 30 8.96562 30 9.67344V14.75C30 15.9922 28.9922 17 27.75 17ZM24.75 17C24.75 16.4033 24.5129 15.831 24.091 15.409C23.669 14.9871 23.0967 14.75 22.5 14.75C21.9033 14.75 21.331 14.9871 20.909 15.409C20.4871 15.831 20.25 16.4033 20.25 17C20.25 17.5967 20.4871 18.169 20.909 18.591C21.331 19.0129 21.9033 19.25 22.5 19.25C23.0967 19.25 23.669 19.0129 24.091 18.591C24.5129 18.169 24.75 17.5967 24.75 17ZM7.5 19.25C8.09674 19.25 8.66903 19.0129 9.09099 18.591C9.51295 18.169 9.75 17.5967 9.75 17C9.75 16.4033 9.51295 15.831 9.09099 15.409C8.66903 14.9871 8.09674 14.75 7.5 14.75C6.90326 14.75 6.33097 14.9871 5.90901 15.409C5.48705 15.831 5.25 16.4033 5.25 17C5.25 17.5967 5.48705 18.169 5.90901 18.591C6.33097 19.0129 6.90326 19.25 7.5 19.25Z"
                fill={props.color? props.color: "currentColor"}/>

        </SvgIcon>
    )
}
