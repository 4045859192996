import {observer} from "mobx-react";
import React from "react";
import {useServices} from "../../../hooks/use-services.hook";
import {NullableUndefinedStation} from "../../../services/stations/station.service.interface";
import {IonIcon} from "@ionic/react";
import {removeOutline} from "ionicons/icons";
import styled from "styled-components";
import {NotebookWithPenIcon} from "../../../assets/svg/notebook-with-pen.icon";

const TopMiddleSectionBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: ${props => props.theme.spacing.spacing8};
    line-height: 1;
`

const StationCodeBox = styled.span`
    font-weight: ${props => props.theme.fontWeight.regular};
`


const PassengersCountBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
`

const RegularFontWeightBox = styled.span`
    font-weight: ${props => props.theme.fontWeight.regular};
`

const RemoveOutlineBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: ${props => props.theme.colors.medium};
`

const ChangeSearchButtonContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap12};
    color: ${props => props.theme.colors.notesTint};
    font-size: ${props => props.theme.fontSize.caption};
    margin-left: ${props => props.theme.spacing.spacing8};
    cursor: pointer;
`

export const BookingFlowPageHeaderRouteInfoLargeScreenComponent: React.FC = observer(() => {
    const services = useServices();
    let designator = services.booking.current.departureJourney?.designator;
    if(!designator) {
        const availableDeparture = services.booking.availability.departureTrips[0];
        designator = availableDeparture?.journeys[0]?.designator;
    }


    let origin: NullableUndefinedStation;
    let destination: NullableUndefinedStation;

    if(designator) {
        origin = designator.origin;
        destination = designator.destination;
    } else {
        origin = services.booking.searchController.departureOrigin;
        destination = services.booking.searchController.departureDestination;
    }

    if(!origin || !destination) {
        return null;
    }

    const onChangeSearch = async () => {
        await services.dialogFactory.showChangeFlightSearch();
    }

    const countPassengers = () => {
        if(services.booking.currentPageCanBeRendered && services.booking.current.unfilteredJourneys.length > 0) {
            return services.booking.current.passengers.getAllPersonsInTheBooking().length;
        }

        return services.booking.searchController.passengers.countAll();
    }


    const translateNumberOfPassengers = () => {
        const numberOfPassengers = countPassengers();

        if(numberOfPassengers === 0) {
            return null;
        }

        let text = services.language.translate('Passenger');
        if(numberOfPassengers > 1) {
            text = services.language.translate('Passengers');
        }

        return (
            <PassengersCountBox>
                <span>{numberOfPassengers}</span>
                <RegularFontWeightBox>{text}</RegularFontWeightBox>
            </PassengersCountBox>
        );
    }

    const renderPassengersCount = () =>  {
        const passengersCountTranslation = translateNumberOfPassengers();
        if(!passengersCountTranslation) {
            return null;
        }

        return (
            <>
                <RemoveOutlineBox><IonIcon icon={removeOutline}/></RemoveOutlineBox>
                <div>{passengersCountTranslation}</div>
            </>
        )
    }

    const renderChangeSearchButton = () => {
        if(!services.booking.current.allowChangeSearch) {
            return null;
        }
        return (
            <ChangeSearchButtonContainerBox onClick={onChangeSearch}>
                <span>
                    {services.language.translate('Change Search')}
                </span>
                <NotebookWithPenIcon/>
            </ChangeSearchButtonContainerBox>
        );
    }

    return (
        <TopMiddleSectionBox>
            <div>
                {origin.stationName}
            </div>
            <StationCodeBox>
                {`(${origin.stationCode})`}
            </StationCodeBox>
            <RegularFontWeightBox>
                {services.language.translate('to')}
            </RegularFontWeightBox>
            <div>
                {destination.stationName}
            </div>
            <StationCodeBox>
                {`(${destination.stationCode})`}
            </StationCodeBox>

            {renderPassengersCount()}

            {renderChangeSearchButton()}


        </TopMiddleSectionBox>
    )
})