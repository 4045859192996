import React, {PropsWithChildren, useEffect, useRef} from "react";
import {observer} from "mobx-react";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";
import styled, {css} from "styled-components";
import {IonFooter} from "@ionic/react";

import {useElementScrollHeight} from "../../../hooks/use-element-scroll-height";
import {AirlineTheme} from "../../../services/theme/interfaces/airline.theme";

function computePaddings(theme: AirlineTheme, shouldPreserveBottomSafeArea: boolean, hasChildren: boolean) {
    if(shouldPreserveBottomSafeArea) {
        if(hasChildren) {
            return css`
              padding: ${theme.spacing.dialogVerticalPadding} ${theme.spacing.dialogHorizontalPadding} calc(${theme.spacing.dialogVerticalPadding} + env(safe-area-inset-bottom)) ${theme.spacing.dialogHorizontalPadding};
            `
        } else {
            return css`
              padding: 0 0 env(safe-area-inset-bottom) 0;
            `
        }

    }

    if(hasChildren) {
        return css`
              padding: ${props => props.theme.spacing.dialogVerticalPadding} ${props => props.theme.spacing.dialogHorizontalPadding};
        `
    }

    return (
        css`
            padding: 0;
        `
    )
}

const FooterBox = styled(IonFooter)<{shouldPreserveBottomSafeArea: boolean; horizontallyCentered?: boolean; hasChildren: boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${props => props.theme.spacing.spacing16};
    width: 100%;
    box-shadow: none;
    background-color: ${props => props.theme.colors.dialogFooterBackground};
    color: ${props => props.theme.colors.dialogFooterColor};
    
    ${props => computePaddings(props.theme, props.shouldPreserveBottomSafeArea, props.hasChildren)}
    ${
            props => props.horizontallyCentered
                    ? css`
                  align-items: center;
                `
                    : ''
    }
    
   
`

interface StandardDialogFooterComponentProps extends PropsWithChildren {
    className?: string;
    dialogHandler: IDialogConfirmationHandler;
    horizontallyCentered?: boolean;
}


export const StandardDialogFooterComponent: React.FC<StandardDialogFooterComponentProps> = observer((props) => {
    const footerRef = useRef<HTMLIonFooterElement | null>(null);
    const hasChildren = React.Children.count(props.children) > 0;
    const scrollHeight = useElementScrollHeight(footerRef);

    useEffect(() => {
        props.dialogHandler.footerHeight = scrollHeight;
    }, [props.dialogHandler, scrollHeight]);

    return (
        <FooterBox ref={footerRef}
                   className={props.className}
                   hasChildren={hasChildren}
                   horizontallyCentered={props.horizontallyCentered}
                   shouldPreserveBottomSafeArea={props.dialogHandler.shouldPreserveBottomSafeArea}>
            {props.children}
        </FooterBox>
    );


});
