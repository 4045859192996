import React from "react";
import {observer} from "mobx-react";
import {
    IPassengerSsrEditorViewModel
} from "../../../../../../services/booking/models/ssrs/passenger-ssr-editor-view-model.interface";
import {MustChooseAnOptionComponent} from "../../must-choose-an-option/must-choose-an-option.component";
import {useContainerMediaQueriesChecks} from "../../../../../../hooks/use-container-media-queries-checks.hook";


export const CabinBagMustChooseAnOptionComponent: React.FC<{ssrEditor: IPassengerSsrEditorViewModel; hasError: boolean}> = observer((props) => {
    const mediaQuery = useContainerMediaQueriesChecks();
    const isInSmallScreen = mediaQuery.smallScreen;

    if(props.ssrEditor.quantityIncludedInBundle > 0 || props.ssrEditor.minAllowedQuantity > 0) {
        return null;
    }

    return (
        <MustChooseAnOptionComponent arrowDirection={isInSmallScreen ? "up" : "forward"} hasError={props.hasError}/>
    );
})