import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {PassengersTypesDropDownInputComponent} from "./input/passengers-types-drop-down-input.component";
import {PassengersTypesDropDownListComponent} from "./content/passengers-types-drop-down-list.component";
import {IDropDownController} from "../../../forms/drop-down/models/drop-down-controller.interface";
import {DropDownComponent} from "../../../forms/drop-down/drop-down.component";
import {PersonGroupNotFilledIcon} from "../../../../assets/svg/person-group-not-filled.icon";
import {FlightSearchDropDownIconComponent} from "../../common/flight-search-drop-down-icon.component";

export const PassengersTypesDropDownComponent: React.FC<{isReadonly?: boolean; onClick?: () => void}> = observer((props) => {
    const services = useServices();
    const fields = services.flightSearch.searchController.fields;


    const renderDropDownInput = (dropDownController: IDropDownController) => {
        return (
            <PassengersTypesDropDownInputComponent dropDownController={dropDownController} searchController={services.flightSearch.searchController}/>
        );
    }

    const renderDropDownContent = (dropDownController: IDropDownController) => {
        return (
            <PassengersTypesDropDownListComponent dropDownController={dropDownController}/>
        );
    }

    const renderIcon = () => {
        return (
            <FlightSearchDropDownIconComponent icon={<PersonGroupNotFilledIcon size={1.2}/>}/>
        )
    }

    return (
        <DropDownComponent renderLabel={() => services.language.translate('Passengers')}
                           renderIcon={renderIcon}
                           errorMessage={fields.passengers.error}
                           renderDropDownInput={renderDropDownInput}
                           renderDropDownContent={renderDropDownContent}
                           inputStyle={"insideBorder"}
                           isReadonly={props.isReadonly}
                           onClick={props.onClick}
                           hideChevron={true}
                           modalDialogOptions={{
                               breakpoints: [0.5, 0.7]
                           }} popperOptions={{
                               sameWidthAsElementToAttach: false,
                               placement: 'bottom'
                           }}/>
    )
})
