import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const ShootingStarIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.4} {...props} viewBox="0 0 29 29" ratio={29 / 29}>
            <g clipPath="url(#clip0_36113_34958)">
                <path
                    d="M17.9208 2.28257L15.249 4.95444C14.7257 5.47769 13.8796 5.47769 13.362 4.95444C12.8443 4.4312 12.8387 3.58511 13.362 3.06743L16.0283 0.38999C16.5515 -0.133252 17.3976 -0.133252 17.9153 0.38999C18.433 0.913232 18.4385 1.75933 17.9153 2.277L17.9208 2.28257ZM9.98315 8.76186C10.473 7.76548 11.8924 7.76548 12.3823 8.76186L14.9595 13.9943L20.7207 14.8348C21.8173 14.9962 22.2515 16.3377 21.4611 17.1115L17.2918 21.1861L18.2771 26.9362C18.4664 28.0272 17.3197 28.8622 16.34 28.3445L11.1855 25.6281L6.02544 28.3445C5.04575 28.8622 3.89907 28.0272 4.08833 26.9362L5.07358 21.1861L0.898776 17.1115C0.108346 16.3377 0.548092 14.9907 1.63911 14.8348L7.40034 13.9943L9.97758 8.76186H9.98315ZM28.6083 11.0775C29.1316 11.6007 29.1316 12.4468 28.6083 12.9645L25.9365 15.6364C25.4132 16.1596 24.5671 16.1596 24.0495 15.6364C23.5318 15.1131 23.5262 14.267 24.0495 13.7494L26.7213 11.0775C27.2446 10.5542 28.0907 10.5542 28.6083 11.0775ZM28.6083 2.28257L20.5927 10.2982C20.0695 10.8214 19.2234 10.8214 18.7057 10.2982C18.188 9.77495 18.1825 8.92886 18.7057 8.41118L26.7158 0.38999C27.239 -0.133252 28.0851 -0.133252 28.6028 0.38999C29.1205 0.913232 29.126 1.75933 28.6028 2.277L28.6083 2.28257Z"
                    fill="#FFF0B5"/>
            </g>
            <defs>
                <clipPath id="clip0_36113_34958">
                    <rect width="28.5" height="28.5" fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>

        </SvgIcon>
    )
}
