import React from "react";
import styled , {css} from "styled-components";
import {IBundleViewModel} from "../../../../../services/booking/models/bundle/bundle-view-model.interface";
import {observer} from "mobx-react";
import {PriceComponent} from "../../../../../components/price/price.component";
import {useServices} from "../../../../../hooks/use-services.hook";

const BundleHeaderBox = styled.div<{$backgroundColor: string; $textColor: string; $isSelected: boolean, $isBasicBundle?: boolean}>`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    background-color: ${props => props.$backgroundColor};
    color: ${props => props.$textColor};
    padding: ${props => props.theme.spacing.spacing16};
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    border-bottom: 1px solid ${props => props.theme.border.mainColor};
    ${
        props => props.$isSelected 
                ? css`
                  border-top-left-radius: ${props => props.theme.border.radius(0.5)}px;
                  border-top-right-radius: ${props => props.theme.border.radius(0.5)}px;
                `
                : css`
                  border-top-left-radius: ${props => props.theme.border.defaultRadius};
                  border-top-right-radius: ${props => props.theme.border.defaultRadius};
                `
    }
`

const BundleNameBox = styled.span`
    flex-grow: 1;
`

const CustomerFavouriteBox = styled.div`
    position: absolute;
    background-color: ${props => props.theme.colors.cardLabelBackgroundWarring};
    color: ${props => props.theme.colors.cardLabelColorWarning};
    font-weight: ${props => props.theme.fontWeight.regular};
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: ${props => props.theme.fontSize.caption};
    padding: 6px ${props => props.theme.spacing.spacing12};
    border-radius: 20px;
    width: fit-content;
    
`

const PriceBox = styled(PriceComponent)`
    font-size: ${props => props.theme.fontSize.h2};
`

interface BundleHeaderComponentProps {
    bundle: IBundleViewModel;
}


export const BundleHeaderComponent: React.FC<BundleHeaderComponentProps> = observer((props) => {
    const services = useServices();
    const bundle = props.bundle;

    const renderCustomerFavourite = () => {
        if(!bundle.bundleType.isFavouriteBundle) {
            return null;
        }

        return (
            <CustomerFavouriteBox>
                {services.language.translate('Customer Favourite')}
            </CustomerFavouriteBox>
        );
    }


    return (
        <BundleHeaderBox  $backgroundColor={props.bundle.bundleType.getHeaderBackgroundColor()}
                          $textColor={props.bundle.bundleType.getHeaderColor()}
                          $isSelected={bundle.isSelected}
                          $isBasicBundle={bundle.bundleType.isBasicBundle}>
            {renderCustomerFavourite()}
            <BundleNameBox>
                {bundle.bundleType.bundleName}
            </BundleNameBox>
            <PriceBox price={props.bundle.pricePerPassenger} label="+" />
        </BundleHeaderBox>)

});
