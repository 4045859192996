import React from "react";
import styled from "styled-components";

export const TitleContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap8};
`

export const TitleTextBox = styled.div`
`

interface FlightSearchTitleComponentProps {
    title: string;
    icon?: React.ReactNode;
}
export const FlightSearchTitleComponent: React.FC<FlightSearchTitleComponentProps> = (props) => {
    return (
        <TitleContainerBox>
            {props.icon}
            <TitleTextBox>
                {props.title}
            </TitleTextBox>
        </TitleContainerBox>
    );
}
