import React from "react";
import {observer} from "mobx-react";
import {StringFieldEditorComponent} from "../forms/string-field/string-field-editor.component";
import {PasswordFieldEditorComponent} from "../forms/password/password-field-editor.component";
import {FormComponent} from "../forms/form.component";
import {FormFields} from "../../models/forms/form-field.interface";
import {IRegistrationFormFields} from "../../services/user/models/registration/registration-form-fields.interface";
import {EmailFieldEditorComponent} from "../forms/contact/email-field-editor.component";
import {DateFieldEditorComponent} from "../forms/date-time/date-field-editor.component";
import {GenderFieldEditorComponent} from "../forms/gender/gender-field-editor.component";
import styled from "styled-components";
import {BooleanFieldEditorComponent} from "../forms/boolean-field/boolean-field-editor.component";
import {useServices} from "../../hooks/use-services.hook";

const FormComponentSubtitleBox = styled.div`
    color: ${props => props.theme.colors.medium};
    font-size: ${props => props.theme.fontSize.body2};
    text-align: center;
    
    margin-bottom: 15px;
    letter-spacing: 1px;
`
interface RegisterFormComponentProps {
    registrationFormFields: FormFields<IRegistrationFormFields>;
}
export const RegistrationFormComponent: React.FC<RegisterFormComponentProps> = observer((props) => {
    const services = useServices();
    const fields = props.registrationFormFields;
    const time = services.time;

    const maxBirthDate = time.addYears(time.formatBirthDate(time.currentDate), -18);

    return (
        <FormComponent>
            <FormComponentSubtitleBox>
                {services.language.translate('Fill in the form below in order to create an account.')}
            </FormComponentSubtitleBox>
            <StringFieldEditorComponent field={fields.firstName}/>
            <StringFieldEditorComponent field={fields.lastName}/>
            <EmailFieldEditorComponent field={fields.emailAddress}/>
            <PasswordFieldEditorComponent field={fields.password}/>
            <DateFieldEditorComponent field={fields.dateOfBirth}
                                      maximumValue={maxBirthDate}/>
            <GenderFieldEditorComponent field={fields.gender} useGenderAsNumber={false}/>
            <BooleanFieldEditorComponent field={fields.acceptsPromotionalMessages}/>
            <BooleanFieldEditorComponent field={fields.acceptsTermsAndConditions}
                                         labelHtml={services.externalLinks.registrationTermsAndConditions}/>
        </FormComponent>
    )
});


