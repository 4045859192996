import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {JourneyBundlesComponent} from "../components/bundle/journey-bundles.component";
import {useContainerMediaQueriesChecks} from "../../../../hooks/use-container-media-queries-checks.hook";

export const ReturnFlightBundlesComponent: React.FC = observer(() => {
    const services = useServices();
    const mediaQuery = useContainerMediaQueriesChecks();

    const selectedDepartureJourneyBundle = services.booking.current.departureJourney?.selectedBundle;
    const returnJourney = services.booking.current.returnJourney;
    if(!returnJourney || !selectedDepartureJourneyBundle) {
        return null;
    }

    let availableBundles = services.bundleTypes.sortBundlesByDisplayOrder(returnJourney.bundlesAvailability);

    if(mediaQuery.smallScreen) {
        availableBundles = [
            ...availableBundles.filter(b => b.bundleCode === selectedDepartureJourneyBundle.bundleCode),
            ...availableBundles.filter(b => b.bundleCode !== selectedDepartureJourneyBundle.bundleCode),
        ];
    }


    return (
        <JourneyBundlesComponent journeyDesignator={returnJourney.designator}
                                 availableBundles={availableBundles}
                                 isOnReturnJourney={true}/>
    );
});
