import { observer } from "mobx-react";
import React from "react";
import {NullableString} from "../../../types/nullable-types";
import {AlertBoxComponent, AlertBoxSize} from "../../../components/alert-box/alert-box.component";

export const SsrModificationBlockingReasonComponent:React.FC<{reason: NullableString; size?: AlertBoxSize; className?: string}> = observer((props) => {

    if(!props.reason) {
        return null;
    }

    return (
        <AlertBoxComponent severity={"info"}
                           fullWidth={true}
                           size={props.size ?? "small"}
                           title={props.reason}
                           className={props.className}>

        </AlertBoxComponent>
    );
});
