import React, {useState} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../../../hooks/use-services.hook";
import {APP_VERSION} from "../../../../version";
import {HybridTestModeToggleDialogComponent} from "./hybrid-test-mode-toggle-dialog.component";
import {DialogCloseButtonBehavior} from "../../../../services/dialog/dialog-enums";

const MAX_VERSION_TAP_COUNT = 7;

const ContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap16};
`

const VersionValueBox = styled.div`
    color: ${props => props.theme.colors.medium};
`

export const HybridAppVersionComponent: React.FC = observer(() => {
    const services = useServices();
    const [versionTapCount, setVersionTapCount] = useState(0);

    const onVersionClick = async () => {


        if(services.layout.shouldUseWebappLayout) {
            return;
        }


        if(services.configuration.isHybridTestModeEnabled) {
            return;
        }

        if(versionTapCount < MAX_VERSION_TAP_COUNT) {
            setVersionTapCount(versionTapCount + 1);
        } else {
            await services.dialog.showPopupDialog({
                closeButtonBehavior: DialogCloseButtonBehavior.Reject,
                maxHeight: "460px",
                render: dialogHandler => (<HybridTestModeToggleDialogComponent dialogHandler={dialogHandler}/>)
            });
        }
    }

    return (
        <ContainerBox>
            <VersionValueBox onClick={onVersionClick}>
                {services.language.translationFor('App version - {version}').withParams({
                    version: APP_VERSION
                })}
            </VersionValueBox>
        </ContainerBox>
    )
})