import React, {PropsWithChildren} from 'react';
import styled, {css} from "styled-components";
import {observer} from "mobx-react";
import {IonIcon} from "@ionic/react";
import {chevronBackOutline, chevronForwardOutline} from "ionicons/icons";

type IconSize = 'small' | 'large';

const NavigationButtonBox = styled.div<{$isVisible: boolean; $disabled: boolean; $iconSize: IconSize}>`
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: ${props => props.$isVisible ? "visible": "hidden"};
    font-size: 30px;
    ${props => props.$disabled
        ? css`
                cursor: not-allowed;
                color: ${props => props.theme.colors.medium};
            `
        : css`
                cursor: pointer;
            `
        }
    ${props => props.$iconSize === 'large'
            ? css`
                font-size: 26px;
            `
            : css`
                font-size: 20px;
            `
    }
`

interface NavigationButtonComponentProps extends PropsWithChildren {
    isVisible: boolean;
    disabled: boolean;
    iconSize: IconSize;
    onClick?: () => void;
}

const NavigationButtonComponent: React.FC<NavigationButtonComponentProps> = observer((props) => {
    return (
        <NavigationButtonBox $isVisible={props.isVisible} $disabled={props.disabled} onClick={props.onClick} $iconSize={props.iconSize}>
            {props.children}
        </NavigationButtonBox>
    );
})

export const BackNavigationButtonComponent: React.FC<NavigationButtonComponentProps> = observer((props) => {
    return (
        <NavigationButtonComponent {...props}>
            <IonIcon icon={chevronBackOutline}/>
        </NavigationButtonComponent>
    );
})

export const ForwardNavigationButtonComponent: React.FC<NavigationButtonComponentProps> = observer((props) => {
    return (
        <NavigationButtonComponent {...props}>
            <IonIcon icon={chevronForwardOutline}/>
        </NavigationButtonComponent>
    );
})
