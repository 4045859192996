import {AirlineTheme} from "../../services/theme/interfaces/airline.theme";

export type SpacerSize = 'small' | 'medium' | 'large' | 'extraLarge';

export function computeSpacerSize(size: SpacerSize | undefined, theme: AirlineTheme): string {
    switch (size) {
        case "small":
            return theme.spacing.spacing8;
        case "medium":
            return theme.spacing.spacing12;
        case "extraLarge":
            return theme.spacing.spacing40;
        default:
            return theme.spacing.spacing20;
    }
}
