import React from "react";
import {observer} from "mobx-react";
import {PhoneNumberInputComponentProps} from "./phone-number-input.component.props";
import {PhoneNumberInputOutsideBordersComponent} from "./phone-number-input.outside-borders.component";
import {PhoneNumberInputInsideBordersComponent} from "./phone-number-input.inside-borders.component";


export const PhoneNumberInputComponent: React.FC<PhoneNumberInputComponentProps> = observer((props) => {
    if(props.inputStyle === "insideBorder") {
        return (
            <PhoneNumberInputInsideBordersComponent {...props}/>
        );

    } else {
        return (
            <PhoneNumberInputOutsideBordersComponent {...props}/>
        )
    }

})