import  React from 'react';
import {IFormField} from "../../../../models/forms/form-field.interface";
import {observer} from "mobx-react-lite";
import {NullableNumber} from "../../../../types/nullable-types";
import {useServices} from "../../../../hooks/use-services.hook";
import {StandardDropDownListItemModel} from "../../standard-drop-down/list-view/models/item/standard-drop-down-list-item.model";
import {StandardDropDownComponent} from "../../standard-drop-down/standard-drop-down.component";
import {FieldEditorCommonProps} from "../../inputs-common.props";
import {Check} from "../../../../types/type-checking";

interface DayFieldEditorComponentProps extends FieldEditorCommonProps {
    field: IFormField<number>;
    month: NullableNumber;
    year: NullableNumber;
    minDay?: number;
    maxDay?: number;
    placeholder?: string;
}

export const DayFieldEditorComponent: React.FC<DayFieldEditorComponentProps> = observer((props) => {
    const services = useServices();
    let items: StandardDropDownListItemModel<number>[] = [];
    if(props.month && props.year) {
        let days = services.time.getDateRange(new Date(props.year, props.month - 1, 1), services.time.lastDateOfTheMonth(props.year, props.month))
                                .map(date => date.getDate());

        if(!Check.isNullOrUndefined(props.minDay)) {
            days = days.filter(d => d >= props.minDay!);
        }

        if(!Check.isNullOrUndefined(props.maxDay)) {
            days = days.filter(d => d <= props.maxDay!);
        }

        items = days.map(day => new StandardDropDownListItemModel<number>(day, day.toString().padStart(2, '0')));
    }

    return (
        <StandardDropDownComponent {...props}
                                   field={props.field}
                                   items={items}
                                   modalDialogOptions={{
                                       renderTitle: () => services.language.translate('Day'),
                                       hideSearchBar: true
                                   }}
                                   placeholder={props.placeholder}/>
    );

});
