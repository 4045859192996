import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {IPassengerAllBagsViewModel} from "../../../../../services/booking/models/ssrs/bags/passenger-all-bags-view-model.interface";
import {SmallCabinBagComponent} from "./small-cabin-bag/small-cabin-bag-component";
import {PriorityBoardingComponent} from "./priority-boarding/priority-boarding.component";
import {CabinBagsInfoComponent} from "./cabin-bags-info/cabin-bags-info.component";
import {BagsSelectionContainerBox} from "../../boxes/bags-selection-container.box";
import {CabinBagMustChooseAnOptionComponent} from "./must-choose-an-option/cabin-bag-must-choose-an-option.component";
import {useContainerMediaQueriesChecks} from "../../../../../hooks/use-container-media-queries-checks.hook";
import {BagsSelectionStepBulletComponent} from "../bags-selection-step-bullet/bags-selection-step-bullet.component";

const CabinBagsSelectionContainerBox = styled(BagsSelectionContainerBox)`
    border-bottom: 1px solid ${props => props.theme.colors.mediumShade};
`


const CabinBagsGridBox = styled.div<{$gridTemplateColumn: string}>`
    display: grid;
    grid-template-columns: ${props => props.$gridTemplateColumn} ;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap20};
    
`

const MustChooseAnOptionForSmallScreenBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    
`

const StepBulletContainerBox = styled.div`
    position: absolute;
    left: 0;
    transform: translateX(-50%) translateY(-4px);
`

interface CabinBagsSelectionComponentProps {
    passengerBags: IPassengerAllBagsViewModel;
}

export const PassengerCabinBagsSelectionComponent: React.FC<CabinBagsSelectionComponentProps> = observer((props) => {
    const containerMediaQuery = useContainerMediaQueriesChecks();
    const isInSmallScreen = containerMediaQuery.smallScreen;
    const cabinBag = props.passengerBags.cabinBag;

    const hasError = Boolean(props.passengerBags.cabinBagErrorMessage);

    let shouldRenderSmallCabinBag = cabinBag.quantityIncludedInBundle === 0;

    if(cabinBag.newQuantity > 0 && cabinBag.minAllowedQuantity > 0) {
        shouldRenderSmallCabinBag = false;
    }


    const captureElementRef = (el: any) => {
        props.passengerBags.attachCabinBagsContainerDOMElement(el);
    }

    const getGridTemplateColumn = () => {

        if(isInSmallScreen) {
            return '1fr';
        }

        if(shouldRenderSmallCabinBag) {
            return '1fr 1.2fr 1.5fr';
        }

        return '1fr 2fr';
    }

    const renderMustChooseAnOptionForSmallScreen = () => {
        if(!isInSmallScreen) {
            return null;
        }

        return (
            <MustChooseAnOptionForSmallScreenBox>
                <CabinBagMustChooseAnOptionComponent ssrEditor={cabinBag} hasError={hasError}/>
            </MustChooseAnOptionForSmallScreenBox>
        )
    }

    const renderStepBullet = () => {
        return (
            <StepBulletContainerBox>
                <BagsSelectionStepBulletComponent stepNumber={1} hasError={hasError}/>
            </StepBulletContainerBox>
        );
    }

    return (
        <CabinBagsSelectionContainerBox>
            {renderStepBullet()}
            {isInSmallScreen && (<CabinBagsInfoComponent ssrEditor={cabinBag} hasError={hasError}/>)}
            <CabinBagsGridBox ref={captureElementRef} $gridTemplateColumn={getGridTemplateColumn()}>
                {!isInSmallScreen && (<CabinBagsInfoComponent ssrEditor={cabinBag} hasError={hasError}/>)}
                <PriorityBoardingComponent ssrEditor={cabinBag} willRenderSmallCabinBag={shouldRenderSmallCabinBag}/>
                {shouldRenderSmallCabinBag && <SmallCabinBagComponent ssrEditor={cabinBag}/>}
            </CabinBagsGridBox>
            {renderMustChooseAnOptionForSmallScreen()}
        </CabinBagsSelectionContainerBox>

    );
});
