import React from 'react';
import {observer} from "mobx-react";
import {IPassengerTypeSelector} from "../../services/passenger-types/passengers-types.service.interface";
import styled from 'styled-components';
import {CardBox} from "../../basic-components/card/card.box";
import {
    QuantityDecrementButtonComponent,
    QuantityIncrementButtonComponent
} from "../quantity-edit-buttons/quantity-edit-buttons";

const PassengerTypeBox = styled(CardBox)`
    display: grid;
    grid-template-columns: 1fr auto 20px auto;
    row-gap: ${props => props.theme.spacing.flexGap20};
    column-gap: ${props => props.theme.spacing.flexGap8};
    align-items: center;
`

const PassengerTypeDetailsBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`

const PassengerTypeDescriptionBox = styled.div`
    width: 100%;
    font-size: ${props => props.theme.fontSize.h3};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

const PassengerTypeAgeLimitDescriptionBox = styled.div`
    width: 100%;
    font-size: ${props => props.theme.fontSize.h6};
    color: ${props => props.theme.colors.medium};
`

export const NumberOfPassengersBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: ${props => props.theme.fontSize.h1};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

interface PassengerCountSelectorComponentProps {
    passengerType: IPassengerTypeSelector;
}

export const PassengerTypeSelectorComponent: React.FC<PassengerCountSelectorComponentProps> = observer((props) => {

    const increment = () => {
        props.passengerType.count++;
    }

    const decrement = () => {
        props.passengerType.count--;
    }

    return (
        <PassengerTypeBox>
            <PassengerTypeDetailsBox>
                <PassengerTypeDescriptionBox>
                    {props.passengerType.description}
                </PassengerTypeDescriptionBox>
                <PassengerTypeAgeLimitDescriptionBox>
                    {props.passengerType.ageLimitsDescription}
                </PassengerTypeAgeLimitDescriptionBox>
            </PassengerTypeDetailsBox>


            <QuantityDecrementButtonComponent onClick={decrement} disabled={!props.passengerType.allowDecrement}/>


            <NumberOfPassengersBox>{props.passengerType.count}</NumberOfPassengersBox>

            <QuantityIncrementButtonComponent disabled={!props.passengerType.allowIncrement}
                                              onClick={increment}/>



        </PassengerTypeBox>
    )
});
