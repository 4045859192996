import React from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {renderInputError, renderInputIcon, renderInputLabel} from "./input-container.helpers";
import {InputContainerComponentProps} from "../inputs-common.props";

const INPUT_CONTAINER_BOX_VERTICAL_SHIFT = '6px';


const RootContainerBox = styled.div<{$hasError: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: inherit;
    color: inherit;
    width: 100%;
    padding: ${props => props.theme.spacing.spacing12} 
             ${props => props.theme.spacing.spacing16} 
             ${props => props.theme.spacing.spacing12} 
             ${props => props.theme.spacing.spacing20};
    border-radius: ${props => props.theme.border.defaultRadius};
    gap: ${props => props.theme.spacing.flexGap12};
    
    ${props => props.$hasError 
        ? css`
            color: ${props => props.theme.colors.danger};
            border: 1px solid ${props => props.theme.colors.danger};    
        `
        : css`
            border: 1px solid ${props => props.theme.border.mainColor};
            &:focus-within {
                border-color: ${props => props.theme.border.focusedColor};
            }
        `
    }
`


const ContentSectionBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    width: 100%;
`

const InputContainerBox = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: -${INPUT_CONTAINER_BOX_VERTICAL_SHIFT};
    font-size: ${props => props.theme.fontSize.h4};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.colors.dark};
    
`


const LabelContainerBox = styled.div`
    font-size: ${props => props.theme.fontSize.caption};
    font-weight: ${props => props.theme.fontWeight.medium};
    color: ${props => props.theme.colors.medium};
`

const ErrorContainerBox =  styled.div`
    width: 100%;
    color: ${props => props.theme.colors.danger};
    font-size: ${props => props.theme.fontSize.body2};
`

const IconContainerBox = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding-bottom: ${INPUT_CONTAINER_BOX_VERTICAL_SHIFT};
    height: 100%;
    
    
`

interface InputContainerInsideBordersStyleComponentProps extends Omit<InputContainerComponentProps, 'inputStyle'> {

}

export const InputContainerInsideBordersStyleComponent: React.FC<InputContainerInsideBordersStyleComponentProps> = observer((props) => {
    const hasError = Boolean(props.errorMessage);
    const specificCssClasses = props.cssClassesForInsideBorders;
    const commonCssClasses = props.cssClasses;
    return (
        <RootContainerBox ref={props.captureInputContainerRef}
                           onClick={props.onClick}
                           $hasError={hasError}
                           className={props.className}>
            {renderInputIcon(props, IconContainerBox)}

            <ContentSectionBox className={specificCssClasses?.contentSectionContainer}>
                {renderInputLabel(props, LabelContainerBox)}
                <InputContainerBox className={commonCssClasses?.inputContainer} ref={props.captureInputRef}>
                    {props.renderInput(hasError)}
                </InputContainerBox>
                {renderInputError(props, ErrorContainerBox)}
            </ContentSectionBox>

        </RootContainerBox>
    );
})