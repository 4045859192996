import React from "react";
import {observer} from "mobx-react";
import {IFormField} from "../../../models/forms/form-field.interface";
import {NullableString} from "../../../types/nullable-types";
import {StringFieldEditorComponent} from "../string-field/string-field-editor.component";
import {FieldEditorCommonProps} from "../inputs-common.props";


interface PersonNameFieldEditorComponentProps extends FieldEditorCommonProps {
    field: IFormField<NullableString>;
    placeholder?: NullableString;
    className?: string;
}


export const PersonNameFieldEditorComponent: React.FC<PersonNameFieldEditorComponentProps> = observer((props) => {


    return (

        <StringFieldEditorComponent {...props}/>
    )
});


