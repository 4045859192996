import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {CardBox} from "../../../../../basic-components/card/card.box";

const ContainerBox = styled(CardBox)`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 0;
    padding: ${props => props.theme.spacing.spacing24} ${props => props.theme.spacing.spacing16};
    gap: ${props => props.theme.spacing.spacing20};

    &:nth-child(even) {
        border-top: none;
    }
    
    &:first-of-type {
        border-top-left-radius: ${props => props.theme.border.defaultRadius};
        border-top-right-radius: ${props => props.theme.border.defaultRadius};
    }

    &:last-of-type {
        border-bottom-left-radius: ${props => props.theme.border.defaultRadius};
        border-bottom-right-radius: ${props => props.theme.border.defaultRadius};
    }
    
    
`

const TitleAndDescriptionBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: ${props => props.theme.spacing.spacing8};
`

const TitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

const DescriptionBox = styled.div`
    font-size: ${props => props.theme.fontSize.subtitle1};
    font-weight: ${props => props.theme.fontWeight.medium};
`

const IconBox = styled.div`
    color: ${props => props.theme.colors.dangerTint};
`



interface SeatSelectionReasonComponentProps {
    title: string;
    description: string;
    icon: React.ReactElement;
}

export const SeatSelectionReasonComponent: React.FC<SeatSelectionReasonComponentProps> = observer((props) => {
    return (
        <ContainerBox>
            <TitleAndDescriptionBox>
                <TitleBox>
                    {props.title}
                </TitleBox>
                <DescriptionBox>
                    {props.description}
                </DescriptionBox>
            </TitleAndDescriptionBox>

            <IconBox>
                {props.icon}
            </IconBox>

        </ContainerBox>
    )
});