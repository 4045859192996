import {ExtrasSsrsSectionRenderer} from "../../base-renderers/extras-ssrs-section.renderer";
import {IBookingViewModel} from "../../../../../services/booking/models/booking-view-model.interface";
import {NullableString} from "../../../../../types/nullable-types";
import React from "react";

export class SpecialEquipmentExtrasSectionRenderer extends ExtrasSsrsSectionRenderer {
    constructor(booking: IBookingViewModel) {
        super(booking.services.ssrTypes.getSpecialEquipmentSsrTypes(), booking);
    }

    getTitle(): string {
        return this.services.language.translate(`Special Equipment`);
    }

    protected _renderDialogSubtitle(): NullableString | React.ReactElement {
        //return this.services.language.translate('Please be sure your items follow our special items guidelines');
        return null;
    }

    protected _getExtrasLargeTilePicture(): NullableString {
        return this.services.theme.currentTheme.assets.images.extras.sporting_equipment_2x_jpg.toString();
    }

    protected _getMarketingMessage(): NullableString {
        return this.services.language.translate('You know that feeling when you rent sporting gear? Why risk that, for a low price take yours!');
    }
}
