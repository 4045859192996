import React from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {IBookingInsuranceViewModel} from "../../../../services/booking/models/insurance/booking-insurance-view-model.interface";
import {ExternalLinkComponent} from "../../../../components/external-link/external-link.component";
import {useServices} from "../../../../hooks/use-services.hook";
import {XCoverLogoIcon} from "../../../../assets/svg/xcover-logo.icon";


const DisclaimerContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: ${props => props.theme.fontSize.body2};
    padding: 0 8px;
    gap: 16px;
`

const DisclaimerTextBox = styled.p`
    color: ${props => props.theme.colors.mediumTint};
    text-align: justify;
`

const InsuranceDisclaimerLogo = styled.div`
    display: inline-block;
    float: right;
    margin: ${props => props.theme.spacing.spacing8} 0 0 ${props => props.theme.spacing.spacing16};
    padding: ${props => props.theme.spacing.spacing16};
    background-color: ${props => props.theme.colors.lightTint};
    border-radius: ${props => props.theme.border.defaultRadius};

    body[color-scheme=dark] & {
        background-color: rgba(${props => props.theme.colors.secondaryContrastRgb}, 0.75);
    }
`

export const InsuranceDisclaimerComponent: React.FC<{insurance: IBookingInsuranceViewModel}> = observer((props) => {
    const services = useServices();
    return (
        <DisclaimerContainerBox>
            <DisclaimerTextBox>
                <InsuranceDisclaimerLogo>
                    <XCoverLogoIcon size={3.6} sizeIsHeight color={services.theme.currentTheme.colors.dark}/>
                </InsuranceDisclaimerLogo>
                {props.insurance.quote.disclaimer}
                <ExternalLinkComponent innerHtml={services.externalLinks.insuranceTermsAndConditionsConfirmation(props.insurance.quote.pdsUrl)}/>
            </DisclaimerTextBox>
        </DisclaimerContainerBox>
    )
});
