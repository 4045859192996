import {Station} from "../../../stations/station.service.interface";
import {IFlightDesignatorViewModel} from "./flight-designator-view-model.interface";
import {IServiceFactory} from "../../../service-factory.interface";
import {TimeSpan} from "../../../../types/time-span";
import {
    IDotRezJourney, IDotRezLeg,
    IDotRezSegment
} from "../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {TimePeriod} from "../../../../types/time-period";
import {FlightIdentifierModel} from "../identifier/flight-identifier.model";

export function composeDesignatorUniqueKey(originStationCode: string, destinationStationCode: string): string {
    return originStationCode + destinationStationCode;
}

export class FlightDesignatorModel implements IFlightDesignatorViewModel {
    constructor(private readonly services: IServiceFactory,
                public readonly origin: Station,
                public readonly destination: Station,
                rawDepartureDate: string,
                rawArrivalDate: string,
                rawDepartureDateUtc: string,
                rawArrivalDateUtc: string,
                public readonly innerDesignators: FlightDesignatorModel[],
                public readonly flightIdentifier: FlightIdentifierModel | null) {

        this.departureDate = services.time.parseIsoDate(rawDepartureDate);
        this.arrivalDate = services.time.parseIsoDate(rawArrivalDate);
        this.departureDateUtc = services.time.parseIsoDate(rawDepartureDateUtc);
        this.arrivalDateUtc = services.time.parseIsoDate(rawArrivalDateUtc);
    }


    static createJourneyDesignator(journeyDotRezData: IDotRezJourney, services: IServiceFactory): FlightDesignatorModel {

        const allLegs = journeyDotRezData.segments.selectMany(s => s.legs);

        return new FlightDesignatorModel(
            services,
            services.stations.getStation(journeyDotRezData.designator.origin),
            services.stations.getStation(journeyDotRezData.designator.destination),
            journeyDotRezData.designator.departure,
            journeyDotRezData.designator.arrival,
            allLegs[0].legInfo.departureTimeUtc,
            allLegs[allLegs.length - 1].legInfo.arrivalTimeUtc,
            journeyDotRezData.segments.map(s => FlightDesignatorModel.createSegmentDesignator(s, services)),
            null);
    }

    static createSegmentDesignator(segmentDotRezData: IDotRezSegment, services: IServiceFactory): FlightDesignatorModel {

        return new FlightDesignatorModel(
            services,
            services.stations.getStation(segmentDotRezData.designator.origin),
            services.stations.getStation(segmentDotRezData.designator.destination),
            segmentDotRezData.designator.departure,
            segmentDotRezData.designator.arrival,
            segmentDotRezData.legs[0].legInfo.departureTimeUtc,
            segmentDotRezData.legs[segmentDotRezData.legs.length - 1].legInfo.arrivalTimeUtc,
            segmentDotRezData.legs.map(l => FlightDesignatorModel.createLegDesignator(l, services)),
            new FlightIdentifierModel(segmentDotRezData.identifier.carrierCode, segmentDotRezData.identifier.identifier, services));
    }

    static createLegDesignator(legDotRezData: IDotRezLeg, services: IServiceFactory): FlightDesignatorModel {
        return new FlightDesignatorModel(
            services,
            services.stations.getStation(legDotRezData.designator.origin),
            services.stations.getStation(legDotRezData.designator.destination),
            legDotRezData.designator.departure,
            legDotRezData.designator.arrival,
            legDotRezData.legInfo.departureTimeUtc,
            legDotRezData.legInfo.arrivalTimeUtc,
            [],
            new FlightIdentifierModel(legDotRezData.legInfo.operatingCarrier, legDotRezData.legInfo.operatingFlightNumber, services));
    }

    public readonly departureDate: Date;
    public readonly arrivalDate: Date;
    public readonly departureDateUtc: Date
    public readonly arrivalDateUtc: Date;

    get uniqueKey(): string {
        return composeDesignatorUniqueKey(this.origin.stationCode, this.destination.stationCode);
    }

    formatDepartureDate() {
        return this.services.time.formatUserFriendlyDate(this.departureDate);
    }

    formatDepartureTime() {
        return this.services.time.formatHHmm(this.departureDate);
    }

    formatArrivalDate() {
        return this.services.time.formatUserFriendlyDate(this.arrivalDate);
    }

    formatArrivalTime() {
        return this.services.time.formatHHmm(this.arrivalDate);
    }

    get numberOfStops(): number {
        return Math.max(0, this.innerDesignators.length - 1);
    }

    get isDirectFlight(): boolean {
        return this.numberOfStops === 0;
    }

    formatNumberOfStops() {
        if(this.numberOfStops === 0) {
            return this.services.language.translate('Direct Flight');
        }

        if(this.numberOfStops === 1) {
            return this.services.language.translate('One stop');
        }

        return this.services.language.translationFor('{numberOfStops} stops').withParams({numberOfStops: this.numberOfStops});
    }

    isMatch(originStationCode: string, destinationStationCode: string): boolean {
        return this.origin.stationCode === originStationCode && this.destination.stationCode === destinationStationCode;
    }

    get travelTime(): TimeSpan {
        return TimeSpan.fromMilliseconds (this.arrivalDateUtc.getTime() -  this.departureDateUtc.getTime());
    }

    toString(): string {
        return `${this.origin.stationCode} --> ${this.destination.stationCode}`;
    }

    toUserFriendlyString(): string {
        return `${this.origin.stationName} - ${this.destination.stationName}  ${this.services.time.formatUserFriendlyDate(this.departureDate)} ${this.services.time.formatHHmm(this.departureDate)}`;
    }

    get allowsOnlineCheckIn(): boolean {
        return this.origin.isOnlineCheckInAllowed(this.destination);
    }

    arrivalOnNextDay(): boolean {
        return this.departureDate.getDay() !== this.arrivalDate.getDay();

    }

    overlaps(otherDesignator: FlightDesignatorModel): boolean {
        const currentPeriod = new TimePeriod(this.departureDate, this.arrivalDate);
        const otherPeriod = new TimePeriod(otherDesignator.departureDate, otherDesignator.arrivalDate);

        return currentPeriod.intersect(otherPeriod);
    }

}



