import React, {CSSProperties} from "react";
import {useServices} from "../../../hooks/use-services.hook";
import {observer} from "mobx-react";

interface TicketRecordLocatorComponentProps {
    recordLocator: string;
}
export const TicketRecordLocatorComponent: React.FC<TicketRecordLocatorComponentProps> = observer((props) => {
    const services = useServices();
    const styles: CSSProperties = {
        fontSize: services.theme.currentTheme.fontSize.h1,
        fontWeight: services.theme.currentTheme.fontWeight.bold,
    };

    return (
        <div style={styles}>
            {services.language.translate('Booking code:') + ' ' + props.recordLocator}
        </div>
    )
})