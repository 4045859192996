import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";

const BottomSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    padding: ${props => props.theme.spacing.pageContentVerticalPadding} 
             ${props => props.theme.spacing.pageContentHorizontalPadding};
`

interface HomePageHybridContentBottomSectionComponentProps extends PropsWithChildren {
    className?: string;
}
export const HomePageHybridContentBottomSectionComponent: React.FC<HomePageHybridContentBottomSectionComponentProps> = observer((props) => {
    return (
        <BottomSectionBox className={props.className}>
            {props.children}
        </BottomSectionBox>
    )
});