import React from "react";
import {
    IPassengerSsrEditorViewModel
} from "../../../../../../services/booking/models/ssrs/passenger-ssr-editor-view-model.interface";
import {observer} from "mobx-react";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {PriceWithDiscountComponent} from "../../../../../../components/price/price-with-discount.component";
import {StandardPriceLabelComponent} from "../../../../../../components/price/standard-price-label.component";
import {BagNameBox} from "../boxes/bag-name.box";
import {BagContainerComponent} from "../../bag-container/bag-container-component";
import {BagDetailsComponent} from "../../bag-details/bag-details.component";
import styled from "styled-components";
import {ReadonlyCheckboxComponent} from "../../../../../../basic-components/checkbox/readonly-checkbox.component";
import {
    SsrModificationBlockingReasonComponent
} from "../../../../common-components/ssr-modification-blocking-reason.component";
import {CabinBagPriceContainerBox} from "../../../boxes/cabin-bag-price-container.box";
import {SmallCabinBag3dIconComponent} from "./small-cabin-bag-3d-icon.component";

const SmallBag3DIcon = styled(SmallCabinBag3dIconComponent)`
    margin-left: -20px;
    margin-bottom: -0.75rem;
`

const BagContainerBox = styled(BagContainerComponent)`
    justify-content: flex-end;
`

const TopSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: ${props => props.theme.spacing.spacing16};
    flex-grow: 1;
`

const PriceContainerBox = styled(CabinBagPriceContainerBox)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: flex-end;
    gap: ${props => props.theme.spacing.flexGap12};
    font-size: ${props => props.theme.fontSize.h2};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`



export const SmallCabinBagComponent: React.FC<{ssrEditor: IPassengerSsrEditorViewModel;}> = observer((props) => {
    const services = useServices();

    if(props.ssrEditor.isCompletelyIncludedInBundle) {
        return null;
    }



    const isSelected = props.ssrEditor.newQuantity === 0 && props.ssrEditor.newQuantityWasSet;
    const onClick = async () => {
        if(props.ssrEditor.canBeModifiedForAtLeastOneSegment) {
            props.ssrEditor.newQuantity = 0;
        }

    }

    return (
        <BagContainerBox isSelected={isSelected} onClick={onClick} isClickable={props.ssrEditor.canBeModifiedForAtLeastOneSegment}>
            <TopSectionBox>
                <SmallBag3DIcon/>
                <BagNameBox>
                    {services.language.translate('One under seat cabin bag')}
                </BagNameBox>
                <BagDetailsComponent>
                    40x30x20 cm, max 10 kg
                </BagDetailsComponent>
            </TopSectionBox>

            <PriceContainerBox>
                <PriceWithDiscountComponent discountedPrice={services.currency.createPrice(0)}
                                            standardPrice={services.currency.createPrice(0)}
                                            label={<StandardPriceLabelComponent/>}/>
                <ReadonlyCheckboxComponent checked={isSelected}/>
            </PriceContainerBox>
            <SsrModificationBlockingReasonComponent reason={props.ssrEditor.modificationBlockingReason}/>

        </BagContainerBox>
    );
});