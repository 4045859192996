import React, {PropsWithChildren} from 'react';
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {IonSpinnerComponent} from "../../../../../components/spinner/ion-spinner.component";

const ExportButtonBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  font-size: ${props => props.theme.fontSize.h1};
`

const SpinnerBox = styled(IonSpinnerComponent)`
    width: 100%;
    height: 100%;
`

export interface BoardingPassExportButtonComponentProps extends PropsWithChildren {
    isExportInProgress: boolean;
    onClick: () => void;
    className?: string;
}

export const BoardingPassExportButtonComponent: React.FC<BoardingPassExportButtonComponentProps> = observer((props) => {

    const renderChildren = () => {
        if(props.isExportInProgress) {
            return (<SpinnerBox/>);
        } else {
            return props.children;
        }
    }

    return (
        <ExportButtonBox onClick={props.onClick} className={props.className}>
            {renderChildren()}
        </ExportButtonBox>
    )
});
