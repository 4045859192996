import styled, {css} from "styled-components";
import {IonContent, ScrollDetail} from "@ionic/react";
import React, {PropsWithChildren, useState} from "react";
import {observer} from "mobx-react";

const StandardPageContentBox = styled(IonContent)<{$reduceTopPadding: boolean}>`
    --padding-top: ${props => props.theme.spacing.pageContentVerticalPadding};
    --padding-bottom: ${props => props.theme.spacing.pageContentVerticalPadding};
    --padding-start: ${props => props.theme.spacing.pageContentHorizontalPadding};
    --padding-end: ${props => props.theme.spacing.pageContentHorizontalPadding};
    --background: ${props => props.theme.colors.pageContentBackground};
    --color: ${props => props.theme.colors.pageContentColor};
    ${props => props.$reduceTopPadding
            ? css`
                --padding-top: 0;
            `
            : ''
    }
    &::part(scroll) {
        scrollbar-gutter: stable;
    }
`

interface StandardPageContentComponentProps extends PropsWithChildren {
    reducePaddingTopOnScroll?: boolean;
    className?: string;
    onScroll?: (event: CustomEvent<ScrollDetail>) => void;
}
export const StandardPageContentComponent: React.FC<StandardPageContentComponentProps> = observer((props) => {

    const [hasScroll, setHasScroll] = useState(false);

    const reducePaddingTopOnScroll = Boolean(props.reducePaddingTopOnScroll);
    const onScrollHandler = (event: CustomEvent<ScrollDetail>) => {
        setHasScroll(event.detail.scrollTop > 3);
        if(props.onScroll) {
            props.onScroll(event);
        }
    }

    return (
        <StandardPageContentBox $reduceTopPadding={hasScroll && Boolean(reducePaddingTopOnScroll)}
                                onIonScroll={onScrollHandler}
                                scrollEvents={reducePaddingTopOnScroll || Boolean(props.onScroll)}
                                className={props.className}>
            {props.children}
        </StandardPageContentBox>
    )
});