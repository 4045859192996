import React from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";

const BulletBox = styled.div<{$hasError: boolean}>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.medium};
    background-color: ${props => props.theme.colors.cardBackground};
    padding: 0;
    line-height: 1;
    
    ${props => props.$hasError 
            ? css`
                border: 1px solid ${props => props.theme.colors.danger};
                color: ${props => props.theme.colors.danger};
            `
            : css`
                border: 1px solid ${props => props.theme.colors.dark};
                color: ${props => props.theme.colors.dark};
            `
    }
    
    ${props => props.theme.containerMediaQuery.smallScreen} {
        width: 24px;
        height: 24px;
        font-size: ${props => props.theme.fontSize.h6};
    }
    
`

interface BagsSelectionStepBulletComponentProps {
    stepNumber: number;
    hasError: boolean;
}

export const BagsSelectionStepBulletComponent: React.FC<BagsSelectionStepBulletComponentProps> = observer((props) => {
    return (
        <BulletBox $hasError={props.hasError}>
            {props.stepNumber.toString()}
        </BulletBox>
    )
})