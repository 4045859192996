import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../../hooks/use-services.hook";
import {PointingHandIcon} from "../../../assets/svg/pointing-hand.icon";


const SkeletonBox = styled.div`
    width: 100%;
    flex-grow: 1;
    background-color: ${props => props.theme.colors.cardBackground};
   
`

const SkeletonContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 1px dashed ${props => props.theme.border.mainColor};
    border-radius: ${props => props.theme.border.defaultRadius};
    gap: ${props => props.theme.spacing.spacing20};
    padding: ${props => props.theme.spacing.spacing16} 0;
`

const BundlesSkeletonsContainerBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    justify-content: center;
    width: 100%;
    gap: ${props => props.theme.spacing.spacing12};

`

const BundleSkeletonBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex-basis: 27%;
    border: 1px solid ${props => props.theme.border.mainColor};
    border-radius: ${props => props.theme.border.defaultRadius};
    height: 100%;
    
`

const BundleSsrsSkeletonsContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${props => props.theme.spacing.spacing8};
    gap: 4px;
`

const BundleSkeletonTitleBox = styled.div`
    background-color: ${props => props.theme.colors.lightShade};
    height: 20px;
`

const BundleSelectButtonSkeletonContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px;
`

const BundleSelectButtonSkeletonBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${props => props.theme.colors.lightShade};
    height: 20px;
    width: 80%;
    border-radius: 4px;
`

const SeparatorBox = styled.div`
    flex-grow: 1;
`

const BundleSsrSkeletonBox = styled.div<{$isEven: boolean}>`
    background-color: ${props => props.theme.colors.lightShade};
    height: 8px;
    width: ${props => props.$isEven ? "90%" : "60%"};
`

const PointingHandBox = styled.div`
    position: absolute;
    bottom: -13%;
    left: 47%;
`
const FooterTextBox = styled.div`
    color: ${props => props.theme.colors.mediumShade};
    font-size: ${props => props.theme.fontSize.body1};
    padding: 0 ${props => props.theme.spacing.spacing20};
    text-align: center;
    
`

const BundleSkeletonComponent: React.FC<{numberOfSkeletonSsrs: number}> = (props) => {

    const ssrsSkeletons = Array(props.numberOfSkeletonSsrs).fill(1);
    return (
        <BundleSkeletonBox>
            <BundleSkeletonTitleBox/>
            <BundleSsrsSkeletonsContainerBox>
                {ssrsSkeletons.map((_, index) => <BundleSsrSkeletonBox key={index} $isEven={index % 2 === 0}/>)}
            </BundleSsrsSkeletonsContainerBox>
            <SeparatorBox/>
            <BundleSelectButtonSkeletonContainerBox>
                <BundleSelectButtonSkeletonBox/>
            </BundleSelectButtonSkeletonContainerBox>

        </BundleSkeletonBox>
    )
}

export const ShoppingCartBundleSkeletonComponent: React.FC = observer(() => {
    const services = useServices();
    return (
        <SkeletonBox>
            <SkeletonContentBox>
                <BundlesSkeletonsContainerBox>
                    <BundleSkeletonComponent numberOfSkeletonSsrs={8}/>
                    <BundleSkeletonComponent numberOfSkeletonSsrs={5}/>
                    <BundleSkeletonComponent numberOfSkeletonSsrs={2}/>
                    <PointingHandBox>
                        <PointingHandIcon/>
                    </PointingHandBox>
                </BundlesSkeletonsContainerBox>

                <FooterTextBox>
                    {services.language.translate('Choose a bundle and save now!')}
                </FooterTextBox>
            </SkeletonContentBox>
        </SkeletonBox>
    )
})