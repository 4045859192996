import React from 'react';
import {SsrTypeBase} from "../../ssr-type-base";
import {IServiceFactory} from "../../../service-factory.interface";
import {ScbgSsrSmallIcon} from "./scbg.ssr.small-icon";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../ssrs-preferred-orders-in-shopping-cart";
import { SsrCategoryEnum } from '../../ssr-category.enum';
import {
    SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE,
    SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE,
    SSR_LARGE_ICON_SIZE
} from "../../ssr-types.service.interface";

export default class ScbgSsrType extends SsrTypeBase {
    constructor(services: IServiceFactory) {
        super('SCBG', services);
    }

    get smallIcon() {
        return (<ScbgSsrSmallIcon/>);
    }
    get largeIcon() {
        return (<ScbgSsrSmallIcon size={SSR_LARGE_ICON_SIZE}/>)
    }

    get iconInExtrasSmallTile(): React.ReactElement | null {
        return (<ScbgSsrSmallIcon size={SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE}/>)
    }

    get iconInExtrasFirstLargeTile(): React.ReactElement | null {
        return (<ScbgSsrSmallIcon size={SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE}/>)
    }

    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.SCBG;
    }

    get category(): SsrCategoryEnum {
        return SsrCategoryEnum.Baggage;
    }

}
