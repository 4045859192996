import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useContainerMediaQueriesChecks} from "../../../../../hooks/use-container-media-queries-checks.hook";
import {IMediaChecks} from "../../../../../services/media-query/media-queries.interface";

const BagIconBox = styled.div<{$width: number; $height: number; $imageUrl: string}>`
    background-image: url("${props => props.$imageUrl}");
    background-size: cover;
    width: ${props => props.$width}px;
    height: ${props => props.$height}px;
`


interface BagIconComponentProps {
    imageUrl: string;
    width: number;
    height: number;
    className?: string;
    getCustomShrinkPercentage?: (containerMediaQuery: IMediaChecks) => number;
}
export const Bag3dIconComponent: React.FC<BagIconComponentProps> = observer((props) => {
    const containerMediaQuery = useContainerMediaQueriesChecks();

    const getShrinkPercentage = () => {
        if(props.getCustomShrinkPercentage) {
            return props.getCustomShrinkPercentage(containerMediaQuery);
        }

        if(containerMediaQuery.xsAndBelow) {
            return 0.4;
        }

        if(containerMediaQuery.smallScreen) {
            return 0.65;
        }

        return 0.9;

    }

    const computeSize = () => {

        const shrinkPercentage = getShrinkPercentage();

        return {
            $width: props.width * shrinkPercentage,
            $height: props.height * shrinkPercentage
        }
    }

    return (
        <BagIconBox {...computeSize()} $imageUrl={props.imageUrl} className={props.className} />
    )
})