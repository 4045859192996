import {IServiceFactory} from "../../../../service-factory.interface";
import React from "react";
import {SpecialEquipmentSsrTypeBase} from "../special-equipment-ssr-type-base";
import { TennisRacketIcon } from "../../../../../assets/svg/tennis-racket.icon";

export default class SpeqSsrType extends SpecialEquipmentSsrTypeBase {
    constructor(services: IServiceFactory) {
        super('SPEQ', services);
    }

    get smallIcon(): React.ReactElement | null {
        return <TennisRacketIcon/>;
    }

    get largeIcon(): React.ReactElement | null {
        return <TennisRacketIcon/>;
    }


}
