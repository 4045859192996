import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {IonIcon} from "@ionic/react";
import {chevronBack, chevronForward} from "ionicons/icons";
import styled from "styled-components";
import {
    SwapStationsButtonBaseBox,
    SwapStationsChevronIconWrapperBox
} from "../boxes/swap-stations-button.base-boxes";

const SwapStationsButtonBox = styled(SwapStationsButtonBaseBox)`
    flex-direction: column;
    left: 50%;
    top: 0;
    transform: translateX(-50%) translateY(25%);
    cursor: pointer;
    
`


export const SearchMaskSwapStationsButtonLargeScreenComponent: React.FC = observer(() => {
    const services = useServices();
    return (
        <SwapStationsButtonBox onClick={() => services.flightSearch.switchStations()}>
            <SwapStationsChevronIconWrapperBox>
                <IonIcon icon={chevronBack}/>
            </SwapStationsChevronIconWrapperBox>
            <SwapStationsChevronIconWrapperBox>
                <IonIcon icon={chevronForward}/>
            </SwapStationsChevronIconWrapperBox>
        </SwapStationsButtonBox>
    )
})