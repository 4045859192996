import React from "react";
import {observer} from "mobx-react";

export const PaypalLogoSmallV2Icon: React.FC = observer(() => {
    return (
        <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.6833 4.90971C15.7368 2.12721 13.4408 -0.00878906 10.2838 -0.00878906H3.75484C3.60264 -0.00874005 3.45547 0.0456279 3.33979 0.14453C3.22411 0.243432 3.14753 0.380374 3.12384 0.530711L0.507336 16.8787C0.495632 16.9528 0.50012 17.0285 0.520492 17.1007C0.540864 17.1729 0.576635 17.2398 0.625343 17.2968C0.67405 17.3538 0.734538 17.3996 0.80264 17.431C0.870742 17.4624 0.944842 17.4787 1.01984 17.4787H4.88834L4.28334 21.2647C4.27163 21.3388 4.27613 21.4146 4.29653 21.4868C4.31693 21.559 4.35274 21.626 4.40151 21.683C4.45027 21.74 4.51082 21.7858 4.57898 21.8172C4.64714 21.8485 4.7213 21.8648 4.79634 21.8647H7.94734C8.09984 21.8647 8.23534 21.8097 8.35084 21.7112C8.46634 21.6122 8.48534 21.4757 8.50884 21.3252L9.43384 15.8827C9.45734 15.7327 9.53384 15.5377 9.64984 15.4387C9.76534 15.3397 9.86634 15.2857 10.0183 15.2852H11.9473C15.0388 15.2852 17.6623 13.0882 18.1418 10.0317C18.4808 7.86171 17.5503 5.88821 15.6833 4.90971Z"
                fill="#001C64"/>
            <path
                d="M5.72525 11.4502L4.76175 17.5602L4.15675 21.3922C4.14504 21.4663 4.14954 21.542 4.16994 21.6143C4.19034 21.6865 4.22616 21.7534 4.27492 21.8104C4.32368 21.8675 4.38423 21.9132 4.4524 21.9446C4.52056 21.976 4.59471 21.9922 4.66975 21.9922H8.00475C8.15686 21.992 8.30392 21.9376 8.41949 21.8387C8.53506 21.7398 8.61157 21.6029 8.63525 21.4527L9.51425 15.8827C9.53795 15.7325 9.61447 15.5956 9.73005 15.4968C9.84564 15.398 9.99269 15.3437 10.1448 15.3437H12.1083C15.1998 15.3437 17.8228 13.0887 18.3023 10.0322C18.6423 7.86266 17.5503 5.88916 15.6833 4.91016C15.6783 5.14116 15.6583 5.37166 15.6228 5.60016C15.1433 8.65616 12.5198 10.9117 9.42825 10.9117H6.35575C6.20376 10.9117 6.05677 10.9659 5.9412 11.0646C5.82563 11.1633 5.74905 11.3 5.72525 11.4502Z"
                fill="#0070E0"/>
        </svg>


    );
})