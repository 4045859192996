import styled from "styled-components";

export const ArrowForPopperOnBottomBox = styled.div`
  position: absolute;
  visibility: hidden;
  width: 12px;
  height: 12px;
  top: -6px;
  background: inherit;
  &:before {
    position: absolute;
    visibility: visible;
    content: '';
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    background: inherit;
  }
`

export const ArrowForPopperOnTopBox = styled.div`
  position: absolute;
  visibility: hidden;
  width: 12px;
  height: 12px;
  bottom: -6px;
  background: inherit;
  &:before {
    position: absolute;
    visibility: visible;
    content: '';
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    background: inherit;
  }
`
