import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const ScareFaceIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.1} fill={"none"} {...props} sizeIsHeight viewBox="0 0 39 34" ratio={39 / 34}>
            <path
                d="M36.6114 19.1582C36.7044 18.4477 36.7509 17.7305 36.7509 17C36.7509 7.61016 29.1407 0 19.7509 0C10.361 0 2.75087 7.61016 2.75087 17C2.75087 17.7305 2.79735 18.4543 2.89032 19.1648C4.25829 18.9988 5.65946 19.3973 6.7286 20.2871C10.547 23.4746 12.5591 28.123 13.2364 32.7051C15.2419 33.5418 17.4399 34 19.7509 34C22.0618 34 24.2665 33.5418 26.272 32.7051C26.9493 28.123 28.9548 23.4746 32.7798 20.2871C33.8489 19.3973 35.2501 18.9988 36.6181 19.1582H36.6114ZM19.7509 21.25C21.5106 21.25 22.9384 22.6777 22.9384 24.4375V26.5625C22.9384 28.3223 21.5106 29.75 19.7509 29.75C17.9911 29.75 16.5634 28.3223 16.5634 26.5625V24.4375C16.5634 22.6777 17.9911 21.25 19.7509 21.25ZM16.6563 16.1367C15.1622 18.5871 12.486 19.7691 10.6731 18.7797C8.86024 17.7902 8.6079 15.0012 10.0954 12.5574C11.5829 10.1137 14.2657 8.925 16.0786 9.91445C17.8915 10.9039 18.1438 13.693 16.6563 16.1367ZM28.8286 18.7797C27.0157 19.7691 24.3395 18.5871 22.8454 16.1367C21.3513 13.6863 21.6102 10.9039 23.4231 9.91445C25.236 8.925 27.9122 10.107 29.4063 12.5574C30.9005 15.0078 30.6415 17.7902 28.8286 18.7797ZM5.36727 21.9207C4.41766 21.1305 3.01649 21.0109 1.92743 21.7148C0.599305 22.5781 0.22743 24.3379 1.10399 25.6527C2.85712 28.309 3.99931 30.6531 3.97274 34H11.2509C10.786 29.4578 8.93993 24.9023 5.36063 21.9207H5.36727ZM37.5809 21.7148C36.4919 21.0109 35.0841 21.1305 34.1411 21.9207C30.5618 24.9023 28.7157 29.4578 28.2509 34H35.5423C35.5157 30.6465 36.6513 28.309 38.411 25.6527C39.2809 24.3379 38.9157 22.5781 37.5876 21.7148H37.5809Z"
                fill={props.color? props.color: "currentColor"}/>
        </SvgIcon>
    )
}
