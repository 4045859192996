import { IFlightIdentifierViewModel } from "./flight-identifier-view-model.interface";
import {IDotRezIdentifier} from "../../../dot-rez-api/data-contracts/booking/search-simple/search-simple.data-contracts";
import {IServiceFactory} from "../../../service-factory.interface";
import {NullableString} from "../../../../types/nullable-types";

export class FlightIdentifierModel implements IFlightIdentifierViewModel{
    constructor(public readonly carrierCode: string,
                public readonly identifier: string,
                private readonly services: IServiceFactory) {
    
        }
    fullIdentifierCode(): string {
        return `${this.carrierCode}${this.identifier}`
    }

    isMatch(dotRezIdentifier: IDotRezIdentifier): boolean {
        return this.carrierCode === dotRezIdentifier.carrierCode
                && this.identifier === dotRezIdentifier.identifier;
    }

    getOperatedByMessage(): NullableString {
        if(this.services.configuration.data.airlineIataCode === this.carrierCode) {
            return null;
        }

        const carrier = this.services.carriers.tryGetCarrier(this.carrierCode);

        if(carrier) {
            return this.services.language.translationFor('Operated by {carrier}').withParams({carrier: carrier.name});
        }

        return null;

    }
}
