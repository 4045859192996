import {IServiceFactory} from "../../../../../services/service-factory.interface";
import {DialogCloseButtonBehavior} from "../../../../../services/dialog/dialog-enums";
import {
    ManageMyBookingFlightsToModifySelectionDialog
} from "../../../change-flight/flights-to-modify-selection/manage-my-booking-flights-to-modify-selection.dialog";
import React from "react";

export async function showFlightsToModifySelectionDialog(services: IServiceFactory) {
    const booking = services.booking.current;
    await services.dialog.showStandardDialog({
        closeButtonBehavior: DialogCloseButtonBehavior.Reject,
        width: '460px',
        render: dialogHandler => (<ManageMyBookingFlightsToModifySelectionDialog dialogHandler={dialogHandler}/>),
        onAccept: async () => {
            if(booking.manageMyBooking.hasJourneysThatAllowDateChange) {
                await booking.manageMyBooking.startFlightsChange();
            }
        }
    })
}