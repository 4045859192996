import React, {PropsWithChildren} from 'react';
import {CreateAnimation, IonApp} from '@ionic/react';
//import {MobileView} from 'react-device-detect';
/* Core CSS required for Ionic common to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './services/theme/variables.css';
import './services/theme/custom-variables.css';

import {LoadingIndicatorComponent} from "./components/loading-indicator/loading-indicator.component";
import styled, {ThemeProvider} from "styled-components";
import {useServices} from "./hooks/use-services.hook";
import {observer} from "mobx-react";
import {SplashComponent} from "./components/splash/splash.component";
import {OpenDialogsContainerComponent} from "./components/modal/open-dialogs-container.component";

/*swiper CSS*/
import 'swiper/css';
import 'swiper/css/pagination'
import 'swiper/css/autoplay'


import {MaintenanceComponent} from './components/maintenance/maintenance.component';
import {DEFAULT_ANIMATION_TIME} from "./global-constants";
import {NavigationDirectionEnum} from "./services/navigation/navigator.service.interface";
import {PayPalSdkLoader} from "./pages/booking-flow/payment/paypal/paypal-sdk-loader";
import {ContainerMediaQueriesChecksContext} from "./react-contexts/container-media-queries-checks.context";
import {ScreenMediaQueriesChecksContext} from "./react-contexts/scren-media-queries-checks.context";
import {
    CSS_VAR_PAGE_CONTENT_VERTICAL_PADDING,
    CSS_VAR_PAGE_FOOTER_VERTICAL_PADDING,
    CSS_VAR_PAGE_HEADER_VERTICAL_PADDING,
    CSS_VAR_PAGE_CONTENT_HORIZONTAL_PADDING,
    CSS_VAR_PAGE_HEADER_HORIZONTAL_PADDING,
    CSS_VAR_PAGE_FOOTER_HORIZONTAL_PADDING, CSS_VAR_DIALOG_VERTICAL_PADDING, CSS_VAR_DIALOG_HORIZONTAL_PADDING
} from "./services/theme/theme-css-variables";
import {CopyToClipboardToastComponent} from "./copy-to-clipboard-toast.component";

const AppBox = styled(IonApp)<PropsWithChildren>`
    
    ${CSS_VAR_PAGE_HEADER_VERTICAL_PADDING}: ${props => props.theme.spacing.spacing12};
    ${CSS_VAR_PAGE_HEADER_HORIZONTAL_PADDING}: ${props => props.theme.spacing.spacing20};
    
    ${CSS_VAR_PAGE_CONTENT_VERTICAL_PADDING}: ${props => props.theme.spacing.spacing24};
    ${CSS_VAR_PAGE_CONTENT_HORIZONTAL_PADDING}: ${props => props.theme.spacing.spacing40};
        
    ${CSS_VAR_PAGE_FOOTER_VERTICAL_PADDING}: ${props => props.theme.spacing.spacing20};
    ${CSS_VAR_PAGE_FOOTER_HORIZONTAL_PADDING}: ${props => props.theme.spacing.spacing40};
    
    ${CSS_VAR_DIALOG_VERTICAL_PADDING}: ${props => props.theme.spacing.spacing16};
    ${CSS_VAR_DIALOG_HORIZONTAL_PADDING}: ${props => props.theme.spacing.spacing24};
    
    
    ${props => props.theme.screenMediaQuery.lAndBelow} {
        ${CSS_VAR_PAGE_HEADER_VERTICAL_PADDING}: ${props => props.theme.spacing.spacing16};
        ${CSS_VAR_PAGE_HEADER_HORIZONTAL_PADDING}: ${props => props.theme.spacing.spacing20};
        
        ${CSS_VAR_PAGE_CONTENT_VERTICAL_PADDING}: ${props => props.theme.spacing.spacing20};
        ${CSS_VAR_PAGE_CONTENT_HORIZONTAL_PADDING}: ${props => props.theme.spacing.spacing20};
        
        ${CSS_VAR_PAGE_FOOTER_VERTICAL_PADDING}: ${props => props.theme.spacing.spacing16};
        ${CSS_VAR_PAGE_FOOTER_HORIZONTAL_PADDING}: ${props => props.theme.spacing.spacing20};
    }
`

const App: React.FC = observer(() => {
    const services = useServices();
    const theme = services.theme.currentTheme;

    const renderApp = () => {
        if (services.maintenance.data.enableMaintenancePage) {
            return (<>
                <MaintenanceComponent />)
                <OpenDialogsContainerComponent />
            </>);
        }

        if (services.isLoading) {
            return (<>
                <SplashComponent />
                <LoadingIndicatorComponent />
                <OpenDialogsContainerComponent />
            </>);
        }


        return (
            <PayPalSdkLoader>
                <CreateAnimation key={services.navigator.currentRoute.path}
                                 duration={DEFAULT_ANIMATION_TIME}
                                 iterations={1}
                                 play={services.screenMediaQuery.smallScreen}
                                 fromTo={{
                                     property: 'transform',
                                     fromValue: services.navigator.currentRouteDirection === NavigationDirectionEnum.backward ? 'translateX(-100%)' : 'translateX(100%)',
                                     toValue: 'translateX(0)'
                                 }}>
                    <div style={{height: '100%'}}>
                        {services.navigator.currentRoute.render()}
                    </div>


                </CreateAnimation>

                <LoadingIndicatorComponent />
                <OpenDialogsContainerComponent />
                <CopyToClipboardToastComponent/>
            </PayPalSdkLoader>
        );
    }


    return (
        <ScreenMediaQueriesChecksContext.Provider value={services.screenMediaQuery}>
            <ContainerMediaQueriesChecksContext.Provider value={services.screenMediaQuery}>
                <ThemeProvider theme={theme}>
                    {services.theme.currentTheme.globalStyles}
                    <AppBox>
                        {renderApp()}
                    </AppBox>
                </ThemeProvider>
            </ContainerMediaQueriesChecksContext.Provider>
        </ScreenMediaQueriesChecksContext.Provider>


    );
});

export default App;
