import React from "react";
import {observer} from "mobx-react";
import {ScreenSizeBasedComponent} from "../../../basic-components/layout/screen-size-based.component";
import {SeatSelectionLargeScreenPage} from "./seat-selection.large-screen.page";
import {SeatsSelectionSmallScreenPage} from "./seats-selection.small-screen.page";


export const SeatSelectionPage: React.FC = observer(() => {

    const renderForSmallScreen = () => {
        return (
            <SeatsSelectionSmallScreenPage/>
        )
    }


    const renderForLargeScreen = () => {
        return (
            (<SeatSelectionLargeScreenPage />)
        );
    }

    return (
        <ScreenSizeBasedComponent renderForSmallScreen={renderForSmallScreen}
                                  renderForLargeScreen={renderForLargeScreen}/>
    );


});
