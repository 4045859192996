import {ButtonBase, ButtonColors, ConcreteButtonProps} from "./button-base";
import React from "react";
import {useServices} from "../../hooks/use-services.hook";
import {observer} from "mobx-react-lite";



interface MediumButtonProps extends ConcreteButtonProps {
}

export const MediumButton: React.FC<MediumButtonProps> = observer((props) => {
    const services = useServices();
    const themeColors = services.theme.currentTheme.colors;

    const buttonColors: ButtonColors = {
        $background: themeColors.cardBackground,
        $color: themeColors.mediumShade,
        $border: services.theme.currentTheme.border.mainColor,
        $backgroundActivated: themeColors.mediumShade,
        $colorActivated: themeColors.cardBackground,
        $borderActivated: themeColors.mediumShade
    }

    return (
        <ButtonBase {...props} colors={buttonColors}>
            {props.children}
        </ButtonBase>
    );
});
