import React from "react";
import {
    IPassengerSsrEditorViewModel
} from "../../../../../../services/booking/models/ssrs/passenger-ssr-editor-view-model.interface";
import {observer} from "mobx-react";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {BagNameBox} from "../boxes/bag-name.box";
import {PriceWithDiscountComponent} from "../../../../../../components/price/price-with-discount.component";
import {StandardPriceLabelComponent} from "../../../../../../components/price/standard-price-label.component";
import {BagContainerComponent} from "../../bag-container/bag-container-component";
import {BagDetailsComponent} from "../../bag-details/bag-details.component";
import styled from "styled-components";
import {ReadonlyCheckboxComponent} from "../../../../../../basic-components/checkbox/readonly-checkbox.component";
import {
    SsrModificationBlockingReasonComponent
} from "../../../../common-components/ssr-modification-blocking-reason.component";
import {CabinBagPriceContainerBox} from "../../../boxes/cabin-bag-price-container.box";
import {PriorityBoardingIconsComponent} from "./priority-boarding-icons.component";

const BagContainer = styled(BagContainerComponent)`
    justify-content: space-between;
`

const TopSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap8};
    flex-grow: 1;
`

const PriceContainerBox = styled(CabinBagPriceContainerBox)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: flex-end;
    gap: ${props => props.theme.spacing.flexGap12};
    
    
`


export const PriorityBoardingComponent: React.FC<{ssrEditor: IPassengerSsrEditorViewModel; willRenderSmallCabinBag: boolean}> = observer((props) => {
    const services = useServices();

    const isSelected = props.ssrEditor.newQuantity > 0;
    const isIncluded = props.ssrEditor.isCompletelyIncludedInBundle;

    const onClick = async () => {
        props.ssrEditor.newQuantity = 1;
    }

    const getTopLabel = () => {

        if(props.ssrEditor.modificationBlockingReason) {
            return null;
        }

        if(!props.willRenderSmallCabinBag) {
            return null;
        }

        return services.language.translate('Limited offer')
    }

    const renderPrice = () => {

        if(isIncluded) {
            return (
                <PriceContainerBox>
                    <ReadonlyCheckboxComponent checked={isSelected}/>
                </PriceContainerBox>
            );
        }

        return (
            <PriceContainerBox>
                <PriceWithDiscountComponent discountedPrice={props.ssrEditor.discountedMinPrice}
                                            standardPrice={props.ssrEditor.standardMinPrice}
                                            label={<StandardPriceLabelComponent/>}/>
                <ReadonlyCheckboxComponent checked={isSelected}/>
            </PriceContainerBox>

        );
    }

    return (
        <BagContainer isSelected={isSelected} onClick={onClick} topLabel={getTopLabel()} isClickable={props.ssrEditor.canBeModifiedForAtLeastOneSegment}>
            <TopSectionBox>
                <PriorityBoardingIconsComponent/>

                <BagNameBox>
                    {services.language.translate('1 Under seat bag & 1 Large cabin bag + Priority')}
                </BagNameBox>
                <BagDetailsComponent>
                    55x40x20 cm; max 10kg + 40x30x20 cm
                </BagDetailsComponent>
            </TopSectionBox>

            {renderPrice()}
            <SsrModificationBlockingReasonComponent reason={props.ssrEditor.modificationBlockingReason} />
        </BagContainer>
    );
});