import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';
//fill={props.color? props.color: "currentColor"}
export const NotebookWithPenIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1} {...props} sizeIsHeight viewBox="0 0 18 16" ratio={18 / 16}>
            <path
                d="M3.95942 1.25005C3.95942 0.990332 3.74692 0.777832 3.4872 0.777832C3.22747 0.777832 3.01497 0.990332 3.01497 1.25005V2.66672H2.07053C1.02869 2.66672 0.181641 3.51377 0.181641 4.55561V5.50005V6.4445V14.0001C0.181641 15.0419 1.02869 15.8889 2.07053 15.8889H7.81984C7.72834 15.6292 7.71063 15.3459 7.77852 15.0685L7.81098 14.9445H2.07053C1.54813 14.9445 1.12609 14.5225 1.12609 14.0001V6.4445H11.515H12.4594H13.4039V5.50005V4.55561C13.4039 3.51377 12.5568 2.66672 11.515 2.66672H10.5705V1.25005C10.5705 0.990332 10.358 0.777832 10.0983 0.777832C9.83859 0.777832 9.62609 0.990332 9.62609 1.25005V2.66672H3.95942V1.25005ZM2.07053 3.61117H11.515C12.0374 3.61117 12.4594 4.03321 12.4594 4.55561V5.50005H1.12609V4.55561C1.12609 4.03321 1.54813 3.61117 2.07053 3.61117ZM3.4872 11.1667C3.22747 11.1667 3.01497 11.3792 3.01497 11.6389C3.01497 11.8987 3.22747 12.1112 3.4872 12.1112H7.26497C7.5247 12.1112 7.7372 11.8987 7.7372 11.6389C7.7372 11.3792 7.5247 11.1667 7.26497 11.1667H3.4872ZM3.01497 8.80561C3.01497 9.06533 3.22747 9.27783 3.4872 9.27783H10.0983C10.358 9.27783 10.5705 9.06533 10.5705 8.80561C10.5705 8.54589 10.358 8.33339 10.0983 8.33339H3.4872C3.22747 8.33339 3.01497 8.54589 3.01497 8.80561ZM15.4934 8.5695L16.007 9.08304C16.19 9.26603 16.19 9.56707 16.007 9.75005L15.275 10.482L14.0945 9.30144L14.8264 8.5695C15.0094 8.38651 15.3105 8.38651 15.4934 8.5695ZM10.2901 13.1058L13.4245 9.97141L14.6051 11.152L11.4678 14.2863C11.4058 14.3483 11.332 14.3896 11.2493 14.4103L9.80317 14.7733L10.1662 13.3271C10.1868 13.2445 10.2311 13.1678 10.2901 13.1087V13.1058ZM14.1565 7.90248L9.62018 12.4388C9.4372 12.6218 9.31029 12.849 9.24831 13.0969L8.6964 15.3075C8.65508 15.4698 8.7023 15.6381 8.82036 15.7561C8.93841 15.8742 9.10664 15.9214 9.26897 15.8801L11.4796 15.3282C11.7275 15.2662 11.9547 15.1363 12.1377 14.9563L16.674 10.4171C17.2259 9.86516 17.2259 8.96794 16.674 8.41308L16.1605 7.89953C15.6086 7.34762 14.7113 7.34762 14.1565 7.89953V7.90248Z"
                fill={props.color? props.color: "currentColor"}/>
        </SvgIcon>
    )
}
