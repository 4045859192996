import {ExtrasSingleSsrSectionRenderer} from "../../base-renderers/extras-single-ssr-section-renderer";
import {IBookingViewModel} from "../../../../../services/booking/models/booking-view-model.interface";
import {NullableString} from "../../../../../types/nullable-types";

export class FastTrackExtrasSectionRenderer  extends ExtrasSingleSsrSectionRenderer {
    constructor(booking: IBookingViewModel) {
        super(booking.services.ssrTypes.FAST, booking);
    }

    protected _getMarketingMessage(): NullableString {
        return this.services.language.translate(`Traffic? Long Queues? Whatever the reason, don't risk missing your flights or wait in infinite queues`)
    }

    protected _getExtrasLargeTilePicture(): NullableString {
        return this.services.theme.currentTheme.assets.images.extras.fast_track_2x_jpg.toString();
    }
}
