import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';


export const CalendarIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.5} sizeIsHeight={true} {...props} viewBox="0 0 18 20" ratio={18 / 20}>
            <path
                d="M4.375 0C4.71875 0 5 0.28125 5 0.625V2.5H12.5V0.625C12.5 0.28125 12.7812 0 13.125 0C13.4688 0 13.75 0.28125 13.75 0.625V2.5H15C16.3789 2.5 17.5 3.62109 17.5 5V6.25V7.5V17.5C17.5 18.8789 16.3789 20 15 20H2.5C1.12109 20 0 18.8789 0 17.5V7.5V6.25V5C0 3.62109 1.12109 2.5 2.5 2.5H3.75V0.625C3.75 0.28125 4.03125 0 4.375 0ZM16.25 7.5H1.25V17.5C1.25 18.1914 1.80859 18.75 2.5 18.75H15C15.6914 18.75 16.25 18.1914 16.25 17.5V7.5ZM15 3.75H2.5C1.80859 3.75 1.25 4.30859 1.25 5V6.25H16.25V5C16.25 4.30859 15.6914 3.75 15 3.75ZM3.75 14.375C3.75 14.0312 4.03125 13.75 4.375 13.75H9.375C9.71875 13.75 10 14.0312 10 14.375C10 14.7188 9.71875 15 9.375 15H4.375C4.03125 15 3.75 14.7188 3.75 14.375ZM4.375 10H13.125C13.4688 10 13.75 10.2812 13.75 10.625C13.75 10.9688 13.4688 11.25 13.125 11.25H4.375C4.03125 11.25 3.75 10.9688 3.75 10.625C3.75 10.2812 4.03125 10 4.375 10Z"
                fill={props.color? props.color: "currentColor"}/>
        </SvgIcon>
    )
}
