import React, {CSSProperties} from "react";
import {observer} from "mobx-react";
import {ISegmentViewModel} from "../../../services/booking/models/segment/segment-view-model.interface";
import {TicketSegmentPassengerComponent} from "./ticket-segment-passenger.component";
import {useServices} from "../../../hooks/use-services.hook";
import {getPageVerticalGap} from "../print-ticket.helpers";

export const TicketSegmentPassengersComponent: React.FC<{segment: ISegmentViewModel}> = observer((props) => {
    const services = useServices();
    const theme = services.theme.currentTheme;
    const styles: CSSProperties = {
        display: "flex",
        flexDirection: "column",
        gap: getPageVerticalGap(theme),
        width: '100%'
    }

    let passengers = props.segment.passengers;
    /*
    passengers = [
        ...passengers,
        ...passengers,
        passengers[0]
    ]
     */

    return (
        <div style={styles}>
            {passengers.map(ps => (<TicketSegmentPassengerComponent key={ps.passengerKey} passengerSegment={ps}/>))}
        </div>
    )
})