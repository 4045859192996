import {IRoute} from "../../../navigation/models/route.interface";
import {IWizardStep} from "../../../../models/wizard/wizard.step.interface";
import {ManageMyBookingDashboardStep} from "./mmb-flow-dashboard.step";
import {ManageMyBookingStepBase} from "../common/manage-my-booking-step.base";

export class ManageMyBookingUnresolvedServicesStep extends ManageMyBookingStepBase {

    get title(): string {
        return this.services.language.translate('Unresolved services');
    }
    
    get route(): IRoute {
        return this.routes.unresolvedServices;
    }

    protected _shouldBeDisplayed(): boolean {
        return !this.booking.mutationsManager.isMutationInProgress
               && this.booking.manageMyBooking.getUnresolvedServices().hasUnresolvedServices;
    }


    private _previousStep: IWizardStep | null = null;

    protected async _onActivated(previousStep: IWizardStep | null): Promise<void> {
        await super._onActivated(previousStep);
        this._previousStep = previousStep;
    }

    async next(): Promise<void> {
        if(this._previousStep instanceof ManageMyBookingDashboardStep) {
            this._activateStepByRoute(this.routes.finalPrice);
        } else {
            this._activateStepByRoute(this.routes.selectSeats);
        }

    }
}
