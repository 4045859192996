import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import styled, {css} from "styled-components";
import {IBookingInsuranceViewModel} from "../../../../services/booking/models/insurance/booking-insurance-view-model.interface";
import {useServices} from "../../../../hooks/use-services.hook";
import {PriceComponent} from "../../../../components/price/price.component";
import {CheckboxComponent} from "../../../../basic-components/checkbox/checkbox.component";

const InsuranceSelectionBox = styled.div<{$isSelected: boolean}>`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: ${props => props.theme.spacing.spacing12};
    padding: ${props => props.theme.spacing.spacing12};
    border-radius: ${props => props.theme.border.defaultRadius};
    background-color: ${props => props.theme.colors.lightTint};
    ${
    props => props.$isSelected 
            ? css`
              border: solid 2px ${props => props.theme.colors.success};
            `
            : css`
              border: none;
            `
    }
`

const CheckBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`

const AddInsuranceCheckBox = styled(CheckboxComponent)`
    font-weight: ${props => props.theme.fontWeight.bold};
    font-size: ${props => props.theme.fontSize.h5};
`

const DetailsBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 34px;
`

const PriceLabelBox = styled.span`
    font-size: ${props => props.theme.fontSize.body2};
`

const PriceBox = styled(PriceComponent)`
    font-weight: ${props => props.theme.fontWeight.bold};
    font-size: ${props => props.theme.fontSize.h5};
    margin-bottom: ${props => props.theme.spacing.spacing8};
`

const ProtectionPeriodBox = styled.div`
  font-size: ${props => props.theme.fontSize.body2};
`


export const InsuranceSelectionComponent: React.FC<{insurance: IBookingInsuranceViewModel}> = observer((props) => {
    const services = useServices();
    const [rerenderComponent, setRerenderComponent] = useState(false);
    const onClickHandler = async () => {
        await props.insurance.sellInsurance();
    }
    const hasInsurance = props.insurance.hasSelectedInsurance === true;

    const renderProtectionPeriod = () => {
        if(!props.insurance.quote.protectionPeriodDescription) {
            return null;
        }
        return (
            <ProtectionPeriodBox>
                {props.insurance.quote.protectionPeriodDescription}
            </ProtectionPeriodBox>)
    }

    return (
        <InsuranceSelectionBox onClick={onClickHandler} $isSelected={hasInsurance}>
            <CheckBoxContainer>
                <AddInsuranceCheckBox checked={hasInsurance}
                                   className={'tst-add-insurance'}
                                   label={services.language.translate('Add travel insurance')}
                                   labelPlacement={'end'}
                                   onChange={() => setRerenderComponent(!rerenderComponent) /*to force component to rerender*/}
                />
            </CheckBoxContainer>
            <DetailsBox>
                <PriceBox price={props.insurance.quote.totalPrice} label={<PriceLabelBox>{services.language.translate('For only')}</PriceLabelBox>}/>
                {renderProtectionPeriod()}
                {
                    /*
                    <SuggestionBox>
                        {services.language.translate('Over 90% of customers choose this protection!')}
                    </SuggestionBox>
                     */
                }
            </DetailsBox>


        </InsuranceSelectionBox>
    );
});
