import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {ChevronUpIcon} from "../../../../assets/svg/chevron-up.icon";
import {ChevronDownIcon} from "../../../../assets/svg/chevron-down.icon";
import {
    IFlightDesignatorViewModel
} from "../../../../services/booking/models/designator/flight-designator-view-model.interface";
import {useServices} from "../../../../hooks/use-services.hook";

const NumberOfStopsBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    color: ${props => props.theme.colors.secondary};
    font-weight: ${props => props.theme.fontWeight.medium};
    text-decoration: underline;
    line-height: 1;
`

const ChevronBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

interface WithStopoverComponentProps {
    onClick: () => void;
    isExpanded: boolean;
    designator: IFlightDesignatorViewModel;
}
export const WithStopoverComponent: React.FC<WithStopoverComponentProps> = observer((props) => {
    const services = useServices();
    const renderChevron = () => {
        if(props.isExpanded) {
            return (
                <ChevronUpIcon size={0.8}/>
            )
        } else {
            return (
                <ChevronDownIcon size={0.8}/>
            )
        }
    }
    return (
        <NumberOfStopsBox onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.onClick();
        }}>
            <div>{services.language.translate('With stopover')}</div>
            <ChevronBox>
                {renderChevron()}
            </ChevronBox>

        </NumberOfStopsBox>
    )
});