import React from "react";
import {observer} from "mobx-react";
import {FlightsDatesSelectorModel} from "../../../../../services/flight-search/dates-selection/flights-dates-selector.model";
import styled, {css} from "styled-components";
import {DayCellBox} from "./boxes/day-cell.box";
import {DayNumberComponent, UnavailableDayNumberComponent} from "./day-number.component";


const RegularDayContentBox = styled(DayCellBox)<{$isInsideSelection: boolean;}>`
    cursor: cell;
    justify-content: center;
    min-height: 50px;
    min-width: 50px;
    ${props => props.$isInsideSelection
        ? css`
            background-color: ${props => props.theme.colors.light};
        `
        : ''
    }
    
    ${props => props.theme.containerMediaQuery.xsAndBelow} {
        min-height: 40px;
        min-width: 40px;
    }
`;

const SelectedDayContentBox = styled(DayCellBox)`
    cursor: default;
    justify-content: center;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primaryContrast};
}
`;

const UnavailableDayNumber = styled(UnavailableDayNumberComponent)`
    align-self: center;
    font-size: ${props => props.theme.fontSize.h6};
`
const AvailableDayNumber = styled(DayNumberComponent)`
    align-self: center;
    font-size: ${props => props.theme.fontSize.h6};
`

const SelectedDayNumber = styled(AvailableDayNumber)`
    color: ${props => props.theme.colors.primaryContrast};
`

interface DayNoPricesToShowComponentProps {
    date: Date;
    flightsDatesSelector: FlightsDatesSelectorModel;
}
export const DayPricesNotShowComponent: React.FC<DayNoPricesToShowComponentProps> = observer((props) => {
   const isInsideSelection = props.flightsDatesSelector.isDateInsideSelection(props.date);
   const isAvailable = props.flightsDatesSelector.isDateAvailable(props.date);


   if(props.flightsDatesSelector.isDateSelected(props.date)) {
       return (
           <SelectedDayContentBox className={'tst-selected-date'}>
               <SelectedDayNumber date={props.date}/>
           </SelectedDayContentBox>
       )
   }

   const renderDayNumber = () => {
       if(isAvailable) {
           return (
               <AvailableDayNumber date={props.date}/>
           )
       } else {
           return (
               <UnavailableDayNumber date={props.date}/>
           )
       }
   }

   return (
       <RegularDayContentBox $isInsideSelection={isInsideSelection}>
           {renderDayNumber()}
       </RegularDayContentBox>
   )
})