import React from "react";
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";

export const SkiingIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.4} {...props} viewBox="0 0 28 24" ratio={28 / 24}>
            <path
                d="M26.508 19.2469C26.4291 19.2469 26.3509 19.2625 26.278 19.2927C26.2052 19.3229 26.1389 19.3671 26.0831 19.4229C26.0273 19.4787 25.9831 19.5449 25.9529 19.6178C25.9227 19.6907 25.9071 19.7689 25.9071 19.8478C25.9065 20.4931 25.6499 21.1118 25.1936 21.5681C24.7373 22.0244 24.1186 22.281 23.4733 22.2816H11.0332L14.1198 17.9109C14.2229 17.7423 14.2782 17.549 14.2798 17.3514C14.2814 17.1539 14.2292 16.9596 14.1288 16.7894L12.027 13.53L13.1112 12.2402H8.06738C8.0453 12.5984 8.14434 12.9537 8.34859 13.2488L11.5357 17.4885L8.44909 22.2805H0.602134C0.447438 22.2872 0.301319 22.3534 0.194228 22.4652C0.087138 22.5771 0.0273438 22.7259 0.0273438 22.8807C0.0273438 23.0356 0.087138 23.1844 0.194228 23.2963C0.301319 23.4081 0.447438 23.4743 0.602134 23.481H23.4756C24.4383 23.4786 25.3609 23.0948 26.0412 22.4136C26.7215 21.7324 27.1042 20.8094 27.1054 19.8466C27.1051 19.6881 27.0421 19.5361 26.9302 19.4237C26.8183 19.3114 26.6665 19.2478 26.508 19.2469Z"
                fill={props.color? props.color: "currentColor"}/>
            <path
                d="M17.9528 5.31874C19.2789 5.31874 20.3539 4.24375 20.3539 2.91767C20.3539 1.5916 19.2789 0.516602 17.9528 0.516602C16.6268 0.516602 15.5518 1.5916 15.5518 2.91767C15.5518 4.24375 16.6268 5.31874 17.9528 5.31874Z"
                fill={props.color? props.color: "currentColor"}/>
            <path
                d="M1.14396 11.1859L2.13893 11.8703C2.22903 11.9322 2.33428 11.9682 2.44337 11.9747C2.55245 11.9812 2.66124 11.9577 2.75801 11.907C2.85478 11.8562 2.93586 11.78 2.99255 11.6866C3.04924 11.5932 3.07937 11.4861 3.07971 11.3768V11.2853H13.9218L14.9473 10.0667V10.7771C14.9473 11.4547 15.1122 11.9313 16.0643 11.9313H20.5095C20.6609 11.9313 20.8109 11.9015 20.9508 11.8436C21.0907 11.7856 21.2178 11.7006 21.3249 11.5936C21.432 11.4865 21.5169 11.3594 21.5748 11.2195C21.6328 11.0796 21.6626 10.9296 21.6626 10.7782C21.6626 10.6268 21.6328 10.4768 21.5748 10.3369C21.5169 10.197 21.432 10.0699 21.3249 9.96284C21.2178 9.85577 21.0907 9.77084 20.9508 9.71289C20.8109 9.65494 20.6609 9.62511 20.5095 9.62511H17.2738L17.3472 7.35617C17.3314 7.077 17.2533 6.80493 17.1187 6.55987C16.984 6.31481 16.7962 6.10297 16.5691 5.93993L15.4826 5.00931C15.2051 4.75855 14.8395 4.628 14.4659 4.64621C14.0923 4.66441 13.7411 4.82992 13.4893 5.10647L9.20775 10.0983H3.08423V10.0069C3.08389 9.89758 3.05375 9.79046 2.99707 9.69704C2.94038 9.60362 2.85929 9.52743 2.76252 9.47666C2.66576 9.4259 2.55697 9.40248 2.44788 9.40894C2.3388 9.41539 2.23355 9.45147 2.14344 9.5133L1.14848 10.1977C1.06834 10.253 1.00283 10.3268 0.95757 10.413C0.912313 10.4992 0.888672 10.5951 0.888672 10.6924C0.888672 10.7897 0.912313 10.8856 0.95757 10.9718C1.00283 11.0579 1.06834 11.1318 1.14848 11.187L1.14396 11.1859Z"
                fill={props.color? props.color: "currentColor"}/>
        </SvgIcon>
    )
}
