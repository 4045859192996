import React from 'react';
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";
export const PlaneDepartingLineNotFilledIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.3} sizeIsHeight={true} {...props} viewBox="0 0 29 21" ratio={29 / 21}>
            <path
                d="M7.19109 2.17592C7.71219 1.94029 8.30125 1.91764 8.84047 2.11248L16.9922 5.1031C17.1825 5.17107 17.3909 5.16201 17.5722 5.06685L21.5597 3.02779C22.2756 2.66076 23.0686 2.47045 23.8706 2.47045H26.6347C27.2102 2.47045 27.5908 3.06404 27.3506 3.58514C26.6528 5.09857 25.4611 6.32654 23.9703 7.0742L11.5819 13.2729C11.4822 13.3228 11.3689 13.35 11.2556 13.35H4.95266C4.76234 13.35 4.57656 13.2729 4.44063 13.137L1.54516 10.2461L2.61906 9.62982C2.81844 9.51654 3.06312 9.50295 3.27156 9.59811L5.86344 10.749C6.06281 10.8397 6.29391 10.8306 6.48875 10.7309L11.6997 8.06654C11.9308 7.94873 12.0848 7.7131 12.0939 7.45029C12.103 7.18748 11.9761 6.94279 11.7541 6.80232L5.52359 2.93717L7.19563 2.17592H7.19109ZM9.33891 0.753104C8.44625 0.426854 7.45844 0.463104 6.59297 0.857323L4.92094 1.61857C3.86516 2.09889 3.77 3.55795 4.75781 4.16967L9.90078 7.3642L6.13984 9.28545L3.86516 8.27498C3.23078 7.99404 2.50578 8.03029 1.90313 8.37467L0.824688 8.98639C-0.00453118 9.46217 -0.158594 10.595 0.516563 11.2701L3.4075 14.1611C3.81531 14.5689 4.36812 14.8 4.94359 14.8H11.2556C11.5955 14.8 11.9263 14.7229 12.2298 14.5689L24.6183 8.37467C26.4036 7.48201 27.8309 6.00935 28.6647 4.19685C29.3489 2.71514 28.2659 1.02498 26.6347 1.02498H23.8706C22.8375 1.02498 21.8225 1.26967 20.9027 1.74092L17.1961 3.63498L9.33891 0.753104ZM0.725 19.15C0.32625 19.15 0 19.4762 0 19.875C0 20.2737 0.32625 20.6 0.725 20.6H28.275C28.6738 20.6 29 20.2737 29 19.875C29 19.4762 28.6738 19.15 28.275 19.15H0.725Z"
                fill={props.color? props.color: "currentColor"}/>
        </SvgIcon>
    )
}
