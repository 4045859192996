import React from "react";
import {observer} from "mobx-react";
import {
    IFlightDesignatorViewModel
} from "../../../../services/booking/models/designator/flight-designator-view-model.interface";
import {JourneyTravelTimeComponent} from "../common/journey-travel-time.component";
import styled from "styled-components";
import {LeftSectionComponent} from "../common/left-section.component";
import {RightSectionComponent} from "../common/right-section.component";
import {MiddleSectionExpandedComponent} from "./middle-section-expanded.component";
import {GridBaseBox} from "../../boxes/grid-base.box";
import {LayoverComponent} from "./layover.component";
import {TimeSpan} from "../../../../types/time-span";
import {FlightConnectivityTypeComponent} from "../common/flight-connectivity-type.component";

const ContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.spacing8};
    font-size: ${props => props.theme.fontSize.caption};
`

const GridBox = styled(GridBaseBox)`
    
`

const JourneyTravelWrapperBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
    width: 100%;
`

interface AdvancedJourneyDesignatorExpandedComponentProps {
    journeyDesignator: IFlightDesignatorViewModel;
    isShownInShoppingCart: boolean;
    onExpandClick: () => void;
}

export const AdvancedJourneyDesignatorExpandedComponent: React.FC<AdvancedJourneyDesignatorExpandedComponentProps> = observer((props) => {
    const renderSegmentDesignator = (designator: IFlightDesignatorViewModel, index: number) => {

        let layoverTime: TimeSpan | null = null;

        if(index < props.journeyDesignator.innerDesignators.length - 1) {
            const layoverStartTime = designator.arrivalDate.getTime();
            const layoverEndTime = props.journeyDesignator.innerDesignators[index + 1].departureDate.getTime();
            layoverTime = TimeSpan.fromMilliseconds(layoverEndTime - layoverStartTime);
            //layoverTime = TimeSpan.parse('1.05:10');
        }



        return (
            <React.Fragment key={designator.uniqueKey}>
                <LeftSectionComponent isShownInShoppingCart={props.isShownInShoppingCart}
                                      station={designator.origin}
                                      formattedDepartureTime={designator.formatDepartureTime()}
                                      formattedDepartureDate={designator.formatDepartureDate()}/>

                <MiddleSectionExpandedComponent designator={designator}/>

                <RightSectionComponent isShownInShoppingCart={props.isShownInShoppingCart}
                                       station={designator.destination}
                                       formattedDepartureTime={designator.formatArrivalTime()}
                                       formattedDepartureDate={designator.formatArrivalDate()}/>
                {layoverTime && <LayoverComponent layoverTime={layoverTime}/>}
            </React.Fragment>
        );
    }

    return (
        <ContainerBox>
            <GridBox>
                {props.journeyDesignator.innerDesignators.map(renderSegmentDesignator)}
            </GridBox>

            <JourneyTravelWrapperBox>
                <JourneyTravelTimeComponent journeyDesignator={props.journeyDesignator}/>
                <FlightConnectivityTypeComponent designator={props.journeyDesignator} onExpandClick={props.onExpandClick} isExpanded={true}/>
            </JourneyTravelWrapperBox>

        </ContainerBox>

    );
})