import React from "react";
import {IRenderStandardDropDownItemOptions, IStandardDropDownItemViewModel} from "./standard-drop-down-list-item-view-model.interface";
import styled, {css} from "styled-components";

const DropDownItemBox = styled.div<{$isCurrent: boolean; $isLast: boolean}>`
    width: 100%;
    padding: ${props => props.theme.spacing.spacing12} ${props => props.theme.spacing.spacing8};
    color: ${props => props.theme.colors.dark};

    ${
            props => props.$isCurrent
                    ? css`
                        background-color: ${props => props.theme.colors.lightShade};
                    `
                    : ''

    }
    
   

    ${
            props => props.$isLast
                    ? css`
                        border-bottom: none;
                    `
                    : css`
                        border-bottom: 1px solid ${props => props.theme.border.mainColor};
                    `

    }
`

const InsideModalDialogDropDownItemBox = styled(DropDownItemBox)`
    text-align: center;
    font-size: ${props => props.theme.fontSize.h3};
    padding: ${props => props.theme.spacing.spacing20} 0;
    background-color: inherit;
`


export class StandardDropDownListItemModel<TFieldValue> implements IStandardDropDownItemViewModel<TFieldValue> {
    constructor(private readonly id: TFieldValue, private readonly text: string) {
    }

    getFieldValue(): TFieldValue {
        return this.id;
    }

    isSameAs(fieldValue: TFieldValue | null) {
        return this.id === fieldValue;
    }

    get key(): string {
        return this.text;
    }

    applyFilter(filter: string): boolean {
        try {
            if(filter) {
                const regEx = new RegExp(filter, "gi");
                return Boolean(this.text.match(regEx));
            } else {
                return false;
            }

        } catch (err) {
            console.error(`StandardDropDownListItemModel.applyFilter for ${filter} failed!`, err);
            return false;
        }

    }

    getDisplayText(): string {
        return this.text;
    }



    renderItem(options: IRenderStandardDropDownItemOptions): React.ReactElement {

        let Box: any = DropDownItemBox;
        if(options?.isRenderedInModalDialog) {
            Box = InsideModalDialogDropDownItemBox;
        }

        return (
            <Box $isCurrent={options.isCurrent} $isLast={options.isLast}>
                {this.text}
            </Box>
        )
    }
}
