import styled from "styled-components";
import React, {useRef} from "react";
import {observer} from "mobx-react";
import {PageBackButtonComponent} from "../page-back-button/page-back-button.component";
import {StandardPageHeaderComponentProps} from "./standard-page-header.component.props";
import {TestModeMenuComponent} from "../../test-mode/test-mode-menu.component";
import {StandardPageHeaderBox} from "./standard-page-header-box";
import {useServices} from "../../../hooks/use-services.hook";
import {AirlineLogoSmallMulticolorIcon} from "../../../assets/svg/airline-logo-small-multicolor.icon";
import {usePageHeader} from "../../../services/page-header/use-page-header.hook";
import {AirlineLogoLargeMulticolorIcon} from "../../../assets/svg/airline-logo-large-multicolor.icon";
import {LanguageSwitcherComponent} from "../../../components/language-switcher/language-switcher.component";

const HeaderContainerBox = styled(StandardPageHeaderBox)`
    box-shadow: none;
    gap: ${props => props.theme.spacing.flexGap8};
`

export const TopSectionBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`

const TopMiddleSectionBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-grow: 1;
    font-size: ${props => props.theme.fontSize.h3};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

const SmallLogoBox = styled.div<{$isHidden: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    visibility: ${props => props.$isHidden ? "hidden" : "visible"};
    border-radius: 50%;
    cursor: pointer;
`

const LargeLogoBox = styled.div`
`

const BottomSectionBox = styled.div`
    width: 100%;
`

export const StandardPageHeaderSmallScreenComponent: React.FC<StandardPageHeaderComponentProps> = observer((props) => {
    const services = useServices();
    const hideBackButton = Boolean(props.hideBackButton);
    const hideLogoButton = Boolean(props.hideLogo);
    const containerRef = useRef<HTMLIonHeaderElement | null>(null);

    usePageHeader(containerRef);

    //If back button or logo button should be displayed then we render both in order for the title to be centered

    const onLogoClick = async () => {
        if(props.onLogoButtonClick) {
            props.onLogoButtonClick();
        } else {
            await services.navigator.goHome();
        }
    }
    const renderTopLeftCorner = () => {
        if(hideBackButton && hideLogoButton) {
            return null;
        }

        return (<PageBackButtonComponent isHidden={hideBackButton}
                                         onClick={props.onBackButtonClick}
                                         useCloseButtonInsteadOfBackButton={props.useCloseButtonInsteadOfBackButton}/>);
    }

    const renderTopRightCorner = () => {
        if(props.useLargeLogoOnSmallScreen) {
            return (
                <LanguageSwitcherComponent/>
            );
        }
        if(hideBackButton && hideLogoButton) {
            return null;
        }

        return (
            <SmallLogoBox $isHidden={hideLogoButton} onClick={onLogoClick}>
                <AirlineLogoSmallMulticolorIcon size={1.5} sizeIsHeight={true}/>
            </SmallLogoBox>
        )

    }

    const renderTopMiddleSectionContent = () => {
        if(props.useLargeLogoOnSmallScreen) {
            return (
                <LargeLogoBox onClick={onLogoClick}>
                    <AirlineLogoLargeMulticolorIcon size={2}/>
                </LargeLogoBox>
            )
        }

        if(props.renderTopMiddleSection) {
            return props.renderTopMiddleSection();
        }
    }

    const renderTopSectionContent = () => {
        const topMiddleContent = renderTopMiddleSectionContent();
        if(hideBackButton && hideLogoButton && !topMiddleContent) {
            return null;
        }
        return (
            <>
                {renderTopLeftCorner()}
                <TopMiddleSectionBox>
                    {topMiddleContent}
                </TopMiddleSectionBox>
                {renderTopRightCorner()}
                <TestModeMenuComponent/>
            </>
        );
    }

    const hasChildren = React.Children.count(props.children) > 0;

    const renderBottomSection = () => {
        if(hasChildren) {
            return (
                <BottomSectionBox>
                    {props.children}
                </BottomSectionBox>
            );
        }

        return null;
    }

    return (
        <HeaderContainerBox ref={containerRef} $addBottomPadding={Boolean(props.addBottomPadding) || !hasChildren}>
            <TopSectionBox>
                {renderTopSectionContent()}
            </TopSectionBox>
            {renderBottomSection()}
        </HeaderContainerBox>
    )
});
