import React from "react";
import {ParentRouteModel} from "../../models/parent-route.model";
import {ICheckInRoutes} from "./check-in.routes.interface";
import {IServiceFactory} from "../../../service-factory.interface";
import {ChildRouteModel} from "../../models/child-route.model";
import {CheckInFlightSelectionPage} from "../../../../pages/check-in-flow/flight-selection/check-in-flight-selection.page";
import {RouteBaseModel} from "../../models/route-base.model";
import {CheckInPassengersDetailsPage} from "../../../../pages/check-in-flow/passengers/check-in-passengers-details.page";
import {SeatSelectionPage} from "../../../../pages/booking-flow/seat-selection/seat-selection.page";
import {ExtrasSelectionPage} from "../../../../pages/booking-flow/extras-selection/extras-selection.page";
import {FinalPricePage} from "../../../../pages/booking-flow/final-price/final-price.page";
import {PaymentPage} from "../../../../pages/booking-flow/payment/payment.page";
import {CheckInBoardingPassPage} from "../../../../pages/check-in-flow/boarding-passes/check-in-boarding-pass.page";
import {FinalizePaymentPage} from "../../../../pages/finalize-payment/finalize-payment.page";

export class CheckInRoutes extends ParentRouteModel implements ICheckInRoutes {
    constructor(services: IServiceFactory) {
        super('/check-in', services);
    }

    flightSelection = new ChildRouteModel('/flight-search', this, () => <CheckInFlightSelectionPage/>);
    seatsSelection = new ChildRouteModel('/seats', this, () => <SeatSelectionPage/>);
    extras = new ChildRouteModel('/extras', this, () => <ExtrasSelectionPage/>);
    finalPrice = new ChildRouteModel('/final-price', this, () => <FinalPricePage/>);
    passengersDetails = new ChildRouteModel('/passengers-details', this, () => <CheckInPassengersDetailsPage/>);
    payment = new ChildRouteModel('/payment', this, () => <PaymentPage/>);
    finalizePayment = new ChildRouteModel('/finalize-payment', this, () => <FinalizePaymentPage/>);
    boardingPass = new ChildRouteModel('/boarding-pass', this, () => <CheckInBoardingPassPage/>);

    protected _getDefaultChildRoute(): RouteBaseModel | null {
        return this.flightSelection;
    }
}
