import { observer } from "mobx-react";
import React from "react";
import {
    SeatSelectionPageContentSmallScreenComponent
} from "./small-screen/seat-selection-page-content.small-screen.component";
import {BookingFlowPage} from "../booking-flow.page";
import {useServices} from "../../../hooks/use-services.hook";
import styled from "styled-components";
import {
    SeatMapsNavigationButtonsComponent
} from "./components/navigation-buttons/seat-maps-navigation-buttons.component";
import {BookingFlowPageFooterOptions, BookingFlowPageShoppingCartOptions} from "../booking-flow-page.props";

const CSS_CLASS_SEATS_PAGE_CONTENT_SMALL_SCREEN = 'seats-page-content-small-screen';

const PageBox = styled(BookingFlowPage)`
    & .${CSS_CLASS_SEATS_PAGE_CONTENT_SMALL_SCREEN} {
        --padding-start: 0;
        --padding-end: 0;
        --background: ${props => props.theme.colors.cardBackground};
    }
`



export const SeatsSelectionSmallScreenPage: React.FC = observer(() => {
    const services = useServices();


    let footerOptions: BookingFlowPageFooterOptions = {};
    let shoppingCartOptions: BookingFlowPageShoppingCartOptions = {
        showPromotionCode: true
    }

    if(services.booking.currentPageCanBeRendered) {
        if(1 < services.booking.current.filteredJourneys.selectMany(j => j.segments).length) {
            footerOptions.renderExtraFooterContent = () => (<SeatMapsNavigationButtonsComponent dialogHandler={null} removePreviousButtonWhenDisabled={true}/>);
            shoppingCartOptions.hideShoppingCart = true;
            shoppingCartOptions.hideContinueButton = true;
            shoppingCartOptions.showPromotionCode = false;

        }
    }



    return (
        <PageBox disableSwipeNavigation={true}
                         headerOptions={{
                            title: services.language.translate('Seat selection')
                         }}
                         contentOptions={{
                             renderContent: () => <SeatSelectionPageContentSmallScreenComponent dialogHandler={null}/>,
                             reduceTopPaddingOnPageScroll: true,
                             pageContentClassName: CSS_CLASS_SEATS_PAGE_CONTENT_SMALL_SCREEN
                         }}
                         shoppingCartOptions={{
                             ...shoppingCartOptions
                         }}
                         footerOptions={{
                             ...footerOptions
                         }}/>
    );


})