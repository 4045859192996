import React from "react";
import {Station} from "../../../../services/stations/station.service.interface";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";

const ContainerBox = styled.div`
    padding: 0 ${props => props.theme.spacing.spacing12};
    ${props => props.theme.screenMediaQuery.smallScreen} {
        padding: 0;
    }
`
const StationBox = styled.div<{$isCurrent: boolean; $isPrevious: boolean; $isLast: boolean;}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.bold};
    min-width: 250px;
    border-bottom: 1px solid ${props => props.theme.border.mainColor};
    padding: ${props => props.theme.spacing.spacing8};
    ${props => props.theme.screenMediaQuery.smallScreen} {
        padding: ${props => props.theme.spacing.spacing12} 0;
    }
    
    ${
        props => props.$isCurrent
                ? css`
                    background-color: ${props => props.theme.colors.dark};
                    color: ${props => props.theme.colors.darkContrast};
                    border-radius: ${props => props.theme.border.defaultRadius};
                `
                : ''

    }

    ${
            props => props.$isPrevious
                    ? css`
                        border-bottom-color: ${props => props.theme.colors.cardBackground};
                    `
                    : ''

    }

    ${
            props => props.$isLast
                    ? css`
                        border-bottom: none;
                    `
                    : ''

    }

    
`

const StationNameBox = styled.div`
    width: 100%;
`

const StationCountryBox = styled.div`
    width: 100%;
    font-size: ${props => props.theme.fontSize.subtitle2};
    font-weight: ${props => props.theme.fontWeight.medium};
    color: ${props => props.theme.colors.medium};
`

const StationCodeBox = styled.div<{$isCurrent: boolean}>`
    text-align: center;
    width: 40px;
    ${
        props => props.$isCurrent 
            ? css`
                    color: ${props => props.theme.colors.primary};
            `
            : ''
    }
`

const StationDetailsBox = styled.div`
  flex-grow: 1;
  
`

interface StationDropDownItemComponentProps {
    station: Station;
    isCurrent: boolean;
    isPrevious: boolean;
    isLast: boolean;
    isInsideDialog: boolean;

}
export const StationDropDownItemComponent: React.FC<StationDropDownItemComponentProps> = observer((props) => {

    return (
        <ContainerBox>
            <StationBox key={props.station.stationCode} $isCurrent={props.isCurrent && !props.isInsideDialog} $isPrevious={props.isPrevious} $isLast={props.isLast}>
                <StationDetailsBox>
                    <StationNameBox>
                        {props.station.stationName}
                    </StationNameBox>
                    <StationCountryBox>
                        {props.station.countryName}
                    </StationCountryBox>
                </StationDetailsBox>

                <StationCodeBox $isCurrent={props.isCurrent && !props.isInsideDialog}>
                    {props.station.stationCode}
                </StationCodeBox>
            </StationBox>
        </ContainerBox>

    )
});
