import {IDotRezDesignator} from "../../common/designator";
import {NullableString} from "../../../../../types/nullable-types";
import {KeyValueArray} from "../../../../../types/key-value-array";
import {
    DotRezPaymentTypeGraphqlEnum,
    DotRezPaymentStatusGraphqlEnum,
    DotRezPaymentStatusApiEnum, DotRezPaymentTypeApiEnum
} from "../../enums/payment-type.enum";
import {FeeTypeEnum} from "../../enums/fee-type.enum";
import {ServiceChargeTypeGraphQLEnum} from "../../enums/service-charge-type.enum";
import {PassengerSegmentCheckInStatusEnum} from "../../enums/passenger-segment-check-in-status.enum";
import {IDotRezName} from "../../common/name";
import {NullableGender} from "../../enums/gender.enum";
import {IDotRezPhoneNumber} from "../../common/phone-number";
import {IDotRezAddress} from "../../common/address";
import {IDotRezTravelDocument} from "../../common/travel-document";
import {DistributionOptionsEnum} from "../../enums/distribution-options.enum";
import {NotificationPreferenceEnum} from "../../enums/notification-preference.enum";
import {BookingCommentTypeEnum} from "../../enums/booking-comment-type.enum";
import { LegStatusEnum } from "../../enums/leg-status.enum";
import {ChangeReasonCodeEnum} from "../../enums/change-reason-code.enum";

export interface IDotRezBookingData {
    currencyCode: string;
    recordLocator: NullableString;
    locators: IDotRezLocators;
    bookingKey: NullableString;
    breakdown: IDotRezBreakdown;
    journeys: IDotRezJourney[];
    passengers: KeyValueArray<string, IDotRezBookingPassenger>;
    contacts: KeyValueArray<string, IDotRezContact>;
    typeOfSale: IDotRezTypeOfSale;
    payments: IDotRezGraphQLPayment[];
    comments: IDotRezBookingComment[];
    info: IDotRezBookingInfo;
    queues: IDotRezBookingQueue[];
}

export interface IDotRezPayment {
    paymentKey: string;
    code: string;
    details: IDotRezDetailsPayments;
    amounts: {
        amount: number
    }
}

export interface IDotRezGraphQLPayment extends IDotRezPayment {
    type: DotRezPaymentTypeGraphqlEnum,
    status: DotRezPaymentStatusGraphqlEnum
}

export interface IDotRezApiPayment extends IDotRezPayment {
    type: DotRezPaymentTypeApiEnum,
    status: DotRezPaymentStatusApiEnum
}

export interface IDotRezDetailsPayments {
    accountNumber: string;
    fields: Record<string, string>;
}

export interface IDotRezTypeOfSale {
    promotionCode: string;
}

export interface IDotRezJourney {
    journeyKey: string;
    segments: IDotRezSegment[];
    designator: IDotRezDesignator;
}

interface IDotRezBreakdownTax {
    totalTax: number;
}

export interface IDotRezBreakdown {
    totalToCollect: number;
    balanceDue: number;
    authorizedBalanceDue: number;
    journeyTotals: IDotRezBreakdownTax;
    journeys: KeyValueArray<string, IDotRezJourneyBreakdown>;
}

export interface IDotRezJourneyBreakdown {
    totalAmount: number;
    totalPoints: number;
    totalTax: number;
    totalDiscount: number;
}

export enum DotRezPassengerAddressStatusGraphQL {
    Residence = "Residence",
    InCountry = "InCountry",
    Emergency = "Emergency",
    Contact = "Contact"
}

export enum DotRezPassengerAddressStatusApi {
    Residence,
    InCountry ,
    Emergency,
    Contact
}

export interface IDotRezPassengerAddress {
    passengerAddressKey: string;
    companyName?: NullableString;
    phone?: NullableString;
    emailAddress?: NullableString;
    countryCode?: NullableString;
    provinceState?: NullableString;
    city?: NullableString;
    postalCode?: NullableString;
    cultureCode?: NullableString;
    lineOne?: NullableString;
    lineTwo?: NullableString;
    lineThree?: NullableString;
    stationCode?: NullableString;
    refusedContact?: boolean;
}

export interface IDotRezPassengerAddressGraphQL extends IDotRezPassengerAddress {
    status: DotRezPassengerAddressStatusGraphQL;
}

export interface IDotRezPassengerAddressApi extends IDotRezPassengerAddress {
    status: DotRezPassengerAddressStatusApi;
}


export interface IDotRezBookingPassenger {
    passengerKey: string;
    passengerTypeCode: string;
    discountCode: string;
    customerNumber: NullableString;
    name: IDotRezPassengerName | null;
    info: IDotRezPassengerInfo;
    infant: IDotRezBookingInfant | null;
    travelDocuments: IDotRezPassengerTravelDocument[];
    fees: IDotRezPassengerFee[];
    addresses: IDotRezPassengerAddressGraphQL[];
}



export interface IDotRezPassengerFee {
    type: FeeTypeEnum;
    code: string;
    ssrCode: NullableString;
    passengerFeeKey: string;
    createdDate: string;
    flightReference: NullableString;
    serviceCharges: IDotRezServiceCharge[];
    ssrNumber: number;
    isConfirmed: boolean;
    note: NullableString;
}

export interface IDotRezPassengerName extends IDotRezName {

}


export interface IDotRezPassengerInfo {
    nationality: NullableString;
    gender: NullableGender;
    dateOfBirth: NullableString;
}

export interface IDotRezPassengerTravelDocument extends IDotRezTravelDocument {
    passengerTravelDocumentKey?: NullableString;
}

export interface IDotRezBookingInfant {
    fees: IDotRezInfantFee[];
    nationality: NullableString;
    dateOfBirth: NullableString;
    travelDocuments: IDotRezPassengerTravelDocument[];
    gender: NullableGender;
    name: IDotRezPassengerName;
}

export interface IDotRezInfantFee {
    code: string;
    flightReference: string;
    serviceCharges: IDotRezServiceCharge[];
    createdDate: string;
    isConfirmed: boolean;
}

export interface IDotRezSegment {
    segmentKey: string;
    international: boolean;
    designator: IDotRezDesignator;
    identifier: IDotRezSegmentIdentifier;
    flightReference: string;
    passengerSegment: KeyValueArray<string, IDotRezPassengerSegment>;
    legs: IDotRezLeg[];
    fares: IDotRezSegmentFare[];
    changeReasonCode: ChangeReasonCodeEnum;
}

export interface IDotRezSegmentIdentifier {
    carrierCode: string;
    identifier: string;
}

export interface IDotRezLeg {
    legKey: string;
    flightReference: string;
    designator: IDotRezDesignator;
    legInfo: IDotRezLegInfo;
}

export interface IDotRezLegInfo {
    departureTimeUtc: string;
    arrivalTimeUtc: string;
    status: LegStatusEnum;
    departureTime: string;
    operatingCarrier: string;
    operatingFlightNumber: string;
    operatedByText: string;
}


export interface IDotRezPassengerSegment {
    bundleCode: NullableString;
    liftStatus: PassengerSegmentCheckInStatusEnum;
    timeChanged: boolean;
    boardingSequence: NullableString;
    seats: IDotRezPassengerSegmentSeat[] | null;
    ssrs: IDotRezPassengerSegmentSsr[] | null;
}

export interface IDotRezPassengerSegmentSsr {
    passengerKey: string;
    ssrCode: string;
    ssrKey: string;
    count: number;
    inBundle: boolean;
    ssrNumber: number;
}


export interface IDotRezPassengerSegmentSeat {
    unitDesignator: string;
    unitKey: string;
}

export interface IDotRezContact {
    contactTypeCode: string;
    phoneNumbers?: IDotRezPhoneNumber[];
    cultureCode: string;
    emailAddress: NullableString;
    customerNumber: string;
    sourceOrganization: NullableString;
    distributionOption: DistributionOptionsEnum;
    address?: Partial<IDotRezAddress>;
    notificationPreference: NotificationPreferenceEnum;
    companyName: NullableString;
    name: IDotRezPassengerName;

}

export interface IDotRezSegmentFare {
    fareKey: string;
    classOfService: string;
    passengerFares: IDotRezPassengerSegmentFare[];
}

export interface IDotRezPassengerSegmentFare {
    passengerType: string;
    discountCode: string;
    serviceCharges: IDotRezServiceCharge[];

}


export interface IDotRezServiceCharge {
    type: ServiceChargeTypeGraphQLEnum;
    amount: number;
}

export interface IDotRezBookingComment {
    type: BookingCommentTypeEnum;
    text: string;
}


export interface IDotRezBookingInfo {
    createdDate: NullableString;
    bookedDate: string;
}


export interface IDotRezRecordLocator {
    owningSystemCode: NullableString
}
export interface IDotRezLocators {
    numericRecordLocator: NullableString;
    recordLocators: IDotRezRecordLocator[] | undefined;
}

export interface IDotRezBookingQueue {
    code: string;
}
