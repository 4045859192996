import {BookingModel} from "../../booking.model";
import {NullableString} from "../../../../../types/nullable-types";
import {IDotRezBookingSession} from "../../../../dot-rez-api/session/booking-session/dot-rez-booking.session.interface";
import {FormModel} from "../../../../../models/forms/form.model";
import {DialogResult} from "../../../../dialog/dialog-enums";
import {RemovePromoCodeMutation} from "../../mutation-actions/promo-code/remove-promo-code.mutation";
import {IPromotionalFields} from "../promotional-fields.interface";
import {IPromotionalViewModel} from "../promotional-view-model.interface";
import {FormFields, IFormField} from "../../../../../models/forms/form-field.interface";
import {PromotionalApplyStatusEnum} from "../promotional-apply-status.enum";
import {ValidationResultEnum} from "../../../../../types/validation-result.enum";
import {IPromotionalApplyStatus} from "../promotional-apply-status.interface";

export class PromoCodeModel extends FormModel<IPromotionalFields> implements IPromotionalViewModel {

    constructor(private readonly booking: BookingModel) {
        super(booking.services)
    }

    protected _createFields(): FormFields<IPromotionalFields> {
        return {
            code: this._createField<NullableString>({
                fieldName: () => this.services.language.translate('Promo code'),
                initialValue: () => this._getAppliedPromoCode(),
                autoCapitalize: true,
                maxLength: 32
            })
        }
    }

    private _getAppliedPromoCode(): NullableString {
        return this.booking.bookingData.typeOfSale.promotionCode;
    }

    private get session(): IDotRezBookingSession {
        return this.booking.session;
    }

    get isApplied(): boolean {
        return Boolean(this.booking.bookingData.typeOfSale.promotionCode);
    }
    
    get isAllowed(): boolean {
        return true;
    }

    get code(): IFormField<NullableString> {
        return this.fields.code;
    }

    async apply(): Promise<IPromotionalApplyStatus> {
        if(!this.fields.code.value || this.isApplied) {
            return {
                status: PromotionalApplyStatusEnum.NotApplied
            };
        }

        const isValid = await this.services.loadingIndicator.execute({
            action: async () => {
                const response = await this.session.applyPromotionCode(this.fields.code.value!.toUpperCase()?.trim(), this.booking.isOneWayTrip);
                if(response === ValidationResultEnum.Success) {
                    const bookingSessionData = await this.session.bookingStateQueryBuilder().useBookingData().getBookingState();
                    this.booking.updateBookingSessionData(bookingSessionData);
                }
                return response === ValidationResultEnum.Success;
            }
        });

        if(isValid) {
            return {
                status: PromotionalApplyStatusEnum.Success
            }
        }

        const dialogResult = await this.services.dialogFactory.showInvalidPromotionCode();

        if(dialogResult === DialogResult.Accepted) { //it means the user chose to continue without the promo code
            this.fields.code.setValue(null);
            return {
                status: PromotionalApplyStatusEnum.NotApplied
            };
        } else {
            return {
                status: PromotionalApplyStatusEnum.Failed
            };
        }
    }

    async remove(): Promise<void>  {
        if(this.isApplied) {
            this.booking.mutationsManager.startMutation(new RemovePromoCodeMutation(this.booking, {
                onError: () =>  {
                    this.fields.code.setValue(this._getAppliedPromoCode());
                    this.services.alert.showError(this.services.language.translate('Could not delete the promo code'));
                }
            }));
        }

        this.fields.code.setValue(null);
    }
}
