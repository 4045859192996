import React, {useState} from "react";
import {observer} from "mobx-react";
import {BookingFlowPage} from "../booking-flow.page";
import {
    SeatSelectionPageContentLargeScreenComponent
} from "./large-screen/seat-selection-page-content.large-screen.component";
import {useServices} from "../../../hooks/use-services.hook";
import styled from "styled-components";
import {SeatFiltersComponent} from "./components/seat-filters/seat-filters.component";

const PageTitleBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const PageTitleComponent: React.FC<{scrollTop: number}> = (props) => {
    const services = useServices();
    if(!services.booking.currentPageCanBeRendered || props.scrollTop > 3) {
        return null;
    }

    return (
        <PageTitleBox>
            <div>
                {services.language.translate('Select Seats')}
            </div>
            <SeatFiltersComponent/>
        </PageTitleBox>
    )
}

export const SeatSelectionLargeScreenPage: React.FC = observer(() => {
    const [scrollTop, setScrollTop] = useState(0)

    return (
        <BookingFlowPage headerOptions={{
                            title: <PageTitleComponent scrollTop={scrollTop}/>
                         }}
                         contentOptions={{
                             renderContent: () => (<SeatSelectionPageContentLargeScreenComponent dialogHandler={null} scrollTop={scrollTop}/>),
                             reduceTopPaddingOnPageScroll: true,
                             maxContentWidth: "1440px",
                             onContentScroll: (event) => setScrollTop(event.detail.scrollTop),
                         }}
                         shoppingCartOptions={{
                             showPromotionCode: false,
                             hideShoppingCart: true,
                             hideContinueButton: true
                         }} />
    );
})