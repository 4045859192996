import {AirlineTheme} from "./interfaces/airline.theme";

export enum PreferredColorScheme {
    Dark = "dark",
    Light = "light",
    System = "system"
}


export interface IThemeService {
    readonly currentTheme: AirlineTheme;
    createSearchMaskTheme(): AirlineTheme;
    applyContainerMediaQueries(): AirlineTheme;
    readonly isInDarkMode: boolean;
    setColorScheme(colorScheme: PreferredColorScheme): void;

}
