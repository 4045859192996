import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import styled from "styled-components";
import {CheckboxComponent} from "../../../basic-components/checkbox/checkbox.component";
import {arrowDownOutline} from "ionicons/icons";
import {IonIcon} from "@ionic/react";
import {
    ISupportsSameOptionsForAllPassengers
} from "../../../services/booking/models/ssrs/supports-same-options-for-all-passengers.interface";


const ContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: ${props => props.theme.spacing.spacing8};
    padding: ${props => props.theme.spacing.spacing16} 0;
`

const CheckBox = styled(CheckboxComponent)`
    color: ${props => props.theme.colors.darkTint};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

const ArrowDownBox = styled.div`
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.mediumShade};
    
`

interface SameForAllPassengersToggleComponentProps {
    ssrsProvider: ISupportsSameOptionsForAllPassengers;
}
export const SameForAllPassengersToggleComponent: React.FC<SameForAllPassengersToggleComponentProps> = observer((props) => {
    const services = useServices();

    const onChange = async (checked: boolean) => {
        if(checked === props.ssrsProvider.useSameOptionsForAllPassengers) {
            return;
        }

        props.ssrsProvider.useSameOptionsForAllPassengers = checked;

    }

    const passengerFirstName = services.booking.current.passengers[0].getPassengerFirstName().split(' ')[0];
    const label = services.language.translationFor('Same options as {passengerName} for all passengers').withParams({passengerName: passengerFirstName});
    return (
        <ContainerBox>
            <ArrowDownBox>
                <IonIcon icon={arrowDownOutline}/>
            </ArrowDownBox>

            <CheckBox checked={props.ssrsProvider.useSameOptionsForAllPassengers}
                      label={label}
                      labelPlacement={'start'}
                      onChange={isChecked => onChange(isChecked)}/>
        </ContainerBox>

    );
});
