import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {ILanguageService} from "../../services/language/language.service.interface";
import {useServices} from "../../hooks/use-services.hook";


function translateText(language: ILanguageService): string {
    return language.translate('The price shown is the lowest available price for 1 person. It may differ if the booking is done for more passengers, based on availability.');
}

const DropDownBox = styled.div`
    color: ${props => props.theme.colors.notesTint};
    font-size: ${props => props.theme.fontSize.caption};
    padding: 4px;
`


export const LowFareDisclaimerMessageForDropDownComponent: React.FC = observer(() => {
    const services = useServices();
    return (
        <DropDownBox>
            {translateText(services.language)}
        </DropDownBox>
    )
})


const DialogBox = styled.div`
    display: flex;
    flex-direction: column;
    text-align: justify;
    vertical-align: center;
    width: 100%;
    height: 100%;
    font-size: ${props => props.theme.fontSize.body2};
    
`

export const LowFareDisclaimerMessageForSheetDialogComponent: React.FC = observer(() => {
    const services = useServices();
    return (
        <DialogBox>
            {translateText(services.language)}
        </DialogBox>
    )
})