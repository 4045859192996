import {ExtrasSingleSsrSectionRenderer} from "../../base-renderers/extras-single-ssr-section-renderer";
import {IBookingViewModel} from "../../../../../services/booking/models/booking-view-model.interface";
import {NullableString} from "../../../../../types/nullable-types";

export class BusTransferExtrasSectionRenderer extends ExtrasSingleSsrSectionRenderer {
    constructor(booking: IBookingViewModel) {
        super(booking.services.ssrTypes.SBUS, booking);
    }

    protected _getExtrasLargeTilePicture(): NullableString {
        return this.services.theme.currentTheme.assets.images.extras.airport_transfer_2x_jpg.toString();
    }

    protected _getMarketingMessage(): NullableString {
        return this.services.language.translate('The best and most trusted transfers to get you to and from the airport, quickly, safely and at the best rate.');
    }
}
