import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const ChevronBackIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1} {...props} viewBox="0 0 6 10" ratio={6 / 10}>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5.51516 0.234835C5.66161 0.381282 5.66161 0.618718 5.51516 0.765165L1.28033 5L5.51516 9.23483C5.66161 9.38128 5.66161 9.61872 5.51516 9.76517C5.36872 9.91161 5.13128 9.91161 4.98484 9.76517L0.484835 5.26516C0.338388 5.11872 0.338388 4.88128 0.484835 4.73484L4.98484 0.234835C5.13128 0.0883883 5.36872 0.0883883 5.51516 0.234835Z"
                  fill={props.color? props.color: "currentColor"}/>

        </SvgIcon>
    );
}
