import React from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {PrimaryButton} from "../../../basic-components/buttons/primary-button";
import {useServices} from "../../../hooks/use-services.hook";

const BookNowButtonBox = styled(PrimaryButton) `
    font-size: ${props => props.theme.fontSize.h2};
`


interface SearchMaskBookNowButtonComponentProps {
    onClick: () => void;
    label?: string;
    className?: string;
}

export const SearchMaskBookNowButtonComponent: React.FC<SearchMaskBookNowButtonComponentProps> = observer((props) => {
    const services = useServices();
    return (
        <BookNowButtonBox onClick={props.onClick} fullWidth={true} className={props.className} showArrow={true}>
            {props.label ?? services.language.translate('Search')}
        </BookNowButtonBox>
    )
});
