import React from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {IExtrasSectionRenderer} from "./base-renderers/extras-section-renderer.interface";
import {IExtrasSectionsRenderersFactory} from "./base-renderers/extras-sections-renderers-factory.interface";

const PageContentBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.spacing24};
    width: 100%;
`

const TopGridBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${props => props.theme.spacing.spacing24};
    width: 100%;
    
    ${props => props.theme.containerMediaQuery.smallScreen} {
        grid-template-columns: 1fr;
    }
    
`

const BottomGridBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: ${props => props.theme.spacing.spacing24};
    width: 100%;
    ${props => props.theme.containerMediaQuery.smallScreen} {
        grid-template-columns: 1fr 1fr;
    }
`



export const ExtrasTilesComponent: React.FC<{sectionsFactory: IExtrasSectionsRenderersFactory}> = observer((props) => {
    const sectionsFactory = props.sectionsFactory;

    const sections = sectionsFactory.getExtrasSections().filter(s => s.shouldRender());

    const firstTwoSections = sections.slice(0, 2);
    const restOfSections = sections.slice(2);


    const renderLargeTile = (section: IExtrasSectionRenderer, index: number) => {
        return (
          <React.Fragment key={section.id}>
              {section.renderLargeTile({
                  isFirstTile: index === 0
              })}
          </React.Fragment>
        );
    }

    const renderSmallTile = (section: IExtrasSectionRenderer) => {
        return (
            <React.Fragment key={section.id}>
                {section.renderSmallTile()}
            </React.Fragment>
        );
    }

    return (
        <PageContentBox>
            {/*<ReorderSsrsButtonComponent sectionsFactory={sectionsFactory}/>*/}
            <TopGridBox>
                {firstTwoSections.map(renderLargeTile)}
            </TopGridBox>
            <BottomGridBox>
                {restOfSections.map(renderSmallTile)}
            </BottomGridBox>

        </PageContentBox>

    );
});
