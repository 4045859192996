import {IServiceFactory} from "../../services/service-factory.interface";
import {IWizardStep} from "./wizard.step.interface";
import {computed, IReactionDisposer, makeObservable, observable, runInAction} from "mobx";
import {IWizard} from "./wizard.interface";

import {
    IRoutingGuard,
    RoutingGuardContext,
    IRoutingGuardSubscription, RoutingGuardResult, IRouteActivationOptions
} from "../../services/navigation/navigator.service.interface";
import {DialogResult} from "../../services/dialog/dialog-enums";
import {IRoute} from "../../services/navigation/models/route.interface";
import {ValidationResultEnum} from "../../types/validation-result.enum";
import {ISessionStorageService} from "../../services/storage/session-storage.service.interface";


export class Wizard implements IWizard, IRoutingGuard {
    constructor(protected readonly services: IServiceFactory,
                private readonly id: string,
                private readonly _sessionStorage?: ISessionStorageService) {
        this._routingGuardSubscription = this.services.navigator.registerRoutingGuard(this);
        this._stepsVisitedStatus = this.storage.getJson(this._getStepsVisitedStatusStorageKey()) ?? {};
        makeObservable<this, '_stepsVisitedStatus'>(this, {
            _stepsVisitedStatus: observable,
            currentStep: computed
        });
    }

    private readonly _allSteps: IWizardStep[] = [];
    private readonly _routingGuardSubscription: IRoutingGuardSubscription;
    private readonly _stepsVisitedStatus: Record<number, boolean> = {};
    private _reactionDisposers: IReactionDisposer[] = [];

    addStep(step: IWizardStep): void {
        this._allSteps.push(step);
        step.onAddedToWizard();
    }

    findStepIndex(step: IWizardStep): number {
        const index = this._allSteps.findIndex(s => s === step);
        if(index < 0) {
            throw new Error(`Step for route ${step.route.path} cannot be found in ${this.id} wizard`);
        }
        return index;
    }

    private _getStepsVisitedStatusStorageKey(): string {
        return `wizard.${this.id}.stepStatus`;
    }

    private get storage(): ISessionStorageService {
        return this._sessionStorage ?? this.services.sessionStorage;
    }

    setStepWasVisited(index: number): void {
        runInAction(() => {
            this._stepsVisitedStatus[index] = true;
            this.storage.setJson(this._getStepsVisitedStatusStorageKey(), this._stepsVisitedStatus);
        });
    }

    getStepVisitedStatus(index: number): boolean {
        return Boolean(this._stepsVisitedStatus[index]);
    }

    dispose() {
        this._routingGuardSubscription.unsubscribe();

        this._reactionDisposers.forEach(disposer => {
            disposer();
        });
        this._reactionDisposers = [];
        this._allSteps.forEach(s => s.dispose());
        this.storage.removeItem(this._getStepsVisitedStatusStorageKey());
    }

    private _previousStep: IWizardStep | null = null;
    get previousStep(): IWizardStep | null {
        return this._previousStep;
    }

    get currentStep(): IWizardStep | null {
        return this._allSteps.find(s => s.isActive) ?? null;
    }

    private get currentStepIndex(): number {
        return this.currentStep?.index ?? -1;
    }


    start(activateOptions?: IRouteActivationOptions) {
        this._allSteps[0].route.activate(activateOptions);
    }

    get steps(): IWizardStep[] {
        return this._allSteps;
    }

    async nextStep(): Promise<void> {
        if(this.currentStep) {
            await this.currentStep.next();
        }
    }

    private get _nextStepIndexAfterCurrentStep(): number {
        return this._allSteps.findIndex((s, i) => i > this.currentStepIndex && s.isVisible);
    }

    canGoToStep(targetStepIndex: number): boolean {
        if(targetStepIndex < 0 || targetStepIndex >= this._allSteps.length) {
            return false;
        }

        const step = this._allSteps[targetStepIndex];
        if(!step.isVisible) {
            return false;
        }

        if(targetStepIndex < this.currentStepIndex) {
            return Boolean(this.currentStep?.allowNavigateToPreviousSteps) && this.services.navigator.canGoBack;
        }


        if(targetStepIndex === this._nextStepIndexAfterCurrentStep) {
            return step.acceptActivation();
        }

        return step.wasVisited && step.acceptActivation();
    }

    private _getHiddenUnvisitedStepsBetween(fromStepIndex: number, toStepIndex: number): number {
        let from = fromStepIndex;
        let to = toStepIndex;

        if(fromStepIndex > toStepIndex) {
            from = toStepIndex;
            to = fromStepIndex;
        }

        let hiddenSteps = 0;
        for(let i = from; i <= to; i++) {
            const step = this._allSteps[i];
            if(!step.isVisible && !step.wasVisited) {
                hiddenSteps++;
            }
        }

        return hiddenSteps;
    }

    async goToStepByIndex(targetStepIndex: number): Promise<void> {
        if(!this.canGoToStep(targetStepIndex)) {
            return;
        }

        if(targetStepIndex === this.currentStepIndex) {
            return;
        }

        if(targetStepIndex === this._nextStepIndexAfterCurrentStep) {
            if(this._allSteps[targetStepIndex].wasVisited) {
                this.services.navigator.go(1);
            } else {
                await this._allSteps[this.currentStepIndex].next();
            }
        } else if(targetStepIndex < this.currentStepIndex){
            this.services.navigator.go(targetStepIndex - this.currentStepIndex + this._getHiddenUnvisitedStepsBetween(targetStepIndex, this.currentStepIndex));
        } else {
            for(let i = this.currentStepIndex + 1; i < targetStepIndex; i++) {
                const step = this._allSteps[i];
                if(step.isVisible) {
                    if(ValidationResultEnum.Success !== await step.validate()) {
                        this.services.navigator.go(i - this.currentStepIndex - this._getHiddenUnvisitedStepsBetween(this.currentStepIndex, i));
                        return;
                    }
                }

            }
            this.services.navigator.go(targetStepIndex - this.currentStepIndex - this._getHiddenUnvisitedStepsBetween(this.currentStepIndex, targetStepIndex));
        }
    }

    findStepIndexByRoute(route: IRoute): number {
        const stepIndex = this._allSteps.findIndex(s => s.route.equals(route));
        if(stepIndex < 0) {
            throw new Error(`There is no step with route ${route.path} in this wizard`);
        }
        return stepIndex;
    }

    async goToStepByRoute(route: IRoute): Promise<void> {
        const index = this.findStepIndexByRoute(route);
        await this.goToStepByIndex(index);
    }

    activateStepByRoute(route: IRoute, activateOptions?: IRouteActivationOptions): void {
        const stepIndex = this.findStepIndexByRoute(route);
        this._allSteps[stepIndex].route.activate(activateOptions);
    }

    async canNavigate(context: RoutingGuardContext): Promise<RoutingGuardResult> {

        const nextStep = this._allSteps.find(s => s.route.matchLocation(context.targetLocation.pathname)) ?? null;

        if(!this.currentStep && !nextStep) {
            return RoutingGuardResult.Allow;
        }

        /*
        if(this.currentStep?.index === nextStep?.index) {
            return RoutingGuardResult.Allow;
        }
         */

        if(this.currentStep?.isVisible) {
            const deactivationResult = await this.currentStep.onBeforeDeactivation({
                nextStep: nextStep,
                routingGuardContext: context
            });

            if(deactivationResult !== DialogResult.Accepted) {
                return RoutingGuardResult.Block;
            }
        }

        if(nextStep) {
            if(nextStep.isVisible) {
                let activationResult = await nextStep.onBeforeActivation({
                    previousStep: this.currentStep,
                    routingGuardContext: context
                });

                if(activationResult !== DialogResult.Accepted) {
                    return RoutingGuardResult.Block;
                }
            } else {
                return RoutingGuardResult.Skip;
            }

        }

        this._previousStep = this.currentStep;

        return RoutingGuardResult.Allow;
    }

}
