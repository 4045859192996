import {ExtrasSingleSsrSectionRenderer} from "../../base-renderers/extras-single-ssr-section-renderer";
import {IBookingViewModel} from "../../../../../services/booking/models/booking-view-model.interface";
import {NullableString} from "../../../../../types/nullable-types";

export class EarlyCheckInExtrasSectionRenderer extends ExtrasSingleSsrSectionRenderer {
    constructor(booking: IBookingViewModel) {
        super(booking.services.ssrTypes.ECKN, booking);
    }

    protected _getExtrasLargeTilePicture(): NullableString {
        return this.services.theme.currentTheme.assets.images.extras.early_checkin_2x_jpg.toString();
    }

    protected _getMarketingMessage(): NullableString {
        return this.services.language.translate('Give your mind some peace. Checking in online early lets you focus on the traveling part of your trip.');
    }
}
