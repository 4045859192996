import {
    IAnalyticsEcommerceEvent,
    IAnalyticsFlightSelectionEvents,
    IFlightSelectionAddsRemarketingHomeEvent,
    IAnalyticsEcommerceModel, AnalyticsConstants, AnalyticsEvents, IFlightSelectionAddsRemarketingSearchEvent
} from "../../../booking/models/analytics/google-analytics.intefaces";
import {AvailabilityModel} from "../../../flight-search/models/availability.model";
import {
    FlightSearchControllerModel
} from "../../../flight-search/flight-search-controller/flight-search-controller.model";
import {AvailableTripModel} from "../../../flight-search/models/available-trip.model";
import {JourneyModel} from "../../../booking/models/journey/journey.model";
import {BookingModel} from "../../../booking/models/booking.model";
import {AnalyticsBaseEvents} from "./analytics-base.events";
import {NullableStation} from "../../../stations/station.service.interface";
import {AirlineCompanyName} from "../../../../global-constants";
import {IAvailableTripViewModel} from "../../../flight-search/models/available-trip-view-model.interface";
import {IBundleViewModel} from "../../../booking/models/bundle/bundle-view-model.interface";
import {JourneyShoppingCartModel} from "../../../booking/models/shopping-cart/journey/journey-shopping-cart.model";


export class AnalyticsFlightSelectionEvents extends AnalyticsBaseEvents implements IAnalyticsFlightSelectionEvents {

    logAdsRemarketingHome(searchController: FlightSearchControllerModel) {
        try {

            const data: IFlightSelectionAddsRemarketingHomeEvent = {
                event: AnalyticsEvents.adsRemarketingHome,
                flight_originid: searchController.departureOrigin!.stationCode,
                flight_destid: searchController.departureDestination!.stationCode,
                flight_pagetype: 'home', // this is a constant
                flight_startdate: this.services.time.formatYYY_MM_DD(searchController.departureDate),
                flight_enddate: this.services.time.formatYYY_MM_DD(searchController.returnDate),
            }

            this.analyticsActionHandler.sendEvent(data)
        } catch (err){
            this.services.logger.error('logAdsRemarketingHomeEvent failed', err);
        }
    }

    logFlightSearchEvent(searchController: FlightSearchControllerModel) {

        try {
            const formattedDepartureStation = `${searchController.departureOrigin?.stationCode}:${searchController.departureDestination?.stationCode}`;
            const formattedFlightType = searchController.isOneWay ? "OW" : //OneWay
                "RT"// RoundTrip;
            const formattedDate = [this.services.time.formatYYY_MM_DD(searchController.departureDate),
                this.services.time.formatYYY_MM_DD(searchController.returnDate)].filter(item => item).join(":");

            const data = {
                event: AnalyticsEvents.search,
                ecommerce: {
                    search_term: `${formattedDepartureStation}|${formattedFlightType}|${formattedDate}`,
                }
            }
            this.analyticsActionHandler.sendEvent(data)
        } catch (err){
            this.services.logger.error('FlightsSelection: logFlightSearchEvent failed', err);
        }
    }

    logAdsRemarketingSearchResults(booking: BookingModel): void {
        try {
            if(!booking.departureJourney) return;

            const data: IFlightSelectionAddsRemarketingSearchEvent = {
                event: AnalyticsEvents.adsRemarketingSearchResults,
                flight_originid: booking.departureJourney.designator.origin.stationCode,
                flight_destid: booking.departureJourney.designator.destination.stationCode,
                flight_pagetype: 'searchresults', // this is a constant
                flight_startdate: this.services.time.formatYYY_MM_DD(booking.departureJourney.designator.departureDate),
                flight_enddate: this.services.time.formatYYY_MM_DD(booking.returnJourney?.designator.departureDate ?? null),
                flight_totalvalue: booking.balanceDue.amount
            }

            this.analyticsActionHandler.sendEvent(data)
        } catch (err){
            this.services.logger.error('FlightsSelection: logAdsRemarketingHomeEvent failed', err);
        }
    }

    logSelectItemForDeparture(journey: JourneyModel): void {
        const journeyIndex = this._extractIndexForJourney(journey, this.services.booking.availability.departureTrips);
        this._logSelectedItemForJourney(journey, journeyIndex);
    }

    logSelectItemForReturn(journey: JourneyModel): void {
        const journeyIndex = this._extractIndexForJourney(journey, this.services.booking.availability.returnTrips);
        this._logSelectedItemForJourney(journey, journeyIndex);
    }

    logViewItemListForDeparture(availability: AvailabilityModel): void {
        this._logViewItemListEvent(availability, true);
    }
    logViewItemListForReturn(availability: AvailabilityModel): void {
        this._logViewItemListEvent(availability, false);
    }
    logAddToCartForDeparture(booking: BookingModel) {
        this._logAddToCartEvent(booking.departureJourney);
    }
    logAddToCartForReturn(booking: BookingModel) {
        this._logAddToCartEvent(booking.returnJourney);
    }

    logRemoveFromCart(journey: JourneyModel | null): void {
        try {
            if(!journey) return;
            const booking = journey.booking;
            const flightUniqueKey = this._createAddToCartUniqueId(journey);
            const addEcommerceData = booking.analyticsHandler.getDataForKey(flightUniqueKey);
            if(!addEcommerceData) { return; }

            const data: IAnalyticsEcommerceEvent<IAnalyticsEcommerceModel> = {
                event: AnalyticsEvents.removeFromCart,
                ecommerce: {
                    ...addEcommerceData
                    // currency: booking.currency,
                    // value: booking.balanceDue.amount,
                    // customer_type: this._getCustomerTypeFromBooking(booking),
                    // items: [
                    //     ...addEcommerceData
                    // ]
                }
            };
            this.analyticsActionHandler.sendEvent(data)
            booking.analyticsHandler.removeItemsFromCart(flightUniqueKey);
        }
        catch (err) {
            this.services.logger.error('FlightsSelection: logRemoveFromCart failed', err);
        }
    }

    logSelectPromotion(booking: BookingModel, fromBundle: IBundleViewModel, toBundle: IBundleViewModel): void {
        try {
            const journey = booking.departureJourney;
            if (!journey) return;

            const data: IAnalyticsEcommerceEvent<any> = {
                event: AnalyticsEvents.selectPromotion,
                ecommerce:  {
                    creative_slot: 'fare_selection',
                    promotion_id: `FareUpgrade|${journey.analyticsFormattedStationCodes}`,
                    promotion_name: `Upgrade to ${toBundle.bundleType.bundleName}`,
                    items: [
                        ...booking.shoppingCart
                            .journeysNewPurchases
                            .filter( j => j.journeyKey === journey.journeyKey)
                            .flatMap( jnp =>
                                this._getPassengerAnalyticsData(jnp, toBundle))
                    ]
                }
            }

            this.analyticsActionHandler.sendEvent(data)
        } catch (err) {
            this.services.logger.error('FlightsSelection: logSelectPromotion failed', err);
        }
    }

    logViewPromotion(booking: BookingModel, fromBundle: IBundleViewModel, toBundle: IBundleViewModel): void {
        try {
            const journey = booking.departureJourney;
            if (!journey) return;

            const data: IAnalyticsEcommerceEvent<any> = {
                event: AnalyticsEvents.viewPromotion,
                ecommerce:  {
                    creative_slot: 'fare_selection',
                    promotion_id: `FareUpgrade|${journey.analyticsFormattedStationCodes}`,
                    promotion_name: `Upgrade to ${toBundle.bundleType.bundleName}`,
                    items: [
                        ...booking.shoppingCart
                            .journeysNewPurchases
                            .filter( j => j.journeyKey === journey.journeyKey)
                            .flatMap( jnp =>
                               this._getPassengerAnalyticsData(jnp, toBundle))
                    ]
                }
            }

            this.analyticsActionHandler.sendEvent(data)
        } catch (err) {
            this.services.logger.error('FlightsSelection: logSelectPromotion failed', err);
        }
    }

    logMandatoryNotice(): void {
        try {
            const data: {event: string, notification:{ slug: string}} = {
                event: AnalyticsEvents.mandatoryNotice,
                notification: {
                    slug: `/booking/${this.services.navigator.currentRoute.analyticsName}`
                }
            };

            this.analyticsActionHandler.sendEvent(data)
        }
        catch (err) {
            this.services.logger.error('FlightsSelection: logMandatoryNotice failed', err);
        }
    }

    private _logAddToCartEvent(journey: JourneyModel | null): void {
        try {
            if(!journey) return;
            //send remove_from_cart if journeyKey exists
            this.logRemoveFromCart(journey);

            const booking: BookingModel = journey.booking;
            const journeyShoppingCartFares = booking.shoppingCart
                .journeysNewPurchases
                .filter( jnp => jnp.journey.journeyKey === journey.journeyKey)
                .flatMap    (jnp =>  this._getFareAnalyticsData(jnp));

            const data: IAnalyticsEcommerceEvent<IAnalyticsEcommerceModel> = {
                event: AnalyticsEvents.addToCart,
                ecommerce: {
                    currency: booking.currency,
                    value: journeyShoppingCartFares.sum(item => item.price * (item.quantity??1)),
                    customer_type: this._getCustomerTypeFromBooking(booking),
                    items: [
                        ...journeyShoppingCartFares,
                    ]
                }
            };

            this.analyticsActionHandler.sendEvent(data)
            booking.analyticsHandler.saveDataToSession(this._createAddToCartUniqueId(journey), data.ecommerce)
        }
        catch (err) {
            this.services.logger.error('FlightsSelection: logAddToCart failed', err);
        }
    }

    private _extractIndexForJourney(journey: JourneyModel, trips: IAvailableTripViewModel[]){
        return trips.flatMap( trip => trip.journeys)
            .findIndex( j => j.journeyKey === journey.journeyKey)
    }

    private _logSelectedItemForJourney(journey: JourneyModel, index: number): void {
        try {
            const item_list_name = `${AnalyticsConstants.flightListCategory} ${journey.analyticsFormattedStationCodes}`;
            const item_list_id = `${AnalyticsConstants.flightCategory}|${journey.analyticsFormattedStationCodes}|${journey.analyticsFormattedDepartureDate}`;

            const data: IAnalyticsEcommerceEvent<any> = {
                event: AnalyticsEvents.selectItem,
                ecommerce: {
                    customer_type: this._getCustomerTypeFromBooking(journey.booking),
                    item_list_name,
                    item_list_id,
                    items: [
                        {
                            item_list_name,
                            item_list_id,
                            item_name: `${AnalyticsConstants.flightCategory}|${journey.analyticsFormattedStationCodes}`,
                            item_id: `${AnalyticsConstants.flightCategory}|${journey.analyticsFlightId}`,
                            item_brand: journey.booking.analyticsConfiguration.itemBrand,
                            item_category: `${AnalyticsConstants.flightCategory}`,
                            is_direct: journey.designator.isDirectFlight,
                            index // need to get this
                        }]
                }
            }
            this.analyticsActionHandler.sendEvent(data)
        } catch (err) {
            this.services.logger.error('FlightsSelection: logViewItemListEvent failed', err);
        }
    }

    /// this happens before the booking is created
    private _logViewItemListEvent(
        availability: AvailabilityModel,
        isDeparture: boolean) {

        try {
            const originStation: NullableStation = isDeparture? availability.departureOrigin : availability.returnOrigin;
            const destinationStation: NullableStation = isDeparture? availability.departureDestination : availability.returnDestination;
            if(!originStation || !destinationStation) return;

            const formattedDepartureArrivalStationCodes = this._formatDepartureArrivalStationCodesForAnalyticsEvent(
                originStation.stationCode,
                destinationStation.stationCode);
            const availableTripModel: AvailableTripModel[] = isDeparture ? availability.departureTrips : availability.returnTrips;

            // availableTripModel[0].searchController.retu
            const formattedDate = this._formatGoogleAnalyticsDates(isDeparture ? availability.departureDate! : availability.returnDate!);
            const item_list_name = `${AnalyticsConstants.flightListCategory} ${formattedDepartureArrivalStationCodes}`;
            const item_list_id = `${AnalyticsConstants.flightCategory}|${formattedDepartureArrivalStationCodes}|${formattedDate}`;

            const data: IAnalyticsEcommerceEvent<any> = {
                event: 'view_item_list',
                ecommerce: {
                    customer_type: availability.searchController.getAnalyticsAgentName(),
                    item_list_id,
                    item_list_name,
                    items: availableTripModel.flatMap(trip =>
                        trip.journeys.map((journey, index) => {
                                return {
                                    item_list_name,
                                    item_list_id,
                                    item_name: `${AnalyticsConstants.flightCategory}|${formattedDepartureArrivalStationCodes}`,
                                    item_id: `${AnalyticsConstants.flightCategory}|${journey.segments.map(item => item.identifier.fullIdentifierCode()).join(",")}`,
                                    item_brand: AirlineCompanyName,
                                    item_category: `${AnalyticsConstants.flightCategory}`,
                                    is_direct: journey.designator.isDirectFlight,
                                    index
                                }
                        }))
                }
            }
            this.analyticsActionHandler.sendEvent(data)
        } catch (err) {
            this.services.logger.error('FlightsSelection: logViewItemListEvent failed', err);
        }
    }

    private _createAddToCartUniqueId(journey: JourneyModel): string {
        return `flight_${journey.analyticsFormattedStationCodes}`
    }

    private _extractSelectedBundle(journey: JourneyModel, bundleCode?: string) {
        bundleCode = bundleCode ??  journey.selectedBundle?.bundleCode;
        const index = journey.bundlesAvailability.findIndex(f=>f.bundleCode ===  bundleCode);
        return {
            index,
            selectedBundle: index !== -1 ? journey.bundlesAvailability[index]: journey.bundlesAvailability[0]//journey.selectedBundle
        }
    }

    private _getPassengerAnalyticsData(jnp: JourneyShoppingCartModel, toBundle: IBundleViewModel) {
        return jnp.getPassengersAnalyticsData().map(
            s => {
                const journey = jnp.journey;
                const {index, selectedBundle} = this._extractSelectedBundle(journey, toBundle.bundleCode);
                return {
                    item_name: `${AnalyticsConstants.flightCategory}|${journey.analyticsFormattedStationCodes}|${selectedBundle?.bundleCode}`,
                    item_id: `${AnalyticsConstants.flightCategory}|${journey.analyticsFlightId}|${s.passengerTypeCode}`,
                    item_brand: journey.booking.analyticsConfiguration.itemBrand,
                    item_category: `${AnalyticsConstants.flightCategory}`,
                    item_category2: `${AnalyticsConstants.flightCategory}|PTC|${s.passengerTypeCode}`,
                    item_category3: `${AnalyticsConstants.flightCategory}|${journey.analyticsFormattedDepartureDate}`,
                    item_variant: `${AnalyticsConstants.flightCategory}|${selectedBundle?.bundleCode}`,
                    is_direct: journey.designator.isDirectFlight, //boolean true or false. If the journey only has 1 segment is_direct = true
                    item_list_name: `${AnalyticsConstants.flightListCategory} ${journey.analyticsFormattedStationCodes}`,
                    item_list_id: `${AnalyticsConstants.flightCategory}|${journey.analyticsFormattedStationCodes}|${journey.analyticsFormattedDepartureDate}`,
                    price: selectedBundle.pricePerPassenger.sum(s.totalAmount).amount,
                    discount: journey.totalDiscount,
                    index,
                    item_coupon: s.discountCode ?? "",
                    quantity: s.quantity
                }
            });
    }

}