import React from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";

const TabsBox = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
    width: fit-content;
    overflow: hidden;
    padding: ${props => props.theme.spacing.spacing8} ${props => props.theme.spacing.spacing8} 0;
`

export const TabItemBox = styled.div<{$isSelected: boolean}>`
    box-sizing: border-box;
    padding: ${props => props.theme.spacing.spacing16} ${props => props.theme.spacing.spacing24};
    border-top-left-radius: ${props => props.theme.border.defaultRadius};
    border-top-right-radius: ${props => props.theme.border.defaultRadius};
    min-width: 9.5rem;
    font-size: ${props => props.theme.fontSize.h4};
    /*box-shadow: 0 34px 26px rgba(13, 10, 44, 0.05), 0 12px 60px rgba(13, 10, 44, 0.08);*/
    ${props => props.$isSelected
    ? css`
                background-color: ${props => props.theme.colors.cardBackground};
                border: 1px solid ${props => props.theme.colors.lightShade};
                color: ${props => props.theme.colors.dark};
                border-bottom: none;
            `
    : css`
                background-color: ${props => props.theme.colors.lightShade};
                color: rgba(${props => props.theme.colors.darkRgb}, 0.5);
                cursor: pointer;
                border-bottom: none;
            `
    }
    
    
    
    
`

interface TabComponentProps<TItem> {
    items: TItem[];
    selectedItem: TItem;
    renderItem: (item: TItem) => React.ReactElement;
    onItemClick: (item: TItem) => void;
    getItemKey: (item: TItem) => string;
}
export const TabComponent = observer(<TItem extends any>(props: TabComponentProps<TItem>) => {


    const renderOnItem = (item: TItem) => {
        const itemKey = props.getItemKey(item);
        const selectedItemKey = props.getItemKey(props.selectedItem);
        return (
            <TabItemBox key={itemKey}
                        $isSelected={itemKey === selectedItemKey}
                        onClick={() => props.onItemClick(item)}>
                {props.renderItem(item)}
            </TabItemBox>
        )
    }
    return (
        <TabsBox>
            {props.items.map(renderOnItem)}
        </TabsBox>
    )
})