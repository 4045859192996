import { observer } from "mobx-react";
import React from "react";
import {AirlineLogoLargeMulticolorIcon} from "../../../../assets/svg/airline-logo-large-multicolor.icon";
import {XCloseSmallIcon} from "../../../../assets/svg/x-close-small.icon";
import styled from "styled-components";
import {IonMenuToggle} from "@ionic/react";

const SideMenuHeaderBox = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding: calc(env(safe-area-inset-top) + ${props => props.theme.spacing.spacing48}) 
            ${props => props.theme.spacing.spacing16} 
            ${props => props.theme.spacing.spacing16} 
            ${props => props.theme.spacing.spacing16};
`



const CloseSideMenuButton = styled(IonMenuToggle)`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    color: ${props => props.theme.colors.medium};
    margin-bottom: -12px;
`

export const HybridPageSideMenuHeaderComponent: React.FC = observer(() => {
    return (
        <SideMenuHeaderBox>
            <AirlineLogoLargeMulticolorIcon size={3}/>
            <CloseSideMenuButton>
                <XCloseSmallIcon size={3}/>
            </CloseSideMenuButton>
        </SideMenuHeaderBox>
    )
})