import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {Bag3dIconComponent} from "../../bag-icon/bag-3d-icon.component";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {PlusPriorityLargeIcon} from "../../../../../../assets/svg/plus-priority-large.icon";
import {PlusBigIcon} from "../../../../../../assets/svg/plus-big.icon";
import {useContainerMediaQueriesChecks} from "../../../../../../hooks/use-container-media-queries-checks.hook";
import {IMediaChecks} from "../../../../../../services/media-query/media-queries.interface";

const IconsContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
`




const TrolleyBagIconWrapperBox = styled.div`
    margin-left: -2rem;
    padding-bottom: 0.75rem;
    ${props => props.theme.containerMediaQuery.smallScreen} {
        margin-left: -1.6rem;
    }

    ${props => props.theme.containerMediaQuery.xsAndBelow} {
        margin-left: -1rem;
    }
    
`


const PriorityIconContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.spacing8};
`

export const TrolleyBagIconComponent: React.FC<{className?: string; getCustomShrinkPercentage?: (containerMediaQuery: IMediaChecks) => number;}> = observer((props) => {
    const services = useServices();
    return (
        <Bag3dIconComponent width={117}
                            height={151}
                            className={props.className}
                            getCustomShrinkPercentage={props.getCustomShrinkPercentage}
                            imageUrl={services.theme.currentTheme.assets.images.bags.trolley_png.toString()}/>
    )
})

export const Priority3DIconComponent: React.FC<{getCustomIconSize?: () => number}> = observer((props) => {
    const containerMediaQuery = useContainerMediaQueriesChecks();
    const getPriorityIconSize = () => {
        if(props.getCustomIconSize) {
            return props.getCustomIconSize();
        }
        if(containerMediaQuery.xsAndBelow) {
            return 5;
        }

        if(containerMediaQuery.smallScreen) {
            return 6;
        }

        return 7
    }

    return (
        <PlusPriorityLargeIcon size={getPriorityIconSize()}/>
    )
})

export const SmallCabinBagSmaller3DIconComponent: React.FC<{className?: string; getCustomShrinkPercentage?: (containerMediaQuery: IMediaChecks) => number;}> = observer((props) => {
    const services = useServices();
    return (
        <Bag3dIconComponent width={109}
                            height={109}
                            className={props.className}
                            getCustomShrinkPercentage={props.getCustomShrinkPercentage}
                            imageUrl={services.theme.currentTheme.assets.images.bags.small_cabin_bag_small_png.toString()}/>
    )
})

const SmallBagIconBox = styled(SmallCabinBagSmaller3DIconComponent)`
    z-index: 1;
`


export const PriorityBoardingIconsComponent: React.FC = observer(() => {

    return (
        <IconsContainerBox>
            <SmallBagIconBox/>
            <TrolleyBagIconWrapperBox>
                <TrolleyBagIconComponent/>
            </TrolleyBagIconWrapperBox>
            <PriorityIconContainerBox>
                <PlusBigIcon/>
                <Priority3DIconComponent/>
            </PriorityIconContainerBox>

        </IconsContainerBox>
    )
})