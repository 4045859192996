import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const AirlineHomePageSmallLogoIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={3} {...props} sizeIsHeight viewBox="0 0 156.428 39" ratio={156.428/39}>
            <g>
                <path fill="#FFFFFF" d="M72.261,23.334c0.324,0.054,0.642,0.137,0.951,0.249l-0.644,2.984c-0.234-0.058-0.517-0.117-0.848-0.175
	c-0.338-0.059-0.681-0.088-1.024-0.088c-1.287,0-2.219,0.336-2.794,1.009c-0.575,0.673-0.863,1.701-0.863,3.086v8.25H63.47V23.466
	h3.101l0.234,1.814c0.487-0.663,1.092-1.165,1.814-1.507c0.721-0.341,1.589-0.512,2.603-0.512
	C71.57,23.258,71.917,23.282,72.261,23.334z M138.525,23.421c-0.76,0-1.311,0.17-1.653,0.512c-0.341,0.341-0.512,0.824-0.512,1.448
	c0,0.624,0.171,1.107,0.512,1.448s0.892,0.512,1.653,0.512c0.761,0,1.312-0.171,1.653-0.512s0.512-0.824,0.512-1.448
	c0-0.624-0.171-1.107-0.512-1.448C139.836,23.592,139.285,23.421,138.525,23.421z M129.019,38.649h3.569V16.913h-3.569V38.649z
	 M59.597,27.02c0.205,0.748,0.308,1.521,0.307,2.296v1.17c0,0.352-0.024,0.639-0.073,0.863c-0.049,0.225-0.137,0.395-0.263,0.512
	c-0.127,0.117-0.298,0.195-0.512,0.234c-0.214,0.039-0.487,0.058-0.819,0.058l-8.017,0.001c0.038,0.78,0.136,1.429,0.292,1.946
	c0.156,0.517,0.415,0.931,0.776,1.243c0.36,0.312,0.848,0.536,1.463,0.673c0.614,0.137,1.4,0.205,2.355,0.205
	c0.643,0,1.277-0.029,1.901-0.088c0.624-0.058,1.346-0.166,2.165-0.322l-0.468,2.545c-0.311,0.108-0.629,0.196-0.951,0.263
	c-0.361,0.077-0.756,0.146-1.185,0.205c-0.429,0.058-0.868,0.102-1.317,0.132C54.803,38.985,54.364,39,53.935,39
	c-1.365,0-2.511-0.156-3.437-0.468c-0.927-0.312-1.678-0.78-2.253-1.404c-0.575-0.624-0.985-1.389-1.229-2.296
	c-0.244-0.907-0.366-1.965-0.365-3.175v-1.229c0-1.092,0.132-2.086,0.395-2.984c0.263-0.897,0.677-1.668,1.243-2.311
	c0.565-0.644,1.282-1.141,2.15-1.492c0.868-0.351,1.906-0.527,3.116-0.527c1.229,0,2.248,0.176,3.057,0.527s1.458,0.814,1.946,1.39
	C59.047,25.609,59.402,26.289,59.597,27.02z M56.54,29.084c0-1.092-0.234-1.921-0.702-2.487c-0.469-0.566-1.259-0.849-2.371-0.849
	s-1.931,0.307-2.457,0.922c-0.527,0.614-0.81,1.624-0.848,3.028h6.378V29.084z M156.43,28.847v9.8h-3.043l-0.263-1.521
	c-0.449,0.624-1.039,1.083-1.77,1.375c-0.731,0.293-1.623,0.439-2.676,0.439c-0.761,0-1.453-0.083-2.077-0.249
	c-0.624-0.165-1.166-0.434-1.624-0.804c-0.458-0.37-0.81-0.848-1.053-1.433c-0.244-0.585-0.366-1.307-0.366-2.165l-0.002-0.321
	c0-0.878,0.132-1.619,0.395-2.223c0.263-0.605,0.638-1.092,1.126-1.463c0.487-0.37,1.082-0.639,1.785-0.805
	c0.702-0.166,1.482-0.248,2.341-0.249c0.878,0,1.623,0.088,2.238,0.263c0.614,0.175,1.136,0.478,1.565,0.907v-0.995
	c0-1.287-0.327-2.199-0.98-2.735c-0.653-0.536-1.672-0.804-3.057-0.805c-0.7,0-1.4,0.054-2.092,0.161
	c-0.692,0.107-1.37,0.239-2.033,0.394l0.439-2.691c0.624-0.175,1.346-0.322,2.165-0.439c0.775-0.114,1.557-0.173,2.341-0.176
	c1.014,0,1.93,0.112,2.75,0.337c0.819,0.225,1.517,0.571,2.092,1.039c0.575,0.468,1.019,1.063,1.331,1.785
	C156.274,26.995,156.43,27.853,156.43,28.847z M153.035,33.645c0-0.643-0.229-1.136-0.688-1.477
	c-0.458-0.341-1.195-0.512-2.209-0.512h-0.497c-0.956,0-1.643,0.181-2.062,0.542c-0.42,0.361-0.629,0.912-0.629,1.653v0.205
	c0,0.741,0.229,1.287,0.687,1.638c0.458,0.351,1.184,0.527,2.18,0.526c1.111,0,1.926-0.21,2.442-0.629
	c0.517-0.419,0.775-0.99,0.776-1.712V33.645z M88.29,27.504c0.224,0.897,0.336,1.872,0.336,2.925v1.229
	c0,1.014-0.107,1.965-0.322,2.853c-0.215,0.887-0.585,1.663-1.111,2.326c-0.527,0.663-1.244,1.19-2.15,1.58
	C84.136,38.805,83,39,81.635,39s-2.497-0.185-3.394-0.556c-0.897-0.371-1.614-0.883-2.15-1.536
	c-0.537-0.653-0.917-1.428-1.141-2.325c-0.225-0.897-0.337-1.872-0.336-2.926v-1.229c0-1.053,0.117-2.028,0.351-2.925
	c0.234-0.897,0.619-1.668,1.155-2.311c0.536-0.644,1.253-1.151,2.15-1.522c0.898-0.37,2.02-0.555,3.365-0.555
	c1.345,0,2.467,0.185,3.364,0.556c0.897,0.371,1.614,0.878,2.15,1.522S88.066,26.607,88.29,27.504z M84.999,30.194
	c0-1.56-0.244-2.667-0.732-3.32c-0.488-0.653-1.355-0.98-2.604-0.98c-1.248,0-2.131,0.327-2.648,0.98
	c-0.517,0.653-0.775,1.76-0.775,3.32v1.697c0,0.839,0.063,1.536,0.19,2.092c0.127,0.556,0.332,1,0.615,1.331
	c0.282,0.331,0.652,0.574,1.068,0.702c0.43,0.137,0.947,0.205,1.551,0.205c1.229,0,2.092-0.327,2.589-0.98
	c0.497-0.653,0.746-1.77,0.746-3.35V30.194z M92.573,38.649h3.569V27.874h-3.569V38.649z M124.606,26.273
	c0.312,0.722,0.468,1.58,0.468,2.574v9.8h-3.043l-0.263-1.521c-0.449,0.624-1.039,1.083-1.77,1.375
	c-0.731,0.293-1.623,0.439-2.676,0.439c-0.761,0-1.453-0.083-2.077-0.249c-0.624-0.165-1.166-0.434-1.624-0.804
	c-0.458-0.37-0.81-0.848-1.053-1.433c-0.244-0.585-0.366-1.307-0.366-2.165l-0.002-0.321c0-0.878,0.132-1.619,0.395-2.223
	c0.263-0.605,0.638-1.092,1.126-1.463c0.487-0.37,1.082-0.639,1.785-0.805c0.702-0.166,1.482-0.248,2.341-0.249
	c0.878,0,1.623,0.088,2.238,0.263c0.614,0.175,1.136,0.478,1.565,0.907v-0.995c0-1.287-0.327-2.199-0.98-2.735
	c-0.653-0.536-1.672-0.804-3.057-0.805c-0.7,0-1.4,0.054-2.092,0.161s-1.37,0.239-2.033,0.394l0.439-2.691
	c0.624-0.175,1.346-0.322,2.165-0.439c0.775-0.114,1.557-0.173,2.341-0.176c1.014,0,1.93,0.112,2.75,0.337
	c0.819,0.225,1.517,0.571,2.092,1.039C123.85,24.956,124.294,25.551,124.606,26.273z M121.678,33.645
	c0-0.643-0.229-1.136-0.688-1.477c-0.458-0.341-1.195-0.512-2.209-0.512h-0.497c-0.956,0-1.643,0.181-2.062,0.542
	c-0.42,0.361-0.629,0.912-0.629,1.653l0.001,0.205c0,0.741,0.229,1.287,0.687,1.638c0.458,0.351,1.185,0.527,2.179,0.526
	c1.111,0,1.926-0.21,2.442-0.629c0.516-0.419,0.775-0.99,0.776-1.712V33.645z M136.741,38.649h3.569V27.874h-3.569V38.649z
	 M108.106,36.22c-0.936,0-1.614-0.219-2.033-0.658c-0.419-0.439-0.629-1.185-0.629-2.238v-7.167h4.272l0.41-2.692h-4.681v-4.183
	l-3.569,0.468v3.714h-2.458l-0.409,2.692h2.867v7.05c0,1.034,0.103,1.917,0.307,2.648c0.204,0.732,0.516,1.327,0.936,1.785
	c0.419,0.459,0.951,0.79,1.594,0.995c0.643,0.205,1.414,0.307,2.311,0.307c0.509-0.001,1.018-0.04,1.521-0.117
	c0.507-0.078,0.917-0.176,1.229-0.293l0.439-2.516c-0.332,0.059-0.654,0.108-0.966,0.147
	C108.868,36.204,108.487,36.224,108.106,36.22z M94.358,23.421c-0.761,0-1.312,0.17-1.653,0.512
	c-0.341,0.341-0.512,0.824-0.512,1.448c0,0.624,0.171,1.107,0.512,1.448s0.892,0.512,1.653,0.512c0.761,0,1.312-0.171,1.653-0.512
	s0.512-0.824,0.512-1.448c0-0.624-0.171-1.107-0.512-1.448C95.669,23.592,95.118,23.421,94.358,23.421z M34.951,15.809l8.243,22.763
	h-8.53l-6.815-18.82c-0.494,0.31-0.99,0.621-1.5,0.958c-5.495,3.629-8.779,6.831-12.35,10.313l-0.042,0.041
	c-1.948,1.902-3.593,4.09-4.88,6.489L8.53,38.572H0L20.696,0h8.53l5.034,13.9c8.578-2.859,14.617-3.94,14.673-3.752
	c0.045,0.149-3.734,1.051-9.961,3.807C37.843,14.455,36.487,15.06,34.951,15.809z M26.727,16.655l-2.594-7.163L18.307,20.35
	c0.028-0.014,0.056-0.028,0.084-0.041C21.244,18.936,24.046,17.724,26.727,16.655z"/>
            </g>
        </SvgIcon>
    )
}
