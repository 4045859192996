import React from "react";
import {useServices} from "../../../../hooks/use-services.hook";
import styled from "styled-components";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import {observer} from "mobx-react";
import { ExclamationCircle } from "../../../../assets/svg/exclamation-circle.icon";
import {StandardDialogPageComponent} from "../../../../components/modal/page/standard-dialog-page.component";
import {StandardPageContentComponent} from "../../../../basic-components/page/standard-page-content/standard-page-content.component";
import {PrimaryButton} from "../../../../basic-components/buttons/primary-button";
import {PrimaryButtonInverted} from "../../../../basic-components/buttons/primary-button-inverted";
import {VerticalSpacer} from "../../../../basic-components/spacing/vertical-spacer";
import {StandardDialogHeaderComponent} from "../../../../components/modal/header/standard-dialog-header.component";


const HeaderTitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.h2};
    font-weight: ${props => props.theme.fontWeight.bold};
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1;
`

const BetweenButtonsBox = styled.div`
    font-size: ${props => props.theme.fontSize.h5};
    text-align: center;
    color: ${props => props.theme.colors.primary};
    line-height: 1;
`


export enum SeatsSuggestionResponse {
    ChooseYourOwnSeats,
    ContinueWithoutSeat
}

export const SeatsSuggestionDialogComponent: React.FC<{dialogHandler: IDialogConfirmationHandler}> = observer((props) => {
    const services = useServices();
    
    const onChooseYourSeats = () => {
        props.dialogHandler.accept(SeatsSuggestionResponse.ChooseYourOwnSeats);
    }

    const onContinueWithoutSeats = () => {
        props.dialogHandler.accept(SeatsSuggestionResponse.ContinueWithoutSeat);
    }



    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler}>
                <HeaderTitleBox>
                    <ExclamationCircle />
                    <VerticalSpacer size="small"/>
                    <div>
                        {services.language.translate('Avoid the middle seats')}
                    </div>
                    <VerticalSpacer size="small"/>
                </HeaderTitleBox>
            </StandardDialogHeaderComponent>
            <StandardPageContentComponent>

                <VerticalSpacer/>

                <PrimaryButton onClick={onChooseYourSeats}>
                    {services.language.translate('Choose your seats')}
                </PrimaryButton>

                <VerticalSpacer/>

                <BetweenButtonsBox>
                    <strong>{services.language.translate('Random allocation')}</strong>
                </BetweenButtonsBox>

                <VerticalSpacer size="small"/>

                <BetweenButtonsBox>
                    {services.language.translate('You may not get your preferred seat')}
                </BetweenButtonsBox>

                <VerticalSpacer/>

                <PrimaryButtonInverted onClick={onContinueWithoutSeats}>
                    {services.language.translate('Continue without a seat')}
                </PrimaryButtonInverted>

            </StandardPageContentComponent>
        </StandardDialogPageComponent>

    );

});
