import React, {PropsWithChildren, useEffect} from "react";
import ReactDOM from 'react-dom';
import {observer} from "mobx-react";
import styled from "styled-components";
import {ZIndex} from "../../../../../types/z-index";
import {IDropDownController} from "../../models/drop-down-controller.interface";
import {isClickInsideElement} from "../../../../../utils/is-click-inside-element";
import {CardBox} from "../../../../../basic-components/card/card.box";
import {useServices} from "../../../../../hooks/use-services.hook";


const DropDownFixedContainerBox = styled(CardBox)`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: ${ZIndex.DropDownPopper};
    box-shadow: ${props => props.theme.boxShadow.shadow6};
    padding: 0;
    overflow: hidden;
    border-radius: ${props => props.theme.border.radiusPx(2)};
`

interface DropDownFixedPositionComponentProps  extends  PropsWithChildren{
    dropDownController: IDropDownController;
}
export const DropDownFixedPositionComponent: React.FC<DropDownFixedPositionComponentProps> = observer((props) => {
    const services = useServices();
    const containerRef = React.useRef<HTMLDivElement | null>(null);

    useEffect(() => {

        const onDocumentClickHandler = (event: MouseEvent) => {
            if(containerRef.current && !isClickInsideElement(event, containerRef.current)) {
                props.dropDownController.closeDropDown();
            }
        }

        document.addEventListener('click', onDocumentClickHandler);

        return (() => {

            document.removeEventListener('click', onDocumentClickHandler);
        });
    }, [props.dropDownController]);

    return (
        ReactDOM.createPortal((<DropDownFixedContainerBox ref={containerRef} className={"tst-drop-down-fixed"}>
            {props.children}
        </DropDownFixedContainerBox>), services.document.body)
    );
});