import {IHomePageTabViewModel} from "../../../../../services/home-page/tabs/home-page.tab.interface";
import React from "react";
import {HomePageTabBase} from "../../../../../services/home-page/tabs/home-page-tab-base";
import {HybridHomePageHeaderComponent} from "../../header/hybrid-home-page-header.component";
import { PhoneCheckmarkIcon } from "../../../../../assets/svg/phone-checkmark.icon";
import {CheckInTabContentComponent} from "./components/check-in-tab-content.component";

export class CheckInTab extends HomePageTabBase implements IHomePageTabViewModel {
    get text(): string {
        return this.services.language.translate('Check-in');
    }

    renderIcon(): React.ReactElement {
        return (
            <PhoneCheckmarkIcon size={1.8}/>
        );
    }

    renderHeader(): React.ReactElement | null {
        return (
            <HybridHomePageHeaderComponent/>
        );
    }

    renderContent(): React.ReactElement {
        return (
            <CheckInTabContentComponent/>
        )
    }

}
