import React from "react";
import {observer} from "mobx-react";
import {IPassengerViewModel} from "../../../../services/booking/models/passenger/passenger-view-model.interface";
import {IFormField} from "../../../../models/forms/form-field.interface";
import {NullableString} from "../../../../types/nullable-types";
import {
    StandardDropDownListItemModel
} from "../../../../components/forms/standard-drop-down/list-view/models/item/standard-drop-down-list-item.model";
import {StandardDropDownComponent} from "../../../../components/forms/standard-drop-down/standard-drop-down.component";
import {useServices} from "../../../../hooks/use-services.hook";


const CityOfBirthDropDownComponent: React.FC<{field: IFormField<NullableString>}> = observer((props) => {
    const services = useServices();
    const items = services.regions.allSicilianCities.map(c => {
        let value = c;
        if(value.length > 32) {
            value = value.substring(0, 32);
        }

        return new StandardDropDownListItemModel(value, c)
    });

    return (
        <StandardDropDownComponent field={props.field}
                                   items={items}/>
    );
})

export const PsoPassengerCitySelectorComponent: React.FC<{passenger: IPassengerViewModel}> = observer((props) => {
    const passengerFields = props.passenger.fields;

    return (
        <CityOfBirthDropDownComponent key={"PASSENGER_CITY_OF_BIRTH"}
                                      field={passengerFields.addressCity}/>
    )

    /*
    if(passengerFields.psoPassengerType.value === PsoPassengerTypeEnum.Native) {
        return (
            <CityOfBirthDropDownComponent key={"PASSENGER_CITY_OF_BIRTH"}
                                          field={passengerFields.addressCity}/>
        )
    }
    return (
        <StringFieldEditorComponent key={"PASSENGER_CITY_OF_RESIDENCE"}
                                    field={passengerFields.addressCity}/>
    )
     */
});