import React from "react";
import {observer} from "mobx-react";
import {
    IJourneyHistoryViewModel
} from "../../../../../../../services/booking-history/models/journey-history-view-model.interface";
import {FlightCardContentBox} from "../../../../common/flight-card/flight-card-content.box";
import {FlightCardTopSectionComponent} from "../../../../common/flight-card/flight-card-top-section.component";
import {FlightCardMiddleSectionComponent} from "../../../../common/flight-card/flight-card-middle-section.component";
import {useServices} from "../../../../../../../hooks/use-services.hook";
import {PrimaryButton} from "../../../../../../../basic-components/buttons/primary-button";


interface PastFlightCardComponentProps {
    flight: IJourneyHistoryViewModel;
}
export const PastFlightCardComponent: React.FC<PastFlightCardComponentProps> = observer((props) => {
    const services = useServices();
    const onViewBookingClick = async () => {
        await props.flight.showFlightItinerary();
    }
    return (
        <FlightCardContentBox id={props.flight.uniqueKey}>
            <FlightCardTopSectionComponent flight={props.flight} />
            <FlightCardMiddleSectionComponent flight={props.flight}/>
            <PrimaryButton fullWidth={true} showArrow={true} onClick={onViewBookingClick}>
                {services.language.translate('View booking')}
            </PrimaryButton>
        </FlightCardContentBox>
    )
})