import React from 'react';
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {WizardProgressIndicatorComponentProps} from "./wizard-progress-indicator.component.props";
import {IWizard} from "../../models/wizard/wizard.interface";
import {IWizardStepViewModel} from "../../models/wizard/wizard-step.view-model.interface";
import {PageBackButtonComponent} from "../../basic-components/page/page-back-button/page-back-button.component";

const WizardContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    width: 100%;
    height: 35px;
    color: ${props => props.theme.colors.mediumTint};
    font-size: ${props => props.theme.fontSize.body2};
    
    gap: ${props => props.theme.spacing.spacing12};
`

const StepContainerBox = styled.div<{$isSelected: boolean; $stepsCount: number; $canClick: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    text-align: center;
    height: 100%;
    width: ${props => `calc(100%/${props.$stepsCount})`};
    padding: ${props => props.theme.spacing.spacing8};
    cursor: ${props => (props.$canClick ? "pointer" : "not-allowed")};
    white-space: nowrap;
    ${
        props => props.$isSelected 
                ? css`
                  color: ${props => props.theme.colors.primary};
                  border-bottom: 3px solid ${props => props.theme.colors.primary};
                  font-weight: ${props => props.theme.fontWeight.bold};
                  cursor: default;
                `
                : ''
                
    }
`

interface WizardStepComponentProps {
    getWizard: () => IWizard;
    step: IWizardStepViewModel;
}

const WizardStepComponent: React.FC<WizardStepComponentProps> = observer((props) => {
    const wizard = props.getWizard();
    const step = props.step;

    if(!step.isVisible) {
        return null;
    }

    const canClick = wizard.canGoToStep(step.index);

    const onClickHandler = async () => {
        if(canClick) {
            await wizard.goToStepByIndex(step.index);
        }
    }
    return (
        <StepContainerBox $isSelected={step.isActive}
                          $stepsCount={wizard.steps.length}
                          $canClick={canClick}
                          onClick={onClickHandler}>
            {step.title}
        </StepContainerBox>
    );
});

const BackButtonBox = styled.div`
    color: ${props => props.theme.colors.dark};
`

export const WizardProgressIndicatorLargeScreenComponent: React.FC<WizardProgressIndicatorComponentProps> = observer((props) => {
    const wizard = props.getWizard();
    const steps = wizard.steps;
    return (
        <WizardContainerBox>
            <BackButtonBox>
                <PageBackButtonComponent/>
            </BackButtonBox>

            {steps.map((s) => {
                return (
                    <WizardStepComponent key={s.title} step={s} getWizard={props.getWizard}/>
                );
            })}
        </WizardContainerBox>
    )
});
