import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {IonSkeletonText} from "@ionic/react";
import {CardBox} from "../../../../../basic-components/card/card.box";

const BundleSkeletonBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    flex-basis: 100%;
    overflow: hidden;
`

const BundleSkeletonHeaderBox = styled(IonSkeletonText)`
    width: 100%;
    background-color: ${props => props.theme.colors.lightShade};
    height: 60px;
    border-radius: 0;
    margin: 0;
`

const SsrsSkeletonsContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.spacing16};
    padding: ${props => props.theme.spacing.cardDefaultPadding};
    width: 100%;
    flex-grow: 1;
`

const SsrSkeletonBox = styled(IonSkeletonText)<{isEven: boolean}>`
    height: 38px;
    width: ${props => props.isEven ? "100%" : "80%"};
    border-radius: ${props => props.theme.border.defaultRadius};
`

const BundleSkeletonFooterBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${props => props.theme.spacing.spacing16};
    width: 100%;
    padding: ${props => props.theme.spacing.cardDefaultPadding};
`

const SelectButtonSkeletonBox = styled(IonSkeletonText)`
    height: 52px;
    width: 100%;
    border-radius: ${props => props.theme.border.defaultRadius};
`

const PricePerPassengerBox = styled(IonSkeletonText)`
    height: 15px;
    width: 40%;
    border-radius: ${props => props.theme.border.defaultRadius};
`


export const BundleSkeletonComponent: React.FC<{numberOfSkeletonSsrs: number}> = observer((props) => {
    const ssrsSkeletons = Array(props.numberOfSkeletonSsrs).fill(1);
    return (
        <BundleSkeletonBox>
            <BundleSkeletonHeaderBox animated/>
            <SsrsSkeletonsContainerBox>
                {ssrsSkeletons.map((_, index) => <SsrSkeletonBox key={index} animated isEven={index % 2 === 0}/>)}
            </SsrsSkeletonsContainerBox>
            <BundleSkeletonFooterBox>
                <SelectButtonSkeletonBox animated/>
                <PricePerPassengerBox animated/>
            </BundleSkeletonFooterBox>
        </BundleSkeletonBox>
    )
});