import { observer } from "mobx-react";
import React from "react";
import { IJourneyHistoryViewModel } from "../../../../../../../services/booking-history/models/journey-history-view-model.interface";
import styled from 'styled-components';
import { FlightCardTopSectionComponent } from "../../../../common/flight-card/flight-card-top-section.component";
import {FlightCardMiddleSectionComponent} from "../../../../common/flight-card/flight-card-middle-section.component";
import {NextFlightBottomSectionComponent} from "./next-flight-bottom-section.component";
import {FlightCardContentBox} from "../../../../common/flight-card/flight-card-content.box";

const ContainerBox = styled.div`
    width: 100%;
    padding-bottom: calc(2 * ${props => props.theme.spacing.pageContentVerticalPadding});
    
`

const ContentBox = styled(FlightCardContentBox)`
    box-shadow: none;
    border: none;
`

interface NextFlightComponentProps {
    flight: IJourneyHistoryViewModel;
    className?: string;
    contentClassName?: string
}

export const NextFlightComponent: React.FC<NextFlightComponentProps> = observer((props) => {

    return (
        <ContainerBox className={props.className}>
            <ContentBox className={props.contentClassName}>
                <FlightCardTopSectionComponent flight={props.flight} />
                <FlightCardMiddleSectionComponent flight={props.flight}/>
                <NextFlightBottomSectionComponent flight={props.flight}/>
            </ContentBox>
        </ContainerBox>
    );
})
