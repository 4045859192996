import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {PassengerCabinBagsSelectionComponent} from "./cabin-bags/passenger-cabin-bags-selection.component";
import {PassengerCheckInBagsSelectionComponent} from "./check-in-bags/passenger-check-in-bags-selection.component";
import {IPassengerAllBagsViewModel} from "../../../../services/booking/models/ssrs/bags/passenger-all-bags-view-model.interface";


const PassengerBagsSelectionBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

interface PassengerBagsSelectionComponentProps {
    passengerBags: IPassengerAllBagsViewModel;
}


export const PassengerBagsSelectionComponent: React.FC<PassengerBagsSelectionComponentProps> = observer((props) => {

    return (
        <PassengerBagsSelectionBox>

            <PassengerCabinBagsSelectionComponent passengerBags={props.passengerBags}/>

            <PassengerCheckInBagsSelectionComponent passengerBags={props.passengerBags}/>

        </PassengerBagsSelectionBox>
    );
});

