import {PaymentMethodGroupModel} from "./payment-method-group.model";
import React from "react";
import {IServiceFactory} from "../../../../service-factory.interface";
import {CreditCardPaymentMethodsTabContentComponent} from "../../../../../pages/booking-flow/payment/credit-card/credit-card-payment-methods-tab-content.component";
import {CreditCardPaymentMethodsTabHeaderComponent} from "../../../../../pages/booking-flow/payment/credit-card/credit-card-payment-methods-tab-header.component";
import {
    CreditCardPayButtonComponent
} from "../../../../../pages/booking-flow/payment/credit-card/credit-card-pay-button.component";
import {PaymentMethodsGroupTypeEnum} from "./payment-method-group-type.enum";

export class CreditCardMethodsGroupModel extends PaymentMethodGroupModel {

    constructor(services: IServiceFactory) {
        super(PaymentMethodsGroupTypeEnum.CreditCards, services);
    }
    renderTabHeader(): React.ReactElement {
        return (
            <CreditCardPaymentMethodsTabHeaderComponent/>
        );
    }

    renderTabContent(): React.ReactElement {
        return (
            <CreditCardPaymentMethodsTabContentComponent paymentMethods={this.paymentHandler.creditCardPaymentMethods}
                                                         paymentHandler={this.paymentHandler}/>
        )
    }

    renderPayButton(): React.ReactElement {
        return (
            <CreditCardPayButtonComponent/>
        );
    }

}