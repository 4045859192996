import React from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {FlightsDatesSelectorModel} from "../../../services/flight-search/dates-selection/flights-dates-selector.model";
import {CheckboxComponent} from "../../../basic-components/checkbox/checkbox.component";
import {useServices} from "../../../hooks/use-services.hook";
import {PrimaryShadeButton} from "../../../basic-components/buttons/primary-shade-button";

const ContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap16};
`

const DoneButton = styled(PrimaryShadeButton)`
    height: 100%;
    min-width: 140px;
    ${props => props.theme.containerMediaQuery.xsAndBelow} {
        min-width: unset;
        max-width: 140px;
        flex-grow: 1;
    }
`

const CheckBoxContainer = styled.div<{checked: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    
    padding: ${props => props.theme.spacing.spacing16} ${props => props.theme.spacing.spacing20};
    border-radius: ${props => props.theme.border.defaultRadius};
    font-size: ${props => props.theme.fontSize.h5};
    ${props => props.checked 
        ? css`
            border: 1px solid ${props => props.theme.colors.primary};    
        `
        : css`
            border: 1px solid ${props => props.theme.colors.darkTint};
        `
    }
`

const CheckBox = styled(CheckboxComponent)`
    --border-radius: 4px;
    --size: 26px;
    --checkbox-background-checked: ${props => props.theme.colors.primaryShade};
    --border-color-checked: ${props => props.theme.colors.primaryShade};
    --border-color: ${props => props.theme.colors.lightShade};
`

interface FlightDirectionSelectorComponentProps {
    flightsDatesSelector: FlightsDatesSelectorModel;
    onDone?: () => void;
    className?: string;
    buttonText?: string;
}

export const FlightDirectionSelectorComponent: React.FC<FlightDirectionSelectorComponentProps> = observer((props) => {
    const services = useServices();
    const datesSelector = props.flightsDatesSelector;
    const searchController = datesSelector.flightSearchController;

    const onChange = (isChecked: boolean) => {
        searchController.isOneWayDepartureTrip = isChecked;
    }

    return (
        <ContainerBox className={props.className}>
            <CheckBoxContainer checked={searchController.isOneWay}>
                <CheckBox checked={searchController.isOneWay}
                          onChange={onChange}
                          label={services.language.translate('Flying one way?')}
                          labelPlacement={"end"}
                          mode={"md"}/>
            </CheckBoxContainer>

            <DoneButton onClick={props.onDone}>
                {props.buttonText ?? services.language.translate('Done')}
            </DoneButton>
        </ContainerBox>
    )
});