import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {DayCellBox} from "./boxes/day-cell.box";
import {IonSpinnerComponent} from "../../../../spinner/ion-spinner.component";

const SpinnerBox = styled(DayCellBox)`
    justify-content: center;
`

export const DaySpinnerComponent: React.FC = observer(() => {
    return (
        <SpinnerBox>
            <IonSpinnerComponent/>
        </SpinnerBox>
    )
})