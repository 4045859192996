import React, {CSSProperties} from "react";
import {observer} from "mobx-react";
import {
    IPassengerSegmentViewModel
} from "../../../services/booking/models/passenger-segment/passenger-segment-view-model.interface";
import {useServices} from "../../../hooks/use-services.hook";
import {TicketPassengerSegmentSsrsComponent} from "./ticket-passenger-segment-ssrs.component";
import {PersonCircleFilledIcon} from "../../../assets/svg/person-circle-filled.icon";
import {SeatExtraLegroomSmallIcon} from "../../../services/ssr-types/ssrs/seats/seat-extra-legroom-ssr.small-icon";


const PassengerNameAndSeatComponent: React.FC<{passengerSegment: IPassengerSegmentViewModel}> = observer((props) => {
    const services = useServices();
    const rootStyles: CSSProperties = {
        display: "flex",
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: services.theme.currentTheme.fontSize.h5,
        fontWeight: services.theme.currentTheme.fontWeight.bold,
        width: '100%',
        gap: services.theme.currentTheme.spacing.flexGap20,

    };

    const personNameStyles: CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: services.theme.currentTheme.spacing.flexGap8

    }

    const seatStyles: CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: services.theme.currentTheme.spacing.flexGap8
    }


    const getSeatNumber = () => {
        if(props.passengerSegment.assignedSeat) {
            return props.passengerSegment.assignedSeat.seatNumber;
        }

        return "N/A";
    }



    return (
        <div style={rootStyles}>
            <div style={personNameStyles}>
                <div>
                    <PersonCircleFilledIcon/>
                </div>
                <div>
                    {services.language.translationFor('Passenger: {passengerName}').withParams({passengerName: props.passengerSegment.passengerFullName})}
                </div>

            </div>
            <div>|</div>
            <div style={seatStyles}>
                <div>
                    <SeatExtraLegroomSmallIcon size={1.6}/>
                </div>
                <div>
                    {services.language.translationFor('Seat: {seatNumber}').withParams({seatNumber: getSeatNumber()})}
                </div>
            </div>

        </div>
    )
})

export const TicketSegmentPassengerComponent: React.FC<{passengerSegment: IPassengerSegmentViewModel}> = observer((props) => {
    const services = useServices();
    const styles: CSSProperties = {
        width: '100%',
        border: `1px solid ${services.theme.currentTheme.border.mainColor}`,
        borderRadius: services.theme.currentTheme.border.defaultRadius,
        padding: services.theme.currentTheme.spacing.cardDefaultPadding,
        pageBreakInside: "avoid",
        breakInside: "avoid"
    };
    return (
        <div style={styles}>
            <PassengerNameAndSeatComponent passengerSegment={props.passengerSegment}/>
            <TicketPassengerSegmentSsrsComponent passengerSegment={props.passengerSegment}/>
        </div>
    );
})