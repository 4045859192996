import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {NextFlightActionButtonsSkeletonsComponent} from "./next-flight-action-buttons-skeletons.component";
import {PrimaryButton} from "../../../../../../../basic-components/buttons/primary-button";
import {
    IJourneyHistoryViewModel
} from "../../../../../../../services/booking-history/models/journey-history-view-model.interface";
import {useServices} from "../../../../../../../hooks/use-services.hook";
import {ActionSheetButton} from "@ionic/react";
import {PlaneTicketsIcon} from "../../../../../../../assets/svg/plane-tickets.icon";
import {PrimaryShadeButtonInverted} from "../../../../../../../basic-components/buttons/primary-shade-button-inverted";


const BottomSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`


const ActionButtonsContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    gap: ${props => props.theme.spacing.spacing16};
`

export const BoardingPassButtonBox = styled.button`
  background-color: transparent;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-right: 0;
  svg {
      margin-right: 8px;
  }
`

const ActionButtonPrimary = styled(PrimaryButton)`
    ${props => props.theme.containerMediaQuery.xsAndBelow} {
        padding: ${props => props.theme.spacing.spacing16} ${props => props.theme.spacing.spacing16};
    }
`

const ActionButtonSecondary = styled(PrimaryShadeButtonInverted)`
    border: none;
    ${props => props.theme.containerMediaQuery.xsAndBelow} {
        padding: ${props => props.theme.spacing.spacing16} ${props => props.theme.spacing.spacing16};
    }
`

export const NextFlightBottomSectionComponent: React.FC<{flight: IJourneyHistoryViewModel}> = observer((props) => {
    const services = useServices();

    const actions = props.flight.getNextFlightActions();
    const actionSheetButtons = actions.map(action => {
        const button: ActionSheetButton = {
            text: action.text,
            handler: action.execute
        }
        return button;
    });


    const viewBoardingPasses = async () => {
        await services.dialogFactory.showBoardingPasses({
            bookingKey: props.flight.bookingKey,
            boardingPasses: props.flight.boardingPasses
        });
    }

    const renderButtons = () => {

        if(services.maintenance.data.enableMaintenancePage) {
            if (props.flight.boardingPasses.length === 0) {
                return null
            }

            return (

                <BoardingPassButtonBox onClick={viewBoardingPasses}>
                    <PlaneTicketsIcon size={1.4} />
                    {services.language.translate('Boarding pass')}
                </BoardingPassButtonBox>
            );
        }

        if (props.flight.isCanceled) {
            return (
                <PrimaryButton onClick={() => props.flight.startManageMyBooking()} fullWidth={true}>
                    {services.language.translate('Modify')}
                </PrimaryButton>
            )
        }

        if (actionSheetButtons.length === 0) {
            return (
                <ActionButtonsContainerBox>
                    <NextFlightActionButtonsSkeletonsComponent />
                </ActionButtonsContainerBox>


            );
        }
        return (
            <ActionButtonsContainerBox>
                {
                    actionSheetButtons.map((item, index) => {
                        if(index % 2 === 0) {
                            return (<ActionButtonPrimary key={item.text} fullWidth={true} onClick={item.handler}>{item.text}</ActionButtonPrimary>)
                        } else {
                            return (<ActionButtonSecondary key={item.text} fullWidth={true} onClick={item.handler}>{item.text}</ActionButtonSecondary>)
                        }

                    })
                }
            </ActionButtonsContainerBox>
        );
    }

    return (
        <BottomSectionBox>
            {renderButtons()}
        </BottomSectionBox>
    )
})