import React from "react";
import {SeatSsrSmallIcon} from "./seat-ssr.small-icon";
import {SeatSsrLargeIcon} from "./seat-ssr.large-icon";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../ssrs-preferred-orders-in-shopping-cart";
import {SsrTypeBase} from "../../ssr-type-base";
import { SsrCategoryEnum } from "../../ssr-category.enum";
import {SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE, SSR_LARGE_ICON_SIZE} from "../../ssr-types.service.interface";

export abstract class SeatSsrTypeBase extends SsrTypeBase{
    get smallIcon(): React.ReactElement | null {
        return (<SeatSsrSmallIcon />);
    }

    get largeIcon(): React.ReactElement | null {
        return (<SeatSsrLargeIcon />);
    }

    get iconInExtrasFirstLargeTile(): React.ReactElement | null {
        return (<SeatSsrLargeIcon size={SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE} />);
    }

    get iconInExtrasSecondLargeTile(): React.ReactElement | null {
        return (<SeatSsrLargeIcon size={SSR_LARGE_ICON_SIZE} />);
    }

    get iconInExtrasSmallTile(): React.ReactElement | null {
        return (<SeatSsrLargeIcon size={4} />);
    }

    get shouldShowBundleIncludedInShoppingCart(): boolean {
        return false;
    }

    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.SEATS;
    }

    get shouldShowInFlightItineraryExtras(): boolean {
        return false;
    }

    get category(): SsrCategoryEnum {
        return SsrCategoryEnum.Seat;
    }
}

