import React from "react";
import {observer} from "mobx-react";
import {Station} from "../../../../services/stations/station.service.interface";
import {StationDropDownItemModel} from "./station-drop-down-item.model";
import {IFormField} from "../../../../models/forms/form-field.interface";
import {StandardDropDownComponent} from "../../../forms/standard-drop-down/standard-drop-down.component";
import {
    FieldEditorCommonProps
} from "../../../forms/inputs-common.props";

interface StationDropDownComponentProps extends FieldEditorCommonProps {
    field: IFormField<Station>;
    placeholder: string;
    getStations: () => Station[];
    noStationsMessage?: () => React.ReactElement | string;
}



export const StationDropDownComponent: React.FC<StationDropDownComponentProps> = observer((props) => {
    const items = props.getStations().map(s => new StationDropDownItemModel(s));

    return (
        <StandardDropDownComponent {...props}
                           inputStyle={props.inputStyle ?? "insideBorder"}
                           field={props.field}
                           placeholder={props.placeholder}
                           hidePlaceholderWhenDropDownOpen={true}
                           items={items}
                           hideChevron={true}
                           noItemsMessage={props.noStationsMessage}
                           popperOptions={{
                               sameWidthAsElementToAttach: false
                           }}/>
    );
});
