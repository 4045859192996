import React from "react";
import {observer} from "mobx-react";
import {StandardDialogPageComponent} from "../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../components/modal/header/standard-dialog-header.component";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import {StandardDialogContentComponent} from "../../components/modal/content/standard-dialog-content.component";
import {BookingSearchComponent} from "../home/hybrid/common/booking-search/booking-search.component";
import {useServices} from "../../hooks/use-services.hook";
import {ISearchBookingParamsEditor} from "../../services/booking/search-booking-params.editor";
import {IBookingHistoryViewModel} from "../../services/booking-history/models/booking-history-view-model.interface";

export const RetrieveBookingDialogComponent: React.FC<{dialogHandler: IDialogConfirmationHandler; retrieveBooking: (searchParams: ISearchBookingParamsEditor) => Promise<IBookingHistoryViewModel | null>}> = observer((props) => {
    const services = useServices();
    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler}/>
            <StandardDialogContentComponent dialogHandler={props.dialogHandler}>
                <BookingSearchComponent retrieveBookingButtonText={services.language.translate('Retrieve my booking')}
                                        onRetrieveBookingButtonClick={async (searchParams) => {
                                            const result = await props.retrieveBooking(searchParams);
                                            if(result) {
                                                props.dialogHandler.accept();
                                            }
                                        }}/>
            </StandardDialogContentComponent>
        </StandardDialogPageComponent>
    )
})