import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {FlightDatesDropDownContentComponent} from "./content/flight-dates-drop-down-content.component";
import {
    IDropDownController
} from "../../../forms/drop-down/models/drop-down-controller.interface";
import {CalendarIcon} from "../../../../assets/svg/calendar.icon";
import {DropDownComponent} from "../../../forms/drop-down/drop-down.component";
import {FlightDatesDropDownInputComponent} from "./input/flight-dates-drop-down-input.component";
import {FlightSearchDropDownIconComponent} from "../../common/flight-search-drop-down-icon.component";
import {useScreenMediaQueriesChecks} from "../../../../hooks/use-screen-media-queries-checks.hook";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import {FlightsDatesSelectionHeaderComponent} from "../header/flights-dates-selection-header.component";
import {FlightsDatesSelectionFooterComponent} from "../footer/flights-dates-selection-footer.component";
import {ISearchMaskControllerViewModel} from "../../../search-mask/webapp/search-mask-controller.view-model.interface";


interface FlightDatesDropdownComponentProps {
    isReadonly?: boolean;
    onClick?: () => void;
    searchMaskController?: ISearchMaskControllerViewModel;
}

export const FlightDatesDropdownComponent: React.FC<FlightDatesDropdownComponentProps> = observer((props) => {
    const services = useServices();
    const searchController = services.flightSearch.searchController;
    const datesSelector = searchController.datesSelector;
    const screenMediaQuery = useScreenMediaQueriesChecks();

    const getEmptyDropDownMessage = () => {
        if(!searchController.departureOrigin || !searchController.departureDestination) {
            return services.language.translate('Please select departure and destination first');
        }
        return null;
    }
    const renderDropDownInput = (dropDownController: IDropDownController) => {
        props.searchMaskController?.attachFlightDatesDropDownController(dropDownController);
        return (
            <FlightDatesDropDownInputComponent searchController={searchController} dropDownController={dropDownController}/>
        );
    }

    const renderDropDownContent = (dropDownController: IDropDownController) => {

        return (
            <FlightDatesDropDownContentComponent datesSelector={datesSelector} dropDownController={dropDownController} emptyDropDownMessage={getEmptyDropDownMessage()}/>
        )
    }

    const renderDialogHeaderContent = () => {
        if(!(searchController.departureOrigin && searchController.departureDestination)) {
            return (<></>);
        }
        return (
            <FlightsDatesSelectionHeaderComponent flightsDatesSelector={datesSelector}/>
        )
    }

    const renderDialogFooterContent = (dlg: IDialogConfirmationHandler) => {
        if(datesSelector.availableMonths.length === 0) {
            return null;
        }
        return (
            <FlightsDatesSelectionFooterComponent flightsDatesSelector={datesSelector} onDone={() => dlg.accept()}/>
        )
    }

    const renderIcon = () => {
        return (
            <FlightSearchDropDownIconComponent icon={<CalendarIcon/>}/>
        )
    }

    const getLabel = () => {
        return searchController.isOneWay
            ? services.language.translate('Departure date')
            : services.language.translate('Flights dates')
    }

    const isEmpty = Boolean(getEmptyDropDownMessage() || datesSelector.scheduleLoadingError);


    return (
        <DropDownComponent renderLabel={getLabel}
                           renderIcon={renderIcon}
                           errorMessage={searchController.departureDateError || searchController.returnDateError}
                           renderDropDownInput={renderDropDownInput}
                           renderDropDownContent={renderDropDownContent}
                           inputStyle={"insideBorder"}
                           fixedPosition={!screenMediaQuery.smallScreen && !isEmpty}
                           isReadonly={props.isReadonly}
                           onClick={props.onClick}
                           hideChevron={true}
                           modalDialogOptions={{
                               renderHeaderContent: renderDialogHeaderContent,
                               renderFooterContent: renderDialogFooterContent
                           }}
                           popperOptions={{
                               sameWidthAsElementToAttach: false,
                               placement: "bottom"
                           }}

                           />
    )
});
