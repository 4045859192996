import React from "react";
import {observer} from "mobx-react";
import {
    IPassengerSegmentBoardingPassViewModel
} from "../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";
import {StandardDialogPageComponent} from "../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../components/modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../../../components/modal/content/standard-dialog-content.component";
import {BoardingPassesContainerComponent} from "./boarding-passes-container/boarding-passes-container.component";
import {BoardingPassExportAllButtonComponent} from "./export-all-buttons/boarding-pass-export-all-button.component";
import {useServices} from "../../../hooks/use-services.hook";
import {StandardDialogFooterComponent} from "../../../components/modal/footer/standard-dialog-footer.component";
import {
    AddAllBoardingPassesToWalletButtonComponent
} from "../../../components/add-to-wallet-buttons/add-all-boarding-passes-to-wallet-button.component";
import {VerticalSpacer} from "../../../basic-components/spacing/vertical-spacer";


interface BoardingPassesPageProps {
    dialogHandler: IDialogConfirmationHandler;
    bookingKey: string;
    boardingPasses: IPassengerSegmentBoardingPassViewModel[];
}

export const BoardingPassDialogComponent: React.FC<BoardingPassesPageProps> = observer((props) => {
    const services = useServices();
    const boardingPasses = props.boardingPasses;


    const renderAddToWalletButton = () => {
        const eligibleBoardingPasses = services.mobileWallet.filterEligiblePasses(props.boardingPasses);
        if(eligibleBoardingPasses.length === 0) {
            return null;
        }

        return (
            <AddAllBoardingPassesToWalletButtonComponent bookingKey={props.bookingKey} boardingPasses={eligibleBoardingPasses}/>
        );
    }


    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate('Boarding pass')} renderTitleOnTheFirstRow={true}
                                           dialogHandler={props.dialogHandler}/>
            <StandardDialogContentComponent dialogHandler={props.dialogHandler}>
                <BoardingPassExportAllButtonComponent bookingKey={props.bookingKey} boardingPasses={boardingPasses}/>
                <VerticalSpacer size={"medium"}/>
                <BoardingPassesContainerComponent bookingKey={props.bookingKey} boardingPasses={boardingPasses}/>
            </StandardDialogContentComponent>

            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                {renderAddToWalletButton()}
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    );
});
