import {createGlobalStyle} from "styled-components";
export const SCROLLBAR_WIDTH = '7px';

export const GlobalStyles = createGlobalStyle`

    // Chrome and Safari scrollbar
    *::-webkit-scrollbar {
        width: ${SCROLLBAR_WIDTH};
    }

    *::-webkit-scrollbar-track {
        background-color: rgba(${props => props.theme.colors.mediumRgb}, 0.1);
    }

    *::-webkit-scrollbar-thumb {
        background-color: rgba(${props => props.theme.colors.mediumRgb}, 0.5);
    }

    *::-webkit-scrollbar-thumb:hover {
        background-color: rgba(${props => props.theme.colors.mediumRgb}, 0.8);
    }

    html {
        
        font-size: 16px;
        font-family: var(--ion-font-family);
        font-weight: ${props => props.theme.fontWeight.regular};
        scrollbar-gutter: stable;

        ${props => props.theme.screenMediaQuery.sAndBelow} {
            font-size: 14px;
        }
        
        ${props => props.theme.screenMediaQuery.xsAndBelow} {
            font-size: 13px;
        }
        
    }
    
    body {
        font-size: ${props => props.theme.fontSize.body1};
        color: ${props => props.theme.colors.dark};
        background-color: ${props => props.theme.colors.light};
    }
    
    h1 {
        font-size: ${props => props.theme.fontSize.h1};
    }

    h2 {
        font-size: ${props => props.theme.fontSize.h2};
    }

    h3 {
        font-size: ${props => props.theme.fontSize.h3};
    }
    h4 {
        font-size: ${props => props.theme.fontSize.h4};
    }
    h5 {
        font-size: ${props => props.theme.fontSize.h4};
    }
    h6 {
        font-size: ${props => props.theme.fontSize.h6};
    }

    ion-header {
        &:after {
            display: none;
        }
    }
 
    ion-footer {
        &:before {
            display: none;
        }
    }

 

    .shopping-cart-notifications-popover .popover-content {
        border: solid 1px rgba(${props => props.theme.colors.mediumRgb}, 0.75);
        background-color: ${props => props.theme.colors.light};
    }


`
