import React, {useRef} from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../../hooks/use-services.hook";
import styled, {css} from "styled-components";
import {
    ISearchBookingParamsEditor,
    SearchBookingParamsEditor
} from "../../../../../services/booking/search-booking-params.editor";
import {FormComponent, FormComponentType} from "../../../../../components/forms/form.component";
import {StringFieldEditorComponent} from "../../../../../components/forms/string-field/string-field-editor.component";
import {PrimaryButton} from "../../../../../basic-components/buttons/primary-button";
import {EmailFieldEditorComponent} from "../../../../../components/forms/contact/email-field-editor.component";
import {CardBox} from "../../../../../basic-components/card/card.box";
import {BookingSearchStrategyToggleComponent} from "./booking-search-strategy-toggle.component";
import {IFormField} from "../../../../../models/forms/form-field.interface";
import {useContainerMediaQueriesChecks} from "../../../../../hooks/use-container-media-queries-checks.hook";

const RootContainerBox = styled(CardBox)`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${props => props.theme.spacing.spacing30};
    width: 100%;
    padding-bottom: calc(3 * ${props => props.theme.spacing.cardDefaultPadding});

    ${props => props.theme.containerMediaQuery.smallScreen} {
        padding-bottom: ${props => props.theme.spacing.cardDefaultPadding};
    }
    overflow: hidden;
    
`

const FormFieldsBox = styled(FormComponent)`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: ${props => props.theme.spacing.spacing20};
    row-gap: 4px;
    ${props => props.theme.containerMediaQuery.smallScreen} {
        grid-template-columns: 1fr;
        column-gap: 0;
    }
`


const BookingSearchTitleBox = styled.div`
    width: 100%;
    font-size: ${props => props.theme.fontSize.h1};
    font-weight: ${props => props.theme.fontWeight.bold};
`


const SearchButton = styled(PrimaryButton)`
    ${props => props.theme.containerMediaQuery.smallScreen} {
        margin-top: ${props => props.theme.spacing.spacing20};
    }
`

const FieldLabelBox = styled.div<{$hasError: boolean}>`
    padding-left: 2px;
    
    ${props => props.$hasError && css`
        color: ${props => props.theme.colors.danger};
    `}

    ${props => props.theme.containerMediaQuery.smallScreen} {
        margin-top: ${props => props.theme.spacing.spacing16};
    }
    
    &:first-of-type {
        margin-top: 0;
    }
    
`

const FieldLabelComponent: React.FC<{field: IFormField}> = observer((props) => {
    if(props.field.isHidden) {
        return null;
    }

    return (
        <FieldLabelBox $hasError={props.field.hasError}>
            {props.field.fieldName}
        </FieldLabelBox>
    )
})

const SearchStrategyToggleWrapperBox = styled.div<{$hasError: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    grid-column: 2;
    grid-row: 3;
    padding-left: 2px;
    
    ${props => props.$hasError
        ? css`
            padding-top: ${props => props.theme.spacing.spacing12};
        `
        : css`
            padding-top: ${props => props.theme.spacing.spacing8};
        `    
    }

    ${props => props.theme.containerMediaQuery.smallScreen} {
        grid-column: unset;
        grid-row: unset;
        padding-top: ${props => props.theme.spacing.spacing16};
    }
`


export interface BookingSearchComponentProps {
    title?: string;
    hideTitle?: boolean;
    retrieveBookingButtonText: string;
    onRetrieveBookingButtonClick: (paramsEditor: ISearchBookingParamsEditor) => void;
    className?: string;
}

export const BookingSearchComponent: React.FC<BookingSearchComponentProps> = observer((props) => {
    const services = useServices();
    const containerMediaQuery = useContainerMediaQueriesChecks();
    const searchParamsEditorRef = useRef(new SearchBookingParamsEditor(services));
    const searchParamsEditor = searchParamsEditorRef.current;

    const onSubmitHandler = () => {
        props.onRetrieveBookingButtonClick(searchParamsEditor);
    }

    const renderTitle = () => {
        if(props.hideTitle) {
            return null;
        }
        let titleText = services.language.translate(`Let's find your booking`);
        if(services.layout.shouldUseWebappLayout) {
            titleText = props.title ?? titleText;
        }

        return (
            <BookingSearchTitleBox>
                {titleText}
            </BookingSearchTitleBox>
        )
    }

    const hasErrors = searchParamsEditor.hasErrors();

    const renderForSmallScreen = () => {
        return (
            <>
                <FieldLabelComponent field={searchParamsEditor.fields.recordLocator}/>
                <StringFieldEditorComponent field={searchParamsEditor.fields.recordLocator}
                                            hideLabel={true}/>

                <FieldLabelComponent field={searchParamsEditor.fields.lastName}/>
                <StringFieldEditorComponent field={searchParamsEditor.fields.lastName}
                                            hideLabel={true}/>

                <FieldLabelComponent field={searchParamsEditor.fields.emailAddress}/>
                <EmailFieldEditorComponent field={searchParamsEditor.fields.emailAddress}
                                           hideLabel={true}/>

                <SearchStrategyToggleWrapperBox $hasError={hasErrors}>
                    <BookingSearchStrategyToggleComponent paramsEditor={searchParamsEditor}/>
                </SearchStrategyToggleWrapperBox>

                <SearchButton onClick={onSubmitHandler} fullWidth={true}>
                    {props.retrieveBookingButtonText}
                </SearchButton>

            </>
        )
    }

    const renderForLargeScreen = () => {
        return (
            <>
                <FieldLabelComponent field={searchParamsEditor.fields.recordLocator}/>
                <FieldLabelComponent field={searchParamsEditor.fields.lastName}/>
                <FieldLabelComponent field={searchParamsEditor.fields.emailAddress}/>
                <div/>

                <StringFieldEditorComponent field={searchParamsEditor.fields.recordLocator}
                                            hideLabel={true}/>

                <StringFieldEditorComponent field={searchParamsEditor.fields.lastName}
                                            hideLabel={true}/>
                <EmailFieldEditorComponent field={searchParamsEditor.fields.emailAddress}
                                           hideLabel={true}/>

                <SearchButton onClick={onSubmitHandler} fullWidth={true}>
                    {props.retrieveBookingButtonText}
                </SearchButton>

                <SearchStrategyToggleWrapperBox $hasError={hasErrors}>
                    <BookingSearchStrategyToggleComponent paramsEditor={searchParamsEditor}/>
                </SearchStrategyToggleWrapperBox>
            </>
        )
    }

    const renderFields = () => {
        if(containerMediaQuery.smallScreen) {
            return renderForSmallScreen();
        } else {
            return renderForLargeScreen();
        }
    }

    return (
        <RootContainerBox className={props.className}>

            {renderTitle()}

            <FormFieldsBox type={FormComponentType.Flat}>
                {renderFields()}
            </FormFieldsBox>

        </RootContainerBox>
    )
});
