import React from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {PlaneTicketsIcon} from "../../assets/svg/plane-tickets.icon";
import {useServices} from "../../hooks/use-services.hook";
import {NullableString} from "../../types/nullable-types";

const BookingCodeBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap12};
    font-weight: ${props => props.theme.fontWeight.regular};
    color: ${props => props.theme.colors.darkTint};
    cursor: pointer;
`

const BookingLabelsContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    
`

const BookingCodeLabelBox = styled.div`
    font-size: ${props => props.theme.fontSize.body2};
`

const RecordLocatorBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap8};
    font-size: ${props => props.theme.fontSize.h2};
    font-weight: ${props => props.theme.fontWeight.bold};
`


export const BookingCodeComponent: React.FC<{recordLocator: NullableString; className?: string; contentClassName?: string}> = observer((props) => {
    const services = useServices();

    if(!props.recordLocator) {
        return null;
    }

    const onClickHandler = async () => {

        if(props.recordLocator) {
            await services.device.copyToClipboard(props.recordLocator);
        }
    }

    return (
        <BookingCodeBox className={props.className} onClick={onClickHandler}>

            <BookingLabelsContainerBox className={props.contentClassName}>
                <BookingCodeLabelBox>
                    {services.language.translate('Booking code')}
                </BookingCodeLabelBox>
                <RecordLocatorBox>
                    <span>
                        {props.recordLocator}
                    </span>
                </RecordLocatorBox>
            </BookingLabelsContainerBox>
            <PlaneTicketsIcon size={1.8}/>
        </BookingCodeBox>
    )
});
