import React, {PropsWithChildren, useEffect, useRef} from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {IonContent, ScrollDetail} from "@ionic/react";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";
import {useElementScrollHeight} from "../../../hooks/use-element-scroll-height";
import {CSS_CLASS_STANDARD_DIALOG_CONTENT_WRAPPER} from "./dialog-content-css-classes";



const DialogContentBox = styled(IonContent)`
    --background: ${props => props.theme.colors.dialogContentBackground};
    --color: ${props => props.theme.colors.dialogContentColor};
    --padding-top: ${props => props.theme.spacing.dialogVerticalPadding};
    --padding-bottom: ${props => props.theme.spacing.dialogVerticalPadding};
    --padding-start: ${props => props.theme.spacing.dialogHorizontalPadding};
    --padding-end: ${props => props.theme.spacing.dialogHorizontalPadding};
    --width: 100%;
    position: relative; /* this is for drop down popper that uses strategy: absolute*/
`

const ContentWrapperBox = styled.div<{$fullHeight: boolean}>`
    width: 100%;
    display: flex;
    flex-direction: column;
    
    ${
        props => props.$fullHeight
            ? css`
                    min-height: 100%;
            `
            : css`
                    min-height: 350px;
            `
    }
`

interface StandardDialogContentComponentProps extends PropsWithChildren {
    className?: string;
    onScroll?: (event: CustomEvent<ScrollDetail>) => void;
    dialogHandler: IDialogConfirmationHandler;
    fullContentHeight?: boolean;
    ignoreVerticalPaddingsWhenComputeHeight?: boolean;
}
export const StandardDialogContentComponent: React.FC<StandardDialogContentComponentProps> = observer((props) => {
    const contentWrapperRef = useRef<HTMLDivElement | null>(null);
    const scrollHeight = useElementScrollHeight(contentWrapperRef);

    useEffect(() => {
        const verticalPaddings = (2 * 20) + 10; //extra 10px to avoid scrollbar to appear because of few pixels difference.
        if(props.ignoreVerticalPaddingsWhenComputeHeight) {
            props.dialogHandler.contentHeight = scrollHeight;
        } else {
            props.dialogHandler.contentHeight = scrollHeight + verticalPaddings;
        }

    }, [props.dialogHandler, scrollHeight, props.ignoreVerticalPaddingsWhenComputeHeight]);

    const onScrollHandler = (event: CustomEvent<ScrollDetail>) => {
        props.dialogHandler.hasScroll = event.detail.scrollTop > 4;

        if(props.onScroll) {
            props.onScroll(event);
        }
    }

    return (
        <DialogContentBox className={props.className} onIonScroll={onScrollHandler} scrollEvents={true}>
            <ContentWrapperBox ref={contentWrapperRef}
                               $fullHeight={Boolean(props.fullContentHeight)}
                               className={CSS_CLASS_STANDARD_DIALOG_CONTENT_WRAPPER}>
                {props.children}
            </ContentWrapperBox>

        </DialogContentBox>
    )
});
