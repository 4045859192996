import React from 'react';
import {SeatFilterBaseModel} from "./seat-filter-base.model";
import {SeatModel} from "../seat.model";
import {WheelchairIcon} from "../../../../../assets/svg/wheelchair.icon";

export class SpecialAssistanceSeatFilterModel extends SeatFilterBaseModel {

    get description(): string {
        return this.services.language.translate('Seats for special assistance');
    }

    renderIcon(): React.ReactElement {
        return (<WheelchairIcon size={1.4}/>);
    }

    shouldAllowSeat(seat: SeatModel): boolean {
        return seat.allowsSpecialAssistance;
    }

}
