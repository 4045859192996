import React, {useState} from "react";
import {observer} from "mobx-react";
import {FieldInputContainerComponent} from "../field-input-container.component";
import {
    FieldInputContainerComponentProps,
    InputContainerCssClassesForOutsideBordersStyle
} from "../inputs-common.props";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {useServices} from "../../../hooks/use-services.hook";
import styled, {css} from "styled-components";
import {PhoneNumberInputComponentProps} from "./phone-number-input.component.props";
import classNames from "classnames";

const PHONE_CONTAINER_ELEMENT_CSS_CLASS = 'react-phone-container-element';
const PHONE_INPUT_ELEMENT_CSS_CLASS = 'react-phone-input-element';
const PHONE_BUTTON_ELEMENT_CSS_CLASS = 'react-phone-button-element';
const PHONE_BUTTON_FOCUSED_ELEMENT_CSS_CLASS = 'react-phone-button-element-focused';
const PHONE_SEARCH_INPUT_CSS_CLASS = 'search-box';

const CssClassesForOutsideBorders: InputContainerCssClassesForOutsideBordersStyle = {
    inputAndIconContainer: 'react-phone-input-and-icon-container'
}


const FieldInputContainer = styled(FieldInputContainerComponent)`
    & .${CssClassesForOutsideBorders.inputAndIconContainer} {
        border: none;
        padding: 0;
    }
`


const ReactPhoneInputComponent = styled(PhoneInput)<{$hasError: boolean}>`

    width: 100%;
    & .${PHONE_CONTAINER_ELEMENT_CSS_CLASS} {
        width: 100%;
        border-radius: ${props => props.theme.border.defaultRadius};
        border: none;
    }

    & .${PHONE_INPUT_ELEMENT_CSS_CLASS} {
        width: 100%;
        height: 100%;
        border-top-right-radius: ${props => props.theme.border.defaultRadius};
        border-bottom-right-radius: ${props => props.theme.border.defaultRadius};
        border-radius: ${props => props.theme.border.defaultRadius};
        padding-top: ${props => props.theme.spacing.spacing24};
        padding-bottom: ${props => props.theme.spacing.spacing24};
        padding-left: calc(4 * ${props => props.theme.spacing.spacing20});
        font-size: ${props => props.theme.fontSize.h6};
        font-family: var(--ion-font-family);
        color: ${props => props.theme.colors.inputFieldColor};
        background-color: ${props => props.theme.colors.inputFieldBackground};

        ${props => props.$hasError
                ? css`
                    color: ${props => props.theme.colors.danger};
                    border-color: ${props => props.theme.colors.danger};
                `
                : css`
                    border-color: ${props => props.theme.border.mainColor};
                    &:focus-within {
                        border-color: ${props => props.theme.border.focusedColor};
                    }
                `
        }
        ${props => props.disabled && css`
            background-color: ${props => props.theme.colors.light};
        `}
    }

    & .${PHONE_BUTTON_ELEMENT_CSS_CLASS} {
        border-top-left-radius: ${props => props.theme.border.defaultRadius};
        border-bottom-left-radius: ${props => props.theme.border.defaultRadius};
        padding: 6px ${props => props.theme.spacing.spacing12};
        background-color: inherit;
        font-family: var(--ion-font-family);
        font-size: ${props => props.theme.fontSize.h6};
        
        ${props => props.$hasError
                ? css`
                    color: ${props => props.theme.colors.danger};
                    border-color: ${props => props.theme.colors.danger};
                `
                : css`
                    border-color: ${props => props.theme.border.mainColor};
                    
                `
        }
    }

    & .${PHONE_BUTTON_ELEMENT_CSS_CLASS}.open {
        border-top-left-radius: ${props => props.theme.border.defaultRadius};
        border-bottom-left-radius: ${props => props.theme.border.defaultRadius};
        ${props => props.$hasError
                ? css`
                    color: ${props => props.theme.colors.danger};
                    border: 1px solid ${props => props.theme.colors.danger};
                `
                : css`
                    border: 1px solid ${props => props.theme.border.mainColor};
                `
        }
    }
    
    & .${PHONE_BUTTON_FOCUSED_ELEMENT_CSS_CLASS} {
        border-left: none;
        border-top: none;
        border-bottom: none;
        ${props => !props.$hasError
                ? css`
                    border-right-color: ${props => props.theme.border.focusedColor};
                `
                :``
        }
    }

    & .${PHONE_BUTTON_ELEMENT_CSS_CLASS} {
        .${PHONE_SEARCH_INPUT_CSS_CLASS} {
            width: 85%;
            border-radius: ${props => props.theme.border.defaultRadius};
            padding: ${props => props.theme.spacing.spacing8};
        }
        
    }
    
    
    
`



export const PhoneNumberInputOutsideBordersComponent: React.FC<PhoneNumberInputComponentProps> = observer((props) => {
    const services = useServices();
    const [isFocused, setIsFocused] = useState(false);
    const onChange = (value: string) => {
        props.field.setValue(value);
    }

    const onFocusHandler = () => {
        setIsFocused(true)
    }

    const onBlurHandler = () => {
        setIsFocused(false)
    }

    let buttonCssClasses: any = {
        [PHONE_BUTTON_ELEMENT_CSS_CLASS]: true,
    }

    if(isFocused) {
        buttonCssClasses[PHONE_BUTTON_FOCUSED_ELEMENT_CSS_CLASS] = true;
    }

    const renderInput = () => {
        return (
            <ReactPhoneInputComponent country={services.configuration.defaultCountryCode.toLowerCase()}
                                      onFocus={onFocusHandler}
                                      onBlur={onBlurHandler}
                                      $hasError={props.field.hasError}
                                      value={props.field.value}
                                      onChange={onChange}
                                      enableSearch={true}
                                      disabled={props.isReadOnly}
                                      containerClass={PHONE_CONTAINER_ELEMENT_CSS_CLASS}
                                      inputClass={PHONE_INPUT_ELEMENT_CSS_CLASS}
                                      buttonClass={classNames(buttonCssClasses)}/>
        );
    }


    const fieldContainerProps: FieldInputContainerComponentProps = {
        ...props,
        cssClassesForOutsideBordersStyle: {
            ...CssClassesForOutsideBorders,
            ...props.cssClassesForOutsideBordersStyle
        },
        renderInput: renderInput
    }

    return (
        <FieldInputContainer {...fieldContainerProps} />
    )

})