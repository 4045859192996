import React, {useEffect, useState} from "react";
import {
    BookingSearchStrategyEnum,
    SearchBookingParamsEditor
} from "../../../../../services/booking/search-booking-params.editor";
import {observer} from "mobx-react";
import {useServices} from "../../../../../hooks/use-services.hook";
import {IFormField} from "../../../../../models/forms/form-field.interface";
import styled from "styled-components";
import {arrowForwardOutline} from "ionicons/icons";
import {IonIcon} from "@ionic/react";

const ContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    
    font-size: ${props => props.theme.fontSize.body2};
    color: ${props => props.theme.colors.darkTint};
    cursor: pointer;
`

const FindBookingByBox = styled.div`
    display: flex;
    align-items: center;
`


const SwitchModeBox = styled.div`
    font-weight: ${props => props.theme.fontWeight.bold};
`



export const BookingSearchStrategyToggleComponent: React.FC<{paramsEditor: SearchBookingParamsEditor}> = observer((props) => {
    const services = useServices();
    const [fieldToFocus, setFieldToFocus] = useState<IFormField | null>(null);

    useEffect(() => {
        if(fieldToFocus) {
            fieldToFocus.setFocus({
                noScroll: true
            });
            setFieldToFocus(null);
        }
    }, [fieldToFocus]);

    const setFocusOnField = (field: IFormField) => {
        setFieldToFocus(field);
    }

    const onToggleStrategy = () => {
        if(props.paramsEditor.fields.strategy.value === BookingSearchStrategyEnum.byName) {
            props.paramsEditor.fields.strategy.setValue(BookingSearchStrategyEnum.byEmail);
            setFocusOnField(props.paramsEditor.fields.emailAddress);

        } else {
            props.paramsEditor.fields.strategy.setValue(BookingSearchStrategyEnum.byName);
            setFocusOnField(props.paramsEditor.fields.lastName);
        }
    }

    const renderText = () => {
        if(props.paramsEditor.fields.strategy.value === BookingSearchStrategyEnum.byName) {
            return services.language.translate('email');
        } else {
            return services.language.translate('last name');
        }
    }



    return (
        <ContainerBox onClick={onToggleStrategy}>
            <FindBookingByBox>
                {services.language.translate('or find booking by')}
            </FindBookingByBox>
            <SwitchModeBox>
                {renderText()}
            </SwitchModeBox>
            <IonIcon icon={arrowForwardOutline}/>
        </ContainerBox>
    );
})