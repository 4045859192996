import React from "react";
import {observer} from "mobx-react";
import {PaymentCreditCardIcon} from "../../../../assets/svg/payment-credit-card.icon";
import {useServices} from "../../../../hooks/use-services.hook";
import styled from "styled-components";

const ContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap8};
    
`

interface CreditCardPaymentsMethodTabHeaderComponentProps {

}
export const CreditCardPaymentMethodsTabHeaderComponent: React.FC<CreditCardPaymentsMethodTabHeaderComponentProps> = observer(() => {
    const services = useServices();
    return (
        <ContainerBox>
            <PaymentCreditCardIcon />
            <div>{services.language.translate('Credit Card')}</div>
        </ContainerBox>
    )
})