import React from 'react';
import {observer} from "mobx-react-lite";
import {ContinueButtonComponent} from "../../../../components/continue-button/continue-button.component";

interface BookingFlowContinueButtonSmallScreenComponentProps {
    onClick: () => void;
    fullWidth?: boolean;
    inactive?: boolean;
    className?: string;
    customButtonText?: string;
}
export const BookingFlowContinueButtonSmallScreenComponent: React.FC<BookingFlowContinueButtonSmallScreenComponentProps> = observer((props) => {
    return (
        <ContinueButtonComponent onClick={props.onClick}
                                 fullWidth={props.fullWidth}
                                 inactive={props.inactive} className={props.className}
                                 customButtonText={props.customButtonText}/>
    );
});
