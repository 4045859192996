import React from "react";
import { IHomePageTabViewModel } from "../../../../../services/home-page/tabs/home-page.tab.interface";
import { HomePageTabBase } from "../../../../../services/home-page/tabs/home-page-tab-base";
import { HybridHomePageHeaderComponent } from "../../header/hybrid-home-page-header.component";
import {PlaneTicketsSimpleIcon} from "../../../../../assets/svg/plane-tickets-simple.icon";
import {BookTabContentComponent} from "./components/book-tab-content.component";

export class BookTab extends HomePageTabBase implements IHomePageTabViewModel {

    get text(): string {
        return this.services.language.translate('Book');
    }

    renderIcon(): React.ReactElement {
        return (<PlaneTicketsSimpleIcon size={2}/>);
    }

    renderHeader(): React.ReactElement | null {
        return (
            <HybridHomePageHeaderComponent/>
        );
    }

    renderContent(): React.ReactElement {
        return (
            <BookTabContentComponent/>
        )
    }
}
