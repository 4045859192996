 import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {BookingFlowPage} from "../booking-flow.page";
import {BagsSelectionPageContentComponent} from "./bags-selection-page-content.component";
import styled from "styled-components";
import {SameForAllFlightsToggleComponent} from "../common-components/same-for-all-flights-toggle.component";

const PageTitleBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const PageTitleForLargeScreenComponent: React.FC = observer(() => {
    const services = useServices();

    if(!services.booking.currentPageCanBeRendered) {
        return null;
    }

    const ssrsAggregator = services.booking.current.bagsSsrsAggregator;
    const renderSameForAllFlightsToggle = () => {
        if(!ssrsAggregator.allowUseSameOptionsForAllFlights) {
            return null;
        }


        return (
            <SameForAllFlightsToggleComponent ssrsAggregator={ssrsAggregator}/>

        );
    }

    return (
        <PageTitleBox>
            <div>
                {services.language.translate('Select bags')}
            </div>
            {renderSameForAllFlightsToggle()}
        </PageTitleBox>
    )
});

export const BagsSelectionPage: React.FC = observer(() => {
    const services = useServices();

    const renderTitle = () => {
        if(services.screenMediaQuery.smallScreen) {
            return services.language.translate('Select bags');
        } else {
            return (
                <PageTitleForLargeScreenComponent/>
            )
        }
    }

    return (
        <BookingFlowPage headerOptions={{
                            title: renderTitle()
                         }}
                         contentOptions={{
                             renderContent: () => <BagsSelectionPageContentComponent dialogHandler={null}/>
                         }}
                         shoppingCartOptions={{
                             showPromotionCode: true
                         }}/>
    );
});
