import React from "react";
import {observer} from "mobx-react-lite";
import {NullablePrice, Price} from "../../../../services/currency/price";
import styled from "styled-components";
import {useServices} from "../../../../hooks/use-services.hook";

const DetailsSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap8};
    line-height: 1;
`

const TitleBox = styled.div`
    width: 100%;
    font-size: ${props => props.theme.fontSize.h2};
    font-weight: ${props => props.theme.fontWeight.bold};
    
`

const PriceRowBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: ${props => props.theme.fontSize.h5};
`

const PriceLabelBox = styled.div`
    display: flex;
    flex-grow: 1;
`

const PriceBox = styled.div`
`



interface ShoppingCartPriceDetailsSectionComponentProps {
    title?: string;
    initialPrice: NullablePrice;
    currentPrice: Price;
}

export const ShoppingCartPriceDetailsSectionComponent: React.FC<ShoppingCartPriceDetailsSectionComponentProps> = observer((props) => {
    const services = useServices();
    return (
        <DetailsSectionBox>
            {props.title && <TitleBox>{props.title}</TitleBox>}

            <PriceRowBox>
                <PriceLabelBox>
                    {services.language.translate('Previous price')}
                </PriceLabelBox>
                <PriceBox>
                    {props.initialPrice?.toString()}
                </PriceBox>
            </PriceRowBox>

            <PriceRowBox>
                <PriceLabelBox>
                    {services.language.translate('Current price')}
                </PriceLabelBox>
                <PriceBox>
                    {props.currentPrice.toString()}
                </PriceBox>
            </PriceRowBox>

        </DetailsSectionBox>
    );
});
