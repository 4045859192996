import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../../../hooks/use-services.hook";
import {BlueBenefitsDiscountInfoComponent} from "./blue-benefits-discount-info.component";

const CurrentUserSubscriptionBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const PackageInfoBox = styled.div`
    display: flex;
    font-size: ${props => props.theme.fontSize.h6};
    background-color: ${props => props.theme.colors.light};
    border-radius: 5px;
    padding: 10px;
    flex-flow: row wrap;
    
`
const MembershipBox = styled.div`
    display: flex;
    color: ${props => props.theme.colors.medium};
    font-size: ${props => props.theme.fontSize.h5};
    margin-top: 2px;
`

const MembershipInfoBox = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 20px;
    text-align: center;
`
const SubscriptionNameBox = styled.div`
    font-size: ${props => props.theme.fontSize.h4};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.colors.secondary};
`

const ExpirationDateBox = styled.div`
  font-size: ${props => props.theme.fontSize.h5};
  flex: 1 0 100%;
  color: ${props => props.theme.colors.medium};
  margin-top: 12px;
  display: flex;
`

const PartnersCountDescriptionBox = styled.div`
  font-size: ${props => props.theme.fontSize.h5};
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.colors.secondary};
  margin-top: 2px;
`

const BenefitsBox = styled.div`
    display: flex;
    flex-direction: column;
`

const ExpiredSubscriptonBox = styled.div`
    color: ${props => props.theme.colors.danger};
    position: relative;
    display: flex;
    align-items: center;
    font-weight: ${props => props.theme.fontWeight.bold};

    &:before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: red;
        margin: 0 5px;
    }
`

export const BlueBenefitsCurrentUserSubscriptionComponent: React.FC = observer(() => {
    const services = useServices();
    const bbSubscription = services.user.profile.blueBenefitsSubscription;
    return (
        <CurrentUserSubscriptionBox>
            <PackageInfoBox>
                <MembershipBox>
                    {services.language.translate('Membership')}
                </MembershipBox>
                <MembershipInfoBox>
                    <SubscriptionNameBox>
                        {bbSubscription.packageName}
                    </SubscriptionNameBox>
                    <PartnersCountDescriptionBox>
                        {bbSubscription.partnersCountDescription}
                    </PartnersCountDescriptionBox>
                </MembershipInfoBox>
                
                <ExpirationDateBox>
                    {services.language.translationFor('Expires on {expirationDate}').withParams({expirationDate: bbSubscription.getFormattedExpirationDate()})}
                    {bbSubscription.isExpired ? <ExpiredSubscriptonBox>{services.language.translate('Expired')}</ExpiredSubscriptonBox> : ''}
                </ExpirationDateBox>

            </PackageInfoBox>
            <BenefitsBox>
                {bbSubscription.getBenefits().map(benefit => <BlueBenefitsDiscountInfoComponent key={benefit.description} discountInfo={benefit}/>)}
            </BenefitsBox>
        </CurrentUserSubscriptionBox>
    );
});
