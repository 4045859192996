import React, {FormEvent, PropsWithChildren} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {FormTitleComponent} from "../forms-view/form-title.component";
import { SectionSeparator } from "../forms-view/section-separator";
import {CardBox} from "../../basic-components/card/card.box";
import {Check} from "../../types/type-checking";

const FormBox = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.spacing30};
    
    >div {
        &:last-of-type {
            ${SectionSeparator}{
                display: none;
            }
        }
    }
`

const FormCardBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap24};
    padding-bottom: calc(2 * ${props => props.theme.spacing.cardDefaultPadding});
    
`

const SubmitButtonBox = styled.input`
    display: none;
`

export enum FormComponentType {
    Card,
    Flat
}

interface FormComponentProps extends PropsWithChildren {
    title?: string;
    type?: FormComponentType;
    onSubmit?: () => void;
    className?: string;
}

export const FormComponent: React.FC<FormComponentProps> = observer((props) => {

    const type = Check.isNullOrUndefined(props.type) ? FormComponentType.Card : FormComponentType.Flat;

    const renderChildren = () => {

        return (
            <>
                {props.title && <FormTitleComponent title={props.title}/>}
                {props.children}
            </>
        );


    }

    const renderContent = () => {
        if(type === FormComponentType.Card) {
            return (
                <FormCardBox>
                    {renderChildren()}
                </FormCardBox>
            )
        }

        return renderChildren();
    }

    const onSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(props.onSubmit) {
            props.onSubmit();
        }
    }

    const renderSubmitButton = () => {
        if(props.onSubmit) {
            return (
                <SubmitButtonBox type={"submit"}/>
            );
        }

        return null;
    }

    return (
        <FormBox onSubmit={onSubmitHandler} autoComplete="off" autoCorrect="off" autoSave={"off"} className={props.className}>
            {renderContent()}
            {renderSubmitButton()}
        </FormBox>
    )
});
