import React, {CSSProperties} from "react";
import {IPassengerSegmentBoardingPassViewModel} from "../../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {BoardingPassFieldLabelComponent} from "../label/boarding-pass-field-label.component";
import {BoardingPassFieldValueComponent} from "../value/boarding-pass-field-value.component";
import {useServices} from "../../../../hooks/use-services.hook";

export const BoardingPassPassengerFieldComponent: React.FC<{label: string; value: string; rootStyles?: CSSProperties; fieldValueStyles?: CSSProperties}> = (props) => {
    const services = useServices();
    const theme = services.theme.currentTheme;
    const rootStyles: CSSProperties = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...props.rootStyles
    }

    const valueStyles: CSSProperties = {
        paddingTop: '0',
        justifyContent: 'flex-start',
        color: theme.colors.primary,
        ...props.fieldValueStyles
    }



    return (
        <div style={rootStyles}>
            <BoardingPassFieldLabelComponent>
                {props.label}
            </BoardingPassFieldLabelComponent>
            <BoardingPassFieldValueComponent styles={valueStyles}>
                {props.value}
            </BoardingPassFieldValueComponent>
        </div>
    )
}


export const BoardingPassDocumentFieldComponent: React.FC<{label: string; value: string;}> = (props) => {

    return (
        <BoardingPassPassengerFieldComponent label={props.label} value={props.value} fieldValueStyles={{color: 'inherit'}}/>
    )
}


const PassengerPrimaryFieldsComponent: React.FC<{boardingPass: IPassengerSegmentBoardingPassViewModel}> = (props) => {
    const services = useServices();
    const styles: CSSProperties = {
        display: "flex",
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: 'space-between',
        gap: services.theme.currentTheme.spacing.flexGap16
    }

    const fieldValueStyles: CSSProperties = {
        fontSize: services.theme.currentTheme.fontSize.h4
    }


    return (
        <div style={styles}>
            <BoardingPassPassengerFieldComponent label={services.language.translate('Passenger')}
                                                 value={props.boardingPass.fullName}
                                                 rootStyles={{alignItems: 'flex-start'}}
                                                 fieldValueStyles={fieldValueStyles}/>

            <BoardingPassPassengerFieldComponent label={services.language.translate('Date of birth')}
                                                 value={props.boardingPass.dateOfBirth}
                                                 fieldValueStyles={fieldValueStyles}/>

            <BoardingPassPassengerFieldComponent label={services.language.translate('Citizenship')}
                                                 value={props.boardingPass.nationality}
                                                 fieldValueStyles={fieldValueStyles}/>
        </div>
    )
}

const DocumentFieldsComponent: React.FC<{boardingPass: IPassengerSegmentBoardingPassViewModel}> = (props) => {
    const services = useServices();
    const styles: CSSProperties = {
        display: "flex",
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: 'space-between',
        gap: services.theme.currentTheme.spacing.flexGap16
    }


    if (!(props.boardingPass.documentTypeCode || props.boardingPass.documentNumber || props.boardingPass.documentExpirationDate)) {
        return null;
    }


    return (
        <div style={styles}>
            <BoardingPassDocumentFieldComponent label={services.language.translate('Document type:')}
                                                value={services.travelDocumentType.tryGetDocumentTypeName(props.boardingPass.documentTypeCode) ?? ""}/>

            <BoardingPassDocumentFieldComponent label={services.language.translate('Document Number:')}
                                                value={props.boardingPass.documentNumber ?? ""}/>

            <BoardingPassDocumentFieldComponent label={services.language.translate('Expiry date:')}
                                                value={services.time.formatYYY_MM_DD(props.boardingPass.documentExpirationDate)}/>
        </div>
    )
}

export const BoardingPassPassengerComponent: React.FC<{boardingPass: IPassengerSegmentBoardingPassViewModel}> = (props) => {
    const services = useServices();
    const styles: CSSProperties = {
        display: "flex",
        flexDirection: "column",
        width: '100%',
        gap: services.theme.currentTheme.spacing.spacing16
    }

    return (
        <div style={styles}>
            <PassengerPrimaryFieldsComponent boardingPass={props.boardingPass}/>
            <DocumentFieldsComponent boardingPass={props.boardingPass}/>
        </div>
    )
}
