import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {
    IFlightDesignatorViewModel
} from "../../../../services/booking/models/designator/flight-designator-view-model.interface";

const FlightNumberBox = styled.div`
    color: ${props => props.theme.colors.notesTint};
    font-size: ${props => props.theme.fontSize.caption};
`

export const FlightNumberComponent: React.FC<{designator: IFlightDesignatorViewModel | null}> = observer((props) => {
    const flightIdentifier = props.designator?.flightIdentifier;
    if(!flightIdentifier) {
        return null;
    }

    return (
        <FlightNumberBox>
            {flightIdentifier.fullIdentifierCode()}
        </FlightNumberBox>
    )
})