import React, {useRef} from "react";
import {useServices} from "../../../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {PriceComponent} from "../../../../../components/price/price.component";
import {PlaneNotAvailableIcon} from "../../../../../assets/svg/plane-not-available.icon";
import useIsInViewPort from "../../../../../hooks/use-is-in-view-port.hook";
import {
    ILowFareResult,
    LowFareStatusEnum
} from "../../../../../services/low-fare/low-fare-readers/low-fare-reader.interface";
import {IFlightsScheduleViewModel} from "../../../../../services/flight-search/flights-schedule/flights-schedule-view-model.interface";
import {SwiperComponent} from "../../../../../basic-components/swiper/swiper.component";
import {SwiperRef, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {
    SwiperNavigationBackButtonComponent, SwiperNavigationNextButtonComponent
} from "../../../../../basic-components/swiper/swiper-navigation-button.component";
import {useContainerMediaQueriesChecks} from "../../../../../hooks/use-container-media-queries-checks.hook";
import {SWIPER_SLIDE_CSS_CLASS} from "../../../../../basic-components/swiper/swiper-consts";
import {IonSpinnerComponent} from "../../../../../components/spinner/ion-spinner.component";


const FlightDatesContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    justify-content: center;
    width: 100%;
    
`

const SwiperBox = styled(SwiperComponent)`
    & .${SWIPER_SLIDE_CSS_CLASS} {
        padding: ${props => props.theme.spacing.spacing8} 0;
    }
`

const DateButtonWrapperBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: ${props => props.theme.border.defaultRadius};
    min-height: 67px;
    ${props => props.theme.screenMediaQuery.smallScreen} {
        min-height: 61px;
    }
`

interface DateButtonBoxProps {
    $isSelected: boolean;
    $disabled: boolean;
}


const DateButtonBox = styled.div<DateButtonBoxProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
    cursor: ${props => props.$disabled ? "not-allowed" : (props.$isSelected ? "default" : "pointer")};
    background-color: ${props => props.theme.colors.primaryContrast};
    border-radius: ${props => props.theme.border.defaultRadius};
    padding: ${props => props.theme.spacing.spacing8} ${props => props.theme.spacing.spacing16};
    gap: 4px;
    min-height: 50px;
    box-shadow: ${props => props.theme.boxShadow.shadow2};
    ${
          props => props.$isSelected
                  ? css`
                    
                    border-bottom: 4px solid ${props => props.theme.colors.primary};
                    transition: transform 400ms;
                    transform: scaleY(1.2);  
                  `
                  : ``
    }
`;


const PriceBox = styled.div<{$isSelected: boolean}>`
    font-size: ${p => p.theme.fontSize.subtitle1};
    font-weight: ${props => props.theme.fontWeight.bold};
    ${
        props => !props.$isSelected 
                    ? css`
                        color: ${p => p.theme.colors.notes};
                    `
                    : ''
    }

`;

const DateBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    font-size: ${props => props.theme.fontSize.caption};
`;




interface AvailabilityDateComponentProps {
    date: Date;
    isAvailable: boolean;
    isSelected: boolean;
    getLowFare: () => ILowFareResult;
    onClick: (date: Date) => void;
}


const AvailableFlightDateComponent: React.FC<AvailabilityDateComponentProps> = observer((props) => {
    const services = useServices();
    const elementRef = useRef<HTMLDivElement | null>(null);
    const isInViewPort = useIsInViewPort(elementRef);
    const formattedDisplayDate = services.time.formatUserFriendlyDayNameDayMonth(props.date);


    const renderPriceNotAvailable = () => {
        return (<PlaneNotAvailableIcon color={services.theme.currentTheme.colors.medium} size={1}/>);
    }

    const renderPriceBoxContent = () => {
        if (!props.isAvailable) {
            return renderPriceNotAvailable();
        }

        let lowFareResult: ILowFareResult = {
            status: LowFareStatusEnum.NoFare
        };

        if (isInViewPort) {
            lowFareResult = props.getLowFare();
        }

        if (lowFareResult.price) {
            return (
                <PriceComponent price={lowFareResult.price}/>
            )
        } else if (lowFareResult.status === LowFareStatusEnum.Reading) {
            return (
                <IonSpinnerComponent/>
            );
        } else if (lowFareResult.status === LowFareStatusEnum.SoldOut) {
            return services.language.translate('Sold Out');
        } else {
            return null;
        }
    }

    const onClick = () => {
        if(props.isAvailable) {
            props.onClick(props.date);
        }

    }

    const priceContent = renderPriceBoxContent();

    return (
        <DateButtonWrapperBox>
            <DateButtonBox ref={elementRef}
                           $isSelected={props.isSelected}
                           $disabled={!props.isAvailable}
                           onClick={onClick}>

                <DateBox>
                    {formattedDisplayDate}
                </DateBox>
                <PriceBox $isSelected={props.isSelected}>
                    {priceContent}
                </PriceBox>
            </DateButtonBox>
        </DateButtonWrapperBox>


    )
});

interface FlightDatesAvailabilityComponentProps {
    flightSchedule: IFlightsScheduleViewModel;
    currentScheduleSelectedDate: Date;
    onDateSelected: (date: Date) => void;
    getLowFare: (date: Date) => ILowFareResult;
}

export const FlightDatesAvailabilityComponent: React.FC<FlightDatesAvailabilityComponentProps> = observer((props) => {
    const services = useServices();
    const containerMediaQuery = useContainerMediaQueriesChecks();
    const swiperRef = useRef<SwiperRef | null>(null);
    const swipeSpeed = 500;

    let numberOfSlides = 7;

    if(containerMediaQuery.mAndBelow) {
        numberOfSlides = 5;
    }

    if (containerMediaQuery.smallScreen) {
        numberOfSlides = 3;
    }

    const middleSlide = Math.floor(numberOfSlides / 2);

    let dateRange = props.flightSchedule.availableDates;
    if (dateRange.length > 0) {
        dateRange = services.time.getDateRange(services.time.addDays(dateRange[0], -1 * middleSlide), dateRange[dateRange.length - 1]);
    }

    const selectedDateIndex = Math.max(0, dateRange.findIndex(d => services.time.areDatesEqual(d, props.currentScheduleSelectedDate)));
    const getPrice = (date: Date, isAvailable: boolean): ILowFareResult => {
        if (isAvailable) {
            return props.getLowFare(date);
        }

        return {
            status: LowFareStatusEnum.NoFare
        };
    }






    const onDateSelected = (date: Date) => {
        props.onDateSelected(date);
        const index = dateRange.findIndex(d => services.time.areDatesEqual(d, date))
        if(index >= 0) {
            swiperRef.current?.swiper?.slideTo(index, swipeSpeed);
        }
    }


    const slideToIndex = (index: number) => {
        index = Math.min(dateRange.length - 1, Math.max(0, index));
        swiperRef.current?.swiper?.slideTo(index, swipeSpeed);
        props.onDateSelected(dateRange[index]);
    }

    const onSwipeBack = () => {
        slideToIndex(Math.max(selectedDateIndex - numberOfSlides, middleSlide));

    }

    const onSwipeForward = () => {
        slideToIndex(selectedDateIndex + numberOfSlides);
    }




    return (
        <FlightDatesContainerBox>
                <SwiperNavigationBackButtonComponent size={"medium"}
                                                     selector={"swipe-back"}
                                                     onClick={onSwipeBack}
                                                     isHidden={selectedDateIndex <= middleSlide}/>
                <SwiperBox modules={[Navigation]}
                                 spaceBetween={8}
                                 speed={swipeSpeed}
                                 initialSlide={selectedDateIndex}
                                 slidesPerView={numberOfSlides}
                                 centeredSlides={true}
                                 ref={swiperRef}>

                    {dateRange.map(date => {

                        const isSelected = services.time.areDatesEqual(props.currentScheduleSelectedDate, date);
                        const isAvailable = props.flightSchedule.isDateAvailable(date);

                        return (
                            <SwiperSlide key={date.getTime()}>
                                <AvailableFlightDateComponent date={date}
                                                              isSelected={isSelected}
                                                              isAvailable={isAvailable}
                                                              onClick={onDateSelected}
                                                              getLowFare={() => getPrice(date, isAvailable)}/>
                            </SwiperSlide>
                        );
                    })}
                </SwiperBox>
            <SwiperNavigationNextButtonComponent size={"medium"}
                                                 selector={"swipe-forward"}
                                                 onClick={onSwipeForward}
                                                 isHidden={selectedDateIndex > dateRange.length - numberOfSlides - 1}/>
        </FlightDatesContainerBox>
    )
});
