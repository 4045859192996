import React from "react";
import {observer} from "mobx-react";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";
import {useServices} from "../../../hooks/use-services.hook";
import {NullableString} from "../../../types/nullable-types";
import styled from "styled-components";
import {StandardDialogPageComponent} from "../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../components/modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../../../components/modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../../../components/modal/footer/standard-dialog-footer.component";
import {ContinueButtonComponent} from "../../../components/continue-button/continue-button.component";
import {IPspError} from "../../../services/airline-webapi/responses/psp-error";
import {PspErrorDetailsComponent} from "./psp-error-details.component";
import {ExclamationDiamondIcon} from "../../../assets/svg/exclamation-diamond.icon";


const DialogContentWrapperBox = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${props => props.theme.spacing.spacing20};
`

const ErrorMessageTitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.h1};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: 1;
    text-align: center;
`;

export const PaymentFailedDialog: React.FC<{dialogHandler: IDialogConfirmationHandler; errorMessage: NullableString; pspErrorDetails: IPspError[] | null}> = observer((props) => {
    const services = useServices();

    let errorMessage = props.errorMessage ?? services.language.translate('There was an error processing your payment.');

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler}/>

            <StandardDialogContentComponent dialogHandler={props.dialogHandler}>
                <DialogContentWrapperBox>
                    <ExclamationDiamondIcon size={3} color={services.theme.currentTheme.colors.danger}/>
                    <ErrorMessageTitleBox>
                        {errorMessage}
                    </ErrorMessageTitleBox>
                    <PspErrorDetailsComponent pspErrorDetails={props.pspErrorDetails}/>
                </DialogContentWrapperBox>
            </StandardDialogContentComponent>

            <StandardDialogFooterComponent dialogHandler={props.dialogHandler} horizontallyCentered={true}>
                <ContinueButtonComponent onClick={() => props.dialogHandler.accept()} fullWidth={true} />
            </StandardDialogFooterComponent>

        </StandardDialogPageComponent>
    )
});
