import { BookingHistoryModel } from "./booking-history.model";
import { IPersistedJourneyHistoryDesignator } from "./persisted-booking-history.interface";
import {
    IJourneyHistoryViewModel,
    IMyTripFlightAction,
    IMyTripFlightDesignatorViewModel
} from "./journey-history-view-model.interface";
import { IPassengerSegmentBoardingPassViewModel } from "../../booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import { IStation } from "../../stations/station.interface";
import { composeTripReferenceKey } from "../../booking/boarding-pass/boarding-pass.utils";
import { IServiceFactory } from "../../service-factory.interface";
import { JourneyModel } from "../../booking/models/journey/journey.model";
import { NullableString } from "../../../types/nullable-types";
import {TimeSpan} from "../../../types/time-span";

export class JourneyHistoryModel implements IJourneyHistoryViewModel {
    constructor(public readonly trip: BookingHistoryModel,
        origin: IStation,
        destination: IStation,
        flightInfo: IPersistedJourneyHistoryDesignator
    ) {
        this.designator = {
            departureDate: flightInfo.departure,
            origin: {
                code: origin.stationCode,
                name: origin.stationName
            },
            destination: {
                code: destination.stationCode,
                name: destination.stationName
            }
        }
    }

    get uniqueKey(): string {
        return this.recordLocator + this.designator.origin.code + this.designator.destination.code;
    }

    get bookingKey(): string {
        return this.trip.bookingKey;
    }

    public readonly designator: IMyTripFlightDesignatorViewModel

    get isFutureFlight(): boolean {
        return this.services.time.parseIsoDate(this.designator.departureDate).getTime() > this.services.time.currentDate.getTime();
    }

    get recordLocator(): string {
        return this.trip.recordLocator;
    }

    async startManageMyBooking(): Promise<void> {
        await this.trip.startManageMyBooking();
    }

    async startCheckIn(): Promise<void> {
        await this.trip.startCheckIn()
    }

    async showFlightItinerary(): Promise<void> {
        await this.trip.showDetails();
    }

    get tripReferenceKey(): string {
        return composeTripReferenceKey(this.recordLocator, this.designator.origin.code, this.designator.destination.code);
    }

    get boardingPasses(): IPassengerSegmentBoardingPassViewModel[] {
        return this.trip.services.bookingHistory.getSavedBoardingPasses(this.tripReferenceKey);
    }

    private get services(): IServiceFactory {
        return this.trip.services;
    }

    private async _viewBoardingPasses(): Promise<void> {
        await this.services.dialogFactory.showBoardingPasses({
            bookingKey: this.bookingKey,
            boardingPasses: this.boardingPasses
        });
    }

    private get _journeyModel(): JourneyModel | null {
        const booking = this.trip.bookingModel;
        if (!booking) {
            return null;
        }

        const journey = booking.unfilteredJourneys
            .find(j => j.designator.origin.stationCode === this.designator.origin.code
                && j.designator.destination.stationCode === this.designator.destination.code);
        return journey || null;
    }

    get isCanceled(): boolean {
        return Boolean(this._journeyModel?.isCanceled);
    }

    getWebSiteManageMyBookingUrl(): NullableString {
        if(this.trip.bookingModel && this.trip.bookingModel?.passengers.length > 0){
            return this.services.externalLinks.flightCanceled(this.recordLocator, this.trip.bookingModel?.passengers[0].fields.lastName.value);
        } else {
            return null; 
        }
    }

    get departureDate(): Date {
        return this.services.time.parseIsoDate(this.designator.departureDate);
    }

    get timeUntilFlight(): string {

        if(!this.isFutureFlight) {
            return this.services.language.translate('Past flight');
        }

        let timeToDeparture = TimeSpan.fromMilliseconds(this.departureDate.getTime() - this.services.time.currentDate.getTime());

        return timeToDeparture.toUserFriendlyString(this.services.language, {
            ignoreSeconds: true
        });

        /*
        return this.services.language.translationFor('{time} left').withParams({
            time: timeToDeparture.toUserFriendlyString(this.services.language, {
                ignoreSeconds: true
            })
        });

         */

    }

    private get _hasPurchasedCheckInBags(): boolean {
        if (!this._journeyModel) {
            return false
        }
        return this._journeyModel.checkInBagsCount > 0;
    }

    private get _allPassengersHaveUserIndependentOnlineCheckInBlockingRestrictions(): boolean {
        return Boolean(this._journeyModel?.allPassengersHaveUserIndependentOnlineCheckInBlockingRestrictions);
    }

    private get _hasPurchasedPriorityBoardingBags(): boolean {
        if (!this._journeyModel) {
            return false
        }
        return this._journeyModel.priorityBoardingCount > 0;
    }

    private get _allPassengersHaveSeat(): boolean {
        if (!this._journeyModel) {
            return false
        }
        return this._journeyModel.getAllPassengersSegments().all(ps => ps.hasSeat);
    }

    private get _isNotPaidAllServices(): boolean {
        return Boolean((this.trip.bookingModel?.balanceDue.amount || 0) > 0)
    }

    async getActions(): Promise<IMyTripFlightAction[]> {
        //await delay(Date.now(), 5000);
        const actions: IMyTripFlightAction[] = [];

        if (this.isFutureFlight) {

            if(this.services.configuration.envConfigs.enableManageMyBooking) {
                actions.push({
                    text: this.services.language.translate('Manage my booking'),
                    execute: () => this.startManageMyBooking()
                });
            }

            if(!this.isCanceled) {
                if(this.services.configuration.envConfigs.enableCheckin) {
                    actions.push({
                        text: this.services.language.translate('Check-in'),
                        execute: () => this.startCheckIn()
                    });
                }
            }
        }

        if (!this.isCanceled && this.services.configuration.envConfigs.enableCheckin) {
            if (this.boardingPasses.length > 0) {
                actions.push({
                    text: this.services.language.translate('Boarding pass'),
                    execute: () => this._viewBoardingPasses()
                });
            }
        }

        /*
        actions.push({
            text: this.services.language.translate('Flight Itinerary'),
            execute: () => this.showFlightItinerary()
        });
         */
        return actions;
    }

    getNextFlightActions(): IMyTripFlightAction[] {

        if(!this.services.configuration.envConfigs.enableManageMyBooking) {
            return [];
        }

        if (!this._journeyModel) {
            return []
        }

        if (this.isCanceled) {
            return [];
        }

        if(this._isNotPaidAllServices){
            return [{
                text: this.services.language.translate('Pay now'),
                execute: async () => {
                    await this.startManageMyBooking();
                }
            }]
        }

        const otherServicesAction: IMyTripFlightAction =  {
            text: this.services.language.translate('Other services'),
            execute: () => this.startManageMyBooking()
        };

        if (this._journeyModel?.isOpenForCheckIn
            && !this._journeyModel?.areAllPassengersCheckedIn
            && !this._allPassengersHaveUserIndependentOnlineCheckInBlockingRestrictions) {
            return [
                {
                    text: this.services.language.translate('Check-in'),
                    execute: () => this.startCheckIn()
                },
                otherServicesAction
            ];
        }

        if(!this._hasPurchasedPriorityBoardingBags){
            return [{
                text: this.services.language.translate('Add priority'),
                execute: async () => {
                    await this.startManageMyBooking();
                    await this.services.booking.current.manageMyBooking.priorityBoarding.edit();
                }
            },
            otherServicesAction];
        }

        if (!this._allPassengersHaveSeat) {
            return [{
                text: this.services.language.translate('Add seats'),
                execute: async () => {
                    await this.startManageMyBooking();
                    await this.services.booking.current.manageMyBooking.seats.edit();
                }
            },
            otherServicesAction
            ]
        }

        if (!this._hasPurchasedCheckInBags) {
            return [{
                text: this.services.language.translate('Add bags'),
                execute: async () => {
                    await this.startManageMyBooking();
                    await this.services.booking.current.manageMyBooking.checkInBags.edit();
                }
            },
            otherServicesAction
            ];
        }

        return [
            {
                text: this.services.language.translate('Add services'),
                execute: () => this.startManageMyBooking()
            }
        ];
    }

}
