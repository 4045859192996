import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {ISeatsRowViewModel} from "../../../../../services/booking/models/seat-maps/seats-row-view-model.interface";
import {ExtraLegSeatBox, FrontSeatBox, SeatDangerBox} from "../../components/seats/seat.component";
import {
    ATR_SEATS_PER_ROW_COUNT,
    CSS_VAR_EXTRA_LEG_ROOM_SEAT_INDICATOR,
    CSS_VAR_EXTRA_LEG_ROOM_SEAT_INDICATOR_RADIUS,
    CSS_VAR_SEAT_HEIGHT,
    CSS_VAR_SEAT_WIDTH
} from "../../components/seat-map-css-constants";
import {ISeatViewModel} from "../../../../../services/booking/models/seat-maps/seat-view-model.interface";
import {ExclamationDiamondIcon} from "../../../../../assets/svg/exclamation-diamond.icon";
import {
    ISegmentSeatMapEditorViewModel
} from "../../../../../services/booking/models/seat-maps/segment-seat-map-editor-view-model.interface";
import {HIGHLIGHTED_SEATS_Z_INDEX} from "./random-seat-alocation-css-consts";
import {useContainerMediaQueriesChecks} from "../../../../../hooks/use-container-media-queries-checks.hook";

const CSS_VALUE_COLUMN_GAP =  '1rem';
const CSS_VALUE_ROW_GAP =  '0.875rem';

const SeatsGridBox = styled.div<{$gridColumns: number}>`
    position: relative;
    display: grid;
    grid-template-columns: repeat(${props => props.$gridColumns}, 1fr);
    align-items: center;
    justify-content: center;
    width: fit-content;
    row-gap: ${CSS_VALUE_ROW_GAP};
    column-gap: ${CSS_VALUE_COLUMN_GAP};
    ${CSS_VAR_SEAT_WIDTH}: 1.5rem;
    ${CSS_VAR_SEAT_HEIGHT}: 1.85rem;
    ${CSS_VAR_EXTRA_LEG_ROOM_SEAT_INDICATOR}: 2.5rem;
    ${CSS_VAR_EXTRA_LEG_ROOM_SEAT_INDICATOR_RADIUS}: 3px;
    
`

const RowNumberBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: ${props => props.theme.fontSize.smallText};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.colors.dark};
`

const ExtraLegRowSpacerBox = styled.div`
    grid-column: 1 / -1;
    height: ${props => props.theme.spacing.spacing30};
`

const ExtraLegSeatBlurBox = styled(ExtraLegSeatBox)`
    
`

const DangerSeatsWrapperBox = styled.div`
    position: absolute;
    border: 2px solid ${props => props.theme.colors.darkTint};
    height: calc(3 * var(${CSS_VAR_SEAT_HEIGHT}) + 3.5 * ${CSS_VALUE_ROW_GAP});
    width: calc(var(${CSS_VAR_SEAT_WIDTH}) + 1.5 * ${CSS_VALUE_COLUMN_GAP});
    top: calc(var(${CSS_VAR_SEAT_HEIGHT}) + 0.25 * ${CSS_VALUE_ROW_GAP});
    left: calc(var(${CSS_VAR_SEAT_WIDTH}) + 0.25 * ${CSS_VALUE_COLUMN_GAP});
    z-index: ${HIGHLIGHTED_SEATS_Z_INDEX};
`

const DangerSeatsIndicatorBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateX(calc(100% + 10px)) translateY(-50%);
    width: 3.8rem;
    height: 3rem;
    background-color: ${props => props.theme.colors.darkTint};
    color: ${props => props.theme.colors.darkContrast};
    border-radius: ${props => props.theme.border.defaultRadius};
    
`

const ArrowBox = styled.div`
    position: absolute;
    left: -6px;
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    background-color: ${props => props.theme.colors.darkTint};
    
`

const DangerSeatsWrapperComponent: React.FC = observer(() => {
    const containerMediaQuery = useContainerMediaQueriesChecks();
    return (

            <DangerSeatsWrapperBox>
                <DangerSeatsIndicatorBox>
                    <ArrowBox/>
                    <ExclamationDiamondIcon size={containerMediaQuery.xsAndBelow ? 1.8 : 2}/>
                </DangerSeatsIndicatorBox>

            </DangerSeatsWrapperBox>
    )
})

const LastRowBlurBox = styled.div`
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: calc(2 * var(${CSS_VAR_SEAT_HEIGHT}) + var(${CSS_VAR_EXTRA_LEG_ROOM_SEAT_INDICATOR}));
    background: linear-gradient(180deg, #FFFFFF 41.13%, rgba(255, 255, 255, 0.873134) 56.32%, rgba(255, 255, 255, 0) 92.39%);
    opacity: 0.6;
    transform: rotate(-180deg);
    margin: 0 -10px;
    
    
`


export const RandomSeatsGridComponent: React.FC<{seatMap: ISegmentSeatMapEditorViewModel}> = observer((props) => {


    const seatMap = props.seatMap;
    const isATR = seatMap.seatsPerRowCount <= ATR_SEATS_PER_ROW_COUNT;

    const getRowsToRender = () => {
        const rows = seatMap.rowsGroups.selectMany(g => g.rows);
        let startingRowIndex = rows.findIndex(r => r.isMiddleEmergencyRow);

        if(startingRowIndex > 0) {
            return rows.slice(startingRowIndex - 4, startingRowIndex + 1);
        }

        startingRowIndex = Math.round(rows.length/2);

        return rows.slice(startingRowIndex - 4, startingRowIndex + 1);

    }


    const rowsToRender = getRowsToRender();

    const shouldRenderDangerLeftSeat = (rowIndex: number, columnIndex: number) => {
        if(isATR) {
            return (columnIndex === 0 && rowIndex === 0)
                || (columnIndex === 0 && rowIndex === 3);

        } else {
            return columnIndex === 1 && rowIndex >= 1 && rowIndex <= 3;
        }

    }


    const shouldRenderDangerRightSeat = (rowIndex: number, columnIndex: number) => {
        if(isATR) {
            return columnIndex === 1 && rowIndex === 1;
        }

        return false;

    }

    const renderLeftSeat = (row: ISeatsRowViewModel, seat: ISeatViewModel | null, rowIndex: number, columnIndex: number) => {
        if(!seat) {
            return <div key={`L_${rowIndex}_${columnIndex}`}/>
        }
        const id = seat.seatKey;
        if(shouldRenderDangerLeftSeat(rowIndex, columnIndex)) {
            return (<SeatDangerBox key={id} id={id}/>)
        }

        if(row.isMiddleEmergencyRow) {
            return (
                <ExtraLegSeatBlurBox key={id} id={id}/>
            )
        }
        return (<FrontSeatBox key={id} id={id}/>)
    }

    const renderRightSeat = (row: ISeatsRowViewModel, seat: ISeatViewModel | null, rowIndex: number, columnIndex: number) => {

        if(!seat) {
            return <div key={`R_${rowIndex}_${columnIndex}`}/>
        }

        const id = seat.seatKey;

        if(shouldRenderDangerRightSeat(rowIndex, columnIndex)) {
            return (<SeatDangerBox key={id} id={id}/>)
        }

        if(row.isMiddleEmergencyRow) {
            return (
                <ExtraLegSeatBlurBox key={id} id={id}/>
            )
        }
        return (<FrontSeatBox key={id} id={id}/>)
    }

    const renderOneRow = (row: ISeatsRowViewModel, rowIndex: number) => {
        return (
            <React.Fragment key={row.rowNumber}>
                {row.isMiddleEmergencyRow && <ExtraLegRowSpacerBox/>}
                {row.leftSeats.map((seat, columnIndex) => renderLeftSeat(row, seat, rowIndex, columnIndex))}
                <RowNumberBox key={"ROW_NUMBER_" + row.rowNumber }>
                    {row.rowNumber}
                </RowNumberBox>
                {row.rightSeats.map((seat, columnIndex) => renderRightSeat(row, seat, rowIndex, columnIndex))}
            </React.Fragment>
        )
    }


    return (
        <SeatsGridBox $gridColumns={seatMap.seatsPerRowCount + 1}>
            {!isATR && (<DangerSeatsWrapperComponent/>)}
            {rowsToRender.map(renderOneRow)}
            <LastRowBlurBox/>
        </SeatsGridBox>
    )



})