import React, {PropsWithChildren, useRef} from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {IonFooter} from "@ionic/react";
import {AirlineTheme} from "../../../services/theme/interfaces/airline.theme";
import {usePageFooter} from "../../../services/page-footer/use-page-footer.hook";

function computePaddings(theme: AirlineTheme, hasChildren: boolean) {
    if(hasChildren) {
        return css`
                padding-top: ${theme.spacing.pageFooterVerticalPadding};
                padding-bottom: calc(${theme.spacing.pageFooterVerticalPadding} + env(safe-area-inset-bottom));
                padding-left: ${theme.spacing.pageFooterHorizontalPadding};
                padding-right: ${theme.spacing.pageFooterHorizontalPadding};
            `
    } else {
        return css`
              padding: 0 0 env(safe-area-inset-bottom) 0;
            `
    }
}

const FooterBox = styled(IonFooter)<{$hasChildren: boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${props => props.theme.spacing.spacing16};
    width: 100%;
    box-shadow: none;
    ${props => computePaddings(props.theme, props.$hasChildren)}
    background-color: ${props => props.theme.colors.pageFooterLargeScreenBackground};
    color: ${props => props.theme.colors.pageFooterLargeScreenColor};
    ${props => props.theme.screenMediaQuery.smallScreen} {
        background-color: ${props => props.theme.colors.pageFooterSmallScreenBackground};
        color: ${props => props.theme.colors.pageFooterSmallScreenColor};
        box-shadow: ${props => props.theme.boxShadow.footerSmallScreen};
    }
`

interface StandardPageFooterComponentProps extends PropsWithChildren {
    className?: string;
}

export const StandardPageFooterComponent: React.FC<StandardPageFooterComponentProps> = observer((props) => {
    const footerRef = useRef<HTMLIonFooterElement | null>(null);
    usePageFooter(footerRef);

    const hasChildren = React.Children.count(props.children) > 0;

    return (
        <FooterBox className={props.className}
                   $hasChildren={hasChildren}
                   ref={footerRef}>
            {props.children}
        </FooterBox>
    );
});
