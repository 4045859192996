import styled, {css} from "styled-components";
import React from "react";
import {observer} from "mobx-react";
import {chevronBackOutline} from "ionicons/icons";
import {IonIcon} from "@ionic/react";

const GoToPreviousButtonBox = styled.div<{$disabled: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.primaryContrast};
    width: 31px;
    height: 31px;
    font-size: 46px;
    padding: 4px 6px 4px 4px;
    
    ${props => props.$disabled
    ? css`
                cursor: not-allowed;
                opacity: 0.5;
                color: ${props => props.theme.colors.mediumShade};
                border-color: ${props => props.theme.colors.mediumShade};
                visibility: hidden;
        `
    : css`
                cursor: pointer;
                color: ${props => props.theme.colors.primary};
        `
}
    
`

interface GoToPreviousButtonComponentProps {
    className?: string;
    disabled: boolean;
    onClick: () => void;
}

export const GoToPreviousButtonComponent: React.FC<GoToPreviousButtonComponentProps> = observer((props) => {
    return (
        <GoToPreviousButtonBox $disabled={props.disabled} className={props.className} onClick={props.onClick}>
            <IonIcon icon={chevronBackOutline}/>
        </GoToPreviousButtonBox>
    );
})