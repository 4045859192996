import React, {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {observer} from "mobx-react";
import styled from "styled-components";
import {createPopper} from "@popperjs/core";
import {ArrowForPopperOnTopBox} from "../../popper-arrow/popper-arrow.box";
import {Instance as PopperInstance} from "@popperjs/core/lib/types";
import {CardBox} from "../../../basic-components/card/card.box";
import {SearchMaskPsoMarketUserOptionsInlineComponent} from "./search-mask-pso-market-user-options-inline.component";
import {IonIcon} from "@ionic/react";
import {checkmarkOutline} from "ionicons/icons";
import {useServices} from "../../../hooks/use-services.hook";
import {MediumButton} from "../../../basic-components/buttons/medium-button";
import {XCloseSmallIcon} from "../../../assets/svg/x-close-small.icon";
import {PsoUserOptionsEnum} from "../../../services/flight-search/flight-search-controller/pso.enums";
import {ISearchMaskControllerViewModel} from "../webapp/search-mask-controller.view-model.interface";

const ContainerBox = styled.div`
    
`

const PsoButtonBox = styled(MediumButton)`
    position: relative;
    display: flex;
    flex-direction: row;
    gap: ${props => props.theme.spacing.spacing8};
    border-radius: 20px;
    padding: ${props => props.theme.spacing.spacing8} ${props => props.theme.spacing.spacing16};
`


const PsoButtonCheckmarkBox = styled.span`
    color: ${props => props.theme.colors.primary};
`


const PopperContentBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap8};
`

const PopperHeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`

const PopperXCloseButtonBox = styled.div`
    cursor: pointer;
`

const PopperBodyBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.cardDefaultPadding};
`

function createPsoPopper(targetEl: HTMLElement | null, popperEl: HTMLElement | null, arrowEl: HTMLElement | null) {
    if(!(targetEl && popperEl && arrowEl)) {
       return null;
    }

    return createPopper(targetEl, popperEl, {
        placement: 'top',
        strategy: 'absolute',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8]
                }
            },
            {
                name: 'arrow',
                options: {
                    element: arrowEl
                }
            }
        ]
    });
}

export interface IPsoPopperController {
    close: () => void;
}

export const SearchMaskPsoMarketUserOptionsPopperComponent: React.FC<{searchMaskController?: ISearchMaskControllerViewModel}> = observer((props) => {
    const services = useServices();
    const [isPopperOpen, setIsPopperOpen] = useState(false);
    const isFirstTime = useRef(true);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const popperContentRef = useRef<HTMLDivElement | null>(null);
    const popperArrowRef = useRef<HTMLDivElement | null>(null);
    const searchController =  services.flightSearch.searchController;
    const popperControllerRef = useRef<IPsoPopperController>({
        close: () => {
            setIsPopperOpen(false);
        }
    })

    const hasPsoMarket = Boolean(searchController.getPsoMarket());

    useEffect(() => {
        if(hasPsoMarket) {
            if(isFirstTime.current) {
                isFirstTime.current = false;
                setIsPopperOpen(true);
            }

        } else {
            isFirstTime.current = true;
            setIsPopperOpen(false);
        }
    }, [hasPsoMarket]);

    useEffect(() => {
        props.searchMaskController?.attachPsoPopperController(popperControllerRef.current);
    }, [props.searchMaskController]);

    useEffect(() => {

        let popperInstance: PopperInstance | null = null;
        if(isPopperOpen) {
            popperInstance = createPsoPopper(containerRef.current, popperContentRef.current, popperArrowRef.current);
        }

        return () => {
            if(popperInstance) {
                popperInstance.destroy();
            }
        };
    }, [isPopperOpen]);


    if(!hasPsoMarket) {
        return null;
    }


    const renderCheckMark = () => {
        if(searchController.psoUserOption === PsoUserOptionsEnum.None) {
            return null;
        }

        return (
            <PsoButtonCheckmarkBox>
                <IonIcon icon={checkmarkOutline}/>
            </PsoButtonCheckmarkBox>

        )
    }

    const renderPopperContent = () => {
        if(!isPopperOpen) {
            return null;
        }

        return (
            ReactDOM.createPortal(
                <PopperContentBox ref={popperContentRef}>
                    <ArrowForPopperOnTopBox ref={popperArrowRef}/>
                    <PopperHeaderBox>
                        <PopperXCloseButtonBox onClick={() => setIsPopperOpen(false)}>
                            <XCloseSmallIcon size={1.5}/>
                        </PopperXCloseButtonBox>
                    </PopperHeaderBox>
                    <PopperBodyBox>
                        <SearchMaskPsoMarketUserOptionsInlineComponent searchController={searchController}/>
                    </PopperBodyBox>
                </PopperContentBox>
                , services.document.body)
        );
    }

    return (
        <ContainerBox ref={containerRef}>
            <PsoButtonBox onClick={() => setIsPopperOpen(!isPopperOpen)}>
                <span>{services.language.translate('PSO Route options')}</span>
                {renderCheckMark()}
            </PsoButtonBox>
            {renderPopperContent()}
        </ContainerBox>
    )
})