import React from "react";
import {observer} from "mobx-react";
import {FlightSelectedDatePreviewComponent} from "./flight-selected-date-preview.component";
import {FlightsDatesSelectorModel} from "../../../../services/flight-search/dates-selection/flights-dates-selector.model";
import {NullablePrice} from "../../../../services/currency/price";

interface DepartureFlightSelectedDatePreviewComponentProps {
    flightsDatesSelector: FlightsDatesSelectorModel;
}
export const DepartureFlightSelectedDatePreviewComponent: React.FC<DepartureFlightSelectedDatePreviewComponentProps> = observer((props) => {
    const datesSelector = props.flightsDatesSelector;
    const searchController = datesSelector.flightSearchController;

    if(!(searchController.departureOrigin && searchController.departureDestination)) {
        return null;
    }

    let price: NullablePrice = null;
    if(datesSelector.departureDate) {
        price = datesSelector.getDepartureLowFare(datesSelector.departureDate).price ?? null;
    }

    const onRemoveClick = () => {
        datesSelector.clearDepartureDate();
    }

    return (
        <FlightSelectedDatePreviewComponent origin={searchController.departureOrigin}
                                            destination={searchController.departureDestination}
                                            selectedDate={datesSelector.departureDate}
                                            price={price}
                                            onRemoveButtonClick={onRemoveClick}
                                            showBottomBorder={datesSelector.isOnDepartureDateStrategy && !Boolean(datesSelector.departureDate)}/>
    )
})