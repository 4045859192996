import {IExtrasSectionRenderer} from "./extras-section-renderer.interface";

export class ExtrasRenderersSorter {
    static sortRenderers(renderers: IExtrasSectionRenderer[]): IExtrasSectionRenderer[] {
        const canBeModified: IExtrasSectionRenderer[] = [];
        const cannotBeModified: IExtrasSectionRenderer[] = [];
        const fullFilled: IExtrasSectionRenderer[] = [];

        for(let r of renderers) {
            if(r.canBeModified) {
                if(r.isFulfilled) {
                    fullFilled.push(r);
                } else {
                    canBeModified.push(r);
                }

            } else {
                cannotBeModified.push(r)
            }
        }

        return [
            ...canBeModified,
            ...fullFilled,
            ...cannotBeModified
        ];
    }
}