import React, {MouseEvent, PropsWithChildren} from "react";
import {observer} from "mobx-react-lite";
import {IFlightDesignatorViewModel} from "../../../../services/booking/models/designator/flight-designator-view-model.interface";
import {NullableString} from "../../../../types/nullable-types";
import styled from "styled-components";
import {useServices} from "../../../../hooks/use-services.hook";
import {FlightChangeBlockingReasonComponent} from "./flight-change-blocking-reason.component";
import {CardBox} from "../../../../basic-components/card/card.box";
import {ReadonlyCheckboxComponent} from "../../../../basic-components/checkbox/readonly-checkbox.component";
import {
    AdvancedJourneyDesignatorComponent
} from "../../../../components/advanced-journey-designator/advanced-journey-designator.component";

const ContainerBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap16};
    cursor: pointer;
    width: 100%;
`


export const JourneyBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: ${props => props.theme.spacing.flexGap16};
    width: 100%;
`

const FlightStatusBox = styled.div`
    color: ${props => props.theme.colors.danger};
    font-size: ${props => props.theme.fontSize.h6};
    font-weight: ${props => props.theme.fontWeight.bold};
    padding: ${props => props.theme.spacing.spacing8} 0 0 0;
    letter-spacing: 1px;
    
`

interface JourneyToModifySelectorComponentProps extends PropsWithChildren {
    canBeUnselected: boolean;
    designator: IFlightDesignatorViewModel;
    changeBlockingReason: NullableString;
    changeBlockingReasonTitle: string;
    isJourneyCanceled: boolean;
    onSelect: () => void;
    isSelected: () => boolean;
}

export const JourneySelectorComponent: React.FC<JourneyToModifySelectorComponentProps> = observer((props) => {
    const services = useServices();

    const renderFlightStatus = () => {
        if(props.changeBlockingReason || !props.isJourneyCanceled) {
            return null;
        }
        return (
            <FlightStatusBox>
                {services.language.translate("Flight Canceled")}
            </FlightStatusBox>
        );
    }


    const renderCheckbox = () => {
        if(props.changeBlockingReason) {
            return null;
        }

        return (
            <ReadonlyCheckboxComponent checked={props.isSelected()} />
        );
    }

    const onJourneyClick = (event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault(); //for some reason without preventDefault this handler is called twice
        props.onSelect();
    }

    return (
        <ContainerBox>
            <JourneyBox onClick={onJourneyClick}>
                <AdvancedJourneyDesignatorComponent journeyDesignator={props.designator} isShownInShoppingCart={false}/>
                {renderCheckbox()}

            </JourneyBox>
            {renderFlightStatus()}
            <FlightChangeBlockingReasonComponent blockingReason={props.changeBlockingReason}
                                                 title={props.changeBlockingReasonTitle}/>
            {props.children}
        </ContainerBox>
    );
})
