import React from "react";
import {RoutedPageComponent} from "../../basic-components/page/routed-page/routed-page.component";
import {observer} from "mobx-react-lite";
import {StandardPageContentComponent} from "../../basic-components/page/standard-page-content/standard-page-content.component";
import {SpinnerComponent} from "../../components/spinner/spinner.component";
import styled from "styled-components";
import {useServices} from "../../hooks/use-services.hook";

const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    
`

const ErrorMessageBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${props => props.theme.fontSize.h5};
`

const TryAgainBox = styled.div`
    color: ${props => props.theme.colors.primary};
    font-weight: ${props => props.theme.fontWeight.bold};
    cursor: pointer;
    text-decoration: underline;
`

export const DeepLinkSearchPage: React.FC = observer(() => {
    const services = useServices();

    const onTryAgainClick = async () => {
        await services.navigator.goHome();
    }
    const renderContent = () => {
        if(services.flightSearch.hasDeepLinkErrors) {
            return (
                <ErrorMessageBox>
                    <div>{services.language.translate('There was an error on this search')}</div>
                    <TryAgainBox onClick={onTryAgainClick}>{services.language.translate('Try again')}</TryAgainBox>
                </ErrorMessageBox>
            )
        } else {
            return (<SpinnerComponent/>);
        }
    }
    return (
        <RoutedPageComponent disableSwipeNavigation={true}>
            <StandardPageContentComponent>
                <ContentBox>
                    {renderContent()}
                </ContentBox>
            </StandardPageContentComponent>
        </RoutedPageComponent>
    )
})
