import {BagsSelectionStepBase} from "../common/bags-selection-step.base";
import {IBookingRoutes} from "../../../navigation/routes/booking/booking.routes.interface";

export class BookingFlowBagsSelectionStep extends BagsSelectionStepBase<IBookingRoutes> {

    protected get routes(): IBookingRoutes {
        return this.services.navigator.routes.booking;
    }

    get route() {
        return this.routes.bags;
    }

    protected async _goToNextStep(): Promise<void> {
        return this._activateStepByRoute(this.routes.extras);
    }



}
