import React from "react";
import {observer} from "mobx-react";
import {FlightSelectedDatePreviewComponent} from "./flight-selected-date-preview.component";
import {FlightsDatesSelectorModel} from "../../../../services/flight-search/dates-selection/flights-dates-selector.model";
import {NullablePrice} from "../../../../services/currency/price";

interface ReturnFlightSelectedDatePreviewComponentProps {
    flightsDatesSelector: FlightsDatesSelectorModel;
}
export const ReturnFlightSelectedDatePreviewComponent: React.FC<ReturnFlightSelectedDatePreviewComponentProps> = observer((props) => {
    const datesSelector = props.flightsDatesSelector;
    const searchController = datesSelector.flightSearchController;

    if(searchController.isOneWay) {
        return null;
    }

    if(!(searchController.returnOrigin && searchController.returnDestination)) {
        return null;
    }

    let price: NullablePrice = null;
    if(datesSelector.returnDate) {
        price = datesSelector.getReturnLowFare(datesSelector.returnDate).price ?? null;
    }

    const onRemoveClick = () => {
        datesSelector.clearReturnDate();
    }

    return (
        <FlightSelectedDatePreviewComponent origin={searchController.returnOrigin}
                                            destination={searchController.returnDestination}
                                            selectedDate={datesSelector.returnDate}
                                            price={price}
                                            onRemoveButtonClick={onRemoveClick}
                                            showBottomBorder={!datesSelector.isOnDepartureDateStrategy &&  !Boolean(datesSelector.returnDate)}/>
    )
})