import React from "react";
import {observer} from "mobx-react";
import {useContainerMediaQueriesChecks} from "../../../../../hooks/use-container-media-queries-checks.hook";
import styled, {css} from "styled-components";
import {BundleSkeletonComponent} from "./bundle-skeleton.component";

const SkeletonsBox = styled.div<{$onlyOneSkeleton: boolean}>`
    display: flex;
    flex-direction: row;
    gap: ${props => props.theme.spacing.spacing20};
    width: 100%;
    /*
    ${props => props.$onlyOneSkeleton
            ? css`
                width: 100%;
            `
            : css`
                width: calc(100% + 2 * ${props => props.theme.spacing.pageContentHorizontalPadding});
                ${props => props.theme.containerMediaQuery.smallScreen} {
                    width: 100%;
                }
            `
    }
    
     */
    
`

export const BundlesSkeletonsComponent: React.FC<{useOnlyOneSkeleton: boolean}> = observer((props) => {
    const mediaQuery = useContainerMediaQueriesChecks();

    if(mediaQuery.smallScreen) {
        return (
            <SkeletonsBox $onlyOneSkeleton={true}>
                <BundleSkeletonComponent numberOfSkeletonSsrs={7}/>
            </SkeletonsBox>
        )
    }

    const renderSkeletons = () => {
        if(props.useOnlyOneSkeleton) {
            return (
                <BundleSkeletonComponent numberOfSkeletonSsrs={5}/>
            );
        } else {
            return (
                <>
                    <BundleSkeletonComponent numberOfSkeletonSsrs={9}/>
                    <BundleSkeletonComponent numberOfSkeletonSsrs={6}/>
                    <BundleSkeletonComponent numberOfSkeletonSsrs={3}/>
                </>
            )
        }

    }

    return (
        <SkeletonsBox $onlyOneSkeleton={props.useOnlyOneSkeleton}>
            {renderSkeletons()}
        </SkeletonsBox>
    )

})