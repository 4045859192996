import React, {MouseEvent} from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {IonIcon} from "@ionic/react";
import {checkmarkCircle, ellipseOutline} from "ionicons/icons";


type LabelPlacement = 'start' | 'end';

const CheckBoxContainer = styled.div<{labelPlacement?: LabelPlacement}>`
    display: flex;
    flex-direction: ${props => props.labelPlacement === "start" ? "row-reverse" : "row"};
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap8};
    cursor: pointer;
    
    
`

const CheckMarkWrapperBox = styled.div<{$isChecked: boolean}>`
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    padding: 0;
    margin: 0;
    
    ${props => props.$isChecked
            ? css`
                color: ${props => props.theme.colors.primary};
            ` 
            : css`
                color: ${props => props.theme.colors.dark};
            `
    }
    
`



const LabelBox = styled.div`
`


interface ReadonlyCheckboxComponentProps {
    checked: boolean;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
    label?: string | React.ReactElement;
    labelPlacement?: LabelPlacement;
    className?: string;
}
export const ReadonlyCheckboxComponent: React.FC<ReadonlyCheckboxComponentProps> = observer((props) => {
    const renderLabel = () => {
        if(!props.label) {
            return null;
        }

        return (
            <LabelBox>
                {props.label}
            </LabelBox>
        );
    }

    const renderIcon = () => {
        if(props.checked) {
            return (<IonIcon icon={checkmarkCircle}/>);
        } else {
            return (
                <IonIcon icon={ellipseOutline}/>
            )
        }
    }

    return (
        <CheckBoxContainer labelPlacement={props.labelPlacement} className={props.className} onClick={props.onClick}>
            <CheckMarkWrapperBox $isChecked={props.checked}>
                {renderIcon()}
            </CheckMarkWrapperBox>

            {renderLabel()}

        </CheckBoxContainer>
    );
})