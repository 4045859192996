import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import styled, {ThemeProvider} from "styled-components";
import {useServices} from "../../../../hooks/use-services.hook";
import {AirlineTheme} from "../../../../services/theme/interfaces/airline.theme";

const TopSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${props => props.theme.spacing.pageContentVerticalPadding} ${props => props.theme.spacing.pageContentHorizontalPadding};
    background-color: ${props => props.theme.colors.darkTint};
    color: ${props => props.theme.colors.light};
    gap: ${props => props.theme.spacing.flexGap24};
`

interface HomePageHybridContentTopSectionComponentProps extends PropsWithChildren {
    className?: string;
}
export const HomePageHybridContentTopSectionComponent: React.FC<HomePageHybridContentTopSectionComponentProps> = observer((props) => {
    const services = useServices();
    const topTheme = services.theme.currentTheme;
    let localTheme: AirlineTheme = {
        ...topTheme,
        colors: {
            ...topTheme.colors,
            cardBackground: 'rgba(255, 255, 255, 0.2)',
            cardColor: topTheme.colors.light,
            darkTint: topTheme.colors.secondaryTint,
            medium: topTheme.colors.secondaryTint
        },
        border: {
            ...topTheme.border,
            focusedColor: '#ffffff'
        }
    }
    return (
        <TopSectionBox className={props.className}>
            <ThemeProvider theme={localTheme}>
                {props.children}
            </ThemeProvider>

        </TopSectionBox>
    )
});