import { observer } from "mobx-react";
import React from "react";
import styled from 'styled-components';
import { useServices } from "../../../../../hooks/use-services.hook";
import { IJourneyHistoryViewModel } from "../../../../../services/booking-history/models/journey-history-view-model.interface";
import {PlaneGoIcon} from "../../../../../assets/svg/plane-go.icon";

const TopSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`


const DepartureDateAndTimeBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    font-size: ${props => props.theme.fontSize.h6};
`


const StationsBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap8};
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`
const PlaneIconBox = styled.div`
    margin-bottom: -2px;
`

export const FlightCardTopSectionComponent: React.FC<{flight: IJourneyHistoryViewModel}> = observer((props) => {
    const services = useServices();
    return (
        <TopSectionBox>
            <DepartureDateAndTimeBox>
                <span>
                    {services.time.formatUserFriendlyDate(props.flight.designator.departureDate)}
                </span>
                <span>-</span>
                <span>
                    {services.time.formatHHmm(props.flight.designator.departureDate)}
                </span>
            </DepartureDateAndTimeBox>

            <StationsBox>
                {props.flight.designator.origin.name}
                <PlaneIconBox>
                    <PlaneGoIcon size={1} />
                </PlaneIconBox>
                {props.flight.designator.destination.name}

            </StationsBox>
        </TopSectionBox>
    )
})
