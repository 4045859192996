import React from "react";
import {useServices} from "../../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import {DepartingFlightSelectionPageContent} from "./departing-flight-selection-page-content.component";
import {BookingFlowPage} from "../../booking-flow.page";
import {
    PsoUserAgreementPageContentComponent
} from "../../../../components/territorial-continuity-regime/pso-user-agreement-page-content.component";
import {
    PsoUserAgreementPageFooterComponent
} from "../../../../components/territorial-continuity-regime/pso-user-agreement-page-footer.component";
import {useScreenMediaQueriesChecks} from "../../../../hooks/use-screen-media-queries-checks.hook";

const ContentComponent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;
    if(booking.shouldAskForPsoUserAgreement) {
        return (<PsoUserAgreementPageContentComponent searchController={booking.flightSearchController}/>);
    } else {
        return (<DepartingFlightSelectionPageContent allowBundleSelection={true}/>);
    }
});


export const DepartingFlightSelectionPage: React.FC = observer(() => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();

    const canRenderPage = services.booking.currentPageCanBeRendered;

    const getTitle = () => {
        if(!canRenderPage) {
            return null;
        }

        if(services.booking.current.shouldAskForPsoUserAgreement && !screenMediaQuery.smallScreen) {
            return null;
        }

        return services.language.translate('Departure Flight');
    }

    const shouldHideShoppingCart = () => {
        return !canRenderPage || services.booking.current.shouldAskForPsoUserAgreement;
    }

    const getExtraFooterRenderer = () => {
        if(shouldHideShoppingCart()) {
            return () => ( <PsoUserAgreementPageFooterComponent flightSearchController={services.booking.current.flightSearchController}/>)
        }
        return undefined;
    }


    return (
        <BookingFlowPage
                 headerOptions={{
                    title: getTitle()
                 }}
                 contentOptions={{
                     maxContentWidth: '100%',
                     renderContent:() => (<ContentComponent/>)
                 }}
                 shoppingCartOptions={{
                     showPromotionCode: false,
                     hideShoppingCart: shouldHideShoppingCart(),
                     hideContinueButton: shouldHideShoppingCart()
                 }}
                 footerOptions={{
                     renderExtraFooterContent: getExtraFooterRenderer()
                 }}/>
    );
});
