import React from "react";
import {MyTripsHistoryTypeEnum} from "./my-trips-history-type.enum";
import {
    HomePageHybridContentTopSectionComponent
} from "../../../content/home-page-hybrid-content-top-section.component";
import styled, {css} from "styled-components";
import {HybridHomePageTabTitleComponent} from "../../tab-title/hybrid-home-page-tab-title.component";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {MyTripsTab} from "../my-trips.tab";
import {BookingSearchComponent} from "../../../common/booking-search/booking-search.component";
import {observer} from "mobx-react";

const TopSection = styled(HomePageHybridContentTopSectionComponent)<{$hasAnyFlightsHistory: boolean}>`
    ${props => props.$hasAnyFlightsHistory && css`padding-bottom: 0;`}
`

const TabsContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 4px;
    
`

const TabItemBox = styled.div<{$isSelected: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${props => props.theme.spacing.spacing24};
    border-top-left-radius: ${props => props.theme.border.defaultRadius};
    border-top-right-radius: ${props => props.theme.border.defaultRadius};
    border: none;
    min-width: 150px;
    font-size: ${props => props.theme.fontSize.h6};
    font-weight: ${props => props.theme.fontWeight.medium};
    
    ${props => props.$isSelected
    ? css`
                background-color: ${props => props.theme.colors.pageContentBackground};
                color: ${props => props.theme.colors.dark};
            `
    : css`
                background-color: ${props => props.theme.colors.cardBackground};
                color: ${props => props.theme.colors.cardColor};
                cursor: pointer;
            `}
`

const BookingSearch = styled(BookingSearchComponent)`
    border: none;
`

interface MyTripsTabContentTopSectionComponentProps {
    tab: MyTripsTab;

}
export const MyTripsTabContentTopSectionComponent: React.FC<MyTripsTabContentTopSectionComponentProps> = observer((props) => {
    const services = useServices();

    const hasAnyFlightsHistory = (props.tab.futureFlights.length > 0 || props.tab.pastFlights.length > 0);

    const renderContent = () => {
        if(hasAnyFlightsHistory) {
            return (
                <>
                    <HybridHomePageTabTitleComponent title={services.language.translate('My Trips')}/>
                    <TabsContainerBox>
                        <TabItemBox $isSelected={props.tab.currentHistoryView === MyTripsHistoryTypeEnum.Future}
                                    onClick={() => props.tab.currentHistoryView = MyTripsHistoryTypeEnum.Future}>
                            {services.language.translate('Upcoming Travels')}
                        </TabItemBox>
                        <TabItemBox $isSelected={props.tab.currentHistoryView === MyTripsHistoryTypeEnum.Past}
                                    onClick={() => props.tab.currentHistoryView = MyTripsHistoryTypeEnum.Past}>
                            {services.language.translate('Past Travels')}
                        </TabItemBox>
                    </TabsContainerBox>
                </>

            );
        }

        return (
            <>
                <HybridHomePageTabTitleComponent title={services.language.translate('My Trips')}
                                                 subtitle={services.language.translate(`Let's find your booking`)}/>
                <BookingSearch retrieveBookingButtonText={services.language.translate('Retrieve booking')}
                               hideTitle={true}
                               onRetrieveBookingButtonClick={(searchParams) => props.tab.retrieveBooking(searchParams)}/>
            </>
        );

    }


    return (
        <TopSection $hasAnyFlightsHistory={hasAnyFlightsHistory}>
            {renderContent()}
        </TopSection>
    )
})