import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {ExternalLinkComponent} from "../../../components/external-link/external-link.component";
import {useServices} from "../../../hooks/use-services.hook";
import {CheckboxComponent} from "../../../basic-components/checkbox/checkbox.component";

const LabelBox = styled.div`
    display: flex;
    flex-direction: column;
    
`

const TermsAndConditionsContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    font-size: ${props => props.theme.fontSize.body1};
    gap: ${props => props.theme.spacing.spacing12};
`

const TermsAndConditionsTextBox = styled(ExternalLinkComponent)`
    flex-grow: 1;
`

const ErrorBox = styled.div`
    color: ${props => props.theme.colors.danger};
    font-weight: ${props => props.theme.fontWeight.bold};
`

export const FinalPriceTermsAndConditionsToggleComponent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;

    const renderError = () => {
        if(booking.travelConditionsAcceptanceError) {
            return (
                <ErrorBox>{booking.travelConditionsAcceptanceError}</ErrorBox>
            )
        }

        return null;
    }

    const renderCheckBoxLabel = () => {
        return (
            <LabelBox>
                <TermsAndConditionsTextBox innerHtml={services.externalLinks.travelConditions}/>
                {renderError()}
            </LabelBox>
        );
    }

    return (
        <TermsAndConditionsContainerBox>
            <CheckboxComponent checked={booking.travelConditionsAccepted}
                               label={renderCheckBoxLabel()}
                               labelPlacement={"end"}
                               onChange={isChecked => booking.travelConditionsAccepted = isChecked}/>
        </TermsAndConditionsContainerBox>

    );
})