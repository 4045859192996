import React from "react";
import {observer} from "mobx-react";
import {PrimaryButtonInverted} from "../../../../../../../basic-components/buttons/primary-button-inverted";
import {useServices} from "../../../../../../../hooks/use-services.hook";
import styled from "styled-components";

const Button = styled(PrimaryButtonInverted)`
    justify-self: flex-end;
    font-weight: ${props => props.theme.fontWeight.medium};
`

export const BookNewTravelButtonComponent: React.FC = observer(() => {
    const services = useServices();
    const onClick = async () => {
        services.flightSearch.startWizardSearch();
    }
    return (
        <Button fullWidth={false} showArrow={true} onClick={onClick}>
            {services.language.translate('Why not book new travel?')}
        </Button>
    )
})