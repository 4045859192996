import React, {useState} from "react";
import {observer} from "mobx-react";
import {
    IFlightDesignatorViewModel
} from "../../services/booking/models/designator/flight-designator-view-model.interface";
import {AdvancedJourneyDesignatorCollapsedComponent} from "./components/collapsed/advanced-journey-designator.colapsed.component";
import {AdvancedJourneyDesignatorExpandedComponent} from "./components/expanded/advanced-journey-designator.expanded.component";

interface AdvancedJourneyDesignatorComponentProps {
    journeyDesignator: IFlightDesignatorViewModel;
    isShownInShoppingCart: boolean;
}

export const AdvancedJourneyDesignatorComponent: React.FC<AdvancedJourneyDesignatorComponentProps> = observer((props) => {
    const [isExpanded, setIsExpanded] = useState(false);

    if(props.journeyDesignator.innerDesignators.length === 0) {
        return null;
    }


    const onExpandClickHandler = () => {
        setIsExpanded(!isExpanded);
    }


    if(isExpanded) {
        return (
            <AdvancedJourneyDesignatorExpandedComponent journeyDesignator={props.journeyDesignator}
                                                        onExpandClick={onExpandClickHandler}
                                                        isShownInShoppingCart={props.isShownInShoppingCart}/>
        )
    }


    return (
        <AdvancedJourneyDesignatorCollapsedComponent journeyDesignator={props.journeyDesignator}
                                                     onExpandClick={onExpandClickHandler}
                                                     isShownInShoppingCart={props.isShownInShoppingCart}/>
    );


});
