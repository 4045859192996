import React from 'react';
import {useServices} from "../../../../../hooks/use-services.hook";
import styled, {css} from "styled-components";
import {observer} from "mobx-react";
import {IHomePageTabViewModel} from "../../../../../services/home-page/tabs/home-page.tab.interface";

const ContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
`

const ItemBox = styled.div<{$isSelected: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${props => props.theme.spacing.flexGap8};
    font-size: ${props => props.theme.fontSize.subtitle2};
    
    ${props => props.$isSelected
        ? css`
            color: ${props => props.theme.colors.primary};
        `   
        : css`
            color: ${props => props.theme.colors.dark};
        `    
    }
`


const NavbarItemComponent: React.FC<{tab: IHomePageTabViewModel}> = observer((props) => {
    const services = useServices();
    const isSelected = services.homePage.currentTab.text === props.tab.text;
    
    return (
        <ItemBox $isSelected={isSelected} onClick={() => props.tab.open()}>
            {props.tab.renderIcon()}
            <span>
                {props.tab.text}
            </span>
        </ItemBox>
    )
});


export const HybridHomePageBottomNavbarComponent: React.FC = observer(() => {
    const services = useServices();


    return (
        <ContainerBox>
            {services.homePage.tabs.filter(tab => tab.isVisible)
                                   .map(tab => <NavbarItemComponent key={tab.text} tab={tab}/>)}
        </ContainerBox>
    );
});


