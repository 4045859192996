import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';
//props.color? props.color: "currentColor"
export const PlaneGoIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.5} {...props} viewBox="0 0 12 10" ratio={12 / 10}>
            <path
                d="M9.70526 3.93624L7.86568 3.93342L4.53382 0.655758C3.79131 -0.0761157 3.08937 0.926432 3.57661 1.64129L5.25692 3.92797L2.0112 3.9266L1.56773 3.46595C0.695566 2.54161 0.653058 3.52569 0.663681 4.26131L0.658621 4.96941C0.652074 5.72149 1.44807 5.73623 1.84573 5.74844L5.22149 5.75519L3.51792 8.00362C3.01618 8.71628 3.70018 9.72244 4.46092 8.99402L7.81472 5.76682L9.82523 5.77324C12.3039 5.7733 10.9924 3.93857 9.70526 3.93624Z"
                fill={props.color? props.color: "currentColor"}/>
        </SvgIcon>
    )
}
