import {IExternalLinksConfiguration} from "../external-links-configuration.interface";

const links: IExternalLinksConfiguration = {
    privacyPolicy: 'https://www.aeroitalia.com/en/transparency/privacy-policy',
    cookiesPolicy: 'https://www.aeroitalia.com/en/transparency/cookie-policy',
    mobileAppPolicy: 'https://www.aeroitalia.com/en/transparency/privacy-policy',
    travelConditions: 'https://www.aeroitalia.com/en/transparency/legal-info-v3',
    contactUs: 'https://www.aeroitalia.com/en/assistance/contacts',
    contactForm: 'https://www.aeroitalia.com/en/contact_form',
    dangerousGoods: 'https://www.aeroitalia.com/en/before-the-flight',
    groupBooking: 'https://www.aeroitalia.com/en/travel-agents#richieste-gruppo',
    flightCanceled: '',
    notAllowFlightChangeForCheckedInPassengers: 'https://www.aeroitalia.com/en/assistance/contacts',
    accountDeletion: ''
}

export default links;
