import React from "react";
import {ISvgIconProps, SvgIcon} from "./svg-icon.component";
//fill={props.color? props.color: "currentColor"}
export const PetIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2.6} {...props} viewBox="00 0 27 24" ratio={27 / 24}>
            <path
                d="M26.967 21.422L24.3405 11.8196L25.9742 9.32708C26.0901 9.14997 26.1491 8.94167 26.1434 8.73007C26.1376 8.51847 26.0673 8.31368 25.942 8.14314L25.4165 7.42238C25.2574 7.20699 25.0204 7.0623 24.7561 7.01923C24.4918 6.97615 24.2212 7.03809 24.0019 7.19183L21.632 8.84727H13.3776L10.9744 10.7457H22.415C24.7736 8.37993 22.2333 11.91 22.2333 11.91L24.7341 21.0585L24.6407 21.0949L21.0359 15.5521C20.9429 15.4082 20.8155 15.2898 20.6651 15.2076C20.5147 15.1254 20.3462 15.0822 20.1749 15.0817H17.1236C17.0567 15.0817 16.9899 15.0883 16.9242 15.1014L11.6941 16.14C11.5154 16.1757 11.3494 16.2582 11.2129 16.3789C11.0765 16.4997 10.9745 16.6545 10.9173 16.8275L9.53288 20.989L10.1072 16.0185C10.1193 15.9275 10.1193 15.8353 10.1072 15.7443L9.50276 11.7531L7.63961 13.1552L8.05503 15.9167L7.37997 21.7544C7.35141 22.0005 7.41182 22.2488 7.55029 22.4543C7.68863 22.658 7.89531 22.8053 8.13292 22.8698L9.89845 23.3568C10.1526 23.4259 10.4235 23.3948 10.6554 23.27C10.8873 23.1451 11.0624 22.9361 11.1447 22.6859L12.6766 18.0447L17.2233 17.1432H19.6172L23.3664 22.9144C23.4936 23.1112 23.6844 23.2585 23.907 23.3318C24.1295 23.4051 24.3705 23.4 24.5898 23.3174L26.3356 22.6579C26.5743 22.5657 26.7706 22.3885 26.8868 22.1604C27.0029 21.9323 27.0308 21.6694 26.9649 21.422H26.967Z"
                fill={props.color? props.color: "currentColor"}/>
            <path
                d="M7.66432 9.79872C7.68116 9.53195 7.59449 9.26895 7.42234 9.06446C7.25081 8.86078 7.00695 8.73159 6.74209 8.70409L2.77068 8.3084L2.34695 7.3924L6.10754 5.12213C6.25784 5.0309 6.38252 4.90299 6.4699 4.75041C6.55727 4.59783 6.60447 4.42556 6.60708 4.24975L6.62785 2.77605L7.6851 4.72333L9.57214 8.37487L11.24 7.11926L9.5036 3.76371L9.49425 3.74605L7.7526 0.539014C7.66483 0.376972 7.53516 0.241468 7.37714 0.146655C7.21912 0.0518408 7.03854 0.00119526 6.85426 7.42296e-06H5.62357C5.48876 -0.000505364 5.35516 0.0255546 5.23042 0.076698C5.10568 0.127841 4.99224 0.203065 4.89659 0.298071C4.70091 0.489819 4.58893 0.751118 4.58503 1.02506L4.55595 3.65051L0.492104 6.09837C0.276511 6.23176 0.117159 6.43941 0.0440809 6.68217C-0.0289966 6.92494 -0.0107514 7.18605 0.0953777 7.41629L1.15781 9.70629C1.23313 9.8684 1.34934 10.0081 1.49501 10.1117C1.64068 10.2153 1.8108 10.2793 1.98865 10.2972L6.41287 10.7428L7.66432 9.79872Z"
                fill={props.color? props.color: "currentColor"}/>
            <path
                d="M7.39156 12.1709C7.4956 12.1723 7.59727 12.1398 7.68131 12.0785L21.6737 1.54343C21.7414 1.49686 21.7963 1.43394 21.8332 1.36051C21.8702 1.28708 21.8879 1.20551 21.8849 1.12337C21.8819 1.04123 21.8582 0.961176 21.816 0.890637C21.7738 0.820098 21.7145 0.761356 21.6436 0.71986C21.5584 0.670226 21.4601 0.647896 21.3619 0.655881C21.2636 0.663867 21.1702 0.701783 21.0942 0.764517L7.1018 11.2975C7.0416 11.3435 6.99294 11.4029 6.95968 11.471C6.92642 11.5391 6.90947 11.614 6.91018 11.6898C6.91088 11.7656 6.92922 11.8401 6.96375 11.9076C6.99827 11.9751 7.04802 12.0336 7.10907 12.0785C7.19156 12.1381 7.29081 12.1701 7.3926 12.1699L7.39156 12.1709Z"
                fill={props.color? props.color: "currentColor"}/>
        </SvgIcon>
    )
}
