import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {SwiperComponent} from "../../../../basic-components/swiper/swiper.component";
import styled from "styled-components";
import {SwiperRef, SwiperSlide} from "swiper/react";
import {
    IPassengerSegmentViewModel
} from "../../../../services/booking/models/passenger-segment/passenger-segment-view-model.interface";
import {SeatMapPassengerComponent} from "../components/passengers/seat-map-passenger.component";
import {Check} from "../../../../types/type-checking";
import {SWIPER_SLIDE_CSS_CLASS} from "../../../../basic-components/swiper/swiper-consts";


const ContainerBox = styled.div`
    padding: ${props => props.theme.spacing.spacing12} 0;
`

const SwiperBox = styled(SwiperComponent)`
    width: 100%;
    height: 100%;
    padding: 0;
    
    & .${SWIPER_SLIDE_CSS_CLASS} {
        padding: 0 1px;
    }
`


export const SeatMapPassengersSwiperSmallScreenComponent: React.FC = observer(() => {
    const services = useServices();
    const swiperRef = useRef<SwiperRef | null>(null);
    const seatMapsEditor = services.booking.current.seatsMapsEditors;

    const currentSeatMap = seatMapsEditor.currentSeatMap;

    const currentSlideIndex = currentSeatMap?.segment.passengers.findIndex(p => p.passengerKey === currentSeatMap.currentPassengerSegment.passengerKey)

    useEffect(() => {
        const swiper = swiperRef.current;
        if(swiper && !Check.isNullOrUndefined(currentSlideIndex)) {
            swiper.swiper.slideTo(currentSlideIndex);
        }
    }, [currentSlideIndex]);


    if(!currentSeatMap) {
        return null;
    }


    const renderOneSlide = (passengerSegment: IPassengerSegmentViewModel) => {

        return (
            <SwiperSlide key={passengerSegment.passengerSegmentUniqueKey}>
                <SeatMapPassengerComponent segmentSeatMap={currentSeatMap} passengerSegment={passengerSegment}/>
            </SwiperSlide>
        );
    }

    let slidesPerView = 2;
    if(currentSeatMap.segment.passengers.length === 1) {
        slidesPerView = 1;
    }

    let centeredSlides = true;

    if(currentSeatMap.segment.passengers.length === slidesPerView || currentSeatMap.currentPassengerSegment.passengerKey === currentSeatMap.segment.passengers[0].passengerKey) {
        centeredSlides = false;
    }

    return (
        <ContainerBox>

            <SwiperBox ref={swiperRef}
                       slidesPerView={slidesPerView}
                       spaceBetween={12}
                       centeredSlides={centeredSlides}
                       initialSlide={currentSlideIndex}>
                {currentSeatMap.segment.passengers.map(renderOneSlide)}
            </SwiperBox>
        </ContainerBox>

    )

})