import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../hooks/use-services.hook";
import {FlightsDatesSelectorModel} from "../../../../services/flight-search/dates-selection/flights-dates-selector.model";
import {BookingFlowPage} from "../../../booking-flow/booking-flow.page";
import {scrollElementIntoViewLazySmooth} from "../../../../utils/scroll-element-into-view";

import styled from "styled-components";
import {CardBox} from "../../../../basic-components/card/card.box";
import {
    FlightDatesSelectorComponent
} from "../../../../components/flight-search/dates-selection/flight-dates-selector.component";
import {useScreenMediaQueriesChecks} from "../../../../hooks/use-screen-media-queries-checks.hook";
import {
    ManageMyBookingNewFlightsDatesSelectionHeaderLargeScreenComponent
} from "./manage-my-booking-new-flights-dates-selection-header.large-screen.component";
import {
    ManageMyBookingNewFlightsDatesSelectionHeaderSmallScreenComponent
} from "./manage-my-booking-new-flights-dates-selection-header.small-screen.component";

const CSS_CLASS_PAGE_CONTENT = 'mmb-dates-selection-content';
const CSS_CLASS_PAGE_FOOTER = 'mmb-dates-selection-footer';

const PageContentContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const ContentWrapperForLargeScreenBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    width: 100%;
`
const ContentWrapperForSmallScreenBox = styled.div`
    display: flex;
    flex-direction: column;
`



const ManageMyBookingNewFlightsDatesSelectionPageContent: React.FC<{flightsDatesSelector: FlightsDatesSelectorModel;isContentScrolling: boolean}> = observer((props) => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();
    const [selectedDepartureDateElement, setSelectedDepartureDateElement] = useState<HTMLElement | null>(null);

    const captureSelectedDepartureDateElementRef = (date: Date, element: HTMLElement | null) => {
        if(props.flightsDatesSelector.isDepartureDate(date)) {
            setSelectedDepartureDateElement(element);
        }
    }

    useEffect(() => {
        if(selectedDepartureDateElement) {
            const timeoutRef = scrollElementIntoViewLazySmooth(selectedDepartureDateElement, {
                block: "nearest"
            });

            return () => {
                clearTimeout(timeoutRef);
            }
        }

        return () => {};

    }, [selectedDepartureDateElement, services.navigator.routes.manageMyBooking.selectNewFlightsDates.isActive]);



    const renderFlightDatesSelector = () => {
        return (
            <FlightDatesSelectorComponent flightsDatesSelector={props.flightsDatesSelector}
                                          captureSelectedDateElementRef={captureSelectedDepartureDateElementRef}
                                          swiperOptions={{
                                              hideFooter: true,
                                              hideHeader: true
                                          }} />
        )
    }

    const renderContent = () => {
        if(screenMediaQuery.smallScreen) {
            return (
                <ContentWrapperForSmallScreenBox>
                    <ManageMyBookingNewFlightsDatesSelectionHeaderSmallScreenComponent flightsDatesSelector={props.flightsDatesSelector}
                                                                                       showBottomBorder={props.isContentScrolling}/>
                    {renderFlightDatesSelector()}
                </ContentWrapperForSmallScreenBox>
            );

        } else {
            return (
                <>
                    <ManageMyBookingNewFlightsDatesSelectionHeaderLargeScreenComponent flightsDatesSelector={props.flightsDatesSelector}/>
                    <ContentWrapperForLargeScreenBox>
                        {renderFlightDatesSelector()}
                    </ContentWrapperForLargeScreenBox>
                </>
            );
        }
    }


    return (
        <PageContentContainerBox>
            {renderContent()}
        </PageContentContainerBox>

    );
})


const BookingPageBox = styled(BookingFlowPage)`
    
    ${props => props.theme.screenMediaQuery.smallScreen} {
        &  .${CSS_CLASS_PAGE_CONTENT} {
            --background: ${props => props.theme.colors.cardBackground};
        }

        /*
        & .${CSS_CLASS_PAGE_FOOTER} {
            background-color: ${props => props.theme.colors.cardBackground};
            box-shadow: none;
        }
         */
    }
    
  
    
`


export const ManageMyBookingNewFlightsDatesSelectionPage: React.FC = observer(() => {
    const services = useServices();
    const [isContentScrolling, setIsContentScrolling] = useState(false);
    const flightSearchController = services.booking.searchController;
    const flightsDatesSelector = flightSearchController.datesSelector;

    const getTitle = () => {
        if(flightSearchController.isOneWay) {
            return services.language.translate('New flight date');

        } else {
            return services.language.translate('New flight dates');
        }
    }




    const renderContent = () => {

        return (
            <ManageMyBookingNewFlightsDatesSelectionPageContent flightsDatesSelector={flightsDatesSelector} isContentScrolling={isContentScrolling}/>
        )
    }


    return (
        <BookingPageBox headerOptions={{
                            title: getTitle()
                        }}
                        contentOptions={{
                            renderContent: renderContent,
                            maxContentWidth: "970px",
                            reduceTopPaddingOnPageScroll: true,
                            onContentScroll: (event) => setIsContentScrolling(event.detail.scrollTop > 3),
                            pageContentClassName: CSS_CLASS_PAGE_CONTENT
                        }}
                        shoppingCartOptions={{
                            showPromotionCode: false
                        }}
                        footerOptions={{
                            pageFooterClassName: CSS_CLASS_PAGE_FOOTER
                        }}/>
    );
});
