import React, {useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {BookingFlowPage} from "../../booking-flow/booking-flow.page";
import {IPassengerSegmentBoardingPassViewModel} from "../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import styled from "styled-components";
import {SpinnerComponent} from "../../../components/spinner/spinner.component";
import {BoardingPassesContainerComponent} from "./boarding-passes-container/boarding-passes-container.component";
import {IServiceFactory} from "../../../services/service-factory.interface";
import {makeObservable, observable, runInAction} from "mobx";
import {BoardingPassExportAllButtonComponent} from "./export-all-buttons/boarding-pass-export-all-button.component";
import {
    AddAllBoardingPassesToWalletButtonComponent
} from "../../../components/add-to-wallet-buttons/add-all-boarding-passes-to-wallet-button.component";
import {useContainerMediaQueriesChecks} from "../../../hooks/use-container-media-queries-checks.hook";
import {
    BookingFlowContinueButtonComponent
} from "../../booking-flow/common-components/continue-button/booking-flow-continue-button.component";
import {useScreenMediaQueriesChecks} from "../../../hooks/use-screen-media-queries-checks.hook";

class BoardingPassesLoader {
    constructor(private readonly services: IServiceFactory) {
        makeObservable(this, {
            isLoading: observable.ref,
            boardingPasses: observable.ref
        })
    }

    isLoading: boolean = true;
    bookingKey: string = "";
    boardingPasses: IPassengerSegmentBoardingPassViewModel[] = [];
    isLoaded: boolean = false;

    async loadBoardingPasses(): Promise<void> {
        const booking = this.services.booking.current;
        const boardingPasses = await booking.getBoardingPassesToShow();
        runInAction(() => {
            this.bookingKey = booking.bookingKey ?? "";
            this.boardingPasses = boardingPasses;
            this.isLoading = false;
            this.isLoaded = true;
        })
    }
}

const SpinnerContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
`

const PageContentBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap16};
    
`

const ExportButtonsForSmallScreenContainerBox = styled.div<{supportsSharing: boolean}>`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding-right: ${props => props.supportsSharing ? props.theme.spacing.cardDefaultPadding : "0"};
`


const CheckInBoardingPassesPageContent: React.FC<{loader: BoardingPassesLoader}> = observer((props) => {
    const services = useServices();
    const mediaQuery = useContainerMediaQueriesChecks();
    const [canShare, setCanShare] = useState(false);

    useEffect(() => {
        services.pdfExporter.canShare().then(supportsShare => {
            setCanShare(supportsShare);
        })
    }, [services.pdfExporter]);

    useEffect(() => {
        if(!props.loader.isLoaded) {
            props.loader.loadBoardingPasses();
        }

    }, [props.loader]);

    if(props.loader.isLoading) {
        return (
            <SpinnerContainerBox>
                <SpinnerComponent innerMessage={services.language.translate('Loading boarding pass')} size={23}/>
            </SpinnerContainerBox>
        )
    }

    const renderExportButtons = () => {
        if(props.loader.isLoading) {
            return null;
        }

        if(!mediaQuery.smallScreen) {
            return null;
        }

        return (
            <ExportButtonsForSmallScreenContainerBox supportsSharing={canShare}>
                <BoardingPassExportAllButtonComponent bookingKey={props.loader.bookingKey} boardingPasses={props.loader.boardingPasses}/>
            </ExportButtonsForSmallScreenContainerBox>);
    }


    return (
        <PageContentBox>
            {renderExportButtons()}
            <BoardingPassesContainerComponent bookingKey={props.loader.bookingKey} boardingPasses={props.loader.boardingPasses}/>
        </PageContentBox>

    );
});

const PageTitleBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap16};
    padding-right: ${props => props.theme.spacing.cardDefaultPadding};
`

const PageTitleTextBox = styled.div`
    flex-grow: 1;
`

const CheckInBoardingPassesPageTitleComponent: React.FC<{loader: BoardingPassesLoader}> = observer((props) => {
    const services = useServices();
    const mediaQuery = useContainerMediaQueriesChecks();

    const renderExportButtons = () => {
        if(props.loader.isLoading) {
            return null;
        }

        if(mediaQuery.smallScreen) {
            return null;
        }

        return (<BoardingPassExportAllButtonComponent bookingKey={props.loader.bookingKey} boardingPasses={props.loader.boardingPasses}/>);
    }

    return (
        <PageTitleBox>
            <PageTitleTextBox>
                {services.language.translate('Boarding pass')}
            </PageTitleTextBox>
            {renderExportButtons()}
        </PageTitleBox>
    )
});

const FooterBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
`

const ContinueButton = styled(BookingFlowContinueButtonComponent)`
    min-width: min(100%, 340px);
    
`


export const CheckInBoardingPassPage: React.FC = observer(() => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();
    const boardingPassesLoader = useRef(new BoardingPassesLoader(services));

    const shouldRenderAddToWalletButton = () => {
        if(boardingPassesLoader.current.isLoading) {
            return false;
        }

        if(!services.booking.currentPageCanBeRendered) {
            return false;
        }


        return services.mobileWallet.filterEligiblePasses(boardingPassesLoader.current.boardingPasses).length > 0;
    }

    const getExtraFooterRenderer = () => {
        if(shouldRenderAddToWalletButton()) {
            return () => {
                return (<AddAllBoardingPassesToWalletButtonComponent bookingKey={boardingPassesLoader.current.bookingKey} boardingPasses={boardingPassesLoader.current.boardingPasses}/>)
            }

        }

        return () => (
            <FooterBox>
                <ContinueButton fullWidth={screenMediaQuery.smallScreen} customButtonText={services.language.translate('Return to booking')}/>
            </FooterBox>
        );
    }



    return (
        <BookingFlowPage disableSwipeNavigation={true}
                         headerOptions={{
                            title: <CheckInBoardingPassesPageTitleComponent loader={boardingPassesLoader.current}/>,
                            dontAskForConfirmationOnLogoClick: true
                         }}
                         contentOptions={{
                             renderContent: () => (<CheckInBoardingPassesPageContent loader={boardingPassesLoader.current}/>)
                         }}
                         footerOptions={{
                             renderExtraFooterContent: getExtraFooterRenderer(),
                         }}
                         shoppingCartOptions={{
                             hideContinueButton: true,
                             hideShoppingCart: true,
                             showPromotionCode: false
                         }}/>
    );
});
