import {SegmentModel} from "../segment/segment.model";
import {
    IDotRezCompartmentUnit, IDotRezSeatFee,
    IDotRezSegmentSeatMap
} from "../../../dot-rez-api/data-contracts/booking/seat-map/seat-map.data-contracts";
import {computed, makeObservable, observable, runInAction} from "mobx";
import {SeatsRowsGroupModel} from "./seats-rows-group.model";
import {PassengerSegmentModel} from "../passenger-segment/passenger-segment.model";
import {SeatsRowGroupType} from "./seats-row-group-type.enum";
import {SeatModel} from "./seat.model";
import {Check} from "../../../../types/type-checking";
import {SeatsRowModel} from "./seats-row.model";
import {Price} from "../../../currency/price";
import {ISegmentSeatMapEditorViewModel} from "./segment-seat-map-editor-view-model.interface";
import {sumOfNonTaxesServiceCharges} from "../base-models/fees/service-charges.helpers";
import {NullableUndefinedString} from "../../../../types/nullable-types";
import {BookingSessionStorageKeys} from "../storage/booking-storage.interface";
import {ExitRowProperty} from "./seat-properties";
import {BookingSeatsMapsEditorModel} from "./booking-seats-maps-editor.model";
import {BookingModel} from "../booking.model";
import {IPassengerSegmentViewModel} from "../passenger-segment/passenger-segment-view-model.interface";
import {ISeatViewModel} from "./seat-view-model.interface";

export class SegmentSeatMapEditorModel implements ISegmentSeatMapEditorViewModel {
    constructor(public readonly segment: SegmentModel) {

        this._currentPassengerKey = this.booking.storage.getItem(BookingSessionStorageKeys.currentPassengerSeatMap);
        makeObservable<this, '_currentPassengerKey'
                             | '_unitsDictionary'
                             | '_passengersGroupsFees'
                             | '_seatsModelsMap'
                             | '_seatsOnLeftWing'
                             | '_seatsOnRightWing'>(this, {
            _unitsDictionary: computed,
            _currentPassengerKey: observable.ref,
            currentPassengerSegment: computed,
            _passengersGroupsFees: computed,
            _seatsModelsMap: computed,
            rowsGroups: computed,
            firstEmergencyRow: computed,
            availableSeatsLetters: computed,
            seatMapData: computed,
            minSeatPrice: computed,
            firstEmergencySeat: computed,
            _seatsOnLeftWing: computed,
            _seatsOnRightWing: computed
        });
    }

    get isLoaded(): boolean {
        return this.segment.journey.isSeatMapLoaded(this.segment.designator)
    }

    shouldAllowSeatByFilters(seat: SeatModel): boolean {
        return this.seatsMaps.shouldAllowSeatByFilters(seat);
    }

    get firstPassengerSegmentWithoutSeat(): IPassengerSegmentViewModel | null {
        return this.segment.passengers.find(ps => !ps.hasSeat) ?? null;
    }

    private _currentPassengerKey: NullableUndefinedString = null;
    get currentPassengerSegment(): PassengerSegmentModel {
        if(this._currentPassengerKey) {
            const passengerSegment = this.segment.passengers.find(p => p.passengerKey === this._currentPassengerKey);
            if(passengerSegment) {
                return passengerSegment;
            }
        }

        return this.segment.passengers[0];
    }

    private get booking(): BookingModel {
        return this.segment.journey.booking;
    }

    private get seatsMaps(): BookingSeatsMapsEditorModel {
        return this.booking.seatsMapsEditors;
    }

    get segmentKey(): string {
        return this.segment.segmentKey;
    }

    setCurrentPassenger(passengerKey: string): void {
        runInAction(() => {
            this._currentPassengerKey = passengerKey;
        });

        this.booking.storage.setItem(BookingSessionStorageKeys.currentPassengerSeatMap, passengerKey)
    }

    get rowsGroups(): SeatsRowsGroupModel[] {
        return this._buildSeatRowsGroups();
    }

    get seatsPerRowCount(): number {
        return this.availableSeatsLetters.length;
    }

    get seatMapData(): IDotRezSegmentSeatMap {
        return this.segment.journey.getSeatMap(this.segment.designator);
    }

    private get _unitsDictionary(): Record<string, IDotRezCompartmentUnit> {
        return this.seatMapData
                            .seatMap
                            .decks[0]
                            .value
                            .compartments[0]
                            .value
                            .units
                            .toDictionary(unit => unit.unitKey);
    }

    getSeatDotRezData(seatKey: string): IDotRezCompartmentUnit {
        return this._unitsDictionary[seatKey];
    }

    private _buildSeatRowsGroups(): SeatsRowsGroupModel[] {
        const seatRowGroups: Record<SeatsRowGroupType, SeatsRowsGroupModel> = {
            [SeatsRowGroupType.first]: new SeatsRowsGroupModel(SeatsRowGroupType.first, () => this.segment.translate('First row seats')),
            [SeatsRowGroupType.front]: new SeatsRowsGroupModel(SeatsRowGroupType.front, () => this.segment.translate('Front seats')),
            [SeatsRowGroupType.emergency]:  new SeatsRowsGroupModel(SeatsRowGroupType.emergency, () => this.segment.translate('Emergency seats')),
            [SeatsRowGroupType.rear]: new SeatsRowsGroupModel(SeatsRowGroupType.rear, () => this.segment.translate('Back seats'))
        }

        for(let row of this._createRowsModels()) {
            seatRowGroups[row.location].rows.push(row);
        }

        return Object.values(seatRowGroups).filter(group => group.rows.length > 0);
    }



    private _extractRowNumber(unit: IDotRezCompartmentUnit) {
        return parseInt(unit.designator.substring(0, unit.designator.length -1));
    }


    private get _seatsModelsMap(): Record<string, SeatModel> {
        const seatModels: Record<string, SeatModel> = {};
        for(let dotRezSeat of Object.values(this._unitsDictionary)) {
            const rowNumber = this._extractRowNumber(dotRezSeat);
            if(Check.isNumber(rowNumber)) {
                seatModels[dotRezSeat.unitKey] = new SeatModel(dotRezSeat.unitKey, rowNumber, this)
            }
        }

        return seatModels
    }

    private get _seatModels(): SeatModel[] {
        return Object.values(this._seatsModelsMap);
    }

    get firstEmergencySeat(): SeatModel | null {
        return this._seatModels.find(s => s.rowNumber > 5 && s.isOnExitRow) ?? null;
    }

    get availableSeatsLetters(): string [] {
        return Object.values(this._seatsModelsMap)
                     .distinct(seat => seat.seatLetter, seat => seat.seatLetter)
                     .sort((l1, l2) => l1.localeCompare(l2));
    }

    private _createRowsModels(): SeatsRowModel[] {
        const allSeats = Object.values(this._seatsModelsMap);
        const rows: SeatsRowModel[] = [];

        let currentRow = new SeatsRowModel(this, allSeats[0].rowNumber);
        rows.push(currentRow);
        for(let i = 0; i < allSeats.length; i++) {
            const seat = allSeats[i];
            if(seat.rowNumber !== currentRow.rowNumber) {
                currentRow = new SeatsRowModel(this, seat.rowNumber);
                rows.push(currentRow);
            }
            currentRow.seats.push(seat);
        }

        return rows;
    }

    private get _passengersGroupsFees(): Record<string, Record<number, IDotRezSeatFee[]>> {


        return this.seatMapData.fees.toDictionaryOfType(fees => fees.key, fees => {

            const groups: Record<number, IDotRezSeatFee[]> = {};

            fees.value.groups.forEach(g => {
                groups[g.key] = g.value.fees;
            });
            return groups;
        });

        /*
        const passengerSeatsFees = this.seatMapData.fees.find(fees => fees.key === this.currentPassengerSegment.passengerKey);
        if(!passengerSeatsFees) {
            return {};
        }

        const groups: Record<number, IDotRezSeatFee[]> = {};

        passengerSeatsFees.value.groups.forEach(g => {
            groups[g.key] = g.value.fees
        });

        return groups;

         */
    }

    getCurrentPassengerGroupFees(groupNumber: number): IDotRezSeatFee[] {
        const currentPassengerSeatFees = this._passengersGroupsFees[this.currentPassengerSegment.passengerKey];
        if(currentPassengerSeatFees) {
            return currentPassengerSeatFees[groupNumber] || [];
        } else {
            return [];
        }

    }

    getPassengerGroupFee(passengerKey: string, groupNumber: number): IDotRezSeatFee[] {
        const currentPassengerSeatFees = this._passengersGroupsFees[passengerKey];
        if(currentPassengerSeatFees) {
            return currentPassengerSeatFees[groupNumber] || [];
        } else {
            return [];
        }
    }

    createPrice(amount: number): Price {
        return this.segment.createPrice(amount);
    }

    isSeatRestrictedBySelectedSsrs(seat: SeatModel): boolean {
        return this.currentPassengerSegment.isSeatRestrictedBySelectedSsrs(seat);
    }

    isSeatRestrictedForCurrentPassengerType(seat: SeatModel): boolean {
        return this.currentPassengerSegment.passenger.passengerType.isChild && !seat.allowChild;
    }

    findSeatOwner(seatKey: string): PassengerSegmentModel | null {
        for(let p of this.segment.passengers) {
            if(p.assignedSeat?.seatKey === seatKey) {
                return p;
            }
        }

        return null;
    }

    get minSeatPrice(): Price {
        let minPrice = 9999999999;

        this.seatMapData.fees.forEach(passengerSeatsFees => {
            passengerSeatsFees.value.groups.forEach(group => {
                group.value.fees.forEach(groupFee => {
                    minPrice = Math.min(minPrice, sumOfNonTaxesServiceCharges(groupFee.serviceCharges));
                })
            })
        });

        if(minPrice === 9999999999) {
            return this.createPrice(0);
        }
        return this.createPrice(minPrice);

    }

    getSeat(seatKey: string): SeatModel {
        return this._seatsModelsMap[seatKey];
    }

    findSeatByNumber(seatNumber: string): SeatModel | null {
        return this._seatModels.find(seat => seat.seatNumber === seatNumber) || null;
    }

    async sellSeat(seat: SeatModel): Promise<void> {
        if(this.seatsMaps.currentSeatMap?.segmentKey !== this.segmentKey) {
            //It means that current selected passenger segment is in another segment.
            //and the user clicked on a seat in this segment, so in this case
            //we sell the seat to the first passenger that doesn't have a seat and make this the selected passenger
            const firstPassengerWithoutASeat = this.segment.passengers.find(p => !p.assignedSeat)
            if(firstPassengerWithoutASeat) {
                this.seatsMaps.setCurrentPassengerSegment(firstPassengerWithoutASeat);
            }
        }

        await this.currentPassengerSegment.sellSeat(seat);

    }

    get firstEmergencyRow(): number {
        for(let dotRezSeat of Object.values(this._unitsDictionary)) {
            const rowNumber = this._extractRowNumber(dotRezSeat);

            if(Check.isNumber(rowNumber) && rowNumber > 2 && dotRezSeat.properties.some(s => s.code === ExitRowProperty.True.code && s.value === ExitRowProperty.True.value)) {
                return rowNumber;
            }
        }
        return 15;
    }

    private get _seatsOnLeftWing(): ISeatViewModel[] {
        let result: ISeatViewModel[] = [];

        for(let rowGroup of this.rowsGroups) {
            for(let row of rowGroup.rows) {
                if(row.seatOnTheLeftWing) {
                    result.push(row.seatOnTheLeftWing);
                }
            }
        }

        return result;
    }

    private get _seatsOnRightWing(): ISeatViewModel[] {
        let result: ISeatViewModel[] = [];

        for(let rowGroup of this.rowsGroups) {
            for(let row of rowGroup.rows) {
                if(row.seatOnTheRightWing) {
                    result.push(row.seatOnTheRightWing);
                }
            }
        }

        return result;
    }
    get firstLeftSeatOnWing(): ISeatViewModel | null {
        return this._seatsOnLeftWing[0];
    }
    get lastLeftSeatOnWing(): ISeatViewModel | null {
        return this._seatsOnLeftWing[this._seatsOnLeftWing.length - 1];
    }
    get firstRightSeatOnWing(): ISeatViewModel | null {
        return this._seatsOnRightWing[0];
    }
    get lastRightSeatOnWing(): ISeatViewModel | null {
        return this._seatsOnRightWing[this._seatsOnRightWing.length - 1];
    }

    getRow(rowNumber: number): SeatsRowModel | null {
        if(rowNumber <= 0) {
            return null;
        }

        for(let g of this.rowsGroups) {
            for(let r of g.rows) {
                if(r.rowNumber === rowNumber) {
                    return r;
                } else if(r.rowNumber > rowNumber) {
                    return null;
                }
            }
        }

        return null;
    }

    getFirstAvailableSeat(): SeatModel | null {
        for(let rowGroup of this.rowsGroups) {
            for(let row of rowGroup.rows) {
                for(let seat of row.seats) {
                    if(seat.isAvailable) {
                        return seat;
                    }
                }
            }
        }

        return null;
    }
}
