import React, {PropsWithChildren} from "react";
import {ShoppingCartButtonComponent} from "../../../components/shopping-cart/shopping-cart-button.component";
import {useServices} from "../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";
import {PromoCodeComponent} from "../../../components/promotionals/promo-code/promo-code.component";
import {StandardPageFooterComponent} from "../../../basic-components/page/standard-page-footer/standard-page-footer.component";
import styled, {css} from "styled-components";
import {BookingFlowContinueButtonComponent} from "../common-components/continue-button/booking-flow-continue-button.component";


const MainFooterBox = styled.div<{$hasPromoCode: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${props => props.theme.spacing.flexGap8};
    width: 100%;
    
    ${props => props.$hasPromoCode
        ? css`
            flex-direction: column;
            align-items: flex-start;
        `
        : css`
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        `    
    }
    
`

const PromoCodeAndContinueButtonContainerBox = styled.div<{$hasPromoCode: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: ${props => props.theme.spacing.flexGap12};
    ${props => props.$hasPromoCode && css`width: 100%;`}
    
`

const ContainerWhenDisplayedInModal = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    width: 100%;
`

interface BookingFlowPageFooterComponentProps extends PropsWithChildren {
    hideContinueButton?: boolean;
    continueButtonText?: string;
    hideShoppingCart?: boolean;
    replaceShoppingCart?: () => React.ReactElement;
    modalHandler?: IDialogConfirmationHandler;
    showPromotionCode?: boolean;
    replaceContinueButton?: () => React.ReactElement;
    className?: string;
}

export const BookingFlowPageFooterSmallScreenComponent: React.FC<BookingFlowPageFooterComponentProps> = observer((props) => {
    const services = useServices();
    const booking = services.booking.current;

    const shouldShowPromoCode = Boolean(props.showPromotionCode) && booking.allowPromoCode;

    const renderPromotionCode = () => {
        if(!shouldShowPromoCode){
            return null;
        }

        return (
            <PromoCodeComponent promoCode={booking.promoCode}/>
        );
    }


    const renderContinueButton = () => {
        if(props.replaceContinueButton) {
            return props.replaceContinueButton();
        }

        if(props.hideContinueButton) {
            return null;
        }
        return (
            <BookingFlowContinueButtonComponent isPromotionAllowed={shouldShowPromoCode}
                                                modalHandler={props.modalHandler}
                                                customButtonText={props.continueButtonText}
                                                fullWidth={false}/>
        );
    }

    const renderShoppingCartTotalAndExpandButton = () => {
        if(props.replaceShoppingCart) {
            return props.replaceShoppingCart();
        }

        if(props.hideShoppingCart) {
            return null;
        }

        return (
            <ShoppingCartButtonComponent modalHandler={props.modalHandler}
                                         modalOptions={{
                                             hideContinueButton: props.hideContinueButton,
                                             continueButtonText: props.continueButtonText,
                                         }}/>
        );

    }

    const renderMainFooter = () => {
        if(props.hideShoppingCart && props.hideContinueButton) {
            return null;
        }

        const promoCode = renderPromotionCode();
        const continueButton = renderContinueButton();
        return (
            <MainFooterBox $hasPromoCode={Boolean(promoCode)}>
                {renderShoppingCartTotalAndExpandButton()}
                <PromoCodeAndContinueButtonContainerBox $hasPromoCode={Boolean(promoCode)}>
                    {promoCode}
                    {continueButton}
                </PromoCodeAndContinueButtonContainerBox>

            </MainFooterBox>
        );
    }

    if(props.modalHandler) {
        return (
            <ContainerWhenDisplayedInModal>
                {renderMainFooter()}
                {props.children}
            </ContainerWhenDisplayedInModal>
        )
    }

    const mainFooter = renderMainFooter();

    if(mainFooter || React.Children.count(props.children) > 0) {
        return (
            <StandardPageFooterComponent className={props.className}>
                {props.children}
                {renderMainFooter()}
            </StandardPageFooterComponent>
        );
    }

    return (
        <StandardPageFooterComponent className={props.className}/>
    );
});
