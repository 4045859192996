import React from "react";
import {observer} from "mobx-react";
import {
    IPassengerSegmentViewModel
} from "../../../../../services/booking/models/passenger-segment/passenger-segment-view-model.interface";
import styled, {css} from "styled-components";
import {IonIcon} from "@ionic/react";
import {radioButtonOffOutline, radioButtonOnOutline} from "ionicons/icons";
import {
    ISegmentSeatMapEditorViewModel
} from "../../../../../services/booking/models/seat-maps/segment-seat-map-editor-view-model.interface";
import {useServices} from "../../../../../hooks/use-services.hook";
import {NotSelectedSeatBox, SeatSelectedBox} from "../seats/seat.component";
import {CSS_VAR_SEAT_HEIGHT, CSS_VAR_SEAT_WIDTH} from "../seat-map-css-constants";


const PassengerSegmentBox = styled.div<{$isCurrent: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: ${props => props.theme.spacing.spacing8} ${props => props.theme.spacing.spacing16};
    gap: ${props => props.theme.spacing.spacing8};
    border-radius: ${props => props.theme.border.defaultRadius};
    cursor: pointer;
    font-size: ${props => props.theme.fontSize.subtitle1};
    color: ${props => props.theme.colors.dark};
    line-height: 1;

    ${props => props.$isCurrent
            ? css`
                border: 1px solid ${props => props.theme.colors.primary};
            `
            : css`
                border: 1px solid ${props => props.theme.border.mainColor};
            `
    }
    
`

const PassengerNameContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    gap: ${props => props.theme.spacing.spacing8};
`

const PassengerNameBox = styled.div`
    
`

const RadioBox = styled.div<{$isCurrent: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
    font-size: ${props => props.theme.fontSize.h6};
    ${props => props.$isCurrent 
            ? css`
                color: ${props => props.theme.colors.primary};
            `
            : ''
    }
`

const SeatWrapperBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    ${CSS_VAR_SEAT_WIDTH}: 2.25rem;
    ${CSS_VAR_SEAT_HEIGHT}: 2.5625rem;

`

const SeatAssignedBox = styled(SeatSelectedBox)`
    
`

const SeatNotAssignedBox = styled(NotSelectedSeatBox)`
    
`


const SeatPriceBox = styled.div`
    font-size: ${props => props.theme.fontSize.caption};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    color: ${props => props.theme.colors.tertiaryShade};
`

const NotAvailablePriceBox = styled(SeatPriceBox)`
    font-size: ${props => props.theme.fontSize.caption};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    color: ${props => props.theme.colors.medium};
    
`

interface SeatMapPassengerLargeScreenComponentProps {
    passengerSegment: IPassengerSegmentViewModel;
    segmentSeatMap: ISegmentSeatMapEditorViewModel;
}
export const SeatMapPassengerComponent: React.FC<SeatMapPassengerLargeScreenComponentProps> = observer((props) => {

    const services = useServices();
    const booking = services.booking.current;

    const isCurrent = props.segmentSeatMap.currentPassengerSegment.passengerSegmentUniqueKey === props.passengerSegment.passengerSegmentUniqueKey;


    const renderRadioIcon = () => {
        if(isCurrent) {
            return (
                <IonIcon icon={radioButtonOnOutline}/>
            )
        } else {
            return (
                <IonIcon icon={radioButtonOffOutline}/>
            );
        }
    }

    const onClick = () => {
        booking.seatsMapsEditors.setCurrentPassengerSegment(props.passengerSegment);
        props.passengerSegment.scrollToAssignedSeat();
    }

    const renderSeat = () => {
        if(props.passengerSegment.assignedSeat) {
            return (
                <SeatAssignedBox>
                    {props.passengerSegment.assignedSeat.seatNumber}
                </SeatAssignedBox>
            );
        } else {
            return (
                <SeatNotAssignedBox/>
            );
        }
    }

    const renderPrice = () => {
        if(props.passengerSegment.assignedSeat) {
            if(!props.passengerSegment.assignedSeat.isPurchasedOnCurrentSession) {
                return null;
            }
            return (<SeatPriceBox>
                {props.passengerSegment.assignedSeat.price.toString()}
            </SeatPriceBox>)
        }
        return (<NotAvailablePriceBox>N/A</NotAvailablePriceBox>);
    }


    return (
        <PassengerSegmentBox $isCurrent={isCurrent} onClick={onClick}>
            <PassengerNameContainerBox>
                <RadioBox $isCurrent={isCurrent}>
                    {renderRadioIcon()}
                </RadioBox>
                <PassengerNameBox>
                    {props.passengerSegment.passengerFullName}
                </PassengerNameBox>
            </PassengerNameContainerBox>
            <SeatWrapperBox>
                {renderSeat()}
                {renderPrice()}
            </SeatWrapperBox>

        </PassengerSegmentBox>
    )
})