import React from "react";
import styled from "styled-components";
import {flightItinerarySpaceBetweenIconAndHeaderText} from "./css-constants";

const FlightItineraryAccordionHeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const FlightItineraryAccordionHeaderText = styled.div`
    font-size: ${props => props.theme.fontSize.h4};
    font-weight: ${props => props.theme.fontWeight.bold};
    margin-left: ${props => flightItinerarySpaceBetweenIconAndHeaderText(props.theme)};
`;

interface FlightItineraryAccordionHeaderComponentProps {
    renderIcon: () => React.ReactElement;
    title: string | React.ReactElement;
    className?: string;
}

export const FlightItineraryAccordionHeaderComponent: React.FC<FlightItineraryAccordionHeaderComponentProps> = (props) => {
    return(
        <FlightItineraryAccordionHeaderBox className={props.className}>
            {props.renderIcon()}
            <FlightItineraryAccordionHeaderText>
                   {props.title}
            </FlightItineraryAccordionHeaderText>
        </FlightItineraryAccordionHeaderBox>
    )
}
