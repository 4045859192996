import React from "react";
import styled from "styled-components";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {
    ManageMyBookingFlightsComponent
} from "./components/flights/manage-my-booking-flights.component";
import {ManageMyBookingPassengersComponent} from "./components/passengers/manage-my-booking-passengers.component";
import {ManageMyBookingContactComponent} from "./components/contact/manage-my-booking-contact.component";
import {BookingFlowPage} from "../../booking-flow/booking-flow.page";
import {ManageMyBookingServicesComponent} from "./services/manage-my-booking-services.component";
import {useScreenMediaQueriesChecks} from "../../../hooks/use-screen-media-queries-checks.hook";
import {
    ManageMyBookingDashboardHeaderTopMiddleSectionComponent
} from "./manage-my-booking-dashboard-header-top-middle-section.component";
import {
    ManageMyBookingDashboardHeaderTitleSmallScreenComponent
} from "./manage-my-booking-dashboard-header-title.small-screen.component";
import {
    ManageMyBookingDashboardHeaderTitleLargeScreenComponent
} from "./manage-my-booking-dashboard-header-title.large-screen.component";


const ManageMyBookingDashboardPageContentBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.spacing36};
`

const NonModifiableBookingMessageBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-size: ${props => props.theme.fontSize.h2};
    text-align: center;
    justify-content: center;
`


export const ManageMyBookingDashboardPageContent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;

    if(!booking.manageMyBooking.canBeModified) {
        return (
            <NonModifiableBookingMessageBox>
                {services.language.translate('This booking cannot be modified')}
            </NonModifiableBookingMessageBox>
        )
    }



    return (
        <ManageMyBookingDashboardPageContentBox>
            <ManageMyBookingDashboardHeaderTitleSmallScreenComponent/>
            {/*<BookingCodeAndPrintTicketComponent booking={booking}/>*/}
            <ManageMyBookingFlightsComponent key={"DASHBOARD_FLIGHTS"}/>

            <ManageMyBookingServicesComponent key={"DASHBOARD_SERVICES"}/>

            <ManageMyBookingPassengersComponent key={"DASHBOARD_PASSENGERS"}/>


            <ManageMyBookingContactComponent key={"DASHBOARD_CONTACT"}/>
        </ManageMyBookingDashboardPageContentBox>
    );
});


export const ManageMyBookingDashboardPage: React.FC = observer(() => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();

    const canRenderThePage = services.booking.currentPageCanBeRendered;

    const renderTitle = () => {
        if(!canRenderThePage) {
            return null;
        }

        if(screenMediaQuery.smallScreen) {
            return null;
        }

        return (
            <ManageMyBookingDashboardHeaderTitleLargeScreenComponent/>
        )

    }

    const hideContinueButton = () => {
        if(!canRenderThePage) {
            return true;
        }

        return !services.booking.current.hasPurchasesOnCurrentSession;
    }

    return (
        <BookingFlowPage disableSwipeNavigation={true}
                         headerOptions={{
                            title: renderTitle(),
                            hideWizardProgressIndicator: true,
                            renderHeaderTopMiddleSectionForLargeScreen: () => (<ManageMyBookingDashboardHeaderTopMiddleSectionComponent/>),
                            useLargeLogoOnSmallScreen: true
                         }}
                         shoppingCartOptions={{
                             showPromotionCode: false,
                             hideContinueButton: hideContinueButton()
                         }}
                         contentOptions={{
                             renderContent:() => <ManageMyBookingDashboardPageContent />
                         }}
                         footerOptions={{
                             hideFooter: canRenderThePage && !services.booking.current.manageMyBooking.canBeModified,
                         }}/>
    );
});
