import React from "react";
import {observer} from "mobx-react";
import {HybridHomePageBottomNavbarComponent} from "./navbar/hybrid-home-page-bottom-navbar.component";
import {
    StandardPageFooterComponent
} from "../../../../basic-components/page/standard-page-footer/standard-page-footer.component";
import {useServices} from "../../../../hooks/use-services.hook";

export const HybridHomePageFooterComponent: React.FC = observer(() => {
    const services = useServices();
    const currentTab = services.homePage.currentTab;
    return (
        <StandardPageFooterComponent>
            {currentTab.renderFooter()}
            <HybridHomePageBottomNavbarComponent/>
            {
                /**
                 * Commented line below shows how to use routes with parameters
                 */
                //<IonButton onClick={() => services.navigator.hybridRoutes.sampleRouteWithParams.activate({routeParams: {id: "xxx"}})}>Click</IonButton>
            }
        </StandardPageFooterComponent>
    )
})
