import styled from "styled-components";
import {
    StandardPageContentComponent
} from "../../../../basic-components/page/standard-page-content/standard-page-content.component";


export const HomePageHybridContentComponent = styled(StandardPageContentComponent)`
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;

    &::part(scroll){
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }
`