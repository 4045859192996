import {ServiceBase} from "../service-base";
import {IRegionsService} from "./regions.service.interface";
import {IServiceFactory} from "../service-factory.interface";
import {computed, makeObservable, observable, reaction} from "mobx";
import {IItalianRegionConfiguration} from "../configuration/objects/italian-region-configuration.interface";

export class RegionsService extends ServiceBase implements IRegionsService {
    constructor(services: IServiceFactory) {
        super(services);

        reaction(() => this.services.configuration.data.italianRegions,
            regions => {
                this._regionsMap = regions.toDictionary(r => r.code);
            }, {
                fireImmediately: true
            });
        makeObservable(this, {
            _regionsMap: observable.ref,
            allItalianCities: computed,
            allSicilianCities: computed
        });
    }

    _regionsMap: Record<string, IItalianRegionConfiguration> = {};
    getItalianRegions(): IItalianRegionConfiguration[] {
        return this.services.configuration.data.italianRegions;
    }

    getItalianRegionCities(regionCode: string): string[] {
        const region = this._regionsMap[regionCode];
        if(region) {
            return region.cities;
        }

        return [];
    }

    get allItalianCities(): string[] {
        return Object.values(this._regionsMap)
                     .selectMany(r => r.cities)
                     .distinct(city => city)
                     .sort((c1, c2) => c1.localeCompare(c2));
    }

    get allSicilianCities(): string[] {
        const sicilianRegions = ["AG", "CL", "CT", "EN", "ME", "PA", "RG", "SR", "TP"];
        let cities: string[] = [];

        for(let region of sicilianRegions) {
            cities = [
                ...cities,
                ...(this._regionsMap[region]?.cities ?? [])
            ];
        }

        return cities.sort((c1, c2) => c1.localeCompare(c2));
    }

}
