import React from "react";
import {observer} from "mobx-react";
import {
    IFlightDesignatorViewModel
} from "../../../../services/booking/models/designator/flight-designator-view-model.interface";
import styled from "styled-components";

const DirectFlightBox = styled.div`
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 12px;
    padding: 2px 10px;
    text-align: center;
    width: fit-content;
    color: ${props => props.theme.colors.primary};
    font-weight: ${props => props.theme.fontWeight.medium};
    white-space: nowrap;
`

interface FlightTypeComponentProps {
    designator: IFlightDesignatorViewModel;
}
export const DirectFlightComponent: React.FC<FlightTypeComponentProps> = observer((props) => {

    return (
        <DirectFlightBox>
            {props.designator.formatNumberOfStops()}
        </DirectFlightBox>
    )



})