import React from 'react';
import {SsrTypeBase} from "../../ssr-type-base";
import {IServiceFactory} from "../../../service-factory.interface";
import {PbrdSsrLargeIcon} from "./pbrd.ssr.large-icon";
import {PbrdSsrSmallIcon} from "./pbrd.ssr.small-icon";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../ssrs-preferred-orders-in-shopping-cart";
import {TimeSpan} from "../../../../types/time-span";
import {
    SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE,
    SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE, SSR_LARGE_ICON_SIZE
} from "../../ssr-types.service.interface";



export default class PbrdSsrType extends SsrTypeBase {
    constructor(services: IServiceFactory) {
        super('PBRD', services);
    }

    get smallIcon() {
         return (<PbrdSsrSmallIcon/>);
    }

    get largeIcon() {
        return (<PbrdSsrSmallIcon size={SSR_LARGE_ICON_SIZE}/>);
    }

    get iconInExtrasFirstLargeTile(): React.ReactElement | null {
        return (<PbrdSsrLargeIcon size={SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE}/>);
    }

    get iconInExtrasSmallTile(): React.ReactElement | null {
        return (<PbrdSsrLargeIcon size={SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE}/>);
    }

    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.PBRD;
    }

    get shouldWriteQuantityChangeStatusInSessionStorage(): boolean {
        return true;
    }

    protected _getPurchaseMinimumTimeBeforeFlight(): TimeSpan | null {
        return TimeSpan.fromMinutes(30);
    }
}
