import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {ISupportsSameOptionsForAllFlights} from "../../../services/booking/models/ssrs/supports-same-options-for-all-flights.interface";
import {SameForAllComponent} from "./same-for-all.component";
import {CheckBoxLabelPlacement} from "../../../basic-components/checkbox/checkbox.component";


interface SameForAllFlightToggleComponentProps {
    ssrsAggregator: ISupportsSameOptionsForAllFlights;
    className?: string;
    fullWidth?: boolean;
    checkboxLabelPlacement?: CheckBoxLabelPlacement;
}

export const SameForAllFlightsToggleComponent: React.FC<SameForAllFlightToggleComponentProps> = observer((props) => {
    const services = useServices();

    const onChange = async (checked: boolean) => {
        if(checked === props.ssrsAggregator.useSameOptionsForAllFlights) {
            return;
        }

        props.ssrsAggregator.useSameOptionsForAllFlights = checked;
    }

    return (
        <SameForAllComponent label={services.language.translate('Same for all flights')}
                             checked={() => Boolean(props.ssrsAggregator.useSameOptionsForAllFlights)}
                             onChanged={checked => onChange(checked)}
                             className={props.className}
                             checkboxLabelPlacement={props.checkboxLabelPlacement}
                             fullWidth={props.fullWidth}/>
    );
});

