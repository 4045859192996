import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';


export const PaymentCreditCardIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.8} sizeIsHeight {...props} fill={"none"} viewBox="0 0 43 28" ratio={43 / 28}>
            <path
                d="M4 2C2.89375 2 2 2.89375 2 4V6H41V4C41 2.89375 40.1063 2 39 2H4ZM2 8V12H41V8H2ZM2 14V24C2 25.1063 2.89375 26 4 26H39C40.1063 26 41 25.1063 41 24V14H2ZM0 4C0 1.79375 1.79375 0 4 0H39C41.2062 0 43 1.79375 43 4V24C43 26.2063 41.2062 28 39 28H4C1.79375 28 0 26.2063 0 24V4ZM6 21C6 20.45 6.45 20 7 20H11C11.55 20 12 20.45 12 21C12 21.55 11.55 22 11 22H7C6.45 22 6 21.55 6 21ZM14 21C14 20.45 14.45 20 15 20H23C23.55 20 24 20.45 24 21C24 21.55 23.55 22 23 22H15C14.45 22 14 21.55 14 21Z"
                fill={props.color ? props.color : "currentColor"}/>
        </SvgIcon>
    )
}