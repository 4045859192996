import React, {PropsWithChildren} from 'react';
import styled from "styled-components";

const FootNoteBox = styled.div`
    font-size: ${props => props.theme.fontSize.footNote};
    color: ${props => props.theme.colors.notesTint};
`;

interface FootNoteComponentProps extends PropsWithChildren {
    className?: string
}
export const FootNoteComponent: React.FC<FootNoteComponentProps> = (props) => {
    return (
        <FootNoteBox className={props.className}>
            {props.children}
        </FootNoteBox>
    );
}
