import React from "react";
import styled from "styled-components";
import {ChevronExpandIcon} from "../../../../../assets/svg/chevron-expand.icon";

const DropDownChevronBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 12px;
    min-height: 17px;
    cursor: pointer;
    color: var(--ion-color-step-650, #595959);
`


export const DropDownChevronComponent: React.FC = () => {
    return (
        <DropDownChevronBox>
            <ChevronExpandIcon size={1.1}/>
        </DropDownChevronBox>
    );
}
