import React, {MouseEvent} from "react";
import {observer} from "mobx-react-lite";
import styled, {css} from "styled-components";
import {IonCheckbox} from "@ionic/react";

const CheckBox = styled(IonCheckbox)<{isDisplayedInTheHeader?: boolean;}>`
  --size: 22px;
  --border-width: 2px;
  --border-color: ${props => props.theme.colors.dark};
  --border-color-checked: ${props => props.theme.colors.primary};
    z-index: 0;
    && {
        --checkbox-background: transparent;
    }
  ${
          props => props.isDisplayedInTheHeader
                  ? css`
                      --checkbox-background: ${props => props.theme.colors.primaryContrast};
                      --checkbox-background-checked: ${props => props.theme.colors.primaryContrast};
                      --checkmark-color: ${props => props.theme.colors.primary};
                    `
                  : css`
                      --checkbox-background: ${props => props.theme.colors.primaryContrast};
                      --checkbox-background-checked: ${props => props.theme.colors.primary};
                      --checkmark-color: ${props => props.theme.colors.primaryContrast};
                    `
  }
   
`

export type CheckBoxLabelPlacement = 'start' | 'end' | 'fixed' | 'stacked';

interface CheckboxComponentProps {
    isDisplayedInTheHeader?: boolean;
    className?: string;
    checked: boolean;
    onChange?: (isChecked: boolean) => void;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
    disabled?: boolean;
    label?: string | React.ReactElement;
    labelPlacement?: CheckBoxLabelPlacement;
    isReadOnly?: boolean;
    mode?: "ios" | "md";
}
export const CheckboxComponent: React.FC<CheckboxComponentProps> = observer((props) => {
    const onClickHandler = (event: MouseEvent<HTMLElement>) => {
        if(props.isReadOnly) {
            event.preventDefault();
        }

        if(props.onClick) {
            props.onClick(event);
        }

    }

    return (

        <CheckBox mode={props.mode ?? "ios"}
                  isDisplayedInTheHeader={props.isDisplayedInTheHeader}
                  className={props.className}
                  checked={props.checked}
                  onIonChange={e => props.onChange && props.onChange(e.detail.checked)}
                  onClick={onClickHandler}
                  disabled={props.disabled}
                  labelPlacement={props.labelPlacement}>
            {props.label}

        </CheckBox>
    )
});
