import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

export const PersonWithShieldIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={2} sizeIsHeight {...props} viewBox="0 0 16 24" ratio={16 / 24}>
            <path
                d="M5.6 11.9229C6.44869 11.9229 7.26263 11.5858 7.86274 10.9857C8.46286 10.3856 8.8 9.57164 8.8 8.72295C8.8 7.87426 8.46286 7.06032 7.86274 6.46021C7.26263 5.86009 6.44869 5.52295 5.6 5.52295C4.75131 5.52295 3.93737 5.86009 3.33726 6.46021C2.73714 7.06032 2.4 7.87426 2.4 8.72295C2.4 9.57164 2.73714 10.3856 3.33726 10.9857C3.93737 11.5858 4.75131 11.9229 5.6 11.9229ZM4.4575 13.1229C1.995 13.1229 0 15.1179 0 17.5804C0 17.9904 0.3325 18.3229 0.7425 18.3229H10.4575C10.5025 18.3229 10.545 18.3179 10.59 18.3104C8.6825 16.9329 8.095 14.7854 8.0125 13.3054C7.61 13.1854 7.185 13.1229 6.745 13.1229H4.46H4.4575ZM12.1775 11.1654L9.1775 12.3654C8.95 12.4579 8.8 12.6779 8.8 12.9229C8.8 14.5054 9.4475 17.143 12.17 18.2779C12.3175 18.3404 12.485 18.3404 12.6325 18.2779C15.3525 17.143 16 14.5054 16 12.9229C16 12.6779 15.85 12.4579 15.6225 12.3654L12.6225 11.1654C12.48 11.1079 12.32 11.1079 12.1775 11.1654ZM14.785 13.3229C14.6875 14.5904 14.105 16.2404 12.4 17.0654V12.3704L14.785 13.3229Z"
                fill={props.color? props.color: "currentColor"}/>
        </SvgIcon>
    )
}