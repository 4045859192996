import React from "react";
import {observer} from "mobx-react";
import {
    HomePageHybridContentTopSectionComponent
} from "../../../content/home-page-hybrid-content-top-section.component";
import {
    HomePageHybridContentBottomSectionComponent
} from "../../../content/home-page-hybrid-content-bottom-section.component";
import {CallCenterPhoneComponent} from "../../../../../../components/call-center-phone/call-center-phone.component";
import {useServices} from "../../../../../../hooks/use-services.hook";
import styled from "styled-components";
import {BookingSearchComponent} from "../../../common/booking-search/booking-search.component";
import {
    HybridHomePageTabTitleComponent
} from "../../tab-title/hybrid-home-page-tab-title.component";

const BookingSearch = styled(BookingSearchComponent)`
    border: none;
`

export const CheckInTabContentComponent: React.FC = observer(() => {
    const services = useServices()
    return (
        <>
            <HomePageHybridContentTopSectionComponent>
                <HybridHomePageTabTitleComponent title={services.language.translate('Check-in online')}
                                                 subtitle={services.language.translate(`Let's find your booking`)}/>
                <BookingSearch retrieveBookingButtonText={services.language.translate('Start check-in')}
                               hideTitle={true}
                               onRetrieveBookingButtonClick={(searchParams) => searchParams.startCheckIn()}/>
            </HomePageHybridContentTopSectionComponent>
            <HomePageHybridContentBottomSectionComponent>
                <CallCenterPhoneComponent title={services.language.translate('Need help finding your booking?')}/>
            </HomePageHybridContentBottomSectionComponent>
        </>

    );
})