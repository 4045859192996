import React from "react";
import {useServices} from "../../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import styled from "styled-components";
import {CardBox} from "../../../../basic-components/card/card.box";
import {BookingContactFieldsComponent} from "../../contact/components/booking-contact-fields.component";
import {CheckboxComponent} from "../../../../basic-components/checkbox/checkbox.component";
import {AirlineCompanyName} from "../../../../global-constants";

const DescriptionBox = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: ${props => props.theme.fontSize.h2};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    
`

const BookingContactBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${props => props.theme.spacing.flexGap24};
    padding: calc(2.25 * ${props => props.theme.spacing.cardDefaultPadding});
    ${props => props.theme.containerMediaQuery.smallScreen}  {
        padding: ${props => props.theme.spacing.cardDefaultPadding};
    }
`

const DataPrivacyContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${props => props.theme.colors.mediumShade};
    gap: 4px;
    font-size: ${props => props.theme.fontSize.subtitle2};
    ${props => props.theme.containerMediaQuery.xsAndBelow} {
        flex-direction: column;
    }
`

const DataPrivacyLink = styled.a`
    color: ${props => props.theme.colors.mediumShade};
    font-weight: ${props => props.theme.fontWeight.bold};
    font-size: inherit;
`

export const BookingContactComponent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;


    const isInHeadlessMode = booking.passengers.some(p => p.isPrimaryContact);



    return (
        <BookingContactBox>
            <DescriptionBox>
                {services.language.translate('Contact details')}
            </DescriptionBox>
            <BookingContactFieldsComponent isInHeadlessMode={isInHeadlessMode}/>
            <DataPrivacyContainerBox>
                <CheckboxComponent mode={"md"}
                                   checked={booking.contact.dataPrivacyAccepted} labelPlacement={"end"} onChange={(isChecked) => booking.contact.dataPrivacyAccepted = isChecked}
                                   label={services.language.translationFor('I would like to receive communications from {companyName}.').withParams({
                                       companyName: AirlineCompanyName
                                   })}/>
                <DataPrivacyLink href={services.externalLinks.privacyPolicyLink} target={"_blank"}>
                    {services.language.translate('Data Privacy')}
                </DataPrivacyLink>
            </DataPrivacyContainerBox>

        </BookingContactBox>
    );
});
