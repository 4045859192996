import React from 'react';
import {ISvgIconProps, SvgIcon} from './svg-icon.component';

export const CheckedIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.8} {...props} viewBox="0 0 28 28" ratio={1}>
            <g clipPath="url(#clip0_35694_46386)">
                <path
                    d="M14 28C17.713 28 21.274 26.525 23.8995 23.8995C26.525 21.274 28 17.713 28 14C28 10.287 26.525 6.72601 23.8995 4.10051C21.274 1.475 17.713 0 14 0C10.287 0 6.72601 1.475 4.10051 4.10051C1.475 6.72601 0 10.287 0 14C0 17.713 1.475 21.274 4.10051 23.8995C6.72601 26.525 10.287 28 14 28ZM20.1797 11.4297L13.1797 18.4297C12.6656 18.9438 11.8344 18.9438 11.3258 18.4297L7.82578 14.9297C7.31172 14.4156 7.31172 13.5844 7.82578 13.0758C8.33984 12.5672 9.17109 12.5617 9.67969 13.0758L12.25 15.6461L18.3203 9.57031C18.8344 9.05625 19.6656 9.05625 20.1742 9.57031C20.6828 10.0844 20.6883 10.9156 20.1742 11.4242L20.1797 11.4297Z"
                    fill="#22AD5C"/>
            </g>
            <defs>
                <clipPath id="clip0_35694_46386">
                    <rect width="28" height="28" fill="white"/>
                </clipPath>
            </defs>
        </SvgIcon>
    )
}
