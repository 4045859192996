import {IBookingViewModel} from "../../../../services/booking/models/booking-view-model.interface";
import {ISsrType} from "../../../../services/ssr-types/ssr-types.service.interface";
import {ExtrasSsrsSectionRenderer} from "./extras-ssrs-section.renderer";

export abstract class ExtrasSingleSsrSectionRenderer extends ExtrasSsrsSectionRenderer {
    constructor(ssrType: ISsrType,
                booking: IBookingViewModel) {
        super([ssrType], booking);
    }

    protected get ssrType(): ISsrType {
        return this.ssrTypes[0];
    }


    getTitle(): string {
        return this.ssrType.name;
    }

}
