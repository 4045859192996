import React from 'react';
import {observer} from "mobx-react-lite";
import {ScreenSizeBasedComponent} from "../../../../basic-components/layout/screen-size-based.component";
import {BookingFlowContinueButtonLargeScreenComponent} from "./booking-flow-continue-button.large-screen.component";
import {BookingFlowContinueButtonSmallScreenComponent} from "./booking-flow-continue-button.small-screen.component";
import {useServices} from "../../../../hooks/use-services.hook";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import {PromotionalApplyStatusEnum} from "../../../../services/booking/models/promotions/promotional-apply-status.enum";

interface BookingFlowContinueButtonComponentProps {
    modalHandler?: IDialogConfirmationHandler;
    isPromotionAllowed?: boolean;
    fullWidth?: boolean;
    inactive?: boolean;
    className?: string;
    customButtonText?: string;
}

export const BookingFlowContinueButtonComponent: React.FC<BookingFlowContinueButtonComponentProps> = observer((props) => {
    const services = useServices();
    const booking = services.booking.current;

    const goToNextStep = async () => {
        props.modalHandler?.accept();
        await services.booking.steps.nextStep();
    }

    const onClickHandler = async () => {
        let applyPromoCodeResult = PromotionalApplyStatusEnum.NotApplied;
        if(props.isPromotionAllowed) {
            applyPromoCodeResult = (await booking.promoCode.apply()).status;
        }

        if(applyPromoCodeResult !== PromotionalApplyStatusEnum.Failed) {
            await goToNextStep();
        }
    }
    return (
        <ScreenSizeBasedComponent renderForSmallScreen={() => (<BookingFlowContinueButtonSmallScreenComponent onClick={onClickHandler} fullWidth={props.fullWidth} inactive={props.inactive} className={props.className} customButtonText={props.customButtonText}/>)}
                                  renderForLargeScreen={() => (<BookingFlowContinueButtonLargeScreenComponent onClick={onClickHandler} inactive={props.inactive} fullWidth={props.fullWidth} className={props.className} customButtonText={props.customButtonText}/>)}/>
    )
});
