import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {PassengerBagsHeaderComponent} from "./passenger-bags-header.component";
import {PassengerBagsSelectionComponent} from "./passenger-bags-selection.component";
import {CardBox} from "../../../../basic-components/card/card.box";
import {
    IBookingSsrsAggregatorViewModel
} from "../../../../services/booking/models/ssrs/aggregator/booking-ssrs-aggregator-view-model.interface";
import {
    IMaturePassengerViewModel
} from "../../../../services/booking/models/passenger/mature-passenger-view-model.interface";
import {useServices} from "../../../../hooks/use-services.hook";
import {IJourneyViewModel} from "../../../../services/booking/models/journey/journey-view-model.interface";
import {PassengerBagsSummaryComponent} from "./passenger-bags-summary.component";
import {reaction} from "mobx";

export const PassengerBagsBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
`


interface PassengerBagsComponentProps {
    passenger: IMaturePassengerViewModel;
    bagsAggregator: IBookingSsrsAggregatorViewModel;
}

export const PassengerBagsComponent: React.FC<PassengerBagsComponentProps> = observer((props) => {
    const services = useServices();
    const journeys = services.booking.current.filteredJourneys;
    const [currentJourney, setCurrentJourney] = useState(journeys[0])
    const [isExpanded, setIsExpanded] = useState(true);

    useEffect(() => {
        const r = reaction(() => props.bagsAggregator.useSameOptionsForAllFlights,
            (sameForAllFlights) => {
                if(sameForAllFlights) {
                    setCurrentJourney(journeys[0]);
                }
            })
        return () => r();
    }, [journeys, props.bagsAggregator.useSameOptionsForAllFlights]);

    useEffect(() => {
        const r = reaction(() => journeys.map(j => j.allBags.validationsActivationTime), () => {
            for(let journey of journeys) {
                if(journey.allBags.hasErrors) {
                    setCurrentJourney(journey);
                    return;
                }
            }
        })

        return () => r();
    }, [journeys]);

    if(!currentJourney) {
        return null;
    }

    const passengerBags = currentJourney.allBags.passengersBags.find(pb => pb.passenger.passengerKey === props.passenger.passengerKey);
    if(!passengerBags) {
        return null;
    }

    const onJourneyTabClickHandler = (journeyKey: IJourneyViewModel) => {
        setCurrentJourney(journeyKey);
    }


    const renderSelectionView = () => {
        return (
            <PassengerBagsSelectionComponent passengerBags={passengerBags} />
        );
    }

    const renderSummaryView = () => {

        const allowClick = passengerBags.passenger.passengerIndex === 0 || !props.bagsAggregator.useSameOptionsForAllPassengers;
        const summaryOnClick = () => {
            if(allowClick) {
                setIsExpanded(true);
            }
        }
        return (
            <PassengerBagsSummaryComponent passengerBags={passengerBags} onClick={summaryOnClick} allowClick={allowClick}/>
        )
    }

    const renderBagsSelectionComponent = () => {
        if(props.passenger.passengerIndex === 0) {
            if(isExpanded) {
                return renderSelectionView();
            } else {
                return renderSummaryView()
            }

        }

        if(props.bagsAggregator.useSameOptionsForAllPassengers || !isExpanded) {
            return renderSummaryView()
        } else {
            return renderSelectionView();
        }
    }

    return (
        <PassengerBagsBox>
            <PassengerBagsHeaderComponent passenger={props.passenger}
                                          journeys={journeys}
                                          ssrsAggregator={props.bagsAggregator}
                                          currentJourney={currentJourney}
                                          showChevron={props.passenger.passengerIndex === 0 || !props.bagsAggregator.useSameOptionsForAllPassengers}
                                          isExpanded={isExpanded}
                                          onJourneyTabClick={onJourneyTabClickHandler}
                                          onExpandClick={() => {setIsExpanded(!isExpanded)}}/>
            {renderBagsSelectionComponent()}
        </PassengerBagsBox>
    );

});
