import React from 'react';
import {observer} from "mobx-react";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import {useServices} from "../../hooks/use-services.hook";
import {ContinueButtonComponent} from "../continue-button/continue-button.component";
import styled from "styled-components";
import {StandardDialogPageComponent} from "../modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../modal/content/standard-dialog-content.component";
import {PsoUserOptionComponent} from "./pso-user-option.component";
import {
    IFlightSearchControllerViewModel,
} from "../../services/flight-search/flight-search-controller/flight-search-controller-view-model.interface";
import {PsoUserOptionsEnum} from "../../services/flight-search/flight-search-controller/pso.enums";

const DialogContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${props => props.theme.spacing.flexGap20};
    font-size: ${props => props.theme.fontSize.h3};
    height: 280px;
    width: 100%;
`


const SubtitleBox = styled.div`
    width: 100%;
`

const OptionsBox = styled.div`
    display: flex;
    flex-direction: column;
    
`

interface PsoUserOptionWithSeasonDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
    searchController: IFlightSearchControllerViewModel;
}

export const PsoUserOptionWithSeasonDialogComponent: React.FC<PsoUserOptionWithSeasonDialogComponentProps> = observer((props) => {
    const services = useServices();
    const searchController = props.searchController;
    const psoMarket = searchController.getPsoMarket();

    if(!psoMarket) {
        return null;
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler}
                                           renderTitleOnTheFirstRow={true}
                                           title={services.language.translate('Resident and equivalent')}>

            </StandardDialogHeaderComponent>
            <StandardDialogContentComponent dialogHandler={props.dialogHandler}>
                <DialogContentBox>
                    <SubtitleBox>
                        {services.language.translate('Check if any of the below apply to you.')}
                    </SubtitleBox>
                    <OptionsBox>
                        <PsoUserOptionComponent optionValue={PsoUserOptionsEnum.Resident} searchController={searchController}/>
                        <PsoUserOptionComponent optionValue={PsoUserOptionsEnum.Worker} searchController={searchController}/>
                    </OptionsBox>

                    <ContinueButtonComponent fullWidth={true}
                                             onClick={() => props.dialogHandler.accept(searchController.psoUserOption)}/>
                </DialogContentBox>

            </StandardDialogContentComponent>

        </StandardDialogPageComponent>
    )
});
