import React from "react";
import {
    IPassengerAllBagsViewModel
} from "../../../../../../services/booking/models/ssrs/bags/passenger-all-bags-view-model.interface";
import {observer} from "mobx-react";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {BagContainerComponent} from "../../bag-container/bag-container-component";
import styled from "styled-components";
import {
    YesNoDialogPrimaryButton,
    YesNoDialogResult
} from "../../../../../../services/dialog-factory/yes-no-dialog.enums";
import {ReadonlyCheckboxComponent} from "../../../../../../basic-components/checkbox/readonly-checkbox.component";
import {BanIcon} from "../../../../../../assets/svg/ban.icon";


const  NoCheckInBagsContainerBox = styled(BagContainerComponent)`
    justify-content: flex-end;
    text-align: center;
    font-size: ${props => props.theme.fontSize.body1};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    
`


const CheckBox = styled(ReadonlyCheckboxComponent)`
    justify-self: flex-end;
`

const BanIconWrapperBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
`


export const NoCheckInBagsComponent: React.FC<{passengerBags: IPassengerAllBagsViewModel}> = observer((props) => {
    const services = useServices();


    if(!services.booking.current.shouldValidateBags) {
        return null;
    }

    const hasIncludedBags = props.passengerBags.checkInBags.some(b => b.quantityIncludedInBundle > 0);


    if(hasIncludedBags) {
        return null;
    }

    if(props.passengerBags.checkInBags.some(cib => cib.minAllowedQuantity > 0)) {
        return null;
    }

    const isClickable = props.passengerBags.checkInBags.some(b => b.canBeModifiedForAtLeastOneSegment);

    const hasSomeBags = props.passengerBags.checkInBags.some(b => b.newQuantity > 0);

    const isSelected = props.passengerBags.skipCheckInBags && !hasSomeBags;

    const onClick = async () => {

        props.passengerBags.skipCheckInBags = true;


        if(hasSomeBags && isClickable) {
            if(YesNoDialogResult.Yes === await services.dialogFactory.showYesNoDialog({
                title: services.language.translate('Confirmation'),
                message: services.language.translationFor('Are you sure you want to remove all checked-in bags for passenger {passengerName}?').withParams({
                    passengerName: props.passengerBags.passenger.getFullName()
                }),
                primaryButton: YesNoDialogPrimaryButton.PrimaryButtonNo
            })) {
                props.passengerBags.checkInBags.forEach(b => b.newQuantity = 0);
            }
        }
    }

    return (
        <NoCheckInBagsContainerBox isSelected={isSelected} onClick={onClick} className={'no-checkin-bags'} isClickable={isClickable}>
            <BanIconWrapperBox>
                <BanIcon/>
            </BanIconWrapperBox>

            <span>
                {services.language.translate("None")}
            </span>

            <CheckBox checked={isSelected}/>

        </NoCheckInBagsContainerBox>
    )
});
