import {ExtrasSectionRendererBase} from "../../base-renderers/extras-section-renderer.base";
import {ISsrType} from "../../../../../services/ssr-types/ssr-types.service.interface";
import {IRenderLargeTileOptions} from "../../base-renderers/extras-section-renderer.interface";
import React from "react";
import {
    IBookingSsrsAggregatorViewModel
} from "../../../../../services/booking/models/ssrs/aggregator/booking-ssrs-aggregator-view-model.interface";
import {ExtrasBagsLargeTileComponent} from "./large-tile/extras-bags.large-tile.component";
import {ExtrasBagsSmallTileComponent} from "./small-tile/extras-bags.small-tile.component";
import {BagSelectionDialogComponent} from "../../../bags-selection/bag-selection-dialog.component";
import {NullableString} from "../../../../../types/nullable-types";

export class BagsExtrasSectionRenderer extends ExtrasSectionRendererBase {

    getTitle(): string {
        return this.services.language.translate('Bags');
    }

    get ssrTypes(): ISsrType[] {
        return this.ssrsAggregator.ssrTypes;
    }

    shouldRender(): boolean {
        return this.booking.shouldRenderBagsInExtras;
    }

    get canBeModified(): boolean {
        return this.booking.bagsSsrsAggregator.canBeModified;
    }

    get isFulfilled(): boolean {
        return this.booking.bagsSsrsAggregator.journeysSsrsBuckets.all(j => j.getAllSsrsInTheBucket().all(ssr => ssr.passengersSsrEditors.all(pse => pse.newQuantity > 0)))
    }

    protected get ssrsAggregator(): IBookingSsrsAggregatorViewModel {
        return this.booking.bagsSsrsAggregator;
    }

    private async _showEditDialog(): Promise<void> {
        await this.services.dialog.showStandardDialog({
            width: '1024px',
            maxHeight: '97%',
            render: dialogHandler => {
                return (<BagSelectionDialogComponent dialogHandler={dialogHandler} ssrsAggregator={this.ssrsAggregator}/>)
            }
        })
    }

    private _countSelectedBags = () => {
        return this.ssrsAggregator.totalSsrsQuantity;
    }

    private _getMarketingMessage(): string {
        return this.services.language.translate(`All the space you need when you know you'll need it. Booking early is always cheaper than at the airport.`);
    }

    renderLargeTile(options: IRenderLargeTileOptions): React.ReactElement {
        return (
            <ExtrasBagsLargeTileComponent isFirstTile={options.isFirstTile}
                                          imageUrl={this._getExtrasLargeTilePicture()}
                                          ssrsAggregator={this.ssrsAggregator}
                                          getMarketingMessage={() => this._getMarketingMessage()}
                                          onEditButtonClick={() => this._showEditDialog()}
                                          countSelectedSsrs={() => this._countSelectedBags()} />
        )
    }

    renderSmallTile(): React.ReactElement {
        return (
            <ExtrasBagsSmallTileComponent ssrsAggregator={this.ssrsAggregator}
                                          onEditButtonClick={() => this._showEditDialog()}
                                          countSelectedSsrs={() => this._countSelectedBags()} />
        );
    }

    protected _getExtrasLargeTilePicture(): NullableString {
        return this.services.theme.currentTheme.assets.images.extras.bags_2x_jpg.toString();
    }



}