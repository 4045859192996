import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {BookingFlowPageHeaderLargeScreenComponent} from "./booking-flow-page-header.large-screen.component";
import styled from "styled-components";
import {BookingFlowPageProps} from "../booking-flow-page.props";
import {RoutedPageComponent} from "../../../basic-components/page/routed-page/routed-page.component";
import {StandardPageContentComponent} from "../../../basic-components/page/standard-page-content/standard-page-content.component";
import {BookingFlowContinueButtonComponent} from "../common-components/continue-button/booking-flow-continue-button.component";
import {StandardPageFooterComponent} from "../../../basic-components/page/standard-page-footer/standard-page-footer.component";
import {BookingFlowLargeScreenPageLeftSectionComponent} from "./booking-flow.large-screen..page.left-section.component";
import {
    BookingFlowLargeScreenPageRightSectionComponent
} from "./booking-flow.large-screen.page.right-section.component";
import {MediaQueryBuilder} from "../../../services/media-query/media-query-builder";
import {CSS_VAR_SHOPPING_CART_WIDTH_FOR_LARGE_SCREEN} from "../booking-flow-css-constants";

const PageRootComponent = styled(RoutedPageComponent)`
    ${CSS_VAR_SHOPPING_CART_WIDTH_FOR_LARGE_SCREEN}: 380px;
    
    @media ${MediaQueryBuilder.For.minWidth(1600).build()} {
        ${CSS_VAR_SHOPPING_CART_WIDTH_FOR_LARGE_SCREEN}: 460px;
    }
    
`

const StandardPageContentWrapper = styled(StandardPageContentComponent)`
    &::part(scroll){
        min-height: 100%;
    }
`

const BookingPageFooterComponent = styled(StandardPageFooterComponent)`
    background-color: ${props => props.theme.colors.successVeryLight};
`

const PageContentGridBox = styled.div`
    display: grid;
    grid-template-columns: 1fr var(${CSS_VAR_SHOPPING_CART_WIDTH_FOR_LARGE_SCREEN});
    width: 100%;
    min-height: 100%;
    column-gap: ${props => props.theme.spacing.pageContentHorizontalPadding};
`

const PageContentGridWithoutShoppingCartBox = styled(PageContentGridBox)`
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
`


const ContinueButtonWrapperBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`


export const BookingFlowLargeScreenPage: React.FC<BookingFlowPageProps> = observer((props) => {
    const services = useServices();

    const leftSectionMaxWidth = props.contentOptions.maxContentWidth ?? "1280px";




    const renderFooter = () => {
        if(props.footerOptions?.renderExtraFooterContent) {
            return (
                <BookingPageFooterComponent className={props.footerOptions?.pageFooterClassName}>
                    {props.footerOptions.renderExtraFooterContent()}
                </BookingPageFooterComponent>
            )
        }

        if(props.shoppingCartOptions.hideShoppingCart && !props.shoppingCartOptions.hideContinueButton) {
            return (
                <ContinueButtonWrapperBox>
                    <BookingFlowContinueButtonComponent fullWidth={false}/>
                </ContinueButtonWrapperBox>
            );
        }

        if(services.device.isHybrid) {
            //because on some devices like iPad pro we need to preserver safe area on the bottom
            return (
                <StandardPageFooterComponent className={props.footerOptions?.pageFooterClassName}/>
            )
        }

        return null;
    }

    const renderPageContent = () => {
        if(props.shoppingCartOptions.hideShoppingCart) {
            return (
                <PageContentGridWithoutShoppingCartBox>
                    <BookingFlowLargeScreenPageLeftSectionComponent title={props.headerOptions.title}
                                                                    renderContent={props.contentOptions.renderContent}
                                                                    maxPageContentWidth={leftSectionMaxWidth}
                                                                    hideCopyRight={props.contentOptions?.hideCopyRight}/>
                </PageContentGridWithoutShoppingCartBox>
            );

        }

        return (
            <PageContentGridBox>
                <BookingFlowLargeScreenPageLeftSectionComponent title={props.headerOptions.title}
                                                                renderContent={props.contentOptions.renderContent}
                                                                maxPageContentWidth={leftSectionMaxWidth}
                                                                hideCopyRight={props.contentOptions?.hideCopyRight}/>
                <BookingFlowLargeScreenPageRightSectionComponent showPromotionCode={props.shoppingCartOptions.showPromotionCode}
                                                                 hideShoppingCart={props.shoppingCartOptions.hideShoppingCart}
                                                                 hideContinueButton={props.shoppingCartOptions.hideContinueButton}
                                                                 continueButtonText={props.shoppingCartOptions.continueButtonText}
                                                                 replaceShoppingCart={props.shoppingCartOptions.replaceShoppingCart}
                                                                 replaceContinueButton={props.shoppingCartOptions.replaceContinueButton}/>
            </PageContentGridBox>
        );

    }

    return (
        <PageRootComponent disableSwipeForwardNavigation={props.disableSwipeForwardNavigation}
                           disableSwipeNavigation={props.disableSwipeNavigation}
                           className={props.className}>
            <BookingFlowPageHeaderLargeScreenComponent dontAskForConfirmationOnLogoClick={props.headerOptions.dontAskForConfirmationOnLogoClick}
                                                       hideWizardProgressIndicator={props.headerOptions.hideWizardProgressIndicator}
                                                       renderTopMiddleSection={props.headerOptions.renderHeaderTopMiddleSectionForLargeScreen}/>
            <StandardPageContentWrapper reducePaddingTopOnScroll={props.contentOptions.reduceTopPaddingOnPageScroll}
                                        onScroll={props.contentOptions.onContentScroll}
                                        className={props.contentOptions.pageContentClassName}>
                {renderPageContent()}
            </StandardPageContentWrapper>
            {renderFooter()}

        </PageRootComponent>
    );
});
