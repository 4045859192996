import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {PaypalLogoSmallV2Icon} from "../../../../assets/svg/paypal-logo-small-v2.icon";

const HeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap8};
`

export const PaypalPaymentMethodTabHeaderComponent: React.FC = observer(() => {
    return (
        <HeaderBox>
            <PaypalLogoSmallV2Icon/>
            <span>
                Paypal
            </span>
        </HeaderBox>
    );
})