import {PassengerSegmentSsrEditorModel} from "./passenger-segment-ssr-editor.model";
import {computed, makeObservable, runInAction} from "mobx";
import {MaturePassengerModel} from "../passenger/mature-passenger.model";
import {ISsrType} from "../../../ssr-types/ssr-types.service.interface";
import {IPassengerSsrEditorViewModel} from "./passenger-ssr-editor-view-model.interface";
import {NullablePrice, Price} from "../../../currency/price";
import {IJourneySsrsBucket} from "./journey-ssrs-bucket.interface";
import {ISeatViewModel} from "../seat-maps/seat-view-model.interface";
import {IJourneyViewModel} from "../journey/journey-view-model.interface";
import {NullableString} from "../../../../types/nullable-types";
import {SsrModel} from "./ssr.model";
import {ValidationResultEnum} from "../../../../types/validation-result.enum";
import {BookingModel} from "../booking.model";


/**
 * Encapsulate the logic for selling a specific SSR for a passenger on a specific journey
 */
export class PassengerJourneySsrEditorModel implements IPassengerSsrEditorViewModel {
    constructor(private readonly passenger: MaturePassengerModel,
                private readonly ssrModel: SsrModel) {
        makeObservable(this, {
            allowUseSameOptionsForAllFlights: computed,
            canBeModifiedForAtLeastOneSegment: computed,
            modificationBlockingReason: computed,
            isCompletelyIncludedInBundle: computed,
            discountedMinPrice: computed,
            standardMinPrice: computed,
            passengerSegmentSsrs: computed,
            quantityIncludedInBundle: computed,
            minAllowedQuantity: computed,
            availableQuantity: computed,
            limitPerPassenger: computed,
            passengerSegmentsSsrsForAllFlights: computed,
            totalPrice: computed,
            totalPriceOnCurrentSession: computed
        });
    }

    get totalPrice(): Price {
        return Price.sumAll(this.passengerSegmentSsrs.map(ps => ps.totalPrice), this.booking.createPrice(0));
    }

    get totalPriceOnCurrentSession(): Price {
        return Price.sumAll(this.passengerSegmentSsrs.map(ps => ps.totalPriceOnCurrentSession), this.booking.createPrice(0));
    }

    get ssrType(): ISsrType {
        return this.ssrModel.ssrType;
    }

    private get booking(): BookingModel {
        return this.ssrsBucket.parentJourney.booking;
    }

    private get ssrsBucket(): IJourneySsrsBucket {
        return this.ssrModel.ssrsBucket;
    }

    get passengerKey(): string {
        return this.passenger.passengerKey;
    }

    get passengerFullName(): string {
        return this.passenger.getFullName();
    }

    get passengerIndex(): number {
        return this.passenger.passengerIndex;
    }

    get parentJourney(): IJourneyViewModel {
        return this.ssrsBucket.parentJourney;
    }
    /**
     * Returns the seats that are preventing this SSR to be sold
     */
    getRestrictedSeats(): ISeatViewModel[] {

        let seats: ISeatViewModel[] = [];

        this.passengerSegmentSsrs.forEach(passengerSegmentSsr => {
            seats = [...seats, ...passengerSegmentSsr.getRestrictedSeats()]
        });

        if(this.ssrsBucket.useSameOptionsForAllFlights) {
            this.ssrsBucket.getOtherJourneysBuckets().forEach(journeyBucket => {
                journeyBucket.getAllSsrsInTheBucket().forEach(ssr => {
                    ssr.passengersSsrEditors.forEach(passengerSsrEditor => {
                        passengerSsrEditor.passengerSegmentSsrs.forEach(ps => {
                            seats = [...seats, ...ps.getRestrictedSeats()]
                        })
                    });
                });
            });
        }


        return seats;
    }

    get allowUseSameOptionsForAllFlights(): boolean {
        const passengerSegmentsSsrEditors = this.passengerSegmentsSsrsForAllFlights;
        const hasTheSameMinQuantityOnAllSegments = (1 === passengerSegmentsSsrEditors.distinct(ps => ps.minAllowedQuantity.toString()).length);
        const hasTheSameModificationStatusOnAllSegments = (1 === passengerSegmentsSsrEditors.distinct(ps => ps.canBeModified.toString()).length);
        //const hasTheSameAvailabilityOnAllSegments = (1 === passengerSegmentsSsrEditors.distinct(ps => (ps.maxAllowedQuantity - ps.currentQuantity).toString()).length);


        return hasTheSameMinQuantityOnAllSegments && hasTheSameModificationStatusOnAllSegments; // && hasTheSameAvailabilityOnAllSegments;
    }

    get hasTheSameQuantityOnAllFlights(): boolean {
        return 1 === this.passengerSegmentsSsrsForAllFlights.distinct(ps => ps.newQuantity.toString(), ps => ps.newQuantity).length
    }

    get canBeModifiedForAtLeastOneSegment(): boolean {
        return this.passengerSegmentSsrs.some(ps => ps.canBeModified);
    }


    get modificationBlockingReason(): NullableString {
        if(this.canBeModifiedForAtLeastOneSegment) {
            return null;
        }
        return this.passengerSegmentSsrs[0]?.modificationBlockingReason || null;
    }

    get isCompletelyIncludedInBundle(): boolean {
        return this.passengerSegmentSsrs.all(p => p.isCompletelyIncludedInBundle);
    }

    get passengerSegmentsSsrsForAllFlights(): PassengerSegmentSsrEditorModel[] {
        return [
            ...this.passengerSegmentSsrs,
            ...this.ssrsBucket.getOtherJourneysBuckets().selectMany(bucket => bucket.getAllSsrsInTheBucket()
                                                                                    .filter(ssr => ssr.ssrType.ssrCode === this.ssrType.ssrCode)
                                                                                    .selectMany(ssr => ssr.passengersSsrEditors.filter(p => p.passengerKey === this.passengerKey))
                                                                                    .selectMany(p => p.passengerSegmentSsrs))
        ];
    }


    private get feeCode(): NullableString {
        return this.passengerSegmentSsrs[0].ssrAvailability.feeCode
    }


    get passengerSegmentSsrs(): PassengerSegmentSsrEditorModel[] {
        return this.ssrsBucket.parentJourney.segments.selectMany(segment => segment.passengers.filter(p => p.passengerKey === this.passengerKey))
                                                     .map(p => p.getSsr(this.ssrType));
    }


    private getPriceFromPassengerSegmentSsr(mapFunction: (segmentSsrEditor: PassengerSegmentSsrEditorModel) => Price): NullablePrice {
        const prices = this.passengerSegmentSsrs.filter(p => p.hasAvailability)
                                                        .map(mapFunction);

        if(prices.length === 0) {
            return null;
        }

        const feeCode = this.feeCode;
        if(!feeCode) {
            return prices[0];
        }

        const feeConfiguration = this.booking.services.ssrTypes.getFeeConfiguration(feeCode);
        if(!feeConfiguration) {
            return prices[0];
        }

        if(feeConfiguration.isFeeChargedPerSegment) {
            return Price.sumAll(prices, this.booking.createPrice(0));
        }

        return prices[0];
    }

    get discountedMinPrice(): NullablePrice {

        return this.getPriceFromPassengerSegmentSsr(p => p.discountedMinPrice);

    }

    get standardMinPrice(): NullablePrice {
        return this.getPriceFromPassengerSegmentSsr(p => p.standardMinPrice);
    }

    get quantityIncludedInBundleToDisplay(): number {
        return this.passengerSegmentSsrs[0].quantityIncludedInBundleToDisplay;
    }

    get quantityIncludedInBundle(): number {
        return this.passengerSegmentSsrs[0].quantityIncludedInBundle;
    }

    get minAllowedQuantity(): number {
        return this.passengerSegmentSsrs.max(p => p.minAllowedQuantity);
    }

    get maxAllowedQuantity(): number {
        return this.passengerSegmentSsrs.min(p => p.maxAllowedQuantity);
    }

    get maxAllowedQuantityReachedMessage(): NullableString {
        return this.passengerSegmentSsrs[0].maxAllowedQuantityReachedMessage;
    }

    get limitPerPassenger(): number {
        return this.passengerSegmentSsrs.min(p => p.limitPerPassenger);
    }

    get availableQuantity(): number {
        return this.passengerSegmentSsrs.min(p => p.availableQuantity);
    }


    get newQuantityWasSet(): boolean {
        return this.passengerSegmentSsrs.all(ps => ps.newQuantityWasSet);
    }

    get newQuantityToDisplay(): number {
        return this.passengerSegmentSsrs[0].newQuantityToDisplay;
    }

    get newQuantity(): number {
        return this.passengerSegmentSsrs[0].newQuantity;
    }

    get quantityPurchasedOnCurrentSession(): number {
        return this.passengerSegmentSsrs[0].quantityPurchasedOnCurrentSession;
    }

    applyNewQuantity(value: number): ValidationResultEnum {

        const diff = value - this.newQuantity;
        const isIncreased = diff > 0;

        if(!isIncreased && value < this.minAllowedQuantity) {
            return ValidationResultEnum.Failure;
        }



        //if the value is increased we need to make sure this quantity doesn't exceed the available quantity
        if(isIncreased) {
            if(value > this.limitPerPassenger || diff > this.availableQuantity) {
                return ValidationResultEnum.Failure;
            }
        }


        runInAction(() => {
            this.passengerSegmentSsrs.forEach(s => s.newQuantity = value);
        });

        return ValidationResultEnum.Success;
    }

    set newQuantity(value: number) {

        if(this.applyNewQuantity(value) !== ValidationResultEnum.Success) {
           return;
        }


        this._applySameQuantityToAllPassengers(value);
        this._applySameQuantityToAllFlightsForCurrentPassenger(value);

        this._resetOtherBucketsUseSameFor();

        this.booking.sellSsrs();
    }

    private _applySameQuantityToAllPassengers(value: number): void {
        if(!this.ssrsBucket.useSameOptionsForAllPassengers) {
            return;
        }

        this.ssrModel.passengersSsrEditors.forEach(p => {
            if(p.passengerKey !== this.passengerKey) {
                p.applyNewQuantity(value);
            }
        });

        if(this.ssrsBucket.useSameOptionsForAllFlights) {
            this.ssrsBucket.getOtherJourneysBuckets().forEach(bucket => {
                bucket.getAllSsrsInTheBucket()
                    .filter(ssr => ssr.ssrType.ssrCode === this.ssrType.ssrCode)
                    .forEach(ssr => {
                        ssr.passengersSsrEditors.forEach(p => p.applyNewQuantity(value));
                    });
            });
        }
    }

    private _applySameQuantityToAllFlightsForCurrentPassenger(value: number): void {
        if(this.ssrsBucket.useSameOptionsForAllFlights && !this.ssrsBucket.useSameOptionsForAllPassengers) {
            this.ssrsBucket.getOtherJourneysBuckets().forEach(bucket => {
                bucket.getAllSsrsInTheBucket().filter(ssr => ssr.ssrType.ssrCode === this.ssrType.ssrCode)
                    .forEach(ssr => {
                        ssr.passengersSsrEditors.find(p => p.passengerKey === this.passengerKey)?.applyNewQuantity(value);
                    });
            });
        }

    }

    private _resetOtherBucketsUseSameFor(): void {

        for(let otherBucket of this.ssrsBucket.getOtherBucketsContainingSsrType(this.ssrType)) {
            if(!this.ssrsBucket.useSameOptionsForAllFlights) {
                otherBucket.useSameOptionsForAllFlights = false;
            }

            if(!this.ssrsBucket.useSameOptionsForAllPassengers) {
                otherBucket.useSameOptionsForAllPassengers = false;
            }
        }
    }
}
