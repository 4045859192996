import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {BagsCategoryTitleBox} from "../../../boxes/bags-category-title.box";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {BagsStepIndicationBox} from "../../../boxes/bags-step-indication.box";
import {
    IPassengerAllBagsViewModel
} from "../../../../../../services/booking/models/ssrs/bags/passenger-all-bags-view-model.interface";


const CheckInBagsInfoContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${props => props.theme.containerMediaQuery.smallScreen} {
        align-items: flex-start;
        flex-direction: column;
        gap: ${props => props.theme.spacing.spacing8};
    }
`

const PromoMessageContainerBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    ${props => props.theme.containerMediaQuery.smallScreen} {
        width: 100%;
    }
`

const TitleContainerBox = styled.div`
    display: flex;
    flex-direction: column;
`

const PromoMessageBox = styled.div`
    background-color: ${props => props.theme.colors.darkTint};
    color: ${props => props.theme.colors.darkContrast};
    text-align: center;
    padding: ${props => props.theme.spacing.spacing8} ${props => props.theme.spacing.spacing16};
    border-radius: ${props => props.theme.border.defaultRadius};
    font-size: ${props => props.theme.fontSize.caption};
    font-weight: ${props => props.theme.fontSize.h6};
    ${props => props.theme.containerMediaQuery.smallScreen} {
        width: 100%;
    }
`

export const CheckInBagsInfoComponent: React.FC<{passengerBags: IPassengerAllBagsViewModel}> = observer((props) => {
    const services = useServices();
    const hasError = Boolean(props.passengerBags.checkInBagsErrorMessage);
    const renderStepIndicator = () => {

        return (
            <BagsStepIndicationBox $hasError={hasError}>
                {services.language.translate('Then select:')}
            </BagsStepIndicationBox>
        );


    }

    const renderPromoMessage = () => {
        if(props.passengerBags.checkInBags.all(b => Boolean(b.modificationBlockingReason))) {
            return null;
        }

        return (
            <PromoMessageContainerBox>
                <PromoMessageBox>
                    {services.language.translate('Best time to buy. Check-in bags at the airport cost 60% more!')}
                </PromoMessageBox>
            </PromoMessageContainerBox>
        );
    }

    return (
        <CheckInBagsInfoContainerBox>
            <TitleContainerBox>
                {renderStepIndicator()}
                <BagsCategoryTitleBox $hasError={hasError}>
                    {services.language.translate('Checked-in baggage')}
                </BagsCategoryTitleBox>
            </TitleContainerBox>

            {renderPromoMessage()}

        </CheckInBagsInfoContainerBox>
    )
})