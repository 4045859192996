import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';

//fill={props.color? props.color: "currentColor"}
export const ChevronUpIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.6} {...props} viewBox="0 0 26 15" ratio={26 / 15}>
            <path
                d="M12.6425 1.23822C13.0135 0.867207 13.6239 0.867207 13.9949 1.23822L25.4844 12.7277C25.8554 13.0987 25.8554 13.7091 25.4844 14.0801C25.1134 14.4511 24.503 14.4511 24.132 14.0801L13.3187 3.26684L2.50543 14.0801C2.13442 14.4512 1.52404 14.4512 1.15302 14.0801C0.782007 13.7091 0.782007 13.0987 1.15302 12.7277L12.6425 1.23822Z"
                fill="#9D9FA6"/>

        </SvgIcon>
    )
}
