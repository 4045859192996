import React from "react";
import {observer} from "mobx-react-lite";
import {useServices} from "../../../hooks/use-services.hook";
import {BookingFlowSmallScreenPage} from "../screen-small/booking-flow.small-screen.page";
import styled from "styled-components";
import {CardBox} from "../../../basic-components/card/card.box";
import {InsuranceBenefitsComponent} from "./components/insurance-benefits.component";
import {InsuranceSelectionComponent} from "./components/insurance-selection.component";
import {InsuranceRemovalComponent} from "./components/insurance-removal.component";
import {InsuranceDisclaimerComponent} from "./components/insurance-disclaimer.component";
import {ErrorMessageTextBoxComponent} from "../../../components/error-message/error-message-text-box.component";

const PolicyTitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.h4};
    font-weight: ${props => props.theme.fontWeight.bold};
    text-align: center;
`

const InsuranceCardBox = styled(CardBox)`
    padding: ${props => props.theme.spacing.spacing12};
    margin-top: ${props => props.theme.spacing.spacing12};
    
`

const InsurancePageContentBox = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${props => props.theme.fontSize.h6};
`

const PricePerPassengerDescriptionBox = styled.div`
    font-size: ${props => props.theme.fontSize.h5};
    
`

const HorizontalLine = styled.div`
    height: 1px;
    margin: ${props => props.theme.spacing.spacing12} ${props => props.theme.spacing.spacing16} ${props => props.theme.spacing.spacing16};
    background-color: ${props => props.theme.colors.mediumTint};
`

const LogoTrustpilotBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 0 12px;
`

export const InsurancePageContentComponent: React.FC = observer(() => {
    const services = useServices();
    const insurance = services.booking.current.insurance;



    return (
        <InsurancePageContentBox>
            <InsuranceCardBox>
                <PolicyTitleBox>
                    {insurance.quote.policyTitle}
                </PolicyTitleBox>
                <LogoTrustpilotBox>
                    <img src={insurance.quote.trustPilotLogoUrl} alt={"Trust pilot logo"}/>
                </LogoTrustpilotBox>
                <PricePerPassengerDescriptionBox>
                    {insurance.quote.pricesPerPassengersDescription}
                </PricePerPassengerDescriptionBox>

                <InsuranceBenefitsComponent insurance={insurance}/>
                <div ref={(element) => insurance.attachValidatedDOMElement(element)}>
                    <ErrorMessageTextBoxComponent errorMessage={insurance.validationError}/>
                    <InsuranceSelectionComponent insurance={insurance}/>
                    <InsuranceRemovalComponent insurance={insurance}/>
                </div>

                <HorizontalLine/>
                <InsuranceDisclaimerComponent insurance={insurance}/>
            </InsuranceCardBox>

        </InsurancePageContentBox>
    );

});

export const InsurancePage: React.FC = observer(() => {
    const services = useServices();

    return (
        <BookingFlowSmallScreenPage headerOptions={{
                                        title: services.language.translate('Travel insurance')
                                    }}
                                    contentOptions={{
                                        renderContent: () => <InsurancePageContentComponent/>
                                    }}
                                    shoppingCartOptions={{
                                        showPromotionCode: false
                                    }}>

        </BookingFlowSmallScreenPage>
    );
});
