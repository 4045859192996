import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {CardBox} from "../../../basic-components/card/card.box";
import {useServices} from "../../../hooks/use-services.hook";
import {Price} from "../../../services/currency/price";
import {PriceComponent} from "../../../components/price/price.component";

const ContainerBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    padding: 0;
`

const HeaderBox = styled.div`
    background-color: ${props => props.theme.colors.primaryShade};
    color: ${props => props.theme.colors.light};
    width: 100%;
    padding: ${props => props.theme.spacing.spacing16} ${props => props.theme.spacing.cardDefaultPadding};
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

const TotalsContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${props => props.theme.spacing.spacing16} ${props => props.theme.spacing.cardDefaultPadding};
`

const AmountLabelBox = styled.div`
    flex-grow: 1;
    font-size: ${props => props.theme.fontSize.subtitle2};
    font-weight: ${props => props.theme.fontWeight.medium};
`

const AmountValueBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    color: ${props => props.theme.colors.primaryShade};
`

const AmountBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.border.mainColor};
    padding: ${props => props.theme.spacing.spacing16} 0;
    &:first-of-type {
        padding-top: 0;
    }
    
    &:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
    }
`
const AmountComponent: React.FC<{label: string; amount: Price}> = (props) => {
    return (
        <AmountBox>
            <AmountLabelBox>
                {props.label}
            </AmountLabelBox>
            <AmountValueBox>
                <PriceComponent price={props.amount}/>
            </AmountValueBox>
        </AmountBox>
    )
}


export const FinalPriceSummaryComponent: React.FC<{className?: string}> = observer((props) => {
    const services = useServices();
    const booking = services.booking.current;
    return (
        <ContainerBox className={props.className}>
            <HeaderBox>
                {services.language.translate('Final Price')}
            </HeaderBox>
            <TotalsContainerBox>
                <AmountComponent label={services.language.translate("Flight total")} amount={booking.shoppingCart.computedTotalToDisplay}/>
                <AmountComponent label={services.language.translate("Balance due")} amount={booking.shoppingCart.balanceDue}/>
            </TotalsContainerBox>
        </ContainerBox>
    )
})