import React from "react";
import {observer} from "mobx-react";
import {
    SearchMaskWebappSmallScreenComponent
} from "../../../components/search-mask/webapp/small-screen/search-mask.webapp.small-screen.component";
import styled from "styled-components";
import {HomePageGreetingMessageComponent} from "./greeting-message/home-page-greeting-message.component";
import {RoutedPageComponent} from "../../../basic-components/page/routed-page/routed-page.component";
import {HomePageStandardContentWebappComponent} from "./boxes/home-page-content-wrapper.webapp.box";
import {CopyRightComponent} from "../../../components/airline-company-info/copy-right.component";
import {
    StandardPageHeaderSmallScreenComponent
} from "../../../basic-components/page/standard-page-header/standard-page-header.small-screen.component";


const HomePageContentWrapperWebappBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;
    padding-top: ${props => props.theme.spacing.spacing48};
`

const GreetingAndSearchMaskContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
    gap: ${props => props.theme.spacing.spacing16};
`


const GreetingMessage = styled(HomePageGreetingMessageComponent)`
    width: 100%;
    text-align: center;
`


export const HomepageWebappSmallScreenComponent: React.FC = observer(() => {

    return (
        <RoutedPageComponent disableSwipeNavigation={true}>
            <StandardPageHeaderSmallScreenComponent hideBackButton={false} useLargeLogoOnSmallScreen={true}/>
            <HomePageStandardContentWebappComponent>
                <HomePageContentWrapperWebappBox>
                    <GreetingAndSearchMaskContainerBox>
                        <GreetingMessage/>
                        <SearchMaskWebappSmallScreenComponent/>
                    </GreetingAndSearchMaskContainerBox>
                    <CopyRightComponent/>
                </HomePageContentWrapperWebappBox>
            </HomePageStandardContentWebappComponent>
        </RoutedPageComponent>
    );


})