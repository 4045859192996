import React from "react";
import {
    IPassengerSsrEditorViewModel
} from "../../../../../../services/booking/models/ssrs/passenger-ssr-editor-view-model.interface";
import {observer} from "mobx-react";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {CabinBagsDisclaimerForAtrAircraftComponent} from "../atr-disclaimer/cabin-bags-disclaimer-for-atr-aircraft.component";
import styled from "styled-components";
import {BagsCategoryTitleBox} from "../../../boxes/bags-category-title.box";
import {CabinBagMustChooseAnOptionComponent} from "../must-choose-an-option/cabin-bag-must-choose-an-option.component";
import {useContainerMediaQueriesChecks} from "../../../../../../hooks/use-container-media-queries-checks.hook";
import {BagsStepIndicationBox} from "../../../boxes/bags-step-indication.box";

const CabinBagsInfoContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: ${props => props.theme.spacing.spacing8};
`

const DisclaimerBox = styled.div`
    flex-grow: 1;
`

export const CabinBagsInfoComponent: React.FC<{ssrEditor: IPassengerSsrEditorViewModel; hasError: boolean}> = observer((props) => {
    const services = useServices();
    const mediaQuery = useContainerMediaQueriesChecks();
    const isInSmallScreen = mediaQuery.smallScreen;
    const renderMustChooseAnOption = () => {
        if(isInSmallScreen) {
            return null;
        }

        return (
            <CabinBagMustChooseAnOptionComponent ssrEditor={props.ssrEditor} hasError={props.hasError}/>
        );
    }

    const renderStepIndicator = () => {

        return (
            <BagsStepIndicationBox $hasError={props.hasError}>
                {services.language.translate('First select:')}
            </BagsStepIndicationBox>
        );
    }

    return (
        <CabinBagsInfoContainerBox>
            {renderStepIndicator()}
            <BagsCategoryTitleBox $hasError={props.hasError}>
                {services.language.translate('Cabin baggage')}
            </BagsCategoryTitleBox>
            <DisclaimerBox>
                <CabinBagsDisclaimerForAtrAircraftComponent/>
            </DisclaimerBox>

            {renderMustChooseAnOption()}
        </CabinBagsInfoContainerBox>
    )
})