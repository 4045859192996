import React from "react";
import {observer} from "mobx-react";
import {DayCellBox} from "./boxes/day-cell.box";
import {UnavailableDayNumberComponent} from "./day-number.component";
import styled, {css} from "styled-components";
import {FlightsDatesSelectorModel} from "../../../../../services/flight-search/dates-selection/flights-dates-selector.model";

const DayContentBox = styled(DayCellBox)<{$isInsideSelection: boolean;}>`
    cursor: not-allowed;
    ${props => props.$isInsideSelection
            ? css`
                background-color: ${props => props.theme.colors.light};
            `
            : ''
    }
`



interface UnavailableDayComponentProps {
    date: Date;
    flightsDatesSelector: FlightsDatesSelectorModel;
}

export const UnavailableDayComponent: React.FC<UnavailableDayComponentProps> = observer((props) => {
    const isInsideSelection = props.flightsDatesSelector.isDateInsideSelection(props.date);
    return (
        <DayContentBox $isInsideSelection={isInsideSelection}>
            <UnavailableDayNumberComponent date={props.date}/>
        </DayContentBox>
    );
})