import React from 'react';
import { ISvgIconProps, SvgIcon } from './svg-icon.component';
//fill={props.color? props.color: "currentColor"}
export const ChevronDownIcon: React.FC<ISvgIconProps> = (props) => {
    return (
        <SvgIcon size={1.6} {...props} viewBox="0 0 26 15" ratio={26 / 15}>
            <path
                d="M12.6425 13.9112C13.0135 14.2822 13.6239 14.2822 13.9949 13.9112L25.4844 2.42169C25.8554 2.05068 25.8554 1.4403 25.4844 1.06928C25.1134 0.698264 24.503 0.698264 24.132 1.06928L13.3187 11.8826L2.50543 1.06928C2.13442 0.698263 1.52404 0.698263 1.15302 1.06928C0.782008 1.44029 0.782008 2.05067 1.15302 2.42169L12.6425 13.9112Z"
                fill={props.color? props.color: "currentColor"}/>

        </SvgIcon>
    );
}
