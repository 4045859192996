import React from "react";
import {observer} from "mobx-react";
import {IPassengerViewModel} from "../../../../services/booking/models/passenger/passenger-view-model.interface";
import {StringFieldEditorComponent} from "../../../../components/forms/string-field/string-field-editor.component";
import {GenderFieldEditorComponent} from "../../../../components/forms/gender/gender-field-editor.component";
import {DateFieldEditorComponent} from "../../../../components/forms/date-time/date-field-editor.component";
import {useServices} from "../../../../hooks/use-services.hook";
import styled, {css} from "styled-components";
import {CountryPickerComponent} from "../../../../components/country-picker/country-picker.component";
import {TravelDocumentTypeFieldEditorComponent} from "../../../../components/forms/travel-document-type/travel-document-type-field-editor.component";
import {PassengerAvailableTravelDocumentsComponent} from "./passenger-available-travel-documents.component";
import {CardBox} from "../../../../basic-components/card/card.box";

const ContainerBox = styled(CardBox)<{$hasErrors: boolean}>`
    display: flex;
    flex-direction: row;
    ${props => props.$hasErrors 
                ? css`
                    padding-bottom: calc(2 * ${props => props.theme.spacing.cardDefaultPadding});
                `
                :''
    }
`


const PassengerNameBox = styled.div`
    font-size: ${props => props.theme.fontSize.h2};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`


const PassengerFieldsBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap20};
    width: 100%;
`

const FieldsGridBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${props => props.theme.spacing.flexGap20};
    ${props => props.theme.containerMediaQuery.smallScreen} {
        grid-template-columns: 1fr;
    }
    
`

interface PassengerTravelDocumentComponentProps {
    passenger: IPassengerViewModel;
}
export const PassengerTravelDocumentComponent: React.FC<PassengerTravelDocumentComponentProps> = observer((props) => {
    const services = useServices();
    const travelDocument = props.passenger.travelDocument;
    const fields = travelDocument.fields;
    const minimumBirthDate = props.passenger.computeMinimumBirthDate();
    const maximumBirthDate = props.passenger.computeMaximumBirthDate();
    let minimumTravelDocumentIssuedDate = fields.dateOfBirth.value;
    let maximumTravelDocumentIssuedDate = travelDocument.computeMaximumTravelDocumentIssuedDate();
    let minimumTravelDocumentExpiration = travelDocument.computeMinimumTravelDocumentExpirationDate();
    let maximumTravelDocumentExpiration = services.time.addYears(services.time.currentDate, 100);


    const renderPassengerFields = () => {
        return (
            <>
                <GenderFieldEditorComponent key={fields.gender.fieldName}
                                            field={fields.gender}
                                            useGenderAsNumber={false}/>
                <DateFieldEditorComponent key={fields.dateOfBirth.fieldName}
                                          field={fields.dateOfBirth}
                                          minimumValue={minimumBirthDate}
                                          maximumValue={maximumBirthDate}/>
            </>
        );
    }

    return (
        <ContainerBox $hasErrors={travelDocument.hasErrors()}>
            <PassengerFieldsBox>
                <PassengerNameBox>
                    {props.passenger.getFullName()}
                </PassengerNameBox>
                <FieldsGridBox>
                    {renderPassengerFields()}
                    <TravelDocumentTypeFieldEditorComponent key={fields.documentTypeCode.fieldName}
                                                            field={fields.documentTypeCode}/>
                    <StringFieldEditorComponent key={fields.number.fieldName}
                                                field={fields.number}/>
                    <CountryPickerComponent key={fields.issuedByCode.fieldName}
                                            field={fields.issuedByCode}/>
                    <DateFieldEditorComponent key={fields.issuedDate.fieldName}
                                              field={fields.issuedDate}
                                              minimumValue={minimumTravelDocumentIssuedDate}
                                              maximumValue={maximumTravelDocumentIssuedDate}/>
                    <DateFieldEditorComponent key={fields.expirationDate.fieldName}
                                              field={fields.expirationDate}
                                              minimumValue={minimumTravelDocumentExpiration}
                                              maximumValue={maximumTravelDocumentExpiration}/>
                    <CountryPickerComponent key={fields.nationality.fieldName}
                                            field={fields.nationality}
                                            popupHeaderTitle={services.language.translate('Search citizenship')}/>
                </FieldsGridBox>

            </PassengerFieldsBox>
            <PassengerAvailableTravelDocumentsComponent passenger={props.passenger}/>
        </ContainerBox>
    );
});
