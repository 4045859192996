import React, {PropsWithChildren, TouchEvent, useRef} from "react";
import {PageBox} from "../page.box";
import {useServices} from "../../../hooks/use-services.hook";
import styled from "styled-components";
import {observer} from "mobx-react-lite";
import {
    SWIPER_SLIDE_ACTIVE_CSS_CLASS,
    SWIPER_SLIDE_CSS_CLASS,
    SWIPER_WRAPPER_CSS_CLASS
} from "../../swiper/swiper-consts";
import {useScreenMediaQueriesChecks} from "../../../hooks/use-screen-media-queries-checks.hook";
//import {createGesture, Gesture} from "@ionic/core";
//https://ionicframework.com/docs/utilities/gestures

const CSS_VAR_TRANSLATE_X = '--translate-x';

const RoutedPageBox = styled(PageBox)`
    
    ${CSS_VAR_TRANSLATE_X}: 0;
    transition: transform 0.3s ease-out;
    transform: translateX(var(${CSS_VAR_TRANSLATE_X}));
    
    
`

const SWIPE_DELTA_X = 100;

enum SwiperPosition {
    NoSwiper,
    SingleSlide,
    FirstSlide,
    MiddleSlide,
    LastSlide
}

enum SwipeNavigationDirection {
    None,
    Back,
    Forward
}

interface RoutedPageComponentProps extends PropsWithChildren {
    id?: string;
    className?: string;
    disableSwipeNavigation?: boolean;
    disableSwipeForwardNavigation?: boolean;
    swipeForwardHandler?: () => void;
}

export const RoutedPageComponent: React.FC<RoutedPageComponentProps> = observer((props) => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();
    const touchStartX = useRef(0);
    const touchStartY = useRef(0);
    const contentRef = useRef<HTMLIonContentElement | null>(null);
    const swipeDirection = useRef(SwipeNavigationDirection.None);


    const {disableSwipeNavigation, disableSwipeForwardNavigation, swipeForwardHandler, ...otherProps} = props;

    const findSwiperWrapperElement = (event: TouchEvent<HTMLIonContentElement>) => {
        let el: HTMLElement | null = event.target as HTMLElement;

        while(el) {
            if(el.classList) {
                if(el.classList.contains(SWIPER_WRAPPER_CSS_CLASS)) {
                    return el;
                }
            }

            el = el.parentElement;
        }

        return null;
    }

    const findSwiperSlidePosition = (event: TouchEvent<HTMLIonContentElement>) => {
        const swiperWrapperElement = findSwiperWrapperElement(event);
        if(!swiperWrapperElement) {
            return SwiperPosition.NoSwiper;
        }

        const swiperSlides = swiperWrapperElement.querySelectorAll(`.${SWIPER_SLIDE_CSS_CLASS}`);
        if(swiperSlides.length <= 1) {
            return SwiperPosition.SingleSlide;
        }

        if(swiperSlides[0].classList.contains(SWIPER_SLIDE_ACTIVE_CSS_CLASS)) {
            return SwiperPosition.FirstSlide;
        }

        if(swiperSlides[swiperSlides.length - 1].classList.contains(SWIPER_SLIDE_ACTIVE_CSS_CLASS)) {
            return SwiperPosition.LastSlide;
        }

        return SwiperPosition.MiddleSlide;
    }

    const isSwipeNavigationDisabled = () => {

        if(disableSwipeNavigation) {
            return true;
        }
        if(!screenMediaQuery.smallScreen) {
            return true;
        }

        return services.dialog.hasOpenDialogs;

    };

    const allowSwipeBack = (e: TouchEvent<HTMLIonContentElement>) => {
        if(isSwipeNavigationDisabled()) {
            return false;
        }

        const swiperSlidePosition = findSwiperSlidePosition(e);

        return (swiperSlidePosition === SwiperPosition.NoSwiper
                || swiperSlidePosition === SwiperPosition.SingleSlide
                || swiperSlidePosition === SwiperPosition.FirstSlide);
    }

    const allowSwipeForward = (e: TouchEvent<HTMLIonContentElement>) => {
        if(isSwipeNavigationDisabled()) {
            return false;
        }

        const swiperSlidePosition = findSwiperSlidePosition(e);

        return (swiperSlidePosition === SwiperPosition.NoSwiper
            || swiperSlidePosition === SwiperPosition.SingleSlide
            || swiperSlidePosition === SwiperPosition.LastSlide);
    }

    const handleTouchStart = (e: TouchEvent<HTMLIonContentElement>) => {
        if(isSwipeNavigationDisabled()) {
            return;
        }
        touchStartX.current = e.touches[0].clientX;
        touchStartY.current = e.touches[0].clientY;
    };


    const handleTouchMove = (e: TouchEvent<HTMLIonContentElement>) => {

        if(!contentRef.current) {
            return;
        }


        const touchMoveX = e.touches[0].clientX;
        const touchMoveY = e.touches[0].clientY;
        const deltaX = touchMoveX - touchStartX.current;
        const deltaY = touchMoveY - touchStartY.current;

        if(Math.abs(deltaX) < 75 // in order to not start the translations on every little touch
            || Math.abs(deltaY) > 25) { // in order to not start the translations when the user actually scroll on vertical
            return;
        }

        if(deltaX > 0 && allowSwipeBack(e)) { // Swipe right, go back
            //const translateX = Math.min(Math.max(deltaX, -SWIPE_DELTA_X), SWIPE_DELTA_X);
            const translateX = Math.min(deltaX, SWIPE_DELTA_X); // Limit translation for better visual feedback
            contentRef.current.style.setProperty(CSS_VAR_TRANSLATE_X, `${translateX}px`);
            swipeDirection.current = SwipeNavigationDirection.Back;
        } else if(deltaX < 0 && allowSwipeForward(e)){ // Swipe left, go forward
            const translateX = Math.max(deltaX, -SWIPE_DELTA_X); // Limit translation for better visual feedback
            contentRef.current.style.setProperty(CSS_VAR_TRANSLATE_X, `${translateX}px`);
            swipeDirection.current = SwipeNavigationDirection.Forward;
        }

    };

    const resetTranslation = () => {
        // Reset the translation after the animation
        if(contentRef.current) {
            contentRef.current.style.setProperty('--translate-x', '0');
        }

        swipeDirection.current = SwipeNavigationDirection.None;
    }

    const handleTouchEnd = (e: TouchEvent<HTMLIonContentElement>) => {

        const touchEndX = e.changedTouches[0].clientX;
        const deltaX = touchEndX - touchStartX.current;

        if (deltaX > SWIPE_DELTA_X && swipeDirection.current === SwipeNavigationDirection.Back) { // Swipe right, go back
            services.navigator.goBack();
        } else if (!disableSwipeForwardNavigation && deltaX < -SWIPE_DELTA_X && swipeDirection.current === SwipeNavigationDirection.Forward) { // Swipe left, go forward
            if(swipeForwardHandler) {
                swipeForwardHandler();
            } else {
                services.navigator.goForward();
            }
        }

        resetTranslation();
    };

    return (
        <RoutedPageBox {...otherProps}
                 ref={contentRef}
                 onTouchStart={handleTouchStart}
                 onTouchMove={handleTouchMove}
                 onTouchEnd={handleTouchEnd}>

        </RoutedPageBox>
    );
})
