import React from "react";
import {observer} from "mobx-react";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import {useServices} from "../../../../hooks/use-services.hook";
import {ISegmentViewModel} from "../../../../services/booking/models/segment/segment-view-model.interface";
import {DesignatorComponent, DesignatorDisplayMode} from "../../../../components/designator/designator.component";
import {CheckedIcon} from "../../../../assets/svg/checked.icon";
import styled from "styled-components";
import { CardBox } from "../../../../basic-components/card/card.box";
import {
    ICheckInRestriction,
    ICheckInRestrictionAction
} from "../../../../services/booking/models/check-in-restrictions/check-in-restriction.interface";
import {OnlineCheckInValidationResultEnum} from "../../../../services/dialog-factory/dialog-factory.service.interface";
import {StandardDialogPageComponent} from "../../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../../components/modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../../../../components/modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../../../../components/modal/footer/standard-dialog-footer.component";
import {PrimaryButton} from "../../../../basic-components/buttons/primary-button";
import {ExclamationDiamondIcon} from "../../../../assets/svg/exclamation-diamond.icon";

const PassengerInformationBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const PassengerNameBox = styled.div`
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.bold};
    
`

const RestrictionsContainerBox = styled.ul`
    display: flex;
    flex-direction: column;
    padding-left: ${props => props.theme.spacing.spacing16};
    padding-top: ${props => props.theme.spacing.spacing8};
    margin: 0;
    width: 100%;
    list-style-type: none;
    gap: ${props => props.theme.spacing.flexGap8};
    
   
`

const RestrictionActionBox = styled.li`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap8};
    font-size: ${props => props.theme.fontSize.h6};
    margin: 0;
    padding: 0;
    &::before {
        content: "•";           
        position: absolute;     
        left: calc(-1 * ${props => props.theme.spacing.spacing16});
        color: black;
        font-size: ${props => props.theme.fontSize.body1};
    }
`

const OnlineCheckInSegmentValidationBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${props => props.theme.spacing.spacing16};
    width: 100%;
`

const OnlineCheckInPassengersValidationBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${props => props.theme.spacing.spacing16};
    width: 100%;
`


const OnlineCheckInPassengerValidationBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: ${props => props.theme.spacing.spacing12};
    width: 100%;
`

const EligibilityStatusBox = styled.div`
    color: ${props => props.theme.colors.notes};
    font-size: ${props => props.theme.fontSize.subtitle2};
`

interface OnlineCheckInPassengerValidationComponentProps {
    passengerFullName: string;
    restrictions: ICheckInRestriction[];
    onBeforeRestrictionActionClick: () => void;
}

const OnlineCheckInPassengerValidationComponent: React.FC<OnlineCheckInPassengerValidationComponentProps> = observer((props) => {
    const services = useServices();
    const isRestrictedForOnlineCheckIn = props.restrictions.length > 0;
    const renderIcon = () => {
        if(isRestrictedForOnlineCheckIn) {
            return (<ExclamationDiamondIcon color={services.theme.currentTheme.colors.danger} size={1.5}/>);
        } else {
            return (<CheckedIcon size={1.5}/>);
        }
    }

    const renderCheckInEligibilityStatus = () => {
        let text;
        if(isRestrictedForOnlineCheckIn) {
            text = services.language.translate('Not eligible for online check-in');
        } else {
            text = services.language.translate('Is eligible for online check-in');
        }

        return (
            <EligibilityStatusBox>
                {text}
            </EligibilityStatusBox>
        )
    }

    const renderRestrictionAction = (action: ICheckInRestrictionAction | undefined) => {
        if(!action) {
            return null;
        }

        const onRestrictionActionClick = async () => {
            props.onBeforeRestrictionActionClick();
            await action.execute();
        }

        return (
            <PrimaryButton onClick={onRestrictionActionClick}>
                {action.description}
            </PrimaryButton>
        )
    }

    const renderRestrictions = () => {
        if(!isRestrictedForOnlineCheckIn) {
            return null;
        }

        return (
            <RestrictionsContainerBox>
                {props.restrictions.map((restriction) => {
                    return (
                        <RestrictionActionBox key={restriction.description}>
                            <span>
                                {restriction.description}
                            </span>
                            {renderRestrictionAction(restriction.action)}
                        </RestrictionActionBox>
                    );
                })}
            </RestrictionsContainerBox>
        )
    }

    return (
        <OnlineCheckInPassengerValidationBox>
            <div>
                {renderIcon()}
            </div>

            <PassengerInformationBox>
                <PassengerNameBox>
                    {props.passengerFullName}
                </PassengerNameBox>
                {renderCheckInEligibilityStatus()}
                {renderRestrictions()}
            </PassengerInformationBox>
        </OnlineCheckInPassengerValidationBox>
    );
});


const OnlineCheckInSegmentValidationComponent: React.FC<{segment: ISegmentViewModel; onBeforeRestrictionActionClick: () => void;}> = observer((props) => {
    const eligiblePassengersForOnlineCheckIn = props.segment.passengers.filter(passengerSegment => passengerSegment.isValidForOnlineCheckIn);
    const restrictedPassengersForOnlineCheckIn = props.segment.passengers.filter(passengerSegment => !passengerSegment.isValidForOnlineCheckIn);

    return (
        <OnlineCheckInSegmentValidationBox>
            <DesignatorComponent displayMode={DesignatorDisplayMode.stationsNamesAndDate} designator={props.segment.designator}/>
            <OnlineCheckInPassengersValidationBox>
                {eligiblePassengersForOnlineCheckIn.map((passengerSegment) => {
                    return(
                        <OnlineCheckInPassengerValidationComponent key={passengerSegment.passengerKey}
                                                                   passengerFullName={passengerSegment.passengerFullName}
                                                                   restrictions={passengerSegment.getAllOnlineCheckInRestrictions()}
                                                                   onBeforeRestrictionActionClick={props.onBeforeRestrictionActionClick}/>
                    );
                })}
                {restrictedPassengersForOnlineCheckIn.map((passengerSegment) => {
                    return (
                        <OnlineCheckInPassengerValidationComponent key={passengerSegment.passengerKey}
                                                                   passengerFullName={passengerSegment.passengerFullName}
                                                                   restrictions={passengerSegment.getAllOnlineCheckInRestrictions()}
                                                                   onBeforeRestrictionActionClick={props.onBeforeRestrictionActionClick}/>
                    );
                })}
            </OnlineCheckInPassengersValidationBox>

        </OnlineCheckInSegmentValidationBox>
    )
});


export const OnlinePreCheckInRestrictionsComponent: React.FC<{segment: ISegmentViewModel; onBeforeRestrictionActionClick: () => void;}> = observer((props) => {
    const restrictedPassengersForOnlineCheckIn = props.segment.passengers.filter(passengerSegment => passengerSegment.getPreCheckInRestrictions().length > 0);
    if(restrictedPassengersForOnlineCheckIn.length === 0) {
        return null;
    }
    return (
        <OnlineCheckInSegmentValidationBox>
            <DesignatorComponent displayMode={DesignatorDisplayMode.stationsNamesAndDate} designator={props.segment.designator}/>
            {restrictedPassengersForOnlineCheckIn.map(passengerSegment => <OnlineCheckInPassengerValidationComponent
                key={passengerSegment.passengerKey}
                passengerFullName={passengerSegment.passengerFullName}
                restrictions={passengerSegment.getPreCheckInRestrictions()}
                onBeforeRestrictionActionClick={props.onBeforeRestrictionActionClick}/>)}

        </OnlineCheckInSegmentValidationBox>
    )
});


const SegmentsContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.spacing24};
`

interface IncompletePassengersDetailsDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
}

export const OnlineCheckInValidationDialogComponent: React.FC<IncompletePassengersDetailsDialogComponentProps> = observer((props) => {
    const services = useServices();
    const booking = services.booking.current;
    const segments = booking.getAllSegments();

    const renderContinueButton = () => {
        const passengersSegments = booking.getAllPassengersSegments();
        if(passengersSegments.all(p => !p.isValidForOnlineCheckIn)) { // if all passengers have online check-in restriction
            if(passengersSegments.all(p => p.getAllOnlineCheckInRestrictions().all(p => !p.shouldBlockSavingTravelDocuments))) {
                //if none of the restrictions are because of document validation
                return (
                    <PrimaryButton onClick={() => props.dialogHandler.accept(OnlineCheckInValidationResultEnum.OnlySaveDocuments)}>
                        {services.language.translate(`Just save my travel documents`)}
                    </PrimaryButton>
                )
            }
            //if all passengers segments are restricted for online check-in
            return (
                <PrimaryButton onClick={() => props.dialogHandler.accept(OnlineCheckInValidationResultEnum.GoToHomePage)}>
                    {services.language.translate(`I will do the check-in later`)}
                </PrimaryButton>
            )
        }

        return (
            <PrimaryButton onClick={() => props.dialogHandler.accept(OnlineCheckInValidationResultEnum.ContinueWithEligiblePassengers)}>
                {services.language.translate(`Continue with passengers eligible for check-in`)}
            </PrimaryButton>
        )
    }

    const onBeforeRestrictionActionClick = () => {
        props.dialogHandler.reject();
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate('Online check-in validation')}
                                           dialogHandler={props.dialogHandler}/>


            <StandardDialogContentComponent dialogHandler={props.dialogHandler}>
                <SegmentsContainerBox>
                    {segments.map(segment => (<OnlineCheckInSegmentValidationComponent key={segment.segmentKey}
                                                                                                         segment={segment}
                                                                                                         onBeforeRestrictionActionClick={onBeforeRestrictionActionClick}/>))}
                </SegmentsContainerBox>

            </StandardDialogContentComponent>


            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                {renderContinueButton()}
            </StandardDialogFooterComponent>
        </StandardDialogPageComponent>
    )
});
