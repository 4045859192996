import React from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {IBlueBenefitsPackageToPurchaseViewModel} from "../../../../services/booking/models/blue-benefits/blue-benefits-package-to-purchase-view-model.interface";
import {BlueBenefitsPackagePresentationComponent} from "../../../blue-benefits/components/packages-info/blue-benefits-package-presentation.component";
import {CheckboxComponent} from "../../../../basic-components/checkbox/checkbox.component";

const PackageToPurchaseBox = styled.div<{$isSelected: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 8px;
    ${
        props => props.$isSelected 
                ? css`
                  border: 2px Solid ${props => props.theme.colors.success};
                `
                : css`
                  border: 1px Solid rgba(${props => props.theme.colors.mediumRgb}, 0.75);
                `
    }
`

const SelectedCheckBox = styled(CheckboxComponent)`
    margin-right: ${props => props.theme.spacing.spacing8};
`

export const BlueBenefitsPackageToPurchaseComponent: React.FC<{packageToPurchase: IBlueBenefitsPackageToPurchaseViewModel}> = observer((props) => {
    const onPurchase = async () => {
        await props.packageToPurchase.purchase();
    }

    return (
      <PackageToPurchaseBox $isSelected={props.packageToPurchase.isSelected} onClick={onPurchase}>
          <BlueBenefitsPackagePresentationComponent bbPackage={props.packageToPurchase.blueBenefitsPackage} displayPackagePrice={true}/>
          <SelectedCheckBox checked={props.packageToPurchase.isSelected}/>
      </PackageToPurchaseBox>
    );
});
