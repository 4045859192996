import React from "react";

//fill={props.color? props.color: "currentColor"}
export const PlaneWingLeftSvg: React.FC<{color?: string;}> = (props) => {
    return (
        <svg width="1568" height="1075" viewBox="0 0 1568 1075" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M162.26 759.506C189.227 745.355 1098.87 284.481 1098.87 284.481C1098.87 284.481 1239.5 214.5 1324.95 169.966C1410.41 125.432 1521.67 38.8108 1567.19 0V645.842H1139.77C1020.16 679.156 949.567 698.858 847.301 727.358L139.305 925.767C74.4017 945.1 56.9885 958.234 44.3244 972.943C34.1932 984.682 10.5533 1045.37 0 1074.23V1027.83C0.366698 1019.36 0.616109 1011.57 0.849101 1004.3L0.85377 1004.15L0.853873 1004.15C2.12798 964.331 2.9347 939.119 19.7878 894.057C39.7428 840.703 109.429 787.229 162.26 759.506Z"
                fill={props.color? props.color: "currentColor"}/>
        </svg>


    )
}
