import {IBeginPaymentStrategyOption, IPaymentStrategy} from "./payment-strategy.interface";
import {IPaymentMethodModel} from "../payment-methods/payment-method.model.interface";
import {IPaymentStrategyBeginPaymentResponse} from "./payment-strategy-begin-payment-response";
import {DialogResult} from "../../../../dialog/dialog-enums";
import {IPaymentStatusResponse} from "../../../../airline-webapi/responses/payment-status.response";
import {ILoadPaymentMethodsOptions} from "../payment-handler-view-model.interface";
import {CreditCardPaymentModel} from "../payment-methods/card/credit-card-payment.model";
import {PaymentMethodFactory} from "../payment-methods/payment-method-factory";
import {BookingModel} from "../../booking.model";
import {IServiceFactory} from "../../../../service-factory.interface";
import {IBookingPaymentMethodsResponse} from "../../../../airline-webapi/responses/booking-payment-methods.response";
import {BookingSessionStorageKeys} from "../../storage/booking-storage.interface";
import {computed, makeObservable, observable, runInAction} from "mobx";
import {IBeginBookingPaymentResponse} from "../../../../airline-webapi/responses/begin-booking-payment.response";
import {IBeginBookingPaymentRequest} from "../../../../airline-webapi/requests/begin-booking-payment.request";
import {MobileWalletPaymentMethodModel} from "../payment-methods/mobile-wallet/mobile-wallet-payment-method.model";
import {ICompletePspOrderResponse} from "../../../../airline-webapi/responses/complete-psp-order.response";

export class WebapiPaymentStrategy implements IPaymentStrategy {
    constructor(private readonly booking: BookingModel) {
        this._paymentMethodsResponse = this._paymentMethodsResponse = this.booking.storage.getJson<IBookingPaymentMethodsResponse>(BookingSessionStorageKeys.bookingPaymentMethods);

        makeObservable(this, {
            _paymentMethodsResponse: observable.ref,
            allPaymentMethods: computed,
            creditCardPaymentMethods: computed,
            mobileWalletPaymentMethods: computed,
        });
    }


    readonly supportsCreditCardCollection: boolean = false;

    _paymentMethodsResponse: IBookingPaymentMethodsResponse | null = null;

    private get services(): IServiceFactory {
        return this.booking.services;
    }

    private get _pendingPaymentDetails(): IBeginBookingPaymentResponse | null {
        return this.booking.storage.getJson<IBeginBookingPaymentResponse>(BookingSessionStorageKeys.pendingPaymentDetails) ?? null;
    }

    private set _pendingPaymentDetails(value: IBeginBookingPaymentResponse | null) {
        if(value) {
            this.booking.storage.setJson(BookingSessionStorageKeys.pendingPaymentDetails, value);
        } else {
            this.booking.storage.removeItem(BookingSessionStorageKeys.pendingPaymentDetails);
        }

    }

    get isRedirectInProgress(): boolean {
        return Boolean(this._pendingPaymentDetails?.redirectUrl);
    }

    async loadPaymentMethods(options: ILoadPaymentMethodsOptions): Promise<void> {
        let paymentMethodsResponse: IBookingPaymentMethodsResponse;
        if(options.showLoadingIndicator) {
            paymentMethodsResponse = await this.services.loadingIndicator.execute({
                action: async () => {
                    return await this.services.airlineWebapi.getBookingPaymentMethods({
                        dotRezToken: this.booking.token
                    });
                }
            })
        } else {
            paymentMethodsResponse = await this.services.loadingIndicator.execute({
                action: async () => {
                    return await this.services.airlineWebapi.getBookingPaymentMethods({
                        dotRezToken: this.booking.token
                    });
                }
            })
        }

        runInAction(() => {
            this._paymentMethodsResponse = paymentMethodsResponse;
            this.booking.storage.setJson(BookingSessionStorageKeys.bookingPaymentMethods, this._paymentMethodsResponse);
        })
    }

    get allPaymentMethods(): IPaymentMethodModel[] {
        if(!this._paymentMethodsResponse) {
            return [];
        }

        const result: IPaymentMethodModel[] = [];

        this._paymentMethodsResponse.paymentMethods.forEach(pm => {
            const paymentMethodModel = PaymentMethodFactory.createPaymentMethod(this.booking, pm.code, pm.availableAmountInBookingCurrency);
            if(paymentMethodModel?.isSupportedOnCurrentPlatform) {
                result.push(paymentMethodModel);
            }
        })

        return result.sort((p1, p2) => p1.sortOrder - p2.sortOrder);
    }
    get creditCardPaymentMethods(): IPaymentMethodModel[] {
        return this.allPaymentMethods.filter(pm => pm instanceof CreditCardPaymentModel);
    }

    get mobileWalletPaymentMethods(): IPaymentMethodModel[] {
        return this.allPaymentMethods.filter(pm => pm instanceof MobileWalletPaymentMethodModel);
    }

    async beginPayment(options: IBeginPaymentStrategyOption): Promise<IPaymentStrategyBeginPaymentResponse> {
        let beginPaymentRequest: IBeginBookingPaymentRequest = {
            dotRezToken: this.booking.token,
            bookingTimestamp: this.booking.bookingTimestamp,
            attempt: options.paymentAttempt,
            paymentMethodCode: options.selectedMethods[0].code,
            culture: this.services.language.currentLanguage,
            callbacks: this.booking.bookingStrategyAdapter.getPaymentCallbacks(),
            vouchers: this.booking.voucher.getAppliedVoucherForBeginPayment(),
            bookingFlow: this.booking.bookingStrategyAdapter.bookingFlowType,
            agentName: this.booking.bookingStrategyAdapter.agentName
        };



        if(this.booking.invoice.invoiceRequested) {
            beginPaymentRequest = await this.booking.invoice.onBeginPayment(beginPaymentRequest);
        }

        const response = await this.services.airlineWebapi.beginBookingPayment(beginPaymentRequest);


        if(response.redirectUrl || response.pspOrderId) {
            this._pendingPaymentDetails = response
        } else {
            this._pendingPaymentDetails = null;
        }

        return {
            status: response.status,
            redirectUrl: response.redirectUrl,
            apiErrorCode: response.apiErrorCode,
            pspOrderId: response.pspOrderId,
            pspErrorDetails: response.pspErrorDetails
        };

    }

    async collectCreditCardDetails(): Promise<DialogResult> {
        return DialogResult.Accepted;
    }

    async getPaymentStatus(): Promise<IPaymentStatusResponse> {
        if (!this._pendingPaymentDetails) {
            throw new Error('getPaymentStatus called before beginPayment in webapi strategy');
        }

        const response = await this.services.airlineWebapi.getBookingPaymentStatus({
            paymentToken: this._pendingPaymentDetails.paymentToken
        });

        return {
            status: response.status,
            apiErrorCode: response.apiErrorCode,
            pspErrorDetails: response.pspErrorDetails
        };

    }


    onPaymentFinalized(): void {
        this._pendingPaymentDetails = null;
    }

    async completePspOrder(orderId: string): Promise<ICompletePspOrderResponse> {
        if(!this._pendingPaymentDetails?.paymentToken) {
            throw new Error(`Cannot completePspOrder without a paymentToken`);
        }

        return await this.services.airlineWebapi.completePspOrder({
            pspOrderId: orderId,
            paymentToken: this._pendingPaymentDetails.paymentToken
        });
    }

    async cancelPspOrder(): Promise<void> {

        if(!this._pendingPaymentDetails?.paymentToken) {
            this.services.logger.error('Attempt to cancel a PSP order without a payment token');
            return;
        }

        if(!this._pendingPaymentDetails?.pspOrderId) {
            this.services.logger.error('Attempt to cancel a PSP order without a pspOrderId');
            return;
        }

        try {
            await this.services.airlineWebapi.cancelPspOrder({
                pspOrderId: this._pendingPaymentDetails.pspOrderId,
                paymentToken: this._pendingPaymentDetails.paymentToken
            });
        } catch (err) {
            this.services.logger.error("cancelPspOrder failed!", err);
        }

    }

}