import {AnalyticsBaseEvents} from "./analytics-base.events";
import {
    IAnalyticsPassengerDetailsEvents
} from "../../../booking/models/analytics/google-analytics.intefaces";
import {BookingModel} from "../../../booking/models/booking.model";

export class AnalyticsPassengerDetailsEvents extends AnalyticsBaseEvents implements IAnalyticsPassengerDetailsEvents {


    logBeginCheckout(booking: BookingModel): void {
        try {
            const data: unknown = {
                event: 'begin_checkout',
                ecommerce: {
                    currency: booking.currency,
                    value: booking.balanceDue.amount,
                    coupon: booking.promoCode.code.value ?? "",
                    customer_type: this._getCustomerTypeFromBooking(booking),
                    items: [
                        ...this._getBookingFaresAnalyticsItems(booking)
                    ]
                }
            }

            this.analyticsActionHandler.sendEvent(data)
        } catch (err) {
            this.services.logger.error('logBeginCheckout failed', err);
        }
    }

    logContactPresent(booking: BookingModel): void {
        try {
            const data: unknown = {
                event: 'contact_present',
                compliance: {
                    'accept_1st_collection': booking.contact.dataPrivacyAccepted,
                    'accept_3rd_collection': booking.contact.dataPrivacyAccepted
                },
                email: booking.contact.fields.emailAddress.value
            }
            this.analyticsActionHandler.sendEvent(data)
        } catch (err) {
            this.services.logger.error('logContactPresent failed', err);
        }


    }

}