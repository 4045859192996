import React from "react";
import {useServices} from "../../hooks/use-services.hook";
import {IonIcon} from "@ionic/react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {PriceComponent} from "../price/price.component";
import {ShoppingCartDialogComponent} from "./shopping-cart-dialog.component";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";
import { IDialogConfirmationHandler } from "../../services/dialog/dialog.service.interface";
import {ShoppingCartNotificationsComponent} from "./shopping-cart-notifications.component";
import {IonSpinnerComponent} from "../spinner/ion-spinner.component";


const ShoppingCartButtonContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    cursor: pointer;
`

const PriceButtonBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 4px;
    padding: 0;
    height: auto;
    font-size: ${props => props.theme.fontSize.h2};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

const ArrowIconBox = styled(IonIcon)`
    width: 20px;
    height: 20px;
    font-size: 18px;
`


interface ShoppingCartButtonModalOptions {
    hideContinueButton?: boolean;
    continueButtonText?: string;
}

interface ShoppingCartButtonComponentProps {
    modalHandler?: IDialogConfirmationHandler;
    modalOptions: ShoppingCartButtonModalOptions;
}

export const ShoppingCartButtonComponent: React.FC<ShoppingCartButtonComponentProps> = observer((props) => {
    const services = useServices();
    const booking = services.booking.current;

    const openShoppingCartDialog = async () => {
        if(booking.mutationsManager.isBookingTotalUpdateInProgress){
            return;
        }

        if(props.modalHandler){
            props.modalHandler.reject();
        }else{
            await services.dialog.showStandardDialog({
                render: (modalHandler) => <ShoppingCartDialogComponent dialogHandler={modalHandler}
                                                                                                   hideContinueButton={props.modalOptions.hideContinueButton}
                                                                                                   continueButtonText={props.modalOptions.continueButtonText}/>
            });
        }
    }

    const renderArrow = () => {

        if(booking.mutationsManager.isBookingTotalUpdateInProgress) {
            return (<IonSpinnerComponent/>);
        }


        return props.modalHandler
            ? <ArrowIconBox icon={chevronDownOutline}/>
            : <ArrowIconBox icon={chevronUpOutline}/>
    }


    return (
        <ShoppingCartButtonContainerBox>
            <ShoppingCartNotificationsComponent/>
            <PriceButtonBox onClick={openShoppingCartDialog}>
                {renderArrow()}
                <PriceComponent price={booking.balanceDue}
                                label={services.language.translate('Total')}/>
            </PriceButtonBox>
        </ShoppingCartButtonContainerBox>
    );
});
