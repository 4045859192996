import React, {PropsWithChildren} from "react";
import {useServices} from "../../../hooks/use-services.hook";
import styled from "styled-components";
import {StandardPageHeaderSmallScreenComponent} from "../../../basic-components/page/standard-page-header/standard-page-header.small-screen.component";
import {WizardProgressIndicatorSmallScreenComponent} from "../../../components/wizard-progress-indicator/wizard-progress-indicator.small-screen.component";

const HeaderContentBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap16};
    width: 100%;
    
    
`
const WizardIndicatorBox = styled(WizardProgressIndicatorSmallScreenComponent)`
  padding-bottom: ${props => props.theme.spacing.spacing16};
`

interface FlightSearchPageHeaderComponentProps extends PropsWithChildren {
    title: string | React.ReactElement
}

export const FlightSearchPageHeaderComponent: React.FC<FlightSearchPageHeaderComponentProps> = (props) => {
    const services = useServices();
    return (
        <StandardPageHeaderSmallScreenComponent renderTopMiddleSection={() => props.title} addBottomPadding={true}>
            <HeaderContentBox>
                <WizardIndicatorBox getWizard={() => services.flightSearch.steps} />
                {props.children}
            </HeaderContentBox>

        </StandardPageHeaderSmallScreenComponent>
    )
}
