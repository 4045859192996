import React from "react";
import {observer} from "mobx-react";
import {NullableUndefinedString} from "../../../types/nullable-types";
import styled from "styled-components";

const FieldContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap8};
    width: 100%;
`

const FieldLabelBox = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 4px;
`

const FieldValueBox = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.colors.light};
    color: ${props => props.theme.colors.medium};
    width: 100%;
    padding: ${props => props.theme.spacing.spacing24} ${props => props.theme.spacing.spacing20};
    border-radius: ${props => props.theme.border.defaultRadius};
`

interface ReadonlyFieldComponentProps {
    fieldLabel: string;
    fieldValue: NullableUndefinedString;
}
export const ReadonlyFieldComponent: React.FC<ReadonlyFieldComponentProps> = observer((props) => {
    return (
        <FieldContainerBox>
            <FieldLabelBox>
                {props.fieldLabel}
            </FieldLabelBox>
            <FieldValueBox>
                {props.fieldValue}
            </FieldValueBox>
        </FieldContainerBox>
    )
})