import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../../hooks/use-services.hook";
import styled, {css} from "styled-components";
import {NullableUndefinedPrice} from "../../../../../services/currency/price";

const DayPriceBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
`

const PriceWholePartBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: ${props => props.theme.fontSize.h4};
    font-weight: ${props => props.theme.fontWeight.medium};
    padding: 0;
    
`

const PriceDecimalPartBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    font-size: 8px;
    
`

const PriceDecimalValueBox = styled.div<{$isHidden: boolean}>`
   ${props => props.$isHidden && css`visibility: hidden`}    
`

const CurrencySymbolBox = styled.span`
    flex-grow: 1;
    font-size: 9px;
`


interface DayPriceComponentProps {
    price: NullableUndefinedPrice;
    className?: string;
}

export const DayPriceComponent: React.FC<DayPriceComponentProps> = observer((props) => {
    const services = useServices();

    const price = props.price;

    if(!price) {
        return null;
    }



    const wholePart = Math.floor(price.amount);
    const decimalPart = Math.round((price.amount - Math.floor(price.amount)) * 100);
    const currencySymbol = services.currency.getCurrencySymbol(price.currency);


    return(
        <DayPriceBox className={props.className}>
            <PriceWholePartBox>
                {wholePart}
            </PriceWholePartBox>
            <PriceDecimalPartBox>
                <PriceDecimalValueBox $isHidden={decimalPart <= 0}>{decimalPart.toString().padStart(2, '0')}</PriceDecimalValueBox>
                <CurrencySymbolBox>{currencySymbol}</CurrencySymbolBox>
            </PriceDecimalPartBox>
        </DayPriceBox>
    )
});

