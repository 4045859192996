import { NullableString } from "../../types/nullable-types";

export const EXTERNAL_LINK_CSS_CLASS_NAME = 'link-external';

export interface IExternalLinksService {
    readonly appUsageTermsAndConditions: string;
    readonly privacyPolicyLink: string;
    readonly travelConditions: string;
    readonly childTravelConditions: string;
    readonly dangerousGoods: string;
    readonly groupBooking: string;
    readonly registrationTermsAndConditions: string;
    flightCanceled(pnr: string, lastName: NullableString): NullableString;
    readonly notAllowFlightChangeForCheckedInPassengers: string;
    insuranceTermsAndConditionsConfirmation(pdsUrl: string): string;
    insurancePolicyInfoDocument(ipidUrl: string, countryCode: string): string;
    readonly removeMyAccount: string;
    readonly contactForm: string;
}
