import React, {CSSProperties} from "react";
import {
    IBoardingPassSsr
} from "../../../../services/booking/boarding-pass/passenger-segment-boarding-pass-view-model.interface";
import {useServices} from "../../../../hooks/use-services.hook";
import {BoardingPassFieldValueComponent} from "../value/boarding-pass-field-value.component";

export const BoardingPassOtherSsrsComponent: React.FC<{otherSsrs: IBoardingPassSsr[]}> = (props) => {
    const services = useServices();
    const theme = services.theme.currentTheme;
    const otherSsrs = props.otherSsrs;
    if(otherSsrs.length === 0) {
        return null;
    }

    const styles: CSSProperties = {
        display: "flex",
        flexDirection: 'column',
        alignItems: 'flex-start',
        flexBasis: "40%",
        fontWeight: theme.fontWeight.medium,
        gap: services.theme.currentTheme.spacing.spacing8,
    }


    return (
        <BoardingPassFieldValueComponent styles={styles}>
            <div style={{fontSize: theme.fontSize.h5, fontWeight: theme.fontWeight.semiBold}}>
                SSRS
            </div>
            <div style={{color: theme.colors.primaryShade, fontSize: theme.fontSize.h4, fontWeight: theme.fontWeight.semiBold}}>
                {otherSsrs.map(ssr => (<div key={ssr.ssrCode}>{`${ssr.count} x ${ssr.ssrCode}`}</div>))}
            </div>

        </BoardingPassFieldValueComponent>
    )
}
