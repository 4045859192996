import React from "react";
import {observer} from "mobx-react";
import {StandardDialogPageComponent} from "../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../../components/modal/header/standard-dialog-header.component";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";
import {StandardDialogContentComponent} from "../../../components/modal/content/standard-dialog-content.component";
import {useServices} from "../../../hooks/use-services.hook";
import {BagsSelectionPageContentComponent} from "./bags-selection-page-content.component";
import {SameForAllFlightsToggleComponent} from "../common-components/same-for-all-flights-toggle.component";
import styled from "styled-components";
import {StandardDialogTitleComponent} from "../../../components/modal/header/standard-dialog-title.component";
import {
    IBookingSsrsAggregatorViewModel
} from "../../../services/booking/models/ssrs/aggregator/booking-ssrs-aggregator-view-model.interface";
import {SsrsSelectionDialogFooterComponent} from "../common-components/ssrs/ssrs-selection-dialog-footer.component";


const DialogHeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    padding: ${props => props.theme.spacing.spacing16} 0;
    
`

const DialogTitleBox = styled(StandardDialogTitleComponent)`
    flex-grow: 1;
`


export const BagSelectionDialogComponent: React.FC<{ssrsAggregator: IBookingSsrsAggregatorViewModel; dialogHandler: IDialogConfirmationHandler}> = observer((props) => {
    const services = useServices();

    const ssrsAggregator = services.booking.current.bagsSsrsAggregator;
    const renderSameForAllFlightsToggle = () => {
        if(!ssrsAggregator.allowUseSameOptionsForAllFlights) {
            return null;
        }


        return (
            <SameForAllFlightsToggleComponent ssrsAggregator={ssrsAggregator} checkboxLabelPlacement={"stacked"}/>
        );
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler}>
                <DialogHeaderBox>
                    <DialogTitleBox>
                        {services.language.translate('Bags')}
                    </DialogTitleBox>
                    {renderSameForAllFlightsToggle()}
                </DialogHeaderBox>

            </StandardDialogHeaderComponent>
            <StandardDialogContentComponent dialogHandler={props.dialogHandler}>
                <BagsSelectionPageContentComponent dialogHandler={props.dialogHandler}/>
            </StandardDialogContentComponent>
            <SsrsSelectionDialogFooterComponent ssrsAggregator={props.ssrsAggregator} dialogHandler={props.dialogHandler} />
        </StandardDialogPageComponent>
    );
})