import React from "react";
import {observer} from "mobx-react";
import {HomepageWebappSmallScreenComponent} from "./homepage.webapp.small-screen.component";
import {HomepageWebappLargeScreenComponent} from "./homepage.webapp.large-screen.component";
import {useScreenMediaQueriesChecks} from "../../../hooks/use-screen-media-queries-checks.hook";

export const HomeWebappPage: React.FC = observer(() => {
    const mediaQuery = useScreenMediaQueriesChecks();

    if(mediaQuery.smallScreen) {
        return (<HomepageWebappSmallScreenComponent/>);
    } else {
        return (<HomepageWebappLargeScreenComponent/>);
    }
});
