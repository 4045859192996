import {Check} from "../../../../types/type-checking";
import {OnlineCheckInValidationResultEnum} from "../../../dialog-factory/dialog-factory.service.interface";
import {IFormField} from "../../../../models/forms/form-field.interface";
import {CheckInFlowStepBase} from "../common/check-in-flow-step.base";
import {ValidationResultEnum} from "../../../../types/validation-result.enum";

export class CheckInFlowPassengersDetailsStep extends CheckInFlowStepBase {

    get title(): string {
        return this.services.language.translate('Passengers');
    }

    get route() {
        return this.routes.passengersDetails;
    }

    async validate(): Promise<ValidationResultEnum> {
        const passengersForCheckin = this.booking.passengers.getAllPersonsInTheBooking();

        let fieldsWithErrors: IFormField[] = [];
        for(let p of passengersForCheckin) {
            fieldsWithErrors = [
                ...fieldsWithErrors,
                ...p.travelDocument.activateErrorsValidation({setFocusOnFirstError: false})
            ]
        }

        if(fieldsWithErrors.length > 0) {
            fieldsWithErrors[0].setFocus();
            return ValidationResultEnum.Failure;
        }

        return ValidationResultEnum.Success;
    }

    async next(): Promise<void> {

        if(ValidationResultEnum.Success !== await this.validate()) {
            return;
        }


        const restrictedPassengersSegmentsCount = this.booking.getAllPassengersSegments().filter(p => !p.isValidForOnlineCheckIn).length;

        if(restrictedPassengersSegmentsCount === 0) {
            await this._executeCheckIn();

        } else {
            await this.services.dialogFactory.showOnlineCheckInValidation({
                onAccept: async (result) => {
                    result = Check.isNullOrUndefined(result)
                                ? OnlineCheckInValidationResultEnum.CancelNextStep
                                : result ;
                    await this._onAfterValidationResult(result);
                }
            });
        }
    }

    private async _onAfterValidationResult(validationResult: OnlineCheckInValidationResultEnum): Promise<void> {

        if(validationResult === OnlineCheckInValidationResultEnum.GoToHomePage) {
            await this.services.navigator.goHome();
        } else if (validationResult === OnlineCheckInValidationResultEnum.ContinueWithEligiblePassengers) {
            await this._executeCheckIn();
        } else if (validationResult === OnlineCheckInValidationResultEnum.OnlySaveDocuments) {
            await this.services.loadingIndicator.execute({
                action: async () => {
                    await this.booking.passengers.saveTravelDocuments();
                }
            });
            await this.services.navigator.goHome();
        }
    }

    private async _executeCheckIn(): Promise<void> {

        if(this.booking.hasPurchasesOnCurrentSession) {
            await this.services.loadingIndicator.execute({
                action: async () => {
                    await this.booking.passengers.saveTravelDocuments();
                    this._activateStepByRoute(this.routes.finalPrice);
                }
            });
        } else {
            if(ValidationResultEnum.Success === await this.booking.executeCheckIn({saveTravelDocuments: true})) {
                this._activateStepByRoute(this.routes.boardingPass);
            } else {
                await this.services.navigator.goHome();
            }
        }
    }
}
