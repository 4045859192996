import React from "react";
import {BookingFlowPageFooterSmallScreenComponent} from "./booking-flow-page-footer.small-screen.component";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import {BookingFlowPageHeaderSmallScreenComponent} from "./booking-flow-page-header.small-screen.component";
import {RoutedPageComponent} from "../../../basic-components/page/routed-page/routed-page.component";
import {StandardPageContentComponent} from "../../../basic-components/page/standard-page-content/standard-page-content.component";
import {BookingFlowPageProps} from "../booking-flow-page.props";
import {CopyRightComponent} from "../../../components/airline-company-info/copy-right.component";
import styled from "styled-components";

const StandardPageContentWrapper = styled(StandardPageContentComponent)`
    &::part(scroll){
        min-height: 100%;
    }
`

const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    gap: ${props => props.theme.spacing.spacing48};
`

const ContentMainSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => props.theme.spacing.pageContentHorizontalPadding};
    flex-grow: 1;
`



export const BookingFlowSmallScreenPage: React.FC<BookingFlowPageProps> = observer((props) => {
    const services = useServices();

    const canRenderPage = services.booking.currentPageCanBeRendered;

    const renderFooter = () => {
        if (props.footerOptions?.hideFooter) {
            return null;
        }

        if(!canRenderPage) {
            return null;
        }

        return (
            <BookingFlowPageFooterSmallScreenComponent hideContinueButton={props.shoppingCartOptions.hideContinueButton}
                                                       continueButtonText={props.shoppingCartOptions.continueButtonText}
                                                       hideShoppingCart={props.shoppingCartOptions.hideShoppingCart}
                                                       replaceShoppingCart={props.shoppingCartOptions.replaceShoppingCart}
                                                       showPromotionCode={props.shoppingCartOptions.showPromotionCode}
                                                       className={props.footerOptions?.pageFooterClassName}
                                                       replaceContinueButton={props.shoppingCartOptions.replaceContinueButton}>
                {props.footerOptions?.renderExtraFooterContent && props.footerOptions.renderExtraFooterContent()}
            </BookingFlowPageFooterSmallScreenComponent>
        );
    }


    const goToNextStep = async () => {
        if(services.booking.steps.currentStep?.allowSwipeForward) {
            await services.booking.steps.nextStep();
        }

    }

    return (
        <RoutedPageComponent swipeForwardHandler={goToNextStep}
                             disableSwipeForwardNavigation={props.disableSwipeForwardNavigation}
                             disableSwipeNavigation={props.disableSwipeNavigation}
                             className={props.className}>
            <BookingFlowPageHeaderSmallScreenComponent title={props.headerOptions.title}
                                                       hideWizardProgressIndicator={props.headerOptions.hideWizardProgressIndicator}
                                                       onBackButtonClick={props.headerOptions.onBackButtonClick}
                                                       dontAsForConfirmationOnLogoClick={props.headerOptions.dontAskForConfirmationOnLogoClick}
                                                       useLargeLogoOnSmallScreen={props.headerOptions.useLargeLogoOnSmallScreen}/>

            <StandardPageContentWrapper reducePaddingTopOnScroll={props.contentOptions.reduceTopPaddingOnPageScroll}
                                        className={props.contentOptions.pageContentClassName}
                                        onScroll={props.contentOptions.onContentScroll}>
                <ContentBox>
                    <ContentMainSectionBox>
                        {props.contentOptions.renderContent()}
                    </ContentMainSectionBox>
                    {!props.contentOptions?.hideCopyRight && <CopyRightComponent/>}
                </ContentBox>
            </StandardPageContentWrapper>
            {renderFooter()}
        </RoutedPageComponent>
    );
});

