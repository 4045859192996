import React, {CSSProperties} from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";

export const TicketPrintDateComponent: React.FC = observer(() => {
    const services = useServices();

    const styles: CSSProperties = {
        textAlign: 'right',
        width: '100%',
        fontSize: services.theme.currentTheme.fontSize.body2
    }

    return (
        <div style={styles}>{services.language.translationFor('Printed at: {time}').withParams({time: services.time.currentDate.toString()})}</div>
    )
})