import React, {useState} from "react";
import {StandardDialogPageComponent} from "../../components/modal/page/standard-dialog-page.component";
import {StandardDialogHeaderComponent} from "../../components/modal/header/standard-dialog-header.component";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import {observer} from "mobx-react-lite";
import {FormModel} from "../../models/forms/form.model";
import {FormFields} from "../../models/forms/form-field.interface";
import {useServices} from "../../hooks/use-services.hook";
import {StandardDropDownListItemModel} from "../../components/forms/standard-drop-down/list-view/models/item/standard-drop-down-list-item.model";
import {ApplicationEnvironmentTypeEnum} from "../../services/configuration/configuration-object.interface";
import {StandardDropDownComponent} from "../../components/forms/standard-drop-down/standard-drop-down.component";
import {StandardDialogFooterComponent} from "../../components/modal/footer/standard-dialog-footer.component";
import {PrimaryButton} from "../buttons/primary-button";
import {FormComponent} from "../../components/forms/form.component";
import {StandardDialogContentComponent} from "../../components/modal/content/standard-dialog-content.component";

interface IEnvSettingsFields {
    apiOrigin: string;
}



class EnvSettingsForm extends FormModel<IEnvSettingsFields> {
    protected _createFields(): FormFields<IEnvSettingsFields> {
        return {
            apiOrigin: this._createField<string>({
                fieldName: () => "Select environment",
                isRequired: true,
                initialValue: () => this.services.configuration.airlineWebapiUrl.toLowerCase()
            })
        }
    }

}

interface SwitchEnvironmentDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
}

export const SwitchEnvironmentDialogComponent: React.FC<SwitchEnvironmentDialogComponentProps> = observer((props) => {
    const services = useServices();
    const [form] = useState(new EnvSettingsForm(services));

    const domainsGroupedByApiOrigin = services.configuration.envConfigs.appDomains
                                        .filter(d => d.environmentType !== ApplicationEnvironmentTypeEnum.Development)
                                        .groupByKeyAndMapToType(d => d.apiOrigin, d => d.environmentType);

    const dropDownItems: StandardDropDownListItemModel<string>[] = [];

    const getEnvTypeName = (envType: ApplicationEnvironmentTypeEnum) => {
        switch (envType) {
            case ApplicationEnvironmentTypeEnum.Production:
                return 'Production';
            case ApplicationEnvironmentTypeEnum.Test:
                return 'Test';
            case ApplicationEnvironmentTypeEnum.Development:
                return 'Development'
            case ApplicationEnvironmentTypeEnum.Stage:
                return 'Stage'
            default:
                throw new Error(`Invalid environment type ${envType}`);
        }
    }

    Object.keys(domainsGroupedByApiOrigin).forEach(apiOrigin => {
        dropDownItems.push(new StandardDropDownListItemModel<string>(apiOrigin, getEnvTypeName(domainsGroupedByApiOrigin[apiOrigin][0])));
    })

    const conApplyClick = () => {
        if(!form.fields.apiOrigin.value) {
            services.alert.showError('Please select an environment');
            return;
        }

        if(form.fields.apiOrigin.value) {
            services.configuration.setPreferredApiOrigin(form.fields.apiOrigin.value);
            props.dialogHandler.accept();
        }

    }

    return (
      <StandardDialogPageComponent>
          <StandardDialogHeaderComponent dialogHandler={props.dialogHandler} title={'Switch environment'}/>
          <StandardDialogContentComponent dialogHandler={props.dialogHandler}>
              <FormComponent>
                  <StandardDropDownComponent field={form.fields.apiOrigin} items={dropDownItems}/>
              </FormComponent>

          </StandardDialogContentComponent>
          <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
              <PrimaryButton onClick={conApplyClick}>
                  Apply
              </PrimaryButton>


          </StandardDialogFooterComponent>
      </StandardDialogPageComponent>
    );
});
