import React from "react";
import {observer} from "mobx-react";
import {
    ISegmentSeatMapEditorViewModel
} from "../../../../../services/booking/models/seat-maps/segment-seat-map-editor-view-model.interface";
import styled from "styled-components";


const LetterBox = styled.div<{$gridColumn: number}>`
    grid-column: ${props => props.$gridColumn};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    color: ${props => props.theme.colors.medium};
    font-size: ${props => props.theme.fontSize.h4};
    font-weight: ${props => props.theme.fontWeight.medium};
`

export const RowsLettersComponent: React.FC<{seatMap: ISegmentSeatMapEditorViewModel; seatsPerRowCount: number;}> = observer((props) => {
    const letters = props.seatMap.availableSeatsLetters;
    const lettersChunks = letters.splitToChunks(letters.length / 2);
    const leftLetters = lettersChunks[0];
    const rightLetters = lettersChunks[1];

    const renderLeftLetter = (letter: string, index: number) => {
        return (
            <LetterBox key={letter} $gridColumn={index + 2}>{letter}</LetterBox>
        )
    }

    const renderRightLetter = (letter: string, index: number) => {
        return (
            <LetterBox key={letter} $gridColumn={leftLetters.length + index + 3}>{letter}</LetterBox>
        )
    }

    return (
        <>
            {leftLetters.map(renderLeftLetter)}
            {rightLetters.map(renderRightLetter)}
        </>
    );
})