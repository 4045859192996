import React, {useState} from "react";
import {observer} from "mobx-react";
import {useServices} from "../../hooks/use-services.hook";
import {TermsAndConditionDoNotAgreeDialogComponent} from "./terms-and-condition-do-not-agree-dialog.component";
import styled from "styled-components";
import {DialogCloseButtonBehavior} from "../../services/dialog/dialog-enums";
import {StandardDialogPageComponent} from "../modal/page/standard-dialog-page.component";
import {IDialogConfirmationHandler} from "../../services/dialog/dialog.service.interface";
import {ExternalLinkComponent} from "../external-link/external-link.component";
import {PrimaryButton} from "../../basic-components/buttons/primary-button";
import {PrimaryButtonInverted} from "../../basic-components/buttons/primary-button-inverted";
import {StandardDialogHeaderComponent} from "../modal/header/standard-dialog-header.component";
import {StandardDialogContentComponent} from "../modal/content/standard-dialog-content.component";
import {StandardDialogFooterComponent} from "../modal/footer/standard-dialog-footer.component";
import {UserSettingsFormModel} from "../../pages/settings/user-settings-form";
import {LangSelectionComponent} from "../lang-selection/lang-selection.component";

const DialogContentBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap20};
`

const TermsAndConditionsLinksBox = styled(ExternalLinkComponent)`
    width: 100%;
    font-size: ${props => props.theme.fontSize.h3};
    text-align: justify;
`

export const TermsAndConditionsDialogComponent: React.FC<{dialogHandler: IDialogConfirmationHandler}> = observer((props) => {
    const services = useServices();
    const [form] = useState(new UserSettingsFormModel(services));

    if(services.application.cookiesPolicyAccepted) {
        return null;
    }

    const onAccept = () => {
        services.application.cookiesPolicyAccepted = true;
        props.dialogHandler.accept();
    }

    form.fields.language.onChange(() => {
        if(form.fields.language.value) {
            services.language.changeCurrentLanguage(form.fields.language.value);
        }
    });

    const onReject = async () => {
        await services.dialog.showPopupDialog({
            closeButtonBehavior: DialogCloseButtonBehavior.Reject,
            maxHeight: '70%',
            render: dialogHandler => <TermsAndConditionDoNotAgreeDialogComponent dialogHandler={dialogHandler}/>
        });
    }


    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent title={services.language.translate("Important information")}
                                           closeButtonVisibility={'hidden'}
                                           dialogHandler={props.dialogHandler}/>
            <StandardDialogContentComponent dialogHandler={props.dialogHandler}>
                <DialogContentBox>
                    <LangSelectionComponent field={form.fields.language} hideLabel={true}/>
                    <TermsAndConditionsLinksBox innerHtml={services.externalLinks.appUsageTermsAndConditions}/>
                </DialogContentBox>

            </StandardDialogContentComponent>
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                <PrimaryButton onClick={onAccept}>
                    {services.language.translate('I accept')}
                </PrimaryButton>
                <PrimaryButtonInverted onClick={onReject}>
                    {services.language.translate('I do not accept')}
                </PrimaryButtonInverted>


            </StandardDialogFooterComponent>

        </StandardDialogPageComponent>

    )
});
