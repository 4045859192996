import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {useServices} from "../../../../hooks/use-services.hook";
import {ExtrasTilesComponent} from "../../../booking-flow/extras-selection/extras-tiles.component";
import {ManageMyBookingServicesSectionFactory} from "./manage-my-booking-services-section-factory";
import styled from "styled-components";
import {ManageMyBookingSectionTitleBox} from "../boxes/manage-my-booking-section-title.box";

const SectionContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.pageContentVerticalPadding};
    
`

export const ManageMyBookingServicesComponent: React.FC = observer(() => {
    const services = useServices();
    const [sectionsFactory] = useState(new ManageMyBookingServicesSectionFactory(services.booking.current));
    return (
        <SectionContainerBox>
            <ManageMyBookingSectionTitleBox>
                {services.language.translate('Services')}
            </ManageMyBookingSectionTitleBox>
            <ExtrasTilesComponent sectionsFactory={sectionsFactory}/>
        </SectionContainerBox>

    )
});
