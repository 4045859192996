import React from "react";
import {observer} from "mobx-react";
import {ExtrasSectionLargeTileComponent} from "../../../base-component/large-tile/extras-section.large-tile.component";
import {BaggageIcon} from "../../../../../../assets/svg/baggage.icon";
import {useServices} from "../../../../../../hooks/use-services.hook";
import {NullableString} from "../../../../../../types/nullable-types";
import {ExtrasBagsSectionCommonProps} from "../extras-bags-section-common-props";
import {
    SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE, SSR_LARGE_ICON_SIZE
} from "../../../../../../services/ssr-types/ssr-types.service.interface";

interface ExtrasBagsLargeTileComponentProps extends ExtrasBagsSectionCommonProps {
    isFirstTile: boolean;
    getMarketingMessage: () => NullableString;
    imageUrl: NullableString;

}
export const ExtrasBagsLargeTileComponent: React.FC<ExtrasBagsLargeTileComponentProps> = observer((props) => {
    const services = useServices();
    const ssrsAggregator = props.ssrsAggregator;

    return (
        <ExtrasSectionLargeTileComponent sectionTitle={services.language.translate('Bags')}
                                         renderFirstTileIcon={() => <BaggageIcon size={SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE}/>}
                                         renderSecondTileIcon={() => <BaggageIcon size={SSR_LARGE_ICON_SIZE}/>}
                                         modificationBlockingReason={ssrsAggregator.modificationBlockingReason}
                                         numberOfSelectedServices={props.countSelectedSsrs()}
                                         minDiscountedPrice={ssrsAggregator.discountedMinPrice}
                                         minStandardPrice={ssrsAggregator.standardMinPrice}
                                         onEditButtonClick={props.onEditButtonClick}
                                         isFirstTile={props.isFirstTile}
                                         getMarketingMessage={props.getMarketingMessage}
                                         allowEdit={!Boolean(ssrsAggregator.modificationBlockingReason)}
                                         imageUrl={props.imageUrl}/>
    )
})