import React, {CSSProperties, PropsWithChildren} from "react";
import {useServices} from "../../../../../hooks/use-services.hook";

interface BoardingPassBagsSectionComponentProps extends PropsWithChildren {
    styles?: CSSProperties;
}

export const BoardingPassBagsSectionComponent: React.FC<BoardingPassBagsSectionComponentProps> = (props) => {
    const services = useServices();
    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: services.theme.currentTheme.spacing.spacing8,
        ...props.styles
    };

    return (
        <div style={styles}>
            {props.children}
        </div>
    )
}
