import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../../hooks/use-services.hook";
import {
    IFlightSearchControllerViewModel
} from "../../../../../services/flight-search/flight-search-controller/flight-search-controller-view-model.interface";
import {IDropDownController} from "../../../../forms/drop-down/models/drop-down-controller.interface";
import {
    ReadonlyDropDownInputComponent
} from "../../../../../basic-components/input/read-only-drop-down-input.component";

interface PassengersTypesDropDownInputComponentProps {
    searchController: IFlightSearchControllerViewModel;
    dropDownController: IDropDownController;
}

export const PassengersTypesDropDownInputComponent: React.FC<PassengersTypesDropDownInputComponentProps> = observer((props) => {
    const services = useServices();


    const count = props.searchController.passengers.countAll();
    let text: string;
    if(count === 0) {
        text = services.language.translate('Select passengers');
    } else if (count === 1) {
        text = services.language.translate('1 passenger');
    } else {
        text = services.language.translationFor('{x} passengers').withParams({
            x: count.toString()
        });
    }


    return (
        <ReadonlyDropDownInputComponent value={text} hasSelection={count > 0}/>
    );

});
