import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import {ContainerMediaQueriesChecksContext} from "../../../react-contexts/container-media-queries-checks.context";
import styled, {ThemeProvider} from "styled-components";
import {useServices} from "../../../hooks/use-services.hook";
import {useScreenMediaQueriesChecks} from "../../../hooks/use-screen-media-queries-checks.hook";
import {ContainerMediaQueryChecks} from "../../../services/media-query/container/container-media-query-checks";
import {
    BookingFlowContinueButtonComponent
} from "../common-components/continue-button/booking-flow-continue-button.component";
import {
    ShoppingCartJourneySkeletonComponent
} from "../../../components/shopping-cart/skeletons/shopping-cart-journey-skeleton.component";
import {ShoppingCartComponent} from "../../../components/shopping-cart/shopping-cart.component";
import {
    ShoppingCartBundleSkeletonComponent
} from "../../../components/shopping-cart/skeletons/shopping-cart-bundle-skeleton.component";
import {ShoppingCartNotificationsComponent} from "../../../components/shopping-cart/shopping-cart-notifications.component";
import {PriceComponent} from "../../../components/price/price.component";
import {PromoCodeComponent} from "../../../components/promotionals/promo-code/promo-code.component";
import {SCROLLBAR_WIDTH} from "../../../services/theme/global-styles";
import {CSS_VAR_SHOPPING_CART_WIDTH_FOR_LARGE_SCREEN} from "../booking-flow-css-constants";
import {BookingFlowLargeScreenPageRightSectionBodyBox} from "./booking-flow.large-screen..page.right-section-body.box";
import {IonSpinnerComponent} from "../../../components/spinner/ion-spinner.component";


const RightSectionMediaChecksContainerBox = styled.div<{$pageHeaderHeight: string; $pageFooterHeight: string}>`
    container-type: inline-size;
    position: fixed;
    right: ${props => props.theme.spacing.spacing40};
    display: flex;
    flex-direction: column;
    align-items: center;
    width: var(${CSS_VAR_SHOPPING_CART_WIDTH_FOR_LARGE_SCREEN});
    height: calc(100vh - ${props => props.$pageHeaderHeight} - ${props => props.$pageFooterHeight} - 2 * ${props => props.theme.spacing.pageContentVerticalPadding});
    padding: 0;
    
`



const ShoppingCartContentContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    border-radius: ${props => props.theme.border.defaultRadius};
    gap: ${props => props.theme.spacing.spacing30};
    padding-top: calc(${SCROLLBAR_WIDTH} + ${props => props.theme.spacing.spacing12});
    padding-bottom: calc(${SCROLLBAR_WIDTH} + ${props => props.theme.spacing.spacing12});
    padding-left: calc(${SCROLLBAR_WIDTH} + ${props => props.theme.spacing.spacing12});
    padding-right: ${props => props.theme.spacing.spacing12};
    
`

const RightSectionFooterBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${props => props.theme.spacing.spacing16};
    padding: 0;
    margin-top: ${props => props.theme.spacing.spacing30};
    width: 100%;
`


const TotalContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 45px;
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props =>props.theme.fontWeight.semiBold};
    padding: ${props => props.theme.spacing.spacing16};
    background-color: ${props => props.theme.colors.primaryShade};
    color: ${props => props.theme.colors.light};
    border-bottom-left-radius: ${props => props.theme.border.defaultRadius};
    border-bottom-right-radius: ${props => props.theme.border.defaultRadius};
    box-shadow: ${props => props.theme.boxShadow.shadow3};
    width: 100%;
    
`

const BalanceDueLabelBox = styled.div`
    flex-grow: 1;
    text-transform: uppercase;
`

const BalanceDueValueBox = styled.div`
    display: flex;
    flex-direction: column;
`



const SpinnerContainerBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 55px;
`

const SpinnerBox = styled(IonSpinnerComponent)`
    --color: ${props => props.theme.colors.primaryContrast};
`

interface BookingFlowLargeScreenPageRightSectionComponentProps {
    showPromotionCode: boolean;
    hideShoppingCart?: boolean;
    hideContinueButton?: boolean;
    replaceShoppingCart?: () => React.ReactElement;
    continueButtonText?: string;
    replaceContinueButton?: () => React.ReactElement;

}
export const BookingFlowLargeScreenPageRightSectionComponent: React.FC<BookingFlowLargeScreenPageRightSectionComponentProps> = observer((props) => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();
    const rightSectionRef = useRef<HTMLDivElement | null>(null);
    const rightContainerMediaChecks = useRef(new ContainerMediaQueryChecks(screenMediaQuery));

    useEffect(() => {

        const rightMediaChecks = rightContainerMediaChecks.current;

        if (rightSectionRef.current) {
            rightMediaChecks.setElement(rightSectionRef.current);
        }

        return () => {

            rightMediaChecks?.dispose();
        }
    }, []);


    if (props.hideShoppingCart) {
        return null;
    }

    const booking = services.booking.current;

    const shouldShowPromoCode = props.showPromotionCode && booking.allowPromoCode;

    const renderPromotionCode = () => {
        if (!shouldShowPromoCode) {
            return null;
        }

        return (
            <PromoCodeComponent promoCode={booking.promoCode}/>
        );
    }


    const renderBalanceDueValue = () => {
        if (booking.mutationsManager.isBookingTotalUpdateInProgress) {
            return (<SpinnerContainerBox>
                <SpinnerBox/>
            </SpinnerContainerBox>);
        }
        return (
            <BalanceDueValueBox>
                <ShoppingCartNotificationsComponent/>
                <PriceComponent price={booking.balanceDue}/>
            </BalanceDueValueBox>

        );
    }


    const renderBundleSkeleton = () => {
        if (booking.departureJourney?.selectedBundle) {
            return null;
        }

        return (
            <ShoppingCartBundleSkeletonComponent key={"SELECT_BUNDLE_SKELETON"}/>
        )
    }

    const shouldRenderJourneySkeletons = !Boolean(booking.departureJourney)

    const renderShoppingCartContent = () => {

        if (shouldRenderJourneySkeletons) {
            let numberOfJourneySkeletons = Math.max(1, services.booking.availability.departureTrips.selectMany(t => t.journeys).length);
            return (
                <ShoppingCartJourneySkeletonComponent numberOfJourneySkeletons={Math.min(7, numberOfJourneySkeletons)}/>
            );
        }


        return (
            <ShoppingCartContentContainerBox>
                <ShoppingCartComponent/>
                {renderBundleSkeleton()}
            </ShoppingCartContentContainerBox>
        );
    }

    const renderTotal = () => {
        if(shouldRenderJourneySkeletons) {
            return null;
        }
        return (
            <TotalContainerBox>
                <BalanceDueLabelBox>
                    {services.language.translate('Total')}
                </BalanceDueLabelBox>
                {renderBalanceDueValue()}
            </TotalContainerBox>
        )
    }

    const renderContinueButton = () => {
        if(props.replaceContinueButton) {
            return props.replaceContinueButton();
        }

        if(props.hideContinueButton) {
            return null;
        }

        return (
            <BookingFlowContinueButtonComponent isPromotionAllowed={shouldShowPromoCode}
                                                inactive={shouldRenderJourneySkeletons}
                                                customButtonText={props.continueButtonText}/>
        );
    }

    const renderContent = () => {
        if(props.replaceShoppingCart) {
            return (
                props.replaceShoppingCart()
            );
        }

        return (
            <>
                <BookingFlowLargeScreenPageRightSectionBodyBox>
                    {renderShoppingCartContent()}
                </BookingFlowLargeScreenPageRightSectionBodyBox>

                {renderTotal()}

                <RightSectionFooterBox>
                    {renderPromotionCode()}
                    {renderContinueButton()}
                </RightSectionFooterBox>
            </>
        )
    }

    return (
        <RightSectionMediaChecksContainerBox ref={rightSectionRef} $pageHeaderHeight={services.pageHeader.heightPx} $pageFooterHeight={services.pageFooter.heightPx}>

            <ContainerMediaQueriesChecksContext.Provider value={rightContainerMediaChecks.current}>
                <ThemeProvider theme={services.theme.applyContainerMediaQueries()}>
                    {renderContent()}
                </ThemeProvider>

            </ContainerMediaQueriesChecksContext.Provider>


        </RightSectionMediaChecksContainerBox>

    );
})