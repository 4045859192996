import React from "react";
import {observer} from "mobx-react-lite";
import {SeatsSelectionSmallScreenPage} from "../../../booking-flow/seat-selection/seats-selection.small-screen.page";
import {SeatSelectionLargeScreenPage} from "../../../booking-flow/seat-selection/seat-selection.large-screen.page";
import {ScreenSizeBasedComponent} from "../../../../basic-components/layout/screen-size-based.component";

export const ManageMyBookingSeatsSelectionPage: React.FC = observer(() => {
    const renderForSmallScreen = () => {
        return (
            <SeatsSelectionSmallScreenPage/>
        )
    }


    const renderForLargeScreen = () => {
        return (
            (<SeatSelectionLargeScreenPage />)
        );
    }

    return (
        <ScreenSizeBasedComponent renderForSmallScreen={renderForSmallScreen}
                                  renderForLargeScreen={renderForLargeScreen}/>
    );
});
