import {IFormField} from "../../../../models/forms/form-field.interface";
import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {FieldInputContainerComponent} from "../../../../components/forms/field-input-container.component";
import {CheckboxComponent} from "../../../../basic-components/checkbox/checkbox.component";
import {useServices} from "../../../../hooks/use-services.hook";
import {PsoPassengerTypeEnum} from "../../../../services/booking/models/passenger/passenger-info-fields.interface";

const ContainerBox = styled.div`
    grid-column: 1 / -1;
`

const PsoPassengerTypeInputBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    
`

interface PsoPassengerTypeSelectorComponentProps {
    field: IFormField<PsoPassengerTypeEnum | null>;
}

export const PsoPassengerTypeSelectorComponent: React.FC<PsoPassengerTypeSelectorComponentProps> = observer((props) => {
    const services = useServices();

    if(props.field.isHidden) {
        return null;
    }

    const renderInput = () => {
        return (
            <PsoPassengerTypeInputBox>
                <CheckboxComponent checked={props.field.value === PsoPassengerTypeEnum.Resident}
                                   labelPlacement={"end"}
                                   label={services.language.translate('Resident in Sicily')}
                                   onChange={(isChecked) => {
                                       if(isChecked) {
                                           props.field.setValue(PsoPassengerTypeEnum.Resident);
                                       } else {
                                           props.field.setValue(null);
                                       }
                                   }}/>
                <CheckboxComponent checked={props.field.value === PsoPassengerTypeEnum.Native}
                                   labelPlacement={"end"}
                                   label={services.language.translate('Born in Sicily')}
                                   onChange={(isChecked) => {
                                       if(isChecked) {
                                           props.field.setValue(PsoPassengerTypeEnum.Native);
                                       } else {
                                           props.field.setValue(null);
                                       }
                                   }}/>
            </PsoPassengerTypeInputBox>
        )
    }

    return (
        <ContainerBox>
            <FieldInputContainerComponent field={props.field} hideLabel={true} renderInput={renderInput} />
        </ContainerBox>

    )
})
